import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids';
import { DoorPrepComponent } from '../door-prep/door-prep.component';
import { PrepsComponent } from '../preps.component';
import { LockPrepComponent } from './lock-prep.component';

@Component({
  selector: 'lib-primary-lock-prep',
  templateUrl: './lock-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class PrimaryLockPrepComponent extends LockPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent;

  linkToPrepCodes = true
  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(
      'Primary Lock Prep',
      prepsComponent,
      'primaryLockPrep',
      DoorPrepCategoryIds.PRIMARY_LOCK
    );
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngAfterViewInit(): void {
    super.afterViewInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}
