<lib-dialog [header]="'TITLE.profileDesigner' | translate">
  <p>{{'INSTRUCTIONS.enterDimensions' | translate}}</p>
  <div class="container">
    <svg id="frameProfile" #svgRef>
      <g>
        <svg #containerRef></svg>
      </g>
    </svg>
  </div>
</lib-dialog>
