import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { gaugeEdges } from '../../rules/gauge';
import { thicknessEdges } from '../../rules/thickness';
import { DoorEdge } from '../door-edge';
import { DoorGauge } from '../door-gauge';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';
import { DoorThickness } from '../door-thickness';

export function DoorEdges(
  series: DoorSeries,
  subType: DoorSubType,
  gauge: DoorGauge,
  thickness: DoorThickness
): DoorEdge[] {
  const edges = [...(DoorSeriesInfos[series]?.subTypes[subType]?.edges ?? [])];
  gaugeEdges(edges, gauge);
  thicknessEdges(edges, thickness);
  return edges;
}
