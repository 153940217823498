import { InjectionToken } from '@angular/core'

export const STORAGE_SERVICE = new InjectionToken('StorageService')

export interface IStorageService<LocalStorageIndex = {}> {
  setItem<K extends keyof LocalStorageIndex, V extends LocalStorageIndex[K]>(key: K, value: V): void
  getItem<K extends keyof LocalStorageIndex, V extends LocalStorageIndex[K]>(key: K): V
  removeItem<K extends keyof LocalStorageIndex>(key: K): void
  containsItem(key: keyof LocalStorageIndex): boolean
  clear(): void
}
