import { Subject } from 'rxjs'
import { Cutout } from './cutout'
import {
  characterHeight,
  characterWidth,
  distanceFromEdge,
  distanceStyles,
  Door,
  imperialTextStyles,
  lineStyles
} from '../models/door'
import { Updatable } from './updatable'
import { takeUntil } from 'rxjs/operators'
import { CutoutExport } from '../models/exports/cutout-export'
import { Handing } from '../enums/handing'
import {
  appendChildElement,
  appendChildrenElements,
  appendToContainerElement,
  createSVGElement,
  setStyles,
  setTextContent,
  setX,
  setX1,
  setX2,
  setY,
  setY1,
  setY2
} from '../../core/helpers/svg-functions'
import { pipe } from 'lodash/fp'

export interface DoorType {
  customDrawing?(container: SVGGElement): void
  drawDimensions?(container: SVGGElement): void
}

export abstract class DoorType extends Updatable {
  private _doorType$ = new Subject<void>()
  abstract cutouts: Cutout[]
  private _minX: number
  private _minY: number
  private _maxY: number

  constructor(public readonly door: Door, cutouts?: CutoutExport[]) {
    super()
  }

  draw(container: SVGGElement) {
    this._minX = container.minX() + distanceFromEdge
    this._minY = container.minY(characterHeight) + distanceFromEdge
    this._maxY = container.maxY() - distanceFromEdge
    this._doorType$.next()
    for (const cutout of this.cutouts) {
      cutout.draw(container)
      cutout.update$.pipe(takeUntil(this._destroy$), takeUntil(this._doorType$)).subscribe(() => this._update$.next())
    }
    if (this.customDrawing) {
      this.customDrawing(container)
    }
    this.cleanup(container)
  }

  destroy(): void {
    this._doorType$.next()
    this._doorType$.unsubscribe()
    super.destroy()
  }

  protected drawVerticalDimensionLine(container: SVGGElement, str: string, x: number, y1: number, y2: number): void {
    x += distanceFromEdge
    const primaryLine = pipe(setY2(y2), setY1(y1), setX2(this._minX), setX1(this._minX))(createSVGElement('line'))

    if (y1 !== 0) {
      const topLine = pipe(
        setY2(y1),
        setY1(y1),
        setX2(x),
        setX1(this._minX + distanceFromEdge / 2),
        appendToContainerElement(container)
      )(createSVGElement('line'))
    }

    if (y2 !== this.door.height - this.door.doorElevation.headClearance) {
      const bottomLine = pipe(
        setY2(y2),
        setY1(y2),
        setX2(x),
        setX1(this._minX + distanceFromEdge / 2),
        appendToContainerElement(container)
      )(createSVGElement('line'))
    }

    const dimensionLine = pipe(
      setStyles(distanceStyles),
      setStyles(lineStyles),
      setY2([y1, y2].average((_) => _)),
      setY1([y1, y2].average((_) => _)),
      setX2(this._minX),
      setX1(this._minX + (distanceFromEdge * 3) / 2)
    )(createSVGElement('line'))

    const text = pipe(
      setStyles(imperialTextStyles),
      setY([y1, y2].average((_) => _) + characterHeight / 2),
      setX(this._minX + (distanceFromEdge * 3) / 2 - str.length * characterWidth),
      setTextContent(str)
    )(createSVGElement('text'))

    appendChildrenElements([primaryLine, dimensionLine, text])(container)
  }

  protected drawTopDimensionLine(container: SVGGElement, str: string, y: number, x1: number, x2: number): void {
    y += distanceFromEdge
    const primaryLine = pipe(
      setStyles(lineStyles),
      setY2(this._minY),
      setY1(this._minY),
      setX2(x2),
      setX1(x1)
    )(createSVGElement('line'))

    if (x1 !== 0) {
      const leftLine =
      pipe(setStyles(lineStyles), setX1(x1), setX2(x1), setY1(this._minY + distanceFromEdge / 2), setY2(y),
      appendToContainerElement(container))(createSVGElement('line'))
    }

    const rightLine = createSVGElement('line')

    if (x2 !== this.door.actualWidth) {
      pipe(
        setStyles(lineStyles),
        setX1(x2),
        setX2(x2),
        setY1(this._minY + distanceFromEdge / 2),
        setY2(y),
        appendToContainerElement(container)
      )(rightLine)
    }

    const dimensionLine = pipe(
      setStyles(distanceStyles),
      setStyles(lineStyles),
      setY2(this._minY),
      setY1(this._minY + (distanceFromEdge * 3) / 2),
      setX2([x1, x2].average((_) => _)),
      setX1([x1, x2].average((_) => _))
    )(createSVGElement('line'))

    const text = pipe(
      setStyles(imperialTextStyles),
      setY(this._minY + (distanceFromEdge * 3) / 2 + characterHeight / 2),
      setX([x1, x2].average((_) => _) - (str.length * characterWidth) / 2),
      setTextContent(str)
    )(createSVGElement('text'))

    appendChildrenElements([primaryLine, dimensionLine, text])(container)
  }

  protected drawBottomDimensionLine(container: SVGGElement, str: string, y: number, x1: number, x2: number): void {
    y -= distanceFromEdge

    const primaryLine = pipe(
      setStyles(lineStyles),
      setY2(this._maxY),
      setY1(this._maxY),
      setX2(x2),
      setX1(x1)
    )(createSVGElement('line'))


    if (x1 !== 0) {
      const leftLine = pipe(
        setStyles(lineStyles),
        setX1(x1),
        setX2(x1),
        setY1(this._maxY - distanceFromEdge / 2),
        setY2(y),
        appendToContainerElement(container)
      )(createSVGElement('line'))
    }


    if (x2 !== this.door.actualWidth) {
      const rightLine = pipe(
        setStyles(lineStyles),
        setX1(x2),
        setX2(x2),
        setY1(this._maxY - distanceFromEdge / 2),
        setY2(y),
        appendToContainerElement(container)
      )(createSVGElement('line'))
    }

    const dimensionLine = pipe(
      setStyles(distanceStyles),
      setStyles(lineStyles),
      setY2(this._maxY),
      setY1(this._maxY - (distanceFromEdge * 3) / 2),
      setX2([x1, x2].average((_) => _)),
      setX1([x1, x2].average((_) => _))
    )(createSVGElement('line'))

    const text = pipe(
      setStyles(imperialTextStyles),
      setY(this._maxY - (distanceFromEdge * 3) / 2 + characterHeight / 2),
      setX([x1, x2].average((_) => _) - (str.length * characterWidth) / 2),
      setTextContent(str)
    )(createSVGElement('text'))

    appendChildrenElements([primaryLine, dimensionLine, text])(container)
  }

  private cleanup(container: SVGGElement): void {
    const arr = Array.from(container.children)
      .filter((element) => element instanceof SVGLineElement)
      .groupBy((x) => `${x.getAttribute('x1')} ${x.getAttribute('y1')} ${x.getAttribute('x2')} ${x.getAttribute('y2')}`)
    arr.forEach((group) => {
      group.skip(1).forEach((element) => element.remove())
    })
  }

  /**
   * Checks whether to flip the cutout - when door handing changes for pair doors
   * @returns {boolean} - whether or not to flip the position of the cutout
   */
  protected shouldFlipCutout(): boolean {
    const isLeft = this.door.doorElevation.doors.indexOf(this.door) === 0
    return (
      this.door.doorElevation.doors.length > 1 &&
      [...(isLeft ? [Handing.RH, Handing.RHR] : [Handing.LH, Handing.LHR])].any(
        (handing) => handing === this.door.handing
      )
    )
  }
}
