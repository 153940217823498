import { Injectable } from '@angular/core'
import { Announcement, ReadAnnoucement } from '../../models/announcement.model'
import { Observable, BehaviorSubject, combineLatest } from 'rxjs'
import { tap, map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { orderBy } from 'lodash'
import { StorageService } from '../local-storage/local-storage.service'

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {
  _announcement: BehaviorSubject<Announcement> = new BehaviorSubject(null)
  announcement$: Observable<Announcement> = this._announcement.asObservable()

  _readAnnouncements: BehaviorSubject<ReadAnnoucement[]> = new BehaviorSubject(null)
  readAnnouncements$: Observable<ReadAnnoucement[]> = this._readAnnouncements.asObservable()

  constructor(private http: HttpClient, private storageService: StorageService) {
    const announcements = this.storageService.getItem('readAnnoucements')
      ? this.handleBackwardsCompatibility(this.storageService.getItem('readAnnoucements'))
      : []
    this._readAnnouncements.next(announcements)
  }

  get(filtered?: boolean): Observable<Announcement[]> {
    const url = environment.onlineOrderingApiUrl + `announcements?filterTags=${!!filtered}`
    const announcements$ = this.http.get<Announcement[]>(url)
    return combineLatest([this.readAnnouncements$, announcements$]).pipe(
      map((vals) => {
        const readIds = vals[0] || []
        const announcements = vals[1] || []
        return announcements.map((a) => {
          let isNew: boolean
          const readInfo: ReadAnnoucement = readIds.find((ra) => a.id === ra.id)
          if (readInfo) {
            const updatedAfterRead = readInfo.dateRead < Date.parse(a.lastModified)
            isNew = updatedAfterRead ? this.isNew(a) : false
          } else {
            isNew = this.isNew(a)
          }
          return { ...a, isNew }
        })
      }),
      map((val) => orderBy(val, ['lastModified'], ['desc']))
    )
  }

  getById(id: string | number): Observable<Announcement> {
    const url = environment.onlineOrderingApiUrl + `announcements/${id}`
    return this.http.get<Announcement>(url).pipe(tap((val: Announcement) => this._announcement.next(val)))
  }

  update(id: string | number, announcement: Announcement): Observable<Object> {
    if (id) {
      return this._update(id, announcement)
    } else {
      return this._create(announcement)
    }
  }

  private _update(id: string | number, announcement: Announcement) {
    const url = environment.onlineOrderingApiUrl + `announcements/${id}`
    const payload = { ...announcement, id }
    return this.http.put(url, payload)
  }

  private _create(announcement: Announcement) {
    const url = environment.onlineOrderingApiUrl + `announcements`
    const payload: any = { ...announcement }

    payload.announcementTags = announcement.announcementTags.map((tag) => ({ tagId: tag.tagId }))
    payload.announcementDetails = announcement.announcementDetails.map((d) => ({
      detail: d.detail,
      language: d.language
    }))
    return this.http.post(url, payload)
  }

  isNew(announcement: Announcement): boolean {
    const today = Date.now()
    const lastModified = Date.parse(announcement.lastModified)
    const diff = today - lastModified
    // milliseconds in a minute * 60 min/hour * 24/day * 14 days
    const twoWeeks = 6000 * 60 * 24 * 14
    return diff < twoWeeks
  }

  markAsRead(id: string | number): ReadAnnoucement[] {
    const readAnnouncements = this.storageService.getItem('readAnnoucements')
      ? this.handleBackwardsCompatibility(this.storageService.getItem('readAnnoucements'))
      : []

    const alreadyRead = !!readAnnouncements.find((ra) => ra.id === Number(id))
    if (alreadyRead) {
      return readAnnouncements
    }
    const updatedList = [...readAnnouncements, { id: Number(id), dateRead: Date.now() }]
    this._readAnnouncements.next(updatedList)
    this.storageService.setItem('readAnnoucements', updatedList)
    return updatedList
  }

  handleBackwardsCompatibility(readAnnoucements: (number | ReadAnnoucement)[]): ReadAnnoucement[] {
    return readAnnoucements.map((a) => {
      if (typeof a === 'number') {
        return { id: a, dateRead: 0 }
      } else {
        return a
      }
    })
  }
}
