import { Component, Input, OnDestroy } from '@angular/core'
import { TableService } from '../../../table.service'
import { Subject } from 'rxjs'
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { TableColumn, InputEvent } from '../../../../../types'

@Component({
  selector: 'oa-cross-reference-input',
  templateUrl: './crossReference-input.component.html',
  styleUrls: ['./crossReference-input.component.scss']
})
export class CrossReferenceInputComponent<T = object> implements OnDestroy {
  @Input() data: any
  @Input() row: any
  @Input() column!: TableColumn<T>

  destroyed$: Subject<boolean> = new Subject()
  inputValue$: Subject<InputEvent<T>> = new Subject()

  constructor(private tableActionsService: TableService<T>) {
    this.inputValue$
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$), debounceTime(1000))
      .subscribe((res) => this.tableActionsService.inputEvent.next(res))
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation()
  }

  handleChange(val: string, $event: KeyboardEvent) {
    const payload: InputEvent<T> = {
      tableName: this.column.tableName ?? '',
      row: this.row,
      column: this.column,
      newVal: val.toUpperCase()
    }
    this.inputValue$.next(payload)
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
