import { Door } from '../../models/door';
import { DoorType } from '../../abstracts/door-type';
import { CutoutExport } from '../../models/exports/cutout-export';
import { Cutout } from '../../abstracts/cutout';

export class TransomType extends DoorType {
  cutouts: Cutout[]= [];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
  }
}
