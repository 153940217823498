import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IPrepCategory } from '../interfaces/i-prep-category';
import { IPrepCategoryService } from '../interfaces/i-prep-category.service';
import { Cacheable } from 'ts-cacheable';

@Injectable()
export class PrepCategoryService implements IPrepCategoryService {
  private _data: IPrepCategory[];
  constructor(private http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  @Cacheable()
  get(): Observable<IPrepCategory[]> {
    return this._data
      ? of(this._data)
      : this.http
          .get<IPrepCategory[]>(`${this.environment.elevations.apiUrl}v1/prepCategories`)
          .pipe(tap(data => (this._data = data)));
  }
}
