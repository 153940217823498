<div class="page-header" [ngClass]="{ loading: loading }">
  <h1 translate>notifications</h1>
</div>

<ng-container *ngIf="announcements?.length; else empty">
  <mat-card>
    <mat-card-title>{{ 'notifications' | translate }}</mat-card-title>
    <mat-card-content>
      <mat-list>
        <a [routerLink]="['./', announcement.id]" mat-list-item *ngFor="let announcement of announcements">
          <mat-icon mat-list-icon color="accent">
            {{ announcement.isNew ? 'fiber_new' : '' }}
          </mat-icon>
          <span mat-line>{{ announcement.name }}</span>
        </a>
      </mat-list>
    </mat-card-content>
  </mat-card>
</ng-container>

<ng-template #empty>
  <div class="empty" *ngIf="!loading">
    <img src="/assets/img/illustrations/standard.svg" alt="" />
    <div class="description">
      <h3 translate>empty</h3>
      <p translate>emptyNotifications</p>
    </div>
  </div>
</ng-template>
