import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { nameOf } from '@oeo/common';
import { FrameElevation } from '../../../models/frame-elevation';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepsComponent } from '../preps.component';
import { StrikePrepComponent } from './strike-prep.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-deadlock-strike-prep',
  templateUrl: './strike-prep.component.html',
  styleUrls: ['./strike-prep.component.scss', '../prep-base.component.scss'],
})
export class DeadlockStrikePrepComponent extends StrikePrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(
      'Deadlock Strike Prep',
      prepsComponent,
      'deadlockStrikePrep',
      FramePrepCategoryIds.DEADLOCK_STRIKE
    );
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngAfterViewInit(): void {
    super.afterViewInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}
