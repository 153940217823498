import { Injectable, inject } from '@angular/core'
import { AlertComponent } from '../components/alert/alert.component'
import { AlertData, AlertPosition, alertPositions } from '../types/alert.model'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  matSnackBar = inject(MatSnackBar)

  openAlert(data: AlertData, duration: number = 3500, position: AlertPosition = 'bottomCenter') {
    this.matSnackBar.openFromComponent(AlertComponent, {
      panelClass: ['oeo-alert'],
      duration,
      data,
      ...alertPositions[position]
    })
  }

  closeAlert() {
    this.matSnackBar.dismiss()
  }
}
