import { Approval } from '../../../core/enums/approval';
import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { approvalSeamlessEdges } from '../../rules/approval';
import { gaugeSeamlessEdges } from '../../rules/gauge';
import { DoorGauge } from '../door-gauge';
import { DoorSeamlessEdge } from '../door-seamless-edge';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorSeamlessEdges(
  series: DoorSeries,
  subType: DoorSubType,
  gauge: DoorGauge,
  approval: Approval
): DoorSeamlessEdge[] {
  const seamlessEdges = [...(DoorSeriesInfos[series]?.subTypes[subType]?.seamlessEdges ?? [])];
  gaugeSeamlessEdges(seamlessEdges, gauge);
  approvalSeamlessEdges(seamlessEdges, approval);
  return seamlessEdges;
}
