<lib-dialog libMoveable [(top)]="top" [(left)]="left" [zIndex]="5" header="Help" [closeable]="true" (close)="close()">
  <table>
    <tr>
      <th>{{"key" | translate}}</th>
      <th>{{"modifier" | translate}}</th>
      <th>{{"description" | translate }}</th>
    </tr>
    <tr *ngFor="let item of items">
      <td>{{ item.key }}</td>
      <td>{{ item.modifier }}</td>
      <td [title]="item.description">{{ item.description }}</td>
    </tr>
  </table>
</lib-dialog>
