import { DoorBottomChannel } from '../enums/door-channel';
import { DoorSweep } from '../enums/door-sweep';

export function bottomChannelSweeps(sweeps: DoorSweep[], bottomChannel: DoorBottomChannel) {
  if (bottomChannel === DoorBottomChannel.FLUSH) {
    while (sweeps.any()) {
      sweeps.removeAt(0);
    }
  }
}
