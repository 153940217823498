import { Component, OnInit } from '@angular/core';
import { parseFromXMLString } from '../../../core/helpers/parse-from-xml-string';
import { IDialog } from '../../../core/interfaces/i-dialog';
import { ExperlogixService } from '../../../core/services/experlogix.service';
import { DoorElevation } from '../../models/door-elevation';
import { catchError, EMPTY } from 'rxjs';

interface DoorConfiguration {
  unitPrice: string;
  basePrice: string;
  coreAdder: string;
  steelStiffenAdder: string;
  materialAdder: string;
  metalMylarAdder: string;
  seamlessEdgeAdder: string;
  thicknessAdder: string;
  gaugeAdder: string;
  finishPaintAdder: string;
  hingePrepAdder: string;
  primaryLockPrepAdder: string;
  deadlockPrepAdder: string;
  secondaryLockPrepAdder: string;
  tertiaryLockPrepAdder: string;
  primaryStrikePrepAdder: string;
  deadlockStrikePrepAdder: string;
  secondaryStrikePrepAdder: string;
  tertiaryStrikePrepAdder: string;
  closerPrepAdder: string;
  blankSurfaceHingePrepAdder: string;
  anchorHingePrepAdder: string;
  topBottomPivotPrepAdder: string;
  intPivotPrepAdder: string;
  ohStopHolderPrepAdder: string;
  magSwitchPrepAdder: string;
  powerTransferPrepAdder: string;
  rollerLatchPrepAdder: string;
  flushBoltPrepAdder: string;
  surfaceBoltPrepAdder: string;
  cutoutPrice: string;
}

interface CutoutConfiguration {
  type: string;
  unitPrice: string;
  louverBasePrice: string;
  glassBasePrice: string;
  muntinBarBasePrice: string;
  materialAdder: string;
  glassPocketAdder: string;
}

interface LineItemConfiguration {
  catalogNumber: string;
  model: string;
  subType: string;
  width: string;
  height: string;
  gauge: string;
  core: string;
  unitPrice: string;
  doors: DoorConfiguration[];
  cutouts: CutoutConfiguration[];
}

@Component({
  selector: 'lib-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, IDialog<DoorElevation> {
  data: DoorElevation;
  lineItem: LineItemConfiguration = {} as LineItemConfiguration;
  loading = true
  constructor(private experlogixService: ExperlogixService) {}

  ngOnInit(): void {
    this.experlogixService.price(this.data.toXML()).pipe(
      catchError(()=>{
        this.loading = false;
        return EMPTY;
      })
    ).subscribe({
      next: (xml) => {
        this.loading = false
        const doc = parseFromXMLString(xml);
        const lineitem = doc.evaluate('lineitem', doc).iterateNext();
        this.lineItem.model = doc.evaluate('modeldesc', lineitem).iterateNext().textContent;
        this.lineItem.unitPrice = doc.evaluate('unitprice', lineitem).iterateNext().textContent;
        const calcs = doc.evaluate('category[@id="DoorElevation"]/option', lineitem).iterateNext();
        this.lineItem.subType = doc.evaluate('property[@id="SubType"]', calcs).iterateNext().textContent;
        this.lineItem.width = doc.evaluate('property[@id="Width"]', calcs).iterateNext().textContent;
        this.lineItem.height = doc.evaluate('property[@id="Height"]', calcs).iterateNext().textContent;
        this.lineItem.gauge = doc.evaluate('property[@id="Gauge"]', calcs).iterateNext().textContent;
        this.lineItem.core = doc.evaluate('property[@id="Core"]', calcs).iterateNext().textContent;
        this.lineItem.doors = this.getDoors(lineitem, doc);
        this.lineItem.cutouts = this.getCutouts(lineitem, doc);
        this.lineItem.catalogNumber = doc
          .evaluate('category[@id="S_Summary"]/option[@id="s_Summary"]/property[@id="S_CatalogNumber"]', lineitem)
          .iterateNext().textContent;
      },
    });
  }

  private getDoors(lineitem: Node, doc: Document) {
    const doors = doc.evaluate('category[@id="Door"]/option', lineitem);

    let door = doors.iterateNext();
    const result: DoorConfiguration[] = [];
    while (door) {
      result.push({
        unitPrice: doc.evaluate('property[@id="ExtPrice"]', door).iterateNext().textContent,
        basePrice: doc.evaluate('property[@id="BasePrice"]', door).iterateNext().textContent,
        coreAdder: doc.evaluate('property[@id="CoreAdder"]', door).iterateNext().textContent,
        steelStiffenAdder: doc.evaluate('property[@id="SteelStiffenAdder"]', door).iterateNext().textContent,
        materialAdder: doc.evaluate('property[@id="MaterialAdder"]', door).iterateNext().textContent,
        metalMylarAdder: doc.evaluate('property[@id="MetalMylarAdder"]', door).iterateNext().textContent,
        seamlessEdgeAdder: doc.evaluate('property[@id="SeamlessEdgeAdder"]', door).iterateNext().textContent,
        thicknessAdder: doc.evaluate('property[@id="ThicknessAdder"]', door).iterateNext().textContent,
        gaugeAdder: doc.evaluate('property[@id="GaugeAdder"]', door).iterateNext().textContent,
        finishPaintAdder: doc.evaluate('property[@id="FinishPaintAdder"]', door).iterateNext().textContent,
        hingePrepAdder: doc.evaluate('property[@id="HingePrepAdder"]', door).iterateNext().textContent,
        primaryLockPrepAdder: doc.evaluate('property[@id="PrimaryLockPrepAdder"]', door).iterateNext().textContent,
        deadlockPrepAdder: doc.evaluate('property[@id="DeadlockPrepAdder"]', door).iterateNext().textContent,
        secondaryLockPrepAdder: doc.evaluate('property[@id="SecondaryLockPrepAdder"]', door).iterateNext()
          .textContent,
        tertiaryLockPrepAdder: doc.evaluate('property[@id="TertiaryLockPrepAdder"]', door).iterateNext()
          .textContent,
        primaryStrikePrepAdder: doc.evaluate('property[@id="PrimaryStrikePrepAdder"]', door).iterateNext()
          .textContent,
        deadlockStrikePrepAdder: doc.evaluate('property[@id="DeadlockStrikePrepAdder"]', door).iterateNext()
          .textContent,
        secondaryStrikePrepAdder: doc.evaluate('property[@id="SecondaryStrikePrepAdder"]', door).iterateNext()
          .textContent,
        tertiaryStrikePrepAdder: doc.evaluate('property[@id="TertiaryStrikePrepAdder"]', door).iterateNext()
          .textContent,
        closerPrepAdder: doc.evaluate('property[@id="CloserPrepAdder"]', door).iterateNext().textContent,
        blankSurfaceHingePrepAdder: doc.evaluate('property[@id="BlankSurfaceHingePrepAdder"]', door).iterateNext()
          .textContent,
        anchorHingePrepAdder: doc.evaluate('property[@id="AnchorHingePrepAdder"]', door).iterateNext().textContent,
        topBottomPivotPrepAdder: doc.evaluate('property[@id="TopBottomPivotPrepAdder"]', door).iterateNext()
          .textContent,
        intPivotPrepAdder: doc.evaluate('property[@id="IntPivotPrepAdder"]', door).iterateNext().textContent,
        ohStopHolderPrepAdder: doc.evaluate('property[@id="OhStopHolderPrepAdder"]', door).iterateNext()
          .textContent,
        magSwitchPrepAdder: doc.evaluate('property[@id="MagSwitchPrepAdder"]', door).iterateNext().textContent,
        powerTransferPrepAdder: doc.evaluate('property[@id="PowerTransferPrepAdder"]', door).iterateNext()
          .textContent,
        rollerLatchPrepAdder: doc.evaluate('property[@id="RollerLatchPrepAdder"]', door).iterateNext().textContent,
        flushBoltPrepAdder: doc.evaluate('property[@id="flushBoltPrepAdder"]', door).iterateNext()
          .textContent,
        surfaceBoltPrepAdder: doc.evaluate('property[@id="SurfaceBoltPrepAdder"]', door).iterateNext().textContent,
        cutoutPrice: doc.evaluate('property[@id="CutoutPrice"]', door).iterateNext().textContent,
      });
      door = doors.iterateNext();
    }
    return result;
  }

  private getCutouts(lineitem: Node, doc: Document) {
    const doors = doc.evaluate('category[@id="Cutout"]/option', lineitem);
    let door = doors.iterateNext();
    const result: CutoutConfiguration[] = [];
    while (door) {
      result.push({
        type: doc.evaluate('property[@id="Type"]', door).iterateNext().textContent,
        unitPrice: doc.evaluate('property[@id="S_TotalPrice"]', door).iterateNext().textContent,
        louverBasePrice: doc.evaluate('property[@id="LouverBasePrice"]', door).iterateNext().textContent,
        glassBasePrice: doc.evaluate('property[@id="GlassBasePrice"]', door).iterateNext().textContent,
        muntinBarBasePrice: doc.evaluate('property[@id="MuntinBarBasePrice"]', door).iterateNext().textContent,
        materialAdder: doc.evaluate('property[@id="MaterialAdder"]', door).iterateNext().textContent,
        glassPocketAdder: doc.evaluate('property[@id="GlassPocketAdder"]', door).iterateNext().textContent,
      });
      door = doors.iterateNext();
    }
    return result;
  }
}
