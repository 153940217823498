import { uomSwitch } from '../../../../core/functions/uomSwitch';
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { FramePrepReferencePoint } from '../../../enums/frame-prep-reference-point';
import { StickSubtype } from '../../../enums/stick-subtype';
import { IElecPrep } from '../../../interfaces/preps/i-elec-prep';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';

export abstract class ElecPrepComponent extends PrepBaseComponent {
  subTypes: StickSubtype[] = [];
  codes: IPrepCode[];
  private _code: IPrepCode;
  public get code(): IPrepCode {
    return this._code;
  }
  public set code(value: IPrepCode) {
    this._code = value;
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    if (this.prepsComponent.data?.frame.prepLocationPreference && !this.location){
      this.location = this.locations.first(({value}) => value === this.prepsComponent.data.frame.prepLocationPreference);
    }
    this.updateValue();
  }
  private _quantity: number;
  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;
    if (this._quantity) {
      this._code = this.specialPrepCode;
    }
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    this.updateValue();
  }

  private _locations: IPrepCategoryLocation[];
  public get locations(): IPrepCategoryLocation[] {
    return this._locations;
  }
  public set locations(value: IPrepCategoryLocation[]) {
    this._locations = value;
  }
  private _location: IPrepCategoryLocation;
  get location(): IPrepCategoryLocation {
    return this._location;
  }
  set location(value: IPrepCategoryLocation) {
    this._location = value;
    if (!this.referencePoints.any(x => x === this._referencePoint)) {
      this._referencePoint = null;
    }
    this.updateValue();
  }

  get referencePoints(): FramePrepReferencePoint[] {
    return !this._location
      ? []
      : this._location?.value === 'Hinge' || this._location?.value === 'Strike'
      ? [
          FramePrepReferencePoint.FB,
          FramePrepReferencePoint.FCL,
          FramePrepReferencePoint.HCL,
          FramePrepReferencePoint.HT,
          FramePrepReferencePoint.TCL,
          FramePrepReferencePoint.TT,
        ]
      : [
          FramePrepReferencePoint.LCL,
          FramePrepReferencePoint.LL,
          FramePrepReferencePoint.RCL,
          FramePrepReferencePoint.RR,
        ];
  }

  protected _referencePoint: FramePrepReferencePoint;
  get referencePoint(): FramePrepReferencePoint {
    return this._referencePoint;
  }
  set referencePoint(value: FramePrepReferencePoint) {
    this._referencePoint = value;
    this.updateValue();
  }
  readonly junctionBoxes: string[] = ['14" Box', '10" Box'];
  private _junctionBox: string;
  public get junctionBox(): string {
    return this._junctionBox;
  }
  public set junctionBox(value: string) {
    this._junctionBox = value;
    this.updateValue();
  }

  abstract prep: IElecPrep;

  constructor(prepsComponent: PrepsComponent, prepCategoryId: number, public name: string) {
    super(prepsComponent, prepCategoryId);
  }

  protected init(): void {
    if (!this.prep) {
      this.prep = {
        id: this.specialPrepCode.id,
        code: this.specialPrepCode.code,
      } as IElecPrep;
    }
    this._quantity = this.prep.quantity ?? null;
    this._code = this.specialPrepCode;
    this.locations = [{ value: 'Strike' }, { value: 'Hinge' }, { value: 'Header' }] as IPrepCategoryLocation[];
    this._location = this.locations.first(c => c.value === this.prep.locationType) ?? null;
    this._junctionBox = this.junctionBoxes.first(c => c === this.prep.junctionBox) ?? null;
    this.setSpecial();
    this.setSubTypes();
  }

  setSubTypes() {
    this.subTypes = !this._location
      ? []
      : this._location?.value === 'Hinge'
      ? [StickSubtype.Hinge, StickSubtype.HingeHinge, StickSubtype.StrikeHinge]
      : this._location?.value === 'Strike'
      ? [StickSubtype.Strike, StickSubtype.StrikeStrike, StickSubtype.StrikeHinge]
      : [StickSubtype.Head];
  }

  updateValue(): void {
    this.setSubTypes();
    if (!this.quantity) {
      this.prep = {} as IElecPrep;
      this._location = null;
      this._code = null;
      this._junctionBox = null;
      return this.draw$.next();
    }
    if (!this.code) {
      this._location = null;
      return this.draw$.next();
    }
    this.prep.id = this.code?.id;
    this.prep.code = this.code?.code;
    this.prep.pricingCategory = this.code?.pricingCategory;
    this.prep.locationType = this.location?.value;
    this.prep.fixedLocation = this.code?.fixedLocation;
    this.prep.referencePoint = this.referencePoint;
    this.prep.junctionBox = this.junctionBox;
    if (!(this.prep.location instanceof Array)) {
      this.prep.location = [];
    }
    const locations = (this.prep.location as string[]).take(this.quantity);
    this.prep.location = locations.concat(
      new Array(this.quantity - locations.length).fill(
        uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure),
        0,
        this.quantity - locations.length
      )
    );
    this.prep.quantity = this.quantity;
    this.draw$.next();
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (!this.prep.quantity || !this.prep.referencePoint) {
      return;
    }
    for (let i = 0; i < this.quantity; i++) {
      this.prepLocationInputs.push(this.drawPrep(i, this.prep));
    }
  }
}
