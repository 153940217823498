import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import '@ornstio/ts-linq';
import { CoreModule } from '../core/core.module';
import { PrepCategoryService } from '../core/services/door-prep-category.service';
import { DoorPrepCodeService } from '../core/services/door-prep-code.service';
import { DoorPrepService } from '../core/services/door-prep.service';
import { ProductService } from '../core/services/door-product.service';
import { DoorRuleService } from '../core/services/door-rule.service';
import { PreferenceService } from '../core/services/preference.service';
import { DoorInfoComponent } from './components/drawing-pad/door-info/door-info.component';
import { DrawingPadComponent } from './components/drawing-pad/drawing-pad.component';
import { ConfigurationComponent } from './dialogs/configuration/configuration.component';
import { ConfirmDialogComponent } from './dialogs/confirm/confirm.component';
import { WarningDialogComponent } from './dialogs/confirm/warning.component';
import { DoorElevationModeSettingsComponent } from './dialogs/door-elevation-settings/door-elevation-mode-settings/door-elevation-mode-settings.component';
import { DoorElevationPairSettingsComponent } from './dialogs/door-elevation-settings/door-elevation-pair-settings/door-elevation-pair-settings.component';
import { DoorElevationSettingsComponent } from './dialogs/door-elevation-settings/door-elevation-settings.component';
import { DoorElevationSingleSettingsComponent } from './dialogs/door-elevation-settings/door-elevation-single-settings/door-elevation-single-settings.component';
import { BlankHingePrepComponent } from './dialogs/preps/blank-hinge-prep/blank-hinge-prep.component';
import { CloserPrepComponent } from './dialogs/preps/closer-prep/closer-prep.component';
import { DoorPrepComponent } from './dialogs/preps/door-prep/door-prep.component';
import { FlushBoltPrepComponent } from './dialogs/preps/flush-bolt-prep/flush-bolt-prep.component';
import { HingePrepComponent } from './dialogs/preps/hinge-prep/hinge-prep.component';
import { IntPivotPrepComponent } from './dialogs/preps/int-pivot-prep/int-pivot-prep.component';
import { DeadlockPrepComponent } from './dialogs/preps/lock-prep/deadlock-prep.component';
import { PrimaryLockPrepComponent } from './dialogs/preps/lock-prep/primary-lock-prep.component';
import { SecondaryLockPrepComponent } from './dialogs/preps/lock-prep/secondary-lock-prep.component';
import { TertiaryLockPrepComponent } from './dialogs/preps/lock-prep/tertiary-lock-prep.component';
import { OhStopHolderPrepComponent } from './dialogs/preps/oh-stop-holder-prep/oh-stop-holder-prep.component';
import { PrepsDialogComponent } from './dialogs/preps/preps-dialog.component';
import { PrepsComponent } from './dialogs/preps/preps.component';
import { AnchorHingePrepComponent } from './dialogs/preps/special-prep/anchor-hinge-prep.component';
import { MagSwitchPrepComponent } from './dialogs/preps/special-prep/mag-switch-prep.component';
import { PowerTransferPrepComponent } from './dialogs/preps/special-prep/power-transfer-prep.component';
import { RollerLatchPrepComponent } from './dialogs/preps/special-prep/roller-latch-prep.component';
import { TopBottomPivotPrepComponent } from './dialogs/preps/special-prep/top-bottom-pivot-prep.component';
import { DeadlockStrikePrepComponent } from './dialogs/preps/strike-prep/deadlock-strike-prep.component';
import { PrimaryStrikePrepComponent } from './dialogs/preps/strike-prep/primary-strike-prep.component';
import { SecondaryStrikePrepComponent } from './dialogs/preps/strike-prep/secondary-strike-prep.component';
import { TertiaryStrikePrepComponent } from './dialogs/preps/strike-prep/tertiary-strike-prep.component';
import { SurfaceBoltComponent } from './dialogs/preps/surface-bolt/surface-bolt.component';
import { PrimaryViewerPrepComponent } from './dialogs/preps/viewer/primary-viewer-prep.component';
import { SecondaryViewerPrepComponent } from './dialogs/preps/viewer/secondary-viewer-prep.component';
import { DoorElevationComponent } from './door-elevation.component';
import { DutchDoorShelvesPrepComponent } from './dialogs/preps/dutch-door-shelves/dutch-door-shelves-prep.component';
@NgModule({
  declarations: [
    AnchorHingePrepComponent,
    BlankHingePrepComponent,
    CloserPrepComponent,
    ConfigurationComponent,
    ConfirmDialogComponent,
    DeadlockPrepComponent,
    DeadlockStrikePrepComponent,
    DoorElevationComponent,
    DoorElevationModeSettingsComponent,
    DoorElevationPairSettingsComponent,
    DoorElevationSettingsComponent,
    DoorElevationSingleSettingsComponent,
    DoorInfoComponent,
    DoorPrepComponent,
    DutchDoorShelvesPrepComponent,
    DrawingPadComponent,
    HingePrepComponent,
    IntPivotPrepComponent,
    MagSwitchPrepComponent,
    OhStopHolderPrepComponent,
    PowerTransferPrepComponent,
    PrepsComponent,
    PrepsDialogComponent,
    PrimaryLockPrepComponent,
    PrimaryStrikePrepComponent,
    PrimaryViewerPrepComponent,
    RollerLatchPrepComponent,
    SecondaryLockPrepComponent,
    SecondaryStrikePrepComponent,
    SecondaryViewerPrepComponent,
    SurfaceBoltComponent,
    TertiaryLockPrepComponent,
    TertiaryStrikePrepComponent,
    TopBottomPivotPrepComponent,
    FlushBoltPrepComponent,
    WarningDialogComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CoreModule ],
  providers: [
    PreferenceService,
    DoorRuleService,
    PrepCategoryService,
    DoorPrepCodeService,
    DoorPrepService,
    ProductService,
  ],
  exports: [DoorElevationComponent],
})
export class DoorElevationModule {}
