import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core'
import { IPrep } from '../../../../core/interfaces/i-prep'
import { DoorPrepReferencePoint } from '../../../enums/door-prep-reference-point'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

@Component({
  selector: 'lib-top-bottom-pivot-prep',
  templateUrl: './special-prep.component.html',
  styleUrls: ['../prep-base.component.scss']
})
export class TopBottomPivotPrepComponent extends PrepBaseComponent implements OnInit {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent

  code = this.specialPrepCode
  set prep(value: IPrep) {
    this.prepsComponent.configService.currentDoor.topBottomPivotPrep = value
  }
  get prep(): IPrep {
    return this.prepsComponent.configService.currentDoor.topBottomPivotPrep
  }
  referencePoints: DoorPrepReferencePoint[]
  referencePoint: DoorPrepReferencePoint

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.TOP_BOTTOM_PIVOT)
    if (!this.prep) {
      this.prep = {
        id: this.specialPrepCode.id,
        code: this.specialPrepCode.code
      } as IPrep
    }
    this.setSpecial()
  }

  ngOnInit(): void {}
}
