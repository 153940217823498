import { Component, forwardRef, Inject, OnInit } from '@angular/core'
import { IPrep } from '../../../../core/interfaces/i-prep'
import { IPrepCode } from '../../../../core/interfaces/i-prep-code'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

@Component({
  selector: 'lib-closer-prep',
  templateUrl: './closer-prep.component.html',
  styleUrls: ['./closer-prep.component.scss', '../prep-base.component.scss']
})
export class CloserPrepComponent extends PrepBaseComponent implements OnInit {
  doorPrepComponent: DoorPrepComponent
  public get code(): IPrepCode {
    return this.prep?.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  codes: IPrepCode[]
  get prep(): IPrep {
    return this.prepsComponent.configService.currentDoor.closerPrep
  }
  set prep(value: IPrep) {
    this.prepsComponent.configService.currentDoor.closerPrep = value
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.CLOSER)
  }

  ngOnInit(): void {
    if (!this.prep) {
      this.prep = {} as IPrep
    }
    if (!this.prep.code) {
      this.setPrepCode(this.getDisplayValue(
        this.codes.find(
          ({ code, description }) =>
            code.trim().searchFor('CR', true) && description.trim().searchFor('CLOSER REINF', true)
        )
      ))
    }
    this.setSpecial()
  }
}
