import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlsOf, getEnumValues, nameOf } from '@oeo/common';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { PrepShape } from '../../core/enums/prep-shape';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { IPrepCategory } from '../../core/interfaces/i-prep-category';
import { IPrepCategoryCodeService } from '../../core/interfaces/i-prep-category-code.service';
import { IPrepCode } from '../../core/interfaces/i-prep-code';
import { RestService } from '../../core/services/rest.service';
import {
  isNotZeroValidator,
  unitOfMeasureValidator
} from '../../core/validators/measurementValidator';

@Component({
  selector: 'lib-prep',
  templateUrl: './prep.component.html',
  styleUrls: ['./prep.component.scss'],
})
export class PrepComponent implements OnInit {
  private _value: IPrepCode;
  get value(): IPrepCode {
    return this._value;
  }
  @Input() set value(value: IPrepCode) {
    this._value = value;
    Object.keys(this.form.controls).forEach(key => {
      this.form.get(key).patchValue(this.value[key as keyof IPrepCode]);
    });
    this.categories = this.value.categories.map(c => Object.assign({}, c));
  }

  @Input() codeService: RestService<IPrepCode>;
  @Input() categoryCodeService: IPrepCategoryCodeService;
  @Input() pricingCategories: string[];
  @Output() value$ = new EventEmitter<IPrepCode>();
  @Output() loading$ = new EventEmitter<boolean>(false);
  categories: IPrepCategory[];

  form: FormGroup<ControlsOf<Partial<IPrepCode>>> = new FormGroup<ControlsOf<Partial<IPrepCode>>>({
    [nameOf((_: IPrepCode) => _.code)]: new FormControl<string>(null, [Validators.required]),
    [nameOf((_: IPrepCode) => _.description)]: new FormControl<string>(null, []),
    [nameOf((_: IPrepCode) => _.standardLocation)]: new FormControl<string>(null, [
      unitOfMeasureValidator(UnitOfMeasure.Imperial),
      isNotZeroValidator(UnitOfMeasure.Imperial),
    ]),
    [nameOf((_: IPrepCode) => _.fixedLocation)]: new FormControl<boolean>(false, []),
    [nameOf((_: IPrepCode) => _.pricingCategory)]: new FormControl<string>(null, []),
    [nameOf((_: IPrepCode) => _.shape)]: new FormControl<PrepShape>(null, []),
    [nameOf((_: IPrepCode) => _.height)]: new FormControl<string>(null, [
      unitOfMeasureValidator(UnitOfMeasure.Imperial),
      isNotZeroValidator(UnitOfMeasure.Imperial),
    ]),
    [nameOf((_: IPrepCode) => _.width)]: new FormControl<string>(null, [
      unitOfMeasureValidator(UnitOfMeasure.Imperial),
      isNotZeroValidator(UnitOfMeasure.Imperial),
    ]),
    [nameOf((_: IPrepCode) => _.isSteelcraft)]: new FormControl<boolean>(false),
    [nameOf((_: IPrepCode) => _.isRepublic)]: new FormControl<boolean>(false),
  });

  get code() {
    return this.form.controls.code
  }

  get description() {
    return this.form.controls.description;
  }

  get standardLocation() {
    return this.form.controls.standardLocation;
  }

  get fixedLocation() {
    return this.form.controls.fixedLocation;
  }

  get shape() {
    return this.form.controls.shape;
  }

  get height() {
    return this.form.controls.height;
  }

  get width() {
    return this.form.controls.width;
  }

  get shapes() {
    return getEnumValues(PrepShape);
  }

  get pricingCategory() {
    return this.form.controls.pricingCategory;
  }

  get isSteelcraft() {
    return this.form.controls.isSteelcraft;
  }

  get isRepublic() {
    return this.form.controls.isRepublic;
  }

  ngOnInit(): void {}

  save() {
    this.loading$.emit(true);
    const prepCodeObservable =
      this.value.id === null
        ? this.codeService.create(this.form.value).pipe(map(v => v.id))
        : this.codeService.update(this.value.id, this.form.value).pipe(map(() => this.value.id));
    prepCodeObservable
      .pipe(
        mergeMap(id => {
          const categories = this.categories.filter(
            c => this.value.categories.first(x => x.id === c.id).checked !== c.checked
          );
          return (
            categories.any()
              ? forkJoin(
                  categories.map(c =>
                    c.checked ? this.categoryCodeService.create(c.id, id) : this.categoryCodeService.delete(c.id, id)
                  )
                )
              : of(null)
          ).pipe(map(() => id));
        })
      )
      .subscribe(id => {
        Object.assign(this.value, this.form.value);
        Object.assign(this.value.categories, this.categories);
        if (!this.value.id) {
          this.value.id = id;
          this.value$.emit(this.value);
        }
        this.loading$.emit(false);
      });
  }

  delete(): void {
    this.loading$.emit(true);
    this.codeService.delete(this.value.id).subscribe(() => {
      this.value$.emit(this.value);
      this.loading$.emit(false);
    });
  }
}
