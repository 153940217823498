import { ChangeDetectorRef, Component, forwardRef } from '@angular/core'
import { NG_VALUE_ACCESSOR } from '@angular/forms'
import { BasePrepsComponent, PrepStep } from '../../../core/components/base-preps/base-preps.component'
import { DialogService } from '../../../core/services/dialog.service'
import { ProductService } from '../../../core/services/door-product.service'
import { DoorSeries } from '../../enums/door-series'
import { DoorSubType } from '../../enums/door-subtype'
import { Door } from '../../models/door'
import { DoorElevationConfigService } from '../../services/door-elevation-config-service/door-elevation-config.service'
import { BlankHingePrepComponent } from './blank-hinge-prep/blank-hinge-prep.component'
import { CloserPrepComponent } from './closer-prep/closer-prep.component'
import { FlushBoltPrepComponent } from './flush-bolt-prep/flush-bolt-prep.component'
import { HingePrepComponent } from './hinge-prep/hinge-prep.component'
import { IntPivotPrepComponent } from './int-pivot-prep/int-pivot-prep.component'
import { DeadlockPrepComponent } from './lock-prep/deadlock-prep.component'
import { PrimaryLockPrepComponent } from './lock-prep/primary-lock-prep.component'
import { SecondaryLockPrepComponent } from './lock-prep/secondary-lock-prep.component'
import { TertiaryLockPrepComponent } from './lock-prep/tertiary-lock-prep.component'
import { OhStopHolderPrepComponent } from './oh-stop-holder-prep/oh-stop-holder-prep.component'
import { AnchorHingePrepComponent } from './special-prep/anchor-hinge-prep.component'
import { MagSwitchPrepComponent } from './special-prep/mag-switch-prep.component'
import { PowerTransferPrepComponent } from './special-prep/power-transfer-prep.component'
import { RollerLatchPrepComponent } from './special-prep/roller-latch-prep.component'
import { TopBottomPivotPrepComponent } from './special-prep/top-bottom-pivot-prep.component'
import { DeadlockStrikePrepComponent } from './strike-prep/deadlock-strike-prep.component'
import { PrimaryStrikePrepComponent } from './strike-prep/primary-strike-prep.component'
import { SecondaryStrikePrepComponent } from './strike-prep/secondary-strike-prep.component'
import { TertiaryStrikePrepComponent } from './strike-prep/tertiary-strike-prep.component'
import { SurfaceBoltComponent } from './surface-bolt/surface-bolt.component'
import { PrimaryViewerPrepComponent } from './viewer/primary-viewer-prep.component'
import { SecondaryViewerPrepComponent } from './viewer/secondary-viewer-prep.component'
import { DoorPrepTypes } from '../../interfaces/preps'
import { nameOf } from '@oeo/common'
import { DutchStyles } from '../../enums/door-style'
import { DutchDoorShelvesPrepComponent } from './dutch-door-shelves/dutch-door-shelves-prep.component'

@Component({
  selector: 'lib-door-preps-component',
  templateUrl: './preps.component.html',
  styleUrls: ['./preps.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrepsComponent),
      multi: true
    }
  ]
})
export class PrepsComponent extends BasePrepsComponent<Door> {
  data: {}

  get unitOfMeasure() {
    return this.configService.currentDoor.doorElevation.unitOfMeasure
  }

  constructor(
    cd: ChangeDetectorRef,
    dialogService: DialogService,
    productService: ProductService,
    public configService: DoorElevationConfigService
  ) {
    super(cd, dialogService, productService)
  }

  setUpPrepSteps() {
    this.prepSteps = [
      {
        name: 'Flush Bolt',
        component: FlushBoltPrepComponent,
        checked: !!this.configService.currentDoor.flushBoltPrep,
        prepName: nameOf((_: Door) => _.flushBoltPrep),
        order: 1,
        active: false,
        disabled: false
      },
      {
        name: 'Primary Lock',
        component: PrimaryLockPrepComponent,
        checked: true,
        prepName: nameOf((_: Door) => _.primaryLockPrep),
        order: 3,
        active: true,
        disabled: true
      },
      {
        name: 'Deadlock',
        component: DeadlockPrepComponent,
        checked: !!this.configService.currentDoor.deadlockPrep,
        prepName: nameOf((_: Door) => _.deadlockPrep),
        order: 4,
        active: true,
        disabled: false
      },
      {
        name: 'Secondary Lock',
        component: SecondaryLockPrepComponent,
        checked: !!this.configService.currentDoor.secondaryLockPrep,
        prepName: nameOf((_: Door) => _.secondaryLockPrep),
        order: 5,
        active: true,
        disabled: false
      },
      {
        name: 'Tertiary Lock',
        component: TertiaryLockPrepComponent,
        checked: !!this.configService.currentDoor.tertiaryLockPrep,
        prepName: nameOf((_: Door) => _.tertiaryLockPrep),
        order: 6,
        active: true,
        disabled: false
      },
      {
        name: 'Primary Strike',
        component: PrimaryStrikePrepComponent,
        checked: true,
        prepName: nameOf((_: Door) => _.primaryStrikePrep),
        order: 7,
        active: false,
        disabled: true
      },
      {
        name: 'Secondary Strike',
        component: SecondaryStrikePrepComponent,
        checked: !!this.configService.currentDoor.secondaryStrikePrep,
        prepName: nameOf((_: Door) => _.secondaryStrikePrep),
        order: 8,
        active: false,
        disabled: false
      },
      {
        name: 'Deadlock Strike',
        component: DeadlockStrikePrepComponent,
        checked: !!this.configService.currentDoor.deadlockStrikePrep,
        prepName: nameOf((_: Door) => _.deadlockStrikePrep),
        order: 9,
        active: false,
        disabled: false
      },
      {
        name: 'Tertiary Strike',
        component: TertiaryStrikePrepComponent,
        checked: !!this.configService.currentDoor.tertiaryStrikePrep,
        prepName: nameOf((_: Door) => _.tertiaryStrikePrep),
        order: 10,
        active: false,
        disabled: false
      },
      {
        name: 'Hinge',
        component: HingePrepComponent,
        checked: true,
        prepName: nameOf((_: Door) => _.hingePrep),
        order: 11,
        active: null,
        disabled: true
      },
      {
        name: 'Blank/Surface Hinge',
        component: BlankHingePrepComponent,
        checked: !!this.configService.currentDoor.blankSurfaceHingePrep,
        prepName: nameOf((_: Door) => _.blankSurfaceHingePrep),
        order: 12,
        active: null,
        disabled: false
      },
      {
        name: 'Anchor Hinge',
        component: AnchorHingePrepComponent,
        checked: !!this.configService.currentDoor.anchorHingePrep,
        prepName: nameOf((_: Door) => _.anchorHingePrep),

        order: 15,
        active: null,
        disabled: false
      },
      {
        name: 'Top/Bottom Pivot',
        component: TopBottomPivotPrepComponent,
        checked: !!this.configService.currentDoor.topBottomPivotPrep,
        prepName: nameOf((_: Door) => _.topBottomPivotPrep),

        order: 16,
        active: null,
        disabled: false
      },
      {
        name: 'Closer',
        component: CloserPrepComponent,
        checked: !!this.configService.currentDoor.closerPrep,
        prepName: nameOf((_: Door) => _.closerPrep),
        order: 18,
        active: null,
        disabled: false
      },
      {
        name: 'OH Stop/Holder',
        component: OhStopHolderPrepComponent,
        checked: !!this.configService.currentDoor.ohStopHolderPrep,
        prepName: nameOf((_: Door) => _.ohStopHolderPrep),
        order: 19,
        active: null,
        disabled: false
      },
      {
        name: 'Mag Switch',
        component: MagSwitchPrepComponent,
        checked: !!this.configService.currentDoor.magSwitchPrep,
        prepName: nameOf((_: Door) => _.magSwitchPrep),

        order: 20,
        active: null,
        disabled: false
      },
      {
        name: 'Power Transfer',
        component: PowerTransferPrepComponent,
        checked: !!this.configService.currentDoor.powerTransferPrep,
        prepName: nameOf((_: Door) => _.powerTransferPrep),
        order: 21,
        active: null,
        disabled: false
      },
      {
        name: 'Roller Latch',
        component: RollerLatchPrepComponent,
        checked: !!this.configService.currentDoor.rollerLatchPrep,
        prepName: nameOf((_: Door) => _.rollerLatchPrep),
        order: 22,
        active: null,
        disabled: false
      },
      ...((): Array<PrepStep<DoorPrepTypes>> => {
        if (!this.configService.currentDoor.active)
          return [
            {
              name: 'Surface Bolt',
              component: SurfaceBoltComponent,
              checked: !!this.configService.currentDoor.surfaceBoltPrep,
              order: 23,
              prepName: nameOf((_: Door) => _.surfaceBoltPrep),
              active: null,
              disabled: false
            }
          ]
        else return []
      })(),
      ...((): Array<PrepStep<DoorPrepTypes>> => {
        /* Hide Primary and Secondary Viewer Prep for STC & STC (Embossed Doors) */
        if (
          [DoorSubType.STC, DoorSubType.STCEMB].any(
            (doorSubType) => doorSubType === this.configService.doorElevation.subType
          )
        ) {
          return []
        }
        return [
          {
            name: 'Primary Viewer',
            component: PrimaryViewerPrepComponent,
            checked: !!this.configService.currentDoor.primaryViewerPrep,
            prepName: nameOf((_: Door) => _.primaryViewerPrep),
            order: 24,
            active: null,
            disabled: false
          },
          {
            name: 'Secondary Viewer',
            component: SecondaryViewerPrepComponent,
            checked: !!this.configService.currentDoor.secondaryViewerPrep,
            prepName: nameOf((_: Door) => _.secondaryViewerPrep),
            order: 25,
            active: null,
            disabled: false
          }
        ]
      })(),
      ...((): Array<PrepStep<DoorPrepTypes>> => {
        if (
          DutchStyles.any((doorStyle) => doorStyle === this.configService.currentDoor.style)
        ) {
          return [
            {
              name: 'Dutch Door Shelves',
              component: DutchDoorShelvesPrepComponent,
              checked: !!this.configService.currentDoor.secondaryViewerPrep,
              prepName: nameOf((_: Door) => _.dutchDoorShelvesPrep),
              order: 25,
              active: null,
              disabled: false
            }
          ]
        }
        else return []
      })(),
    ]
      .filter((x) => x.active == null || x.active === !!this.configService.currentDoor.active)
      .filter((x) => DPSeriesPrepfilter(x)(this.configService.doorElevation.series))
      .orderBy((x) => x.order)
  }

  removePrep(prepName: DoorPrepTypes) {
    this.configService.removePrep(prepName)
  }

  updateHingePrepSelection(blankSurfaceHingeChecked: boolean) {
    const hingePrepStepIndex = this.prepSteps.findIndex(({ name }) => name === 'Hinge')
    if (blankSurfaceHingeChecked){
      this.prepSteps[hingePrepStepIndex] = {...this.prepSteps[hingePrepStepIndex], disabled: false}
    }
    else {
      this.prepSteps[hingePrepStepIndex] = {...this.prepSteps[hingePrepStepIndex], checked: true, disabled: true}
      if (!this.steps.find(({name})=>name === 'Hinge')){
        this.modifySteps({...this.prepSteps.find(({name})=>name === 'Hinge'), checked: true})
      }
    }
  }
}

/**
 * All the prep types - for type safety
 */
export type DoorPrepComponentType =
  | AnchorHingePrepComponent
  | BlankHingePrepComponent
  | CloserPrepComponent
  | DeadlockPrepComponent
  | DeadlockStrikePrepComponent
  | DutchDoorShelvesPrepComponent
  | IntPivotPrepComponent
  | MagSwitchPrepComponent
  | OhStopHolderPrepComponent
  | PowerTransferPrepComponent
  | PrimaryLockPrepComponent
  | PrimaryStrikePrepComponent
  | PrimaryViewerPrepComponent
  | RollerLatchPrepComponent
  | SecondaryLockPrepComponent
  | SecondaryStrikePrepComponent
  | SecondaryViewerPrepComponent
  | SurfaceBoltComponent
  | TertiaryLockPrepComponent
  | TertiaryStrikePrepComponent
  | TopBottomPivotPrepComponent
  | FlushBoltPrepComponent

/**
 * Filters the preps based on the Door Series
 * FIXME: This is a temporary working solution that needs to be looked into later
 * @param prepStep
 * @returns valid prep steps
 */
const DPSeriesPrepfilter = (prepStep: PrepStep<DoorPrepTypes>) => (series: DoorSeries) => {
  if (series == DoorSeries.DP) {
    return [
      'Primary Lock',
      'Secondary Lock',
      'Tertiary Lock',
      'Primary Strike',
      'Secondary Strike',
      'Tertiary Strike',
      'Closer',
      'Hinge',
      'Flush Bolt'
    ].any((prep) => prepStep.name === prep)
  }
  return true
}
