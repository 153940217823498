import { RepositionScrollStrategy, Overlay} from "@angular/cdk/overlay";

/**
 * This automatically repositions the Overlay when a user scrolls outside the overlay
 * This is an issue that occurs when a user scrolls down the container and the autocomplete overlay doesn't get repositioned
 *
 * [Refer to this stack overflow question and solution](https://stackoverflow.com/questions/63223304/angular-material-autocomplete-control-once-opened-is-not-getting-closed-for-exte)
 *
 * **Note** the `cdkScrollable` directive has to be applied to the container that scrolls, otherwise, the scroll won't get picked up
 *
 * @param overlay
 * @returns
 */
export function scrollFactory(overlay: Overlay): () => RepositionScrollStrategy {
  return () => overlay.scrollStrategies.reposition()
}
