<div class="host">
  <div *ngIf="isCustomizing && service.elevation">
    <lib-drawing-pad
      #drawingPad
      [frameElevation]="service.elevation"
      [tools]="tools"
      [tool]="tool"
      (tool$)="tool = $event"
    ></lib-drawing-pad>
    <lib-stick-info></lib-stick-info>
    <lib-glass-info></lib-glass-info>
    <lib-door-info></lib-door-info>
    <lib-help *ngIf="help" (close$)="help = false"></lib-help>
  </div>
  <div *ngIf="!isCustomizing && service?.elevation?.template">
    <lib-template-designer [template]="service.elevation.template"></lib-template-designer>
  </div>
  <lib-tool-bar
    [tools]="tools"
    [tool]="tool"
    (tool$)="setTool($event)"
    (help$)="help = true"
    (edit$)="customize()"
    (settings$)="openSettings()"
    (money$)="money()"
    [help]="isCustomizing"
    [edit]="!isCustomizing"
  ></lib-tool-bar>
</div>
<div libDialog></div>
