import { AbstractControl, ValidatorFn } from '@angular/forms';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { uomSwitch } from '../../core/functions/uomSwitch';
import { DoorSeries } from '../../door-elevation/enums/door-series';
import { DoorSubType } from '../../door-elevation/enums/door-subtype';
import { DoorBaseData } from '../../door-elevation/rules/door-base-data';

export function dpWidthValidator(series: DoorSeries, subType: DoorSubType, unitOfMeasure: UnitOfMeasure): ValidatorFn {
  return (control: AbstractControl): { [key: string]: string } | null => {
    if (series !== DoorSeries.DP) {
      return null;
    }
    const widths = new DoorBaseData()
      .get(series, subType, unitOfMeasure)
      .map((width: string) => uomSwitch(width, 'door', unitOfMeasure));
    return !widths.any(w => w === (control.value as string)?.fromDimension('door', unitOfMeasure))
      ? { dp: 'Width not valid for DP' }
      : null;
  };
}

export function dpHeightValidator(
  series: DoorSeries,
  subType: DoorSubType,
  width: string,
  unitOfMeasure: UnitOfMeasure
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: string } | null => {
    if (series !== DoorSeries.DP || !width) {
      return null;
    }
    const heights = new DoorBaseData()
      .get(series, subType, unitOfMeasure, width)
      .map((height: string) => uomSwitch(height, 'door', unitOfMeasure));
    return !heights.any((h: number) => h === (control.value as string)?.fromDimension('door', unitOfMeasure))
      ? { dp: 'Height not valid for DP' }
      : null;
  };
}
