export type InputType = string | { value?: string } | { code: string; description: string }

export type TransformFunction<T> = (input: T) => string
import capitalize from 'just-capitalize'

function none<T extends string>(val: T): string {
  return val
}

function preps<T extends { code: string; description: string }>(code: T) {
  if (!code) return ''
  return `${code.code} ${code.description ? '(' + code.description + ')' : ''}`
}

function value<T extends { value?: string }>(input: T) {
  if (!input) return ''
  return input.value ? input.value : ''
}

function customerAccount<T extends { customerName?: string; primaryCity?: string; primaryState?: string }>(account: T) {
  if (!account) return ''
  return `${capitalize(account?.customerName ?? '')} - ${capitalize(account?.primaryCity ?? '')}${
    account?.primaryState !== 'Not Available' ? ', ' + account?.primaryState?.toUpperCase() : ''
  }`
}

export const transformFunctions = {
  none,
  preps,
  value,
  customerAccount
} as Record<string, TransformFunction<InputType>>

export type InputTransformFn = keyof typeof transformFunctions
