import { Component, inject } from '@angular/core'
import { AuthService } from '../../../services/auth.service'
import { environment } from '../../../../environments/environment'
import { TranslateService } from '@ngx-translate/core'
import { FlagsService } from '../../../services/flags/flags.service'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { ThirdPartyLicensesComponent } from '../third-party-licenses/third-party-licenses.component'
import { StorageService } from '../../../services/local-storage/local-storage.service'
import { CustomerService } from '../../../services/customer/customer.service'

@Component({
  selector: 'oa-user-options-dialog',
  templateUrl: './user-options-dialog.component.html',
  styleUrls: ['./user-options-dialog.component.scss']
})
export class UserOptionsDialogComponent {
  authService = inject(AuthService)
  translateSvc = inject(TranslateService)
  flags$ = inject(FlagsService).flags$
  dialogRef = inject(MatDialogRef<UserOptionsDialogComponent>)
  router = inject(Router)
  dialog = inject(MatDialog)
  storageService = inject(StorageService)
  customerSvc = inject(CustomerService)

  languages = [
    ...[
      { name: 'English', code: 'en' },
      { name: 'Français', code: 'fr' }
    ],
    ...(environment.featureFlags.enableSpanish ? [{ name: 'Español', code: 'es' }] : [])
  ] as const

  multiBranchEnabled = environment.featureFlags.enableMultiBranch

  changeLanguage(languageCode: 'en' | 'fr' | 'es') {
    this.translateSvc.use(languageCode)
    this.storageService.setItem('i18n', languageCode)
    window.location.reload()
  }

  openThirdPartyLicenses() {
    this.dialog.open(ThirdPartyLicensesComponent, { disableClose: false, maxHeight: '80vh', maxWidth: '50vw' })
  }
}
