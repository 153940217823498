import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { Profile } from '../../abstracts/profile';
import { ProfileTemplate, ProfileTemplateDimensionPosition } from '../../abstracts/profile-template';
import { Direction } from '../../enums/direction';
import { GlazingBeadLocation } from '../../enums/glazing-bead-location';
import { GlassExport } from '../../models/exports/glass-export';

export class MUDRCS extends ProfileTemplate {
  private _a: number;
  private _b: number;
  private _d: number;
  private _e: number;
  private _h: number;
  private _j: number;

  get stopHeight1() {
    return this.dimensions.A.get();
  }
  get stopHeight2() {
    return this.dimensions.E.get();
  }
  get backBend1() {
    return NaN;
  }
  get backBend2() {
    return NaN;
  }
  get doorSideRabbet1() {
    return this._h;
  }
  get oppositeDoorSideRabbet1() {
    return this._d;
  }
  get doorSideRabbet2() {
    return this._j;
  }
  get oppositeDoorSideRabbet2() {
    return this._b;
  }

  readonly dimensions = {
    A: {
      direction: Direction.Right,
      get: () => this._a,
      set: (value: number) => {
        this._a = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    B: {
      direction: Direction.Up,
      get: () =>
        !!this.glazingBeads[1] && this.glassPosition === GlazingBeadLocation.CenterGlazed
          ? this.centerGlazingLength
          : this._b,
      set:
        !!this.glazingBeads[1] && this.glassPosition === GlazingBeadLocation.CenterGlazed
          ? null
          : (value: number) => {
              this._b = value;
              this.update();
            },
      position: () => ProfileTemplateDimensionPosition.MinX,
      glazingBead:
        !!this.glazingBeads[1] && this.glassPosition !== GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Left,
              start: () => ({
                x: 0,
                y: -this.glassThickness,
              }),
            }
          : null,
    },
    C: {
      direction: Direction.Right,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    D: {
      direction: Direction.Down,
      get: () =>
        !!this.glazingBeads[0] && this.glassPosition === GlazingBeadLocation.CenterGlazed
          ? this.centerGlazingLength
          : this._d,
      set:
        !!this.glazingBeads[0] && this.glassPosition === GlazingBeadLocation.CenterGlazed
          ? null
          : (value: number) => {
              this._d = value;
              this.update();
            },
      position: () => ProfileTemplateDimensionPosition.MaxX,
      glazingBead:
        !!this.glazingBeads[0] && this.glassPosition !== GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Right,
              start: () => ({
                x: 0,
                y: this.dimensions.D.get() - this._glazingBeadWidth - this.glassThickness,
              }),
            }
          : null,
    },
    E: {
      direction: Direction.Right,
      get: () => this._e,
      set: (value: number) => {
        this._e = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    F: {
      direction: Direction.Down,
      get: () => this.profile.jambDepth - this.dimensions.D.get() - this.dimensions.H.get(),
      position: () => ProfileTemplateDimensionPosition.MaxX,
    },
    G: {
      direction: Direction.Left,
      get: () => this.dimensions.E.get(),
      position: () => ProfileTemplateDimensionPosition.MaxY,
    },
    H: {
      direction: Direction.Down,
      get: () => this._h,
      position: () => ProfileTemplateDimensionPosition.MaxX,
      set: (value: number) => {
        this._h = value;
        this.update();
      },
      glazingBead:
        !!this.glazingBeads[0] && this.glassPosition === GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Right,
              start: () => ({ x: 0, y: this.glassThickness }),
            }
          : null,
    },
    I: {
      direction: Direction.Left,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MaxY,
      doorSide: true,
    },
    J: {
      direction: Direction.Up,
      get: () => this._j,
      set: (value: number) => {
        this._j = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MinX,
      glazingBead:
        !!this.glazingBeads[1] && this.glassPosition === GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Left,
              start: () => ({
                x: 0,
                y: -(this.dimensions.J.get() - this._glazingBeadWidth - this.glassThickness),
              }),
            }
          : null,
    },
    K: {
      direction: Direction.Left,
      get: () => this.dimensions.A.get(),
      position: () => ProfileTemplateDimensionPosition.MaxY,
    },
    L: {
      direction: Direction.Up,
      get: () => this.profile.jambDepth - this.dimensions.B.get() - this.dimensions.J.get(),
      position: () => ProfileTemplateDimensionPosition.MinX,
    },
  };

  constructor(profile: Profile, glazingBeads: GlassExport[]) {
    super(profile, glazingBeads);
    this._a = this._e = this.defaultStopHeight;
    this._b = this._d = this.defaultOppositeDoorSideRabbet;
    this._h = this._j = this.defaultDoorSideRabbet;
    this.loadDimensions();
  }
}
