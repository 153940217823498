import { omit, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import { appendToContainerElement, createSVGElement, setAttributes, setCx, setCy, setRx, setRy, setStyles } from '../../../core/helpers/svg-functions';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';

const liteStyles = {
  fill: 'rgb(63, 101, 191)',
  fillOpacity: '0.35',
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '16px',
  transform: 'translate(8,8)',
};

export class CircularLite extends Cutout {
  readonly type = 'Circular Lite';
  sizes: { width: string; height: string }[] = [];

  constructor(
    name: string,
    doorType: DoorType,
    x: number,
    y: number,
    width: number,
    height: number,
    verticalDimensionMode: VerticalCutoutDimensionMode,
    horizontalDimensionMode: HorizontalCutoutDimensionMode
  ) {
    super(name, doorType, x, y, width, height, verticalDimensionMode, horizontalDimensionMode, true, true);
  }
  drawCutout(container: SVGGElement) {
    return pipe(
      setCx(this.x + (this.width - 16) / 2),
      setCy(this.y + (this.height - 16) / 2),
      setRx((this.width - 16) / 2),
      setRy((this.height - 16) / 2),
      setStyles(omit(liteStyles, 'transform')),
      setAttributes({ ...pick(liteStyles, 'transform') }),
      appendToContainerElement(container),
    )(createSVGElement('ellipse'));
  }
}
