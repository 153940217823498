import { ValidatorFn } from '@angular/forms';
import { UnitOfMeasure } from '../enums/unit-of-measure';
import { uomSwitch } from '../functions/uomSwitch';
import { minMaxValidator } from './minMaxValidator';
import { AdditionalLabel } from '../enums/additional-label';
import { Approval } from '../enums/approval';
import { DoorFireRating } from '../../door-elevation/enums/door-fire-rating';

export function doorUndercutValidator(
  additionalLabel: AdditionalLabel,
  approval: Approval,
  fireRating: DoorFireRating,
  unitOfMeasure: UnitOfMeasure
): ValidatorFn {
  let max = `3 1/2"`;
  if (additionalLabel === AdditionalLabel.BULLET || !!fireRating || additionalLabel === AdditionalLabel.STC) {
    max = '3/4"';
  }
  if (approval === Approval.STC52) {
    max = '1/4"';
  }
  return minMaxValidator(
    uomSwitch('1/8"', 'door', unitOfMeasure).toDimension('door', unitOfMeasure),
    uomSwitch(max, 'door', unitOfMeasure).toDimension('door', unitOfMeasure),
    'door',
    unitOfMeasure
  );
}
