import { DoorSeamlessEdge } from '../../enums/door-seamless-edge';
import { DoorSeries } from '../../enums/door-series';
import { DoorSubType } from '../../enums/door-subtype';
import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { seamlessEdgeTopChannels } from '../../rules/seamless-edge';

export function DoorTopChannels(series: DoorSeries, subType: DoorSubType, seamlessEdge: DoorSeamlessEdge) {
  if (!series || !subType) {
    return [];
  }
  const channels = [...(DoorSeriesInfos[series]?.subTypes[subType]?.topChannels ?? [])];
  seamlessEdgeTopChannels(seamlessEdge, channels);
  return channels;
}
