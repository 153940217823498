import { Injectable } from '@angular/core'
import { Subject, BehaviorSubject, Observable } from 'rxjs'
import { InternalUserSession } from '../internalUserSession.model'
import { StorageService } from '../../../../services/local-storage/local-storage.service'
import { CustomerService } from '../../../../services/customer/customer.service'

@Injectable({
  providedIn: 'root'
})
export class InternalLandingService {
  private _hash: Subject<string> = new BehaviorSubject(null)
  public hash$: Observable<string> = this._hash.asObservable()

  constructor(private storageService: StorageService, private customerService: CustomerService) {
    const hash = this.storageService.getItem('internalHash')
    if (hash) {
      this._hash.next(hash)
    }
  }

  public storeHash(hash: string): void {
    this._hash.next(hash)
    this.storageService.setItem('internalHash', hash)
    const payload = new InternalUserSession(hash)
    this.customerService.parentCompanyId = payload.customerId
    this.customerService.targetBranchCustomerId = payload.customerId
  }

  public clear() {
    this._hash.next(null)
    this.storageService.removeItem('internalHash')
  }
}
