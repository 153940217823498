import { Component, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { StickService } from '../../services/stick.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Stick } from '../../models/stick';
import { Orientation } from '../../enums/orientation';
import { Direction } from '../../enums/direction';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilteredType, Writable, nameOf } from '@oeo/common';
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { CoreConstants } from '../../../core/core.constants';
import { isMinimumValidator, measurementValidators } from '../../../core/validators/measurementValidator';

@Component({
  selector: 'lib-stick-info',
  templateUrl: './stick-info.component.html',
  styleUrls: ['./stick-info.component.scss', '../info.component.scss'],
})
export class StickInfoComponent implements OnInit, OnDestroy {
  readonly orientation = Orientation;
  readonly direction = Direction;
  stick: Stick;
  private _destroy$ = new Subject<void>();
  formGroup: FormGroup = new FormGroup({});
  top = 16;
  right = 16;

  constructor(private stickService: StickService) {}

  ngOnInit(): void {
    this.stickService.activeStick$.pipe(takeUntil(this._destroy$)).subscribe(stick => {
      this.stick = stick;
      if (this.stick) {
        this.setupForm();
      }
    });
  }

  get face() {
    return this.formGroup.get(nameOf((_: Stick) => _.face));
  }

  get length() {
    return this.formGroup.get(nameOf((_: Stick) => _.length));
  }

  setupForm() {
    for (const control in this.formGroup.controls) {
      this.formGroup.removeControl(control);
    }
    let validators = measurementValidators(this.stick.unitOfMeasure)
    validators.push(isMinimumValidator('frame', this.stick.unitOfMeasure, '2"'))
    this.formGroup.addControl(
      nameOf((_: Stick) => _.face),
      new FormControl(
        {
          value: this.stick.face.toDimension('frame', this.stick.unitOfMeasure),
          disabled: !this.stick.isEditable || this.stick.frameElevation.frameSeriesInfo.fixedFace,
        },
        validators
      )
    );
    this.formGroup.addControl(
      nameOf((_: Stick) => _.length),
      new FormControl(
        { value: this.stick.length.toDimension('frame', this.stick.unitOfMeasure), disabled: true },
        measurementValidators(this.stick.unitOfMeasure)
      )
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  updateShopBreak(input: HTMLInputElement, index: number) {
    const value = input.value.fromDimension('frame', this.stick.unitOfMeasure);
    if (index === this.stick.shopBreaks.length) {
      if (value) {
        this.stick.addShopBreak(value);
      }
      input.value = null;
    } else {
      if (!value) {
        return this.stick.deleteShopBreak(index);
      }
      if (!this.stick.updateShopBreak(value, index)) {
        input.value = this.stick.shopBreaks[index].toDimension('frame', this.stick.unitOfMeasure);
      }
    }
  }

  applyChange(component: HTMLInputElement, control: AbstractControl, value: string): void {
    const controlName = Object.keys(this.formGroup.controls).first(key => this.formGroup.controls[key] === control);
    control.patchValue(value);
    if (control.valid) {
      this.stick[controlName as Writable<Pick<Stick, FilteredType<Stick, number>>>] = value.fromDimension('frame', this.stick.unitOfMeasure);
    }
    control.patchValue(
      (component.value = (this.stick[controlName as keyof Stick] as number).toDimension('frame', this.stick.unitOfMeasure))
    );
  }

  close(){
    this.stickService.deactivate()
  }
}
