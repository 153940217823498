import { Injectable } from '@angular/core'
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http'
import { Observable, catchError } from 'rxjs'
import { environment } from '../../../environments/environment'
import { AuthService } from '../../services/auth.service'
import { switchMap } from 'rxjs/operators'
import { StorageService } from '../../services/local-storage/local-storage.service'

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private excludedUrlsRegex: RegExp[]
  private excludedUrls = ['.svg', '.json']

  constructor(private auth: AuthService, private storageService: StorageService) {
    this.excludedUrlsRegex = this.excludedUrls.map((urlPattern) => new RegExp(urlPattern, 'i')) || []
  }

  getCustomerId(): string {
    if (this.storageService.getItem('internal')) {
      return this.storageService.containsItem('targetBranchCustomerId')
        ? this.storageService.getItem('targetBranchCustomerId')
        : this.storageService.getItem('parentCompanyId')
    } else return this.storageService.getItem('targetBranchCustomerId')
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const passThrough: boolean = !!this.excludedUrlsRegex.find((regex) => regex.test(request.url))
    const customerId = this.getCustomerId()?.toLocaleLowerCase()
    //  make icons and translation files bypass the need for authentication
    if (passThrough) {
      return next.handle(request)
    }

    //  Add it in as a query param instead of in header
    //  TODO: make sure there isn't already params becore adding
    //  request = request.clone({ url: request.url + `?customerId=${customerId}` })

    return this.auth.getTokenSilently$().pipe(
      switchMap((token) => {
        request = request.clone({
          withCredentials: true,
          setHeaders: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': environment.origin
          }
        })

        if (customerId) {
          request = request.clone({
            headers: request.headers.set('CustomerId', customerId)
          })
        }

        if (request.method === 'GET') {
          request = request.clone({
            headers: request.headers.set('Cache-Control', 'no-cache').set('Pragma', 'no-cache')
          })
        }
        return next.handle(request)
      }),
      catchError((err) => {
        return next.handle(request)
      })
    )
  }
}
