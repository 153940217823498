import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core'
import { TableService } from '../../table.service'
import { MenuItem, CutSheetEvent } from '../../../../types'
import { MatMenuTrigger } from '@angular/material/menu'
import { ICutsheet } from '../../../../interfaces'

@Component({
  selector: 'oa-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent<C> implements OnInit {
  @Input() data: any
  @Input() row: any
  @Input() column: any

  @ViewChild('MenuTrigger') menuTrigger!: MatMenuTrigger
  @ViewChild('snackBarTemplate')
  snackBarTemplate!: TemplateRef<any>
  visibleMenuItems: boolean = false

  constructor(private tableActionsService: TableService<C>) {}

  ngOnInit() {
    this.visibleMenuItems = this.column?.menuItems.reduce(
      (acc: boolean, item: MenuItem<C>) => acc || !this.isDisabled(item, this.row),
      false
    )
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation()
  }

  handleClick(item: MenuItem<C>, event: MouseEvent, cutsheet?: ICutsheet) {
    event.stopPropagation()
    this.tableActionsService.actionEvent.next({
      tableName: this.column.tableName,
      event: item,
      data: cutsheet || this.row
    })
    this.menuTrigger.closeMenu()
  }

  handleCutsheet(item: MenuItem<C>, cutSheet: ICutsheet, event: MouseEvent) {
    event.stopPropagation()
    this.tableActionsService.cutsheetEvent.next({
      tableName: this.column.tableName,
      event: item,
      data: this.row,
      cutsheet: cutSheet
    } as CutSheetEvent<C>)
    this.menuTrigger.closeMenu()
  }

  isHidden(item: MenuItem<C>) {
    if (item.hidden) {
      return (
        this.row.hasHardwareSets ||
        ((this.row.category || this.row.category === '') && this.row.category !== 'Electronic Lock')
      )
    }
    return false
  }

  isDisabled(item: MenuItem<C>, row: any) {
    const disable: (args?: any) => any = item?.menuItemConditions?.disable || (() => false)
    return item?.disable || disable(row)
  }
}
