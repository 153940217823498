import { FilteredType, Constructable } from '@oeo/common'
import { IPrep, Prep } from '../../../core/interfaces/i-prep'
import { FrameElevation } from '../../models/frame-elevation'
import { ElecPrep } from './i-elec-prep'
import { FrameHingePrep } from './i-frame-hinge-prep'
import { FrameQuantityPrep } from './i-frame-quantity-prep'

/* Extract the types in Frame Elevation that are of type IPrep */
export type FramePrepTypes = FilteredType<FrameElevation, IPrep>

/**
 * Return all the prep properties, each of which extends their interfaced type in the FrameElevation Class
 * This object is typesafe, i.e, any change in the prep types will be checked against this object
 * And this object only contains keys in the FrameElevation class type
 */
export const FramePrepTypes: { [K in FramePrepTypes]: Constructable<FrameElevation[K]> } = {
  baseAnchorPrep: FrameQuantityPrep,
  bottomJambAnchorPrep: FrameQuantityPrep,
  closerPrep: FrameQuantityPrep,
  coordinatorPrep: Prep,
  deadlockStrikePrep: Prep,
  flushSurfaceBoltPrep: Prep,
  headAnchorPrep: FrameQuantityPrep,
  headStrikePrep: FrameQuantityPrep,
  hingePrep: FrameHingePrep,
  hospitalStopPrep: Prep,
  junctionBoxHeadPrep: Prep,
  junctionBoxHingePrep: Prep,
  junctionBoxSillPrep: Prep,
  junctionBoxStrikePrep: Prep,
  leftJambAnchorPrep: FrameQuantityPrep,
  magLockPrep: ElecPrep,
  magSwitchPrep: ElecPrep,
  powerTransferPrep: ElecPrep,
  primaryStrikePrep: Prep,
  removableMullionPrep: Prep,
  rightJambAnchorPrep: FrameQuantityPrep,
  rollerLatchPrep: FrameQuantityPrep,
  secondaryStrikePrep: Prep,
  sillAnchorPrep: FrameQuantityPrep,
  tertiaryStrikePrep: Prep,
  topJambAnchorPrep: FrameQuantityPrep,
}
