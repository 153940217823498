export const DoorMuntinBars = [
  { vertQty: 0, horzQty: 1, value: '2 Lites - One Horizontal Mullion' },
  { vertQty: 0, horzQty: 2, value: '3 Lites - Two Horizontal Mullions' },
  { vertQty: 2, horzQty: 0, value: '3 Lites Vertical - Two Vertical Mullions' },
  { vertQty: 1, horzQty: 1, value: '4 Lites - One Horizontal and One Vertical Mullion' },
  { vertQty: 0, horzQty: 3, value: '4 Lites - Three Horizontal Mullions' },
  { vertQty: 0, horzQty: 4, value: '5 Lites - Four Horizontal Mullions' },
  { vertQty: 1, horzQty: 2, value: '6 Lites - Two Horizontal and One Vertical Mullion' },
  { vertQty: 1, horzQty: 3, value: '8 Lites - Three Horizontal and One Vertical Mullion' },
  { vertQty: 2, horzQty: 2, value: '9 Lites - Two Horizontal and Two Vertical Mullions' },
  { vertQty: 1, horzQty: 4, value: '10 Lites - Four Horizontal and One Verical Mullion' },
  { vertQty: 2, horzQty: 3, value: '12 Lites - Three Horizontal and Two Vertical Mullions' },
  { vertQty: 2, horzQty: 4, value: '15 Lites - Four Horizontal and Two Vertical Mullions' },
  { vertQty: 3, horzQty: 5, value: '24 Lites - Five Horizontal and Three Vertical Mullions' },
];
