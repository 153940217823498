import { Component, Input, ViewChild, OnInit, Output, EventEmitter, HostListener } from '@angular/core'
import { state, style, transition, animate, trigger } from '@angular/animations'
import { MatMenu } from '@angular/material/menu'
import { MatMenuTrigger } from '@angular/material/menu'
import { SelectColumn } from '../../../types'

@Component({
  selector: 'oa-select-columns',
  templateUrl: './select-columns.component.html',
  styleUrls: ['./select-columns.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'scaleY(1)'
        })
      ),
      state(
        'closed',
        style({
          transform: 'scaleY(0)'
        })
      ),
      transition('open <=> closed', [animate('250ms ease-in-out')])
    ]),
    trigger('EnterLeave', [
      transition('void => *', [style({ opacity: '0' }), animate('250ms ease-in-out', style({ opacity: 1 }))]),
      transition('* => void', [style({ opacity: '1' }), animate('250ms ease-in-out', style({ opacity: 0 }))])
    ])
  ]
})
export class SelectColumnsComponent {
  @Input() public columns: SelectColumn[] = []
  @Output() public displayColumnsUpdated: EventEmitter<SelectColumn[]> = new EventEmitter()
  @ViewChild('columnsMenu') columnsMenu!: MatMenu
  @ViewChild('MenuTrigger') menuTrigger!: MatMenuTrigger
  isOpen = false

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.isOpen = false
    }
  }

  toggleMenu() {
    this.isOpen = !this.isOpen
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation()
  }

  updateDisplayColumns() {
    this.displayColumnsUpdated.emit(this.columns)
  }
}
