import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { DefaultBottomRail, DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Lite } from '../../models/cutout-types/lite';
import { Louver } from '../../models/cutout-types/louver';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class FLTType extends DoorType {
  cutouts: Cutout[];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = uomSwitch('24"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = uomSwitch('24"', 'door', this.door.doorElevation.unitOfMeasure);
    this.cutouts = [
      new Louver(
        'Louver',
        this,
        (this.door.actualWidth - width) / 2,
        DefaultTopRail(this.door.doorElevation.unitOfMeasure),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }
}
