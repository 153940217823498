<svg
  #svgRef
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:ev="http://www.w3.org/2001/xml-events"
>
  <defs>
    <g id="shape">
      <rect x="0" y="0" width="50" height="50"></rect>
      <circle cx="0" cy="0" r="50"></circle>
    </g>
    <g id="centerline">
      <path
        transform="scale(0.5)"
        id="path0"
        d="M159.259 44.005 L 159.259 84.306 142.139 88.617 C 3.599 123.502,-18.794 326.308,109.125 387.611 C 123.343 394.425,137.804 400.000,141.259 400.000 C 158.421 400.000,159.259 402.260,159.259 448.544 L 159.259 492.593 257.407 492.593 L 355.556 492.593 355.556 468.519 L 355.556 444.444 281.481 444.444 L 207.407 444.444 207.407 424.074 C 207.407 404.332,207.721 403.702,217.593 403.660 C 243.256 403.549,305.869 378.987,319.839 363.550 C 329.720 352.631,297.289 316.857,286.414 326.679 C 276.020 336.066,241.080 351.156,223.148 354.003 L 207.407 356.502 207.407 242.995 L 207.407 129.489 217.593 131.740 C 242.627 137.273,263.572 145.690,277.614 155.862 L 292.799 166.863 309.363 153.017 C 330.194 135.604,330.063 133.298,307.293 116.583 C 285.720 100.747,263.169 91.486,230.556 85.071 L 207.407 80.518 207.407 42.111 L 207.407 3.704 183.333 3.704 L 159.259 3.704 159.259 44.005 M157.488 351.154 C 151.979 356.663,123.380 342.119,105.568 324.751 C 51.495 272.025,67.102 176.057,134.970 143.956 L 157.407 133.343 158.390 241.306 C 158.931 300.686,158.525 350.117,157.488 351.154 "
        stroke="none"
        fill="#000000"
        fill-rule="evenodd"
      ></path>
    </g>
  </defs>
  <g>
    <g #gRef>
      <rect
        transform="translate(8,8)"
        style="fill: white; stroke: #7f7f7f; stroke-width: 16px"
        [attr.y]="door.doorElevation.headClearance"
        [attr.height]="door.actualHeight - 16"
        [attr.width]="door.actualWidth - 16"
      ></rect>
      <rect
        [attr.height]="32"
        [attr.width]="(door.actualWidth * 3) / 2"
        [attr.y]="door.height"
        [attr.x]="-door.actualWidth / 4"
        style="fill: #7f7f7f"
      ></rect>
      <text
        style="font-family: 'Work Sans'; font-size: 160px"
        [attr.y]="door.height + 80 + 104"
        [attr.x]="door.actualWidth / 2 - 672"
      >
        Finished Floor
      </text>
      <g #prepsRef></g>
    </g>
  </g>
</svg>
<p class="message">
  <span [ngClass]="{hidden: prepLocationInputsFilled}" class="special-location-message info-message">
    Please fill out all special prep location entries.
  </span>
  <ng-content></ng-content>
</p>

