import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import SvgPanZoom from 'svg-pan-zoom';
import { StickSubtype } from '../../../enums/stick-subtype';
import { FrameElevation } from '../../../models/frame-elevation';
import { Stick } from '../../../models/stick';

@Component({
  selector: 'lib-frame-prep',
  templateUrl: './frame-prep.component.html',
  styleUrls: ['./frame-prep.component.scss'],
})
export class FramePrepComponent implements OnInit {
  @Input() frameElevation: FrameElevation;
  @Input() prepLocationInputsFilled = true
  private _subTypes: StickSubtype[];
  public get subTypes(): StickSubtype[] {
    return this._subTypes;
  }
  @Input() public set subTypes(value: StickSubtype[]) {
    this._subTypes = value;
    this.setSticks();
  }
  @Input() showMessage: boolean = true;

  @ViewChild('gRef', { static: true }) gRef: ElementRef<SVGGElement>;
  @ViewChild('prepsRef', { static: true }) prepsRef: ElementRef<SVGGElement>;
  @ViewChild('svgRef', { static: true }) svgRef: ElementRef<SVGSVGElement>;
  private _svgPanZoom: SvgPanZoom.Instance;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resetAndFit()
  }
  get g() {
    return this.gRef.nativeElement;
  }

  get svg() {
    return this.svgRef.nativeElement;
  }

  get preps() {
    return this.prepsRef.nativeElement;
  }

  private _sticks: Stick[];
  get sticks(): Stick[] {
    return this._sticks ?? [];
  }
  @Input() set sticks(value: Stick[]) {
    this._sticks = value;
  }
  activeSticks: Stick[];

  subTypeString: string;

  constructor() {}

  ngOnInit(): void {
    this.subTypeString = this.subTypes.join(', ');
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        this.setSticks();
        this._svgPanZoom = SvgPanZoom(this.svg, {
          zoomEnabled: true,
          fit: false,
          contain: false,
          center: false,
          panEnabled: true,
          minZoom: 0,
          dblClickZoomEnabled: false,
        });
        this.resetAndFit();
      });
    });
  }

  setSticks() {
    for (const child of Array.from(this.g.children)) {
      child.remove();
    }
    const sticks = this.frameElevation.draw(this.g, null, false).filter(i => i instanceof Stick) as Stick[];
    sticks.forEach(i => {
      i.drawJambs();
    });
    this.activeSticks = sticks.filter(
      s =>
        this.subTypes.includes(s.subType) ||
        this.sticks.any(
          x => x.points.intersect(s.points, (a, b) => a.x === b.x && a.y === b.y).length === x.points.length
        )
    );
    this.activeSticks.forEach(i => (i.active = true));
  }

  resetAndFit() {
    requestAnimationFrame(() => {
      this._svgPanZoom.updateBBox().center().updateBBox().fit().updateBBox().zoomOut();
      this._svgPanZoom.zoomBy(0.8);
    });
  }
}
