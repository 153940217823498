import { nameOf, ValueOf } from '@oeo/common';
import { Subject } from 'rxjs';
import { CoreConstants } from '../../core/core.constants';
import { AdditionalLabel } from '../../core/enums/additional-label';
import { Approval } from '../../core/enums/approval';
import { PrepLocation } from '../../core/enums/prep-location';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { appendPreps } from '../../core/functions/appendPrep';
import { convertImperialMetricEnum } from '../../core/functions/convertJambDepth';
import { createProperty } from '../../core/functions/createProperty';
import { parsePreps } from '../../core/functions/parsePrep';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { createSVGElement } from '../../core/helpers/svg-functions';
import { IPrep } from '../../core/interfaces/i-prep';
import { Intersectable } from '../abstracts/intersectable';
import { ProfileTemplate } from '../abstracts/profile-template';
import { Template } from '../abstracts/template';
import { TemplateTypes } from '../constants/template-types';
import { FireRating } from '../enums/fire-rating';
import { FrameCornerAssembly } from '../enums/frame-corner-assembly';
import { FrameCornerCondition } from '../enums/frame-corner-condition';
import { FrameDoorThickness } from '../enums/frame-door-thickness';
import { FrameGauge } from '../enums/frame-gauge';
import { FrameJambDepth } from '../enums/frame-jamb-depth';
import { FrameMaterial } from '../enums/frame-material';
import { FrameMetalMylar } from '../enums/frame-metal-mylar';
import { FrameSeries, FrameSeriesInfo, FrameSeriesInfos } from '../enums/frame-series';
import { GlazingBeadLocation } from '../enums/glazing-bead-location';
import { GlazingBeadSize } from '../enums/glazing-bead-size';
import { TemplateType } from '../enums/template-type';
import { IElecPrep } from '../interfaces/preps/i-elec-prep';
import { IFrameHingePrep } from '../interfaces/preps/i-frame-hinge-prep';
import { IFrameQuantityPrep } from '../interfaces/preps/i-frame-quantity-prep';
import { Door } from './door';
import { DoorExport } from './exports/door-export';
import { FrameElevationExport } from './exports/frame-elevation-export';
import { GlassExport } from './exports/glass-export';
import { IntersectableExport } from './exports/intersectable-export';
import { StickExport } from './exports/stick-export';
import { Glass } from './glass';
import { Stick } from './stick';
import { IElevation } from '../../core/interfaces/i-elevation';

export class FrameElevation implements IElevation {

  id?: number;
  createdOn?: string;
  modifiedOn?: string;
  name: string = 'Untitled Drawing'
  configuration: string;
  userId?: number;
  folderId?: number;
  thumbnail?: string;

  private _afterUpdate$ = new Subject<void>();
  private _source: FrameElevationExport;
  private _editable: boolean;
  unitOfMeasure: UnitOfMeasure = UnitOfMeasure.Imperial;
  height: number;
  width: number;
  series: FrameSeries;
  jambDepth: FrameJambDepth;
  material: FrameMaterial;
  cornerAssembly: FrameCornerAssembly;
  cornerCondition: FrameCornerCondition;
  templateType: TemplateType;
  kerf: boolean;
  glazingBeadLocation: GlazingBeadLocation;
  glazingBeadSize: GlazingBeadSize;
  glassThickness: number;
  doorThickness: FrameDoorThickness;
  fireRating: FireRating;
  metalMylar: FrameMetalMylar;
  additionalLabel: AdditionalLabel;
  approval: Approval;
  gauge: FrameGauge;
  prepLocationPreference: PrepLocation

  get containsErrors() {
    return false
  }

  get frameSeriesInfo(): FrameSeriesInfo {
    return FrameSeriesInfos[this.series];
  }

  get editable() {
    return this._editable && this.frameSeriesInfo.editable && !this.kerf;
  }

  set editable(value: boolean) {
    this._editable = value;
  }

  get source(): FrameElevationExport {
    return this._source;
  }

  set source(value: FrameElevationExport) {
    this._source = value;
    this._afterUpdate$.next();
  }

  get afterUpdate$() {
    return this._afterUpdate$.asObservable();
  }

  primaryStrikePrep: IPrep;
  deadlockStrikePrep: IPrep;
  secondaryStrikePrep: IPrep;
  tertiaryStrikePrep: IPrep;
  hingePrep: IFrameHingePrep;
  closerPrep: IFrameQuantityPrep;
  leftJambAnchorPrep: IFrameQuantityPrep;
  rightJambAnchorPrep: IFrameQuantityPrep;
  topJambAnchorPrep: IFrameQuantityPrep;
  bottomJambAnchorPrep: IFrameQuantityPrep;
  headStrikePrep: IFrameQuantityPrep;
  rollerLatchPrep: IFrameQuantityPrep;
  coordinatorPrep: IPrep;
  flushSurfaceBoltPrep: IPrep;
  removableMullionPrep: IPrep;
  hospitalStopPrep: IPrep;
  magSwitchPrep: IElecPrep;
  magLockPrep: IElecPrep;
  powerTransferPrep: IElecPrep;
  junctionBoxHeadPrep: IPrep;
  junctionBoxSillPrep: IPrep;
  junctionBoxHingePrep: IPrep;
  junctionBoxStrikePrep: IPrep;
  sillAnchorPrep: IFrameQuantityPrep;
  headAnchorPrep: IFrameQuantityPrep;
  baseAnchorPrep: IFrameQuantityPrep;

  get isSteelcraft() {
    return this.frameSeriesInfo?.experlogixSeriesId === 'STL_Elevation';
  }

  get isRepublic() {
    return this.frameSeriesInfo?.experlogixSeriesId === 'REP_Elevation';
  }

  intersectables: Intersectable[] = [];
  template: Template;
  svg: SVGSVGElement;

  static fromXML(value: string): FrameElevationExport {
    if (!value) {
      return {} as FrameElevationExport;
    }
    const doc = parseFromXMLString(value);
    const sticks = this.parseIntersectableXML(doc, 'Stick');
    const doors = this.parseIntersectableXML(doc, 'Opening');
    const glasses = this.parseIntersectableXML(doc, 'Glass');
    const unitOfMeasure = this.parseXML(
      doc,
      nameOf((_: FrameElevation) => _.unitOfMeasure)
    ) as UnitOfMeasure;
    return {
      unitOfMeasure,
      height:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: FrameElevation) => _.height)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.frameElevation : 1),
      width:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: FrameElevation) => _.width)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.frameElevation : 1),
      series: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.series)
      ) as FrameSeries,
      jambDepth: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.jambDepth)
      ) as FrameJambDepth,
      material: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.material)
      ) as FrameMaterial,
      cornerAssembly: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.cornerAssembly)
      ) as FrameCornerAssembly,
      cornerCondition: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.cornerCondition)
      ) as FrameCornerCondition,
      templateType: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.templateType)
      ) as TemplateType,
      kerf:
        this.parseXML(
          doc,
          nameOf((_: FrameElevation) => _.kerf)
        ) === 'true',
      glazingBeadLocation: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.glazingBeadLocation)
      ) as GlazingBeadLocation,
      glassThickness: (this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.glassThickness)
      ) ?? '')?.fromDimension('frame', unitOfMeasure),
      glazingBeadSize: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.glazingBeadSize)
      ) as GlazingBeadSize,
      doorThickness: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.doorThickness)
      ) as FrameDoorThickness,
      fireRating: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.fireRating)
      ) as FireRating,
      intersectables: [
        ...sticks.map(x => Stick.fromXML(x)),
        ...doors.map(x => Door.fromXML(x)),
        ...glasses.map(x => Glass.fromXML(x)),
      ],
      metalMylar: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.metalMylar)
      ) as FrameMetalMylar,
      approval: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.approval)
      ) as Approval,
      additionalLabel: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.additionalLabel)
      ) as AdditionalLabel,
      gauge: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.gauge)
      ) as FrameGauge,
      prepLocationPreference: this.parseXML(
        doc,
        nameOf((_: FrameElevation) => _.prepLocationPreference)
      ) as PrepLocation,
      ...parsePreps('frame', doc)
  };
}

  public toElevationExport(){
    return FrameElevation.fromXML(this.toXML())
  }

  private static parseXML(doc: Document, property: string): string {
    const value = doc.evaluate(
      `//category[@id="FrameElevation"]/option[@id="FrameElevation"]/property[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${property.toLowerCase()}']`,
      doc,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    )?.singleNodeValue?.textContent;
    if (!value || value === 'undefined') {
      return null;
    }
    return value;
  }

  private static parseIntersectableXML(doc: Document, name: string): string[] {
    const result = doc.evaluate(
      `//category[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${name.toLowerCase()}']/option[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${name.toLowerCase()}']`,
      doc,
      null,
      XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
      null
    );
    const intersectables: string[] = [];
    let intersectable = result.iterateNext() as HTMLElement;
    while (intersectable) {
      intersectables.push(intersectable.outerHTML);
      intersectable = result.iterateNext() as HTMLElement;
    }
    return intersectables;
  }

  constructor(frameElevation?: FrameElevationExport, elevation?: IElevation) {
    if (frameElevation) {
      this.update((this.source = frameElevation), elevation);
    }
  }


  update(frameElevation: FrameElevationExport, elevation?: IElevation) {
    this.template?.destroy();
    let hasChanged = false;
    for (const key in frameElevation) {
      if (!hasChanged) {
        hasChanged = this[`${key}` as keyof FrameElevation] !== frameElevation[key as keyof FrameElevationExport];
      }
    }
    Object.assign(this, frameElevation, elevation)
    if (this.intersectables == null) {
      this.intersectables = [];
    }
    if (hasChanged) {
      this._afterUpdate$.next();
      const template = TemplateTypes.first(t => t.type === this.templateType);
      if (template) {
        this.template = new template.value(this);
      }
    }
  }

  customize(): void {
    this._editable = true;
  }

  toXML(): string {
    const erpNomenclatureIdentifier = '{{erpNomenclatureIdentifier}}';
    const doc = parseFromXMLString(
      `
      <lineitem seriesid="${this.frameSeriesInfo.experlogixSeriesId}" modelid="${
        this.frameSeriesInfo.experlogixModelId
      }">
          ${this.appendFrameElevationXml()}
          ${this.intersectables.map(i => i.toXML()).join('\n')}
          <category id="Calcs">
            <option id="Calcs">
              <property id="ERP_AGNConfigNomenclature">${erpNomenclatureIdentifier}</property>
            </option>
          </category>
          <category id="Image">
            <option id="Elevation">
              <property id="Svg">${btoa(this.svg?.prepareForExport(40, 80) ?? '')}</property>
            </option>
            ${this.appendProfiles()}
          </category>
      </lineitem>` );
    const erpNomenclature = Array.from(doc.getElementsByTagName('property'))
      .map(x => Array.from(x.innerHTML).take(2))
      .join(' ')
      .replace(/[^a-z0-9]+/gi, '');
    return doc.documentElement.outerHTML.replace(erpNomenclatureIdentifier, erpNomenclature);
  }

  private appendProfiles(): string {
    const groups = Array.from(
      this.intersectables
        .filter(i => i instanceof Stick)
        .map(i => i as Stick)
        .map(s => s.profiles.map(p => p))
        .selectMany(i => i)
        .groupBy(p => p.id)
        .values()
    );
    return groups
      .map((group, i) => {
        const svg = createSVGElement('svg');
        const profile = group[0];
        profile.drawProfile(svg, true);
        return `
          <option id="Profile">
            <property id="Svg">${
              btoa(svg.prepareForExport(ProfileTemplate.charWidth, ProfileTemplate.charHeight)) ?? ''
            }</property>
            <property id="Name">${String.fromCharCode(i + 65)}</property>
          </option>
        `;
      })
      .join('\n');
  }

  private appendFrameElevationXml(): string {
    return `<category id="FrameElevation">
      <option id="FrameElevation">
        ${createProperty(
          nameOf((_: FrameElevation) => _.unitOfMeasure),
          this.unitOfMeasure
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.height),
          `${
            this.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.height / CoreConstants.multipliers.frameElevation
              : this.height
          }`
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.width),
          `${
            this.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.width / CoreConstants.multipliers.frameElevation
              : this.width
          }`
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.series),
          this.series
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.jambDepth),
          this.jambDepth
        )}
        ${createProperty(
          'JambDepthNumeric',
          `${
            convertImperialMetricEnum(this.jambDepth, 'frame', this.unitOfMeasure) /
            (this.unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.frameElevation : 1)
          }`
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.material),
          this.material
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.cornerAssembly),
          this.cornerAssembly
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.cornerCondition),
          this.cornerCondition
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.templateType),
          this.templateType
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.kerf),
          `${this.kerf}`
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.glazingBeadLocation),
          this.glazingBeadLocation
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.glassThickness),
          this.glassThickness?.toDimension('frame', this.unitOfMeasure)
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.glazingBeadSize),
          this.glazingBeadSize
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.doorThickness),
          this.doorThickness
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.fireRating),
          this.fireRating
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.metalMylar),
          this.metalMylar
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.approval),
          this.approval
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.additionalLabel),
          this.additionalLabel
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.gauge),
          this.gauge
        )}
        ${createProperty(
          nameOf((_: FrameElevation) => _.prepLocationPreference),
          this.prepLocationPreference
        )}
        ${appendPreps(this)}
      </option>
    </category>`;
  }

  draw(container: SVGGElement, intersectableExports?: IntersectableExport[], editable?: boolean): Intersectable[] {
    const exports = intersectableExports ?? this.toElevationExport().intersectables;
    return exports.map(intersectable => {
      switch (intersectable.exportType) {
        case Stick.intersectableName:
          return Stick.fromJSON(this, container, intersectable as StickExport, editable);
        case Glass.intersectableName:
          return Glass.fromJSON(this, container, intersectable as GlassExport, editable);
        case Door.intersectableName:
          return Door.fromJSON(this, container, intersectable as DoorExport, editable);
      }
    });
  }
}
