import { Directive, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core'
import { Subject, debounceTime, fromEvent, mergeMap, takeUntil } from 'rxjs'

@Directive({
  selector: '[libAutocomplete]',
  standalone: true
})
export class AutoCompleteDirective implements OnDestroy {
  onFocus$ = fromEvent(this.inputElementRef.nativeElement, 'focus')
  onBlur$ = fromEvent(this.inputElementRef.nativeElement, 'blur')
  destroyed$ = new Subject()

  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent & { target: HTMLInputElement }) {
    this.renderer.setProperty(this.inputElementRef.nativeElement, 'autocompleteValue', event.target.value)
  }
  @HostListener('focus', ['$event']) onFocus() {
    this.renderer.setProperty(this.inputElementRef.nativeElement, 'autocompleteValue', '')
  }

  constructor(private inputElementRef: ElementRef<HTMLInputElement>, private renderer: Renderer2) {
    this.renderer.setProperty(this.inputElementRef.nativeElement, 'autocompleteValue', '')
    this.inputElementRef.nativeElement.classList.add('oeo-search-input')

    /**
     * Taking care of the blur event, we don't want to clear the inputs immediately
     */
    this.onFocus$
      .pipe(
        mergeMap(() => this.onBlur$.pipe(debounceTime(1000), takeUntil(this.onFocus$))),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.renderer.setProperty(this.inputElementRef.nativeElement, 'autocompleteValue', ''))
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
  }
}
