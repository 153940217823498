import { Injectable } from '@angular/core'
import { environment } from '../../../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { LocksmithIdNumber } from '../../../../models/locksmithIdNumber.model'
import { Observable, BehaviorSubject, of, AsyncSubject } from 'rxjs'
import { HelperService } from '../../../../services/helper/helper.service'

@Injectable({
  providedIn: 'root'
})
export class LocksmithIdNumbersService {
  url = environment.onlineOrderingApiUrl + 'CustomerLocksmithNumbers/'

  private _locksmithNumbers: BehaviorSubject<LocksmithIdNumber[]> = new BehaviorSubject(null)
  public locksmithNumbers$: Observable<LocksmithIdNumber[]> = this._locksmithNumbers.asObservable()

  constructor(private http: HttpClient, private helperService: HelperService) {
    this.getCustomerLocksmithNumbers()
  }

  public getCustomerLocksmithNumbers(): Observable<LocksmithIdNumber[]> {
    const response: AsyncSubject<LocksmithIdNumber[]> = new AsyncSubject()
    this.http.get(this.url).subscribe(
      (res: LocksmithIdNumber[]) => {
        this._locksmithNumbers.next(res)
        response.next(res)
        response.complete()
      },
      (err) => this.helperService.handleError(err.message)
    )
    return response.asObservable()
  }

  saveCustomerLocksmithNumber(payload: LocksmithIdNumber): Observable<LocksmithIdNumber> {
    const id: number = payload.id
    const _payload: LocksmithIdNumber = {
      locksmithNumber: payload.locksmithNumber,
      description: payload.description
    }
    if (id) {
      return this.updateCustomerLocksmithNumber(_payload, id)
    } else {
      return this.createCustomerLocksmithNumbers(_payload)
    }
  }

  private createCustomerLocksmithNumbers(payload: LocksmithIdNumber): Observable<LocksmithIdNumber> {
    const response: AsyncSubject<LocksmithIdNumber> = new AsyncSubject()
    this.http.post(this.url, payload).subscribe(
      (res: LocksmithIdNumber) => {
        this.getCustomerLocksmithNumbers()
        response.next(res)
        response.complete()
      },
      (err) => this.helperService.handleError(err.message)
    )
    return response.asObservable()
  }

  private updateCustomerLocksmithNumber(payload: LocksmithIdNumber, id: number): Observable<LocksmithIdNumber> {
    const _url = this.url + id
    const response: AsyncSubject<LocksmithIdNumber> = new AsyncSubject()
    this.http.put(_url, payload).subscribe(
      (res: LocksmithIdNumber) => {
        this.getCustomerLocksmithNumbers()
        response.next(res)
        response.complete()
      },
      (err) => this.helperService.handleError(err.message)
    )
    return response.asObservable()
  }

  public deleteCustomerLocksmithNumber(id: number): Observable<LocksmithIdNumber> {
    const _url = this.url + id
    const response: AsyncSubject<LocksmithIdNumber> = new AsyncSubject()
    this.http.delete(_url).subscribe(
      (_) => {
        this.getCustomerLocksmithNumbers()
        response.next(null)
        response.complete()
      },
      (err) => this.helperService.handleError(err.message)
    )
    return response.asObservable()
  }
}
