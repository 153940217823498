import { pipe, isEqual } from 'lodash/fp';
import { BehaviorSubject,Subject } from 'rxjs';
import { takeWhile, debounceTime, map, skipWhile } from 'rxjs/operators';
import {
  SVGElementType,
  setX,
  setStyles,
  addClass,
  setY,
  setWidth,
  setHeight,
  setRx,
  setRy,
  createSVGElement,
  setInnerHTML,
  appendChildElement,
  setX1,
  setY1,
  setX2,
  setY2,
  setCx,
  setCy,
  setR,
  insertFirstChildElement,
} from '../../core/helpers/svg-functions';
import { textStyles } from '../constants/text-styles';
import { Orientation } from '../enums/orientation';
import { Stick } from '../models/stick';

const RECT_STYLES = Object.freeze({
  fill: 'white',
  stroke: 'black',
  strokWidth: '1px',
});

export class ActiveDimensionsController {
  private _charHeight = 20;
  private _charWidth = 16;
  private _destroy$ = new Subject<void>();
  private sticksLengths = new BehaviorSubject<number[]>([]);

  constructor(private readonly container: SVGGElement) {
    this.sticksLengths
      .pipe(
        skipWhile(()=>this.sticksLengths.value.length === 0),
        debounceTime(4000),
        map(()=> this.destroy())
      )
      .subscribe()
  }

  draw(sticks: Stick[]) {
    if(sticks.any((stick, i) => this.sticksLengths.value[i] !== stick.length)) this.destroy();
    if (this.sticksLengths.value.length === sticks.length) {
      sticks
        .filter((stick, i) => this.sticksLengths.value[i] !== stick.length)
        .map((stick, i) => this.drawDimensions(this.container, stick));
    }
    if(isEqual(sticks.map(s => s.length))(this.sticksLengths.value)) return
    this.sticksLengths.next(sticks.map(s => s.length));
  }

  destroy() {
    this._destroy$.next();
    this.container?.querySelectorAll('.lib-stick-dimension').forEach(el => el.remove());
  }

  drawDimensions(container: SVGGElement, stick: Stick): SVGGElement {
    const charLength = stick.length.toDimension('frame', stick.unitOfMeasure).length + 0.5;
    const CONTAINER_HEIGHT = this._charHeight * 2;
    const CONTAINER_WIDTH = charLength * this._charWidth;
    const isVertical = stick.orientation === Orientation.Vertical;
    let x = stick.points.average(pt => pt.x);
    let y = stick.points.average(pt => pt.y);

    // Draw the line from the center of the stick to the measurement container
    const line = pipe(
      setX1(x),
      setY1(y),
      setX2(x + (isVertical ? CONTAINER_HEIGHT : 0)),
      setY2(y + (isVertical ? 0 : CONTAINER_HEIGHT)),
      addClass('active-dimension')
    )(createSVGElement('line'));

    // Draw a point at the center of the stick
    const point = pipe(setCx(x), setCy(y), setR(6))(createSVGElement('circle'));

    // Offset the container to either just below or to the right of the stick
    switch (stick.orientation) {
      case Orientation.Vertical:
        x += CONTAINER_WIDTH / 2 + CONTAINER_HEIGHT;
        break;
      case Orientation.Horizontal:
        y += CONTAINER_HEIGHT * 1.5;
        break;
    }

    const rect = pipe(
      setX(x - CONTAINER_WIDTH / 2),
      setY(y - CONTAINER_HEIGHT / 2),
      setStyles(RECT_STYLES),
      addClass(['stick-length-tooltip', 'animate-in']),
      setWidth(CONTAINER_WIDTH),
      setHeight(CONTAINER_HEIGHT),
      setRx(2),
      setRy(2)
    )(createSVGElement('rect'));

    const text = pipe(
      setStyles(textStyles),
      addClass(['tspan', 'text-on-dark-bg']),
      setX(x - CONTAINER_WIDTH / 2 + this._charWidth / 2),
      setY(y + this._charHeight / 2),
      setInnerHTML(stick.length.toDimension('frame', stick.unitOfMeasure))
    )(createSVGElement('text'));

    const element = pipe(
      appendChildElement(rect),
      appendChildElement(text),
      appendChildElement(line),
      appendChildElement(point),
      addClass('lib-stick-dimension')
    )(createSVGElement('g'));

    insertFirstChildElement(element)(container);
    return element;
  }
}
