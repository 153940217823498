import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IDialog } from '../../../core/interfaces/i-dialog';

@Component({
  selector: 'lib-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss', '../info.component.scss'],
})
export class HelpComponent implements IDialog<void>, OnInit {
  data: void;
  items = [
    { key: '← ↑ → ↓', modifier: '', description: 'Move the active element' },
    {
      key: '← ↑ → ↓',
      modifier: 'SHIFT',
      description: 'Move the active element as far as it can move without intersecting',
    },
    { key: 'DEL', modifier: '', description: 'Delete the active element' },
    {
      key: '← ↑ → ↓',
      modifier: 'ALT',
      description: 'Shrink the active element',
    },
    {
      key: '← ↑ → ↓',
      modifier: 'CTRL',
      description: 'Stretch the active element as far as it can stretch without intersecting',
    },
    { key: 'C', modifier: 'SHIFT', description: 'Center a stick' },
    { key: 'T', modifier: 'ALT', description: 'Change the top joint type of a stick' },
    { key: 'B', modifier: 'ALT', description: 'Change the bottom joint type of a stick' },
    { key: 'L', modifier: 'ALT', description: 'Change the left joint type of a stick' },
    { key: 'R', modifier: 'ALT', description: 'Change the right joint type of a stick' },
    { key: 'O', modifier: 'ALT', description: 'Change the type of stick' },
    { key: 'P', modifier: 'ALT', description: 'Change the profile of stick' },
  ];
  left = 80;
  top = 16;

  @Output() close$ = new EventEmitter()

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.close$.emit();
  }
}
