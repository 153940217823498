export class PointAnchor {
  private _anchor: DOMPoint;

  get anchor() {
    return this._anchor ? this._anchor : ({} as DOMPoint);
  }

  constructor(anchor: DOMPoint, public readonly point: DOMPoint) {
    this._anchor = anchor;
  }
}
