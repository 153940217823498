<h3>{{ ruleType.name }}</h3>
<form class="row" [formGroup]="form">
  <div class="col grow padding right">
    <mat-form-field class="no-hint">
      <mat-label>{{ 'FORMFIELDS.property' | translate }}</mat-label>
      <mat-select [value]="property.value" (selectionChange)="setRuleProperty($event.value)">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let x of properties" [value]="x.property">{{ x.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col grow padding left right">
    <mat-form-field class="no-hint">
      <mat-label>{{ 'FORMFIELDS.comparison' | translate }}</mat-label>
      <mat-select [disabled]="!ruleProperty" formControlName="comparison">
        <mat-option [value]="null">--</mat-option>
        <mat-option *ngFor="let x of comparisons" [value]="x">{{ x }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col grow padding left">
    <ng-container [ngSwitch]="ruleProperty?.type">
      <ng-container *ngSwitchCase="'string'">
        <mat-form-field class="no-hint" *ngIf="comparison.value === 'EQUALS' || comparison.value === 'NOT EQUALS'">
          <mat-label>{{"FORMFIELDS.conclusion" | translate}}</mat-label>
          <mat-select [placeholder]="conclusion.value" formControlName="conclusion">
            <mat-option
              class="option"
              (click)="$event.stopPropagation(); $event.stopImmediatePropagation()"
              *ngFor="let x of conclusions"
              [value]="null"
            >
              <mat-checkbox color="primary" #checkbox [value]="x.selected"  (concatConclusion)="x.selected = $event" (change)="concatConclusion()">{{
                x.value
              }}</mat-checkbox>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.conclusion' | translate}}</mat-label>
          <input
            matInput
            formControlName="conclusion"
            libApplyMeasure
            [unitOfMeasure]="'Imperial'"
          />
        </mat-form-field>
      </ng-container>
    </ng-container>
  </div>
</form>
<div class="rule" *ngFor="let subRule of customRule.subRules; let last = last; let first = first">
  <div class="line">
    <lib-custom-rule
      *ngIf="subRule"
      [ruleType]="ruleProperty.ruleType"
      [customRule]="subRule"
      [parentComparison]="comparison.value"
      (valid)="valid.emit($event && form.valid)"
    ></lib-custom-rule>
    <div class="button-margin">
      <button class="icon-button padding s4 all" (click)="addRule()">
        <mat-icon>add</mat-icon>
      </button>
      <button class="icon-button padding s4 all"
        (click)="deleteRule(subRule)"
        *ngIf="!first"
      >
      <mat-icon>delete</mat-icon>
    </button>
    </div>
  </div>
  <div class="andOr" *ngIf="!last">
    <mat-radio-group [value]="subRule.andOr" (change)="subRule.andOr = $event.value">
      <mat-radio-button [value]="true" color="primary">{{"and" | translate}}</mat-radio-button>
      <mat-radio-button [value]="false" color="primary">{{"or" | translate}}</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
