export class RouteIds {
  estimateId?: number
  hwSetId?: number
  quoteId?: number
  notificationId?: number
  constructor() {
    this.estimateId = null
    this.hwSetId = null
    this.quoteId = null
    this.notificationId = null
  }
}
