import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { SharedModule } from '../shared/shared.module'

import { LoginComponent } from './login.component'
import { LogoutComponent } from './logout.component'
import { SsoComponent } from './sso.component'

@NgModule({
  declarations: [LoginComponent, LogoutComponent, SsoComponent],
  imports: [CommonModule, SharedModule],
  exports: [LoginComponent, LogoutComponent, SsoComponent]
})
export class AuthModule {}
