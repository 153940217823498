import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import '@ornstio/ts-linq';
import { CoreModule } from '../core/core.module';
import { PrepCategoryService } from '../core/services/door-prep-category.service';
import { FramePrepCodeService } from '../core/services/frame-prep-code.service';
import { FramePrepService } from '../core/services/frame-prep.service';
import { DoorInfoComponent } from './components/door-info/door-info.component';
import { DrawingPadComponent } from './components/drawing-pad/drawing-pad.component';
import { GlassInfoComponent } from './components/glass-info/glass-info.component';
import { HelpComponent } from './components/help/help.component';
import { StickInfoComponent } from './components/stick-info/stick-info.component';
import { TemplateDesignerComponent } from './components/template-designer/template-designer.component';
import { ConfigurationComponent } from './dialogs/configuration/configuration.component';
import { FrameElevationSettingsComponent } from './dialogs/frame-elevation-settings/frame-elevation-settings.component';
import { FrameProfileComponent } from './dialogs/frame-profile/frame-profile.component';
import { BaseAnchorPrepComponent } from './dialogs/preps/anchor-prep/base-anchor-prep.component';
import { HeadAnchorPrepComponent } from './dialogs/preps/anchor-prep/head-anchor-prep.component';
import { SillAnchorPrepComponent } from './dialogs/preps/anchor-prep/sill-anchor-prep.component';
import { CloserPrepComponent } from './dialogs/preps/closer-prep/closer-prep.component';
import { MagLockPrepComponent } from './dialogs/preps/elec-prep/mag-lock-prep.component';
import { MagSwitchPrepComponent } from './dialogs/preps/elec-prep/mag-switch-prep.component';
import { PowerTransferPrepComponent } from './dialogs/preps/elec-prep/power-transfer-prep.component';
import { CoordinatorPrepComponent } from './dialogs/preps/ez-prep/coordinator-prep.component';
import { FlushSurfaceBoltPrepComponent } from './dialogs/preps/ez-prep/flush-surface-bolt-prep.component';
import { HospitalStopPrepComponent } from './dialogs/preps/ez-prep/hospital-stop-prep.component';
import { RemovableMullionPrepComponent } from './dialogs/preps/ez-prep/removable-mullion-prep.component';
import { FramePrepComponent } from './dialogs/preps/frame-prep/frame-prep.component';
import { HeadStrikePrepComponent } from './dialogs/preps/head-strike-prep/head-strike-prep.component';
import { HingePrepComponent } from './dialogs/preps/hinge-prep/hinge-prep.component';
import { JunctionBoxHeadPrepComponent } from './dialogs/preps/junction-box-prep/junction-box-head-prep.component';
import { JunctionBoxHingePrepComponent } from './dialogs/preps/junction-box-prep/junction-box-hinge-prep.component';
import { JunctionBoxSillPrepComponent } from './dialogs/preps/junction-box-prep/junction-box-sill-prep.component';
import { JunctionBoxStrikePrepComponent } from './dialogs/preps/junction-box-prep/junction-box-strike-prep.component';
import { PrepsDialogComponent } from './dialogs/preps/preps-dialog.component';
import { PrepsComponent } from './dialogs/preps/preps.component';
import { BottomJambPrepComponent } from './dialogs/preps/quantity-prep/bottom-jamb-prep.component';
import { LeftJambPrepComponent } from './dialogs/preps/quantity-prep/left-jamb-prep.component';
import { RightJambPrepComponent } from './dialogs/preps/quantity-prep/right-jamb-prep.component';
import { RollerLatchPrepComponent } from './dialogs/preps/quantity-prep/roller-latch-prep.component';
import { TopJambPrepComponent } from './dialogs/preps/quantity-prep/top-jamb-prep.component';
import { DeadlockStrikePrepComponent } from './dialogs/preps/strike-prep/deadlock-strike-prep.component';
import { PrimaryStrikePrepComponent } from './dialogs/preps/strike-prep/primary-strike-prep.component';
import { SecondaryStrikePrepComponent } from './dialogs/preps/strike-prep/secondary-strike-prep.component';
import { TertiaryStrikePrepComponent } from './dialogs/preps/strike-prep/tertiary-strike-prep.component';
import { FrameElevationComponent } from './frame-elevation.component';

@NgModule({
  declarations: [
    DrawingPadComponent,
    FrameElevationComponent,
    StickInfoComponent,
    GlassInfoComponent,
    DoorInfoComponent,
    FrameElevationSettingsComponent,
    HelpComponent,
    TemplateDesignerComponent,
    FrameProfileComponent,
    ConfigurationComponent,
    PrepsComponent,
    PrepsDialogComponent,
    FramePrepComponent,
    PrimaryStrikePrepComponent,
    DeadlockStrikePrepComponent,
    SecondaryStrikePrepComponent,
    TertiaryStrikePrepComponent,
    HingePrepComponent,
    CloserPrepComponent,
    LeftJambPrepComponent,
    RightJambPrepComponent,
    TopJambPrepComponent,
    BottomJambPrepComponent,
    HeadStrikePrepComponent,
    RollerLatchPrepComponent,
    CoordinatorPrepComponent,
    FlushSurfaceBoltPrepComponent,
    RemovableMullionPrepComponent,
    HospitalStopPrepComponent,
    MagSwitchPrepComponent,
    MagLockPrepComponent,
    PowerTransferPrepComponent,
    JunctionBoxHeadPrepComponent,
    JunctionBoxSillPrepComponent,
    JunctionBoxHingePrepComponent,
    JunctionBoxStrikePrepComponent,
    SillAnchorPrepComponent,
    HeadAnchorPrepComponent,
    BaseAnchorPrepComponent,
  ],
  imports: [CoreModule, CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule],
  exports: [FrameElevationComponent],
  providers: [PrepCategoryService, FramePrepCodeService, FramePrepService],
})
export class FrameElevationModule {}
