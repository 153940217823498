import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { nameOf } from '@oeo/common';
import { isNull } from 'lodash';
import { BasePrepsComponent } from '../../../core/components/base-preps/base-preps.component';
import { DialogService } from '../../../core/services/dialog.service';
import { ProductService } from '../../../core/services/door-product.service';
import { FrameElevation } from '../../models/frame-elevation';
import { FrameElevationConfigService } from '../../services/frame-elevation-config.service';
import { BaseAnchorPrepComponent } from './anchor-prep/base-anchor-prep.component';
import { HeadAnchorPrepComponent } from './anchor-prep/head-anchor-prep.component';
import { SillAnchorPrepComponent } from './anchor-prep/sill-anchor-prep.component';
import { CloserPrepComponent } from './closer-prep/closer-prep.component';
import { MagLockPrepComponent } from './elec-prep/mag-lock-prep.component';
import { MagSwitchPrepComponent } from './elec-prep/mag-switch-prep.component';
import { PowerTransferPrepComponent } from './elec-prep/power-transfer-prep.component';
import { CoordinatorPrepComponent } from './ez-prep/coordinator-prep.component';
import { FlushSurfaceBoltPrepComponent } from './ez-prep/flush-surface-bolt-prep.component';
import { HospitalStopPrepComponent } from './ez-prep/hospital-stop-prep.component';
import { RemovableMullionPrepComponent } from './ez-prep/removable-mullion-prep.component';
import { HeadStrikePrepComponent } from './head-strike-prep/head-strike-prep.component';
import { HingePrepComponent } from './hinge-prep/hinge-prep.component';
import { JunctionBoxHeadPrepComponent } from './junction-box-prep/junction-box-head-prep.component';
import { JunctionBoxHingePrepComponent } from './junction-box-prep/junction-box-hinge-prep.component';
import { JunctionBoxSillPrepComponent } from './junction-box-prep/junction-box-sill-prep.component';
import { JunctionBoxStrikePrepComponent } from './junction-box-prep/junction-box-strike-prep.component';
import { BottomJambPrepComponent } from './quantity-prep/bottom-jamb-prep.component';
import { LeftJambPrepComponent } from './quantity-prep/left-jamb-prep.component';
import { RightJambPrepComponent } from './quantity-prep/right-jamb-prep.component';
import { RollerLatchPrepComponent } from './quantity-prep/roller-latch-prep.component';
import { TopJambPrepComponent } from './quantity-prep/top-jamb-prep.component';
import { DeadlockStrikePrepComponent } from './strike-prep/deadlock-strike-prep.component';
import { PrimaryStrikePrepComponent } from './strike-prep/primary-strike-prep.component';
import { SecondaryStrikePrepComponent } from './strike-prep/secondary-strike-prep.component';
import { TertiaryStrikePrepComponent } from './strike-prep/tertiary-strike-prep.component';
import { FramePrepTypes } from '../../interfaces/preps';

@Component({
  selector: 'lib-preps',
  templateUrl: './preps.component.html',
  styleUrls: ['./preps.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrepsComponent),
      multi: true,
    },
  ],
})
export class PrepsComponent extends BasePrepsComponent<FrameElevation> {
  @Input() data: { frame: FrameElevation, isNew: boolean };

  get unitOfMeasure() {
    return this.data.frame.unitOfMeasure;
  }

  constructor(
    cd: ChangeDetectorRef,
    dialogService: DialogService,
    readonly productService: ProductService,
    public configService: FrameElevationConfigService,
  ) {
    super(cd, dialogService, productService)
  }

  setUpPrepSteps(){
    this.prepSteps = [
      {
        name: 'Primary Strike',
        component: PrimaryStrikePrepComponent,
        prepName: nameOf((_: FrameElevation) => _.primaryStrikePrep),
        checked: !!this.data.frame.primaryStrikePrep,
        order: 1,
      },
      {
        name: 'Deadlock Strike',
        component: DeadlockStrikePrepComponent,
        checked: !!this.data.frame.deadlockStrikePrep,
        prepName: nameOf((_: FrameElevation) => _.deadlockStrikePrep),
        order: 2,
      },
      {
        name: 'Secondary Strike',
        component: SecondaryStrikePrepComponent,
        checked: !!this.data.frame.secondaryStrikePrep,
        prepName: nameOf((_: FrameElevation) => _.secondaryStrikePrep),
        order: 3,
      },
      {
        name: 'Tertiary Strike',
        component: TertiaryStrikePrepComponent,
        checked: !!this.data.frame.tertiaryStrikePrep,
        prepName: nameOf((_: FrameElevation) => _.tertiaryStrikePrep),
        order: 4,
      },
      {
        name: 'Head Strike',
        component: HeadStrikePrepComponent,
        checked: !!this.data.frame.headStrikePrep,
        prepName: nameOf((_: FrameElevation) => _.headStrikePrep),
        order: 4.1,
      },
      {
        name: 'Hinge',
        component: HingePrepComponent,
        checked: !!this.data.frame.hingePrep,
        prepName: nameOf((_: FrameElevation) => _.hingePrep),
        order: 5,
      },
      {
        name: 'Closer',
        component: CloserPrepComponent,
        checked: !!this.data.frame.closerPrep,
        prepName: nameOf((_: FrameElevation) => _.closerPrep),
        order: 6,
      },
      {
        name: 'Left Jamb Anchor',
        component: LeftJambPrepComponent,
        checked: !!this.data.frame.leftJambAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.leftJambAnchorPrep),
        order: 7,
      },
      {
        name: 'Right Jamb Anchor',
        component: RightJambPrepComponent,
        checked: !!this.data.frame.rightJambAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.rightJambAnchorPrep),
        order: 8,
      },
      {
        name: 'Top Jamb Anchor',
        component: TopJambPrepComponent,
        checked: !!this.data.frame.topJambAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.topJambAnchorPrep),
        order: 9,
      },
      {
        name: 'Bottom Jamb Anchor',
        component: BottomJambPrepComponent,
        checked: !!this.data.frame.bottomJambAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.bottomJambAnchorPrep),
        order: 10,
      },
      {
        name: 'Roller Latch',
        component: RollerLatchPrepComponent,
        checked: !!this.data.frame.rollerLatchPrep,
        prepName: nameOf((_: FrameElevation) => _.rollerLatchPrep),
        order: 11,
      },
      {
        name: 'Coordinator',
        component: CoordinatorPrepComponent,
        checked: !!this.data.frame.coordinatorPrep,
        prepName: nameOf((_: FrameElevation) => _.coordinatorPrep),
        order: 12,
      },
      {
        name: 'Flush/Surface Bolt',
        component: FlushSurfaceBoltPrepComponent,
        checked: !!this.data.frame.flushSurfaceBoltPrep,
        prepName: nameOf((_: FrameElevation) => _.flushSurfaceBoltPrep),
        order: 13,
      },
      {
        name: 'Removable Mullion',
        component: RemovableMullionPrepComponent,
        checked: !!this.data.frame.removableMullionPrep,
        prepName: nameOf((_: FrameElevation) => _.removableMullionPrep),
        order: 14,
      },
      {
        name: 'Hospital Stop',
        component: HospitalStopPrepComponent,
        checked: !!this.data.frame.hospitalStopPrep,
        prepName: nameOf((_: FrameElevation) => _.hospitalStopPrep),
        order: 15,
      },
      {
        name: 'Mag Switch',
        component: MagSwitchPrepComponent,
        checked: !!this.data.frame.magSwitchPrep,
        prepName: nameOf((_: FrameElevation) => _.magSwitchPrep),
        order: 16,
      },
      {
        name: 'Mag Lock',
        component: MagLockPrepComponent,
        checked: !!this.data.frame.magLockPrep,
        prepName: nameOf((_: FrameElevation) => _.magLockPrep),
        order: 17,
      },
      {
        name: 'Power Transfer',
        component: PowerTransferPrepComponent,
        checked: !!this.data.frame.powerTransferPrep,
        prepName: nameOf((_: FrameElevation) => _.magLockPrep),
        order: 18,
      },
      {
        name: 'Junction Box Head',
        component: JunctionBoxHeadPrepComponent,
        checked: !!this.data.frame.junctionBoxHeadPrep,
        prepName: nameOf((_: FrameElevation) => _.junctionBoxHeadPrep),
        order: 19,
      },
      {
        name: 'Junction Box Sill',
        component: JunctionBoxSillPrepComponent,
        checked: !!this.data.frame.junctionBoxSillPrep,
        prepName: nameOf((_: FrameElevation) => _.junctionBoxSillPrep),
        order: 20,
      },
      {
        name: 'Junction Box Strike',
        component: JunctionBoxStrikePrepComponent,
        checked: !!this.data.frame.junctionBoxStrikePrep,
        prepName: nameOf((_: FrameElevation) => _.junctionBoxStrikePrep),
        order: 21,
      },
      {
        name: 'Junction Box Hinge',
        component: JunctionBoxHingePrepComponent,
        checked: !!this.data.frame.junctionBoxHingePrep,
        prepName: nameOf((_: FrameElevation) => _.junctionBoxHingePrep),
        order: 22,
      },
      {
        name: 'Sill Anchor',
        component: SillAnchorPrepComponent,
        checked: !!this.data.frame.sillAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.sillAnchorPrep),
        order: 23,
      },
      {
        name: 'Head Anchor',
        component: HeadAnchorPrepComponent,
        checked: !!this.data.frame.headAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.headAnchorPrep),
        order: 24,
      },
      {
        name: 'Base Anchor',
        component: BaseAnchorPrepComponent,
        checked: !!this.data.frame.baseAnchorPrep,
        prepName: nameOf((_: FrameElevation) => _.baseAnchorPrep),
        order: 25,
      },
    ].orderBy(x => x.order);
  }
  removePrep(prepName: FramePrepTypes){
    if (!isNull(this.data.frame[prepName])){
      this.data.frame[prepName] = null
    }
  }
  close(){
    this.dialogService.close(this.data.frame.toElevationExport())
  }


  public updateHingePrepSelection(...args: any[]): void { }
}

export type FrameElevationPrepComponentType =
  | PrimaryStrikePrepComponent
  | DeadlockStrikePrepComponent
  | SecondaryStrikePrepComponent
  | TertiaryStrikePrepComponent
  | HeadStrikePrepComponent
  | HingePrepComponent
  | CloserPrepComponent
  | LeftJambPrepComponent
  | RightJambPrepComponent
  | TopJambPrepComponent
  | BottomJambPrepComponent
  | RollerLatchPrepComponent
  | CoordinatorPrepComponent
  | FlushSurfaceBoltPrepComponent
  | RemovableMullionPrepComponent
  | HospitalStopPrepComponent
  | MagSwitchPrepComponent
  | MagLockPrepComponent
  | PowerTransferPrepComponent
  | JunctionBoxHeadPrepComponent
  | JunctionBoxSillPrepComponent
  | JunctionBoxStrikePrepComponent
  | JunctionBoxHingePrepComponent
  | SillAnchorPrepComponent
  | HeadAnchorPrepComponent
  | BaseAnchorPrepComponent
