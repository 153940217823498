import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Stick } from '../models/stick';
import { Orientation } from '../enums/orientation';
import { IntersectableService } from './intersectable.service';
import { map, tap, takeUntil } from 'rxjs/operators';
import { BaseIntersectableService, IntersectableKeyboardEventModifier } from '../abstracts/base-intersectable-service';
import { DialogService } from '../../core/services/dialog.service';

@Injectable()
export class StickService extends BaseIntersectableService implements OnDestroy {
  private _activeStick: Stick;
  get activeStick() {
    return this._activeStick;
  }
  private _activeStick$ = new Subject<Stick>();
  get activeStick$() {
    return this._activeStick$.asObservable();
  }

  constructor(private intersectableService: IntersectableService, dialogService: DialogService) {
    super(dialogService);
    this.intersectableService.activeIntersectable$
      .pipe(
        map(i => (i instanceof Stick ? i : null)),
        tap(stick => this._activeStick$.next(stick)),
        takeUntil(this._destroy$),
      )
      .subscribe(stick => {
        this._activeStick = stick
      });

    this.registerHotkey('keydown', 'ArrowUp', IntersectableKeyboardEventModifier.None, this.arrowUp);
    this.registerHotkey('keyup', 'ArrowUp', IntersectableKeyboardEventModifier.ShiftKey, this.arrowUpShift);
    this.registerHotkey('keyup', 'ArrowUp', IntersectableKeyboardEventModifier.CtrlKey, this.arrowUpCtrl);
    this.registerHotkey('keydown', 'ArrowUp', IntersectableKeyboardEventModifier.AltKey, this.arrowUpAlt);

    this.registerHotkey('keydown', 'ArrowRight', IntersectableKeyboardEventModifier.None, this.arrowRight);
    this.registerHotkey('keyup', 'ArrowRight', IntersectableKeyboardEventModifier.ShiftKey, this.arrowRightShift);
    this.registerHotkey('keyup', 'ArrowRight', IntersectableKeyboardEventModifier.CtrlKey, this.arrowRightCtrl);
    this.registerHotkey('keydown', 'ArrowRight', IntersectableKeyboardEventModifier.AltKey, this.arrowRightAlt);

    this.registerHotkey('keydown', 'ArrowLeft', IntersectableKeyboardEventModifier.None, this.arrowLeft);
    this.registerHotkey('keyup', 'ArrowLeft', IntersectableKeyboardEventModifier.ShiftKey, this.arrowLeftShift);
    this.registerHotkey('keyup', 'ArrowLeft', IntersectableKeyboardEventModifier.CtrlKey, this.arrowLeftCtrl);
    this.registerHotkey('keydown', 'ArrowLeft', IntersectableKeyboardEventModifier.AltKey, this.arrowLeftAlt);

    this.registerHotkey('keydown', 'ArrowDown', IntersectableKeyboardEventModifier.None, this.arrowDown);
    this.registerHotkey('keyup', 'ArrowDown', IntersectableKeyboardEventModifier.ShiftKey, this.arrowDownShift);
    this.registerHotkey('keyup', 'ArrowDown', IntersectableKeyboardEventModifier.CtrlKey, this.arrowDownCtrl);
    this.registerHotkey('keydown', 'ArrowDown', IntersectableKeyboardEventModifier.AltKey, this.arrowDownAlt);

    this.registerHotkey('keyup', 'KeyC', IntersectableKeyboardEventModifier.ShiftKey, this.keyCShift);
    this.registerHotkey('keyup', 'KeyT', IntersectableKeyboardEventModifier.AltKey, this.keyTAlt);
    this.registerHotkey('keyup', 'KeyB', IntersectableKeyboardEventModifier.AltKey, this.keyBAlt);
    this.registerHotkey('keyup', 'KeyL', IntersectableKeyboardEventModifier.AltKey, this.keyLAlt);
    this.registerHotkey('keyup', 'KeyR', IntersectableKeyboardEventModifier.AltKey, this.keyRAlt);
    this.registerHotkey('keyup', 'KeyO', IntersectableKeyboardEventModifier.AltKey, this.keyOAlt);
    this.registerHotkey('keyup', 'KeyP', IntersectableKeyboardEventModifier.AltKey, this.keyPAlt);
  }

  deactivate(){
    this.intersectableService.activate(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  arrowUp = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.moveUp({ sticky: true, afterUpdate: true });
  };

  arrowUpShift = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.alignUp();
  };

  arrowUpCtrl = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    switch (this._activeStick.orientation) {
      case Orientation.Horizontal:
        return this._activeStick.flip();
      case Orientation.Vertical:
        return this._activeStick.stretchUp();
    }
  };

  arrowUpAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Vertical) {
      this._activeStick.shrinkUp();
    }
  };

  arrowRight = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.moveRight({ sticky: true, afterUpdate: true });
  };

  arrowRightShift = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.alignRight();
  };

  arrowRightCtrl = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    switch (this._activeStick.orientation) {
      case Orientation.Horizontal:
        return this._activeStick.stretchRight();
      case Orientation.Vertical:
        return this._activeStick.flip();
    }
  };

  arrowRightAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Horizontal) {
      this._activeStick.shrinkRight();
    }
  };

  arrowLeft = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.moveLeft({ sticky: true, afterUpdate: true });
  };

  arrowLeftShift = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.alignLeft();
  };

  arrowLeftCtrl = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    switch (this._activeStick.orientation) {
      case Orientation.Horizontal:
        return this._activeStick.stretchLeft();
      case Orientation.Vertical:
        return this._activeStick.flip();
    }
  };

  arrowLeftAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Horizontal) {
      this._activeStick.shrinkLeft();
    }
  };

  arrowDown = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.moveDown({ sticky: true, afterUpdate: true });
  };

  arrowDownShift = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.alignDown();
  };

  arrowDownCtrl = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    switch (this._activeStick.orientation) {
      case Orientation.Horizontal:
        return this._activeStick.flip();
      case Orientation.Vertical:
        return this._activeStick.stretchDown();
    }
  };

  arrowDownAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Vertical) {
      this._activeStick.shrinkDown();
    }
  };

  keyTAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Vertical) {
      const index = this._activeStick.jointTypes.indexOf(this._activeStick.topJointType) + 1;
      this._activeStick.topJointType = this._activeStick.jointTypes[index.mod(this._activeStick.jointTypes.length)];
    }
  };

  keyBAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Vertical) {
      const index = this._activeStick.jointTypes.indexOf(this._activeStick.bottomJointType) + 1;
      this._activeStick.bottomJointType = this._activeStick.jointTypes[index.mod(this._activeStick.jointTypes.length)];
    }
  };

  keyRAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Horizontal) {
      const index = this._activeStick.jointTypes.indexOf(this._activeStick.rightJointType) + 1;
      this._activeStick.rightJointType = this._activeStick.jointTypes[index.mod(this._activeStick.jointTypes.length)];
    }
  };

  keyLAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    if (this._activeStick.orientation === Orientation.Horizontal) {
      const index = this._activeStick.jointTypes.indexOf(this._activeStick.leftJointType) + 1;
      this._activeStick.leftJointType = this._activeStick.jointTypes[index.mod(this._activeStick.jointTypes.length)];
    }
  };

  keyOAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    const index = this._activeStick.types.indexOf(this._activeStick.type) + 1;
    this._activeStick.type = this._activeStick.types[index.mod(this._activeStick.types.length)];
  };

  keyCShift = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    this._activeStick.center();
  };

  keyPAlt = () => {
    if (this._activeStick == null || !this._activeStick?.isEditable) {
      return;
    }
    const index = this._activeStick.rabbets.indexOf(this._activeStick.rabbet);
    this._activeStick.rabbet = this._activeStick.rabbets[(index + 1) % this._activeStick.rabbets.length];
  };
}
