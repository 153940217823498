import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'oa-detached-customer-account',
  templateUrl: './detached-customer-account.component.html',
  styleUrls: ['./detached-customer-account.component.scss']
})
export class DetachedCustomerAccountComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
