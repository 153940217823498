import { AdditionalLabel } from '../../../core/enums/additional-label';
import { additionalLabelMetalMylar } from '../../rules/additional-label';
import { fireRatingMetalMylar } from '../../rules/fire-rating';
import { DoorFireRating } from '../door-fire-rating';
import { DoorMetalMylar } from '../door-metal-mylar';
import { DoorSubType } from '../door-subtype';

export function DoorMetalMylars(fireRating: DoorFireRating, additionalLabel: AdditionalLabel, doorSubType: DoorSubType) {
  const metalMylars: DoorMetalMylar[] = [];
  fireRatingMetalMylar(metalMylars, fireRating);
  additionalLabelMetalMylar(metalMylars, additionalLabel);
  if(doorSubType == DoorSubType.IMP) {
    metalMylars.remove(DoorMetalMylar.Metal);
  }
  return metalMylars;
}
