import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core'
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms'
import { takeUntil } from 'rxjs/operators'
import { Subject, Observable } from 'rxjs'
import { CustomerProfileService } from '../customer-profile.service'
import { CustomerProfile } from '../customer-profile.model'

@Component({
  selector: 'oa-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readOnly: boolean
  form: FormGroup<{ purchaseOrderTerms: FormControl<string> }>
  destroyed$: Subject<boolean> = new Subject()
  profile$: Observable<CustomerProfile> = this.customerProfileService.profile
  profile: CustomerProfile

  constructor(private fb: FormBuilder, public customerProfileService: CustomerProfileService) {
    this.createForm()
    this.profile$.subscribe((profile) => {
      if (!profile) {
        return
      }
      this.profile = profile
      this.form.controls.purchaseOrderTerms.patchValue(profile.purchaseOrderTerms)
    })
  }

  ngOnInit() {}

  ngOnChanges(event: SimpleChanges) {
    if (!event.readOnly) {
      return
    }
    if (event.readOnly.currentValue === true && this.form.controls.purchaseOrderTerms) {
      this.form.disable()
    } else {
      this.form.enable()
    }
  }

  createForm() {
    this.form = this.fb.group<{ purchaseOrderTerms: FormControl<string> }>({
      purchaseOrderTerms: new FormControl({ value: '', disabled: this.readOnly }, Validators.maxLength(2000))
    })
  }

  updateTerms(terms: string) {
    if (terms === this.profile.purchaseOrderTerms || this.form.invalid) {
      return
    }
    this.customerProfileService.updateTerms(terms)
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
