import { Validators } from '@angular/forms';
import { UnitOfMeasure } from './enums/unit-of-measure';

export const CoreConstants = {
  multipliers: {
    doorElevation: 64,
    frameElevation: 16,
  },
  regex: {
    imperial: /^(?!$)(?:(\d*)'[ ]{0,1}){0,1}(?:(\d*)(?: |\.|")){0,1}(?:(?:(\d{0,}\/\d{0,})")|(?:(\d{0,})")){0,1}$/,
    metric: /^(?!$)(\d*)[\s]{0,}(?:mm){0,1}$/i,
  },
};
