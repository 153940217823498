import { FilteredType, Constructable } from '@oeo/common'
import { IPrep, Prep } from '../../../core/interfaces/i-prep'
import { Door } from '../../models/door'
import { DoorHingePrep } from './i-door-hinge-prep'
import { DoorIntPivotPrep } from './i-door-int-pivot-prep'
import { DoorLockPrep } from './i-door-lock-prep'
import { OHStopHolderPrep } from './i-oh-stop-holder-prep'
import { DoorFlushBoltPrep } from './i-door-flush-bolt-prep'

/* Extract the types in door that are of type IPrep */
export type DoorPrepTypes = FilteredType<Door, IPrep>

/**
 * Return all the prep properties, each of which extends their interfaced type in the Door Class
 * This object is typesafe, i.e, any change in the prep types will be checked against this object
 * And this object only contains keys in the Door type
 */
export const DoorPrepTypes: { [K in DoorPrepTypes]: Constructable<Door[K]> } = {
  anchorHingePrep: Prep,
  blankSurfaceHingePrep: Prep,
  closerPrep: Prep,
  deadlockPrep: DoorLockPrep,
  deadlockStrikePrep: Prep,
  dutchDoorShelvesPrep: Prep,
  flushBoltPrep: DoorFlushBoltPrep,
  hingePrep: DoorHingePrep,
  intPivotPrep: DoorIntPivotPrep,
  magSwitchPrep: Prep,
  ohStopHolderPrep: OHStopHolderPrep,
  powerTransferPrep: Prep,
  primaryLockPrep: DoorLockPrep,
  primaryStrikePrep: Prep,
  primaryViewerPrep: Prep,
  rollerLatchPrep: Prep,
  secondaryLockPrep: DoorLockPrep,
  secondaryStrikePrep: Prep,
  secondaryViewerPrep: Prep,
  surfaceBoltPrep: Prep,
  tertiaryLockPrep: DoorLockPrep,
  tertiaryStrikePrep: Prep,
  topBottomPivotPrep: Prep
}

export type TDoorPrepTypes = typeof DoorPrepTypes
