import { Product } from './product.model'
import { ProductDiscount } from '../models/product-discount.model'
import { Quote } from './quote'
import { DoorMarking } from './door-marking'
import { EstimateHardwareSet } from './estimateHardwareSet.model'

export class HardwareSet {
  hardwareSetId: number
  userId: number
  name: string
  lastModified: string
  isSystemSet: boolean
  totalPrice: number
  netPrice: number
  setQuantity: number
  products: Product[]
  finish?: string
  leverStyle?: string
  openingType?: string
  isElectronic?: boolean
  listPrice?: number
  keyedQuickShip: boolean
  keyable?: boolean
  lastModifiedByUserId?: number
  doorMarkings?: DoorMarking[]
  hasNotification?() {
    return this.userId > 0 && this.userId !== this.lastModifiedByUserId
  }
}

export class IndirectProducts extends Product {
  productDiscounts: ProductDiscount[]
}

export class IndirectHardwareSet extends HardwareSet {
  estimateHardwareSets: EstimateHardwareSet[]
}

export type HardwareSetProductAttachment = {
  created: string
  product: Product
  fileName: string
  id: number
  lastModified: string
  hwSetId: string
  hwSetProductId: string
}

export type HardwareSetEstimates = { [key: number]: string }
