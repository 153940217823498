import { Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { cloneDeep, isEqual, sortBy } from 'lodash'
import { BehaviorSubject, catchError, filter, forkJoin, mergeMap, of, take, tap } from 'rxjs'
import { Cacheable } from 'ts-cacheable'
import { objectKeyExtractor, nameOf, WritableKeys } from '@oeo/common'
import { IPrep, Prep } from '../../../core/interfaces/i-prep'
import { IPrepCategory } from '../../../core/interfaces/i-prep-category'
import { IPrepCode } from '../../../core/interfaces/i-prep-code'
import { IProduct } from '../../../core/interfaces/i-product'
import { Preference } from '../../../core/models/preference'
import { DialogService } from '../../../core/services/dialog.service'
import { DoorPrepService } from '../../../core/services/door-prep.service'
import { ProductService } from '../../../core/services/door-product.service'
import { PreferenceService } from '../../../core/services/preference.service'
import { DoorElevationSettingsComponent } from '../../dialogs/door-elevation-settings/door-elevation-settings.component'
import { Door } from '../../models/door'
import { DoorElevation } from '../../models/door-elevation'
import {
  ConfigStep,
  DoorElevationConfigResult,
  DoorMode,
  DoorPosition,
  getPairPrepSteps,
  NEW_DOOR_STEPS,
  PAIR_STEPS,
  SINGLE_DOOR_SETTINGS,
  SINGLE_PREP_STEPS,
  StepType
} from './constants'
import { DoorSeries } from '../../enums/door-series'
import { DoorPrepCategoryIds } from '../../../core/enums/prep-category-ids'
import { IPrepCategoryLocation } from '../../../core/interfaces/i-prep-category-location'
import { DoorPrepTypes } from '../../interfaces/preps'

@Injectable({
  providedIn: 'root'
})
export class DoorElevationConfigService {
  #steps: ConfigStep[] = []
  set steps(steps: ConfigStep[]) {
    this.#steps = steps.map((step) => ({...step, name: this.translate.instant(step.name)}))
  }
  get steps(): ConfigStep[] {
    return this.#steps
  }

  #step: BehaviorSubject<number> = new BehaviorSubject(0)
  step$ = this.#step.asObservable()
  get step() {
    return this.#step.getValue()
  }

  #estimateId: BehaviorSubject<string> = new BehaviorSubject(null)
  get estimateId() {
    return this.#estimateId.getValue()
  }

  loadFromPreference = true
  preferenceExists$ = new BehaviorSubject(false)

  loadingPrepCodes$ = new BehaviorSubject(true)

  constructor(
    private prepService: DoorPrepService,
    private productService: ProductService,
    private preferenceService: PreferenceService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private translate: TranslateService
  ) {
    /* Load formatted preps and product selections */
    forkJoin([this.getFormattedPreps(), this.getProductSelections()]).subscribe(()=>this.loadingPrepCodes$.next(false))

    /* Load preferences based on the estimateId */
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        mergeMap(() => {
          const params = this.activatedRoute.root.firstChild.snapshot.firstChild?.params
          if (params?.estimateId) {
            this.#estimateId.next(params.estimateId)
            return this.getPreference(this.estimateId)
          } else {
            this.#estimateId.next(null)
            return this.#preference.asObservable()
          }
        })
      )
      .subscribe()
  }

  #doorElevation: BehaviorSubject<DoorElevation> = new BehaviorSubject<DoorElevation>(null)
  get doorElevation() {
    return this.#doorElevation.getValue()
  }
  /**
   * Sets a door elevation
   * NOTE: Only persists preps if the door elevation is not new and the Series or Door Active Status has not changed
   *
   * Also checks whether or not to persist preps
   */
   set doorElevation(newDoorElevation: DoorElevation) {
    /* Firstly Check if preps should be persisted */
    if(this.steps.length > 0) {
      this.steps[this.step].persistPreps = this.isNew ? true : this.persistPreps(this.doorElevation, newDoorElevation)
    }
    if (this.persistedPreps) this.#doorElevation.next(this.copyPreps(this.doorElevation, newDoorElevation, this.persistedPreps))
    else this.#doorElevation.next(cloneDeep(newDoorElevation))
  }

  /**
   * This is useful in the door elevation component to reset the door elevation
   */
  resetDoorElevation(doorElevation: DoorElevation) {
    this.#doorElevation.next(doorElevation)
  }

  /** Sets whether or not to save the door configuration as a preference for the current estimate */
  #saveAsPreference: boolean = false
  set saveAsPreference(value: boolean) {
    this.#saveAsPreference = value
  }
  get saveAsPreference() {
    return this.#saveAsPreference
  }

  #isNew: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  get isNew() {
    return this.#isNew.getValue()
  }

  /**
   * Modifies an existing door elevation
   * Does not allow the user to change the door mode.
   * @param doorElevation the door elevation to update
   */
  modifyDoorElevation(doorElevation: DoorElevation) {
    this.doorElevation = doorElevation
    this.#isNew.next(false)
    this.setSteps()
    this.#step.next(0)
    return this.openDoorElevationSettings()
  }

  /**
   * Modifies the door preps for the current door elevation
   * @param doorElevation
   * @param doorPosition
   * @returns
   */
  modifyDoorPreps(doorElevation: DoorElevation, doorPosition: DoorPosition){
    this.doorElevation = doorElevation
    this.#isNew.next(false)

    if(doorElevation.doors.length > 1) this.steps = [getPairPrepSteps(this.doorElevation.doors)[doorPosition]]
    else this.steps = [SINGLE_PREP_STEPS]

    this.#step.next(0)
    return this.openDoorElevationSettings()
  }

  /**
   * Creates a new door elevation with user's preferences
   */
  createNewDoorElevation(doorMode: DoorMode) {
    const elevation = new DoorElevation()
    this.doorElevation = elevation
    this.#isNew.next(true)
    this.#step.next(0)
    this.steps = NEW_DOOR_STEPS
    return this.openDoorElevationSettings(doorMode)
  }

  /**
   * Setting the door mode proceeds to next step
   * @param doorMode Whether the door is single or double
   */
  public setDoorMode(doorMode: DoorMode) {
    const draft = new DoorElevation(this.loadFromPreference ? DoorElevation.fromXML(this.preference?.configuration) : null)
      draft.doors = new Array(doorMode === 'Single' ? 1 : 2).fill(
        new Door(this.doorElevation),
        0,
        doorMode === 'Single' ? 1 : 2
      )
    this.doorElevation = draft
    this.setSteps()
    this.goNext()
  }

  public goNext(door?: Door) {
    if (door) {
      this.updateCurrentDoor(door)
    }
    if (this.steps[this.step].type === StepType.DoorSettings && this.saveAsPreference) {
      this.createOrUpdatePreferences()
    }
    if (this.step === this.steps.length - 1) {
      this.dialogService.close<DoorElevationConfigResult>({doorElevation: this.doorElevation, persistedPreps: this.persistedPreps})
    } else this.#step.next(this.step + 1)
    this.steps = this.steps.map((step, i)=>({...step, current: this.step === i, completed: i < this.step}))
  }

  public goBack() {
    this.#step.next(this.#step.getValue() - 1)
    /* If we are going back to DoorMode, we need to reset the steps */
    if (this.steps[this.step].type === StepType.DoorMode) {
      this.steps = this.steps.slice(0, 1)
    }
    this.steps = this.steps.map((step, i)=>({...step, current: this.step === i, completed: i < this.step}))
  }

  updatePrepSteps(doorElevation: DoorElevation){
    if(!this.isNew || doorElevation.doors.length === 1) return
    this.steps = [...this.steps.slice(0, -2), ...getPairPrepSteps(doorElevation.doors)]
  }

  setSteps() {
    this.steps = this.steps.slice(0, 1)
    if (this.doorElevation.doors.length === 1) {
      if (this.isNew) this.steps = [...this.steps, SINGLE_DOOR_SETTINGS, SINGLE_PREP_STEPS]
      else this.steps = [SINGLE_DOOR_SETTINGS]
    } else {
      if (this.isNew) this.steps = [...this.steps, ...PAIR_STEPS, ...getPairPrepSteps(this.doorElevation.doors)]
      else this.steps = PAIR_STEPS
    }
  }

  public openDoorElevationSettings(doorMode?: DoorMode) {
    if(doorMode) {
      this.setDoorMode(doorMode)
    }
    return this.dialogService.open<{}, DoorElevationConfigResult>(DoorElevationSettingsComponent, {}, { closeable: false }).pipe(
      mergeMap((result) => {
        if (!result) return of(null)
        return of(result)
      }),
      take(1)
    )
  }

  public closeDialog() {
    this.dialogService.close({doorElevation: null, persistedPreps: true})
  }

  /**
   * Copies door preps from one elevation to another, returning a new elevation
   * @param fromElevation the elevation to copy the preps from
   * @param toElevation The elevation to copy the preps to
   * @returns DoorElevation with the preps copied
   */
  public copyPreps(fromElevation: Readonly<DoorElevation>, toElevation: DoorElevation, copy: boolean): DoorElevation {
    const draft = cloneDeep(toElevation)
    if(!draft.doors) draft.doors = []
    if(!fromElevation?.doors?.length) return draft
    draft.doors = draft?.doors.map((door, index) => {
      /* Get the prep keys from the original door  */
      const prepKeys = objectKeyExtractor<Door, IPrep>(fromElevation?.doors[index], /Prep/)
      /* Copy the prep objects to the target door */
      prepKeys.forEach((key: string) => (door[key as DoorPrepTypes] = copy ? (fromElevation?.doors[index][key as DoorPrepTypes] as unknown as never): null))
      return door
    })
    return draft
  }
  /**
   * Checks whether or not to persist door preps
   * If the Width, Height, Series or Door Active Status changes, we do not want to persist the preps
   *
   * @param oldElevation The old elevation to compare to
   * @param newElevation
   * @returns {boolean} Whether or not to persist the preps
   */
  persistPreps(oldElevation: DoorElevation, newElevation: DoorElevation) {
    if (!oldElevation?.doors?.length || !newElevation?.doors?.length) return false
    if (newElevation.doors.length !== oldElevation.doors.length) return false
    if (newElevation.series !== oldElevation.series) return false
    if (newElevation.height !== oldElevation.height) return false
    if (!isEqual(newElevation.doors.map(({width})=>width), oldElevation.doors.map(({width})=>width))) return false
    return oldElevation.doors.every((door, index) => door.active === newElevation.doors[index].active)
  }

  get persistedPreps(){
    return !this.steps.any(({persistPreps}) => !persistPreps)
  }

  /**
   * Removes a prep from the current door
   * @param prepPropertyName The name of the prep property to update
   */
  removePrep(prepPropertyName: DoorPrepTypes) {
    this.doorElevation.doors[this.steps[this.step].doorIndex][prepPropertyName] = null
  }

  /**
   * Updates the current door with the given door
   * @param door - the door to update
   * @param index - The index of the door in the doorElevation
   */
  updateCurrentDoor(door: Door) {
    if(isEqual(this.doorElevation.doors[this.steps[this.step].doorIndex], door)) return
    const draft = cloneDeep(this.doorElevation)
    draft.doors[this.steps[this.step].doorIndex] = door
    this.doorElevation = draft
  }

  /*** Prep Categories, Prep Codes and Products ***/
  #prepCategories: BehaviorSubject<IPrepCategory[]> = new BehaviorSubject<IPrepCategory[]>([])
  get prepCategories() {
    return this.#prepCategories.getValue().map((c: IPrepCategory) => {
      c.prepCategoryLocations = sortBy<IPrepCategoryLocation>(
        c.prepCategoryLocations,
        [
          ({ value }) => value !== 'Republic',
          ({ value }) => value !== 'Steelcraft',
          ({ value }) => value !== 'Republic (Old)',
          nameOf((_: IPrepCategoryLocation) => _.value)
        ],
        ['asc']
      )
      return c
    })
  }
  #prepCodes: BehaviorSubject<IPrepCode[]> = new BehaviorSubject<IPrepCode[]>([])
  getPrepCodes(prepCategoryId: DoorPrepCategoryIds) {
    return this.#prepCodes.getValue().filter((code)=>DPSeriesPrepCodeFilter(code)(this.doorElevation.series)).filter(
      (code) =>
        (this.doorElevation.isSteelcraft ? code.isSteelcraft : code.isRepublic) &&
        code.categories.any((category) => category.id === prepCategoryId && category.checked))
    .orderBy((c) => c.code)
  }
  #products: BehaviorSubject<IProduct[]> = new BehaviorSubject<IProduct[]>([])
  get products() {
    return this.#products.getValue()
  }

  #preference: BehaviorSubject<Preference> = new BehaviorSubject<Preference>(null)
  get preference() {
    return this.#preference.getValue()
  }

  @Cacheable()
  public getFormattedPreps() {
    return this.prepService.getFormattedPreps().pipe(
      take(1),
      tap(({ prepCategories, prepCodes }) => {
        this.#prepCategories.next(prepCategories)
        this.#prepCodes.next(prepCodes)
      }),
      catchError((e) => {
        console.error(e)
        return []
      })
    )
  }

  @Cacheable()
  public getProductSelections() {
    return this.productService.getProductSelections().pipe(tap((products) => this.#products.next(products)))
  }

  @Cacheable()
  public getPreference(estimateId: string = this.estimateId) {
    return this.preferenceService.getByPreferenceTypeId(3, +estimateId).pipe(
      take(1),
      tap((preference) => {
        this.#preference.next(preference)
        this.preferenceExists$.next(!!preference)
      })
    )
  }

  public createOrUpdatePreferences() {
    this.preferenceService
      .getByPreferenceTypeId(3, +this.estimateId)
      .pipe(
        mergeMap((preference) =>
          preference == null
            ? this.preferenceService.create({
                configuration: this.doorElevation.toXML(),
                preferenceTypeId: 3,
                estimateId: this.estimateId
              })
            : this.preferenceService.update(preference.id, { configuration: this.doorElevation.toXML() })
        ),
        mergeMap(() => this.getPreference(this.estimateId))
      )
      .subscribe()
  }

  /**
   * Returns the current door based on the current step
   */
  get currentDoor() {
    return this.doorElevation.doors[this.steps[this.step].doorIndex]
  }
}

/**
 * Filters the prep codes based on the Door Series
 * FIXME: This is a temporary implementation that needs a better solution
 */
const DPSeriesPrepCodeFilter =
  ({ code, description }: IPrepCode) =>
  (series: DoorSeries) => {
    const codeDescriptions = [
      /* Primary, Secondary, Tertiary Lock Preps */
      'CH (161, CYLINDRICAL LOCK)',
      'MOL (86ED, MORTISE OMIT LOCK)',
      'M (86, MORTISE LOCK (STANDARD FACE PREP))',
      'OLRIM (OMIT LOCK RIM REINFORCED (NO PREP))',
      'OLVROD (OMIT LOCK - VERTICAL ROD (NO PREP))',
      '2-1/8" Bore Deadlock 1" Front (218DLK.B)',
      '2-1/8" Bore Deadlock 1-1/8" Front (218DLK.A)',
      'Falcon-NT (Cylindrical Lock)',
      'DT (161DT)',
      'OL (OMIT LOCK)',
      /* Closer Preps */
      'CR (CLOSER REINF)',
      'C2 (1 CLOSER REINF - 12GA)',
      'C2F (Full Width 12 Gauge)',
      'CRF (Full Width Closer Reinf)',
      /* Hinge */
      '4 1/2 UNIVERSAL HINGE (4 1/2 STD MORTISE)',
      'OMIT HINGE',
      /* Primary, Secondary, Tertiary Strike Preps */
      '4-7/8" Strike',
      '2-3/4" Deadlock Strike',
      'OMIT STRIKE',
      /* Flush Bolt */
      'Standard (1" x 6-3/4" Edge Prep)',
      'Embossed (Top & Bottom Channel)'
    ]
    if (series == DoorSeries.DP) {
      return codeDescriptions.any((c) =>
        `${code?.trim() + (description?.length > 0 ? ' (' + description?.trim() + ')' : '')}`.searchFor(c, true)
      )
    }
    return true
  }
