import { Intersectable } from '../abstracts/intersectable';
import { Stick } from '../models/stick';
import { Orientation } from '../enums/orientation';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { textStyles } from '../constants/text-styles';
import { Direction } from '../enums/direction';
import { StickType } from '../enums/stick-type';
import { appendChildElement, createSVGElement, setStyles, addClass, setTextContent } from '../../core/helpers/svg-functions';
import { pipe } from 'lodash/fp';
import { CSSPropKey } from '@oeo/common';

export const lineStyles: Record<string, string> = {
  stroke: 'black',
  strokeWidth: '1px',
};
export const distanceStyles: Record<string, string> = {
  strokeDasharray: '4px',
};

export class LineMaker {
  static readonly characterHeight = 16;

  static readonly characterWidth = 12;

  private readonly _distanceFromEdge = -40;

  private get svg() {
    return this.container.root(SVGSVGElement);
  }

  constructor(private readonly text: SVGTextElement, private readonly container: SVGGElement) {}

  redraw(intersectables: ReadonlyArray<Intersectable>, height: number, width: number, unitOfMeasure: UnitOfMeasure) {
    this.destroy();
    this.drawVertical(intersectables, height, unitOfMeasure);
    this.drawHorizontal(intersectables, width, unitOfMeasure);
    this.drawOverallText(height, width, unitOfMeasure);
    this.drawPartialNotches(
      intersectables.filter(i => i instanceof Stick && i.partialNotchDimension > 0).map(i => i as Stick),
      height,
      width,
      unitOfMeasure
    );
    this.drawShopBreaks(
      intersectables.filter(i => i instanceof Stick).map(i => i as Stick),
      height,
      width,
      unitOfMeasure
    );
  }

  private drawVertical(intersectables: ReadonlyArray<Intersectable>, height: number, unitOfMeasure: UnitOfMeasure) {
    const points = [0, height]
      .concat(
        intersectables
          .map(i => i.leftPoints.map(l => l.y))
          .selectMany(i => i)
          .concat(intersectables.map(i => i.rightPoints.map(l => l.y)).selectMany(i => i))
      )
      .distinct()
      .sort((a, b) => (a < b ? -1 : 1));
    this.drawLine(this._distanceFromEdge, points[0], this._distanceFromEdge, points[points.length - 1]);
    for (let i = 0; i < points.length; i++) {
      this.drawLine(this._distanceFromEdge / 2, points[i], this._distanceFromEdge * 1.5, points[i]);
      if (points[i + 1]) {
        this.drawLine(
          this._distanceFromEdge,
          (points[i] + points[i + 1]) / 2,
          this._distanceFromEdge * (i.mod(2) ? 2 : 3),
          (points[i] + points[i + 1]) / 2,
          distanceStyles
        );
        const value = (points[i + 1] - points[i]).toDimension('frame', unitOfMeasure);
        this.drawText(
          this._distanceFromEdge * (i.mod(2) ? 2 : 3) - value.length * LineMaker.characterWidth,
          (points[i] + points[i + 1]) / 2 + LineMaker.characterHeight / 2,
          value
        );
      }
    }
  }

  private drawHorizontal(intersectables: ReadonlyArray<Intersectable>, width: number, unitOfMeasure: UnitOfMeasure) {
    const points = [0, width]
      .concat(
        intersectables
          .map(i => i.topPoints.map(l => l.x))
          .selectMany(i => i)
          .concat(intersectables.map(i => i.bottomPoints.map(l => l.x)).selectMany(i => i))
      )
      .distinct()
      .sort((a, b) => (a < b ? -1 : 1));
    this.drawLine(points[0], this._distanceFromEdge, points[points.length - 1], this._distanceFromEdge);
    for (let i = 0; i < points.length; i++) {
      this.drawLine(points[i], this._distanceFromEdge / 2, points[i], this._distanceFromEdge * 1.5);
      if (points[i + 1]) {
        this.drawLine(
          (points[i] + points[i + 1]) / 2,
          this._distanceFromEdge,
          (points[i] + points[i + 1]) / 2,
          this._distanceFromEdge * (i.mod(2) ? 2 : 3),
          distanceStyles
        );
        const value = (points[i + 1] - points[i]).toDimension('frame', unitOfMeasure);
        this.drawText(
          (points[i] + points[i + 1]) / 2 - (value.length * LineMaker.characterWidth) / 2,
          this._distanceFromEdge * (i.mod(2) ? 2 : 3),
          value
        );
      }
    }
  }

  private drawOverallText(height: number, width: number, unitOfMeasure: UnitOfMeasure) {
    const value = `${width.toDimension('frame', unitOfMeasure)} X ${height.toDimension('frame', unitOfMeasure)}`;
    this.drawText(
      this._distanceFromEdge / 2 - value.length * LineMaker.characterWidth,
      this._distanceFromEdge / 2 - LineMaker.characterHeight / 2,
      value
    );
  }

  private drawShopBreaks(sticks: Stick[], height: number, width: number, unitOfMeasure: UnitOfMeasure) {
    for (const stick of sticks) {
      const direction =
        stick.orientation === Orientation.Vertical
          ? stick.points.average(_ => _.x) < width / 2
            ? Direction.Right
            : Direction.Left
          : stick.points.average(_ => _.y) < height / 2
          ? Direction.Down
          : Direction.Up;
      for (const shopBreak of stick.shopBreaks) {
        const value = `SB@${shopBreak.toDimension('frame', unitOfMeasure)}`;
        switch (direction) {
          case Direction.Left:
            const leftPoint = { x: stick.leftPoints.min(_ => _.x), y: stick.topPoints.min(_ => _.y) + shopBreak };
            this.drawLine(leftPoint.x, leftPoint.y, leftPoint.x + this._distanceFromEdge, leftPoint.y, distanceStyles);
            this.drawText(
              leftPoint.x + this._distanceFromEdge - LineMaker.characterWidth * value.length,
              leftPoint.y + LineMaker.characterHeight / 2,
              value
            );
            break;
          case Direction.Up:
            const upPoint = { x: stick.leftPoints.min(_ => _.x) + shopBreak, y: stick.topPoints.min(_ => _.y) };
            this.drawLine(upPoint.x, upPoint.y, upPoint.x, upPoint.y + this._distanceFromEdge, distanceStyles);
            this.drawText(
              upPoint.x - (LineMaker.characterWidth * value.length) / 2,
              upPoint.y + this._distanceFromEdge,
              value
            );
            break;
          case Direction.Down:
            const downPoint = { x: stick.leftPoints.min(_ => _.x) + shopBreak, y: stick.bottomPoints.max(_ => _.y) };
            this.drawLine(downPoint.x, downPoint.y, downPoint.x, downPoint.y - this._distanceFromEdge, distanceStyles);
            this.drawText(
              downPoint.x - (LineMaker.characterWidth * value.length) / 2,
              downPoint.y - this._distanceFromEdge + LineMaker.characterHeight,
              value
            );
            break;
          case Direction.Right:
            const rightPoint = {
              x: stick.rightPoints.max(_ => _.x),
              y: stick.topPoints.min(_ => _.y) + shopBreak,
            };
            this.drawLine(
              rightPoint.x,
              rightPoint.y,
              rightPoint.x - this._distanceFromEdge,
              rightPoint.y,
              distanceStyles
            );
            this.drawText(rightPoint.x - this._distanceFromEdge, rightPoint.y + LineMaker.characterHeight / 2, value);
            break;
        }
        // this.drawText(x, y, value);
      }
    }
  }

  private drawPartialNotches(sticks: Stick[], height: number, width: number, unitOfMeasure: UnitOfMeasure) {
    for (const stick of sticks) {
      const direction = stick.points.average(_ => _.x) < width / 2 ? Direction.Left : Direction.Right;
      const value = `SC@${stick.partialNotchDimension.toDimension('frame', unitOfMeasure)}`;
      switch (direction) {
        case Direction.Left:
          const leftPoint = {
            x: stick.leftPoints.min(_ => _.x),
            y: stick.topPoints.min(_ => _.y) + stick.length - stick.partialNotchDimension / 2,
          };
          this.drawLine(leftPoint.x, leftPoint.y, leftPoint.x + this._distanceFromEdge, leftPoint.y, distanceStyles);
          this.drawText(
            leftPoint.x + this._distanceFromEdge - LineMaker.characterWidth * value.length,
            leftPoint.y + LineMaker.characterHeight / 2,
            value
          );
          break;
        case Direction.Right:
          const rightPoint = {
            x: stick.rightPoints.max(_ => _.x),
            y: stick.topPoints.min(_ => _.y) + stick.length - stick.partialNotchDimension / 2,
          };
          this.drawLine(
            rightPoint.x,
            rightPoint.y,
            rightPoint.x - this._distanceFromEdge,
            rightPoint.y,
            distanceStyles
          );
          this.drawText(rightPoint.x - this._distanceFromEdge, rightPoint.y + LineMaker.characterHeight / 2, value);
          break;
      }
    }
  }

  private drawLine(x1: number, y1: number, x2: number, y2: number, styles?: { [key: string]: string }) {
    const line = createSVGElement('line');
    line.classList.add('line');
    for (const key in lineStyles) {
      line.style[key as CSSPropKey] =  lineStyles[key];
    }
    if (styles) {
      for (const key in styles) {
        line.style[key as CSSPropKey] =  styles[key];
      }
    }
    line.x1.baseVal.value = x1;
    line.y1.baseVal.value = y1;
    line.x2.baseVal.value = x2;
    line.y2.baseVal.value = y2;
    this.container.appendChild(line);
  }

  private drawText(x: number, y: number, value: string) {
    const tspan = pipe(
      setStyles(textStyles),
      addClass('tspan'),
      setTextContent(value),
    )(createSVGElement('tspan'))

    const xLength = this.svg.createSVGLength();
    xLength.value = x;
    tspan.x.baseVal.appendItem(xLength);

    const yLength = this.svg.createSVGLength();
    yLength.value = y;
    tspan.y.baseVal.appendItem(yLength);

    this.text.appendChild(tspan);
    appendChildElement(tspan)(this.text);
  }

  destroy() {
    while (this.text.children.length > 0) {
      this.text.children[0].remove();
    }
    while (this.container.children.length > 0) {
      this.container.children[0].remove();
    }
  }
}
