import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { convertMeasurement } from '../../core/functions/convertMeasurement';
import { uomSwitch } from '../../core/functions/uomSwitch';

export class DoorBaseData {
  data: Record<string, any> = {
    DL: {
      Standard: {
        12: {
          12: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        32: {
          12: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        36: {
          12: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        42: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        48: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
      },
      'Lead Lined': {
        12: {
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        36: {
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        48: {
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        58: {
          84: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
          108: {
            '16 Gauge': {},
          },
        },
      },
      'Steel Stiffened': {
        12: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        32: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        36: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        42: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        48: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        58: {
          12: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          86: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
          108: {
            '16 Gauge': {},
          },
          120: {
            '16 Gauge': {},
          },
        },
      },
      Impact: {
        12: {
          12: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          86: {
            '16 Gauge': {},
          },
        },
        32: {
          12: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          86: {
            '16 Gauge': {},
          },
        },
        36: {
          12: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          86: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
        },
        42: {
          12: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          86: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
        },
        48: {
          12: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          86: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
        },
      },
      Insulated: {
        12: {
          12: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        32: {
          12: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        36: {
          12: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '20 Gauge': {},
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        42: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
        48: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
          },
        },
      },
      STC: {
        12: {
          80: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
        },
        36: {
          80: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
        },
        48: {
          80: {
            '16 Gauge': {},
          },
          84: {
            '16 Gauge': {},
          },
          96: {
            '16 Gauge': {},
          },
        },
      },
      Embossed: {
        32: {
          80: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '18 Gauge': {
              HD2: {},
              E6: {},
              E1: {},
              E4: {},
            },
          },
        },
        36: {
          80: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '18 Gauge': {
              HD2: {},
              E6: {},
              E1: {},
              E4: {},
            },
          },
        },
        42: {
          80: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
          },
          84: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
          },
          96: {
            '18 Gauge': {
              HD2: {},
              E1: {},
              E4: {},
            },
          },
        },
      },
      'Impact (Embossed)': {
        36: {
          80: {
            '16 Gauge': {
              E6: {},
              E4TL: {},
              HD2A: {},
              HD2: {},
              E1: {},
            },
          },
          84: {
            '16 Gauge': {
              E6: {},
              E4TL: {},
              HD2A: {},
              HD2: {},
              E1: {},
            },
          },
          96: {
            '16 Gauge': {
              E6: {},
              E4TL: {},
              HD2A: {},
              HD2: {},
              E1: {},
            },
          },
        },
        32: {
          80: {
            '16 Gauge': {
              E6: {},
              E4TL: {},
              HD2A: {},
              HD2: {},
              E1: {},
            },
          },
          84: {
            '16 Gauge': {
              E6: {},
              E4TL: {},
              HD2A: {},
              HD2: {},
              E1: {},
            },
          },
          96: {
            '16 Gauge': {
              E6: {},
              E4TL: {},
              HD2A: {},
              HD2: {},
              E1: {},
            },
          },
        },
      },
      'STC (Embossed)': {
        36: {
          80: {
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '16 Gauge': {
              E6: {},
            },
          },
        },
        42: {
          80: {
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '16 Gauge': {
              E6: {},
            },
          },
        },
      },
    },
    DE: {
      'Lead Lined': {
        12: {
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        36: {
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        48: {
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        58: {
          84: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
      },
      Bullet: {
        12: {
          84: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
          120: {
            '14 Gauge': {},
          },
        },
        36: {
          84: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
          120: {
            '14 Gauge': {},
          },
        },
        48: {
          84: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
          120: {
            '14 Gauge': {},
          },
        },
        58: {
          84: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
          120: {
            '14 Gauge': {},
          },
        },
      },
      Standard: {
        12: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        32: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        36: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        42: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        48: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
      },
      Insulated: {
        12: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        32: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        36: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        42: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        48: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
      },
      'Steel Stiffened': {
        12: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        32: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        36: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        42: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        48: {
          12: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '18 Gauge': {},
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        58: {
          12: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          86: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          108: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          120: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
      },
      Impact: {
        12: {
          12: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
        },
        32: {
          12: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
        },
        36: {
          12: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
        },
        42: {
          12: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
        },
        48: {
          12: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
        },
      },
      STC: {
        12: {
          80: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        36: {

          80: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
        48: {

          80: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          84: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
          96: {
            '16 Gauge': {},
            '14 Gauge': {},
          },
        },
      },
      Embossed: {
        32: {
          80: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '18 Gauge': {
              HD2: {},
              E6: {},
              E1: {},
              E4: {},
            },
          },
        },
        36: {
          80: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '18 Gauge': {
              HD2: {},
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              E1: {},
              E4: {},
              E2: {},
            },
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '18 Gauge': {
              HD2: {},
              E6: {},
              E1: {},
              E4: {},
            },
          },
        },
        42: {
          80: {
            '18 Gauge': {
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              HD2: {},
              E1: {},
              E4: {},
              E2: {},
            },
          },
          84: {
            '18 Gauge': {
              HD2A: {},
              E6: {},
              EG: {},
              E2G: {},
              HD2: {},
              E1: {},
              E4: {},
              E2: {},
            },
          },
          96: {
            '18 Gauge': {
              HD2: {},
              E1: {},
              E4: {},
            },
          },
        },
      },
      'STC (Embossed)': {
        36: {
          80: {
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '16 Gauge': {
              E6: {},
            },
          },
        },
        42: {
          80: {
            '16 Gauge': {
              E6: {},
            },
          },
          84: {
            '16 Gauge': {
              E6: {},
            },
          },
          96: {
            '16 Gauge': {
              E6: {},
            },
          },
        },
      },
    },
    DP: {
      Standard: {
        30: {
          80: {
            '18 Gauge': {},
            '20 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '20 Gauge': {},
          },
        },
        32: {
          80: {
            '18 Gauge': {},
            '20 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '20 Gauge': {},
          },
        },
        36: {
          80: {
            '18 Gauge': {},
            '20 Gauge': {},
          },
          84: {
            '18 Gauge': {},
            '20 Gauge': {},
          },
          96: {
            '18 Gauge': {},
          },
        },
        42: {
          80: {
            '18 Gauge': {},
          },
          84: {
            '18 Gauge': {},
          },
        },
        44: {
          80: {
            '18 Gauge': {},
          },
          84: {
            '18 Gauge': {},
          },
        },
        48: {
          80: {
            '18 Gauge': {},
          },
          84: {
            '18 Gauge': {},
          },
          96: {
            '18 Gauge': {},
          },
        },
        40: {
          80: {
            '18 Gauge': {},
          },
          84: {
            '18 Gauge': {},
          },
        },
      },
      Embossed: {
        36: {
          80: {
            '18 Gauge': {
              E6: {},
              HD2: {},
            },
          },
          84: {
            '18 Gauge': {
              E6: {},
              HD2: {},
            },
          },
          96: {
            '18 Gauge': {
              E6: {},
              HD2: {},
            },
          },
        },
      },
    },
    DF: {
      Standard: {
        36: {
          80: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
        },
        48: {
          80: {
            '14 Gauge': {},
          },
          84: {
            '14 Gauge': {},
          },
          86: {
            '14 Gauge': {},
          },
          96: {
            '14 Gauge': {},
          },
        },
      },
    },
  };

  get(series: string, subType: string, unitOfMeasure: UnitOfMeasure, width?: string, height?: string, gauge?: string) {
    if (!series || !subType) {
      return [];
    }
    let category = this.data[series][subType];
    const widths = Object.keys(category ?? {}).map(key => `${key}"`);
    if (!width) {
      return widths;
    }
    const lookupWidth =
      unitOfMeasure === UnitOfMeasure.Imperial ? width : convertMeasurement(width, 'door', UnitOfMeasure.Imperial);
    const widthKey = Object.keys(this.data[series][subType] ?? {}).first(
      key => uomSwitch(lookupWidth, 'door', unitOfMeasure) <= uomSwitch(`${key}"`, 'door', unitOfMeasure)
    );
    if(!widthKey) {
      return [];
    }
    category = category[widthKey];
    if (!category) {
      return [];
    }
    const heights = Object.keys(category ?? {}).map(key => `${key}"`);
    if (!height) {
      return heights;
    }
    const lookupHeight =
      unitOfMeasure === UnitOfMeasure.Imperial ? height : convertMeasurement(height, 'door', unitOfMeasure);
    const heightKey = Object.keys(category).first(
      key => uomSwitch(lookupHeight, 'door', unitOfMeasure) <= uomSwitch(`${key}"`, 'door', unitOfMeasure)
    );
    category = category[heightKey];
    if (!category) {
      return [];
    }
    if (!gauge) {
      return Object.keys(category);
    }
    return Object.keys(category[gauge] ?? {});
  }
}
