import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core'
import { DoorPrepSpecialReferencePoint } from '../../../enums/door-prep-special-reference-point'
import { IOHStopHolderPrep } from '../../../interfaces/preps/i-oh-stop-holder-prep'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

@Component({
  selector: 'lib-oh-stop-holder-prep',
  templateUrl: './oh-stop-holder-prep.component.html',
  styleUrls: ['./oh-stop-holder-prep.component.scss', '../prep-base.component.scss']
})
export class OhStopHolderPrepComponent extends PrepBaseComponent implements OnInit {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent

  code = this.specialPrepCode
  set prep(value: IOHStopHolderPrep) {
    this.prepsComponent.configService.currentDoor.ohStopHolderPrep = value
  }
  get prep(): IOHStopHolderPrep {
    return this.prepsComponent.configService.currentDoor.ohStopHolderPrep
  }
  referencePoints: DoorPrepSpecialReferencePoint[]
  referencePoint: DoorPrepSpecialReferencePoint

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.OH_STOP_HOLDER)
    if (!this.prep) {
      this.prep = {
        id: this.specialPrepCode.id,
        code: this.specialPrepCode.code,
        degreeOfOpening: '100'
      } as IOHStopHolderPrep
    }
    this.setSpecial()
  }

  ngOnInit(): void {}
}
