import { Directive, Input } from '@angular/core'
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms'
import { InputTransformFn, InputType, transformFunctions } from '../helpers/input-transforms'
import { AutoCompleteDirective } from './autocomplete.directive'

/**
 * This directive is used to validate that the value of a form control is one of the values in the array passed to the directive.
 */
@Directive({
  selector: '[libValidValueValidator]',
  standalone: true,
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidValueValidatorDirective, multi: true }],
  hostDirectives: [AutoCompleteDirective]
})
export class ValidValueValidatorDirective<T extends InputType> implements Validator {
  @Input() libValidValueValidator: T[] = []
  @Input() validatorTransformFn: InputTransformFn = 'none'

  validate(control: AbstractControl): ValidationErrors | null {
    const value = control.value
    if (!value) return null
    const transformedValue = value
    if (this.libValidValueValidator.length === 0) return null
    const validValues = this.libValidValueValidator.map((validValue) =>
      transformFunctions[this.validatorTransformFn](validValue)
    )
    return validValues.includes(transformedValue) ? null : { invalidValue: true }
  }
}
