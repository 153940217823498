<div class="left">
  <div class="top">
    <input
    type="search"
    ngModel
    (ngModelChange)="search$.next($event)"
    [placeholder]="'ACTIONS.search' | translate"
    />
  </div>
  <div class="bottom">
    <div class="prep" [ngClass]="{ active: code?.id === null }" (click)="setNewCode()">{{'newPrep' | translate}}...</div>
    <div
      class="prep"
      [ngClass]="{ active: c === code }"
      *ngFor="let c of codes$ | async"
      (click)="code = c"
      [title]="c.code"
    >
      {{ c.code }}
    </div>
  </div>
</div>
<div class="right">
  <mat-progress-bar mode="indeterminate" [ngStyle]="{visibility: ((dataLoading$ | async) || loading)? 'visible': 'hidden'}"></mat-progress-bar>
  <lib-prep
    (loading)="loading = $event"
    *ngIf="code"
    [value]="code"
    (value$)="addPrepCode($event)"
    [codeService]="codeService"
    [categoryCodeService]="categoryCodeService"
    [pricingCategories]="pricingCategories"
  ></lib-prep>
</div>
