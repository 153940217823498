import { CoreConstants } from '../../core/core.constants';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { guid } from '@oeo/common';
import { nameOf } from '@oeo/common';
import { uomSwitch } from '../../core/functions/uomSwitch';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { Intersectable } from '../abstracts/intersectable';
import { Direction } from '../enums/direction';
import { GlassType } from '../enums/glass-type';
import { GlassExport } from './exports/glass-export';
import { FrameElevation } from './frame-elevation';
import { CSSPropKey } from '@oeo/common'

const glassClass = 'glass';
const styles: Record<string, string> = {
  fill: '#3f65bf',
  fillOpacity: '0.35',
  stroke: '#3f65bf',
  strokeDasharray: '1px',
};

export class Glass extends Intersectable {
  static intersectableName: string = 'Glass';

  get imperialWidth() {
    return this.width.toDimension('frame', this.unitOfMeasure);
  }

  get imperialHeight() {
    return this.height.toDimension('frame', this.unitOfMeasure);
  }

  get imperialThickness() {
    return this.thickness.toDimension('frame', this.unitOfMeasure);
  }

  get thickness() {
    return this.frameElevation.glassThickness;
  }

  get position() {
    return this.frameElevation.glazingBeadLocation;
  }

  type: GlassType = GlassType.Glass;

  get topPoints() {
    return this.points
      .sort((a, b) => (a.y < b.y ? -1 : 1))
      .slice(0, 2)
      .sort((a, b) => (a.x < b.x ? -1 : 1));
  }

  get bottomPoints() {
    return this.points
      .sort((a, b) => (a.y < b.y ? 1 : -1))
      .slice(0, 2)
      .sort((a, b) => (a.x < b.x ? -1 : 1));
  }

  get rightPoints() {
    return this.points
      .sort((a, b) => (a.x < b.x ? 1 : -1))
      .slice(0, 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
  }

  get leftPoints() {
    return this.points
      .sort((a, b) => (a.x < b.x ? -1 : 1))
      .slice(0, 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
  }

  static fromXML(value: string): GlassExport {
    const doc = parseFromXMLString(value);
    return {
      id: guid(),
      exportType: Glass.intersectableName,
      type: Intersectable.parseXML(
        doc,
        nameOf((_: Glass) => _.type)
      ) as GlassType,
      points: JSON.parse(
        Intersectable.parseXML(
          doc,
          nameOf((_: Glass) => _.points)
        )
      ),
    };
  }

  constructor(frameElevation: FrameElevation, container: SVGGElement, x: number, y: number, editable: boolean = true) {
    super(frameElevation, container, glassClass, editable);
    for (const key in styles) {
      this.element.style[key as CSSPropKey] = styles[key];
    }
    for (let i = 0; i < 4; i++) {
      this.element.points.appendItem(this.svg.transformPoint(x, y));
    }
    this.fill();
  }

  static fromJSON(
    frameElevation: FrameElevation,
    container: SVGGElement,
    json: GlassExport,
    editable: boolean = true
  ): Glass {
    const glass = new Glass(frameElevation, container, 0, 0, editable);
    for (let i = 0; i < json.points.length; i++) {
      glass.points[i].x = json.points[i].x;
      glass.points[i].y = json.points[i].y;
    }
    glass.type = json.type;
    return glass;
  }

  protected beforeMoveUp(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected beforeMoveDown(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected beforeMoveRight(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected beforeMoveLeft(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected afterMoveUp(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected afterMoveDown(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected afterMoveRight(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected afterMoveLeft(points: Partial<DOMPoint>[], sticky: boolean) {}

  isValid() {
    const minLength = uomSwitch('6"', 'frame', this.unitOfMeasure);
    if (this.width < minLength) {
      return false;
    }
    if (this.height < minLength) {
      return false;
    }
    return true;
  }

  destroy() {
    this.element.remove();
    this.element = null;
    this.container = null;
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  toJSON(): GlassExport {
    return {
      id: this.id,
      exportType: Glass.intersectableName,
      points: this.points.map(pt => ({ x: pt.x, y: pt.y })),
      type: this.type,
    };
  }

  toXML(): string {
    return `
    <category id="Glass">
      <description>Glass</description>
      <option id="Glass">
        <property id="Height">${
          this.unitOfMeasure === UnitOfMeasure.Imperial
            ? this.height / CoreConstants.multipliers.frameElevation
            : this.height
        }</property>
        <property id="Width">
        ${
          this.unitOfMeasure === UnitOfMeasure.Imperial
            ? this.width / CoreConstants.multipliers.frameElevation
            : this.width
        }
        </property>
        <property id="Points">${JSON.stringify(this.points.map(p => ({ x: p.x, y: p.y })))}</property>
        <property id="Type">${this.type}</property>
        <property id="Position">${this.position}</property>
      </option>
    </category>`;
  }

  private fill() {
    const arr = [
      {
        points: () => [this.points[0], this.points[1]],
        point: (pt: DOMPoint) => ({ x: pt.x, y: pt.y - 1 }),
        modifier: (pt: DOMPoint) => (pt.y -= 1),
        direction: Direction.Up,
      },
      {
        points: () => [this.points[1], this.points[2]],
        point: (pt: DOMPoint) => ({ x: pt.x + 1, y: pt.y }),
        modifier: (pt: DOMPoint) => (pt.x += 1),
        direction: Direction.Right,
      },
      {
        points: () => [this.points[2], this.points[3]],
        point: (pt: DOMPoint) => ({ x: pt.x, y: pt.y + 1 }),
        modifier: (pt: DOMPoint) => (pt.y += 1),
        direction: Direction.Down,
      },
      {
        points: () => [this.points[0], this.points[3]],
        point: (pt: DOMPoint) => ({ x: pt.x - 1, y: pt.y }),
        modifier: (pt: DOMPoint) => (pt.x -= 1),
        direction: Direction.Left,
      },
    ];
    for (const obj of arr) {
      while (obj.points().every(pt => !this.doesIntersect(obj.point(pt), obj.direction))) {
        obj.points().forEach(pt => obj.modifier(pt));
      }
    }
  }
}
