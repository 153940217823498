import { PurchaseOrderLineItem } from './purchaseOrder.model'

export class Brand {
  name: string
  products: PurchaseOrderLineItem[]
  constructor(name: string) {
    this.name = name
    this.products = []
  }
}

export class ProductsByBrand {
  //  Keep alphabetized so they show up alphabetically in view.
  ADS?: Brand
  ADV?: Brand
  DEX?: Brand
  FAL?: Brand
  GLY?: Brand
  IVE?: Brand
  LCN?: Brand
  LOC?: Brand
  SCH?: Brand
  SCE?: Brand
  VON?: Brand
  ZER?: Brand
  constructor() {
    this.ADS = new Brand('AD Systems')
    this.ADV = new Brand('Advantage')
    this.DEX = new Brand('Dexter')
    this.FAL = new Brand('Falcon')
    this.GLY = new Brand('Glynn-Johnson')
    this.IVE = new Brand('Ives')
    this.LCN = new Brand('LCN')
    this.LOC = new Brand('Locknetics')
    this.SCH = new Brand('Schlage')
    this.SCE = new Brand('Schlage Electronics')
    this.VON = new Brand('Von Duprin')
    this.ZER = new Brand('Zero')
  }
}
