import { DoorPrepReferencePoint } from '../../door-elevation/enums/door-prep-reference-point';
import { DoorPrepSpecialReferencePoint } from '../../door-elevation/enums/door-prep-special-reference-point';
import { IPrepConversion } from '../../door-elevation/interfaces/preps/i-prep-conversion';
import { FramePrepReferencePoint } from '../../frame-elevation/enums/frame-prep-reference-point';
import { nameOf } from '@oeo/common';
import { IQuestion } from './i-question';

export interface IPrep {
  id: number;
  code: string;
  locationType: string;
  location: string | string[];
  fixedLocation: boolean;
  referencePoint: DoorPrepReferencePoint | FramePrepReferencePoint;
  specialReferencePoint?: DoorPrepSpecialReferencePoint;
  manufacturer?: string;
  hardwareType?: string;
  productId?: string;
  questions?: IQuestion[];
  pricingCategory?: string;
  conduit?: string;
  properties?: () => Record<string, ((value: string) => any)>;
}

export class Prep implements IPrep, IPrepConversion {
  id: number;
  code: string;
  locationType: string;
  location: string | string[];
  fixedLocation: boolean;
  referencePoint: DoorPrepReferencePoint;
  manufacturer?: string;
  hardwareType?: string;
  productId?: string;
  questions?: IQuestion[];
  specialReferencePoint: DoorPrepSpecialReferencePoint;
  pricingCategory: string;
  conduit?: string;
  properties(): Record<string, ((value: string) => any)> {
    return {
      [nameOf((_: IPrep) => _.id)]: (value: string) => parseInt(value, 10),
      [nameOf((_: IPrep) => _.code)]: (value: string) => value,
      [nameOf((_: IPrep) => _.locationType)]: (value: string) => value,
      [nameOf((_: IPrep) => _.location)]: (value: string) => value,
      [nameOf((_: IPrep) => _.fixedLocation)]: (value: string) => value.searchFor('true') === true,
      [nameOf((_: IPrep) => _.referencePoint)]: (value: string) => value,
      [nameOf((_: IPrep) => _.specialReferencePoint)]: (value: string) => value,
      [nameOf((_: IPrep) => _.pricingCategory)]: (value: string) => value,
      [nameOf((_: IPrep) => _.conduit)]: (value: string) => value,
      special: (value: string) => null,
    };
  }
}
