import { getEnumValues } from '@oeo/common';
import { AdditionalLabel } from '../../core/enums/additional-label';
import { Astragal } from '../enums/astragal';
import { DoorMetalMylar } from '../enums/door-metal-mylar';

export function additionalLabelAstragal(astragals: Astragal[], additionalLabel: AdditionalLabel): void {
  if (additionalLabel === AdditionalLabel.BULLET) {
    astragals.remove(Astragal.Z);
  }
}

export function additionalLabelMetalMylar(metalMylars: DoorMetalMylar[], additionalLabel: AdditionalLabel) {
  if (additionalLabel === AdditionalLabel.FBC) {
    getEnumValues(DoorMetalMylar)
      .filter(metalMylar => !metalMylars.any(m => metalMylar === m))
      .forEach(metalMylar => metalMylars.push(metalMylar));
  }
}
