import { gcd } from '../functions/gcd';
import { UnitOfMeasure } from '../enums/unit-of-measure';
import { CoreConstants } from '../core.constants';

export {};

declare global {
  interface Number {
    mod(m: number): number;
    toDimension(type: 'door' | 'frame', unitOfMeasure: UnitOfMeasure, includeMeasure?: boolean): string;
    cleanAsDimension(type: 'door' | 'frame', unitOfMeasure: UnitOfMeasure): number;
  }
}

function mod(this: number, n: number): number {
  return ((this % n) + n) % n;
}

function toImperial(value: number, type: 'door' | 'frame') {
  let multiplier = 1;
  switch (type) {
    case 'door':
      multiplier = CoreConstants.multipliers.doorElevation;
      break;
    case 'frame':
      multiplier = CoreConstants.multipliers.frameElevation;
      break;
  }
  const inches = Math.floor(value / multiplier);
  const decimal = (value / multiplier - inches) * 1000;
  const denominator = gcd(decimal, 1000);
  const fraction = decimal === 0 ? '' : `${decimal / denominator}/${1000 / denominator}`;
  if (inches && fraction) {
    return `${inches} ${fraction}"`;
  } else if (inches && !fraction) {
    return `${inches}"`;
  } else if (!inches && fraction) {
    return `${fraction}"`;
  } else {
    return `0"`;
  }
}

function toMetric(value: number, includeMeasure: boolean) {
  return `${Math.floor(value)}${includeMeasure ? 'mm' : ''}`;
}

function toDimension(
  this: number,
  type: 'door' | 'frame',
  unitOfMeasure: UnitOfMeasure,
  includeMeasure: boolean = true
): string {
  switch (unitOfMeasure) {
    case UnitOfMeasure.Imperial:
      return toImperial(this, type);
    case UnitOfMeasure.Metric:
      return toMetric(this, includeMeasure);
  }
}

function cleanAsDimension(
  this: number,
  type: 'door' | 'frame',
  unitOfMeasure: UnitOfMeasure,
  includeMeasure?: boolean
): number {
  return this.toDimension(type, unitOfMeasure, includeMeasure).fromDimension(type, unitOfMeasure);
}

Number.prototype.mod = mod;
Number.prototype.toDimension = toDimension;
Number.prototype.cleanAsDimension = cleanAsDimension;

export {}
