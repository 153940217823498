import { Pipe, PipeTransform } from '@angular/core'
import { InputType, transformFunctions } from '../helpers/input-transforms'

@Pipe({
  name: 'libAutoCompleteFilter',
  pure: true,
  standalone: true
})
export class AutoCompleteFilterPipe implements PipeTransform {
  transform<T extends InputType>(
    options: T[],
    filter: string,
    transformFnType: keyof typeof transformFunctions = 'none'
  ): T[] {
    if (!filter) return options
    if (!options) return []
    const filteredOptions = options.filter((option) =>
      transformFunctions[transformFnType](option).toLowerCase().includes(filter.toLowerCase())
    )
    return filteredOptions.length === 0 && options.length > 0 ? options : filteredOptions
  }
}
