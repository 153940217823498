import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { getEnumValues, nameOf } from '@oeo/common';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, skipWhile, takeUntil } from 'rxjs/operators';
import { AdditionalLabel } from '../../core/enums/additional-label';
import { Approval } from '../../core/enums/approval';
import { PrepLocation } from '../../core/enums/prep-location';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { addToMeasurement } from '../../core/functions/addToMeasurement';
import { convertMeasurement } from '../../core/functions/convertMeasurement';
import { uomSwitch } from '../../core/functions/uomSwitch';
import { doorUndercutValidator, dpHeightValidator, dpWidthValidator, measurementValidators, minMaxValidator } from '../../core/validators';
import { Astragal } from '../enums/astragal';
import { DoorBottomChannel, DoorTopChannel } from '../enums/door-channel';
import { DoorCore } from '../enums/door-core';
import { DoorEdge } from '../enums/door-edge';
import { DoorFinishPaint } from '../enums/door-finish-paint';
import { DoorFireRating } from '../enums/door-fire-rating';
import { DoorGauge } from '../enums/door-gauge';
import { DoorGlassInstallation, DoorGlassInstallationOptions } from '../enums/door-glass-installation';
import { DoorGlassType } from '../enums/door-glass-type';
import { DoorLouverInstallation } from '../enums/door-louver-installation';
import { DoorMaterial } from '../enums/door-material';
import { DoorMetalMylar } from '../enums/door-metal-mylar';
import { DoorSeamlessEdge } from '../enums/door-seamless-edge';
import { DoorSeries } from '../enums/door-series';
import { DoorSteelStiffen } from '../enums/door-steel-stiffen';
import { DoorStyle, DutchStyles, LiteLouverOptions } from '../enums/door-style';
import { DoorSubType } from '../enums/door-subtype';
import { DoorSweep } from '../enums/door-sweep';
import { DoorThickness } from '../enums/door-thickness';
import { Astragals } from '../enums/filters/Astragals';
import { DoorAdditionalLabels } from '../enums/filters/DoorAdditionalLabels';
import { DoorApprovals } from '../enums/filters/DoorApprovals';
import { DoorBottomChannels } from '../enums/filters/DoorBottomChannels';
import { DoorCores } from '../enums/filters/DoorCores';
import { DoorEdges } from '../enums/filters/DoorEdges';
import { DoorFireRatings } from '../enums/filters/DoorFireRatings';
import { DoorGauges } from '../enums/filters/DoorGauges';
import { DoorGlassInstallations } from '../enums/filters/DoorGlassInstallations';
import { DoorLouverInstallations } from '../enums/filters/DoorLouverInstallations';
import { DoorMaterials } from '../enums/filters/DoorMaterials';
import { DoorMetalMylars } from '../enums/filters/DoorMetalMylars';
import { DoorSeamlessEdges } from '../enums/filters/DoorSeamlessEdges';
import { DoorSteelStiffens } from '../enums/filters/DoorSteelStiffens';
import { DoorStyles } from '../enums/filters/DoorStyles';
import { DoorSubTypes } from '../enums/filters/DoorSubTypes';
import { DoorSweeps } from '../enums/filters/DoorSweeps';
import { DoorThicknesses } from '../enums/filters/DoorThicknesses';
import { DoorTopChannels } from '../enums/filters/DoorTopChannels';
import { Handing } from '../enums/handing';
import { DoorSeriesInfos } from '../interfaces/door-series-infos';
import { Door } from '../models/door';
import { DoorElevation } from '../models/door-elevation';
import { DoorBaseData } from '../rules/door-base-data';
import { DefaultGlassThickness } from '../constants/default-glass-thickness';

export class DoorFormGroup extends FormGroup {

  get height(): AbstractControl<string> {
    return this.get(nameOf((_: Door) => _.height));
  }

  get width(): AbstractControl<string> {
    return this.get(nameOf((_: Door) => _.width));
  }

  get style(): AbstractControl<DoorStyle> {
    return this.get(nameOf((_: Door) => _.style));
  }

  get handings(): Handing[]{
    const handings = getEnumValues(Handing);
    if(this.doorElevationFormGroup?.doorFormGroups?.length >1 || !(this.doorElevationFormGroup.series.value === DoorSeries.DP) ){
      handings.remove(Handing.NH);
    }
    return handings;
  }

  get handing(): AbstractControl<Handing> {
    return this.get(nameOf((_: Door) => _.handing));
  }

  get active(): AbstractControl<boolean> {
    return this.get(nameOf((_: Door) => _.active));
  }

  get edge(): AbstractControl<DoorEdge> {
    return this.get(nameOf((_: Door) => _.edge));
  }

  get glassInstallations(): DoorGlassInstallation[] {
    return this.hasLite()
      ? DoorGlassInstallations(
          this.doorElevationFormGroup.core.value,
          this.doorElevationFormGroup.steelStiffen.value,
          this.style.value
        )
      : [];
  }

  get glassInstallation(): AbstractControl<DoorGlassInstallation> {
    return this.get(nameOf((_: Door) => _.glassInstallation));
  }

  get glassTypes() {
    return this.hasLite() ? Object.keys(DoorGlassType).map(key => DoorGlassType[key as keyof typeof DoorGlassType]) : []
  }

  get glassType(): AbstractControl<DoorGlassType> {
    return this.get(nameOf((_: Door)=> _.glassType))
  }

  get glassThickness(): AbstractControl<string> {
    return this.get(nameOf((_: Door) => _.glassThickness));
  }

  get astragals(): Astragal[] {
    return Astragals(this.active.value, this.doorElevationFormGroup.isDPSeries, this.doorElevationFormGroup.doorFormGroups.length === 1, DutchStyles.includes(this.style.value));
  }

  get astragal(): AbstractControl<Astragal> {
    return this.get(nameOf((_: Door) => _.astragal));
  }

  get louverInstallations(): DoorLouverInstallation[] {
    return this.hasLouver()
      ? DoorLouverInstallations(
          this.doorElevationFormGroup.core.value,
          this.doorElevationFormGroup.steelStiffen.value
        )
      : [];
  }

  get louverInstallation(): AbstractControl<DoorLouverInstallation> {
    return this.get(nameOf((_: Door) => _.louverInstallation));
  }

  get styles(): DoorStyle[] {
    return DoorStyles(
      this.doorElevationFormGroup.series.value,
      this.doorElevationFormGroup.subType.value,
      this.doorElevationFormGroup.unitOfMeasure.value,
      this.width.value,
      this.doorElevationFormGroup.height.value,
      this.doorElevationFormGroup.gauge.value,
      this.doorElevationFormGroup.additionalLabel.value,
      this.doorElevationFormGroup.core.value,
      this.doorElevationFormGroup.fireRating.value,
      this.doorElevationFormGroup.doorFormGroups.length
    );
  }

  constructor(
    private door: Door,
    private doorElevationFormGroup: DoorElevationFormGroup,
    public readonly position: 'Left' | 'Right'
  ) {
    super({});

    this.addControl(
      nameOf((_: Door) => _.height),
      new FormControl(
        {
          value: this.door.height?.toDimension('door', this.doorElevationFormGroup.unitOfMeasure?.value),
          disabled: true,
        },
        measurementValidators(this.doorElevationFormGroup.unitOfMeasure?.value)
      )
    );

    this.addControl(
      nameOf((_: Door) => _.width),
      new FormControl(this.door.width?.toDimension('door', this.doorElevationFormGroup.unitOfMeasure?.value), [
        ...measurementValidators(this.doorElevationFormGroup.unitOfMeasure?.value),
      ])
    );

    this.addControl(
      nameOf((_: Door) => _.style),
      new FormControl(this.door.style, [Validators.required])
    );

    this.addControl(
      nameOf((_: Door) => _.handing),
      new FormControl(this.handings.any(h => h === this.door.handing) ? this.door.handing : null, [Validators.required])
    );

    this.addControl(
      nameOf((_: Door) => _.active),
      new FormControl(this.door.active, [Validators.required])
    );

    this.addControl(
      nameOf((_: Door) => _.edge),
      new FormControl(this.door.edge, [Validators.required])
    );

    this.addControl(
      nameOf((_: Door) => _.glassInstallation),
      new FormControl({ value: this.door.glassInstallation ?? null, disabled: !this.hasLite() }, [Validators.required])
    );

    this.addControl(
      nameOf((_: Door) => _.glassType),
      new FormControl({ value: this.door.glassType ?? null, disabled: !(this.doorElevationFormGroup.fireRating?.value && this.hasLite() && !this.isCutoutOnly()) }, [Validators.required])
    );

    this.addControl(
      nameOf((_: Door) => _.glassThickness),
      new FormControl(
        {
          value: this.door.glassThickness?.toDimension('door', this.doorElevationFormGroup.unitOfMeasure?.value),
          disabled: !this.hasLite() || this.isCutoutOnly(),
        },
        [Validators.required, ...measurementValidators(this.doorElevationFormGroup.unitOfMeasure.value)]
      )
    );

    this.addControl(
      nameOf((_: Door) => _.louverInstallation),
      new FormControl({ value: this.door.louverInstallation ?? null, disabled: !this.hasLouver() }, [
        Validators.required,
      ])
    );

    this.addControl(
      nameOf((_: Door) => _.astragal),
      new FormControl(this.door.astragal, [])
    );
  }

  hasLite(): boolean {
    return LiteLouverOptions[this.style.value]?.lite;
  }

  hasLouver(): boolean {
    return LiteLouverOptions[this.style.value]?.louver;
  }

  isCutoutOnly(): boolean {
    return DoorGlassInstallationOptions[this.glassInstallation.value as DoorGlassInstallation]?.cutoutOnly
  }
}

export class DoorElevationFormGroup extends FormGroup {
  doorFormGroups: DoorFormGroup[];
  private _destroy$ = new Subject<void>();

  constructor(private doorElevation: DoorElevation, private widthValidators: (uom: UnitOfMeasure) => ValidatorFn[]) {
    super({});
    this.addControl(
      nameOf((_: DoorElevation) => _.unitOfMeasure),
      new FormControl(this.doorElevation.unitOfMeasure, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.height),
      new FormControl(this.doorElevation.height?.toDimension('door', this.unitOfMeasure.value), [
        ...measurementValidators(this.unitOfMeasure.value),
      ])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.width),
      new FormControl(this.doorElevation.width?.toDimension('door', this.unitOfMeasure.value))
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.undercut),
      new FormControl(this.doorElevation.undercut?.toDimension('door', this.unitOfMeasure.value), [
        ...measurementValidators(this.unitOfMeasure.value),
      ])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.series),
      new FormControl(this.doorElevation.series, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.subType),
      new FormControl(this.doorElevation.subType, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.fireRating),
      new FormControl(this.doorElevation.fireRating, [])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.thickness),
      new FormControl(this.doorElevation.thickness, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.additionalLabel),
      new FormControl({ value: this.doorElevation.additionalLabel ?? null, disabled: !this.additionalLabels.any() })
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.approval),
      new FormControl(
        { value: this.doorElevation.approval ?? null, disabled: !this.additionalLabel.value },
        this.additionalLabel.value && ![AdditionalLabel.PP, AdditionalLabel.SMOKE].includes(this.additionalLabel.value) ? [Validators.required] : []
      )
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.metalMylar),
      new FormControl({ value: this.doorElevation.metalMylar ?? null, disabled: !this.metalMylars.any() }, [])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.gauge),
      new FormControl(this.doorElevation.gauge, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.material),
      new FormControl(this.doorElevation.material, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.seamlessEdge),
      new FormControl(this.doorElevation.seamlessEdge, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.core),
      new FormControl(this.doorElevation.core, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.topChannel),
      new FormControl(this.doorElevation.topChannel, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.bottomChannel),
      new FormControl(this.doorElevation.bottomChannel, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.sweep),
      new FormControl({ value: this.doorElevation.sweep ?? null, disabled: !this.sweeps.any() })
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.steelStiffen),
      new FormControl(this.doorElevation.steelStiffen, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.wideInactive),
      new FormControl(this.doorElevation.wideInactive)
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.finishPaint),
      new FormControl(this.doorElevation.finishPaint, [Validators.required])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.doubleEgress),
      new FormControl(this.doorElevation.doubleEgress ?? false, [])
    );

    this.addControl(
      nameOf((_: DoorElevation) => _.prepLocationPreference),
      new FormControl(this.doorElevation.prepLocationPreference, [])
    );
  }

  readonly unitOfMeasures = getEnumValues(UnitOfMeasure)
    .map(key => UnitOfMeasure[key as keyof typeof UnitOfMeasure])
    .orderBy(_ => _);

  get wideInactive(): AbstractControl<boolean> {
    return this.get(nameOf((_: DoorElevation) => _.wideInactive));
  }

  get unitOfMeasure(): AbstractControl<UnitOfMeasure> {
    return this.get(nameOf((_: DoorElevation) => _.unitOfMeasure));
  }

  get width(): AbstractControl<string> {
    return this.get(nameOf((_: DoorElevation) => _.width));
  }

  get height(): AbstractControl<string> {
    return this.get(nameOf((_: DoorElevation) => _.height));
  }

  get undercut(): AbstractControl<string> {
    return this.get(nameOf((_: DoorElevation) => _.undercut));
  }

  readonly serieses = getEnumValues(DoorSeries)
    .orderBy(_ => _);

  get series(): AbstractControl<DoorSeries> {
    return this.get(nameOf((_: DoorElevation) => _.series));
  }

  get subTypes() {
    return DoorSubTypes(this.series.value, this.doorFormGroups.length > 1);
  }

  get subType(): AbstractControl<DoorSubType> {
    return this.get(nameOf((_: DoorElevation) => _.subType));
  }

  get fireRatings(): DoorFireRating[] {
    return DoorFireRatings(this.series.value, this.subType.value);
  }

  get fireRating(): AbstractControl<DoorFireRating> {
    return this.get(nameOf((_: DoorElevation) => _.fireRating));
  }

  get additionalLabels(): AdditionalLabel[] {
    return DoorAdditionalLabels(this.series.value, this.subType.value, this.fireRating.value);
  }

  get additionalLabel(): AbstractControl<AdditionalLabel> {
    return this.get(nameOf((_: DoorElevation) => _.additionalLabel));
  }

  get approvals(): Approval[] {
    return DoorApprovals(
      this.series.value,
      this.subType.value,
      this.gauge.value,
      this.additionalLabel.value,
      this.fireRating.value,
      this.doorFormGroups.length > 1
    );
  }

  get approval(): AbstractControl<Approval> {
    return this.get(nameOf((_: DoorElevation) => _.approval));
  }

  get thicknesses(): DoorThickness[] {
    return DoorThicknesses(this.series.value, this.subType.value);
  }

  get thickness(): AbstractControl<DoorThickness> {
    return this.get(nameOf((_: DoorElevation) => _.thickness));
  }

  get edges(): DoorEdge[] {
    return DoorEdges(
      this.series.value,
      this.subType.value,
      this.gauge.value,
      this.thickness.value
    );
  }

  get seamlessEdges(): DoorSeamlessEdge[] {
    return DoorSeamlessEdges(
      this.series.value,
      this.subType.value,
      this.gauge.value,
      this.approval.value
    );
  }

  get seamlessEdge(): AbstractControl<DoorSeamlessEdge> {
    return this.get(nameOf((_: DoorElevation) => _.seamlessEdge));
  }

  get gauges(): DoorGauge[] {
    return DoorGauges(
      this.series.value,
      this.subType.value,
      this.unitOfMeasure.value,
      (
        ((this.width.value) ?? '').fromDimension('door', this.unitOfMeasure.value) /
        this.doorFormGroups.length
      ).toDimension('door', this.unitOfMeasure.value),
      this.height.value
    );
  }

  get gauge(): AbstractControl<DoorGauge> {
    return this.get(nameOf((_: DoorElevation) => _.gauge));
  }

  get materials(): DoorMaterial[] {
    return DoorMaterials(
      this.series.value,
      this.subType.value,
      this.gauge.value
    );
  }

  get material(): AbstractControl<DoorMaterial> {
    return this.get(nameOf((_: DoorElevation) => _.material));
  }

  get cores(): DoorCore[] {
    return DoorCores(
      this.series.value,
      this.subType.value,
      this.approval.value,
      this.fireRating.value,
      this.steelStiffen.value
    );
  }

  get core(): AbstractControl<DoorCore> {
    return this.get(nameOf((_: DoorElevation) => _.core));
  }

  get topChannels(): DoorTopChannel[] {
    return DoorTopChannels(
      this.series.value,
      this.subType.value,
      this.seamlessEdge.value
    );
  }

  get topChannel(): AbstractControl<DoorTopChannel> {
    return this.get(nameOf((_: DoorElevation) => _.topChannel));
  }

  get bottomChannels(): DoorBottomChannel[] {
    return DoorBottomChannels(
      this.series.value,
      this.subType.value,
      this.seamlessEdge.value
    );
  }

  get bottomChannel(): AbstractControl<DoorBottomChannel> {
    return this.get(nameOf((_: DoorElevation) => _.bottomChannel));
  }

  get sweeps(): DoorSweep[] {
    return DoorSweeps(this.additionalLabel.value, this.bottomChannel.value);
  }

  get sweep(): AbstractControl<DoorSweep> {
    return this.get(nameOf((_: DoorElevation) => _.sweep));
  }

  get steelStiffens(): DoorSteelStiffen[] {
    return DoorSteelStiffens(this.series.value, this.subType.value);
  }

  get steelStiffen(): AbstractControl<DoorSteelStiffen> {
    return this.get(nameOf((_: DoorElevation) => _.steelStiffen));
  }

  get metalMylars(): DoorMetalMylar[] {
    return DoorMetalMylars(this.fireRating.value, this.additionalLabel.value, this.subType.value);
  }

  get metalMylar(): AbstractControl<DoorMetalMylar> {
    return this.get(nameOf((_: DoorElevation) => _.metalMylar));
  }

  get finishPaints(): DoorFinishPaint[] {
    return getEnumValues(DoorFinishPaint)
  }

  get finishPaint(): AbstractControl<DoorFinishPaint> {
    return this.get(nameOf((_: DoorElevation) => _.finishPaint));
  }

  get glassTypes() {
    return  getEnumValues(DoorGlassType)
  }

  get glassType(): AbstractControl<DoorGlassType> {
    return this.get(nameOf((_: DoorElevation)=> _.glassType))
  }

  get showDoubleEgress(){
    return this.series.value === DoorSeries.DE || this.series.value === DoorSeries.DL;
  }

  get doubleEgress(): AbstractControl<boolean> {
    return this.get(nameOf((_: DoorElevation) => _.doubleEgress));
  }

  get prepLocationPreference(): AbstractControl {
    return this.get(nameOf((_: DoorElevation) => _.prepLocationPreference));
  }

  get prepLocationPreferences() {
    return Object.values(PrepLocation)
  }

  get isDPSeries(): boolean {
    return (this.series.value) == DoorSeries.DP
  }

  isDutchStyle(index: number): boolean {
    return DutchStyles.includes(this.doorFormGroups[index].style.value)
  }

  validations: () => { control: AbstractControl; values: () => any[], disable?: ((...args: any[]) => any) }[] = () => [
    { control: this.series, values: () => this.serieses },
    { control: this.subType, values: () => this.subTypes },
    { control: this.gauge, values: () => this.gauges },
    { control: this.thickness, values: () => this.thicknesses },
    ...this.doorFormGroups.map(d => ({ control: d.handing, values: () => d.handings })),
    { control: this.fireRating, values: () => this.fireRatings, disable: ()=> {
      if ([DoorSubType.STC, DoorSubType.STCEMB].any(s=> s === this.subType?.value)) {
        if([Approval.STC42, Approval.STC40].any(a=> a === this.approval.value)){
          return false
        } else {
          return true
        }
      }
      else return false
      }
    },
    { control: this.additionalLabel, values: () => this.additionalLabels },
    { control: this.approval, values: () => this.approvals },
    { control: this.metalMylar, values: () => this.metalMylars },
    { control: this.material, values: () => this.materials },
    ...this.doorFormGroups.map(d => ({ control: d.edge, values: () => this.edges })),
    { control: this.seamlessEdge, values: () => this.seamlessEdges },
    { control: this.steelStiffen, values: () => this.steelStiffens },
    { control: this.core, values: () => this.cores },
    ...this.doorFormGroups.map(d => ({ control: d.style, values: () => d.styles })),
    { control: this.topChannel, values: () => this.topChannels },
    { control: this.bottomChannel, values: () => this.bottomChannels },
    { control: this.finishPaint, values: () => this.finishPaints },
    ...this.doorFormGroups.map(d => ({ control: d.glassInstallation, values: () => d.glassInstallations })),
    ...this.doorFormGroups.map(d => ({ control: d.louverInstallation, values: () => d.louverInstallations })),
    ...this.doorFormGroups.map(d => ({ control: d.glassType, values: () => d.glassTypes })),
  ];

  numericValidations() {
    return [
      {
        control: this.undercut,
        controls: [this.unitOfMeasure, this.additionalLabel, this.approval, this.fireRating],
        validators: () => [
          doorUndercutValidator(
            this.additionalLabel.value,
            this.approval.value,
            this.fireRating.value,
            this.unitOfMeasure.value
          ),
          ...measurementValidators(this.unitOfMeasure.value),
        ],
      },
      {
        control: this.width,
        controls: [this.unitOfMeasure, this.series, this.subType, ...this.doorFormGroups.map(d => d.style)],
        validators: () => [
          ...this.widthValidators(this.unitOfMeasure.value),
          ...measurementValidators(this.unitOfMeasure.value),
          this.pairMinMaxSeriesSubtypeValidator(),
        ],
      },
      {
        control: this.height,
        controls: [
          this.unitOfMeasure,
          this.series,
          this.subType,
          ...this.doorFormGroups.map(d => d.style),
          ...this.doorFormGroups.map(d => d.width),
          this.width,
        ],
        validators: () => [
          ...measurementValidators(this.unitOfMeasure.value),
          ...this.doorFormGroups.map(({width}) =>{
            return dpHeightValidator(this.series.value, this.subType.value, width.value, this.unitOfMeasure.value)}
          ),
          this.minMaxSeriesSubtypeWidthValidator(),
        ],
      },
      ...this.doorFormGroups.map(d => ({
        control: d.width,
        controls: [this.unitOfMeasure, this.width, this.series, this.subType, this.gauge, d.style],
        validators: () => [
          ...measurementValidators(this.unitOfMeasure.value),
          this.minMaxSeriesSubtypeValidator(),
          dpWidthValidator(this.series.value, this.subType.value, this.unitOfMeasure.value),
          this.pairGaugeValidators(),
        ],
      })),
      ...this.doorFormGroups.map(d => ({
        control: d.glassThickness,
        controls: [this.unitOfMeasure],
        validators: () => [...measurementValidators(this.unitOfMeasure.value)],
      })),
    ];
  }

  validateFormOnChange<T>(formField: { control: AbstractControl<T>; values: () => any[], disable?: ((...args: any[]) => any) }): void {
    const values = formField.values();
    if (values.any()) {
      formField.control.enable();
      if (!values.any(v => v === formField.control.value)) {
        formField.control.patchValue(null);
        formField.control.markAsTouched();
        formField.control.updateValueAndValidity();
      }
      if (
        values.length === 1 &&
        !!formField.control.validator &&
        formField.control.validator({} as AbstractControl<T>)?.required
      ) {
        formField.control.patchValue(values.first());
      }
      if(formField.disable && formField.disable()){
        formField.control.patchValue(null);
        formField.control.disable();
      }
    } else {
      formField.control.patchValue(null);
      formField.control.markAsTouched();
      if (!formField.control.validator || !formField.control.validator({} as AbstractControl<T>).required) {
        formField.control.disable();
      }
      formField.control.updateValueAndValidity();
    }
  }

  validateNumericFormOnChange<T>(control: AbstractControl<T>, validators: ValidatorFn[]) {
    control.clearValidators();
    control.setValidators(validators);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  setupValidations(): void {
    this.numericValidations().forEach(f => {
      f.controls.forEach(c => {
        c.valueChanges
          .pipe(
            distinctUntilChanged(),
            skipWhile((val) => !val),
            takeUntil(this._destroy$)
          )
          .subscribe(() => {
            this.validateNumericFormOnChange(f.control, f.validators());
          });
      });
    });

    this.unitOfMeasure.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe((uom) => {
      this.height.patchValue(convertMeasurement(this.height.value, 'door', this.unitOfMeasure.value));
      this.width.patchValue(convertMeasurement(this.width.value, 'door', this.unitOfMeasure.value));
      this.undercut.patchValue(
        convertMeasurement(this.undercut.value, 'door', this.unitOfMeasure.value)
      );
      this.doorFormGroups.forEach((formGroup) => {
        formGroup.width.patchValue(
          convertMeasurement(formGroup.width.value, 'door', this.unitOfMeasure.value))
        formGroup.glassThickness.patchValue(
          convertMeasurement(formGroup.glassThickness.value, 'door', this.unitOfMeasure.value)
        )
      })
    });

    [this.series.valueChanges, this.subType.valueChanges].forEach(x =>
      (x as Observable<DoorSeries | DoorSubType>).pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
        this.additionalLabel.setValidators(
          DoorSeriesInfos[this.series.value]?.subTypes[this.subType.value]
            ?.requiresAdditionalLabel
            ? [Validators.required]
            : []
        );
        this.additionalLabel.updateValueAndValidity();
        this.doorFormGroups.forEach(form => this.applyLiteLouverValidations(form));
        this.validateUndercut(this.series.value)
        this.setUpDefaultDPHanding(this.series.value, this.doorFormGroups)
        /* Set defaults for Top Channel, Bottom Channel & Finish */
        !this.topChannel.value && this.topChannel.patchValue(DoorTopChannel.FLUSH)
        !this.bottomChannel.value && this.bottomChannel.patchValue(DoorBottomChannel.INVERT)
        !this.finishPaint.value && this.finishPaint.patchValue(DoorFinishPaint.PRIMED)
      })
    );

    this.validateUndercut(this.series.value)
    this.fireRating.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
      if (this.metalMylars.any()) {
        this.metalMylar.enable();
        this.metalMylar.setValidators([Validators.required]);
      } else {
        this.metalMylar.disable();
        this.metalMylar.setValidators([]);
        this.metalMylar.setValue(null);
      }
     this.metalMylar.updateValueAndValidity();

      this.doorFormGroups.forEach((form, doorIndex) => {
        if(this.doorElevation.doors.length === 1) {
          this.validateSingleDoorAstragal(form.astragal, this.isDutchStyle(doorIndex));
        }

      });

      this.doorFormGroups.forEach((doorFormGroup) => this.applyGlassTypeValidations(doorFormGroup))
      this.validateUndercut(this.series.value)
    });

    this.doorFormGroups.forEach((form, doorIndex) => {
      form.astragal.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
        const previousAstragalControl = doorIndex > 0 ? this.doorFormGroups[doorIndex - 1].astragal : null;
        this.validatePairDoorAstragal(previousAstragalControl, form.astragal ,this.fireRating.value,  doorIndex);
      })
    })

    this.additionalLabel.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
      if (this.additionalLabel.value) {
        this.approval.setValidators(![AdditionalLabel.PP, AdditionalLabel.SMOKE].includes(this.additionalLabel.value) ? [Validators.required] : []);
        this.approval.enable();
      } else {
        /* Reset the Fire Rating and the Approval Fields if the additional label fields is blank */
        if([DoorSubType.STC, DoorSubType.STCEMB].any(s=> s === this.subType?.value)){
          this.fireRating.patchValue(null)
          this.fireRating.disable()
          this.approval.reset(null)
        }
        this.approval.setValidators([]);
      }
      this.approval.updateValueAndValidity();

      if (this.metalMylars.any()) {
        this.metalMylar.enable();
        this.metalMylar.setValidators([Validators.required]);
      } else {
        this.metalMylar.disable();
        this.metalMylar.setValidators([]);
      }
      this.metalMylar.updateValueAndValidity();
    });

    this.approval.valueChanges.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe((approval: Approval) => {
      if ([DoorSubType.STC, DoorSubType.STCEMB].any(s=> s === this.subType?.value)) {
        if([Approval.STC42, Approval.STC40].any(a=> a === approval)){
          this.fireRating.enable()
        } else {
          /* Reset the Fire Rating Field if the Approval fields is not STC40 or STC42 */
          this.fireRating.reset(null)
          this.fireRating.disable()
        }
      }
    })

    this.applyTransomStyleDimensions = this.initializeTransomStyleDimensions(this.doorFormGroups)
    this.doorFormGroups.forEach((form, doorIndex) => {
      form.style.valueChanges
        .pipe(takeUntil(this._destroy$), distinctUntilChanged())
        .subscribe(() => {
          this.applyLiteLouverValidations(form)
          this.applyTransomStyleDimensions(form, doorIndex)
          if(this.doorElevation.doors.length === 1) {
            this.validateSingleDoorAstragal(form.astragal, this.isDutchStyle(doorIndex));
          }
        });

      form.glassInstallation.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
        if (form.isCutoutOnly()) {
          form.glassThickness.disable();
          form.glassThickness.patchValue(null)
        } else {
          form.glassThickness.enable();
          if(form.glassThickness.value === null) {
            form.glassThickness.patchValue(uomSwitch('1/4"', 'door', this.unitOfMeasure.value).toDimension('door', this.unitOfMeasure.value))
          }
        }
        this.applyGlassTypeValidations(form)
        form.updateValueAndValidity();
      });

      form.glassType.valueChanges.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe((glassType) => {
        if(form.hasLite() && !form.isCutoutOnly()){
          const defaultThickness = DefaultGlassThickness[glassType] ?? '1/4"'
          form.glassThickness.patchValue(uomSwitch(defaultThickness, 'door', this.unitOfMeasure.value).toDimension('door', this.unitOfMeasure.value))
        }
      });

      form.active.valueChanges.pipe(distinctUntilChanged(), takeUntil(this._destroy$)).subscribe(() => {
        if(this.isDutchStyle && this.doorFormGroups.length === 1){
          form.astragal.patchValue(form.astragals.first())
          form.updateValueAndValidity();
        }
      })
    });

    this.validations().forEach((v, i) => {
      v.control.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(val => !!val),
          takeUntil(this._destroy$)
        )
        .subscribe(() => {
          this.validations()
            .skip(i + 1)
            .forEach(validation => this.validateFormOnChange(validation));
        });
    });
    [this.height, this.width, this.undercut].forEach(control => {
      control.valueChanges
        .pipe(
          distinctUntilChanged(),
          filter(val => !!val),
          takeUntil(this._destroy$)
        )
        .subscribe(() => {
          this.validations().forEach(validation => this.validateFormOnChange(validation));
        });
    });

  }

  toElevation(): DoorElevation {
    const elevation = Object.assign(new DoorElevation(), {
      unitOfMeasure: this.unitOfMeasure.value,
      width: (this.width.value)?.fromDimension('door', this.unitOfMeasure.value),
      height: (this.height.value)?.fromDimension('door', this.unitOfMeasure.value),
      undercut: (this.undercut.value)?.fromDimension('door', this.unitOfMeasure.value),
      series: this.series.value,
      subType: this.subType.value,
      fireRating: this.fireRating.value,
      thickness: this.thickness.value,
      additionalLabel: this.additionalLabel.value,
      approval: this.approval.value,
      gauge: this.gauge.value,
      material: this.material.value,
      core: this.core.value,
      topChannel: this.topChannel.value,
      bottomChannel: this.bottomChannel.value,
      sweep: this.sweep.value,
      isPair: this.doorFormGroups.length > 1,
      steelStiffen: this.steelStiffen.value,
      metalMylar: this.metalMylar.value,
      wideInactive: this.wideInactive.value,
      finishPaint: this.finishPaint.value,
      seamlessEdge: this.seamlessEdge.value,
      doubleEgress: this.doubleEgress.value,
      prepLocationPreference: this.prepLocationPreference.value,
    });
    elevation.doors = this.doorFormGroups.map(form =>
      Object.assign(new Door(elevation), {
        width: (form.width.value)?.fromDimension('door', this.unitOfMeasure.value),
        style: form.style.value,
        handing: form.handing.value,
        active: form.active.value,
        edge: form.edge.value,
        glassThickness: (form.glassThickness.value)?.fromDimension('door', this.unitOfMeasure.value),
        glassInstallation: form.glassInstallation.value,
        louverInstallation: form.louverInstallation.value,
        glassType: form.glassType.value,
        astragal: form.astragal.value,
      })
    );
    return elevation;
  }

  destroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  pairGaugeValidators(): ValidatorFn {
    return minMaxValidator(
      uomSwitch('0"', 'door', this.unitOfMeasure.value).toDimension('door', this.unitOfMeasure.value),
      uomSwitch(this.gauge.value === DoorGauge._20 ? '36"' : '1000"', 'door', this.unitOfMeasure.value).toDimension(
        'door',
        this.unitOfMeasure.value
      ),
      'door',
      this.unitOfMeasure.value
    );
  }

  pairMinMaxSeriesSubtypeValidator(): ValidatorFn {
    const widths = new DoorBaseData()
      .get(this.series.value, this.subType.value, this.unitOfMeasure.value)
      .map(width => uomSwitch(width, 'door', this.unitOfMeasure.value));
    return minMaxValidator(
      (widths.min(_ => _) * this.doorFormGroups.length).toDimension('door', this.unitOfMeasure.value),
      (widths.max(_ => _) * this.doorFormGroups.length).toDimension('door', this.unitOfMeasure.value),
      'door',
      this.unitOfMeasure.value
    );
  }

  minMaxSeriesSubtypeValidator(): ValidatorFn {
    const widths = new DoorBaseData()
      .get(this.series.value, this.subType.value, this.unitOfMeasure.value)
      .map(width => uomSwitch(width, 'door', this.unitOfMeasure.value));
    return minMaxValidator(
      widths.min(_ => _).toDimension('door', this.unitOfMeasure.value),
      widths.max(_ => _).toDimension('door', this.unitOfMeasure.value),
      'door',
      this.unitOfMeasure.value
    );
  }

  minMaxSeriesSubtypeWidthValidator(): ValidatorFn {
    const width =
      (
        (this.width.value)?.fromDimension('door', this.unitOfMeasure.value) /
        (this.doorFormGroups.length > 1 ? 2 : 1)
      )?.toDimension('door', this.unitOfMeasure.value) ?? '';
    const heights = new DoorBaseData()
      .get(this.series.value, this.subType.value, this.unitOfMeasure.value, width)
      .map(height => uomSwitch(height, 'door', this.unitOfMeasure.value));
    return minMaxValidator(
      heights.min(_ => _).toDimension('door', this.unitOfMeasure.value),
      heights.max(_ => _).toDimension('door', this.unitOfMeasure.value),
      'door',
      this.unitOfMeasure.value
    );
  }

  private applyLiteLouverValidations(form: DoorFormGroup): void {
    if (form.hasLite() && !form.isCutoutOnly()) {
      form.glassInstallation.enable();
      form.glassThickness.enable();
    } else {
      form.glassInstallation.disable();
      form.glassThickness.disable();
    }
    form.glassInstallation.updateValueAndValidity();
    form.glassThickness.updateValueAndValidity();

    if (form.hasLouver()) {
      form.louverInstallation.enable();
    } else {
      form.louverInstallation.disable();
    }
    form.louverInstallation.updateValueAndValidity();
  }

  private applyGlassTypeValidations(form: DoorFormGroup): void {
    if(this.fireRating.value && form.hasLite() && !form.isCutoutOnly()){
      form.glassType.setValidators([Validators.required])
    }
    else {
      form.glassType.setValidators([])
    }
  }

  /**
   * The initializeTransomStyleDimensions function uses closure to save the previous door styles
   * if the previous style was not Transom and the current style is Transom,
   * 1/16" is added to both the width and height
   *
   * @param formgroups - the formgroups of each of the doors
   * @returns a function that adjusts the width and height of the door, i.e. adds 1/16" to width and height
   */
  private initializeTransomStyleDimensions(formgroups: DoorFormGroup[]){
    const previousStyles: DoorStyle[] = [...formgroups.map((fg)=> fg.style.value)];
    return (form: DoorFormGroup, index: number) => {
      if(previousStyles[index] !== form.style.value && form.style.value === DoorStyle.Transom){

        const adjustedWidth = addToMeasurement(form.width.value, '1/16"', 'door', this.unitOfMeasure.value)
        const adjustedHeight = addToMeasurement(this.height.value, '1/16"', 'door', this.unitOfMeasure.value)
        if(!this.pairMinMaxSeriesSubtypeValidator()(new FormControl(adjustedWidth))){
          form.width.setValue(adjustedWidth);
        }
        if(!this.minMaxSeriesSubtypeWidthValidator()(new FormControl(adjustedHeight))){
          this.height.setValue(adjustedHeight);
        }
      }
      previousStyles[index] = form.style.value;
    }
  }

  /** This is a variable that holds the result of the initializeTransomStyleDimensions function when the form is initialized */
  private applyTransomStyleDimensions(formgroup: DoorFormGroup, index: number){}

  validateUndercut(series: DoorSeries){
    if(series === DoorSeries.DP){
      this.undercut.patchValue(uomSwitch('3/4"', 'door', this.unitOfMeasure.value).toDimension('door', this.unitOfMeasure.value));
      this.undercut.disable()
    }
    else{
      this.undercut.enable()
    }
    this.undercut.updateValueAndValidity()
  }


  setUpDefaultDPHanding (series: DoorSeries, doorFormGroups: DoorFormGroup[]){
    if(series === DoorSeries.DP && doorFormGroups.length === 1){
      doorFormGroups.forEach(({handing})=>{
        handing.patchValue(Handing.NH)
      })
    }
  }

  /** Astragal is required on at least one door for pair doors if the fire rating is 3 HR UL Label A */
  validatePairDoorAstragal(leftDoorAstragal: AbstractControl<Astragal>, control: AbstractControl<Astragal>, fireRating: DoorFireRating, doorIndex: number){
    if (fireRating === DoorFireRating._180 && doorIndex === 1 && !leftDoorAstragal.value) {
      control.setValidators([Validators.required]);
    }
    else {
      control.setValidators([])
    }

    /** Disable the second astragal field if the left door astragal is filled */
    if(doorIndex === 1){
      if (leftDoorAstragal.value) control.disable()
      else control.enable()
    }
    control.updateValueAndValidity();
  }

  validateSingleDoorAstragal(control: AbstractControl<Astragal>, isDutchStyle: boolean){
    if(isDutchStyle){
      control.setValidators([Validators.required])
    }
    else {
      control.setValue(null)
      control.setValidators([])
    }
    control.updateValueAndValidity();
  }
}
