import { Injectable, Type } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { Router, NavigationStart } from '@angular/router'
import { IDialog } from '../interfaces/i-dialog'

@Injectable()
export class DialogService {
  private _dialog = new Subject<{ component: Type<IDialog<any>>; data: any; opts: { closeable: boolean } }>()
  get dialog$(): Observable<{ component: Type<IDialog<any>>; data: any; opts: { closeable: boolean } }> {
    return this._dialog
  }
  private _close = new Subject<{}>()
  get close$(): Observable<{}> {
    return this._close
  }
  private _isOpen = false
  get isOpen() {
    return this._isOpen
  }

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.close()
      }
    })
  }

  open<T, U>(component: Type<IDialog<T>>, data?: T, opts?: { closeable: boolean }): Observable<U> {
    this._isOpen = true
    this._dialog.next({ component, data, opts })
    return this._close.pipe(
      map((value) => value as U),
      first()
    )
  }

  close<T>(value?: T) {
    this._isOpen = false
    this._close.next(value)
  }
}
