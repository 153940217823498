import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { RestService } from './rest.service';
import { DoorType } from '../../door-elevation/abstracts/door-type';
import { DoorElevation } from '../../door-elevation/models/door-elevation';
import { Folder } from '../models/folder';
import { ExperlogixPreferenceCategory } from '../models/experlogix-preference-category';

@Injectable()
export class ExperlogixService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  private loading = new BehaviorSubject<boolean>(false);
  loading$ = this.loading.asObservable();

  price(xmlData: string): Observable<string> {
    this.loading.next(true);
    return this.http.post<string>(
      `${this.environment.elevations.apiUrl}v1/experlogix/price`,
      { xmlData },
      { responseType: 'text' as 'json' }
    ).pipe(tap(() => this.loading.next(false)));
  }

  preferences(): Observable<ExperlogixPreferenceCategory[]> {
    this.loading.next(true);
    return this.http.get<ExperlogixPreferenceCategory[]>(
      `${this.environment.elevations.apiUrl}v1/experlogix/preferences`
    ).pipe(tap(() => this.loading.next(false)));
  }
}
