import { pipe } from 'lodash/fp';
import { appendChildrenElements, createSVGElement, setAttributes, appendToContainerElement } from '../../../core/helpers/svg-functions';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { Panel } from '../../abstracts/panel';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';

export class Embossment extends Cutout {
  type: string = 'Embossment';
  sizes: { width: string; height: string }[] = [];

  constructor(
    name: string,
    doorType: DoorType,
    x: number,
    y: number,
    width: number,
    height: number,
    verticalDimensionMode: VerticalCutoutDimensionMode,
    horizontalDimensionMode: HorizontalCutoutDimensionMode,
    private panels: Panel[]
  ) {
    super(name, doorType, x, y, width, height, verticalDimensionMode, horizontalDimensionMode, false, true);
  }

  drawCutout(container: SVGGElement) {
    return pipe(
      setAttributes({transform: `translate(${this.x}, ${this.y})`}),
      appendChildrenElements(this.panels.map(panel => panel.drawCutout())),
      appendToContainerElement(container),
    )(createSVGElement('g'));
  }
}
