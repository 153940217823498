import { seriesFireRatings } from '../../rules/series';
import { subTypeFireRatings } from '../../rules/sub-type';
import { DoorFireRating } from '../door-fire-rating';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';
import { getEnumValues } from '@oeo/common';

export function DoorFireRatings(series: DoorSeries, subType: DoorSubType) {
  if (!series) {
    return [];
  }
  const fireRatings = getEnumValues(DoorFireRating);
  seriesFireRatings(fireRatings, series);
  subTypeFireRatings(fireRatings, subType);
  return fireRatings;
}
