import { Template } from '../abstracts/template';
import { FrameElevation } from '../models/frame-elevation';
import { Stick } from '../models/stick';
import { JointType } from '../enums/joint-type';
import { Orientation } from '../enums/orientation';
import { StickType } from '../enums/stick-type';
import { StickSubtype } from '../enums/stick-subtype';
import { DimensionType } from '../../core/enums/dimension-type';
import { guid } from '@oeo/common';
import { Glass } from '../models/glass';
import { GlassType } from '../enums/glass-type';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { GlazingBeadLocation } from '../enums/glazing-bead-location';
import { Door } from '../models/door';
import { Handing } from '../enums/handing';
import { Rabbet } from '../enums/rabbet';

export class DSST06 extends Template {
  private _b: number;
  private _c: number;
  private _d: number;
  private _e: number;
  private _g: number;
  private _h: number;
  private _i: number;
  private _j: number;

  dimensions = {
    A: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    B: {
      type: DimensionType.Horizontal,
      get: () => this._b,
      set: (value: number) => {
        const diff = this._b - value;
        this._b = value;
        this._c += diff;
        this.update();
      },
    },
    C: {
      type: DimensionType.Horizontal,
      get: () => this._c,
      set: (value: number) => {
        const diff = this._c - value;
        this._c = value;
        this._b += diff;
        this.update();
      },
    },
    D: {
      type: DimensionType.Horizontal,
      get: () => this._d,
      set: (value: number) => {
        const diff = this._d - value;
        this._d = value;
        this._e += diff;
        this.update();
      },
    },
    E: {
      type: DimensionType.Horizontal,
      get: () => this._e,
      set: (value: number) => {
        const diff = this._e - value;
        this._e = value;
        this._c += diff;
        this.update();
      },
    },
    F: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    G: {
      type: DimensionType.Vertical,
      get: () => this._g,
      set: (value: number) => {
        if (!this.headDimensions.includes(value) || value === this._g) {
          return;
        }
        const diff = this._g - value;
        this._g = value;
        this._h += diff;
        this.update();
      },
      hint: `Head dimension must be either ${this.headDimensions
        .map(d => d.toDimension(this.dimensionType, this.unitOfMeasure))
        .join(' or ')}`,
    },
    H: {
      type: DimensionType.Vertical,
      get: () => this._h,
      set: (value: number) => {
        const diff = this._h - value;
        this._h = value;
        this._j += diff;
        this.update();
      },
    },
    I: {
      type: DimensionType.Vertical,
      get: () => this._i,
      set: (value: number) => {
        const diff = this._i - value;
        this._i = value;
        this._h += diff;
        this.update();
      },
    },
    J: {
      type: DimensionType.Vertical,
      get: () => this._j,
      set: (value: number) => {
        const diff = this._j - value;
        this._j = value;
        this._h += diff;
        this.update();
      },
    },
    K: {
      type: DimensionType.Vertical,
      get: () => this.defaultFace,
    },
  };

  constructor(frameElevation: FrameElevation) {
    super(frameElevation);
    this._d = this._i = this._g = this.defaultFace;
    this._b = this._c = (
      (this.width -
        this.rollupDimensions(
          this.dimensionName(() => this.dimensions.F),
          this.dimensionName(() => this.dimensions.B),
          this.dimensionName(() => this.dimensions.C),
          this.dimensionName(() => this.dimensions.E)
        )) /
      3
    ).cleanAsDimension('door', this.unitOfMeasure);
    this._e =
      this.width -
      this.rollupDimensions(
        this.dimensionName(() => this.dimensions.F),
        this.dimensionName(() => this.dimensions.E)
      );
    this._h = (
      (this.height -
        this.rollupDimensions(
          this.dimensionName(() => this.dimensions.K),
          this.dimensionName(() => this.dimensions.H),
          this.dimensionName(() => this.dimensions.J)
        )) /
      4
    ).cleanAsDimension(this.dimensionType, this.unitOfMeasure);
    this._j =
      this.height -
      this.rollupDimensions(
        this.dimensionName(() => this.dimensions.K),
        this.dimensionName(() => this.dimensions.J)
      );
  }

  protected drawObject(container: SVGSVGElement) {
    this.drawLeft(container);
    this.drawTop(container);
    this.drawRight(container);
    this.drawBottom(container);
    this.drawVerticalMullion(container);
    this.drawHorizontalMullion(container);
    this.drawTopGlass(container);
    this.drawBottomGlass(container);
    this.drawLeftDoor(container);
    this.drawRightDoor(container);
  }

  private drawLeft(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: 0, y: this._g === this.headFace ? this.headFace / 2 : 0 },
          { x: 0, y: this.height },
          { x: this.dimensions.A.get(), y: this.height },
          { x: this.dimensions.A.get(), y: this.dimensions.G.get() },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Hinge,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawRight(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.dimensions.G.get() === this.headFace ? this.headFace / 2 : 0,
          },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawTop(container: SVGSVGElement) {
    const points =
      this._g === this.headFace
        ? [
            {
              x: 0,
              y: 0,
            },
            { x: this.width, y: 0 },
            { x: this.width, y: this.headFace / 2 },
            {
              x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
              y: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            },
            {
              x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
              y: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            },
            {
              x: 0,
              y: this.headFace / 2,
            },
          ]
        : [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width - this.dimensions.F.get(), y: this.dimensions.G.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.G.get() },
          ];
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points,
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: this._g === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        rightJointType: this._g === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawBottom(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.OpenSection,
        subType: StickSubtype.Sill,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawHorizontalMullion(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.H)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.H)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawVerticalMullion(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.height,
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.height,
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
        ],
        topJointType: JointType.Notched,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.ClosedSection,
        subType: StickSubtype.Hinge,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawTopGlass(container: SVGSVGElement) {
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.H)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.H)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );
  }

  private drawBottomGlass(container: SVGSVGElement) {
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );
  }

  private drawLeftDoor(container: SVGSVGElement) {
    this.intersectables.push(
      Door.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.A)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
        ],
        exportType: Door.intersectableName,
        handing: Handing.LH,
        id: guid(),
      })
    );
  }

  private drawRightDoor(container: SVGSVGElement) {
    this.intersectables.push(
      Door.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.I)),
          },
        ],
        exportType: Door.intersectableName,
        handing: Handing.RH,
        id: guid(),
      })
    );
  }
}
