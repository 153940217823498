<lib-dialog header="Configuration" [loading]="loading">
  <h3>{{'overall' | translate}}</h3>
  <ul>
    <li>
      Series: <span class="value">{{ lineItem.catalogNumber }}</span>
    </li>
    <li>
      Series: <span class="value">{{ lineItem.model }}</span>
    </li>
    <li>
      Unit Price: <span class="value">${{ lineItem.unitPrice }}</span>
    </li>
    <li>
      SubType: <span class="value">{{ lineItem.subType }}</span>
    </li>
    <li>
      Width: <span class="value">{{ lineItem.width }}</span>
    </li>
    <li>
      Height: <span class="value">{{ lineItem.height }}</span>
    </li>
    <li>
      Gauge: <span class="value">{{ lineItem.gauge }}</span>
    </li>
    <li>
      Core: <span class="value">{{ lineItem.core }}</span>
    </li>
  </ul>
  <ng-container *ngFor="let door of lineItem.doors">
    <h3>Door</h3>
    <ul>
      <li>
        Unit Price: <span class="value">${{ door.unitPrice }}</span>
      </li>
      <li>
        Base Price: <span class="value">${{ door.basePrice }}</span>
      </li>
      <li>
        Core Adder: <span class="value">${{ door.coreAdder }}</span>
      </li>
      <li>
        Steel Stiffen Adder: <span class="value">${{ door.steelStiffenAdder }}</span>
      </li>
      <li>
        Material Adder: <span class="value">${{ door.materialAdder }}</span>
      </li>
      <li>
        Metal/Mylar Adder: <span class="value">${{ door.metalMylarAdder }}</span>
      </li>
      <li>
        Seamless Edge Adder: <span class="value">${{ door.seamlessEdgeAdder }}</span>
      </li>
      <li>
        Thickness Adder: <span class="value">${{ door.thicknessAdder }}</span>
      </li>
      <li>
        Gauge Adder: <span class="value">${{ door.gaugeAdder }}</span>
      </li>
      <li>
        Finish Paint Adder: <span class="value">${{ door.finishPaintAdder }}</span>
      </li>
      <li>
        Hinge Prep Adder: <span class="value">${{ door.hingePrepAdder }}</span>
      </li>
      <li>
        Primary Lock Prep Adder: <span class="value">${{ door.primaryLockPrepAdder }}</span>
      </li>
      <li>
        Deadlock Prep Adder: <span class="value">${{ door.deadlockPrepAdder }}</span>
      </li>
      <li>
        Secondary Lock Prep Adder: <span class="value">${{ door.secondaryLockPrepAdder }}</span>
      </li>
      <li>
        Tertiary Lock Prep Adder: <span class="value">${{ door.tertiaryLockPrepAdder }}</span>
      </li>
      <li>
        Primary Strike Prep Adder: <span class="value">${{ door.primaryStrikePrepAdder }}</span>
      </li>
      <li>
        Deadlock Strike Adder: <span class="value">${{ door.deadlockStrikePrepAdder }}</span>
      </li>
      <li>
        Secondary Strike Prep Adder: <span class="value">${{ door.secondaryStrikePrepAdder }}</span>
      </li>
      <li>
        Tertiary Strike Prep Adder: <span class="value">${{ door.tertiaryStrikePrepAdder }}</span>
      </li>
      <li>
        Closer Prep Adder: <span class="value">${{ door.closerPrepAdder }}</span>
      </li>
      <li>
        Blank/Surface Hinge Prep Adder: <span class="value">${{ door.blankSurfaceHingePrepAdder }}</span>
      </li>
      <li>
        Anchor Hinge Prep Adder: <span class="value">${{ door.anchorHingePrepAdder }}</span>
      </li>
      <li>
        Top/Bottom Pivot Prep Adder: <span class="value">${{ door.topBottomPivotPrepAdder }}</span>
      </li>
      <li>
        Int Pivot Prep Adder: <span class="value">${{ door.intPivotPrepAdder }}</span>
      </li>
      <li>
        OH Stop/Holder Prep Adder: <span class="value">${{ door.ohStopHolderPrepAdder }}</span>
      </li>
      <li>
        Mag Switch Prep Adder: <span class="value">${{ door.magSwitchPrepAdder }}</span>
      </li>
      <li>
        Power Transfer Prep Adder: <span class="value">${{ door.powerTransferPrepAdder }}</span>
      </li>
      <li>
        Roller Latch Prep Adder: <span class="value">${{ door.rollerLatchPrepAdder }}</span>
      </li>
      <li>
        Flush Bolt Prep Adder: <span class="value">${{ door.flushBoltPrepAdder }}</span>
      </li>
      <li>
        Surface Bolt Prep Adder: <span class="value">${{ door.surfaceBoltPrepAdder }}</span>
      </li>
      <li>
        Cutout(s) Price: <span class="value">${{ door.cutoutPrice }}</span>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngFor="let cutout of lineItem.cutouts">
    <h3>Cutout</h3>
    <ul>
      <li>
        Type: <span class="value">{{ cutout.type }}</span>
      </li>
      <li>
        Unit Price: <span class="value">${{ cutout.unitPrice }}</span>
      </li>
      <li>
        Glass Base Price: <span class="value">${{ cutout.glassBasePrice }}</span>
      </li>
      <li>
        Louver Base Price: <span class="value">${{ cutout.louverBasePrice }}</span>
      </li>
      <li>
        Muntin Bar Base Price: <span class="value">${{ cutout.muntinBarBasePrice }}</span>
      </li>
      <li>
        Galvanized Price: <span class="value">${{ cutout.materialAdder }}</span>
      </li>
      <li>
        Glass Pocket Adder: <span class="value">${{ cutout.glassPocketAdder }}</span>
      </li>
    </ul>
  </ng-container>
</lib-dialog>
