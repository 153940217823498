import { debounceTime, takeUntil } from 'rxjs/operators'
import { uomSwitch } from '../../../../core/functions/uomSwitch'
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location'
import { IPrepCode } from '../../../../core/interfaces/i-prep-code'
import { DoorEdge } from '../../../enums/door-edge'
import { DoorPrepReferencePoint } from '../../../enums/door-prep-reference-point'
import { IDoorLockPrep } from '../../../interfaces/preps/i-door-lock-prep'
import { Door } from '../../../models/door'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { FilteredKeys } from '@oeo/common'

type StrikePrepTypes = FilteredKeys<Door, `${string}StrikePrep`>

export abstract class StrikePrepComponent extends PrepBaseComponent {
  door: Door
  abstract doorPrepComponent: DoorPrepComponent
  codes: IPrepCode[]
  get code(): IPrepCode {
    return this.prep?.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  locations: IPrepCategoryLocation[]
  get referencePoints(): DoorPrepReferencePoint[] {
    return Object.keys(DoorPrepReferencePoint).map((key) => DoorPrepReferencePoint[key as keyof typeof DoorPrepReferencePoint])
  }
  get prep(): IDoorLockPrep {
    return this.door[this.doorLockPrepCodeProperty] as IDoorLockPrep
  }
  set prep(value: IDoorLockPrep) {
    this.door[this.doorLockPrepCodeProperty] = value
  }

  constructor(
    public title: string,
    prepsComponent: PrepsComponent,
    private doorLockPrepCodeProperty: StrikePrepTypes,
    prepCategoryId: number
  ) {
    super(prepsComponent, prepCategoryId)
    this.codes = this.codes.concat(this.specialPrepCode)
    if (this.door.edge == DoorEdge.BEVSQ) {
      this.codes = this.codes.filter((code) => code.code !== 'ASA')
    }
    this.locations = prepsComponent.configService.prepCategories
      .first((c) => c.id === prepCategoryId)
      ?.prepCategoryLocations.concat([this.specialPrepLocation])
    this.setDefaultPrepValues()
    this.setSpecial()
  }

  setDefaultPrepValues(){
    if(!this.prep){
      this.prep = {} as IDoorLockPrep
    }
    this.prep.referencePoint = this.prep.referencePoint ?? this.referencePoints.first((c) => c === this.prep.referencePoint) ?? DoorPrepReferencePoint.FFCL
  }

  onInit(): void {}

  afterViewInit(): void {
    this.draw$.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(() => this.drawPreps())
    this.prepFieldsForm.valueChanges.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(() => {
      if (this.prep.code && !this.prep.locationType) {
        this.prep.locationType =
          this.locations.first(
            ({ value }) => value === this.prepsComponent.configService.doorElevation.prepLocationPreference
          )?.value ?? this.locations.length === 1
            ? this.locations.first()?.value
            : null
      }
      if (this.code?.fixedLocation && this.prep.referencePoint !== DoorPrepReferencePoint.FFCL) {
        this.prep.referencePoint = DoorPrepReferencePoint.FFCL
        return
      }
      this.prep.location = this.code?.fixedLocation
        ? this.code.standardLocation
        : this.prep.location ?? uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure)
      this.draw$.next()
    })
    this.draw$.next()
  }

  onDestroy(): void {
    super.destroy()
  }

  override get incompleteQs(): boolean {
    return super.incompleteQs && !this.prepFieldsForm.valid
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear()
    if (this.prep.locationType !== this.specialPrepLocation.value) {
      this.prep.location = Array.isArray(this.prep.location) ? [] : '0"'
      this.doorPrepComponent.resetAndFit();
      return
    }
    this.prepLocationInputs.push(this.drawPrep(0, this.prep))
    this.drawShape(0, this.prep, this.door.actualWidth)
  }
}
