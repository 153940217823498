import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Lite } from '../../models/cutout-types/lite';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class GType extends DoorType {
  cutouts: Cutout[];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = this.getWidth();
    const height = this.getHeight();
    this.cutouts = [
      new Lite(
        'Lite',
        this,
        (this.door.actualWidth - width) / 2,
        uomSwitch('6.125"', 'door', this.door.doorElevation.unitOfMeasure),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private getWidth(): number {
    if(this.door.width > uomSwitch('48"', 'door', this.door.doorElevation.unitOfMeasure)) {
      return uomSwitch('34"', 'door', this.door.doorElevation.unitOfMeasure)
    }
    const measures = [
      { min: '24"', max: '28"', width: '10"'}, 
      { min: '29"', max: '31"', width: '16"'}, 
      { min: '32"', max: '34"', width: '18"'}, 
      { min: '35"', max: '38"', width: '22"'}, 
      { min: '39"', max: '42"', width: '26"'}, 
      { min: '43"', max: '46"', width: '30"'}
    ]

    const { width } = measures.find(({ min, max }) => 
      this.door.width >= uomSwitch(min, 'door', this.door.doorElevation.unitOfMeasure) && 
      this.door.width <= uomSwitch(max, 'door', this.door.doorElevation.unitOfMeasure)
    )
    
    return uomSwitch(width, 'door', this.door.doorElevation.unitOfMeasure)
  }

  private getHeight(): number {
    
    const measures = [
      { min: '1"', max: '80"', height: '29"'}, 
      { min: '81"', max: '94"', height: '33"'}, 
      { min: '95"', max: '120"', height: '45"'}
    ]

    const { height } = measures.find(({ min, max }) => 
      this.door.height >= uomSwitch(min, 'door', this.door.doorElevation.unitOfMeasure) && 
      this.door.height <= uomSwitch(max, 'door', this.door.doorElevation.unitOfMeasure)
    )
    
    return uomSwitch(height, 'door', this.door.doorElevation.unitOfMeasure)
  }
}