import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { FlagsService } from '../../../services/flags/flags.service'

@Component({
  selector: 'oa-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  isInternal: boolean

  constructor(flagsService: FlagsService) {
    flagsService.flags$.pipe(takeUntil(this.destroyed$)).subscribe((flags) => {
      if (!flags) {
        return
      }
      this.isInternal = flags.internal
    })
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
