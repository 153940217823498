import { Component, OnInit } from '@angular/core';
import { parseFromXMLString } from '../../../core/helpers/parse-from-xml-string';
import { IDialog } from '../../../core/interfaces/i-dialog';
import { ExperlogixService } from '../../../core/services/experlogix.service';
import { FrameElevation } from '../../models/frame-elevation';

interface GlassConfiguration {
  name: string;
  vertGlazingBeadAdder: number;
  horzGlazingBeadAdder: number;
  unitPrice: number;
  centerGlazedAdder: number;
}

interface StickConfiguration {
  name: string;
  unitPrice: number;
  linealInchPrice: number;
  cornerCondition1Adder: number;
  cornerCondition2Adder: number;
  weld1Adder: number;
  weld2Adder: number;
  partialNotchAdder: number;
  specialProfileLinealInchAdder: number;
  specialStopAdder: number;
  specialBackBendAdder: number;
  spliceJointAdder: number;
  specialRabbetAdder: number;
  specialFaceAdder: number;
  materialAdder: number;
  materialMultiplier: number;
}

interface LineItemConfiguration {
  model: string;
  unitPrice: number;
  cornerAssembly: string;
  jambDepth: number;
  material: string;
  sticks: StickConfiguration[];
  glasses: GlassConfiguration[];
}

@Component({
  selector: 'lib-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit, IDialog<FrameElevation> {
  data: FrameElevation;
  lineItem: LineItemConfiguration = { sticks: [], glasses: [] } as LineItemConfiguration;

  constructor(private experlogixService: ExperlogixService) {}

  ngOnInit(): void {
    this.experlogixService.price(this.data.toXML()).subscribe(xml => {
      const doc = parseFromXMLString(xml);
      const lineitem = doc.evaluate('lineitem', doc).iterateNext();
      this.lineItem.model = doc.evaluate('modeldesc', lineitem).iterateNext().textContent;
      this.lineItem.unitPrice = parseFloat(doc.evaluate('unitprice', lineitem).iterateNext().textContent);
      const calcs = doc.evaluate('category[@id="FrameElevation"]/option', lineitem).iterateNext();
      this.lineItem.cornerAssembly = doc
        .evaluate('property[@id="CornerAssembly"]', calcs)
        .iterateNext().textContent;
      this.lineItem.jambDepth = parseFloat(
        doc.evaluate('property[@id="JambDepth"]', calcs).iterateNext().textContent
      );
      this.lineItem.material = doc.evaluate('property[@id="Material"]', calcs).iterateNext().textContent;
      this.lineItem.sticks = this.getSticks(lineitem, doc);
      this.lineItem.glasses = this.getGlasses(lineitem, doc);
    });
  }

  private getSticks(lineitem: Node, doc: Document) {
    const sticks = doc.evaluate('category[@id="Stick"]/option', lineitem);
    let stick = sticks.iterateNext();
    const result = [];
    while (stick) {
      result.push({
        name: `${doc.evaluate('property[@id="Length"]', stick).iterateNext().textContent} x ${
          doc.evaluate('property[@id="Face"]', stick).iterateNext().textContent
        } ${doc.evaluate('property[@id="Type"]', stick).iterateNext().textContent} ${
          doc.evaluate('property[@id="SubType"]', stick).iterateNext().textContent
        } ${doc.evaluate('property[@id="CornerCondition1"]', stick).iterateNext().textContent}/${
          doc.evaluate('property[@id="CornerCondition2"]', stick).iterateNext().textContent
        }`,
        unitPrice: parseFloat(doc.evaluate('property[@id="ExtPrice"]', stick).iterateNext().textContent),
        linealInchPrice: parseFloat(doc.evaluate('property[@id="LinealInchPrice"]', stick).iterateNext().textContent),
        cornerCondition1Adder: parseFloat(
          doc.evaluate('property[@id="CornerCondition1Adder"]', stick).iterateNext().textContent
        ),
        cornerCondition2Adder: parseFloat(
          doc.evaluate('property[@id="CornerCondition2Adder"]', stick).iterateNext().textContent
        ),
        weld1Adder: parseFloat(doc.evaluate('property[@id="Weld1Adder"]', stick).iterateNext().textContent),
        weld2Adder: parseFloat(doc.evaluate('property[@id="Weld2Adder"]', stick).iterateNext().textContent),
        specialProfileLinealInchAdder: parseFloat(
          doc.evaluate('property[@id="SpecialProfileLinealInchAdder"]', stick).iterateNext().textContent
        ),
        partialNotchAdder: parseFloat(doc.evaluate('property[@id="StickTypeAdder"]', stick).iterateNext().textContent),
        specialStopAdder: parseFloat(doc.evaluate('property[@id="SpecialStopAdder"]', stick).iterateNext().textContent),
        specialBackBendAdder: parseFloat(
          doc.evaluate('property[@id="SpecialBackBendAdder"]', stick).iterateNext().textContent
        ),
        spliceJointAdder: parseFloat(doc.evaluate('property[@id="SpliceJointAdder"]', stick).iterateNext().textContent),
        specialRabbetAdder: parseFloat(
          doc.evaluate('property[@id="SpecialRabbetAdder"]', stick).iterateNext().textContent
        ),
        specialFaceAdder: parseFloat(doc.evaluate('property[@id="SpecialFaceAdder"]', stick).iterateNext().textContent),
        materialAdder: parseFloat(doc.evaluate('property[@id="MaterialAdder"]', stick).iterateNext().textContent),
        materialMultiplier: parseFloat(
          doc.evaluate('property[@id="MaterialMultiplier"]', stick).iterateNext().textContent
        ),
      });
      stick = sticks.iterateNext();
    }
    return result;
  }

  private getGlasses(lineitem: Node, doc: Document) {
    const glasses = doc.evaluate('category[@id="Glass"]/option', lineitem);
    let glass = glasses.iterateNext();
    const result = [];
    while (glass) {
      result.push({
        name: `${doc.evaluate('property[@id="Height"]', glass).iterateNext().textContent} x ${
          doc.evaluate('property[@id="Width"]', glass).iterateNext().textContent
        } Glazing Bead ${doc.evaluate('property[@id="Position"]', glass).iterateNext().textContent}`,
        unitPrice: parseFloat(doc.evaluate('property[@id="ExtPrice"]', glass).iterateNext().textContent),
        vertGlazingBeadAdder: parseFloat(
          `${doc.evaluate('property[@id="VertGlazingBeadAdder"]', glass).iterateNext().textContent}`
        ),
        horzGlazingBeadAdder: parseFloat(
          `${doc.evaluate('property[@id="HorzGlazingBeadAdder"]', glass).iterateNext().textContent}`
        ),
        centerGlazedAdder: parseFloat(
          `${doc.evaluate('property[@id="CenterGlazedAdder"]', glass).iterateNext().textContent}`
        ),
      });
      glass = glasses.iterateNext();
    }
    return result;
  }
}
