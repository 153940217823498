import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { pairSubTypes } from '../../rules/pair';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorSubTypes(series: DoorSeries, isPair: boolean): DoorSubType[] {
  const subTypes = Object.keys(DoorSeriesInfos[series]?.subTypes ?? {}) as DoorSubType[];
  pairSubTypes(subTypes, isPair);
  return subTypes;
}
