import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { Template } from '../../abstracts/template';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SvgPanZoom from 'svg-pan-zoom';
import { FrameElevation } from '../../models/frame-elevation';
import { TemplateTypes } from '../../constants/template-types';

@Component({
  selector: 'lib-template-designer',
  templateUrl: './template-designer.component.html',
  styleUrls: ['./template-designer.component.scss'],
})
export class TemplateDesignerComponent implements OnInit, OnDestroy {
  private _template$ = new Subject<void>();
  get template() {
    return this._template;
  }
  @Input() set template(value: Template) {
    this._template$.next();
    if (value == null) {
      return;
    }
    this._template = value;
    this._template.update$.pipe(takeUntil(this._destroy$), takeUntil(this._template$)).subscribe(() => {
      this._template.draw(this.intersectableContainer);
      this.resetAndFit();
    });
    requestAnimationFrame(() => {
      this._template.draw(this.intersectableContainer);
      this.resetAndFit();
    });
  }
  private _template: Template;
  private _destroy$ = new Subject<void>();
  private svgPanZoom: SvgPanZoom.Instance;

  constructor() {}

  get height() {
    return this._template == null ? 0 : this._template.height;
  }

  get width() {
    return this._template == null ? 0 : this._template.width;
  }

  get viewBox() {
    return `0 0 ${this.height} ${this.width}`;
  }

  @ViewChild('svgRef', { static: true }) svgRef: ElementRef<SVGSVGElement>;
  get svg() {
    return this.svgRef.nativeElement;
  }

  @ViewChild('containerRef', { static: true }) containerRef: ElementRef<SVGSVGElement>;
  get container() {
    return this.containerRef.nativeElement;
  }

  @ViewChild('intersectableContainerRef', { static: true }) intersectableContainerRef: ElementRef<SVGGElement>;
  get intersectableContainer() {
    return this.intersectableContainerRef.nativeElement;
  }

  @ViewChild('textRef', { static: true }) textRef: ElementRef<SVGTextElement>;
  get text() {
    return this.textRef.nativeElement;
  }

  ngOnInit(): void {
    this.svgPanZoom = SvgPanZoom(this.svg, {
      zoomEnabled: true,
      fit: false,
      contain: false,
      center: false,
      panEnabled: true,
      dblClickZoomEnabled: false,
      minZoom: 0.25,
    });
    this.resetAndFit();
  }

  ngOnDestroy() {
    if (this.template) {
      this.template.destroy();
    }
    this._template$.next();
    this._template$.unsubscribe();
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  private resetAndFit() {
    requestAnimationFrame(() => {
      this.svgPanZoom.updateBBox().center().updateBBox().fit().updateBBox().zoomOut();
    });
  }
}
