import { DoorLouverKit } from './../door-louver-kit';

export function DoorLouverKits(isFireRated: boolean, isLouverInstalled: boolean): DoorLouverKit[] {
    if (isFireRated && isLouverInstalled) {
        return [
            DoorLouverKit._1900_A,
            DoorLouverKit._1900_A_G,
            DoorLouverKit._100AFL_G,
            DoorLouverKit._100AFL,
            DoorLouverKit.FLDL_UL_G,
            DoorLouverKit.FLDL_UL,  
            ];
    }
    return Object.values(DoorLouverKit);
}