import { Component, OnDestroy, ViewChild, ViewContainerRef, AfterViewInit, ChangeDetectorRef } from '@angular/core'
import { Subject, takeUntil } from 'rxjs'
import { DoorElevation } from '../../models/door-elevation'
import { IDialog } from '../../../core/interfaces/i-dialog'
import { DialogService } from '../../../core/services/dialog.service'
import { DoorElevationConfigService } from '../../services/door-elevation-config-service/door-elevation-config.service'
import { DoorPosition, StepType } from '../../services/door-elevation-config-service/constants'
import { StepperComponent } from '@oeo/common'
import { IStep } from '../../../core/interfaces/i-step'

@Component({
  selector: 'lib-door-elevation-settings',
  templateUrl: './door-elevation-settings.component.html',
  styleUrls: ['./door-elevation-settings.component.scss']
})
export class DoorElevationSettingsComponent implements AfterViewInit, OnDestroy, IDialog<{}> {
  private _destroy$ = new Subject<void>()

  mode: 'Single' | 'Pair'
  header = 'TITLE.defaultDoorSettings'

  data: {}
  @ViewChild('DoorElevationSettingContainer', { read: ViewContainerRef }) doorSettingContainer: ViewContainerRef
  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent<IStep>

  constructor(public dialogService: DialogService, public configService: DoorElevationConfigService, private cd: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.configService.step$.pipe(takeUntil(this._destroy$)).subscribe((stepNo) => {
      this.stepper?.setStepNo(stepNo)
      const currentStep = this.configService.steps[stepNo]
      this.header = currentStep?.dialogTitle ?? currentStep.name
      if (currentStep.type === StepType.Preps) {
        if(this.configService.doorElevation.doors.length === 1){
          this.header = `TITLE.${this.configService.isNew ? 'select' :'modify'}` + (this.configService.currentDoor.active ? 'Active' : 'Inactive') + 'DoorPreps'
        }
        else {
          this.header = `TITLE.${this.configService.isNew ? 'select' :'modify'}` +
          (currentStep.doorIndex === DoorPosition.LEFT ? 'Left' : 'Right') +
          (this.configService.currentDoor.active ? 'Active' : 'Inactive') +
          'DoorPreps'
        }
      }
      this.doorSettingContainer.clear()
      this.doorSettingContainer.createComponent(currentStep.component)
      this.cd.detectChanges()
    })
  }

  close() {
    return this.dialogService.close({} as Partial<DoorElevation>)
  }

  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.unsubscribe()
  }
}
