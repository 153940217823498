import { BehaviorSubject, Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { distinctUntilChanged, map, take, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Quote } from '../../models/quote'
import { RoutingService } from '../../services/routing/routing.service'

@Injectable({
  providedIn: 'root'
})
export class QuotesService {
  private _quote: BehaviorSubject<Quote> = new BehaviorSubject(null)
  quote$: Observable<Quote> = this._quote.asObservable()
  quoteId: number

  private _loading: BehaviorSubject<boolean> = new BehaviorSubject(false)
  loading$: Observable<boolean> = this._loading.asObservable()

  constructor(private authHttp: HttpClient, private routingService: RoutingService) {
    this.routingService.ids$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (res.quoteId && res.quoteId !== this.quoteId) {
        this._loading.next(true)
        this._quote.next(null)
        this.getDetails(res.quoteId).pipe(take(1)).subscribe()
        this.quoteId = res.quoteId
      }
    })
  }

  public get(): Observable<Quote[]> {
    const url = environment.onlineOrderingApiUrl + 'Quotes'
    return this.authHttp.get<Quote[]>(url).pipe(map((quotes) => quotes ?? []))
  }

  //  This looks like it should work for quoteNumber or quoteId
  public getDetails(id: number | string): Observable<Quote> {
    const url = environment.onlineOrderingApiUrl + `Quotes/${id}`
    return this.authHttp.get<Quote>(url).pipe(
      tap((quote: Quote) => {
        this._quote.next(quote)
        this._loading.next(false)
      })
    )
  }

  public getQuoteDocument(quoteNumber: string): Observable<Blob> {
    const url = environment.onlineOrderingApiUrl + `Quotes/${quoteNumber}`
    let headers = new HttpHeaders()
    headers = headers.set('Accept', `application/pdf`)
    return this.authHttp.get(url, { headers: headers, responseType: 'blob' })
  }
}
