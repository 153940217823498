<div class="dialog-content">
  <div class="header">
    <lib-stepper [steps]="steps"> </lib-stepper>
  </div>
  <div class="content" *ngIf="!init">
    <ng-container *ngIf="step === 0">
      <lib-prep-selection [type]="'door'" [steps]="prepSteps" (step$)="modifySteps($event)"></lib-prep-selection>
    </ng-container>
    <ng-container #PrepComponentEntry></ng-container>
  </div>
  <div class="content" *ngIf="init"></div>
  <div class="footer">
    <button
      class="mat-stroked-button"
      mat-stroked-button
      [disabled]="step === 0 && !configService.isNew"
      (click)="step === 0 ? configService.goBack() : back()"
    >
      {{
        step === 0 && configService.isNew
          ? ('BUTTON.previous' | translate) +
            ' (' +
            (configService.steps[configService.step - 1].name | translate) +
            ')'
          : ('BUTTON.previous' | translate)
      }}
    </button>
    <button
      color="primary"
      class="mat-flat-button"
      mat-flat-button
      *ngIf="step !== steps.length - 1"
      (click)="next()"
      [disabled]="
        (step !== 0 && (currentComponent?.incompleteQs || !currentComponent?.prepLocationInputsFilled)) || (configService.loadingPrepCodes$ | async)
      "
    >
      {{ 'BUTTON.continue' | translate }}
    </button>
    <button
      color="primary"
      class="mat-flat-button"
      mat-flat-button
      (click)="configService.goNext()"
      *ngIf="step === steps.length - 1"
      [disabled]="
        (step !== 0 && (currentComponent?.incompleteQs || !currentComponent?.prepLocationInputsFilled)) || (configService.loadingPrepCodes$ | async)
      "
    >
      {{
        configService.isNew && configService.step !== configService.steps.length - 1
          ? ('BUTTON.continue' | translate) +
            ' (' +
            (configService.steps[configService.step + 1].name | translate) +
            ')'
          : ('BUTTON.finish' | translate)
      }}
    </button>
  </div>
</div>
