export class InternalUserSession {
  email: string
  customerId: string
  hash: string
  epochTimeStamp: number
  constructor(queryString: string) {
    const decodedQueryString = window.atob(queryString)
    const parseRegex = /^accountId=(.*?)userEmail=(.*?)timeStamp=(.*?)hash=(.*?)$/
    const match = decodedQueryString.match(parseRegex)
    if (match) {
      this.email = match[2]
      this.customerId = match[1]
      this.hash = match[4]
      this.epochTimeStamp = Number(match[3])
    }
  }
}
