import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { uomSwitch } from '../../core/functions/uomSwitch';

export const DoorMultiplier = 64;

export function DefaultStile(uom: UnitOfMeasure) {
  return uomSwitch('6 7/8"', 'door', uom);
}

export function DefaultTopRail(uom: UnitOfMeasure) {
  return uomSwitch('6 7/8"', 'door', uom);
}

export function DefaultBottomRail(uom: UnitOfMeasure) {
  return uomSwitch('12"', 'door', uom);
}

export const DefaultNarrowLiteWidth = '9"';

export const PanelDiff = '1"';
export const PanelDiffInches = '1"';
export const PanelDiffMM = '25mm';

export const DutchBottomHeight = '43 1/8"';
export const DutchBottomHeightInches = '43 1/8"';
export const DutchBottomHeightMM = '1095mm';

export const FirstSpecialPanelDiff = '1/8"';
export const SecondSpecialPanelDiff = '1/4"';
export const ThirdSpecialPanelDiff = '5/8"';
export const FourthSpecialPanelDiff = '1 1/2"';
export const FifthSpecialPanelDiff = '1 5/8"';
export const SixthSpecialPanelDiff = '2 1/2"';

export const DoorStrokeThickness = 16;

export const PanelThickStroke = 6;

export const ArchXRadius = 576;
export const ArchYRadius = 75;
