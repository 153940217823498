<lib-dialog [header]="'TITLE.validations' | translate" class="validations" [closeable]="true">
  <div class="main">
    <div class="left">
      <div class="rule-group" (click)="ruleGroup = newRule()"><span>{{"newValidation" | translate}}</span></div>
      <div class="rule-group" *ngFor="let rg of ruleGroups" (click)="ruleGroup = rg">
        <span [title]="rg.name">{{ rg.name }}</span>
        <button class="rule-button" mat-flat-button
          (click)="$event.stopPropagation(); delete(rg)"
        ><mat-icon>delete</mat-icon></button>
      </div>
    </div>
    <div class="right">
      <div class="rule-container">
        <div class="rule" *ngFor="let rule of ruleGroup.rules; let last = last; let first = first">
          <div class="line">
            <lib-custom-rule
              [ruleType]="obj.ruleType"
              [customRule]="rule"
              (valid)="rule.valid = $event"
            ></lib-custom-rule>
            <div>
              <button class="icon-button padding s4 all" (click)="addRule()">
                <mat-icon>add</mat-icon>
              </button>
              <button class="icon-button padding s4 all"
                (click)="deleteRule(rule)"
                *ngIf="!first"
              ><mat-icon>delete</mat-icon>
            </button>
            </div>
          </div>
          <div class="andOr" *ngIf="!last">
            <mat-radio-group [value]="rule.andOr" (change)="rule.andOr = $event.value">
              <mat-radio-button [value]="true" color="primary">{{"and" | translate}}</mat-radio-button>
              <mat-radio-button [value]="false" color="primary">{{'or' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="script">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.script' | translate}}</mat-label>
          <input matInput [readonly]="true" [value]="ruleValidator.script(ruleGroup)"/>
        </mat-form-field>
        <mat-form-field class="no-hint">
          <mat-label>Test Result</mat-label>
          <input matInput [readonly]="true" [ngModel]="testValue"/>
        </mat-form-field>
        <div class="button-margin">
          <button mat-flat-button color="primary" (click)="test()">{{'test'|translate}}</button>
        </div>
      </div>
      <div class="script">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.name' | translate}}</mat-label>
          <input matInput name="ruleGroupName" [(ngModel)]="ruleGroup.name"/>
        </mat-form-field>
        <mat-form-field class="no-hint" label="Level">
          <mat-select [(ngModel)]="ruleGroup.level">
            <mat-option [value]="1">{{"info" | translate}}</mat-option>
            <mat-option [value]="2">{{'warning'|translate}}</mat-option>
            <mat-option [value]="3">{{'error' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="bottom">
        <mat-form-field class="no-hint">
          <mat-label>{{'FORMFIELDS.message'| translate}}</mat-label>
          <input matInput name="message" [(ngModel)]="ruleGroup.message"/>
        </mat-form-field>
        <div class="button-margin">
          <button mat-flat-button color="primary" class="mat-flat-button" (click)="save()" [disabled]="!isValid() || !ruleGroup.name || !ruleGroup.message">Save</button>
        </div>
      </div>
    </div>
  </div>
</lib-dialog>
