import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CoreConstants } from '../core.constants';
import { UnitOfMeasure } from '../enums/unit-of-measure';
import { createSVGElement } from '../helpers/svg-functions';

@Directive({
  selector: '[libRuler]',
})
export class RulerDirective implements OnInit, AfterViewInit {
  private _unitOfMeasure: UnitOfMeasure;
  private readonly _rulerColor: string = '#F0F7FF';
  private readonly _markColor: string = '#4B4B4B';
  private _zoom = 1;
  private _ruler = true;
  private _grid = true;

  get charWidth(): number {
    return this.fontSize / 2;
  }

  get unitOfMeasure(): UnitOfMeasure {
    return this._unitOfMeasure;
  }
  @Input() set unitOfMeasure(value: UnitOfMeasure) {
    this._unitOfMeasure = value;
  }
  @Input() fontSize = 14;
  @Input() type: 'door' | 'frame';

  @Input() set zoom(value: number) {
    this._zoom = value;
    if (this.rulerElement) {
      this.rulerElement.style.transform = `scale(${this._zoom})`;
    }
    if (this.gridElement) {
      this.gridElement.style.transform = `scale(${this._zoom})`;
    }
  }

  @Input() set ruler(value: boolean) {
    this._ruler = value;
    if (this.rulerElement) {
      this.rulerElement.style.display = this._ruler ? 'block' : 'none';
    }
  }

  @Input() set grid(value: boolean) {
    this._grid = value;
    if (this.gridElement) {
      this.gridElement.style.display = this._grid ? 'block' : 'none';
    }
  }

  get length() {
    return (this.unitOfMeasure === UnitOfMeasure.Imperial ? `30'` : '12192mm').fromDimension(
      this.type,
      this.unitOfMeasure
    );
  }

  get width() {
    return (this.unitOfMeasure === UnitOfMeasure.Imperial ? '3"' : '76mm').fromDimension(this.type, this.unitOfMeasure);
  }

  get element(): SVGGElement {
    return this.elementRef.nativeElement;
  }

  private rulerElement: SVGGElement;
  private gridElement: SVGGElement;

  constructor(private elementRef: ElementRef<SVGGElement>) {}

  ngAfterViewInit() {
    this.drawGrid();
    this.drawRuler();
  }

  ngOnInit(): void {}

  private drawRuler() {
    this.rulerElement = this.element.appendChild(createSVGElement('g'));
    this.rulerElement.style.transform = `scale(${this._zoom})`;
    this.rulerElement.style.userSelect = 'none';
    this.rulerElement.style.pointerEvents = 'none';
    this.rulerElement.style.display = this._ruler ? 'block' : 'none';

    this.drawSquare();
    this.drawHorizontal();
    this.drawVertical();
  }

  private drawGrid() {
    this.gridElement = this.element.appendChild(createSVGElement('g'));
    this.gridElement.style.transform = `scale(${this._zoom})`;
    this.gridElement.style.userSelect = 'none';
    this.gridElement.style.pointerEvents = 'none';
    this.gridElement.style.display = this._grid ? 'block' : 'none';

    const gridMark =
      this.unitOfMeasure === UnitOfMeasure.Imperial
        ? 6 *
          (this.type === 'frame' ? CoreConstants.multipliers.frameElevation : CoreConstants.multipliers.doorElevation)
        : 100;
    for (let i = 0; i < this.length; i += gridMark) {
      const vert = this.gridElement.appendChild(createSVGElement('line'));
      vert.setAttribute('x1', `${i + this.width}`);
      vert.setAttribute('y1', `${-this.width}`);
      vert.setAttribute('x2', `${i + this.width}`);
      vert.setAttribute('y2', `${this.length}`);
      vert.style.stroke = this._markColor;

      const horz = this.gridElement.appendChild(createSVGElement('line'));
      horz.setAttribute('y1', `${i + this.width}`);
      horz.setAttribute('x1', `${-this.width}`);
      horz.setAttribute('y2', `${i + this.width}`);
      horz.setAttribute('x2', `${this.length}`);
      horz.style.stroke = this._markColor;
    }
  }

  private drawSquare() {
    const rect = this.rulerElement.appendChild(createSVGElement('rect'));
    rect.setAttribute('x', `${0}`);
    rect.setAttribute('y', `${0}`);
    rect.setAttribute('height', `${this.width}`);
    rect.setAttribute('width', `${this.width}`);
    rect.style.fill = this._rulerColor;
    rect.style.stroke = 'none';
  }

  private drawHorizontal() {
    const rect = this.rulerElement.appendChild(createSVGElement('rect'));
    rect.setAttribute('x', `${this.width}`);
    rect.setAttribute('y', `${0}`);
    rect.setAttribute('height', `${this.width}`);
    rect.setAttribute('width', `${this.length}`);
    rect.style.fill = this._rulerColor;
    rect.style.stroke = 'none';
    for (let i = 0; i < this.length; i++) {
      const length = this.getMarkLength(i);
      if (length === 0) {
        continue;
      }
      const line = this.rulerElement.appendChild(createSVGElement('line'));
      line.setAttribute('x1', `${i + this.width}`);
      line.setAttribute('y1', `${0}`);
      line.setAttribute('x2', `${i + this.width}`);
      line.setAttribute('y2', `${length}`);
      line.style.stroke = this._markColor;

      if (
        i !== 0 &&
        i %
          (this.unitOfMeasure === UnitOfMeasure.Imperial
            ? 12 *
              (this.type === 'frame'
                ? CoreConstants.multipliers.frameElevation
                : CoreConstants.multipliers.doorElevation)
            : 150) ===
          0
      ) {
        const text = this.rulerElement.appendChild(createSVGElement('text'));
        text.setAttribute('x', `${i + this.width + 2}`);
        text.setAttribute('y', `${this.width / 2}`);
        text.textContent = `${i.toDimension(this.type, this.unitOfMeasure, false)}`;
        text.style.fontSize = `${this.fontSize}px`;
      }
    }
  }

  private drawVertical() {
    const rect = this.rulerElement.appendChild(createSVGElement('rect'));
    rect.setAttribute('x', `${0}`);
    rect.setAttribute('y', `${this.width}`);
    rect.setAttribute('height', `${this.length}`);
    rect.setAttribute('width', `${this.width}`);
    rect.style.fill = this._rulerColor;
    rect.style.stroke = 'none';
    for (let i = 0; i < this.length; i++) {
      const length = this.getMarkLength(i);
      if (length === 0) {
        continue;
      }
      const line = this.rulerElement.appendChild(createSVGElement('line'));
      line.setAttribute('x1', `${0}`);
      line.setAttribute('y1', `${i + this.width}`);
      line.setAttribute('x2', `${length}`);
      line.setAttribute('y2', `${i + this.width}`);
      line.style.stroke = this._markColor;

      if (
        i !== 0 &&
        i %
          (this.unitOfMeasure === UnitOfMeasure.Imperial
            ? 12 *
              (this.type === 'frame'
                ? CoreConstants.multipliers.frameElevation
                : CoreConstants.multipliers.doorElevation)
            : 150) ===
          0
      ) {
        const value = `${i.toDimension(this.type, this.unitOfMeasure, false)}`;
        const text = this.rulerElement.appendChild(createSVGElement('text'));
        text.setAttribute('x', `${length - (value.length - 2) * this.charWidth}`);
        text.setAttribute('y', `${i + this.width - 2}`);
        text.textContent = value;
        text.style.fontSize = `${this.fontSize}px`;
      }
    }
  }

  private getMarkLength(i: number): number {
    if (i === 0) {
      return this.width;
    }
    if (
      i %
        (this.unitOfMeasure === UnitOfMeasure.Imperial
          ? 12 *
            (this.type === 'frame' ? CoreConstants.multipliers.frameElevation : CoreConstants.multipliers.doorElevation)
          : 150) ===
      0
    ) {
      return this.width / 2;
    } else if (
      i %
        (this.unitOfMeasure === UnitOfMeasure.Imperial
          ? 6 *
            (this.type === 'frame' ? CoreConstants.multipliers.frameElevation : CoreConstants.multipliers.doorElevation)
          : 100) ===
      0
    ) {
      return this.width / 4;
    } else if (
      i %
        (this.unitOfMeasure === UnitOfMeasure.Imperial
          ? 2 *
            (this.type === 'frame' ? CoreConstants.multipliers.frameElevation : CoreConstants.multipliers.doorElevation)
          : 50) ===
      0
    ) {
      return this.width / 8;
    }
    return 0;
  }
}
