import { Profile } from '../../abstracts/profile';
import { ProfileTemplate, ProfileTemplateDimensionPosition } from '../../abstracts/profile-template';
import { Direction } from '../../enums/direction';
import { GlassExport } from '../../models/exports/glass-export';

export class FCCS extends ProfileTemplate {
  get stopHeight1() {
    return NaN;
  }
  get stopHeight2() {
    return NaN;
  }
  get backBend1() {
    return NaN;
  }
  get backBend2() {
    return NaN;
  }
  get doorSideRabbet1() {
    return NaN;
  }
  get oppositeDoorSideRabbet1() {
    return NaN;
  }
  get doorSideRabbet2() {
    return NaN;
  }
  get oppositeDoorSideRabbet2() {
    return NaN;
  }

  readonly dimensions = {
    A: {
      direction: Direction.Right,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    B: {
      direction: Direction.Down,
      get: () => this.profile.jambDepth,
      position: () => ProfileTemplateDimensionPosition.MaxX,
    },
    C: {
      direction: Direction.Left,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MaxY,
      doorSide: true,
    },
    D: {
      direction: Direction.Up,
      get: () => this.profile.jambDepth,
      position: () => ProfileTemplateDimensionPosition.MinX,
    },
  };

  constructor(profile: Profile, glazingBeads: GlassExport[]) {
    super(profile, glazingBeads);
    this.loadDimensions();
  }
}
