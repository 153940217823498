export enum FrameJambDepth {
  _3 = '3" (76.2 mm)',
  _318 = '3 1/8" (79.4 mm)',
  _314 = '3 1/4" (82.6 mm)',
  _338 = '3 3/8" (85.7 mm)',
  _312 = '3 1/2" (88.9 mm)',
  _358 = '3 5/8" (92.1 mm)',
  _334 = '3 3/4" (95.3 mm)',
  _378 = '3 7/8" (98.4 mm)',
  _4 = '4" (101.6 mm)',
  _418 = '4 1/8" (104.8 mm)',
  _414 = '4 1/4" (108 mm)',
  _438 = '4 3/8" (111.1 mm)',
  _412 = '4 1/2" (114.3 mm)',
  _458 = '4 5/8" (117.5 mm)',
  _434 = '4 3/4" (120.7 mm)',
  _478 = '4 7/8" (123.8 mm)',
  _5 = '5" (127 mm)',
  _518 = '5 1/8" (130.2 mm)',
  _514 = '5 1/4" (133.4 mm)',
  _538 = '5 3/8" (136.5 mm)',
  _512 = '5 1/2" (139.7 mm)',
  _558 = '5 5/8" (142.9 mm)',
  _534 = '5 3/4" (146.1 mm)',
  _578 = '5 7/8" (149.2 mm)',
  _6 = '6" (152.4 mm)',
  _618 = '6 1/8" (155.6 mm)',
  _614 = '6 1/4" (158.8 mm)',
  _638 = '6 3/8" (161.9 mm)',
  _612 = '6 1/2" (165.1 mm)',
  _658 = '6 5/8" (168.3 mm)',
  _634 = '6 3/4" (171.5 mm)',
  _678 = '6 7/8" (174.6 mm)',
  _7 = '7" (1.8 mm)',
  _718 = '7 1/8" (181 mm)',
  _714 = '7 1/4" (184.2 mm)',
  _738 = '7 3/8" (187.3 mm)',
  _712 = '7 1/2" (190.5 mm)',
  _758 = '7 5/8" (193.7 mm)',
  _734 = '7 3/4" (196.9 mm)',
  _778 = '7 7/8" (200 mm)',
  _8 = '8" (203.2 mm)',
  _818 = '8 1/8" (206.4 mm)',
  _814 = '8 1/4" (209.6 mm)',
  _838 = '8 3/8" (212.7 mm)',
  _812 = '8 1/2" (215.9 mm)',
  _858 = '8 5/8" (219.1 mm)',
  _834 = '8 3/4" (222.3 mm)',
  _878 = '8 7/8" (225.4 mm)',
  _9 = '9" (228.6 mm)',
  _918 = '9 1/8" (231.8 mm)',
  _914 = '9 1/4" (235 mm)',
  _938 = '9 3/8" (238.1 mm)',
  _912 = '9 1/2" (241.3 mm)',
  _958 = '9 5/8" (244.5 mm)',
  _934 = '9 3/4" (247.7 mm)',
  _978 = '9 7/8" (250.8 mm)',
  _10 = '10" (254 mm)',
  _1018 = '10 1/8" (257.2 mm)',
  _1014 = '10 1/4" (260.4 mm)',
  _1038 = '10 3/8" (263.5 mm)',
  _1012 = '10 1/2" (266.7 mm)',
  _1058 = '10 5/8" (269.9 mm)',
  _1034 = '10 3/4" (273.1 mm)',
  _1078 = '10 7/8" (276.2 mm)',
  _11 = '11" (279.4 mm)',
  _1118 = '11 1/8" (282.6 mm)',
  _1114 = '11 1/4" (285.8 mm)',
  _1138 = '11 3/8" (288.9 mm)',
  _1112 = '11 1/2" (292.1 mm)',
  _1158 = '11 5/8" (295.3 mm)',
  _1134 = '11 3/4" (298.5 mm)',
  _1178 = '11 7/8" (301.6 mm)',
  _12 = '12" (304.8 mm)',
  _1218 = '12 1/8" (308 mm)',
  _1214 = '12 1/4" (311.2 mm)',
  _1238 = '12 3/8" (314.3 mm)',
  _1212 = '12 1/2" (317.5 mm)',
  _1258 = '12 5/8" (320.7 mm)',
  _1234 = '12 3/4" (323.9 mm)',
  _1278 = '12 7/8" (327 mm)',
  _13 = '13" (330.2 mm)',
  _1318 = '13 1/8" (333.4 mm)',
  _1314 = '13 1/4" (336.6 mm)',
  _1338 = '13 3/8" (339.7 mm)',
  _1312 = '13 1/2" (342.9 mm)',
  _1358 = '13 5/8" (346.1 mm)',
  _1334 = '13 3/4" (349.3 mm)',
  _1378 = '13 7/8" (352.4 mm)',
  _14 = '14" (355.6 mm)',
  _1418 = '14 1/8" (358.8 mm)',
  _1414 = '14 1/4" (362 mm)',
  _1438 = '14 3/8" (365.1 mm)',
  _1412 = '14 1/2" (368.3 mm)',
  _1458 = '14 5/8" (371.5 mm)',
  _1434 = '14 3/4" (374.7 mm)',
  _1478 = '14 7/8" (377.8 mm)',
  _15 = '15" (381 mm)',
  _1518 = '15 1/8" (384.2 mm)',
  _1514 = '15 1/4" (387.4 mm)',
  _1538 = '15 3/8" (390.5 mm)',
  _1512 = '15 1/2" (393.7 mm)',
  _1558 = '15 5/8" (396.9 mm)',
  _1534 = '15 3/4" (400.1 mm)',
  _1578 = '15 7/8" (403.2 mm)',
  _16 = '16" (406.4 mm)',
  _1618 = '16 1/8" (409.6 mm)',
  _1614 = '16 1/4" (412.8 mm)',
  _1638 = '16 3/8" (415.9 mm)',
  _1612 = '16 1/2" (419.1 mm)',
  _1658 = '16 5/8" (422.3 mm)',
  _1634 = '16 3/4" (425.5 mm)',
  _1678 = '16 7/8" (428.6 mm)',
  _17 = '17" (431.8 mm)',
  _1718 = '17 1/8" (435 mm)',
  _1714 = '17 1/4" (438.2 mm)',
  _1738 = '17 3/8" (441.3 mm)',
  _1712 = '17 1/2" (444.5 mm)',
  _1758 = '17 5/8" (447.7 mm)',
  _1734 = '17 3/4" (450.9 mm)',
  _1778 = '17 7/8" (454 mm)',
  _18 = '18" (457.2 mm)',
  _1818 = '18 1/8" (460.4 mm)',
  _1814 = '18 1/4" (463.6 mm)',
  _1838 = '18 3/8" (466.7 mm)',
  _1812 = '18 1/2" (469.9 mm)',
  _1858 = '18 5/8" (473.1 mm)',
  _1834 = '18 3/4" (476.3 mm)',
  _1878 = '18 7/8" (479.4 mm)',
  _19 = '19" (482.6 mm)',
  _1918 = '19 1/8" (485.8 mm)',
  _1914 = '19 1/4" (489 mm)',
  _1938 = '19 3/8" (492.1 mm)',
  _1912 = '19 1/2" (495.3 mm)',
  _1958 = '19 5/8" (498.5 mm)',
  _1934 = '19 3/4" (501.7 mm)',
  _1978 = '19 7/8" (504.8 mm)',
  _20 = '20" (508 mm)',
  _2018 = '20 1/8" (511.2 mm)',
  _2014 = '20 1/4" (514.4 mm)',
  _2038 = '20 3/8" (517.5 mm)',
  _2012 = '20 1/2" (520.7 mm)',
  _2058 = '20 5/8" (523.9 mm)',
  _2034 = '20 3/4" (527.1 mm)',
  _2078 = '20 7/8" (530.2 mm)',
  _21 = '21" (533.4 mm)',
  _2118 = '21 1/8" (536.6 mm)',
  _2114 = '21 1/4" (539.8 mm)',
  _2138 = '21 3/8" (542.9 mm)',
  _2112 = '21 1/2" (546.1 mm)',
  _2158 = '21 5/8" (549.3 mm)',
  _2134 = '21 3/4" (552.5 mm)',
  _2178 = '21 7/8" (555.6 mm)',
  _22 = '22" (558.8 mm)',
  _2218 = '22 1/8" (562 mm)',
  _2214 = '22 1/4" (565.2 mm)',
  _2238 = '22 3/8" (568.3 mm)',
  _2212 = '22 1/2" (571.5 mm)',
  _2258 = '22 5/8" (574.7 mm)',
  _2234 = '22 3/4" (577.9 mm)',
  _2278 = '22 7/8" (581 mm)',
  _23 = '23" (584.2 mm)',
  _2318 = '23 1/8" (587.4 mm)',
  _2314 = '23 1/4" (590.6 mm)',
  _2338 = '23 3/8" (593.7 mm)',
  _2312 = '23 1/2" (596.9 mm)',
  _2358 = '23 5/8" (600.1 mm)',
  _2334 = '23 3/4" (603.3 mm)',
  _2378 = '23 7/8" (606.4 mm)',
  _24 = '24" (609.6 mm)',
  _2418 = '24 1/8" (612.8 mm)',
  _2414 = '24 1/4" (616 mm)',
  _2438 = '24 3/8" (619.1 mm)',
  _2412 = '24 1/2" (622.3 mm)',
  _2458 = '24 5/8" (625.5 mm)',
  _2434 = '24 3/4" (628.7 mm)',
  _2478 = '24 7/8" (631.8 mm)',
  _25 = '25" (635 mm)',
}
