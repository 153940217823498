import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { Customer } from '../../../../models/customer.model'
import { CustomerService } from '../../../../services/customer/customer.service'
import { Subject, Observable } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CustomerProfileService } from '../customer-profile.service'
import { CustomerProfile } from '../customer-profile.model'

@Component({
  selector: 'oa-customer-information',
  templateUrl: './customer-information.component.html',
  styleUrls: ['./customer-information.component.scss']
})
export class CustomerInformationComponent implements OnInit, OnDestroy {
  @Input() readOnly: boolean
  destroyed$: Subject<boolean> = new Subject()
  customer: Customer
  phoneNumber: string
  profile$: Observable<CustomerProfile> = this.customerProfileService.profile
  file: FileList

  constructor(public customerService: CustomerService, public customerProfileService: CustomerProfileService) {}

  ngOnInit() {
    this.getCustomer()
  }

  getCustomer(): void {
    this.customerService.customer$.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      this.customer = res
      this.phoneNumber = this.formatPhoneNumber(res.telephone)
    })
  }

  formatPhoneNumber(phone: string) {
    if (!phone) {
      return null
    }
    const phoneArray = phone.split('')
    phoneArray.unshift('(')
    phoneArray.splice(4, 0, ')')
    phoneArray.splice(5, 0, ' ')
    phoneArray.splice(9, 0, '-')
    return phoneArray.join('')
  }

  uploadLogo(inputElement: HTMLInputElement) {
    if (this.readOnly) {
      return
    }
    this.customerProfileService.uploadLogo(inputElement.files[0])
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
