import { DoorStyle } from '../enums/door-style';
import { NType } from '../library/standard/n-type';
import { FType } from '../library/standard/f-type';
import { DoorType } from '../abstracts/door-type';
import { Type } from '@angular/core';
import { LNLType } from '../library/standard/lnl-type';
import { VType } from '../library/standard/v-type';
import { FGType } from '../library/standard/fg-type';
import { FG2Type } from '../library/standard/fg2-type';
import { FG3Type } from '../library/standard/fg3-type';
import { GType } from '../library/standard/g-type';
import { FLBType } from '../library/louver/flb-type';
import { FLTType } from '../library/louver/flt-type';
import { FL2Type } from '../library/louver/fl2-type';
import { FLBGType } from '../library/louver/flbg-type';
import { FLBNType } from '../library/louver/flbn-type';
import { FLVCType } from '../library/louver/flvc-type';
import { FLVType } from '../library/louver/flv-type';
import { DFType } from '../library/dutch/df-type';
import { DGType } from '../library/dutch/dg-type';
import { DVType } from '../library/dutch/dv-type';
import { DNType } from '../library/dutch/dn-type';
import { E2GType } from '../library/embossed/e2g-type';
import { E6Type } from '../library/embossed/e6-type';
import { HD2Type } from '../library/embossed/hd2-type';
import { HD2AType } from '../library/embossed/hd2a-type';
import { E1Type } from '../library/embossed/e1-type';
import { E4TLType } from '../library/embossed/e4tl-type';
import { E4Type } from '../library/embossed/e4-type';
import { E2Type } from '../library/embossed/e2-type';
import { VCType } from '../library/standard/vc-type';
import { TransomType } from '../library/standard/transom-type';
import { EGType } from '../library/embossed/eg-type';

export const DoorTypes: { [key in DoorStyle]: { value: Type<DoorType> } } = {
  [DoorStyle.Flush]: {
    value: FType,
  },
  [DoorStyle.Transom]: {
    value: TransomType,
  },
  [DoorStyle.N]: {
    value: NType,
  },
  [DoorStyle.LNL]: {
    value: LNLType,
  },
  [DoorStyle.V]: {
    value: VType,
  },
  [DoorStyle.FG]: {
    value: FGType,
  },
  [DoorStyle.FG2]: {
    value: FG2Type,
  },
  [DoorStyle.FG3]: {
    value: FG3Type,
  },
  [DoorStyle.G]: {
    value: GType,
  },
  [DoorStyle.FLB]: {
    value: FLBType,
  },
  [DoorStyle.FLT]: {
    value: FLTType,
  },
  [DoorStyle.FL2]: {
    value: FL2Type,
  },
  [DoorStyle.FLBG]: {
    value: FLBGType,
  },
  [DoorStyle.FLBN]: {
    value: FLBNType,
  },
  [DoorStyle.FLVC]: {
    value: FLVCType,
  },
  [DoorStyle.VC]: {
    value: VCType,
  },
  [DoorStyle.FLV]: {
    value: FLVType,
  },
  [DoorStyle.DF]: {
    value: DFType,
  },
  [DoorStyle.DG]: {
    value: DGType,
  },
  [DoorStyle.DV]: {
    value: DVType,
  },
  [DoorStyle.DN]: {
    value: DNType,
  },
  [DoorStyle.EG]: {
    value: EGType,
  },
  [DoorStyle.E2G]: {
    value: E2GType,
  },
  [DoorStyle.E4TL]: {
    value: E4TLType,
  },
  [DoorStyle.E6]: {
    value: E6Type,
  },
  [DoorStyle.HD2]: {
    value: HD2Type,
  },
  [DoorStyle.HD2A]: {
    value: HD2AType,
  },
  [DoorStyle.E1]: {
    value: E1Type,
  },
  [DoorStyle.E4]: {
    value: E4Type,
  },
  [DoorStyle.E2]: {
    value: E2Type,
  },
};
