import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { Preference } from '../models/preference';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService extends RestService<Preference> {
  constructor(http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) {elevations: { apiUrl }}: IEnvironment) {
    super(http, `${apiUrl}v1/preferences`);
  }

  @Cacheable()
  getByPreferenceTypeId(preferenceTypeId: number, estimateId: number): Observable<Preference> {
    return this.search({ search: { preferenceTypeId: `${preferenceTypeId}`, estimateId: `${estimateId}` } }).pipe(
      map(preferences => preferences.first())
    );
  }
}
