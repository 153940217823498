<mat-card-header>
  <mat-card-title>{{header}}</mat-card-title>
  <button
    *ngIf="closeable"
    mat-icon-button
    aria-label="'Cancel'"
    (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</mat-card-header>
<mat-divider></mat-divider>
<mat-progress-bar mode="indeterminate" [ngStyle]="{visibility: loading ? 'visible': 'hidden'}" color="primary"></mat-progress-bar>
<mat-card-content>
  <ng-content></ng-content>
</mat-card-content>
<mat-card-footer>
  <div *ngIf="footerTemplate" class="footer">
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </div>
</mat-card-footer>
