import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core'
import { IPrep } from '../../../../core/interfaces/i-prep'
import { DoorPrepSpecialReferencePoint } from '../../../enums/door-prep-special-reference-point'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

@Component({
  selector: 'lib-anchor-hinge-prep',
  templateUrl: './special-prep.component.html',
  styleUrls: ['../prep-base.component.scss']
})
export class AnchorHingePrepComponent extends PrepBaseComponent implements OnInit {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent

  code = this.specialPrepCode
  set prep(value: IPrep) {
    this.prepsComponent.configService.currentDoor.anchorHingePrep = value
  }
  get prep(): IPrep {
    return this.prepsComponent.configService.currentDoor.anchorHingePrep
  }
  referencePoints: DoorPrepSpecialReferencePoint[]
  referencePoint: DoorPrepSpecialReferencePoint

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.ANCHOR_HINGE)
    if (!this.prep) {
      this.prep = {
        id: this.specialPrepCode.id,
        code: this.specialPrepCode.code
      } as IPrep
    }
    this.setSpecial()
  }

  ngOnInit(): void {}
}
