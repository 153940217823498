import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { DefaultBottomRail, DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { CircularLite } from '../../models/cutout-types/circular-lite';
import { Lite } from '../../models/cutout-types/lite';
import { Louver } from '../../models/cutout-types/louver';
import { distanceFromEdge, Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';
import { FLBNType } from './flbn-type';

export class FLVType extends FLBNType {
  cutouts: Cutout[];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);

    this.cutouts = [this.flvLite(), this.flvLouver()];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private flvLite(): Cutout {
    const width = uomSwitch('11"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = uomSwitch('11"', 'door', this.door.doorElevation.unitOfMeasure);
    return new Lite(
      'Lite',
      this,
      (this.door.actualWidth - width) / 2,
      this.distanceFromTop(height),
      width,
      height,
      VerticalCutoutDimensionMode.Top,
      HorizontalCutoutDimensionMode.Lock
    );
  }

  private distanceFromTop(liteHeight: number): number {
    if (this.door.height < uomSwitch(`6' 5"`, 'door', this.door.doorElevation.unitOfMeasure)) {
      return uomSwitch(`7 15/32"`, 'door', this.door.doorElevation.unitOfMeasure);
    }
    return (
      this.door.actualHeight - liteHeight / 2 - uomSwitch(`62 1/4"`, 'door', this.door.doorElevation.unitOfMeasure)
    );
  }

  private flvLouver(): Cutout {
    const width = uomSwitch('24"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = uomSwitch('24"', 'door', this.door.doorElevation.unitOfMeasure);
    return new Louver(
      'Louver',
      this,
      (this.door.actualWidth - width) / 2,
      this.door.actualHeight - height - DefaultBottomRail(this.door.doorElevation.unitOfMeasure),
      width,
      height,
      VerticalCutoutDimensionMode.Bottom,
      HorizontalCutoutDimensionMode.Lock
    );
  }

  drawDimensions(container: SVGGElement): void {
    /*********** VERTICAL ***************/
    // vertical dimension line
    container.appendChild(this.door.drawLine(this.door.minX, 0, this.door.minX, this.door.height));

    this.cutouts.forEach((cutout, index) => {
      const prevMargin = cutout.y - (index === 0 ? 0 : this.cutouts[index - 1].y + this.cutouts[index - 1].height);
      // top margin
      this.door.drawDimensionLineAndText(
        container,
        prevMargin.toDimension('door', this.door.doorElevation.unitOfMeasure),
        this.door.minX + (distanceFromEdge * 3) / 2,
        index === 0 ? 0 : this.cutouts[index - 1].y + this.cutouts[index - 1].height,
        this.door.minX,
        cutout.y
      );

      this.door.drawEdgeDimensionLines(container, cutout.x, cutout.y, cutout.height);
      // cutout height
      this.door.drawDimensionLineAndText(
        container,
        cutout.height.toDimension('door', this.door.doorElevation.unitOfMeasure),
        this.door.minX + (distanceFromEdge * 3) / 2,
        cutout.y,
        this.door.minX,
        cutout.y + cutout.height
      );
      // last cutout  - bottom margin
      if (index === this.cutouts.length - 1) {
        const y1 = cutout.y + cutout.height;
        this.door.drawDimensionLineAndText(
          container,
          (this.door.actualHeight - y1).toDimension('door', this.door.doorElevation.unitOfMeasure),
          this.door.minX + (distanceFromEdge * 3) / 2,
          y1,
          this.door.minX,
          this.door.actualHeight
        );
      }
    });

    /*********** HORIZONTAL ***************/
    // lite horizontal dimension line @ TOP
    container.appendChild(this.door.drawLine(0, this.door.minY, this.door.actualWidth, this.door.minY));
    const lite = this.cutouts.first(x => x.name === 'Lite');
    // lite left margin
    this.door.drawDimensionLineAndText(
      container,
      lite.x.toDimension('door', this.door.doorElevation.unitOfMeasure),
      0,
      this.door.minY + (distanceFromEdge * 3) / 2,
      lite.x,
      this.door.minY,
      true
    );

    this.door.drawEdgeDimensionLines(container, lite.x, lite.y, lite.width, true);
    // lite width
    this.door.drawDimensionLineAndText(
      container,
      lite.width.toDimension('door', this.door.doorElevation.unitOfMeasure),
      lite.x,
      this.door.minY + (distanceFromEdge * 3) / 2,
      lite.x + lite.width,
      this.door.minY,
      true
    );
    // lite  - right margin
    this.door.drawDimensionLineAndText(
      container,
      (this.door.actualWidth - lite.x - lite.width).toDimension('door', this.door.doorElevation.unitOfMeasure),
      lite.x + lite.width,
      this.door.minY + (distanceFromEdge * 3) / 2,
      this.door.actualWidth,
      this.door.minY,
      true
    );

    /******************** LOUVER horizontal dimension line @ TOP ******************/
    // door bottom left edge
    container.appendChild(
      this.door.drawLine(
        0,
        this.door.actualHeight - distanceFromEdge / 2,
        0,
        this.door.actualHeight - distanceFromEdge * 1.5
      )
    );
    // door bottom right edge
    container.appendChild(
      this.door.drawLine(
        this.door.actualWidth,
        this.door.actualHeight - distanceFromEdge / 2,
        this.door.actualWidth,
        this.door.actualHeight - distanceFromEdge * 1.5
      )
    );
    const louver = this.cutouts.first(x => x.name === 'Louver');
    // louver bottom left edge
    container.appendChild(
      this.door.drawLine(louver.x, louver.y + louver.height, louver.x, this.door.height - distanceFromEdge / 2)
    );
    // louver bottom right edge
    container.appendChild(
      this.door.drawLine(
        louver.x + louver.width,
        louver.y + louver.height,
        louver.x + louver.width,
        this.door.height - distanceFromEdge / 2
      )
    );

    this.drawBottomDimensionLine(
      container,
      louver.x.toDimension('door', this.door.doorElevation.unitOfMeasure),
      this.door.height - distanceFromEdge,
      0,
      louver.x
    );
    this.drawBottomDimensionLine(
      container,
      louver.width.toDimension('door', this.door.doorElevation.unitOfMeasure),
      this.door.height - distanceFromEdge,
      louver.x,
      louver.x + louver.width
    );

    this.drawBottomDimensionLine(
      container,
      (this.door.actualWidth - louver.x - louver.width).toDimension('door', this.door.doorElevation.unitOfMeasure),
      this.door.height - distanceFromEdge / 2,
      louver.x + louver.width,
      this.door.actualWidth
    );
  }
}
