import { AfterViewInit, Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { IPrep } from '../../../../core/interfaces/i-prep'
import { DoorPrepSpecialReferencePoint } from '../../../enums/door-prep-special-reference-point'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

@Component({
  selector: 'lib-roller-latch-prep',
  templateUrl: './special-prep.component.html',
  styleUrls: ['../prep-base.component.scss']
})
export class RollerLatchPrepComponent extends PrepBaseComponent implements OnInit, AfterViewInit {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent

  code = this.specialPrepCode
  set prep(value: IPrep) {
    this.prepsComponent.configService.currentDoor.rollerLatchPrep = value
  }
  get prep(): IPrep {
    return this.prepsComponent.configService.currentDoor.rollerLatchPrep
  }

  referencePoints = [DoorPrepSpecialReferencePoint.TDLSCL]
  public get referencePoint(): DoorPrepSpecialReferencePoint {
    return this.prep.specialReferencePoint
  }
  public set referencePoint(value: DoorPrepSpecialReferencePoint) {
    this.prep.specialReferencePoint = value
    this.draw$.next()
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.ROLLER_LATCH)
    if (!this.prep) {
      this.prep = {
        id: this.specialPrepCode.id,
        code: this.specialPrepCode.code,
        location: '0"',
        locationType: this.specialPrepLocation.value
      } as IPrep
    }
    this.setSpecial()
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => {
      this.prepLocationInputs = []
      super.clear()
      if (!this.referencePoint) {
        return
      }
      this.prepLocationInputs.push(this.drawSpecialPrep(this.prep))
    })
    this.referencePoint = this.prep?.specialReferencePoint ?? DoorPrepSpecialReferencePoint.TDLSCL
  }
}
