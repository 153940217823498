import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import '@ornstio/ts-linq';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CustomRuleComponent } from './components/custom-rules/custom-rule/custom-rule.component';
import { CustomRulesComponent } from './components/custom-rules/custom-rules.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { DimensionsComponent } from './components/dimensions/dimensions.component';
import { FilterComponent } from './components/filter/filter.component';
import { MessageHandlerComponent } from './components/message-handler/message-handler.component';
import { PrepSelectionComponent } from './components/prep-selection/prep-selection.component';
import { SpecialHardwareComponent } from './components/special-hardware/special-hardware.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { ApplyMeasureDirective } from './directives/apply-measure.directive';
import { DialogDirective } from './directives/dialog.directive';
import { MoveableDirective } from './directives/moveable.directive';
import { RulerDirective } from './directives/ruler.directive';
import './extensions/number.extensions';
import './extensions/string.extensions';
import './extensions/svg-element.extensions';
import './extensions/svg-polygon-element.extensions';
import './extensions/svg-svg-element.extensions';
import { DialogService } from './services/dialog.service';
import { DoorElevationService } from '../door-elevation/services/door-elevation-service/door-elevation.service';
import { ExperlogixService } from './services/experlogix.service';
import { FolderService } from './services/folder.service';
import { FrameElevationService } from './services/frame-elevation.service';
import { PdfService } from './services/pdf.service';
import { PreferenceService } from './services/preference.service';
import { Overlay, } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { scrollFactory } from './functions/scrollFactory';
import { TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { OEOCommonModule } from '@oeo/common';

@NgModule({
  declarations: [
    ToolBarComponent,
    DialogDirective,
    DialogComponent,
    DimensionsComponent,
    RulerDirective,
    MoveableDirective,
    FilterComponent,
    ApplyMeasureDirective,
    CustomRulesComponent,
    CustomRuleComponent,
    MessageHandlerComponent,
    BreadcrumbsComponent,
    PrepSelectionComponent,
    SpecialHardwareComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    OEOCommonModule
  ],
  exports: [
    TranslateModule,
    OEOCommonModule,
    ToolBarComponent,
    DialogDirective,
    DialogComponent,
    DimensionsComponent,
    RulerDirective,
    MoveableDirective,
    FilterComponent,
    ApplyMeasureDirective,
    CustomRulesComponent,
    MessageHandlerComponent,
    BreadcrumbsComponent,
    PrepSelectionComponent,
    SpecialHardwareComponent,
  ],
  providers: [
    TranslateStore,
    TranslateService,
    DialogService,
    PdfService,
    DoorElevationService,
    FolderService,
    FrameElevationService,
    ExperlogixService,
    PreferenceService,
    TranslateService,
    { provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ]
})
export class CoreModule {}
