import { Component } from "@angular/core";
import { IDialog } from "../../../core/interfaces/i-dialog";
import { DialogService } from "../../../core/services/dialog.service";

@Component({
  selector: 'lib-warning-dialog',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class WarningDialogComponent implements IDialog<{}>{
  confirmText = 'Dismiss'
  data = {}
  iconName = 'info'
  subTitle = 'Changes made to your drawing impacted the available hardware preps.<br/> Please specify hardware preps needed before saving to your estimate.'
  title = 'Hardware Prep Changes'
  showGoBack = false

  constructor(protected dialogService: DialogService) {}

  confirm(){
    this.dialogService.close();
  }

  goBack(){}
}
