import { DFType } from './df-type';
import { Door } from '../../models/door';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Lite } from '../../models/cutout-types/lite';
import { Cutout } from '../../abstracts/cutout';
import { CutoutExport } from '../../models/exports/cutout-export';

export class DNType extends DFType {
  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = uomSwitch('7 3/8"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = this.getHeight();
    this.cutouts = [
      new Lite(
        'Lite',
        this,
        this.shouldFlipCutout() ? DefaultStile(this.door.doorElevation.unitOfMeasure) :
          this.door.actualWidth - width - DefaultStile(this.door.doorElevation.unitOfMeasure),
        DefaultTopRail(this.door.doorElevation.unitOfMeasure),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private getHeight(): number {
    const heights = new Array(8)
      .fill(
        {
          key: uomSwitch(`6' 8"`, 'door', this.door.doorElevation.unitOfMeasure),
          value: uomSwitch('22 11/32"', 'door', this.door.doorElevation.unitOfMeasure),
        },
        0,
        8
      )
      .map((obj, index) => ({
        key: obj.key + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
        value: obj.value + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
      }));
    for (let i = 1; i < heights.length; i++) {
      if (this.door.height < heights[i].key) {
        return heights[i - 1].value;
      }
    }
    return heights.last().value;
  }
}
