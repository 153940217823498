<button
  mat-icon-button
  type="button"
  [matMenuTriggerFor]="Menu"
  #MenuTrigger="matMenuTrigger"
  (click)="stopPropagation($event)"
  [matTooltip]="'ACTIONS.chooseColumns' | translate"
>
  <mat-icon svgIcon="actions_menu"></mat-icon>
</button>
<mat-menu #Menu>
  <div class="menu">
    <div class="header">{{ 'ACTIONS.viewColumns' | translate }}</div>
    <div class="list">
      <ng-container *ngFor="let column of columns">
        <mat-checkbox
          [name]="column.name"
          *ngIf="!column.visibleAlways"
          (click)="$event.stopPropagation()"
          [ngModel]="column.show"
          (ngModelChange)="column.show = $event; updateDisplayColumns()"
          color="accent"
        >
          {{ column.name | translate }}
        </mat-checkbox>
      </ng-container>
    </div>
  </div>
</mat-menu>
