import { guid, nameOf, enumKeys } from '@oeo/common';
import { pipe } from 'lodash/fp';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreConstants } from '../../core/core.constants';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { appendPreps } from '../../core/functions/appendPrep';
import { createProperty } from '../../core/functions/createProperty';
import { parsePreps } from '../../core/functions/parsePrep';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { addClass, createSVGElement, setInnerHTML, setStyles, setX, setX1, setX2, setY, setY1, setY2 } from '../../core/helpers/svg-functions';
import { ICustomRuleType } from '../../core/interfaces/i-custom-rule-type';
import { IPrep } from '../../core/interfaces/i-prep';
import { Cutout, liteLouverEmbossmentRuleType, liteLouverRuleType } from '../abstracts/cutout';
import { DoorType } from '../abstracts/door-type';
import { Updatable } from '../abstracts/updatable';
import { DoorTypes } from '../constants/door-types';
import { Astragal } from '../enums/astragal';
import { DoorEdge } from '../enums/door-edge';
import { DoorGlassInstallation } from '../enums/door-glass-installation';
import { DoorGlassType } from '../enums/door-glass-type';
import { DoorLouverInstallation } from '../enums/door-louver-installation';
import { DoorStyle } from '../enums/door-style';
import { Handing } from '../enums/handing';
import { IDoorHingePrep } from '../interfaces/preps/i-door-hinge-prep';
import { IDoorIntPivotPrep } from '../interfaces/preps/i-door-int-pivot-prep';
import { IDoorLockPrep } from '../interfaces/preps/i-door-lock-prep';
import { IOHStopHolderPrep } from '../interfaces/preps/i-oh-stop-holder-prep';
import { DoorElevation } from './door-elevation';
import { DoorExport } from './exports/door-export';
import { IDoorFlushBoltPrep } from '../interfaces/preps/i-door-flush-bolt-prep';

export const lineStyles = {
  stroke: 'black',
  strokeWidth: '4px',
};
export const distanceStyles = {
  strokeDasharray: '16px',
};

export const imperialTextStyles = {
  stroke: 'black',
  fontSize: '80px',
  fontFamily: 'Work Sans',
};

export const characterHeight = 52;
export const characterWidth = 48;
export const distanceFromEdge = -80;

export class Door extends Updatable {
  get minX(): number {
    return this._minX;
  }

  get minY(): number {
    return this._minY;
  }

  public get viewDimensionArrows(): boolean {
    return this._viewDimensionArrows;
  }
  public set viewDimensionArrows(value: boolean) {
    this._viewDimensionArrows = value;
    this._update$.next();
  }

  constructor(public readonly doorElevation: DoorElevation, door?: DoorExport) {
    super();
    if (!door) {
      return;
    }
    Object.assign(this, door);
    this.doorType = new DoorTypes[this.style].value(this, door.cutouts);
  }

  get height(): number {
    return this.doorElevation.height;
  }

  get actualHeight(): number {
    return this.height - this.doorElevation.headClearance - this.doorElevation.undercut;
  }

  get actualWidth(): number {
    let width = this.width - this.doorElevation.hingeClearance;
    if (this.doorElevation.isPair) {
      if (this.doorElevation.doors.indexOf(this) === 1) {
        width -= this.doorElevation.meetingEdge;
      }
      if (this.doorElevation.doors.indexOf(this) !== 1 && !this.doorElevation.wideInactive) {
        width -= this.doorElevation.meetingEdge;
      }
    }
    if (!this.doorElevation.isPair) {
      width -= this.doorElevation.hingeClearance;
    }
    return width;
  }

  private _door$ = new Subject<void>();
  private _minX: number;
  private _minY: number;
  private _viewDimensionArrows: boolean = true;

  style: DoorStyle;
  id = guid();
  doorType: DoorType;
  handing: Handing;
  width: number;
  active: boolean;
  edge: DoorEdge;
  glassThickness: number = '1/4"'.fromDimension('door', UnitOfMeasure.Imperial);
  glassInstallation: DoorGlassInstallation;
  louverInstallation: DoorLouverInstallation;
  glassType: DoorGlassType;
  astragal: Astragal;

  flushBoltPrep: IDoorFlushBoltPrep;
  closerPrep: IPrep;
  secondaryLockPrep: IDoorLockPrep;
  primaryLockPrep: IDoorLockPrep;
  deadlockPrep: IDoorLockPrep;
  hingePrep: IDoorHingePrep;
  primaryStrikePrep: IPrep;
  secondaryStrikePrep: IPrep;
  deadlockStrikePrep: IPrep;
  tertiaryLockPrep: IDoorLockPrep;
  tertiaryStrikePrep: IPrep;
  blankSurfaceHingePrep: IPrep;
  anchorHingePrep: IPrep;
  topBottomPivotPrep: IPrep;
  intPivotPrep: IDoorIntPivotPrep;
  ohStopHolderPrep: IOHStopHolderPrep;
  dutchDoorShelvesPrep: IPrep;
  magSwitchPrep: IPrep;
  powerTransferPrep: IPrep;
  rollerLatchPrep: IPrep;
  surfaceBoltPrep: IPrep;
  primaryViewerPrep: IPrep;
  secondaryViewerPrep: IPrep;

  static fromXML(value: string, configurationXml: Document, unitOfMeasure: UnitOfMeasure): DoorExport {
    const doc = parseFromXMLString(value);
    const id = this.parseXML(
      doc,
      nameOf((_: Door) => _.id)
    );
    const result = {
      id,
      style: this.parseXML(
        doc,
        nameOf((_: Door) => _.style)
      ) as DoorStyle,
      handing: this.parseXML(
        doc,
        nameOf((_: Door) => _.handing)
      ) as Handing,
      width:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: Door) => _.width)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      active:
        this.parseXML(
          doc,
          nameOf((_: Door) => _.active)
        )?.toLowerCase() === 'true',
      edge: this.parseXML(
        doc,
        nameOf((_: Door) => _.edge)
      ) as DoorEdge,
      glassThickness:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: Door) => _.glassThickness)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      glassInstallation: this.parseXML(
        doc,
        nameOf((_: Door) => _.glassInstallation)
      ) as DoorGlassInstallation,
      louverInstallation: this.parseXML(
        doc,
        nameOf((_: Door) => _.louverInstallation)
      ) as DoorLouverInstallation,
      cutouts: this.parseCutoutXML(configurationXml, id).map(x => Cutout.fromXML(x, unitOfMeasure)),
      glassType: this.parseXML(
        doc,
        nameOf((_: Door) => _.glassType)
      ) as DoorGlassType,
      astragal: this.parseXML(
        doc,
        nameOf((_: Door) => _.astragal)
      ) as Astragal,
      ...parsePreps('door', doc)
    };
    return result;
  }

  private static parseCutoutXML(doc: Document, id: string): string[] {
    const result = doc.evaluate(
      `//category[@id="Cutout"]/option[@id="Cutout"]`,
      doc,
      null,
      XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
      null
    );
    const intersectables: string[] = [];
    let intersectable = result.iterateNext() as HTMLElement;
    while (intersectable) {
      if (Array.from(intersectable.children).first(x => x.getAttribute('id')?.searchFor('id', true)).innerHTML === id) {
        intersectables.push(intersectable.outerHTML);
      }
      intersectable = result.iterateNext() as HTMLElement;
    }
    return intersectables;
  }

  private static parseXML(doc: Document, property: string): string {
    const value = doc.evaluate(
      `option[@id="Door"]/property[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${property.toLowerCase()}']`,
      doc,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    )?.singleNodeValue?.textContent;
    if (!value || value === 'undefined') {
      return null;
    }
    return value;
  }

  toXML(): string {
    return `
      <option id="Door">
        ${createProperty('Location', this.doorElevation.doors.indexOf(this) === 0 ? 'Left' : 'Right')}
        ${createProperty(
          nameOf((_: Door) => _.id),
          this.id
        )}
        ${createProperty(
          nameOf((_: Door) => _.style),
          this.style
        )}
        ${this.handing!== Handing.NH && createProperty(
          nameOf((_: Door) => _.handing),
          this.handing
        )}
        ${createProperty(
          nameOf((_: DoorElevation) => _.width),
          `${
            this.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.width / CoreConstants.multipliers.doorElevation
              : this.width
          }`
        )}
        ${createProperty(
          nameOf((_: Door) => _.active),
          `${this.active}`
        )}
        ${createProperty(
          nameOf((_: Door) => _.edge),
          `${this.edge}`
        )}
        ${createProperty(
          nameOf((_: Door) => _.glassInstallation),
          this.glassInstallation
        )}
        ${createProperty(
          nameOf((_: Door) => _.glassThickness),
          `${
            this.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.glassThickness / CoreConstants.multipliers.doorElevation
              : this.glassThickness
          }`
        )}
        ${createProperty(
          nameOf((_: Door) => _.louverInstallation),
          this.louverInstallation
        )}
        ${createProperty(
          nameOf((_: Door) => _.glassType),
          this.glassType
        )}
        ${createProperty(
          nameOf((_: Door) => _.astragal),
          this.astragal
        )}
        ${appendPreps(this)}
        )}
      </option>`;
  }

  draw(container: SVGGElement, hideDimensions?: boolean): void {
    this._door$.next();
    if (!this.doorType) {
      this.doorType = new DoorTypes[this.style].value(this);
    }
    this.doorType.draw(container);
    if (!hideDimensions) {
      this.drawOverallLines(container);
    }

    if (
      (this.doorElevation.doors.length > 1 && this.doorElevation.doors.indexOf(this) === 1) ||
      (this.doorElevation.doors.length === 1 && (this.handing === Handing.RH || this.handing === Handing.RHR))
    ) {
      container.flip(characterWidth, -(this.width + this.doorElevation.meetingEdge));
    }
    this.doorType.update$.pipe(takeUntil(this._destroy$), takeUntil(this._door$)).subscribe(() => this._update$.next());
  }

  destroy(): void {
    this._door$.next();
    this._door$.unsubscribe();
    super.destroy();
  }

  drawLine(x1: number, y1: number, x2: number, y2: number, styles?: { [key: string]: string }): SVGLineElement {
    return pipe(
      setStyles({ ...lineStyles, ...styles }),
      addClass('line'),
      setY2(y2),
      setY1(y1),
      setX2(x2),
      setX1(x1)
    )(createSVGElement('line'))
  }

  drawText(value: string, x: number, y: number) {
    return pipe(
      setStyles(imperialTextStyles),
      setX(x),
      setY(y),
      setInnerHTML(value)
    )(createSVGElement('text'));
  }

  drawDimensionLineAndText(
    container: SVGGElement,
    str: string,
    x1: number,
    y1: number,
    x2: number,
    y2: number,
    isHorizontal: boolean = false
  ) {
    const y = [y1, y2].average(_ => _);
    const x = [x1, x2].average(_ => _);
    container.appendChild(
      this.drawLine(
        isHorizontal ? x : x1,
        !isHorizontal ? y : y1,
        isHorizontal ? x : x2,
        !isHorizontal ? y : y2,
        distanceStyles
      )
    ); // dotted line

    // value/text at the top
    container.appendChild(
      this.drawText(
        str,
        isHorizontal ? x - (str.length / 2) * characterWidth : x1 - str.length * characterWidth,
        isHorizontal ? y1 : y + characterHeight / 2
      )
    );
  }

  drawEdgeDimensionLines(
    container: SVGGElement,
    x: number,
    y: number,
    length: number,
    isvertical: boolean = false
  ): void {
    let x1: number = x;
    const x2: number = x;
    let y1: number = y;
    const y2: number = y;

    if (isvertical) {
      y += distanceFromEdge;
      y1 = this._minY + distanceFromEdge / 2;
    } else {
      x += distanceFromEdge;
      x1 = this._minX + distanceFromEdge / 2;
    }

    container.appendChild(this.drawLine(x1, y1, x2, y2));
    container.appendChild(
      this.drawLine(
        isvertical ? x + length : x1,
        !isvertical ? y + length + distanceFromEdge / 5 : y1,
        isvertical ? x + length : x2,
        !isvertical ? y + length + distanceFromEdge / 5 : y2
      )
    );
  }

  private drawOverallLines(container: SVGGElement) {
    if (this.viewDimensionArrows) {
      this._minX = (isNaN(container.minX()) ? 0 : container.minX()) + distanceFromEdge;
      this._minY = (isNaN(container.minY(characterHeight)) ? 0 : container.minY(characterHeight)) + distanceFromEdge;
      // allow custom drawings if defined for the selected door type
      if (this.doorType.drawDimensions) {
        this.doorType.drawDimensions(container);
      } else {
        this.drawDimensions(container); // generic
      }
      this._minX = (isNaN(container.minX()) ? 0 : container.minX()) + distanceFromEdge;
      this._minY = (isNaN(container.minY(characterHeight)) ? 0 : container.minY(characterHeight)) + distanceFromEdge;
      this.drawVerticalLines(container); // height
      this.drawHorizontalLines(container); // width
      this.cleanup(container);
    }
  }

  private drawVerticalLines(container: SVGGElement) {
    // vertical line for door height
    container.appendChild(this.drawLine(this._minX, 0, this._minX, this.height - this.doorElevation.headClearance));
    // top horizontal
    container.appendChild(this.drawLine(this._minX, 0, 0, 0));
    // bottom horizontal 1 (undercut top)
    container.appendChild(
      this.drawLine(
        this._minX,
        this.height - this.doorElevation.headClearance,
        0,
        this.height - this.doorElevation.headClearance
      )
    );
    // bottom horizontal 2(undercut bottom)
    container.appendChild(this.drawLine(this._minX, this.actualHeight, 0, this.actualHeight));

    // height in the middle left
    const doorHeight = this.actualHeight.toDimension('door', this.doorElevation.unitOfMeasure);
    this.drawDimensionLineAndText(
      container,
      doorHeight,
      this._minX + distanceFromEdge,
      0,
      this._minX,
      this.actualHeight
    );
    // undercut in the bottom left
    const undercut = this.doorElevation.undercut.toDimension('door', this.doorElevation.unitOfMeasure);
    this.drawDimensionLineAndText(
      container,
      undercut,
      this._minX + distanceFromEdge,
      this.actualHeight,
      this._minX,
      this.height
    );
  }

  private drawHorizontalLines(container: SVGGElement) {
    container.appendChild(this.drawLine(0, this._minY, this.actualWidth, this._minY)); // horizontal width

    container.appendChild(this.drawLine(0, distanceFromEdge / 2, 0, this._minY + distanceFromEdge / 2)); // left edge
    container.appendChild(
      this.drawLine(this.actualWidth, distanceFromEdge / 2, this.actualWidth, this._minY + distanceFromEdge / 2)
    ); // right edge

    // width at the top
    const doorWidth = this.actualWidth.toDimension('door', this.doorElevation.unitOfMeasure);
    this.drawDimensionLineAndText(
      container,
      doorWidth,
      0,
      this._minY + 1.5 * distanceFromEdge,
      this.width,
      this._minY,
      true
    );

    // over all dimensoins at the bottom
    const overall = `${this.width.toDimension('door', this.doorElevation.unitOfMeasure)} X ${this.height.toDimension(
      'door',
      this.doorElevation.unitOfMeasure
    )}`;
    const maxY = isNaN(container.maxY()) ? this.actualHeight : container.maxY();
    container.appendChild(
      this.drawText(
        overall,
        this.actualWidth / 2 - (overall.length * characterWidth) / 2,
        maxY - distanceFromEdge + characterHeight
      )
    );
  }

  private drawDimensions(container: SVGGElement): void {
    if (!this.doorType.cutouts?.any()) {
      return;
    }
    const topRowCutouts: Cutout[] =
      Array.from(
        this.doorType.cutouts
          ?.orderBy(c => c.y)
          .groupBy(c => c.x)
          .values()
      ).map(c => c.first()) ?? [];

    const leftColumnCutouts: Cutout[] = topRowCutouts.any()
      ? this.doorType.cutouts.filter(c => c.x === topRowCutouts[0].x)
      : [];

    /*********************** TOP - HORIZONTAL DIMENSIONS *********************/
    // horizontal dimension line
    container.appendChild(this.drawLine(0, this._minY, this.actualWidth, this._minY));

    topRowCutouts.forEach((cutout, index) => {
      const prevMargin =
        cutout.x - (index === 0 ? 0 : this.doorType.cutouts[index - 1].x + this.doorType.cutouts[index - 1].width);
      // left margin
      this.drawDimensionLineAndText(
        container,
        prevMargin.toDimension('door', this.doorElevation.unitOfMeasure),
        index === 0 ? 0 : this.doorType.cutouts[index - 1].x + this.doorType.cutouts[index - 1].width,
        this._minY + (distanceFromEdge * 3) / 2,
        cutout.x,
        this._minY,
        true
      );

      this.drawEdgeDimensionLines(container, cutout.x, cutout.y, cutout.width, true);
      // cutout width
      this.drawDimensionLineAndText(
        container,
        cutout.width.toDimension('door', this.doorElevation.unitOfMeasure),
        cutout.x,
        this._minY + (distanceFromEdge * 3) / 2,
        cutout.x + cutout.width,
        this._minY,
        true
      );
      // last cutout  - right margin
      if (index === topRowCutouts.length - 1) {
        const x1 = cutout.x + cutout.width;
        this.drawDimensionLineAndText(
          container,
          (this.actualWidth - x1).toDimension('door', this.doorElevation.unitOfMeasure),
          x1,
          this._minY + (distanceFromEdge * 3) / 2,
          this.actualWidth,
          this._minY,
          true
        );
      }
    });

    /*********************** LEFT - VERTICAL DIMENSIONS *********************/
    // vertical dimension line
    container.appendChild(this.drawLine(this._minX, 0, this._minX, this.actualHeight));
    // for each cutout draw vertical dimension lines
    leftColumnCutouts.forEach((cutout, index) => {
      const prevMargin =
        cutout.y - (index === 0 ? 0 : this.doorType.cutouts[index - 1].y + this.doorType.cutouts[index - 1].height);
      // top margin
      this.drawDimensionLineAndText(
        container,
        prevMargin.toDimension('door', this.doorElevation.unitOfMeasure),
        this._minX + (distanceFromEdge * 3) / 2,
        index === 0 ? 0 : this.doorType.cutouts[index - 1].y + this.doorType.cutouts[index - 1].height,
        this._minX,
        cutout.y
      );

      this.drawEdgeDimensionLines(container, cutout.x, cutout.y, cutout.height);
      // cutout height
      this.drawDimensionLineAndText(
        container,
        cutout.height.toDimension('door', this.doorElevation.unitOfMeasure),
        this._minX + (distanceFromEdge * 3) / 2,
        cutout.y,
        this._minX,
        cutout.y + cutout.height
      );
      // last cutout  - bottom margin
      if (index === leftColumnCutouts.length - 1) {
        const y1 = cutout.y + cutout.height;
        this.drawDimensionLineAndText(
          container,
          (this.actualHeight - y1).toDimension('door', this.doorElevation.unitOfMeasure),
          this._minX + (distanceFromEdge * 3) / 2,
          y1,
          this._minX,
          this.actualHeight
        );
      }
    });
  }

  private cleanup(container: SVGGElement): void {
    const arr = Array.from(container.children)
      .filter(element => element instanceof SVGLineElement)
      .groupBy(x => `${x.getAttribute('x1')} ${x.getAttribute('y1')} ${x.getAttribute('x2')} ${x.getAttribute('y2')}`);
    arr.forEach(group => {
      group.skip(1).forEach(element => element.remove());
    });
  }
}

export const doorRuleType: ICustomRuleType = {
  name: 'Door',
  prefix: 'doors',
  properties: [
    { property: nameOf((_: Door) => _.width), name: 'Width', type: 'number' },
    { property: nameOf((_: Door) => _.style), name: 'Style', type: 'string', values: enumKeys(DoorStyle) },
    { property: nameOf((_: Door) => _.handing), name: 'Handing', type: 'string', values: enumKeys(Handing) },
    { property: nameOf((_: Door) => _.edge), name: 'Edge', type: 'string', values: enumKeys(DoorEdge) },
    {
      property: nameOf((_: Door) => _.glassInstallation),
      name: 'Glass Installation',
      type: 'string',
      values: enumKeys(DoorGlassInstallation),
    },
    { property: nameOf((_: Door) => _.glassType), name: 'Glass Type', type: 'string', values: enumKeys(DoorGlassType) },
    {
      property: nameOf((_: Door) => _.glassThickness),
      name: 'Glass Thickness',
      type: 'number',
    },
    {
      property: nameOf((_: Door) => _.louverInstallation),
      name: 'Louver Installation',
      type: 'string',
      values: enumKeys(DoorLouverInstallation),
    },
    {
      property: nameOf((_: DoorType) => _.cutouts),
      name: 'Lites/Louvers',
      type: 'array',
      ruleType: liteLouverRuleType,
    },
    {
      property: 'allCutouts',
      name: 'Lites/Louvers/Embossments',
      type: 'array',
      ruleType: liteLouverEmbossmentRuleType,
    },
  ],
};
