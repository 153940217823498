import { Directive, ViewContainerRef, Type, ComponentRef, OnDestroy } from '@angular/core'
import { DialogService } from '../services/dialog.service'
import { Subject, fromEvent } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IDialog } from '../interfaces/i-dialog'

@Directive({
  selector: '[libDialog]'
})
export class DialogDirective implements OnDestroy {
  private ref: ComponentRef<IDialog<any>>
  private destroy = new Subject<void>()
  private events = new Subject<void>()

  constructor(private viewContainerRef: ViewContainerRef, private dialogService: DialogService) {
    this.dialogService.dialog$.pipe(takeUntil(this.destroy)).subscribe((output) => {
      this.close(null)
      if (output == null) {
        return
      }
      this.open(output)
    })

    this.dialogService.close$.pipe(takeUntil(this.destroy)).subscribe((output) => {
      this.close(output)
    })
  }

  ngOnDestroy() {
    this.close()
    this.destroy.next()
    this.destroy.unsubscribe()
  }

  open(output: { component: Type<IDialog<any>>; data: any; opts: { closeable: boolean } }) {
    this.ref = this.viewContainerRef.createComponent(output.component)
    this.ref.instance.data = output.data
    const container = this.buildDialogContainer(this.ref.location.nativeElement, output.opts)
    this.viewContainerRef.element.nativeElement.appendChild(container)
  }

  close(data?: any) {
    this.events.next()
    if (this.ref != null) {
      this.ref.location.nativeElement.parentElement?.remove()
      this.ref.destroy()
      this.ref = null
      this.dialogService.close(data)
    }
  }

  buildDialogContainer(child: HTMLElement, opts: { closeable: boolean }) {
    const element = document.createElement('div')
    element.appendChild(child)
    element.classList.add('dialog-container')
    if (opts?.closeable) {
      fromEvent(element, 'mousedown')
        .pipe(takeUntil(this.events))
        .subscribe(() => {
          this.close()
        })
      fromEvent(child, 'mousedown')
        .pipe(takeUntil(this.events))
        .subscribe((e) => {
          e.stopPropagation()
        })
    }
    return element
  }
}
