import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { FrameElevationExport } from '../../frame-elevation/models/exports/frame-elevation-export';
import { DoorElevationExport } from '../../door-elevation/models/exports/door-elevation-export';

@Injectable()
export class PdfService {
  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  download(configurationData: string, type: 0 | 1) {
    return this.httpClient.post(`${this.environment.elevations.apiUrl}v1/pdf?type=${type}`, [{ configurationData }], {
      responseType: 'arraybuffer',
    });
  }
}
