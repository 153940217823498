import { omit, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import { createSVGElement, setAttributes, setStyles, setX, setY, setWidth, setHeight, appendChildrenElements, setX1, setX2, setY1, setY2 } from '../../../../core/helpers/svg-functions';
import { Panel } from '../../../abstracts/panel';

const panelStyles = {
  stroke: 'black',
  strokeWidth: '4px',
  fill: 'lightgrey',
  fillOpacity: '0.35',
  transform: 'translate(2, 2)',
};

const lineStyles = {
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '2px',
};

export class HDSquarePanel extends Panel {
  constructor(x: number, y: number, width: number, height: number) {
    super(x, y, width, height);
  }

  drawCutout() {

    const multiplier = [0, 0.25, 1.75, 2, 3];

    const distance = 80;
    const rects = new Array(5).fill(0).map((_, i) => {
      return pipe(
        setX(2 + distance * multiplier[i]),
        setY(2 + distance * multiplier[i]),
        setWidth(this.width - distance * multiplier[i] * 2 - 4),
        setHeight(this.height - distance * multiplier[i] * 2 - 4),
        setStyles(omit(panelStyles, 'transform')),
        setAttributes(pick(panelStyles, 'transform')),
      )(createSVGElement('rect'));
    })

    const topLeft = pipe(
      setX1(distance * 2 + 2),
      setX2(distance * 3),
      setY1(distance * 2 + 2),
      setY2(distance * 3),
      setStyles(lineStyles)
      )(createSVGElement('line'));


    const topRight = pipe(
      setX1(this.width - distance * 2 - 2),
      setX2(this.width - distance * 3),
      setY1(distance * 2 + 2),
      setY2(distance * 3),
      setStyles(lineStyles)
    )(createSVGElement('line'));


    const bottomRight = pipe(
      setX1(this.width - distance * 2 - 2),
      setX2(this.width - distance * 3),
      setY1(this.height - distance * 2 - 2),
      setY2(this.height - distance * 3),
      setStyles(lineStyles)
    )(createSVGElement('line'));


    const bottomLeft = pipe(
      setX1(distance * 2 + 2),
      setX2(distance * 3),
      setY1(this.height - distance * 2 - 2),
      setY2(this.height - distance * 3),
      setStyles(lineStyles)
    )(createSVGElement('line'));


    return pipe(
      setAttributes({transform:`translate(${this.x}, ${this.y})` }),
      appendChildrenElements([...rects, topLeft, topRight, bottomRight, bottomLeft]),
    )(createSVGElement('g'))
  }
}
