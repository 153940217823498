<div class="container">
  <div class="content">
    <div class="row top">
      <div></div>
      <div class="buttons">
        <div
          *ngFor="let t of templateDisplayOptions"
          [ngClass]="{ selected: t === templateDisplayOption }"
          (click)="filterProduct = filterProduct"
        >
          {{ t.name | translate }}
        </div>
      </div>
      <div class="right">
        <input
        type="search"
        ngModel
        (ngModelChange)="search = $event"
        [placeholder]="'ACTIONS.search' | translate"
        />
      </div>
    </div>
    <div class="row wrap" *ngIf="!(loading$|async); else Loading">
      <div class="card" *ngFor="let dt of drawings$ | async">
        <div class="titleOptions">
          <span class="title">{{ dt.name }}</span>
          <span>
            <mat-icon [matMenuTriggerFor]="menu">more_horiz</mat-icon>
            <mat-menu class="options" #menu="matMenu" xPosition="before">
              <button mat-menu-item (click)="deleteDrawing(dt.id)">{{'BUTTON.delete' | translate}}</button>
            </mat-menu>
          </span>
        </div>
        <div class="image" (click)="createTemplate(dt)">
          <img [src]="dt.thumbnail" />
        </div>
      </div>
    </div>
  </div>
  <lib-filter
    [filterProduct]="'Door'"
    (filterProductChange)="filterProduct = $event"
  ></lib-filter>
</div>
<ng-template #Loading>
  <div class="loading">
    <mat-spinner color="primary"></mat-spinner>
  </div>
</ng-template>
