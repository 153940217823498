import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';

export interface IDoorFlushBoltPrep extends IPrep {
  description: string;
}

export class DoorFlushBoltPrep extends Prep implements IDoorFlushBoltPrep {
  description: string;

  properties(): { [key: string]: (value: string) => any } {
    const obj = super.properties();
    obj[nameOf((_: IDoorFlushBoltPrep) => _.description)] = (value: string) => value;
    return obj;
  }
}
