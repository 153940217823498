import { DFType } from './df-type';
import { Door } from '../../models/door';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Lite } from '../../models/cutout-types/lite';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Cutout } from '../../abstracts/cutout';
import { CutoutExport } from '../../models/exports/cutout-export';

export class DVType extends DFType {
  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = uomSwitch('11"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = uomSwitch('11"', 'door', this.door.doorElevation.unitOfMeasure);
    this.cutouts = [
      new Lite(
        'Lite',
        this,
        (this.door.actualWidth - width) / 2,
        this.distanceFromTop(height),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private distanceFromTop(liteHeight: number): number {
    if (this.door.height < uomSwitch(`6' 5"`, 'door', this.door.doorElevation.unitOfMeasure)) {
      return uomSwitch(`7 15/32"`, 'door', this.door.doorElevation.unitOfMeasure);
    }
    return (
      this.door.actualHeight - liteHeight / 2 - uomSwitch(`62 1/4"`, 'door', this.door.doorElevation.unitOfMeasure)
    );
  }
}
