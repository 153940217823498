<div class="container">
  <h1>{{ 'welcomeToEstimate' | translate }} <mat-icon svgIcon="oeo"></mat-icon> {{ 'order' | translate }}</h1>
  <div
    class="branch-selection"
    *ngIf="
      ((customerService.isInternalUser$ | async) || multiBranchEnabled) &&
        (customerService.externalUserStateLoading$ | async) === false;
      else loading
    "
  >
    <div class="form">
      <p>
        {{ 'selectBranch' | translate }}
      </p>
      <form>
        <mat-form-field appearance="outline" floatLabel="always" class="no-hint">
          <mat-label class="mat-label">{{ 'company' | translate }} </mat-label>
          <input
            matInput
            name="parentCompany"
            [readonly]="!interalUser"
            [matAutocomplete]="auto"
            [value]="(customerService.parentCompany$ | async)?.customerName"
            (keyup)="customerService.searchCompany$.next($event.target.value)"
            placeholder="{{ (interalUser ? 'enter' : 'loading') | translate }}"
          />
          <div
            matSuffix
            class="company-search-suffix"
            *ngIf="
              (customerService.searchingCompanies$ | async).loading ||
              (customerService.searchingCompanies$ | async).error
            "
          >
            <mat-spinner
              *ngIf="(customerService.searchingCompanies$ | async).loading"
              mode="indeterminate"
              diameter="20"
              stokeWidth="10"
            ></mat-spinner>
            <mat-icon
              *ngIf="(customerService.searchingCompanies$ | async).error"
              [matTooltip]="'ERRORS.gettingCompanies' | translate"
            >
              error_outline
            </mat-icon>
          </div>

          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="helperService.displayName">
            <mat-option
              class="account-select"
              *ngFor="let parentCompany of customerService.parentCompanies"
              [value]="parentCompany"
              (click)="selectCompany(parentCompany)"
              ovtTrack="click"
              ovtCategory="MBA"
              ovtAction="Select Company"
              [ovtProperties]="{ Location: 'Landing' }"
            >
              <span class="mat-subheading-1"
                >{{ parentCompany.customerName | titlecase }} - {{ parentCompany.primaryCity | titlecase }}</span
              >
              <span class="mat-subheading-1" *ngIf="parentCompany.primaryState !== 'Not Available'"
                >, {{ parentCompany.primaryState | uppercase }}</span
              >
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" class="no-hint">
          <mat-label class="mat-label">{{ 'branch' | translate }}</mat-label>
          <input
            #branchInput
            matInput
            [matAutocomplete]="autoBranch"
            name="branch"
            [value]="customerService.getBranchDisplayName(targetBranch)"
            libAutocomplete
            spellcheck="false"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #autoBranch="matAutocomplete"
            [displayWith]="customerService.getBranchDisplayName"
          >
            <mat-option
              *ngFor="
                let branch of customerService.customerBranches
                  | libAutoCompleteFilter: branchInput.autocompleteValue:'customerAccount'
              "
              (click)="targetBranch = branch"
              [value]="branch"
              class="branch-option-select"
              ovtTrack="click"
              ovtCategory="MBA"
              ovtAction="Select Branch"
              [ovtProperties]="{ Location: 'Landing' }"
            >
              {{ customerService.getBranchDisplayName(branch) }}
            </mat-option>
          </mat-autocomplete>
          <div matSuffix class="company-search-suffix" *ngIf="(customerService.searchingBranches$ | async).error">
            <mat-icon [matTooltip]="'ERRORS.gettingBranches' | translate"> error_outline </mat-icon>
          </div>
        </mat-form-field>
        <mat-checkbox
          *ngIf="customerService.isExternalUser$ | async"
          name="defaultBranchOnLogin"
          [(ngModel)]="setDefaultBranchOnLogin"
          color="primary"
        >
          Open this Branch as default on next login.
        </mat-checkbox>
      </form>
      <button mat-flat-button color="primary" [disabled]="!targetBranch" (click)="selectBranch()">
        {{ 'ACTIONS.submit' | translate }}
      </button>
    </div>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</ng-template>
