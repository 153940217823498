import { CutoutOrderSize } from './cutout-order-size';

export enum DoorLouverKit {
  _800A1 = 'Air Louver 800A1',
  _1400A = 'Air Louver 1400A',
  _1500A = 'Air Louver 1500A',
  HRC = 'Air Louver 1500A-HRC',
  AFDL = 'Air Louver AFDL',
  FDLS = 'Air Louver FDLS',
  UL = 'Air Louver FLDL-UL',
  RX = 'Air Louver L-700-RX',
  _100AFL_G = '100AFL (G) Advantage Louver GLV',
  _100AFL = '100AFL Advandage Louver',
  _1900_A = '1900-A Air Louvers',
  _1900_A_G = '1900-A(G) Air Louvers GLV',
  FLDL_UL_G = 'FLDL-UL(G) Anemostat GLV',
  FLDL_UL = 'FLDL-UL Anemostat',
  _600A = 'Air Louver 600A',
}

export const DoorLouverOrderSizes: { [key in DoorLouverKit]: CutoutOrderSize } = {
  [DoorLouverKit._600A]: CutoutOrderSize.C,
  [DoorLouverKit._1400A]: CutoutOrderSize.C,
  [DoorLouverKit._1500A]: CutoutOrderSize.C,
  [DoorLouverKit.HRC]: CutoutOrderSize.C,
  [DoorLouverKit.AFDL]: CutoutOrderSize.C,
  [DoorLouverKit.FDLS]: CutoutOrderSize.C,
  [DoorLouverKit.UL]: CutoutOrderSize.C,
  [DoorLouverKit.RX]: CutoutOrderSize.C,
  [DoorLouverKit._800A1]: CutoutOrderSize.C,
  [DoorLouverKit._100AFL_G]: CutoutOrderSize.C,
  [DoorLouverKit._100AFL]: CutoutOrderSize.C,
  [DoorLouverKit._1900_A]: CutoutOrderSize.C,
  [DoorLouverKit._1900_A_G]: CutoutOrderSize.C,
  [DoorLouverKit.FLDL_UL_G]: CutoutOrderSize.C,
  [DoorLouverKit.FLDL_UL]: CutoutOrderSize.C,
};
