<mat-card>
  <mat-card-content>
    <div class="photo-and-info">
      <form class="image-upload">
        <img *ngIf="!(profile$ | async)?.logo" class="default" src="/assets/img/upload-icon.svg" alt="" />
        <label class="label" for="fileInput">{{ 'LABEL.clickToUploadImage' | translate }}</label>
        <img
          *ngIf="(profile$ | async)?.logo"
          class=""
          [src]="'data:image/gif;base64,' + (profile$ | async).logo"
          alt=""
        />
        <input
          id="fileInput"
          type="file"
          aria-label="Logo upload input"
          [(ngModel)]="file"
          (change)="uploadLogo($event.target)"
          name="logo"
          [disabled]="readOnly"
        />
      </form>
      <div class="customer-info">
        <span class="name">{{ customer?.name }}</span>
        <span>{{ customer?.address.line1 }}</span>
        <span
          >{{ customer?.address.city }}, {{ customer?.address.stateOrProvince }}
          {{ customer?.address.postalCode }}</span
        >
        <span>{{ phoneNumber }}</span>
      </div>
    </div>
    <div class="info-message">
      <span>{{ 'businessNameChange' | translate }}</span>
    </div>
  </mat-card-content>
</mat-card>
