export class DoorMarking {
  id?: number
  name: string
  quantity: number
  quantityToOrder: number
  isKeyed: boolean
}

export class QuantityToOrderProductDoorMarking extends DoorMarking {
  estimateProductId: number
}

export class QuantityToOrderHardwareSetDoorMarking extends DoorMarking {
  estimateHardwareSetId: number
}
