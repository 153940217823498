import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { Profile } from '../../abstracts/profile';
import { ProfileTemplate, ProfileTemplateDimensionPosition } from '../../abstracts/profile-template';
import { Direction } from '../../enums/direction';
import { GlassExport } from '../../models/exports/glass-export';

export class FCOS extends ProfileTemplate {
  private _a: number;
  private _e: number;

  get stopHeight1() {
    return this.dimensions.A.get();
  }
  get stopHeight2() {
    return NaN;
  }
  get backBend1() {
    return NaN;
  }
  get backBend2() {
    return this.dimensions.E.get();
  }
  get doorSideRabbet1() {
    return NaN;
  }
  get oppositeDoorSideRabbet1() {
    return NaN;
  }
  get doorSideRabbet2() {
    return NaN;
  }
  get oppositeDoorSideRabbet2() {
    return NaN;
  }

  readonly dimensions = {
    A: {
      direction: Direction.Up,
      get: () => this._a,
      set: (value: number) => {
        this._a = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.Left,
    },
    B: {
      direction: Direction.Right,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    C: {
      direction: Direction.Down,
      get: () => this.profile.jambDepth,
      position: () => ProfileTemplateDimensionPosition.MaxX,
    },
    D: {
      direction: Direction.Left,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MaxY,
      doorSide: true,
    },
    E: {
      direction: Direction.Up,
      get: () => this._e,
      set: (value: number) => {
        this._e = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.Left,
    },
  };

  constructor(profile: Profile, glazingBeads: GlassExport[]) {
    super(profile, glazingBeads);
    this._a = this._e = this.defaultBackbend;
    this.loadDimensions();
  }
}
