/**
 * Prep Category IDs are used to filter out the preps when users
 * add preps to doors
 */
export enum DoorPrepCategoryIds {
  FLUSH_BOLT = 1,
  BOTTOM_FLUSH_BOLT = 2,
  CLOSER = 3,
  SECONDARY_LOCK = 4,
  PRIMARY_LOCK = 5,
  DEADLOCK = 6,
  HINGE = 7,
  PRIMARY_STRIKE = 8,
  SECONDARY_STRIKE = 9,
  DEADLOCK_STRIKE = 10,
  TERTIARY_LOCK = 11,
  TERTIARY_STRIKE = 12,
  CONTINUOUS_HINGE = 13,
  BLANK_SURFACE_HINGE = 14,
  ANCHOR_HINGE = 15,
  TOP_BOTTOM_PIVOT = 16,
  INT_PIVOT = 17,
  ELECTRIC_HINGE = 18,
  OH_STOP_HOLDER = 20,
  MAG_SWITCH = 21,
  POWER_TRANSFER = 22,
  ROLLER_LATCH = 23,
  SURFACE_BOLT = 24,
  JAMB_ANCHOR = 25,
  HEAD_STRIKE = 26,
  COORDINATOR = 27,
  FLUSH_SURFACE_BOLT = 28,
  REMOVABLE_MULLION = 29,
  HOSPITAL_STOP = 30,
  MAG_LOCK = 31,
  VIEWER = 32,
  JUNCTION_BOX_HEAD = 33,
  JUNCTION_BOX_STRIKE_JAMB = 34,
  JUNCTION_BOX_HINGE_JAMB = 35,
  JUNCTION_BOX_SILL = 36,
  SILL_ANCHOR = 37,
  HEAD_ANCHOR = 38,
  BASE_ANCHOR = 39,
  DUTCH_DOOR_SHELVES = 40
}

export enum FramePrepCategoryIds {
  CLOSER = 3,
  HINGE = 7,
  PRIMARY_STRIKE = 8,
  SECONDARY_STRIKE = 9,
  DEADLOCK_STRIKE = 10,
  TERTIARY_STRIKE = 12,
  MAG_SWITCH = 21,
  POWER_TRANSFER = 22,
  ROLLER_LATCH = 23,
  BOTTOM_JAMB_ANCHOR = 25,
  LEFT_JAMB_ANCHOR = 25,
  RIGHT_JAMB_ANCHOR = 25,
  TOP_JAMB_ANCHOR = 25,
  HEAD_STRIKE = 26,
  COORDINATOR = 27,
  FLUSH_SURFACE_BOLT = 28,
  REMOVABLE_MULLION = 29,
  HOSPITAL_STOP = 30,
  MAG_LOCK = 31,
  JUNCTION_BOX_HEAD = 33,
  JUNCTION_BOX_STRIKE = 34,
  JUNCTION_BOX_HINGE = 35,
  JUNCTION_BOX_SILL = 36,
  SILL_ANCHOR = 37,
  HEAD_ANCHOR = 38,
  BASE_ANCHOR = 39
}
