import { CSSPropKey } from '@oeo/common';
import { createSVGElement } from '../../core/helpers/svg-functions';
import { PointAnchor } from './point-anchor';

const jambClass = 'jamb';
const styles: Record<string, string> = {
  fill: '#a6a6a6',
  stroke: 'black',
};

export class Jamb {
  private _element: SVGPolygonElement;

  get _text() {
    return this.container.previousSibling as SVGTextElement;
  }

  constructor(private container: SVGGElement, private pointAnchors: PointAnchor[]) {
    this._element = createSVGElement('polygon');
    this._element.classList.add(jambClass);
    for (const key in styles) {
      this._element.style[key as CSSPropKey] = styles[key];
    }
    this.container.insertAdjacentElement('afterbegin', this._element);
    for (const pointAnchor of pointAnchors) {
      this._element.points.appendItem(pointAnchor.point);
    }
  }

  moveUp(points: Partial<DOMPoint>[]) {
    for (const point of points) {
      this.pointAnchors
        .filter(pa => pa.anchor.x === point.x && pa.anchor.y === point.y)
        .forEach(pa => (pa.point.y -= 1));
    }
  }

  moveDown(points: Partial<DOMPoint>[]) {
    for (const point of points) {
      this.pointAnchors
        .filter(pa => pa.anchor.x === point.x && pa.anchor.y === point.y)
        .forEach(pa => (pa.point.y += 1));
    }
  }

  moveLeft(points: Partial<DOMPoint>[]) {
    for (const point of points) {
      this.pointAnchors
        .filter(pa => pa.anchor.x === point.x && pa.anchor.y === point.y)
        .forEach(pa => (pa.point.x -= 1));
    }
  }

  moveRight(points: Partial<DOMPoint>[]) {
    for (const point of points) {
      this.pointAnchors
        .filter(pa => pa.anchor.x === point.x && pa.anchor.y === point.y)
        .forEach(pa => (pa.point.x += 1));
    }
  }

  destroy() {
    this.pointAnchors = null;
    this._element.remove();
    this._element = null;
  }
}
