import { Component, Inject, Input } from '@angular/core';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../interfaces/i-environment';

@Component({
  selector: 'lib-message-handler',
  templateUrl: './message-handler.component.html',
  styleUrls: ['./message-handler.component.scss'],
})
export class MessageHandlerComponent {
  @Input() messages: { value: string; level: number }[];
  top = 16;
  left = 80;
  constructor(@Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment) {}
}
