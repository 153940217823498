import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from '../../core/services/dialog.service';
import { PrepCategoryService } from '../../core/services/door-prep-category.service';
import { FramePrepCategoryCodeService } from '../../core/services/frame-prep-category-code.service';
import { FramePrepCodeService } from '../../core/services/frame-prep-code.service';
import { FramePrepService } from '../../core/services/frame-prep.service';
import { LookupsService } from '../../core/services/lookups.service';
import { GenericPrepsComponent } from '../generic-preps/generic-preps.component';

@Component({
  selector: 'lib-frame-preps',
  templateUrl: '../generic-preps/generic-preps.component.html',
  styleUrls: ['../generic-preps/generic-preps.component.scss'],
})
export class FramePrepsComponent extends GenericPrepsComponent implements AfterViewInit, OnDestroy {
  constructor(
    prepService: FramePrepService,
    prepCategoryService: PrepCategoryService,
    prepCodeService: FramePrepCodeService,
    prepCodeCategoryService: FramePrepCategoryCodeService,
    dialogService: DialogService,
    lookupsService: LookupsService
  ) {
    super(prepService, prepCategoryService, prepCodeService, prepCodeCategoryService, dialogService, lookupsService, 1);
  }

  ngAfterViewInit() {
    super.getData();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
