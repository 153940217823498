import { EstimateHardwareSetDoorMarking } from './estimate-hardware-set-door-marking.model'
import { EstimateProductDoorMarking } from './estimate-product-door-marking.model'
import { FlattenedHardwareSet } from './flattenedHardwareSet.model'
import { FlattenedProduct } from './product.model'

export class Item {
  id: number
  description: string
  dhiSequence?: number
  itemId: number
  itemType: 'product' | 'hardwareSet'
  quantity: number
  item: FlattenedProduct | any
  brand?: string
  category?: string
  _expandable: boolean
  canConfigure: boolean
  isSystemSet: boolean
  marks_disabled: boolean
  handing?: string
  customPrice?: boolean
  constructor(item: FlattenedProduct | FlattenedHardwareSet) {
    this.quantity = item.quantity
    this.item = item
    this.id = item.id
    if ((item as FlattenedProduct).productId) {
      item = item as FlattenedProduct
      this.description = item.acn || item.description
      this.dhiSequence = item.dhisequence
      this.itemId = item.productId
      this.itemType = 'product'
      this.brand = item.brand
      this.handing = item.handing
      this.category = item.category
      this.canConfigure = item.canConfigure
      this.marks_disabled = !!item?.hollowMetalStockProduct?.valuePacQuantity
      this.customPrice = item.customPrice
    } else {
      // HardwareSet
      item = item as FlattenedHardwareSet
      this.description = item.name
      this.itemId = item.id
      this.itemType = 'hardwareSet'
      this.isSystemSet = item.isSystemSet
      this._expandable = true
    }
  }
}

export class MarkedItem extends Item {
  doorNumber: string
  doorMarkingId: number

  description: string
  itemId: number
  itemType: 'product' | 'hardwareSet'
  _stripe: boolean

  constructor(
    item: FlattenedProduct | FlattenedHardwareSet,
    doorMark: EstimateProductDoorMarking | EstimateHardwareSetDoorMarking
  ) {
    super(item)
    this.doorNumber = doorMark.name
    this.quantity = doorMark.quantity
    this.doorMarkingId = doorMark.id
  }
}
