import { coreLouverInstallations } from '../../rules/cores';
import { steelStiffenLouverInstallations } from '../../rules/steel-stiffen';
import { DoorCore } from '../door-core';
import { DoorLouverInstallation } from '../door-louver-installation';
import { DoorSteelStiffen } from '../door-steel-stiffen';
import { getEnumValues } from '@oeo/common'

export function DoorLouverInstallations(core: DoorCore, steelStiffen: DoorSteelStiffen) {
  const louverInstallations = getEnumValues(DoorLouverInstallation);
  steelStiffenLouverInstallations(louverInstallations, steelStiffen);
  coreLouverInstallations(louverInstallations, core);
  return louverInstallations;
}
