import { uomSwitch } from '../../../../core/functions/uomSwitch';
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { FramePrepReferencePoint } from '../../../enums/frame-prep-reference-point';
import { StickSubtype } from '../../../enums/stick-subtype';
import { IFrameQuantityPrep } from '../../../interfaces/preps/i-frame-quantity-prep';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';
import { FilteredKeys } from '@oeo/common'
import { FrameElevation } from '../../../models/frame-elevation';

type JunctionBoxPrepKeys = FilteredKeys<FrameElevation, `${string}AnchorPrep`>

export abstract class AnchorPrepComponent extends PrepBaseComponent {
  abstract subTypes: StickSubtype[];
  public noReferencePoint: boolean;
  codes: IPrepCode[];
  private _code: IPrepCode;
  public get code(): IPrepCode {
    return this._code;
  }
  public set code(value: IPrepCode) {
    this._code = value;

    this.updateWithStandardLocation()
    this.updateValue();
  }

  private _quantity: number;
  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;
    if (this.locations.length === 1) {
      this.location = this.locations.first();
    }
    this.updateValue();
  }

  private _locations: IPrepCategoryLocation[];
  public get locations(): IPrepCategoryLocation[] {
    return this._locations;
  }
  public set locations(value: IPrepCategoryLocation[]) {
    this._locations = value;
  }
  private _location: IPrepCategoryLocation;
  get location(): IPrepCategoryLocation {
    return this._location;
  }
  set location(value: IPrepCategoryLocation) {
    this._location = value;
    this.updateValue();
  }

  public updateWithStandardLocation(){
    if(this.isShippedLoose){
      this._location = null
      return
    }
    if(!this.locations.find(({value})=> value === this.standardPrepLocation.value)){
      this.locations = [...this.locations, this.standardPrepLocation]
    }
    this._location = this.standardPrepLocation
  }

  abstract readonly isSpecial: boolean;
  protected _referencePoint: FramePrepReferencePoint;
  get referencePoint(): FramePrepReferencePoint {
    return this._referencePoint;
  }
  set referencePoint(value: FramePrepReferencePoint) {
    this._referencePoint = value;
    this.updateValue();
  }

  get prep(): IFrameQuantityPrep {
    return this.frameElevation[this.framePrepCodeCategory];
  }
  set prep(value: IFrameQuantityPrep) {
    this.frameElevation[this.framePrepCodeCategory] = value;
  }

  constructor(
    prepsComponent: PrepsComponent,
    private framePrepCodeCategory: JunctionBoxPrepKeys,
    private prepCategoryId: number,
    public name: string,
    public referencePoints: FramePrepReferencePoint[],
    public showCodeOnly: boolean = true
  ) {
    super(prepsComponent, prepCategoryId);
    if (!this.prep) {
      this.prep = {} as IFrameQuantityPrep;
    }
    this._code = this.specialPrepCode;

    this.locations = this.prepsComponent.configService.prepCategories
      .first(c => c.id === prepCategoryId)
      .prepCategoryLocations.concat([this.specialPrepLocation]);

    this._location = this.locations.first(c => c.value === this.prep.locationType) ?? null;
    this._referencePoint = this.referencePoints.first(c => c === this.prep.referencePoint) ?? null;
    this.updateWithStandardLocation()
    this.setSpecial();
  }

  protected init(): void {
    if (!this.prep) {
      this.prep = {} as IFrameQuantityPrep;
    }
    this._quantity = this.prep.quantity ?? null;
    if (this.isSpecial) {
      this.codes = this.codes.concat(this.specialPrepCode);
    }
    this._code = this.codes.first(c => c.id === this.prep.id) ?? null;
    this.locations = this.prepsComponent.configService.prepCategories
      .first(c => c.id === this.prepCategoryId)
      .prepCategoryLocations.concat([this.specialPrepLocation]);
    this._location = this.locations.first(c => c.value === this.prep.locationType) ?? null;

    this.setSpecial();
  }

  updateValue(): void {
    if (!this.quantity && !this.code) {
      this.prep = {} as IFrameQuantityPrep;
      this._code = null;
      this._location = null;
      return this.draw$.next();
    }

    this.noReferencePoint = this.location?.value != "Special";

    if (!(this.prep.location instanceof Array)) {
      this.prep.location = [];
    }
    const locations = (this.prep.location as string[]).take(this.quantity);
    this.prep.location = locations.concat(
      new Array(this.quantity - locations.length).fill(
        uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure),
        0,
        this.quantity - locations.length
      )
    );
    this.prep.quantity = this.quantity;
    this.prep.id = this.code?.id;
    this.prep.code = this.code?.code;
    this.prep.pricingCategory = this.code?.pricingCategory;
    this.prep.locationType = this.location?.value;
    this.prep.fixedLocation = this.code?.fixedLocation;
    this.prep.referencePoint = this.referencePoint;
    this.draw$.next();
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (this.prep.locationType !== this.specialPrepLocation.value || !this.prep.quantity) {
      this.prep.location = Array.isArray(this.prep.location)? []: '0"'
      this.framePrepComponent.resetAndFit();
      return;
    }
    for (let i = 0; i < this.quantity; i++) {
      this.prepLocationInputs.push(this.drawPrep(i, this.prep));
    }
  }
}
