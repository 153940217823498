import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { seriesThicknesses } from '../../rules/series';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';
import { DoorThickness } from '../door-thickness';

export function DoorThicknesses(series: DoorSeries, subType: DoorSubType): DoorThickness[] {
  const thicknesses = [...(DoorSeriesInfos[series]?.subTypes[subType]?.thicknesses ?? [])];
  seriesThicknesses(thicknesses, series);
  return thicknesses;
}
