import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IPrepCategory } from '../../core/interfaces/i-prep-category';
import { IProductCategory } from '../../core/interfaces/i-product-category';
import { PrepCategoryService } from '../../core/services/door-prep-category.service';
import { ProductCategoryPrepCategoryService } from '../../core/services/door-product-category-prep-category.service';
import { ProductCategoryService } from '../../core/services/door-product-category.service';

@Component({
  selector: 'lib-generic-hardware-type',
  templateUrl: './generic-hardware-type.component.html',
  styleUrls: ['./generic-hardware-type.component.scss'],
})
export class GenericHardwareTypeComponent implements OnInit, OnDestroy {
  productCategories: IProductCategory[];
  productCategories$ = new BehaviorSubject<IProductCategory[]>([]);
  prepCategories: IPrepCategory[];
  search$ = new Subject<string>();
  destroy$ = new Subject<void>();
  productCategory: IProductCategory;
  dataLoading$ = new BehaviorSubject<boolean>(false);
  loading = false
  constructor(
    public productCategoryService: ProductCategoryService,
    public prepCategoryService: PrepCategoryService,
    public productCategoryPrepCategoryService: ProductCategoryPrepCategoryService
  ) {}

  ngOnInit(): void {
    this.dataLoading$.next(true);
    forkJoin([this.productCategoryService.get(), this.prepCategoryService.get()]).subscribe(result => {
      this.productCategories$.next((this.productCategories = result[0]));
      this.prepCategories = result[1];
      this.dataLoading$.next(false);
    });
    this.search$.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(value => {
      this.productCategories$.next(
        value ? this.productCategories.filter(c => c.displayName.searchFor(value)) : this.productCategories
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
