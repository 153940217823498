import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';

export interface IDoorLockPrep extends IPrep {
  backset: string;
}

export class DoorLockPrep extends Prep implements IDoorLockPrep {
  backset: string;

  properties(): { [key: string]: (value: string) => any } {
    const obj = super.properties();
    obj[nameOf((_: IDoorLockPrep) => _.backset)] = (value: string) => value;
    return obj;
  }
}
