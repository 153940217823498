import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';

export interface IFrameQuantityPrep extends IPrep {
  quantity: number;
}

export class FrameQuantityPrep extends Prep implements IFrameQuantityPrep {
  quantity: number;

  properties(): { [key: string]: (value: string) => any } {
    const obj = super.properties();
    obj[nameOf((_: IFrameQuantityPrep) => _.quantity)] = (value: string) => parseInt(value, 10);
    obj[nameOf((_: IPrep) => _.location)] = (value: string) => value?.split(',') ?? [];
    return obj;
  }
}
