import { UnitOfMeasure } from '../../../core/enums/unit-of-measure'
import { uomSwitch } from '../../../core/functions/uomSwitch'
import { MaxMinDistance } from '../../../core/models/max-min-distance'
import { Cutout } from '../../abstracts/cutout'
import { EmbossedDoorType } from '../../abstracts/embossed-door-type'
import { Panel } from '../../abstracts/panel'
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode'
import { Embossment } from '../../models/cutout-types/embossment'
import { SquarePanel } from '../../models/cutout-types/embossments/square-panel'
import { Lite } from '../../models/cutout-types/lite'
import { distanceFromEdge, Door } from '../../models/door'
import { CutoutExport } from '../../models/exports/cutout-export'

export class E2GType extends EmbossedDoorType {
  cutouts: Cutout[]
  private width = uomSwitch('23"', 'door', this.door.doorElevation.unitOfMeasure)
  private height = uomSwitch('21 9/16"', 'door', this.door.doorElevation.unitOfMeasure)
  private bottomRail = uomSwitch('10"', 'door', this.door.doorElevation.unitOfMeasure)
  private topRail = uomSwitch('6 1/4"', 'door', this.door.doorElevation.unitOfMeasure)

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts)
    if (this.door.height.toDimension('door', UnitOfMeasure.Imperial) === '80"') {
      this.bottomRail = uomSwitch('8"', 'door', this.door.doorElevation.unitOfMeasure)
    }
    const spaceBetween = uomSwitch('6 1/2"', 'door', this.door.doorElevation.unitOfMeasure)

    const liteMinMaxDistance = new MaxMinDistance(
      {
        /* Door Height - distance  */
        fromBottom:
          this.door.actualHeight -
          '6 1/8"'.fromDimension('door', UnitOfMeasure.Imperial) -
          (this.door.actualHeight - this.height - this.bottomRail - this.topRail - spaceBetween),
        fromTop: '63.563"'.fromDimension('door', UnitOfMeasure.Imperial)
      },
      {
        fromTop: '6 1/8"'.fromDimension('door', UnitOfMeasure.Imperial),
        fromBottom: 0
      }
    )

    this.cutouts = [
      new Lite(
        'Lite',
        this,
        (this.door.actualWidth - this.width) / 2,
        this.topRail,
        this.width,
        this.door.actualHeight - this.height - this.bottomRail - this.topRail - spaceBetween,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ).setMinMaxDistance(liteMinMaxDistance),
      this.getEmbossment()
    ]
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]))
    }
  }

  get totalEmbossmentHeight(): number {
    return this.height
  }

  get maxEmbossmentDistances() {
    return {
      fromTop: '63.563"'.fromDimension('door', UnitOfMeasure.Imperial),
      fromBottom: '10 3/8"'.fromDimension('door', UnitOfMeasure.Imperial)
    }
  }

  private getEmbossment(): Cutout {
    return new Embossment(
      'Embossment',
      this,
      (this.door.actualWidth - this.width) / 2,
      this.door.actualHeight - this.height - this.bottomRail,
      this.width,
      this.height,
      VerticalCutoutDimensionMode.Bottom,
      HorizontalCutoutDimensionMode.Lock,
      this.getPanels()
    ).setMinMaxDistance(this.minMaxEmbossmentDimensions)
  }

  private getPanels(): Panel[] {
    const horizontalSpaceBetween = uomSwitch('5"', 'door', this.door.doorElevation.unitOfMeasure)
    const width = uomSwitch('9"', 'door', this.door.doorElevation.unitOfMeasure)
    return [
      new SquarePanel(0, 0, width, this.height),
      new SquarePanel(width + horizontalSpaceBetween, 0, width, this.height)
    ]
  }

  drawDimensions(container: SVGGElement): void {
    /*********** VERTICAL ***************/
    // vertical dimension line
    container.appendChild(this.door.drawLine(this.door.minX, 0, this.door.minX, this.door.height))

    this.cutouts.forEach((cutout, index) => {
      const prevMargin = cutout.y - (index === 0 ? 0 : this.cutouts[index - 1].y + this.cutouts[index - 1].height)
      // top margin
      this.door.drawDimensionLineAndText(
        container,
        prevMargin.toDimension('door', this.door.doorElevation.unitOfMeasure),
        this.door.minX + (distanceFromEdge * 3) / 2,
        index === 0 ? 0 : this.cutouts[index - 1].y + this.cutouts[index - 1].height,
        this.door.minX,
        cutout.y
      )

      this.door.drawEdgeDimensionLines(container, cutout.x, cutout.y, cutout.height)
      // cutout height
      this.door.drawDimensionLineAndText(
        container,
        cutout.height.toDimension('door', this.door.doorElevation.unitOfMeasure),
        this.door.minX + (distanceFromEdge * 3) / 2,
        cutout.y,
        this.door.minX,
        cutout.y + cutout.height
      )
      // last cutout  - bottom margin
      if (index === this.cutouts.length - 1) {
        const y1 = cutout.y + cutout.height
        this.door.drawDimensionLineAndText(
          container,
          (this.door.actualHeight - y1).toDimension('door', this.door.doorElevation.unitOfMeasure),
          this.door.minX + (distanceFromEdge * 3) / 2,
          y1,
          this.door.minX,
          this.door.actualHeight
        )
      }
    })

    /*********** HORIZONTAL ***************/
    // lite horizontal dimension line @ TOP
    container.appendChild(this.door.drawLine(0, this.door.minY, this.door.actualWidth, this.door.minY))
    const lite = this.cutouts.first((x) => x.name === 'Lite')
    // lite left margin
    this.door.drawDimensionLineAndText(
      container,
      lite.x.toDimension('door', this.door.doorElevation.unitOfMeasure),
      0,
      this.door.minY + (distanceFromEdge * 3) / 2,
      lite.x,
      this.door.minY,
      true
    )

    this.door.drawEdgeDimensionLines(container, lite.x, lite.y, lite.width, true)
    // lite width
    this.door.drawDimensionLineAndText(
      container,
      lite.width.toDimension('door', this.door.doorElevation.unitOfMeasure),
      lite.x,
      this.door.minY + (distanceFromEdge * 3) / 2,
      lite.x + lite.width,
      this.door.minY,
      true
    )
    // lite  - right margin
    this.door.drawDimensionLineAndText(
      container,
      (this.door.actualWidth - lite.x - lite.width).toDimension('door', this.door.doorElevation.unitOfMeasure),
      lite.x + lite.width,
      this.door.minY + (distanceFromEdge * 3) / 2,
      this.door.actualWidth,
      this.door.minY,
      true
    )

    /******************** LOUVER horizontal dimension line @ TOP ******************/
    // door bottom left edge
    container.appendChild(
      this.door.drawLine(
        0,
        this.door.actualHeight - distanceFromEdge / 2,
        0,
        this.door.actualHeight - distanceFromEdge * 1.5
      )
    )
    // door bottom right edge
    container.appendChild(
      this.door.drawLine(
        this.door.actualWidth,
        this.door.actualHeight - distanceFromEdge / 2,
        this.door.actualWidth,
        this.door.actualHeight - distanceFromEdge * 1.5
      )
    )
    const louver = this.cutouts.first((x) => x.name === 'Embossment')
    // louver bottom left edge
    container.appendChild(
      this.door.drawLine(louver.x, louver.y + louver.height, louver.x, this.door.height - distanceFromEdge / 2)
    )
    // louver bottom right edge
    container.appendChild(
      this.door.drawLine(
        louver.x + louver.width,
        louver.y + louver.height,
        louver.x + louver.width,
        this.door.height - distanceFromEdge / 2
      )
    )

    this.drawBottomDimensionLine(
      container,
      louver.x.toDimension('door', this.door.doorElevation.unitOfMeasure),
      this.door.height - distanceFromEdge,
      0,
      louver.x
    )
    this.drawBottomDimensionLine(
      container,
      louver.width.toDimension('door', this.door.doorElevation.unitOfMeasure),
      this.door.height - distanceFromEdge,
      louver.x,
      louver.x + louver.width
    )

    this.drawBottomDimensionLine(
      container,
      (this.door.actualWidth - louver.x - louver.width).toDimension('door', this.door.doorElevation.unitOfMeasure),
      this.door.height - distanceFromEdge / 2,
      louver.x + louver.width,
      this.door.actualWidth
    )
  }
}
