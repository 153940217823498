export const alertState = ['error', 'success', 'warning', 'info'] as const

export type AlertState = typeof alertState[number]

export type AlertData = {
  state: AlertState
  title: string
  description?: string
  secondaryAction?: {
    label: string
    action: () => void
  }
}

export const alertPositions = {
  topLeft: {
    verticalPosition: 'top',
    horizontalPosition: 'left'
  },
  topCenter: {
    verticalPosition: 'top',
    horizontalPosition: 'center'
  },
  topRight: {
    verticalPosition: 'top',
    horizontalPosition: 'right'
  },
  bottomLeft: {
    verticalPosition: 'bottom',
    horizontalPosition: 'left'
  },
  bottomCenter: {
    verticalPosition: 'bottom',
    horizontalPosition: 'center'
  },
  bottomRight: {
    verticalPosition: 'bottom',
    horizontalPosition: 'right'
  }
} as const

export type AlertPosition = keyof typeof alertPositions
