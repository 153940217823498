import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { nameOf } from '@oeo/common';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Door } from '../../../models/door';
import { DoorPrepComponent } from '../door-prep/door-prep.component';
import { PrepsComponent } from '../preps.component';
import { StrikePrepComponent } from './strike-prep.component';
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-secondary-strike-prep',
  templateUrl: './strike-prep.component.html',
  styleUrls: ['./strike-prep.component.scss', '../prep-base.component.scss'],
})
export class SecondaryStrikePrepComponent extends StrikePrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent;

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(
      'Secondary Strike Prep',
      prepsComponent,
      'secondaryStrikePrep',
      DoorPrepCategoryIds.SECONDARY_STRIKE
    );
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngAfterViewInit(): void {
    super.afterViewInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}
