import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { IPrepService } from '../interfaces/i-prep.service';
import { PrepCategoryService } from './door-prep-category.service';
import { FramePrepCodeService } from './frame-prep-code.service';

@Injectable()
export class FramePrepService implements IPrepService {

  constructor(private categoryService: PrepCategoryService, private codeService: FramePrepCodeService) {}

  getFormattedPreps() {
    return forkJoin({prepCategories: this.categoryService.get(), prepCodes: this.codeService.search({})}).pipe(
      map(({prepCategories, prepCodes}) => {
        for (const code of prepCodes) {
          code.categories = [...prepCategories].map(c => {
            const category = Object.assign({}, c);
            category.checked = c.framePrepCategoryCodes.any(p => p.prepCodeId === code.id);
            return category;
          });
        }
        return { prepCategories, prepCodes };
      })
    );
  }
}
