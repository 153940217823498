export enum DoorGlassType{
  FL20180 = 'Firelite IGU (20-180 min) 100 squin max 3HR',
  FL2080 = 'Fireline NT (20-80 min) 100 squin max 3HR',
  FLPLUS = 'Firelite Plus (20-180 min) 100 squin max 3HR',
  KFRF = 'Keralite FR-F',
  KFRL = 'Keralite FR-L',
  PSG = 'Protect3 Safety Glass',
  PF = 'Pryan F',
  PL = 'Pryan L',
  PPLUS = 'Pyroshield Plus',
  PP45 = 'Pilkington Pyrostop (45 min)',
  PP60 = 'Pilkington Pyrostop (60 min)',
  PP90 = 'Pilkington Pyrostop (90 min)',
  W45 = 'Wirelite (45 min)',
  WNT = 'Wirelite NT (45-90 min)',
  GG45 = 'Global Glazing 1 5/16” thich (45 min)',
  K = 'Kerashield',
  SXL60 = 'Superlite II XL 60'
}
