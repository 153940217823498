import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FilteredType, Writable } from '@oeo/common';
import { BehaviorSubject, Observable, Subject, debounceTime, map, takeUntil, tap } from 'rxjs';
import { ICustomRuleGroup } from '../../../core/interfaces/i-custom-rule-group';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../../core/interfaces/i-environment';
import { IQueryParams } from '../../../core/interfaces/i-query-params';
import { IElevation } from '../../../core/interfaces/i-elevation';
import { DoorRuleService } from '../../../core/services/door-rule.service';
import { BaseElevationRestService } from '../../../core/services/rest.service';
import { Cutout } from '../../abstracts/cutout';
import { DoorElevation } from '../../models/door-elevation';
import { DoorPosition } from '../door-elevation-config-service/constants';
import { IValidationMessage } from '../../../core/interfaces/i-validation-message';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DoorElevationService extends BaseElevationRestService<IElevation> {
  #doorElevation: DoorElevation
  private doorElevation$ = new Subject<void>()
  currentDoorIndex: DoorPosition = DoorPosition.LEFT

  #rules: ICustomRuleGroup[] = []
  messages: IValidationMessage[]

  set elevation(doorElevation: DoorElevation | null) {
    this.#doorElevation = doorElevation;
    if (this.#doorElevation) {
      this.#doorElevation.update$.pipe(debounceTime(100), takeUntil(this.doorElevation$)).subscribe(() => {
        this.executeRules()
      })
    }
  }

  get elevation(): DoorElevation {
    return this.#doorElevation;
  }

  get currentDoor(){
    return this.elevation?.doors[this.currentDoorIndex]
  }

  constructor(http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) environment: IEnvironment, public doorRuleService: DoorRuleService, private translate: TranslateService) {
    super(http, `${environment.elevations.apiUrl}v1/doorElevations`);
    this.doorRuleService.search({}).subscribe((rules) => this.#rules = rules)
  }

  doorElevations$ = new BehaviorSubject<IElevation[]>([])
  search(query: IQueryParams): Observable<IElevation[]> {
    return super.search(query).pipe(
      map(elevations => elevations.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())),
      tap((elevations) => this.doorElevations$.next(elevations))
    )
  }

  updateCutoutProperty(index: number, property: string, value: string | number){
    const formattedValue = `${value}`.fromDimension('door', this.elevation.unitOfMeasure)
    this.elevation.doors[this.currentDoorIndex].doorType.cutouts[index][property as Writable<Pick<Cutout, FilteredType<Cutout, number>>>] = formattedValue
  }

  executeRules(): void {
    const ruleResults = this.elevation.execRules(this.#rules)
    this.messages = [...ruleResults.messages, ...this.elevation.validatePreps(), ...this.getCutoutValidationMessages()]
  }

  /**
   * Gets the validation messages for the cutouts
   *
   * @returns {IValidationMessage[]} - Returns an array of validation messages for the cutouts
   */
  getCutoutValidationMessages(){
    const isPair = this.elevation.doors.length === 2
    const cutoutValidations: IValidationMessage[] = []
    this.elevation.doors.forEach((door, doorIndex) => {

      const prefix = isPair ? doorIndex === 0 ? 'leftDoor': 'rightDoor' : 'door'

      door.doorType.cutouts.forEach((cutout, ) => {

        const cutoutErrors = Object.entries(cutout.validation.errors ?? {})

        cutoutErrors.forEach(([, cutoutError]) => {
          const verticalDimensionMode = cutout.verticalDimensionMode
          const value = `${
              this.translate.instant(prefix)} ${cutout.type
            } ${
              this.translate.instant(`distFrom${verticalDimensionMode === 'Top' ? 'Top' : 'Bottom'}`)
            } ${
              this.translate.instant(cutoutError.message, {dimension: cutoutError.dimension})
            }`

          cutoutValidations.push({value, level: 3})
        })
      })
    })

    return cutoutValidations
  }

  reset(){
    this.elevation = null
  }
}
