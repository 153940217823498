import { ElementRef, ViewChild } from '@angular/core';
import { omit, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { createSVGElement, setAttributes, setStyles, setWidth, setHeight, setX, setX1, setX2, setY, setY1, setY2 } from '../../../core/helpers/svg-functions';
import { Cutout, lineStyles } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';

const louverStyles = {
  fill: 'transparent',
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '16px',
  transform: 'translate(8,8)',
};

const louverLineStyles = {
  strokeWidth: '4px',
  stroke: 'rgb(127, 127, 127)',
};

export class Louver extends Cutout {
  readonly type = 'Louver';
  sizes: { width: string; height: string }[] = []

  constructor(
    name: string,
    doorType: DoorType,
    x: number,
    y: number,
    width: number,
    height: number,
    verticalDimensionMode: VerticalCutoutDimensionMode,
    horizontalDimensionMode: HorizontalCutoutDimensionMode,
    sizeEditable: boolean = true,
    positionEditable: boolean = true
  ) {
    super(name, doorType, x, y, width, height, verticalDimensionMode, horizontalDimensionMode, sizeEditable, positionEditable);
    this.size = this.sizes[4];
  }

  drawCutout(container: SVGGElement) {
    const lineSpacing = 40;

    const g = pipe(
      setAttributes({transform: `translate(${this.x}, ${this.y})`})
    )(createSVGElement('g'));

    const rect = pipe(
      setX(0),
      setY(0),
      setWidth(this.width - 16),
      setHeight(this.height - 16),
      setAttributes({...pick(louverStyles, 'transform')}),
      setStyles(omit(louverStyles, 'transform')),
    )(createSVGElement('rect'));
    g.appendChild(rect);

    for (let i = 1; i < Math.round((this.height - 8) / lineSpacing); i++) {
      const line = pipe(
        setX1(0),
        setX2(this.width - 8),
        setY1(lineSpacing * i),
        setY2(lineSpacing * i),
        setStyles(louverLineStyles),
      )(createSVGElement('line'));

      g.appendChild(line);
    }
    return container.appendChild(g);
  }
}
