import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IPrep } from '../../../../core/interfaces/i-prep';
import { DoorPrepComponent } from '../door-prep/door-prep.component';
import { PrepsComponent } from '../preps.component';
import { ViewerPrepComponent } from './viewer-prep.component';

@Component({
  selector: 'lib-primary-viewer-prep',
  templateUrl: './viewer-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class SecondaryViewerPrepComponent extends ViewerPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent;

  public get prep(): IPrep {
    return this.door.secondaryViewerPrep;
  }
  public set prep(value: IPrep) {
    this.door.secondaryViewerPrep = value;
    this.prepsComponent.configService.updateCurrentDoor(this.door)
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super('Secondary Viewer', prepsComponent);
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngAfterViewInit(): void {
    super.afterViewInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}
