<lib-dialog
  header="Stick Information"
  [closeable]="true"
  (close)="close()"
  *ngIf="stick"
  libMoveable
  [(top)]="top"
  [(right)]="right"
  [zIndex]="5"
  moveableElementName="mat-card-header"
>
  <div class="content">
    <form [formGroup]="formGroup" autocomplete="off">
      <div class="row input-fields">
        <mat-form-field class="no-hint">
          <mat-label>{{'face' | translate}}</mat-label>
          <input
            matInput
            #faceInput
            [disabled]="face.disabled"
            [formControl]="face"
            (keyup.enter)="applyChange(faceInput, face, $event.target.value)"
            (focusout)="applyChange(faceInput, face, $event.target.value)"
            [ngModel]="face.value"
            libApplyMeasure
            [unitOfMeasure]="stick.unitOfMeasure"
          />
        </mat-form-field>
        <mat-form-field class="no-hint">
          <mat-label>{{'length' | translate}}</mat-label>
          <input
            matInput
            [disabled]="length.disabled"
            [formControl]="length"
            [ngModel]="length.value"
            libApplyMeasure
            [unitOfMeasure]="stick.unitOfMeasure"
          />
        </mat-form-field>
      </div>
    </form>
    <div class="input-fields padding top s3">
      <div>
        <span class="section-header sub">{{'type' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable" [(ngModel)]="stick.type">
          <mat-radio-button *ngFor="let t of stick.types" [value]="t" color="primary">{{ t }}</mat-radio-button>
        </mat-radio-group>
        </div>
      <div>
        <span class="section-header sub">{{'subType' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable" [(ngModel)]="stick.subType">
          <mat-radio-button *ngFor="let t of stick.subTypes" [value]="t" color="primary">{{ t }}</mat-radio-button>
        </mat-radio-group></div>
    </div>
    <div class="input-fields padding top s3">
      <div *ngIf="stick.orientation === orientation.Vertical && stick.jointTypes.length">
        <span class="section-header sub">{{'topJoint' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable" [(ngModel)]="stick.topJointType">
          <mat-radio-button *ngFor="let jointType of stick.jointTypes" [value]="jointType" color="primary">{{
            jointType
          }}</mat-radio-button>
        </mat-radio-group></div>
      <div *ngIf="stick.orientation === orientation.Vertical && stick.jointTypes.length">
        <span class="section-header sub">{{'bottomJoint' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable" [(ngModel)]="stick.bottomJointType">
          <mat-radio-button *ngFor="let jointType of stick.jointTypes" [value]="jointType" color="primary">{{
            jointType
          }}</mat-radio-button>
        </mat-radio-group></div>
      <div *ngIf="stick.orientation === orientation.Horizontal && stick.jointTypes.length">
        <span class="section-header sub">{{'leftJoint' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable" [(ngModel)]="stick.leftJointType">
          <mat-radio-button *ngFor="let jointType of stick.jointTypes" [value]="jointType">{{
            jointType
          }}</mat-radio-button>
        </mat-radio-group></div>
      <div *ngIf="stick.orientation === orientation.Horizontal && stick.jointTypes.length">
        <span class="section-header sub">{{'rightJoint' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable" [(ngModel)]="stick.rightJointType">
          <mat-radio-button *ngFor="let jointType of stick.jointTypes" [value]="jointType" color="primary">{{
            jointType
          }}</mat-radio-button>
        </mat-radio-group></div>
    </div>
    <div class="row">
      <div class="col">
        <span class="section-header sub">{{'rabbets' | translate}}</span>
        <mat-radio-group [disabled]="!stick.isEditable && !stick.isKerf" [(ngModel)]="stick.rabbet">
          <mat-radio-button *ngFor="let r of stick.rabbets" [value]="r" color="primary">{{ r }}</mat-radio-button>
        </mat-radio-group></div>
    </div>
    <div class="row section-header">
      <span>{{'shopBreaks' | translate}}</span>
    </div>
    <div class="shop-breaks">
      <mat-form-field *ngFor="let shopBreak of stick.shopBreaks; let index = index" class="no-hint">
        <input
          matInput
          [ngModel]="shopBreak.toDimension('frame', stick.unitOfMeasure)"
          (keyup.enter)="updateShopBreak($event.target, index)"
          (focusout)="updateShopBreak($event.target, index)"
        />
      </mat-form-field>
      <mat-form-field class="no-hint">
        <input
          matInput
          (keyup.enter)="updateShopBreak($event.target, stick.shopBreaks.length)"
          (focusout)="updateShopBreak($event.target, stick.shopBreaks.length)"
        />
      </mat-form-field>
    </div>
  </div>
</lib-dialog>
