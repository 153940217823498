import { DFType } from './df-type';
import { Door } from '../../models/door';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { DefaultTopRail } from '../../constants/door-constants';
import { Lite } from '../../models/cutout-types/lite';
import { Cutout } from '../../abstracts/cutout';
import { CutoutExport } from '../../models/exports/cutout-export';

export class DGType extends DFType {
  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = this.getWidth();
    const height = this.getHeight();
    const distanceFromTop = DefaultTopRail(this.door.doorElevation.unitOfMeasure);
    this.cutouts = [
      new Lite(
        'Lite',
        this,
        (this.door.actualWidth - width) / 2,
        distanceFromTop,
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private getWidth(): number {
    const widths = new Array(100)
      .fill(
        {
          key: uomSwitch(`2'`, 'door', this.door.doorElevation.unitOfMeasure),
          value: uomSwitch('11 25/32"', 'door', this.door.doorElevation.unitOfMeasure),
        },
        0,
        100
      )
      .map((obj, index) => ({
        key: obj.key + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
        value: obj.value + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
      }));
    for (let i = 1; i < widths.length; i++) {
      if (this.door.width < widths[i].key) {
        return widths[i - 1].value;
      }
    }
    return widths.last().value;
  }

  private getHeight(): number {
    const heights = new Array(100)
      .fill(
        {
          key: uomSwitch(`6' 8"`, 'door', this.door.doorElevation.unitOfMeasure),
          value: uomSwitch('16 11/32"', 'door', this.door.doorElevation.unitOfMeasure),
        },
        0,
        100
      )
      .map((obj, index) => ({
        key: obj.key + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
        value: obj.value + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
      }));
    for (let i = 1; i < heights.length; i++) {
      if (this.door.height < heights[i].key) {
        return heights[i - 1].value;
      }
    }
    return heights.last().value;
  }
}
