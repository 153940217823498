import { Approval } from '../../core/enums/approval';
import { DoorCore } from '../enums/door-core';
import { DoorFireRating } from '../enums/door-fire-rating';
import { DoorMetalMylar } from '../enums/door-metal-mylar';

export function fireRatingApprovals(approvals: Approval[], fireRating: DoorFireRating): void {
  if (fireRating) {
    approvals.remove(Approval.L2BRD);
    approvals.remove(Approval.L3BRD);
    approvals.remove(Approval.L4BRD);
    approvals.remove(Approval.L5BRD);
    approvals.remove(Approval.L6BRD);
    approvals.remove(Approval.L7BRD);
    approvals.remove(Approval.L8BRD);
    approvals.remove(Approval.STC43);
    approvals.remove(Approval.STC47);
    approvals.remove(Approval.STC48);
    approvals.remove(Approval.C_STC50);
    approvals.remove(Approval.STC50);
    approvals.remove(Approval.STC52);
  }
}

export function fireRatingCores(cores: DoorCore[], fireRating: DoorFireRating): void {
  if (fireRating) {
    [...cores].filter(c => c === DoorCore.PU).forEach(c => cores.remove(c));
  }
}

export function fireRatingMetalMylar(metalMylars: DoorMetalMylar[], fireRating: DoorFireRating) {
  if (fireRating) {
    Object.keys(DoorMetalMylar)
      .map(key => DoorMetalMylar[key as keyof typeof DoorMetalMylar])
      .filter(metalMylar => !metalMylars.any(m => metalMylar === m))
      .forEach(metalMylar => metalMylars.push(metalMylar));
  }
  if (
    [DoorFireRating._60, DoorFireRating.WHA, DoorFireRating.WHB, DoorFireRating.WHC, DoorFireRating.WHS].any(
      fr => fr === fireRating
    )
  ) {
    metalMylars.remove(DoorMetalMylar.Mylar);
  }
}
