import { Injectable } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ZipRegex, CanadaZipRegex, PhoneRegex } from '../helper/helper.service'
import { NoCareOfValidator } from '../../shared/directives/no-care-off.validator'
import { ControlsOf } from '@oeo/common'
import { Features, Address } from '../../models'

export type AddressFormType = Omit<Address, 'id' | 'userId' | 'fax'> & { deliveryContact?: any }

@Injectable({
  providedIn: 'root'
})
export class AddressForm {
  constructor(private fb: FormBuilder) {}

  createAddressForm(flags: Features): FormGroup<ControlsOf<AddressFormType>> {
    const zipValidation = flags.country === 'CAN' ? CanadaZipRegex : ZipRegex
    const form = this.fb.group<ControlsOf<AddressFormType>>({
      name: new FormControl<string>(null, [Validators.required, Validators.maxLength(50)]),
      line1: new FormControl<string>(null, [Validators.required, Validators.maxLength(40)]),
      line2: new FormControl<string>(null, [Validators.maxLength(40), NoCareOfValidator()]),
      city: new FormControl<string>(null, [Validators.required, Validators.maxLength(25)]),
      stateOrProvince: new FormControl<string>(null, Validators.required),
      postalCode: new FormControl<string>(null, [Validators.required, Validators.pattern(zipValidation)]), // change based on country
      telephone: new FormControl<string>(null, [Validators.required, this.validatePhoneNumber]),
      country: new FormControl<string>(null, flags.askCountry ? Validators.required : null)
    })
    return form
  }

  validatePhoneNumber(control: FormControl) {
    const phoneRegex = PhoneRegex //formatted 10 digit number
    const numericRegex = /^[0-9]*$/ //only numeric values
    const validPhoneNumber = phoneRegex.test(control.value) || numericRegex.test(control.value)
    return validPhoneNumber
      ? null
      : {
          validatePhoneNumber: {
            valid: false
          }
        }
  }
}
