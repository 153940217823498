import { DoorGlassType } from "../enums/door-glass-type";

export const DefaultGlassThickness: { [key in DoorGlassType]: string } = {
    [DoorGlassType.FL20180]: '1"',
    [DoorGlassType.FL2080]: '3/16"',
    [DoorGlassType.FLPLUS]: '5/16"',
    [DoorGlassType.KFRF]: '3/16"',
    [DoorGlassType.KFRL]: '5/16"',
    [DoorGlassType.PSG]: '1/4"',
    [DoorGlassType.PF]: '3/16"',
    [DoorGlassType.PL]: '3/8"',
    [DoorGlassType.PPLUS]: '7/16"',
    [DoorGlassType.PP45]: '3/4"',
    [DoorGlassType.PP60]: '7/8"',
    [DoorGlassType.PP90]: '1 7/16"',
    [DoorGlassType.W45]: '1/4"',
    [DoorGlassType.WNT]: '1/4"',
    [DoorGlassType.GG45]: '1 5/16"',
    [DoorGlassType.K]: '1/4"',
    [DoorGlassType.SXL60]: '1 3/8"',
}
