import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from "@angular/core"
import { StepperComponent } from "@oeo/common"
import { Subject, takeUntil } from "rxjs"
import { IPrepStep } from "../../../core/interfaces/i-prep-step"
import { IStep } from "../../../core/interfaces/i-step"
import { DialogService } from "../../../core/services/dialog.service"
import { ProductService } from "../../../core/services/door-product.service"
import { DoorPrepComponentType } from "../../../door-elevation/dialogs/preps/preps.component"
import { DoorPrepTypes } from "../../../door-elevation/interfaces/preps"
import { Door } from "../../../door-elevation/models/door"
import { DoorElevationConfigService } from "../../../door-elevation/services/door-elevation-config-service/door-elevation-config.service"
import { FrameElevationPrepComponentType } from "../../../frame-elevation/dialogs/preps/preps.component"
import { FramePrepTypes } from "../../../frame-elevation/interfaces/preps"
import { FrameElevationConfigService } from "../../../frame-elevation/services/frame-elevation-config.service"
import { UnitOfMeasure } from "../../enums/unit-of-measure"

export type PrepStep<DoorOrFramePrepTypes> = {
  active?: boolean
  disabled?: boolean
  prepName: DoorOrFramePrepTypes
} & IPrepStep

type PrepComponentType<DoorOrFrame> = DoorOrFrame extends Door ? DoorPrepComponentType: FrameElevationPrepComponentType

@Component({
  template: ''
})
export abstract class BasePrepsComponent<DoorOrFrame> implements OnInit, AfterViewInit, OnDestroy{
  protected _destroy$ = new Subject<void>()

  @Output() back$: EventEmitter<DoorOrFrame> = new EventEmitter<DoorOrFrame>()
  @Output() next$: EventEmitter<DoorOrFrame> = new EventEmitter<DoorOrFrame>()

  @ViewChild(StepperComponent, { static: true }) stepper: StepperComponent<IStep>
  @ViewChild('PrepComponentEntry', { read: ViewContainerRef }) prepComponentVCR: ViewContainerRef
  currentComponent: PrepComponentType<DoorOrFrame> = null

  #prepSteps: Array<PrepStep<DoorOrFrame extends Door ? DoorPrepTypes: FramePrepTypes>>
  get prepSteps(): Array<PrepStep<DoorOrFrame extends Door ? DoorPrepTypes: FramePrepTypes>>{
    return this.#prepSteps
  }
  set prepSteps(prepSteps: Array<PrepStep<DoorOrFrame extends Door ? DoorPrepTypes: FramePrepTypes>>){
    this.#prepSteps = prepSteps
    prepSteps.filter((step) => step.checked).forEach((step)=> this.modifySteps(step))
  }

  protected steps$: Subject<IStep[]> = new Subject()
  steps: IStep[] = [{ name: 'Select Preps', current: true, completed: false, step: 0, component: null, order: 0 }]
  step = 0

  loading = true;
  init = true;

  abstract get unitOfMeasure(): UnitOfMeasure;
  public abstract configService: DoorOrFrame extends Door ? DoorElevationConfigService : FrameElevationConfigService

  constructor(
    private cd: ChangeDetectorRef,
    public dialogService: DialogService,
    public productService: ProductService,
  ) {}

  next(data?: DoorOrFrame): void {
    if (this.step === this.steps.length - 1) {
      this.next$.emit(data)
      return
    }
    this.stepper.changeStep(this.steps[(this.step += 1)])
  }

  back(data?: DoorOrFrame): void {
    if (this.step === 0) {
      this.back$.emit(data)
      return
    }
    this.stepper.changeStep(this.steps[(this.step -= 1)])
  }

  ngOnInit(): void {
    this.stepper.stepChange$.pipe(takeUntil(this._destroy$)).subscribe((step) => {
      this.step = this.steps.indexOf(step)
      for (const s of this.steps) {
        s.completed = s.current = false
      }
      for (let i = 0; i <= this.step; i++) {
        this.steps[i].current = i === this.step
        this.steps[i].completed = i !== this.step
      }
    })
    this.setUpPrepSteps()
    this.loading = this.init = false
  }

  ngAfterViewInit(): void {
    this.stepper.stepChange$.pipe(takeUntil(this._destroy$)).subscribe((step) => {
      /*
       * Destory all current views in the container and Create the current prep component.
       * The error values are references from the `currentComponent`
       */
      this.prepComponentVCR.clear()
      if (this.step > 0) {
        this.currentComponent = this.prepComponentVCR.createComponent<PrepComponentType<DoorOrFrame>>(
          this.steps[this.step].component
        ).instance
      }
    })
  }
  public abstract updateHingePrepSelection(blankSurfaceHingeChecked: boolean): void;

  modifySteps(step: PrepStep<DoorOrFrame extends Door ? DoorPrepTypes: FramePrepTypes>): void {
    this.steps.removeWhere((s) => s.name === step.name)
    if (step.checked) {
      this.steps.push({
        name: step.name,
        current: false,
        completed: false,
        step: 0,
        order: step.order,
        component: step.component
      })
    } else {
      /* Remove prep */
      this.removePrep(step.prepName)
    }
    this.steps = this.steps.orderBy((x) => x.order)
    this.steps.forEach((x, i) => (x.step = i))
    this.stepper.steps = this.steps
    this.steps$.next(this.steps)
    this.cd.detectChanges()

    if(step.name === 'Blank/Surface Hinge'){
      this.updateHingePrepSelection(step.checked)
    }
  }
  public abstract setUpPrepSteps(): void;
  public abstract removePrep(prepName: DoorOrFrame extends Door ? DoorPrepTypes: FramePrepTypes): void;

  ngOnDestroy(): void {
    this.prepComponentVCR?.clear()
    this._destroy$.next()
    this._destroy$.unsubscribe()
  }
}
