import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { Subject } from 'rxjs'
import { IBaseStep } from '../../interfaces/i-base-step'
import { MatButtonModule } from '@angular/material/button'
import { CommonModule } from '@angular/common'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatTooltipModule, CommonModule, RouterModule]
})
export class StepperComponent<Step extends IBaseStep> implements OnDestroy {
  private destroyed$: Subject<boolean> = new Subject()

  /**
   * A variable that holds the boolean property whether the display is smaller then 550px
   */
  smallDisplayWidth: boolean = false
  /**
   * Whether the stepper is routed. i.e.
   * Clicking on the step no will route the step
   */
  @Input() routed: boolean = false

  /**
   * Output that tracks changes in the step.
   */
  @Output() stepChange$ = new EventEmitter<Step>()

  #step!: Step
  #steps: Step[] = []

  #currentStepNo!: number

  /**
   * The steps that the stepper takes in.
   */
  @Input() set steps(steps: Step[]) {
    this.#steps = steps
    this.#currentStepNo = 1
  }

  get steps() {
    return this.#steps
  }

  constructor(public router: Router) {}

  /**
   * Updates the stepper to set it to the current step.
   * This also updates the step in the stepper service
   * @param step - the step to set
   */
  changeStep(step: Step) {
    this.step = step
  }

  @HostListener('window:resize', ['$event']) onWindowResize() {
    this.smallDisplayWidth = this.isSmall(window.innerWidth)
  }

  private isSmall(width: number): boolean {
    return width > 550 ? false : true
  }

  set step(step: Step) {
    this.#currentStepNo = this.steps.indexOf(step) + 1
    this.#step = step
    this.stepChange$.emit(step)
  }

  /**
   * This sets the current step
   * @param step The step to set
   */
  get step() {
    return this.#step
  }

  get currentStepNo() {
    return this.#currentStepNo
  }

  /**
   * An alias for the get set step(step: Step) method
   */
  setStep(step: Step) {
    this.step = step
  }

  /**
   * Sets the current step on a One-indexed Array
   * @param currentStepNo The step number to set (One Indexed)
   */
  setStepNo(currentStepNo: number) {
    this.#currentStepNo = currentStepNo
    this.#steps = this.steps.map((step: Step, index: number) => ({ ...step, completed: index < currentStepNo - 1 }))
  }

  /**
   * Returns the next step number prefixed with "step-"
   */
  get nextStep() {
    return `step-${this.currentStepNo + 1}`
  }

  /**
   * Returns the previous step number prefixed with "step-"
   */
  get previousStep() {
    return `step-${this.currentStepNo - 1}`
  }

  /**
   * Gets the step at index stepNo-1 or the current Step
   * @param stepNo The step no on a one-indexed scale
   * @returns The step at index (stepNo - 1)
   */
  getStep(stepNo?: number) {
    return this.steps[stepNo ? stepNo - 1 : this.currentStepNo - 1]
  }

  /**
   * Checks whether the step is active, i.e. the current step
   * @param index The index of the step
   * @returns {boolean}
   */
  isActive(index: number): boolean {
    const previousCompleted = index > 0 ? this.steps[index - 1].completed : true
    const selfIncomplete = !this.steps[index].completed
    const nextIncomplete = index + 1 < this.steps.length ? !this.steps[index].completed : true
    return previousCompleted && selfIncomplete && nextIncomplete
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
