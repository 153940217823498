import { Component, OnInit, Input } from '@angular/core'
import { TableService } from '../../table.service'
import { TableColumn, InputEvent } from '../../../../types'

@Component({
  selector: 'oa-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent<T> implements OnInit {
  @Input() data: any
  @Input() row: any
  @Input() column!: TableColumn<T>

  constructor(private tableActionsService: TableService<T>) {}

  ngOnInit(): void {}

  stopPropagation(event: MouseEvent) {
    event.stopPropagation()
  }

  handleChange(val: boolean) {
    const payload: InputEvent<T> = {
      tableName: this.column.tableName ?? '',
      row: this.row,
      column: this.column,
      newVal: val
    }
    this.tableActionsService.inputEvent.next(payload)
  }
}
