import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../services/auth.service'

@Component({ template: '' })
export class SsoComponent {
  constructor(auth: AuthService, private router: Router) {
    auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (!isAuthenticated) auth.sso()
      else this.router.navigateByUrl('landing')
    })
  }
}
