import { HttpClient } from '@angular/common/http'
import { TranslateLoader } from '@ngx-translate/core'
import { merge } from 'lodash'
import { Observable, forkJoin, map } from 'rxjs'

export class OEOTranslateLoader implements TranslateLoader {
  #projects = ['ovt-elevations']

  constructor(private http: HttpClient) {}

  getTranslation(lang: string = 'en'): Observable<Record<string, any>> {
    return forkJoin([
      this.http.get<Record<string, any>>(`assets/i18n/${lang}.json`),
      ...this.#projects.map((projectPath) => this.http.get(`assets/i18n/${projectPath}/${lang}.json`))
    ]).pipe(map(([source, ...translations]) => merge(source, ...translations)))
  }
}
