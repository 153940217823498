import { ComparisonType } from '../enums/comparison-type';
import { ICustomRule } from '../interfaces/i-custom-rule';
import { ICustomRuleEntity } from '../interfaces/i-custom-rule-entity';
import { ICustomRuleGroup } from '../interfaces/i-custom-rule-group';

export class CustomRuleValidator {
  script(ruleGroup: ICustomRuleGroup): string {
    return this.buildRuleScript(ruleGroup.rules);
  }

  private buildRuleScript(rules: ICustomRule[]): string {
    const script = [];
    for (let i = 0; i < rules.length; i++) {
      const rule = rules[i];
      script.push(this.buildTypeScript(rule, rule.prefix));
      if (i !== rules.length - 1) {
        script.push(rule.andOr ? '&&' : '||');
      }
    }
    return `${script
      .filter(x => !!x)
      .map((x, i) => (i % 2 === 1 ? x : `(${x})`))
      .join(' ')}`;
  }

  private buildTypeScript(rule: ICustomRule, prefix: string): string {
    switch (rule.type) {
      case 'string':
        return this.buildStringScript(rule, prefix);
      case 'number':
        return this.buildNumberScript(rule, prefix);
      case 'array':
        return this.buildArrayScript(rule, prefix);
    }
  }

  private buildStringScript(rule: ICustomRule, prefix: string): string {
    const conclusions = rule.conclusion.split('\t');
    if (rule.comparison === ComparisonType.EXISTS) {
      return `!!${prefix}.${rule.property}`;
    }
    if (rule.comparison === ComparisonType.NEXISTS) {
      return `!${prefix}.${rule.property}`;
    }
    return conclusions
      .map(c => `${prefix}.${rule.property} ${this.getComparisonSymbol(rule.comparison)} '${c ?? ''}'`)
      .join(' || ');
  }

  private buildNumberScript(rule: ICustomRule, prefix: string): string {
    const conclusion = `uomSwitch(\`${rule.conclusion}\`, this.uomType, this.unitOfMeasure)`;
    return `${prefix}.${rule.property} ${this.getComparisonSymbol(rule.comparison)} ${conclusion}`;
  }

  private buildArrayScript(rule: ICustomRule, prefix: string): string {
    switch (rule.comparison) {
      case ComparisonType.ANY: {
        return `${rule.subRules
          .map(
            (subRule, i) =>
              `${prefix}.${subRule.prefix}.any(x => ${this.buildTypeScript(subRule, 'x')})${
                i === rule.subRules.length - 1 ? '' : `${subRule.andOr ? ' && ' : ' || '}`
              }`
          )
          .join(' ')}`;
      }
      case ComparisonType.ALL: {
        return `${rule.subRules
          .map(
            (subRule, i) =>
              `${prefix}.${subRule.prefix}.all(x => ${this.buildTypeScript(subRule, 'x')}) ${
                i === rule.subRules.length - 1 ? '' : `${subRule.andOr ? ' && ' : ' || '}`
              }`
          )
          .join(' ')}`;
      }
      case ComparisonType.MAX: {
        return `${rule.subRules
          .map(
            (subRule, i) =>
              `${prefix}.${subRule.prefix}.max(x => x.${subRule.property}) ${this.getComparisonSymbol(
                subRule.comparison
              )} uomSwitch(\`${subRule.conclusion}\`, this.uomType, this.unitOfMeasure) ${
                i === rule.subRules.length - 1 ? '' : `${subRule.andOr ? ' && ' : ' || '}`
              }`
          )
          .join(' ')}`;
      }
      case ComparisonType.SUM: {
        return `${rule.subRules
          .map(
            (subRule, i) =>
              `${prefix}.${subRule.prefix}.sum(x => x.${subRule.property}) ${this.getComparisonSymbol(
                subRule.comparison
              )} uomSwitch(\`${subRule.conclusion}\`, this.uomType, this.unitOfMeasure) ${
                i === rule.subRules.length - 1 ? '' : `${subRule.andOr ? ' && ' : ' || '}`
              }`
          )
          .join(' ')}`;
      }
    }
  }

  private getComparisonSymbol(comparison: ComparisonType): string {
    switch (comparison) {
      case ComparisonType.EQ:
        return `===`;
      case ComparisonType.NEQ:
        return `!==`;
      case ComparisonType.LESS:
        return `<`;
      case ComparisonType.LESSEQ:
        return `<=`;
      case ComparisonType.GREAT:
        return `>`;
      case ComparisonType.GREATEQ:
        return `>=`;
    }
  }
}
