import { Injectable, OnDestroy } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '../../core/services/dialog.service';

export abstract class IntersectableKeyboardEvent extends KeyboardEvent {
  none: boolean;
}

export enum IntersectableKeyboardEventModifier {
  None = 'none',
  CtrlKey = 'ctrlKey',
  ShiftKey = 'shiftKey',
  AltKey = 'altKey',
}

const ignoreElements = [HTMLInputElement, HTMLSelectElement, HTMLButtonElement];

const intersectableKeyboardEventModifiers: IntersectableKeyboardEventModifier[] = [
  IntersectableKeyboardEventModifier.None,
  IntersectableKeyboardEventModifier.CtrlKey,
  IntersectableKeyboardEventModifier.ShiftKey,
  IntersectableKeyboardEventModifier.AltKey,
];

@Injectable()
export abstract class BaseIntersectableService implements OnDestroy {
  constructor(private dialogService: DialogService) {}

  protected _destroy$ = new Subject<void>();

  registerHotkey(event: string, code: string, modifier: IntersectableKeyboardEventModifier, func: () => void) {
    fromEvent(window, event)
      .pipe(
        takeUntil(this._destroy$),
        map((e: IntersectableKeyboardEvent) => {
          if (this.dialogService.isOpen) {
            return null;
          }
          if (ignoreElements.any(i => document.activeElement instanceof i)) {
            return null;
          }
          e.none = modifier === IntersectableKeyboardEventModifier.None;
          if (
            e.code === code &&
            e[modifier] &&
            intersectableKeyboardEventModifiers.filter(m => m !== modifier).every(m => !e[m])
          ) {
            e.preventDefault();
            return func;
          }
          return null;
        }),
        debounceTime(10)
      )
      .subscribe((action: () => void) => {
        if (action) {
          action();
        }
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}
