import { takeUntil } from 'rxjs/operators';
import { CoreConstants } from '../../core/core.constants';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { CSSPropKey, Mutable, getEnumValues, guid } from '@oeo/common';
import { nameOf } from '@oeo/common';
import { uomSwitch } from '../../core/functions/uomSwitch';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { Intersectable } from '../abstracts/intersectable';
import { ProfileTemplate } from '../abstracts/profile-template';
import { Direction } from '../enums/direction';
import { FrameSeriesInfos } from '../enums/frame-series';
import { JointType } from '../enums/joint-type';
import { Orientation } from '../enums/orientation';
import { Rabbet } from '../enums/rabbet';
import { StickSubtype } from '../enums/stick-subtype';
import { StickType } from '../enums/stick-type';
import { StickExport } from './exports/stick-export';
import { FrameElevation } from './frame-elevation';
import { Glass } from './glass';
import { StickValidator } from './handlers/stick-validator';
import { Jamb } from './jamb';
import { PointAnchor } from './point-anchor';

const stickClass = 'stick';
const styles: Record<string, string> = {
  fill: '#a6a6a6',
  stroke: 'black',
};

export class Stick extends Intersectable {
  static intersectableName: string = 'Stick';

  private _screenPosition: Partial<DOMPoint>;
  private _face: number;
  private _orientation: Orientation;
  private _topJointType = JointType.Notched;
  private _bottomJointType = JointType.Notched;
  private _leftJointType = JointType.Notched;
  private _rightJointType = JointType.Notched;
  private _type = StickType.OpenSection;
  private _jambs: Jamb[] = [];
  private _sectionSize = 8;
  private _flipped = false;
  private _subType = StickSubtype.Blank;
  private readonly _types = [
    {
      key: StickType.OpenSection,
      values: [
        StickSubtype.Cased,
        StickSubtype.Blank,
        StickSubtype.Strike,
        StickSubtype.Hinge,
        StickSubtype.Sill,
        StickSubtype.Head,
      ],
    },
    {
      key: StickType.ClosedSection,
      values: [
        StickSubtype.Cased,
        StickSubtype.Blank,
        StickSubtype.Strike,
        StickSubtype.Hinge,
        StickSubtype.StrikeStrike,
        StickSubtype.HingeHinge,
        StickSubtype.StrikeHinge,
        StickSubtype.Head,
      ],
    },
    {
      key: StickType.PartialMullion,
      values: [StickSubtype.Cased, StickSubtype.Blank, StickSubtype.Strike, StickSubtype.Hinge],
    },
  ];
  private readonly _validator = new StickValidator(this);
  private _shopBreaks: number[] = [];
  private _rabbet: Rabbet;
  private _profiles: ProfileTemplate[] = [];
  private _profileDimensions: { [key: string]: number };
  private _partialNotchDimension: number;
  private _afterMoveUpIntersections: Intersectable[];
  private _afterMoveDownIntersections: Intersectable[];
  private _afterMoveLeftIntersections: Intersectable[];
  private _afterMoveRightIntersections: Intersectable[];

  get partialNotchDimension(): number {
    return this._partialNotchDimension;
  }

  public get isHingeJamb(): boolean {
    return (
      this.subType === StickSubtype.Hinge ||
      this.subType === StickSubtype.StrikeHinge ||
      this.subType === StickSubtype.HingeHinge
    );
  }

  public get isStrikeJamb(): boolean {
    return (
      this.subType === StickSubtype.Strike ||
      this.subType === StickSubtype.StrikeHinge ||
      this.subType === StickSubtype.StrikeStrike
    );
  }

  public get isHead(): boolean {
    return this.subType === StickSubtype.Head;
  }

  get isKerf() {
    return this.frameElevation.kerf;
  }

  get profileDimensions() {
    return this._profileDimensions;
  }

  set profileDimensions(value: { [key: string]: number }) {
    this._profileDimensions = value;
  }

  get profiles() {
    return this._profiles;
  }

  get rabbets() {
    if (this.frameElevation.frameSeriesInfo.doubleEgress) {
      return [Rabbet.DoubleEgress];
    }
    if (this.subType === StickSubtype.Cased) {
      return [Rabbet.Cased];
    }
    return [
      this.frameElevation.frameSeriesInfo.doubleRabbet ? Rabbet.Double : null,
      this.frameElevation.frameSeriesInfo.singleRabbet ? Rabbet.Single : null,
    ].filter(_ => !!_);
  }

  get rabbet() {
    return this._rabbet;
  }

  set rabbet(value: Rabbet) {
    this._rabbet = value;
    this.profileDimensions = null;
    this._afterUpdate$.next();
  }

  get jointTypes() {
    return getEnumValues(JointType)
      .filter(jt => {
        if (
          (this.face !== this.get2InchMiterFace() || this.orientation !== Orientation.Horizontal) &&
          jt === JointType._2InchMiter
        ) {
          return false;
        }
        if (
          (this.type === StickType.PartialMullion || this.type === StickType.ClosedSection) &&
          jt === JointType.Mitered
        ) {
          return false;
        }
        return true;
      });
  }

  get types() {
    return this._types
      .map(st => st.key)
      .filter(st => {
        if (
          (this.isMitered || this.is2InchMitered) &&
          (st === StickType.PartialMullion || st === StickType.ClosedSection)
        ) {
          return false;
        }
        if (this.orientation !== Orientation.Vertical && st === StickType.PartialMullion) {
          return false;
        }
        return true;
      });
  }

  get type() {
    return this._type;
  }

  set type(value: StickType) {
    this._type = value;
    if (!this._types.find(t => t.key === value).values.any(st => st === this.subType)) {
      this.subType = this._types.find(t => t.key === value).values[0];
    }

    if (this.orientation === Orientation.Horizontal) {
      if (!this.jointTypes.some(j => j === this.leftJointType)) {
        this.leftJointType = this.jointTypes[0];
      }
      if (!this.jointTypes.some(j => j === this.rightJointType)) {
        this.rightJointType = this.jointTypes[0];
      }
    }
    if (this.orientation === Orientation.Vertical) {
      if (!this.jointTypes.some(j => j === this.topJointType)) {
        this.topJointType = this.jointTypes[0];
      }
      if (!this.jointTypes.some(j => j === this.bottomJointType)) {
        this.bottomJointType = this.jointTypes[0];
      }
    }
    this._afterUpdate$.next();
  }

  get subTypes() {
    return this._types
      .find(t => t.key === this.type)
      .values.filter(type => {
        if (
          this.orientation === Orientation.Horizontal &&
          (type === StickSubtype.Hinge ||
            type === StickSubtype.Strike ||
            type === StickSubtype.StrikeHinge ||
            type === StickSubtype.HingeHinge ||
            type === StickSubtype.StrikeStrike)
        ) {
          return false;
        }
        if (this.orientation === Orientation.Vertical && (type === StickSubtype.Sill || type === StickSubtype.Head)) {
          return false;
        }
        return true;
      });
  }

  get subType() {
    return this._subType;
  }

  set subType(value: StickSubtype) {
    this._subType = value;
    if (this.subType === StickSubtype.Cased) {
      this.rabbet = Rabbet.Cased;
    }
    if (this.subType !== StickSubtype.Cased) {
      this.rabbet = Rabbet.Double;
    }
    this._afterUpdate$.next();
  }

  get isMitered() {
    return (
      this.leftJointType === JointType.Mitered ||
      this.rightJointType === JointType.Mitered ||
      this.topJointType === JointType.Mitered ||
      this.bottomJointType === JointType.Mitered
    );
  }

  get is2InchMitered() {
    return (
      this.leftJointType === JointType._2InchMiter ||
      this.rightJointType === JointType._2InchMiter ||
      this.topJointType === JointType._2InchMiter ||
      this.bottomJointType === JointType._2InchMiter
    );
  }

  get topJointType() {
    return this._topJointType;
  }

  set topJointType(value: JointType) {
    if (value === JointType._2InchMiter) {
      throw new Error(`${JointType._2InchMiter} is not valid as a top joint type`);
    }
    this._topJointType = value;
    switch (value) {
      case JointType.Mitered: {
        this.miterTop();
        break;
      }
      case JointType.Notched:
      case JointType.Square: {
        this.notchOrSquareTop();
        break;
      }
    }
    this._afterUpdate$.next();
  }

  get bottomJointType() {
    return this._bottomJointType;
  }

  set bottomJointType(value: JointType) {
    if (value === JointType._2InchMiter) {
      throw new Error(`${JointType._2InchMiter} is not valid as a bottom joint type`);
    }
    this._bottomJointType = value;
    switch (value) {
      case JointType.Mitered: {
        this.miterBottom();
        break;
      }
      case JointType.Notched:
      case JointType.Square: {
        this.notchOrSquareBottom();
        break;
      }
    }
    this._afterUpdate$.next();
  }

  get leftJointType() {
    return this._leftJointType;
  }

  set leftJointType(value: JointType) {
    const leftPoints = this.leftPoints;
    while (leftPoints.length !== 2) {
      const index = this.points.location(pt => pt.x === leftPoints[1].x && pt.y === leftPoints[1].y);
      leftPoints.removeAt(1);
      this.element.points.removeItem(index);
    }
    this._leftJointType = value;
    switch (this._leftJointType) {
      case JointType.Mitered: {
        this.miterLeft();
        break;
      }
      case JointType.Notched:
      case JointType.Square: {
        this.notchOrSquareLeft();
        break;
      }
      case JointType._2InchMiter: {
        this.miter2InchLeft();
        break;
      }
    }
    this._afterUpdate$.next();
  }

  get rightJointType() {
    return this._rightJointType;
  }

  set rightJointType(value: JointType) {
    const rightPoints = this.rightPoints;
    while (rightPoints.length !== 2) {
      const index = this.points.location(pt => pt.x === rightPoints[1].x && pt.y === rightPoints[1].y);
      rightPoints.removeAt(1);
      this.element.points.removeItem(index);
    }
    this._rightJointType = value;
    switch (value) {
      case JointType.Mitered: {
        this.miterRight();
        break;
      }
      case JointType.Notched:
      case JointType.Square: {
        this.notchOrSquareRight();
        break;
      }
      case JointType._2InchMiter: {
        this.miter2InchRight();
        break;
      }
    }
    this._afterUpdate$.next();
  }

  get orientation(): Orientation {
    return this._orientation;
  }

  get length() {
    return this.orientation === Orientation.Vertical ? this.height : this.width;
  }

  get imperialLength() {
    return this.length.toDimension('frame', this.unitOfMeasure);
  }

  set face(value: number) {
    if (value <= 0 || this.is2InchMitered || this.frameElevation.frameSeriesInfo.fixedFace) {
      return;
    }

    const diff = value - this._face;
    let movement = 0;
    for (let i = 0; i < Math.abs(diff); i++) {
      let didMove = false;
      switch (this.orientation) {
        case Orientation.Vertical: {
          if (diff < 0) {
            if (this.flipped) {
              didMove = this.moveRight({ points: this.leftPoints, ignoreIntersection: true, afterUpdate: false });
            } else {
              didMove = this.moveLeft({ points: this.rightPoints, ignoreIntersection: true, afterUpdate: false });
            }
          } else {
            if (this.flipped) {
              didMove = this.moveRight({
                points: this.rightPoints,
                ignoreIntersection: false,
                afterUpdate: false,
                sticky: true,
              });
            } else {
              didMove = this.moveLeft({
                points: this.leftPoints,
                ignoreIntersection: false,
                afterUpdate: false,
                sticky: true,
              });
            }
          }
          break;
        }
        case Orientation.Horizontal: {
          if (diff < 0) {
            if (this.flipped) {
              didMove = this.moveUp({ points: this.bottomPoints, ignoreIntersection: true, afterUpdate: false });
            } else {
              didMove = this.moveDown({ points: this.topPoints, ignoreIntersection: true, afterUpdate: false });
            }
          } else {
            if (this.flipped) {
              didMove = this.moveDown({
                points: this.bottomPoints,
                ignoreIntersection: false,
                afterUpdate: false,
                sticky: true,
              });
            } else {
              didMove = this.moveUp({
                points: this.topPoints,
                ignoreIntersection: false,
                afterUpdate: false,
                sticky: true,
              });
            }
          }
          break;
        }
      }
      if (!didMove) {
        break;
      }
      movement += diff < 0 ? -1 : 1;
    }
    this._face += movement;
    [
      nameOf((_: Stick) => _.topJointType),
      nameOf((_: Stick) => _.bottomJointType),
      nameOf((_: Stick) => _.leftJointType),
      nameOf((_: Stick) => _.rightJointType),
    ].forEach(key => {
      if (this[key] === JointType._2InchMiter || this[key] === JointType.Mitered) {
        this[key] = this[key];
      }
    });
    this._afterUpdate$.next();
  }

  get face() {
    return this._face;
  }

  get imperialFace() {
    return this.face.toDimension('frame', this.unitOfMeasure);
  }

  get flipped() {
    return this._flipped;
  }

  get warnings() {
    return this._validator.warnings();
  }

  get errors() {
    return this._validator.errors();
  }

  get shopBreaks() {
    return this._shopBreaks as ReadonlyArray<number>;
  }

  get topPoints() {
    return this.points
      .sort((a, b) => (a.y < b.y ? -1 : 1))
      .slice(0, 2)
      .sort((a, b) => (a.x < b.x ? -1 : 1));
  }

  get bottomPoints() {
    return this.points
      .sort((a, b) => (a.y < b.y ? 1 : -1))
      .slice(0, 2)
      .sort((a, b) => (a.x < b.x ? -1 : 1));
  }

  get rightPoints() {
    return this.points
      .sort((a, b) => (a.x < b.x ? 1 : -1))
      .slice(0, this.rightJointType === JointType._2InchMiter && this.orientation === Orientation.Horizontal ? 3 : 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
  }

  get leftPoints() {
    return this.points
      .sort((a, b) => (a.x < b.x ? -1 : 1))
      .slice(0, this.leftJointType === JointType._2InchMiter && this.orientation === Orientation.Horizontal ? 3 : 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
  }

  static fromXML(value: string): StickExport {
    const doc = parseFromXMLString(value);
    return {
      id: guid(),
      exportType: Stick.intersectableName,
      orientation: Intersectable.parseXML(
        doc,
        nameOf((_: Stick) => _.orientation)
      ) as Orientation,
      topJointType: Intersectable.parseXML(doc, 'TopCornerCondition') as JointType,
      bottomJointType: Intersectable.parseXML(doc, 'BottomCornerCondition') as JointType,
      leftJointType: Intersectable.parseXML(doc, 'LeftCornerCondition') as JointType,
      rightJointType: Intersectable.parseXML(doc, 'RightCornerCondition') as JointType,
      type: Intersectable.parseXML(doc, 'Type') as StickType,
      subType: Intersectable.parseXML(doc, 'SubType') as StickSubtype,
      flipped: Intersectable.parseXML(doc, 'Flipped')?.toLowerCase() === 'true',
      rabbet: Intersectable.parseXML(doc, 'Rabbet') as Rabbet,
      shopBreaks: JSON.parse(Intersectable.parseXML(doc, 'ShopBreaks')),
      profileDimensions: JSON.parse(Intersectable.parseXML(doc, 'ProfileDimensions')),
      points: JSON.parse(
        Intersectable.parseXML(
          doc,
          nameOf((_: Stick) => _.points)
        )
      ),
    };
  }

  constructor(
    frameElevation: FrameElevation,
    container: SVGGElement,
    x: number,
    y: number,
    data?: StickExport,
    editable: boolean = true
  ) {
    super(frameElevation, container, stickClass, editable);
    this._rabbet = this.rabbets.first();
    for (const key in styles) {
      this.element.style[key as CSSPropKey] = styles[key]
    }
    this._face = frameElevation.frameSeriesInfo.defaultFace[this.unitOfMeasure];
    this._screenPosition = { x, y };

    if (data == null) {
      for (let i = 0; i < 4; i++) {
        this.element.points.appendItem(this.svg.transformPoint(x, y));
      }
    } else {
      for (const point of data.points) {
        this.element.points.appendItem(this.svg.getPoint(point.x, point.y));
      }
      this._orientation = data.orientation;
      this._topJointType = data.topJointType;
      this._bottomJointType = data.bottomJointType;
      this._leftJointType = data.leftJointType;
      this._rightJointType = data.rightJointType;
      this._type = data.type;
      this._subType = data.subType;
      this._flipped = data.flipped;
      this._shopBreaks = data.shopBreaks ?? [];
      this._face =
        this.orientation === Orientation.Vertical
          ? this.rightPoints.max(_ => _.x) - this.leftPoints.min(_ => _.x)
          : this.bottomPoints.max(_ => _.y) - this.topPoints.min(_ => _.y);
      if (data.rabbet) {
        this._rabbet = data.rabbet;
      }
      this._profileDimensions = data.profileDimensions;
    }
    this.afterUpdate$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.drawJambs();
    });
  }

  static fromJSON(
    frameElevation: FrameElevation,
    container: SVGGElement,
    data: StickExport,
    editable: boolean = true
  ): Stick {
    return new Stick(frameElevation, container, 0, 0, data, editable);
  }

  addShopBreak(value?: number) {
    if (isNaN(value) || value > this.length) {
      return false;
    }
    this._shopBreaks.push(
      value ??
        (this.unitOfMeasure === UnitOfMeasure.Imperial ? '12"' : '305mm').fromDimension('frame', this.unitOfMeasure)
    );
    this._afterUpdate$.next();
    return true;
  }

  updateShopBreak(value: number, index: number): boolean {
    if (isNaN(value) || value > this.length) {
      return false;
    }
    this._shopBreaks[index] = value;
    this._afterUpdate$.next();
  }

  deleteShopBreak(index: number) {
    this._shopBreaks.removeAt(index);
    this._afterUpdate$.next();
  }

  resize(x: number, y: number, dx: number, dy: number) {
    const direction = this.orientation;
    this.setDirection(x, y);
    if (this.orientation === Orientation.Horizontal) {
      this.resizeHorizontal(this.orientation !== direction, dx < 0 ? Direction.Left : Direction.Right, dx);
    } else {
      this.resizeVertical(this.orientation !== direction, dy < 0 ? Direction.Up : Direction.Down, dy);
    }
  }

  isValid() {
    const minLength = uomSwitch('6"', 'frame', this.unitOfMeasure);
    if (this.length < minLength) {
      return false;
    }
    return true;
  }

  destroy() {
    this.profiles.forEach(p => p.destroy());
    this.destroyJambs();
    this.element.remove();
    this.element = null;
    this.container = null;
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  flip() {
    this._flipped = !this._flipped;
    switch (this.orientation) {
      case Orientation.Horizontal:
        return this.flipHorizontal();
      case Orientation.Vertical:
        return this.flipVertical();
    }
  }

  flipOverCenter() {
    super.flipOverCenter();
    if (this.orientation === Orientation.Vertical) {
      this._flipped = !this._flipped;
    } else {
      const leftJointType = this.leftJointType;
      this._leftJointType = this.rightJointType;
      this._rightJointType = leftJointType;
    }
    this.drawJambs();
    this.setProfiles();
  }

  center() {
    switch (this.orientation) {
      case Orientation.Horizontal:
        this.centerVertical();
        break;
      case Orientation.Vertical:
        this.centerHorizontal();
        break;
    }
    this._afterUpdate$.next();
  }

  setProfiles(): ProfileTemplate[] {
    switch (this.orientation) {
      case Orientation.Vertical:
        return (this._profiles = this.verticalProfiles());
      case Orientation.Horizontal:
        return (this._profiles = this.horizontalProfiles());
    }
  }

  private verticalProfiles(): ProfileTemplate[] {
    const leftIntersections = this.leftIntersections;
    const rightIntersections = this.rightIntersections;
    const groups = [...leftIntersections, ...rightIntersections]
      .orderBy(x => x.topPoints.first().y)
      .groupBy(_ => `${_.leftPoints.first().y} ${_.leftPoints.last().y}`);
    const intersections = Array.from(groups.keys()).map(key => groups.get(key).first());
    const profileTemplates: ProfileTemplate[] = [];
    const info = FrameSeriesInfos[this.frameElevation.series];
    const sticks: Stick[] = intersections.filter(i => i instanceof Stick) as Stick[];
    if (!sticks.any()) {
      const topPoint = this.topPoints.orderBy(_ => _.y).last().y;
      const bottomPoint = this.bottomPoints.orderBy(_ => _.y).first().y;
      profileTemplates.push(
        new info.type(this, [
          { x: this.topPoints.first().x, y: topPoint },
          { x: this.topPoints.last().x, y: topPoint },
          { x: this.topPoints.last().x, y: bottomPoint },
          { x: this.topPoints.first().x, y: bottomPoint },
        ]).template(this.verticalGlazingBeads(leftIntersections, rightIntersections, topPoint, bottomPoint))
      );
    }
    sticks.forEach((stick, i) => {
      if (i === 0 && ![...stick.leftPoints, ...stick.rightPoints].any(pt => this.topPoints.any(tp => tp.y === pt.y))) {
        const topPoint = this.topPoints.orderBy(_ => _.y).last().y;
        const bottomPoint = stick.topPoints.orderBy(_ => _.y).first().y;
        profileTemplates.push(
          new info.type(this, [
            { x: this.topPoints.first().x, y: topPoint },
            { x: this.topPoints.last().x, y: topPoint },
            { x: this.topPoints.last().x, y: bottomPoint },
            { x: this.topPoints.first().x, y: bottomPoint },
          ]).template(this.verticalGlazingBeads(leftIntersections, rightIntersections, topPoint, bottomPoint))
        );
      }
      const nextStick = sticks[sticks.indexOf(stick) + 1];
      if (nextStick) {
        const topPoint = stick.bottomPoints.orderBy(_ => _.y).last().y;
        const bottomPoint = nextStick.topPoints.orderBy(_ => _.y).first().y;
        profileTemplates.push(
          new info.type(this, [
            { x: this.topPoints.first().x, y: topPoint },
            { x: this.topPoints.last().x, y: topPoint },
            { x: this.topPoints.last().x, y: bottomPoint },
            { x: this.topPoints.first().x, y: bottomPoint },
          ]).template(this.verticalGlazingBeads(leftIntersections, rightIntersections, topPoint, bottomPoint))
        );
      }
      if (
        i === sticks.length - 1 &&
        ![...stick.leftPoints, ...stick.rightPoints].any(pt => this.bottomPoints.any(tp => tp.y === pt.y))
      ) {
        const topPoint = stick.bottomPoints.orderBy(_ => _.y).last().y;
        const bottomPoint = this.bottomPoints.orderBy(_ => _.y).first().y;
        profileTemplates.push(
          new info.type(this, [
            { x: this.topPoints.first().x, y: topPoint },
            { x: this.topPoints.last().x, y: topPoint },
            { x: this.topPoints.last().x, y: bottomPoint },
            { x: this.topPoints.first().x, y: bottomPoint },
          ]).template(this.verticalGlazingBeads(leftIntersections, rightIntersections, topPoint, bottomPoint))
        );
      }
    });
    return profileTemplates;
  }

  private verticalGlazingBeads(
    leftIntersections: Intersectable[],
    rightIntersections: Intersectable[],
    topPoint: number,
    bottomPoint: number
  ) {
    return [
      leftIntersections
        .filter(left => left instanceof Glass)
        .map(left => left as Glass)
        .first(
          glass =>
            topPoint >= glass.topPoints.orderBy(_ => _.y).last().y &&
            bottomPoint <= glass.bottomPoints.orderBy(_ => _.y).first().y
        )
        ?.toJSON(),
      rightIntersections
        .filter(left => left instanceof Glass)
        .map(left => left as Glass)
        .first(
          glass =>
            topPoint >= glass.topPoints.orderBy(_ => _.y).last().y &&
            bottomPoint <= glass.bottomPoints.orderBy(_ => _.y).first().y
        )
        ?.toJSON(),
    ].filter(_ => !!_);
  }

  private horizontalProfiles(): ProfileTemplate[] {
    const topIntersections = this.topIntersections;
    const bottomIntersections = this.bottomIntersections;
    const groups = [...topIntersections, ...bottomIntersections]
      .orderBy(x => x.leftPoints.first().x)
      .groupBy(_ => `${_.topPoints.first().x} ${_.topPoints.last().x}`);
    const intersections = Array.from(groups.keys()).map(key => groups.get(key).first());
    const profileTemplates: ProfileTemplate[] = [];
    const info = FrameSeriesInfos[this.frameElevation.series];
    const sticks: Stick[] = intersections.filter(i => i instanceof Stick) as Stick[];
    if (!sticks.any()) {
      const leftPoint = this.leftPoints.orderBy(_ => _.x).last().x;
      const rightPoint = this.rightPoints.orderBy(_ => _.x).first().x;
      profileTemplates.push(
        new info.type(this, [
          { x: leftPoint, y: this.leftPoints.first().y },
          { x: leftPoint, y: this.leftPoints.last().y },
          { x: rightPoint, y: this.leftPoints.last().y },
          { x: rightPoint, y: this.leftPoints.first().y },
        ]).template(this.horizontalGlazingBeads(topIntersections, bottomIntersections, leftPoint, rightPoint))
      );
    }
    sticks.forEach((stick, i) => {
      if (i === 0 && ![...stick.leftPoints, ...stick.rightPoints].any(pt => this.topPoints.any(tp => tp.x === pt.x))) {
        const leftPoint = this.leftPoints.orderBy(_ => _.x).last().x;
        const rightPoint = stick.leftPoints.orderBy(_ => _.x).first().x;
        profileTemplates.push(
          new info.type(this, [
            { x: leftPoint, y: this.leftPoints.first().y },
            { x: leftPoint, y: this.leftPoints.last().y },
            { x: rightPoint, y: this.leftPoints.last().y },
            { x: rightPoint, y: this.leftPoints.first().y },
          ]).template(this.horizontalGlazingBeads(topIntersections, bottomIntersections, leftPoint, rightPoint))
        );
      }
      const nextStick = sticks[sticks.indexOf(stick) + 1];
      if (nextStick) {
        const leftPoint = stick.rightPoints.orderBy(_ => _.x).last().x;
        const rightPoint = nextStick.leftPoints.orderBy(_ => _.x).first().x;
        profileTemplates.push(
          new info.type(this, [
            { x: leftPoint, y: this.leftPoints.first().y },
            { x: leftPoint, y: this.leftPoints.last().y },
            { x: rightPoint, y: this.leftPoints.last().y },
            { x: rightPoint, y: this.leftPoints.first().y },
          ]).template(this.horizontalGlazingBeads(topIntersections, bottomIntersections, leftPoint, rightPoint))
        );
      }
      if (
        i === sticks.length - 1 &&
        ![...stick.leftPoints, ...stick.rightPoints].any(pt => this.bottomPoints.any(tp => tp.y === pt.y))
      ) {
        const leftPoint = stick.rightPoints.orderBy(_ => _.x).last().x;
        const rightPoint = this.rightPoints.orderBy(_ => _.x).first().x;
        profileTemplates.push(
          new info.type(this, [
            { x: leftPoint, y: this.leftPoints.first().y },
            { x: leftPoint, y: this.leftPoints.last().y },
            { x: rightPoint, y: this.leftPoints.last().y },
            { x: rightPoint, y: this.leftPoints.first().y },
          ]).template(this.horizontalGlazingBeads(topIntersections, bottomIntersections, leftPoint, rightPoint))
        );
      }
    });
    return profileTemplates;
  }

  private horizontalGlazingBeads(
    topIntersections: Intersectable[],
    bottomIntersections: Intersectable[],
    leftPoint: number,
    rightPoint: number
  ) {
    return [
      topIntersections
        .filter(top => top instanceof Glass)
        .map(top => top as Glass)
        .first(
          glass =>
            leftPoint >= glass.leftPoints.orderBy(_ => _.x).last().x &&
            rightPoint <= glass.rightPoints.orderBy(_ => _.x).first().x
        )
        ?.toJSON(),
      bottomIntersections
        .filter(left => left instanceof Glass)
        .map(left => left as Glass)
        .first(
          glass =>
            leftPoint >= glass.leftPoints.orderBy(_ => _.x).last().x &&
            rightPoint <= glass.rightPoints.orderBy(_ => _.x).first().x
        )
        ?.toJSON(),
    ].filter(_ => !!_);
  }

  protected beforeMoveUp(points: Partial<DOMPoint>[], sticky: boolean) {
    if (sticky) {
      if (this.orientation === Orientation.Vertical) {
        return;
      }
      const intersections = this.topIntersections;
      this._afterMoveUpIntersections = this.getIntersectables().filter(i =>
        this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x, y: pt.y + 1 }, Direction.Down))
      );
      if (
        !intersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Vertical) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      intersections.forEach(e => e.shrinkUp(false));
    }
  }

  protected beforeMoveDown(points: Partial<DOMPoint>[], sticky: boolean) {
    if (sticky) {
      if (this.orientation === Orientation.Vertical) {
        return;
      }
      const intersections = this.bottomIntersections;
      this._afterMoveDownIntersections = this.getIntersectables().filter(i =>
        this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x, y: pt.y - 1 }, Direction.Up))
      );
      if (
        !intersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Vertical) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      intersections.forEach(e => e.shrinkDown(false));
    }
  }

  protected beforeMoveRight(points: Partial<DOMPoint>[], sticky: boolean) {
    if (sticky) {
      if (this.orientation === Orientation.Horizontal) {
        return;
      }
      const intersections = this.rightIntersections;
      this._afterMoveRightIntersections = this.getIntersectables().filter(i =>
        this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x - 1, y: pt.y }, Direction.Left))
      );
      if (
        !intersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Horizontal) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      intersections.forEach(e => e.shrinkRight(false));
    }
  }

  protected beforeMoveLeft(points: Partial<DOMPoint>[], sticky: boolean) {
    if (sticky) {
      if (this.orientation === Orientation.Horizontal) {
        return;
      }
      const intersections = this.leftIntersections;
      this._afterMoveLeftIntersections = this.getIntersectables().filter(i =>
        this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x + 1, y: pt.y }, Direction.Right))
      );

      if (
        !intersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Horizontal) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      intersections.forEach(e => e.shrinkLeft(false));
    }
  }

  protected afterMoveUp(points: Partial<DOMPoint>[], sticky: boolean) {
    this._jambs.forEach(jamb => jamb.moveUp(points));

    if (sticky) {
      if (this.orientation === Orientation.Vertical) {
        return;
      }
      if (
        !this._afterMoveUpIntersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Vertical) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      this._afterMoveUpIntersections.forEach(e => {
        e.moveUp({ points: e.topPoints });
      });
    }
  }

  protected afterMoveDown(points: Partial<DOMPoint>[], sticky: boolean) {
    this._jambs.forEach(jamb => jamb.moveDown(points));

    if (sticky) {
      if (this.orientation === Orientation.Vertical) {
        return;
      }
      if (
        !this._afterMoveDownIntersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Vertical) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      this._afterMoveDownIntersections.forEach(e => {
        e.moveDown({ points: e.bottomPoints });
      });
    }
  }

  protected afterMoveRight(points: Partial<DOMPoint>[], sticky: boolean) {
    this._jambs.forEach(jamb => jamb.moveRight(points));

    if (sticky) {
      if (this.orientation === Orientation.Horizontal) {
        return;
      }
      if (
        !this._afterMoveRightIntersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Horizontal) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      this._afterMoveRightIntersections.forEach(e => {
        e.moveRight({ points: e.rightPoints });
      });
    }
  }

  protected afterMoveLeft(points: Partial<DOMPoint>[], sticky: boolean) {
    this._jambs.forEach(jamb => jamb.moveLeft(points));
    if (sticky) {
      if (this.orientation === Orientation.Horizontal) {
        return;
      }
      if (
        !this._afterMoveLeftIntersections.every(
          e => (e instanceof Stick && e.orientation === Orientation.Horizontal) || !(e instanceof Stick)
        )
      ) {
        return;
      }
      this._afterMoveLeftIntersections.forEach(e => {
        e.moveLeft({ points: e.leftPoints });
      });
    }
  }

  drawJambs() {
    this.destroyJambs();
    switch (this.type) {
      case StickType.OpenSection:
        return this.drawOpenJambs();
      case StickType.ClosedSection:
        return this.drawClosedJambs();
      case StickType.PartialMullion:
        return this.drawPartialNotchJambs();
    }
  }

  toJSON(): StickExport {
    return {
      exportType: Stick.intersectableName,
      points: this.points.map(pt => ({ x: pt.x, y: pt.y })),
      orientation: this.orientation,
      topJointType: this.topJointType,
      bottomJointType: this.bottomJointType,
      leftJointType: this.leftJointType,
      rightJointType: this.rightJointType,
      type: this.type,
      subType: this.subType,
      id: this.id,
      flipped: this.flipped,
      shopBreaks: this.shopBreaks as number[],
      rabbet: this.rabbet,
      profileDimensions: this.profileDimensions,
    };
  }

  toXML(): string {
    return `
    <category id="Stick">
      <description>Stick</description>
      <option id="Stick">
      <property id="Points">${JSON.stringify(this.points.map(p => ({ x: p.x, y: p.y })))}</property>
      <property id="Orientation">${this.orientation ?? ''}</property>
      <property id="TopCornerCondition">${this.topJointType ?? ''}</property>
      <property id="LeftCornerCondition">${this.leftJointType ?? ''}</property>
      <property id="BottomCornerCondition">${this.bottomJointType ?? ''}</property>
      <property id="RightCornerCondition">${this.rightJointType ?? ''}</property>
      <property id="Type">${this.type ?? ''}</property>
      <property id="SubType">${this.subType ?? ''}</property>
      <property id="Flipped">${!!this.flipped}</property>
      <property id="Rabbet">${this.rabbet ?? ''}</property>
      <property id="SpecialBackBend">${this.profiles.any(p => p.specialBackBend)}</property>
      <property id="SpecialStop">${this.profiles.any(p => p.specialStop)}</property>
      <property id="Face">${
        this.unitOfMeasure === UnitOfMeasure.Imperial ? this.face / CoreConstants.multipliers.frameElevation : this.face
      }</property>
      <property id="Length">
      ${
        this.unitOfMeasure === UnitOfMeasure.Imperial
          ? this.length / CoreConstants.multipliers.frameElevation
          : this.length
      }
      </property>
      <property id="SpliceJoints">${this.shopBreaks.join(',')}</property>
      <property id="SpliceJointQty">${this.shopBreaks.length}</property>
      <property id="SpecialRabbet">${this.profiles.any(p => p.specialRabbet)}</property>
      <property id="ShopBreaks">${JSON.stringify(this.shopBreaks)}</property>
      <property id="ProfileDimensions">${JSON.stringify(this.profileDimensions)}</property>
      </option>
    </category>`;
  }

  private centerVertical() {
    let top = 0;
    const topPoints = this.topPoints;
    while (topPoints.every(pt => !this.doesIntersect({ x: pt.x, y: pt.y - top }, Direction.Up))) {
      top += 1;
    }

    let bottom = 0;
    const bottomPoints = this.bottomPoints;
    while (bottomPoints.every(pt => !this.doesIntersect({ x: pt.x, y: pt.y + bottom }, Direction.Down))) {
      bottom += 1;
    }

    const topY = topPoints[0].y - top;
    const bottomY = bottomPoints[0].y + bottom;
    const mid = Math.floor((topY + bottomY) / 2);
    topPoints.forEach(pt => (pt.y = mid - this.face / 2));
    bottomPoints.forEach(pt => (pt.y = mid + this.face / 2));
  }

  private centerHorizontal() {
    let left = 0;
    const leftPoints = this.leftPoints;
    while (leftPoints.every(pt => !this.doesIntersect({ x: pt.x - left, y: pt.y }, Direction.Left))) {
      left += 1;
    }

    let right = 0;
    const rightPoints = this.rightPoints;
    while (rightPoints.every(pt => !this.doesIntersect({ x: pt.x + right, y: pt.y }, Direction.Right))) {
      right += 1;
    }

    const leftX = leftPoints[0].x - left;
    const rightX = rightPoints[0].x + right;
    const mid = Math.floor((leftX + rightX) / 2);
    leftPoints.forEach(pt => (pt.x = mid - this.face / 2));
    rightPoints.forEach(pt => (pt.x = mid + this.face / 2));
  }

  private flipHorizontal() {
    const leftPoints = this.leftPoints;
    const leftMin = leftPoints.min(_ => _.y);
    const leftMax = leftPoints.max(_ => _.y);
    const leftMid = [leftMin, leftMax].average(_ => _);
    for (const leftPoint of leftPoints) {
      const dist = leftMid - leftPoint.y;
      leftPoint.y = leftMid + dist;
    }

    const rightPoints = this.rightPoints;
    const rightMin = rightPoints.min(_ => _.y);
    const rightMax = rightPoints.max(_ => _.y);
    const rightMid = [rightMin, rightMax].average(_ => _);
    for (const rightPoint of rightPoints) {
      const dist = rightMid - rightPoint.y;
      rightPoint.y = rightMid + dist;
    }
    this._afterUpdate$.next();
  }

  private flipVertical() {
    const topPoints = this.topPoints;
    const topMin = topPoints.min(_ => _.x);
    const topMax = topPoints.max(_ => _.x);
    const topMid = [topMin, topMax].average(_ => _);
    for (const topPoint of topPoints) {
      const dist = topMid - topPoint.x;
      topPoint.x = topMid + dist;
    }

    const bottomPoints = this.bottomPoints;
    const bottomMin = bottomPoints.min(_ => _.x);
    const bottomMax = bottomPoints.max(_ => _.x);
    const bottomMid = [bottomMin, bottomMax].average(_ => _);
    for (const bottomPoint of bottomPoints) {
      const dist = bottomMid - bottomPoint.x;
      bottomPoint.x = bottomMid + dist;
    }
    this._afterUpdate$.next();
  }

  private miterTop() {
    if (this.orientation !== Orientation.Vertical) {
      throw new Error();
    }
    const points = this.topPoints;
    if (this.type === StickType.ClosedSection) {
      return false;
    }
    const point = this.flipped ? points[1] : points[0];
    const ref = this.flipped ? points[0] : points[1];
    point.y = ref.y + this.face;
    return true;
  }

  private notchOrSquareTop() {
    if (this.orientation !== Orientation.Vertical) {
      throw new Error();
    }
    const points = this.topPoints;
    const point = this.flipped ? points[0] : points[1];
    const ref = this.flipped ? points[1] : points[0];
    if (points.groupBy(pt => pt.y).size === 1 && this.topJointType === JointType.Notched) {
      ref.y += this.face;
    }
    point.y = ref.y;
    return true;
  }

  private miterBottom() {
    if (this.orientation !== Orientation.Vertical) {
      throw new Error();
    }
    const points = this.bottomPoints;
    if (this.type === StickType.ClosedSection) {
      return false;
    }

    const point = this.flipped ? points[1] : points[0];
    const ref = this.flipped ? points[0] : points[1];
    point.y = ref.y - this.face;
    return true;
  }

  private notchOrSquareBottom() {
    if (this.orientation !== Orientation.Vertical) {
      throw new Error();
    }
    const points = this.bottomPoints;
    const point = this.flipped ? points[0] : points[1];
    const ref = this.flipped ? points[1] : points[0];
    if (points.groupBy(pt => pt.y).size === 1 && this.bottomJointType === JointType.Notched) {
      ref.y -= this.face;
    }
    point.y = ref.y;
    return true;
  }

  private miterRight() {
    if (this.orientation !== Orientation.Horizontal) {
      throw new Error();
    }
    if (this.type === StickType.ClosedSection) {
      return false;
    }
    const points = this.rightPoints;
    const point = this.flipped ? points[1] : points[0];
    const ref = this.flipped ? points[0] : points[1];
    point.x = ref.x - this.face;
    return true;
  }

  private notchOrSquareRight() {
    if (this.orientation !== Orientation.Horizontal) {
      throw new Error();
    }
    const points = this.rightPoints;
    const point = this.flipped ? points[0] : points[1];
    const ref = this.flipped ? points[1] : points[0];
    if (points.groupBy(pt => pt.x).size === 1 && this.rightJointType === JointType.Notched) {
      ref.x -= this.face;
    }
    point.x = ref.x;
    return true;
  }

  private miterLeft() {
    if (this.orientation !== Orientation.Horizontal) {
      throw new Error();
    }
    const points = this.leftPoints;
    if (this.type === StickType.ClosedSection) {
      return false;
    }
    const point = this.flipped ? points[1] : points[0];
    const ref = this.flipped ? points[0] : points[1];
    point.x = ref.x + this.face;
    return true;
  }

  private notchOrSquareLeft() {
    if (this.orientation !== Orientation.Horizontal) {
      throw new Error();
    }
    const points = this.leftPoints;
    const point = this.flipped ? points[0] : points[1];
    const ref = this.flipped ? points[1] : points[0];
    if (points.groupBy(pt => pt.x).size === 1 && this.leftJointType === JointType.Notched) {
      ref.x += this.face;
    }
    point.x = ref.x;
    return true;
  }

  private miter2InchLeft() {
    if (this.orientation !== Orientation.Horizontal) {
      throw new Error();
    }
    if (this.face !== this.get2InchMiterFace()) {
      return false;
    }
    let leftPoints = this.leftPoints
      .sort((a, b) => (a.x < b.x ? -1 : 1))
      .slice(0, 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
    leftPoints = this.flipped ? leftPoints.reverse() : leftPoints;
    leftPoints.forEach(pt => (pt.x = leftPoints.max(p => p.x)));
    const pointA = this.points.location(pt => pt.x === leftPoints[0].x && pt.y === leftPoints[0].y);
    const pointB = this.points.location(pt => pt.x === leftPoints[1].x && pt.y === leftPoints[1].y);
    const point = this.svg.getPoint(
      leftPoints[1].x,
      leftPoints[0].y + this.get2InchMiterFace() / (this.flipped ? -2 : 2)
    );
    if ((pointA === 0 && pointB === this.points.length - 1) || (pointB === 0 && pointA === this.points.length - 1)) {
      leftPoints.insertAt(1, this.element.points.appendItem(point));
    } else {
      leftPoints.insertAt(
        1,
        this.element.points.insertItemBefore(
          point,
          [pointA, pointB].max(_ => _)
        )
      );
    }

    leftPoints[0].x = leftPoints[1].x + this.get2InchMiterFace() / 2;
    return true;
  }

  private miter2InchRight() {
    if (this.orientation !== Orientation.Horizontal) {
      throw new Error();
    }
    if (this.face !== this.get2InchMiterFace()) {
      return false;
    }
    let rightPoints = this.rightPoints
      .sort((a, b) => (a.x < b.x ? 1 : -1))
      .slice(0, 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
    rightPoints = this.flipped ? rightPoints.reverse() : rightPoints;
    rightPoints.forEach(pt => (pt.x = rightPoints.min(p => p.x)));
    const pointA = this.points.location(pt => pt.x === rightPoints[0].x && pt.y === rightPoints[0].y);
    const pointB = this.points.location(pt => pt.x === rightPoints[1].x && pt.y === rightPoints[1].y);
    const point = this.svg.getPoint(
      rightPoints[1].x,
      rightPoints[0].y + this.get2InchMiterFace() / (this.flipped ? -2 : 2)
    );
    if ((pointA === 0 && pointB === this.points.length - 1) || (pointB === 0 && pointA === this.points.length - 1)) {
      rightPoints.insertAt(1, this.element.points.appendItem(point));
    } else {
      rightPoints.insertAt(
        1,
        this.element.points.insertItemBefore(
          point,
          [pointA, pointB].max(_ => _)
        )
      );
    }

    rightPoints[0].x = rightPoints[1].x - this.get2InchMiterFace() / 2;
    return true;
  }

  private resizeHorizontal(reset: boolean, direction: Direction, dx: number) {
    const root = this.element.points.getItem(0);
    if (reset) {
      for (let i = 1; i < 4; i++) {
        const point = this.element.points.getItem(i);
        point.x = root.x;
        switch (i) {
          case 1:
            point.y = root.y + this.face;
            break;
          case 2:
            point.y = root.y + this.face;
            break;
          case 3:
            point.y = root.y;
            break;
        }
      }
    }

    const points = this.points.slice(2, 4);
    for (let i = 0; i < Math.abs(dx); i++) {
      const modifier = direction === Direction.Left ? -1 : 1;
      if (!points.every(point => !this.doesIntersect({ x: point.x + modifier, y: point.y }, direction))) {
        break;
      }
      points.forEach(point => (point.x += modifier));
    }
  }

  private resizeVertical(reset: boolean, direction: Direction, dy: number) {
    const root = this.element.points.getItem(0);
    if (reset) {
      for (let i = 1; i < 4; i++) {
        const point = this.element.points.getItem(i);
        point.y = root.y;
        switch (i) {
          case 1:
            point.x = root.x + this.face;
            break;
          case 2:
            point.x = root.x + this.face;
            break;
          case 3:
            point.x = root.x;
            break;
        }
      }
    }
    const points = this.points.slice(2, 4);
    for (let i = 0; i < Math.abs(dy); i++) {
      const modifier = direction === Direction.Up ? -1 : 1;
      if (!points.every(point => !this.doesIntersect({ x: point.x, y: point.y + modifier }, direction))) {
        break;
      }
      points.forEach(point => (point.y += modifier));
    }
  }

  private setDirection(x: number, y: number) {
    this._orientation =
      Math.abs(this._screenPosition.x - x) > Math.abs(this._screenPosition.y - y)
        ? Orientation.Horizontal
        : Orientation.Vertical;
  }

  private drawPartialNotchJambs() {
    if (this.orientation !== Orientation.Vertical) {
      return;
    }
    switch (this.subType) {
      case StickSubtype.Blank:
      case StickSubtype.Hinge:
      case StickSubtype.Strike:
      case StickSubtype.Hinge:
        return this.drawPartialNotchBlankJambs();
    }
  }

  private drawPartialNotchBlankJambs() {
    const aPoints = (this._flipped ? this.rightPoints : this.leftPoints).sort((a, b) => (a.y < b.y ? -1 : 1));
    const aModifier = this._flipped ? -this._sectionSize : this._sectionSize;
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          aPoints[0],
          this.svg.getPoint(
            aPoints[0].x,
            aPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          aPoints[0],
          this.svg.getPoint(
            aPoints[0].x - aModifier,
            aPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          aPoints[1],
          this.svg.getPoint(
            aPoints[1].x - aModifier,
            aPoints[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          aPoints[1],
          this.svg.getPoint(
            aPoints[1].x,
            aPoints[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
      ])
    );

    const bPoints = (this._flipped ? this.leftPoints : this.rightPoints).sort((a, b) => (a.y < b.y ? -1 : 1));
    const bModifier = this._flipped ? this._sectionSize : -this._sectionSize;

    const refIntersectable = (this._flipped ? this.leftIntersections : this.rightIntersections)
      .filter(i => i instanceof Stick)
      .orderBy(_ => _.topPoints[0].y)
      .last() as Stick;
    const ref = refIntersectable?.topPoints.sort((a, b) =>
      this._flipped ? (a.x < b.x ? -1 : 1) : a.x > b.x ? 1 : -1
    )[0];
    const y = ref?.y + refIntersectable?.face / 2 ?? 0;
    this._partialNotchDimension = this.bottomPoints.max(_ => _.y) - y;

    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          bPoints[0],
          this.svg.getPoint(
            bPoints[0].x,
            bPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          bPoints[0],
          this.svg.getPoint(
            bPoints[0].x - bModifier,
            bPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(null, this.svg.getPoint(bPoints[1].x - bModifier, y)),
        new PointAnchor(null, this.svg.getPoint(bPoints[1].x, y)),
      ])
    );
  }

  private drawClosedJambs() {
    switch (this.orientation) {
      case Orientation.Horizontal:
        switch (this.subType) {
          case StickSubtype.Blank:
          case StickSubtype.Sill:
          case StickSubtype.Head:
            return this.drawHorizontalClosedBlankJambs();
          default:
            return;
        }
      case Orientation.Vertical:
        switch (this.subType) {
          case StickSubtype.Blank:
          case StickSubtype.Hinge:
          case StickSubtype.Strike:
          case StickSubtype.StrikeHinge:
          case StickSubtype.StrikeStrike:
          case StickSubtype.HingeHinge:
            return this.drawVerticalClosedBlankJambs();
          default:
            return;
        }
    }
  }

  private drawHorizontalClosedBlankJambs() {
    const topPoints = this.topPoints.sort((a, b) => (a.x < b.x ? -1 : 1));
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          topPoints[0],
          this.svg.getPoint(
            topPoints[0].x + (this.leftJointType === JointType.Square ? 0 : this._sectionSize),
            topPoints[0].y
          )
        ),
        new PointAnchor(
          topPoints[0],
          this.svg.getPoint(
            topPoints[0].x + (this.leftJointType === JointType.Square ? 0 : this._sectionSize),
            topPoints[0].y - this._sectionSize
          )
        ),
        new PointAnchor(
          topPoints[1],
          this.svg.getPoint(
            topPoints[1].x - (this.rightJointType === JointType.Square ? 0 : this._sectionSize),
            topPoints[1].y - this._sectionSize
          )
        ),
        new PointAnchor(
          topPoints[1],
          this.svg.getPoint(
            topPoints[1].x - (this.rightJointType === JointType.Square ? 0 : this._sectionSize),
            topPoints[1].y
          )
        ),
      ])
    );

    const bottomPoints = this.bottomPoints.sort((a, b) => (a.x < b.x ? -1 : 1));
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          bottomPoints[0],
          this.svg.getPoint(
            bottomPoints[0].x + (this.leftJointType === JointType.Square ? 0 : this._sectionSize),
            bottomPoints[0].y
          )
        ),
        new PointAnchor(
          bottomPoints[0],
          this.svg.getPoint(
            bottomPoints[0].x + (this.leftJointType === JointType.Square ? 0 : this._sectionSize),
            bottomPoints[0].y + this._sectionSize
          )
        ),
        new PointAnchor(
          bottomPoints[1],
          this.svg.getPoint(
            bottomPoints[1].x - (this.rightJointType === JointType.Square ? 0 : this._sectionSize),
            bottomPoints[1].y + this._sectionSize
          )
        ),
        new PointAnchor(
          bottomPoints[1],
          this.svg.getPoint(
            bottomPoints[1].x - (this.rightJointType === JointType.Square ? 0 : this._sectionSize),
            bottomPoints[1].y
          )
        ),
      ])
    );
  }

  private drawVerticalClosedBlankJambs() {
    const leftPoints = this.leftPoints.sort((a, b) => (a.y < b.y ? -1 : 1));
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          leftPoints[0],
          this.svg.getPoint(
            leftPoints[0].x,
            leftPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          leftPoints[0],
          this.svg.getPoint(
            leftPoints[0].x - this._sectionSize,
            leftPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          leftPoints[1],
          this.svg.getPoint(
            leftPoints[1].x - this._sectionSize,
            leftPoints[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          leftPoints[1],
          this.svg.getPoint(
            leftPoints[1].x,
            leftPoints[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
      ])
    );

    const rightPoints = this.rightPoints.sort((a, b) => (a.y < b.y ? -1 : 1));
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          rightPoints[0],
          this.svg.getPoint(
            rightPoints[0].x,
            rightPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          rightPoints[0],
          this.svg.getPoint(
            rightPoints[0].x + this._sectionSize,
            rightPoints[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          rightPoints[1],
          this.svg.getPoint(
            rightPoints[1].x + this._sectionSize,
            rightPoints[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          rightPoints[1],
          this.svg.getPoint(
            rightPoints[1].x,
            rightPoints[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
      ])
    );
  }

  private drawOpenJambs() {
    switch (this.orientation) {
      case Orientation.Horizontal:
        switch (this.subType) {
          case StickSubtype.Blank:
          case StickSubtype.Sill:
          case StickSubtype.Head:
            return this.drawHorizontalOpenBlankJambs();
          default:
            return;
        }
      case Orientation.Vertical:
        switch (this.subType) {
          case StickSubtype.Blank:
          case StickSubtype.Hinge:
          case StickSubtype.Strike:
          case StickSubtype.StrikeHinge:
          case StickSubtype.HingeHinge:
          case StickSubtype.StrikeStrike:
            return this.drawVerticalOpenBlankJambs();
          default:
            return;
        }
    }
  }

  private drawVerticalOpenBlankJambs() {
    const points = this.points
      .filter(pt => {
        if (this.topJointType === JointType.Mitered) {
          const point = this.topPoints.sort((a, b) => (a.y < b.y ? 1 : -1))[0];
          return point.x === pt.x;
        } else if (this.bottomJointType === JointType.Mitered) {
          const point = this.bottomPoints.sort((a, b) => (a.y < b.y ? -1 : 1))[0];
          return point.x === pt.x;
        }
        return (this.flipped ? this.rightPoints : this.leftPoints)[0].x === pt.x;
      })
      .sort((a, b) => (a.y < b.y ? -1 : 1));
    const modifier = points.every(pt => this.leftPoints.some(tp => tp.x === pt.x))
      ? -this._sectionSize
      : this._sectionSize;
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          points[0],
          this.svg.getPoint(
            points[0].x,
            points[0].y + (this.topJointType === JointType.Notched ? this._sectionSize : 0)
          )
        ),
        new PointAnchor(
          points[0],
          this.svg.getPoint(
            points[0].x + modifier,
            points[0].y + (this.topJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          points[1],
          this.svg.getPoint(
            points[1].x + modifier,
            points[1].y - (this.bottomJointType === JointType.Square ? 0 : this._sectionSize)
          )
        ),
        new PointAnchor(
          points[1],
          this.svg.getPoint(
            points[1].x,
            points[1].y - (this.bottomJointType === JointType.Notched ? this._sectionSize : 0)
          )
        ),
      ])
    );
  }

  private drawHorizontalOpenBlankJambs() {
    const points = this.points
      .filter(pt => {
        if (this.leftJointType === JointType.Mitered || this.leftJointType === JointType._2InchMiter) {
          const point = this.leftPoints.sort((a, b) => (a.x < b.x ? 1 : -1))[0];
          return point.y === pt.y;
        } else if (this.rightJointType === JointType.Mitered || this.rightJointType === JointType._2InchMiter) {
          const point = this.rightPoints.sort((a, b) => (a.x < b.x ? -1 : 1))[0];
          return point.y === pt.y;
        }
        return (this.flipped ? this.bottomPoints : this.topPoints)[0].y === pt.y;
      })
      .sort((a, b) => (a.x < b.x ? -1 : 1));
    const modifier = points.every(pt => this.topPoints.some(tp => tp.y === pt.y))
      ? -this._sectionSize
      : this._sectionSize;
    this._jambs.push(
      new Jamb(this.container, [
        new PointAnchor(
          points[0],
          this.svg.getPoint(
            points[0].x + (this.leftJointType === JointType.Notched ? this._sectionSize : 0),
            points[0].y
          )
        ),
        new PointAnchor(
          points[0],
          this.svg.getPoint(
            points[0].x + (this.leftJointType === JointType.Square ? 0 : this._sectionSize),
            points[0].y + modifier
          )
        ),
        new PointAnchor(
          points[1],
          this.svg.getPoint(
            points[1].x - (this.rightJointType === JointType.Square ? 0 : this._sectionSize),
            points[1].y + modifier
          )
        ),
        new PointAnchor(
          points[1],
          this.svg.getPoint(
            points[1].x - (this.rightJointType === JointType.Notched ? this._sectionSize : 0),
            points[1].y
          )
        ),
      ])
    );
  }

  private destroyJambs() {
    this._jambs.forEach(jamb => jamb.destroy());
    this._jambs = [];
  }

  private get2InchMiterFace() {
    return (this.unitOfMeasure === UnitOfMeasure.Imperial ? '4"' : '64mm').fromDimension('frame', this.unitOfMeasure);
  }
}
