import rpip from 'robust-point-in-polygon';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { guid } from '@oeo/common';
import { Direction } from '../enums/direction';
import { IntersectableExport } from '../models/exports/intersectable-export';
import { FrameElevation } from '../models/frame-elevation';
import { IntersectablePolygon } from './intersectable-polygon';
import { createSVGElement } from '../../core/helpers/svg-functions';

export const intersectableClass = 'intersectable';

export interface IMovementOptions {
  points?: Partial<DOMPoint>[];
  ignoreIntersection?: boolean;
  afterUpdate?: boolean;
  sticky?: boolean;
}

export abstract class Intersectable {
  private _active: boolean;
  protected _afterUpdate$ = new Subject<void>();
  protected _destroy$ = new Subject<void>();
  private _isDragging = false;
  readonly id = guid();

  get destroyed$() {
    return this._destroy$.asObservable();
  }

  get afterUpdate$() {
    return this._afterUpdate$.asObservable();
  }

  get svg() {
    return this.element.root(SVGSVGElement);
  }

  get rect() {
    return this.svg.children.item(0) as SVGRectElement;
  }

  get points() {
    return Array.from(this.element.points);
  }

  abstract readonly topPoints: DOMPoint[];
  abstract readonly leftPoints: DOMPoint[];
  abstract readonly rightPoints: DOMPoint[];
  abstract readonly bottomPoints: DOMPoint[];

  get width() {
    return this.getWidth(this.points);
  }

  get height() {
    return this.getHeight(this.points);
  }

  get unitOfMeasure() {
    return this.frameElevation.unitOfMeasure;
  }

  get isEditable(): boolean {
    return this.frameElevation.editable;
  }

  get active() {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
    if (this._active) {
      this.element.classList.add('active');
    } else {
      this.element.classList.remove('active');
    }
  }

  element: IntersectablePolygon;
  container: SVGGElement;

  protected static parseXML(doc: Document, property: string): string {
    const value = doc.evaluate(
      `option/property[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${property.toLowerCase()}']`,
      doc,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    )?.singleNodeValue?.textContent;
    if (!value || value === 'undefined') {
      return null;
    }
    return value;
  }

  constructor(
    public readonly frameElevation: FrameElevation,
    container: SVGGElement,
    className: string,
    editable: boolean
  ) {
    this.container = container;
    this.element = createSVGElement('polygon') as IntersectablePolygon;
    this.element.intersectable = this;
    this.element.classList.add(intersectableClass, className);
    container.appendChild(this.element);

    if (editable) {
      fromEvent(this.element, 'mousedown')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(e => {
          e.stopPropagation();
          if (!this.isEditable) {
            return;
          }
          this._isDragging = true;
        });

      fromEvent(document, 'mousemove')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((e: MouseEvent) => {
          if (this._isDragging) {
            this.move(e.movementX, e.movementY);
          }
        });

      fromEvent(document, 'mouseup')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(e => {
          this._isDragging = false;
        });

      fromEvent(this.element, 'click')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(e => e.stopPropagation());
    }
  }

  get topIntersections(): Intersectable[] {
    return this.getIntersectables().filter(i =>
      this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x, y: pt.y - 1 }, Direction.Up))
    );
  }

  get bottomIntersections(): Intersectable[] {
    return this.getIntersectables().filter(i =>
      this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x, y: pt.y + 1 }, Direction.Down))
    );
  }

  get leftIntersections(): Intersectable[] {
    return this.getIntersectables().filter(i =>
      this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x - 1, y: pt.y }, Direction.Left))
    );
  }

  get rightIntersections(): Intersectable[] {
    return this.getIntersectables().filter(i =>
      this.points.any(pt => this.doesIntersectPolygon(i, { x: pt.x + 1, y: pt.y }, Direction.Right))
    );
  }

  moveUp(opts: IMovementOptions = { afterUpdate: true, sticky: false }) {
    opts.points = opts.points ? opts.points : this.points;
    this.beforeMoveUp(opts.points, opts.sticky);
    if (
      opts.ignoreIntersection ||
      opts.points
        .sort((a, b) => (a.y < b.y ? -1 : 1))
        .slice(0, 2)
        .every(point => !this.doesIntersect({ x: point.x, y: point.y - 1 }, Direction.Up))
    ) {
      opts.points.forEach(point => (point.y -= 1));
      this.afterMoveUp(opts.points, opts.sticky);
      if (opts.afterUpdate) {
        this._afterUpdate$.next();
      }
      return true;
    }
    if (opts.afterUpdate) {
      this._afterUpdate$.next();
    }
    return false;
  }

  alignUp() {
    while (this.moveUp({ afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  stretchUp() {
    while (this.moveUp({ points: this.topPoints, afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  shrinkUp(afterUpdate: boolean = true) {
    const points = this.bottomPoints;
    this.moveUp({ points, ignoreIntersection: true, afterUpdate: false });
    if (!this.isValid()) {
      this.moveDown({ points, ignoreIntersection: true, afterUpdate: false });
    }
    if (afterUpdate) {
      this._afterUpdate$.next();
    }
  }

  moveDown(opts: IMovementOptions = { afterUpdate: true, sticky: false }) {
    opts.points = opts.points ? opts.points : this.points;
    this.beforeMoveDown(opts.points, opts.sticky);
    if (
      opts.ignoreIntersection ||
      opts.points
        .sort((a, b) => (a.y < b.y ? 1 : -1))
        .slice(0, 2)
        .every(point => !this.doesIntersect({ x: point.x, y: point.y + 1 }, Direction.Down))
    ) {
      opts.points.forEach(point => (point.y += 1));
      this.afterMoveDown(opts.points, opts.sticky);
      if (opts.afterUpdate) {
        this._afterUpdate$.next();
      }
      return true;
    }
    if (opts.afterUpdate) {
      this._afterUpdate$.next();
    }
    return false;
  }

  alignDown() {
    while (this.moveDown({ afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  stretchDown() {
    while (this.moveDown({ points: this.bottomPoints })) {}
    this._afterUpdate$.next();
  }

  shrinkDown(afterUpdate: boolean = true) {
    const points = this.topPoints;
    this.moveDown({ points, afterUpdate: false, ignoreIntersection: true });
    if (!this.isValid()) {
      this.moveUp({ points, afterUpdate: false, ignoreIntersection: true });
    }
    if (afterUpdate) {
      this._afterUpdate$.next();
    }
  }

  moveRight(opts: IMovementOptions = { afterUpdate: true, sticky: false }) {
    opts.points = opts.points ? opts.points : this.points;
    this.beforeMoveRight(opts.points, opts.sticky);
    if (
      opts.ignoreIntersection ||
      opts.points
        .sort((a, b) => (a.x < b.x ? 1 : -1))
        .slice(0, 2)
        .every(point => !this.doesIntersect({ x: point.x + 1, y: point.y }, Direction.Right))
    ) {
      opts.points.forEach(point => (point.x += 1));
      this.afterMoveRight(opts.points, opts.sticky);
      if (opts.afterUpdate) {
        this._afterUpdate$.next();
      }
      return true;
    }
    if (opts.afterUpdate) {
      this._afterUpdate$.next();
    }
    return false;
  }

  alignRight() {
    while (this.moveRight({ afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  stretchRight() {
    while (this.moveRight({ points: this.rightPoints, afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  shrinkRight(afterUpdate: boolean = true) {
    const points = this.leftPoints;
    this.moveRight({ points, ignoreIntersection: true, afterUpdate: false });
    if (!this.isValid()) {
      this.moveLeft({ points, ignoreIntersection: true, afterUpdate: false });
    }
    if (afterUpdate) {
      this._afterUpdate$.next();
    }
  }

  moveLeft(opts: IMovementOptions = { afterUpdate: true, sticky: false }) {
    opts.points = opts.points ? opts.points : this.points;
    this.beforeMoveLeft(opts.points, opts.sticky);
    if (
      opts.ignoreIntersection ||
      opts.points
        .sort((a, b) => (a.x < b.x ? -1 : 1))
        .slice(0, 2)
        .every(point => !this.doesIntersect({ x: point.x - 1, y: point.y }, Direction.Left))
    ) {
      opts.points.forEach(point => (point.x -= 1));
      this.afterMoveLeft(opts.points, opts.sticky);
      if (opts.afterUpdate) {
        this._afterUpdate$.next();
      }
      return true;
    }
    if (opts.afterUpdate) {
      this._afterUpdate$.next();
    }
    return false;
  }

  alignLeft() {
    while (this.moveLeft({ afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  stretchLeft() {
    while (this.moveLeft({ points: this.leftPoints, afterUpdate: false })) {}
    this._afterUpdate$.next();
  }

  shrinkLeft(afterUpdate: boolean = true) {
    const points = this.rightPoints;
    this.moveLeft({ points, afterUpdate: false, ignoreIntersection: true });
    if (!this.isValid()) {
      this.moveRight({ points, afterUpdate: false, ignoreIntersection: true });
    }
    if (afterUpdate) {
      this._afterUpdate$.next();
    }
  }

  flipOverCenter() {
    const centerPoint = this.rect.width.baseVal.value / 2;
    this.points.forEach(point => {
      const distance = centerPoint - point.x;
      point.x += distance * 2;
    });
  }

  protected doesIntersect(point: Partial<DOMPoint>, direction: Direction): boolean {
    if (!this.isInBounds(point)) {
      return true;
    }
    const polygons = this.getIntersectables();

    if (polygons.length === 0) {
      return false;
    }

    for (const polygon of polygons) {
      if (this.doesIntersectPolygon(polygon, point, direction)) {
        return true;
      }
    }
    return false;
  }

  protected doesIntersectPolygon(polygon: Intersectable, point: Partial<DOMPoint>, direction: Direction): boolean {
    const elementPoly = this.points.map(pt => {
      if (Math.abs(pt.y - point.y) === 1) {
        return { x: pt.x, y: point.y };
      }
      if (Math.abs(pt.x - point.x) === 1) {
        return { x: point.x, y: pt.y };
      }
      return pt;
    });
    if (this.isPointInPoly(point, polygon.points)) {
      return true;
    }
    if (this.getInverseDirectionalPolygonPoints(polygon, direction).some(pt => this.isPointInPoly(pt, elementPoly))) {
      return true;
    }
    if (
      this.getInverseDirectionalPolygonPoints(polygon, direction, true).every(pt =>
        this.getDirectionalPolygonPoints(this, direction).some(p => pt.x === p.x && pt.y === p.y)
      )
    ) {
      return true;
    }
  }

  protected getIntersectables() {
    return (Array.from(this.container.getElementsByClassName(intersectableClass)) as IntersectablePolygon[])
      .filter(_ => _ !== this.element)
      .map(polygon => polygon.intersectable);
  }

  protected linesOverlap(a: Partial<DOMPoint>[], b: Partial<DOMPoint>[]): boolean {
    if (a[0].x === b[0].x && a[1].x === b[1].x) {
      return (a[0].y <= b[0].y && a[1].y >= b[1].y) || (b[0].y <= a[0].y && b[1].y >= a[1].y);
    }
    if (a[0].y === b[0].y && a[1].y === b[1].y) {
      return (a[0].x <= b[0].x && a[1].x >= b[1].x) || (b[0].x <= a[0].x && b[1].x >= a[1].x);
    }
    return false;
  }

  private getWidth(points: Partial<DOMPoint>[]) {
    return Math.max(...points.map(p => p.x)) - Math.min(...points.map(p => p.x));
  }

  private getHeight(points: Partial<DOMPoint>[]) {
    return Math.max(...points.map(p => p.y)) - Math.min(...points.map(p => p.y));
  }

  private getInverseDirectionalPolygonPoints(
    polygon: Intersectable,
    direction: Direction,
    offset?: boolean
  ): Partial<DOMPoint>[] {
    switch (direction) {
      case Direction.Up:
        return polygon.bottomPoints.map(pt => ({ x: pt.x, y: pt.y - (offset ? 1 : 0) }));
      case Direction.Down:
        return polygon.topPoints.map(pt => ({ x: pt.x, y: pt.y + (offset ? 1 : 0) }));
      case Direction.Left:
        return polygon.rightPoints.map(pt => ({ x: pt.x - (offset ? 1 : 0), y: pt.y }));
      case Direction.Right:
        return polygon.leftPoints.map(pt => ({ x: pt.x + (offset ? 1 : 0), y: pt.y }));
    }
  }

  private getDirectionalPolygonPoints(polygon: Intersectable, direction: Direction) {
    switch (direction) {
      case Direction.Down:
        return polygon.bottomPoints.map(pt => ({ x: pt.x, y: pt.y + 1 }));
      case Direction.Up:
        return polygon.topPoints.map(pt => ({ x: pt.x, y: pt.y - 1 }));
      case Direction.Right:
        return polygon.rightPoints.map(pt => ({ x: pt.x + 1, y: pt.y }));
      case Direction.Left:
        return polygon.leftPoints.map(pt => ({ x: pt.x - 1, y: pt.y }));
    }
  }

  private isInBounds(point: Partial<DOMPoint>) {
    if (point.x < 0 || point.y < 0) {
      return false;
    }
    if (point.y > this.rect.height.baseVal.value || point.x > this.rect.width.baseVal.value) {
      return false;
    }
    return true;
  }

  private isPointInPoly(pt: Partial<DOMPoint>, poly: Partial<DOMPoint>[], onLine?: boolean) {
    return (
      rpip(
        poly.map(p => [p.x, p.y]),
        [pt.x, pt.y]
      ) === (onLine ? 0 : -1)
    );
  }

  protected abstract beforeMoveUp(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract beforeMoveDown(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract beforeMoveRight(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract beforeMoveLeft(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract afterMoveUp(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract afterMoveDown(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract afterMoveRight(points: Partial<DOMPoint>[], sticky: boolean): void;

  protected abstract afterMoveLeft(points: Partial<DOMPoint>[], sticky: boolean): void;

  abstract destroy(): void;

  abstract isValid(): boolean;

  abstract toJSON(): IntersectableExport;

  abstract toXML(): string;

  private move(dx: number, dy: number) {
    if (dx < 0) {
      for (let i = 0; i > dx; i--) {
        if (!this.moveLeft({ points: this.points, afterUpdate: false, sticky: true })) {
          break;
        }
      }
    }
    if (dy < 0) {
      for (let i = 0; i > dy; i--) {
        if (!this.moveUp({ points: this.points, afterUpdate: false, sticky: true })) {
          break;
        }
      }
    }
    if (dx > 0) {
      for (let i = 0; i < dx; i++) {
        if (!this.moveRight({ points: this.points, afterUpdate: false, sticky: true })) {
          break;
        }
      }
    }
    if (dy > 0) {
      for (let i = 0; i < dy; i++) {
        if (!this.moveDown({ points: this.points, afterUpdate: false, sticky: true })) {
          break;
        }
      }
    }
    this._afterUpdate$.next();
  }
}
