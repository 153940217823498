import { Injectable } from '@angular/core'
import { ActiveFilters, Filter, FilterKey } from '../../models/filter'
import { Dictionary, cloneDeep, isEqual, snakeCase } from 'lodash'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SearchAndFilterService {
  public buttonClickTrackEvent = new Subject<string>()

  constructor() {}

  searchAndFilter<Item>(
    filters: ActiveFilters,
    data: Item[],
    searchKeys: string[],
    searchValue: string,
    searchParams?: Record<string, string>
  ) {
    let _data = cloneDeep(data)
    if (searchValue) {
      _data = this.search<Item>(_data, searchKeys, searchValue)
    }
    if (searchParams) {
      _data = this.filter<Item>(_data, searchParams)
    }
    return _data
  }

  filter<Item>(data: Item[], searchParams?: Record<string, string>) {
    if (!searchParams) {
      return data
    }

    for (const key in searchParams) {
      const value = searchParams[key]

      if (value) {
        const _searchValue = value.trim().toLowerCase()
        const _strippedSearchValue = _searchValue.replace(/[^a-z0-9]/gim, '')

        data = data.filter((d) => {
          let isMatch = false
          Object.entries(searchParams).forEach((columnName) => {
            if (columnName[0] == key) {
              if (d[columnName[0] as keyof Item]) {
                const sanitized = d[columnName[0] as keyof Item].toString().trim().toLowerCase()
                const containsSanitized = sanitized.includes(_searchValue)
                const constainsStripped = sanitized.replace(/[^a-z0-9]/gim, '').includes(_strippedSearchValue)
                if (containsSanitized || constainsStripped) {
                  isMatch = true
                }
              }
            }
          })
          return isMatch
        })
      }
    }
    return data
  }

  search<Item>(data: Item[], searchKeys: string[], searchValue: string) {
    if (!searchValue) {
      return data
    }
    const _searchValue = searchValue.trim().toLowerCase()
    const _strippedSearchValue = _searchValue.replace(/[^a-z0-9]/gim, '')
    data = data.filter((d) => {
      let isMatch = false
      searchKeys.forEach((key) => {
        if (d[key as keyof Item]) {
          const sanitized = d[key as keyof Item].toString().trim().toLowerCase()
          const containsSanitized = sanitized.includes(_searchValue)
          const constainsStripped = sanitized.replace(/[^a-z0-9]/gim, '').includes(_strippedSearchValue)
          if (containsSanitized || constainsStripped) {
            isMatch = true
          }
        }
      })
      return isMatch
    })
    return data
  }

  /**
   * Creates filters and removes duplicates from list in a single filter
   *
   * @template Item
   * @param items
   * @param filterKeys
   * @returns filters
   */
  createFilters<Item extends Object>(items: Item[], filterKeys: FilterKey[]): Filter[] {
    if (!items.length) {
      return []
    }
    return filterKeys.map((key) => {
      let values: (string | boolean)[] = []
      const property = key.attributeValue as keyof Item
      items.forEach((item) => {
        // check for duplicates in list
        if (
          item.hasOwnProperty(property) &&
          item[property as keyof Item] !== null &&
          !((item[property] as unknown as string) === '') &&
          !values.includes(item[property as keyof Item] as unknown as string | boolean)
        ) {
          values.push(item[key.attributeValue as keyof Item] as unknown as string | boolean)
        }
      })
      values = values.sort()
      if (isEqual(values, [false, true])) values = values.reverse()
      const filter = { key: property, title: `TABLE_HEADERS.${key.displayValue}`, list: values }
      return filter
    }) as Filter[]
  }

  /**
   * Maps an array of selected items into a given array
   * @param items the items to map the selected items into
   * @param selectedItems
   * @param mapBy a function that is used to map a selectedItem to an item in the items array
   * @returns the items, with their selected value either true or false
   */
  mapToSelected<T extends { selected: boolean }>(items: T[], selectedItems: T[], mapBy: (item: T) => T[keyof T]) {
    return items.map((item) => {
      const itemInSelected = selectedItems.findIndex((selectedIitem) => mapBy(selectedIitem) === mapBy(item))
      if (itemInSelected >= 0) item.selected = true
      return item
    })
  }
}
