import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event, ParamMap } from '@angular/router';

import { IBreadCrumb } from '../../interfaces/i-breadcrumb';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, map } from 'rxjs/operators';
import { query } from '@angular/animations';

@Component({
  selector: 'lib-breadcrumb',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject();

  breadcrumbs: IBreadCrumb[];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
    this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
      });
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<any> = []): Array<any> {
    let newBreadcrumbs = [];
    const breadcrumb = this.createBreadCrumb(route, url);
    newBreadcrumbs = [breadcrumb, ...breadcrumbs];
    if (route.firstChild) {
      const nextUrl = this.createPathNextUrl(route, url);
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    if (route.snapshot.queryParams && newBreadcrumbs.length > 0) {
      const params = route.snapshot.queryParams;
      let queryString = Object.keys(params)
        .map(key => `${key} ${params[key]}`)
        .join(',');
      if (!queryString && (newBreadcrumbs[0].label === 'Doors' || newBreadcrumbs[0].label === 'Frames')) {
        queryString = 'Untitled Drawing';
      }
      newBreadcrumbs.unshift({
        label: queryString,
        url: '',
        params: null,
      });
    }
    return newBreadcrumbs.filter(x => x.label && x.label !== 'ignore');
  }

  createBreadCrumb(route: ActivatedRoute, url: string): IBreadCrumb {
    let label: string;
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
      label = route.routeConfig.data['breadcrumb'];
    } else {
      label = 'ignore';
    }
    const nextUrl = this.createPathNextUrl(route, url);
    const breadcrumb = {
      label: label,
      url: nextUrl,
    };

    return breadcrumb;
  }

  createPathNextUrl(route: ActivatedRoute, url: string): string {
    const path = route.snapshot.url.map(segment => segment.path).join('/');
    const nextUrl = `${url}/${path}`;
    return nextUrl;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
