import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'oa-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {}

  ngOnInit() {}

  handleRetry() {
    this.dialogRef.close('retry')
  }
  handleContactSupport() {
    window.location.href = 'mailto:online.ordering.support@allegion.com'
    this.dialogRef.close()
  }

  cancel() {
    this.dialogRef.close()
  }
}
