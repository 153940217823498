import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { EMPTY, Subject } from 'rxjs'
import { switchMap, takeWhile } from 'rxjs/operators'
import { AuthService } from '../../../services/auth.service'
import { CustomerType, Features } from '../../../models/features.model'
import { FlagsService } from '../../../services/flags/flags.service'
import { InternalLandingService } from './internal-landing-service/internal-landing-service.service'
import { HelperService } from '../../../services/helper/helper.service'

@Component({
  template: ''
})
export class InternalLandingComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject()

  constructor(
    private router: Router,
    private internalLandingService: InternalLandingService,
    private flagsService: FlagsService,
    private auth: AuthService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    const path: string = this.router.url
    const hash: string = this.router.parseUrl(path).queryParamMap.keys[0]
    if (hash) {
      this.internalLandingService.storeHash(hash)
    }
    this.auth.userProfile$
      .pipe(
        switchMap((userProfile) => {
          if (!userProfile) {
            return EMPTY
          }
          return this.flagsService.flags$
        }),
        takeWhile((flags: Features) => !flags, true)
      )
      .subscribe({
        next: (flags) => {
          if (!flags) {
            return
          }
          if (flags.isDirect) {
            this.router.navigate(['/estimates'])
          }
          if (flags.isIndirect) {
            this.router.navigate(['projects'])
          }
        },
        error: (err) => this.helperService.handleError(err.message)
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
