import { Component, OnInit, OnDestroy, Inject, AfterViewInit } from '@angular/core'
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Subject } from 'rxjs'
import { first } from 'rxjs/operators'
import { cloneDeep } from 'lodash'
import { Estimate } from '../../../../../models/estimate.model'
import { EstimatesService } from '../../../../../services/estimates/estimates.service'
import { HelperService, NameRegex } from '../../../../../services/helper/helper.service'

@Component({
  selector: 'oa-indirect-new-project-dialog',
  templateUrl: './indirect-new-project-dialog.component.html',
  styleUrls: ['./indirect-new-project-dialog.component.scss']
})
export class IndirectNewProjectDialogComponent implements OnInit, OnDestroy {
  private destroyed$: Subject<boolean> = new Subject()
  project: Estimate
  isSaving = false
  projectForm: FormGroup<{ name: FormControl<string> }>

  constructor(
    public dialogRef: MatDialogRef<IndirectNewProjectDialogComponent>,
    public dialog: MatDialog,
    public router: Router,
    public estimateService: EstimatesService,
    public helperService: HelperService,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { estimate: Estimate; action: string }
  ) {
    if (data && data.action === 'edit') {
      this.project = cloneDeep(data.estimate)
    } else {
      this.project = new Estimate()
    }
    this.projectForm = this.createForm()
  }

  ngOnInit() {}

  createForm(): FormGroup<{ name: FormControl<string> }> {
    return this.fb.group({
      name: new FormControl(this.project.name, [
        Validators.maxLength(50),
        Validators.pattern(NameRegex),
        Validators.required
      ])
    })
  }

  trim(name: string): void {
    const control = this.projectForm.controls.name
    const _name = name ? name.trim() : name
    control.patchValue(_name)
  }

  createProjectList(): void {
    this.isSaving = true
    this.project.name = this.projectForm.value.name
    if (this.data && this.data.action === 'edit') {
      this.edit()
    } else {
      this.create()
    }
  }

  edit(): void {
    this.estimateService
      .updateEstimate(this.project)
      .pipe(first())
      .subscribe(
        () => this.dialogRef.close(),
        (error) => {
          this.helperService.handleError(error.message)
          this.dialogRef.close()
        }
      )
  }

  create(): void {
    this.estimateService
      .createEstimate(this.project)
      .pipe(first())
      .subscribe(
        (project: Estimate) => {
          if (!project) {
            return
          }
          this.router.navigate(['/projects', project.id])
          this.dialogRef.close()
        },
        (error) => {
          this.dialogRef.close()
          this.helperService.handleError(error.message)
        }
      )
  }

  cancel(): void {
    this.dialogRef.close()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
