import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { RestService } from './rest.service';
import { Folder } from '../models/folder';
import { Cacheable } from 'ts-cacheable';
import { IQueryParams } from '../interfaces/i-query-params';

@Injectable({
  providedIn: 'root',
})
export class FolderService extends RestService<Folder> {
  constructor(http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) environment: IEnvironment) {
    super(http, `${environment.elevations.apiUrl}v1/folders`);
  }

  @Cacheable()
  search(query: IQueryParams): Observable<Folder[]> {
      return super.search(query);
  }
}
