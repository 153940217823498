import { Profile } from '../abstracts/profile';
import { ProfileTemplate } from '../abstracts/profile-template';
import { Stick } from '../models/stick';
import { GlassExport } from '../models/exports/glass-export';
import { MUDROS } from './mu-series/mudros';
import { MHSROS } from './mh-series/mhsros';
import { MUCCS } from './mu-series/muccs';
import { MUCOS } from './mu-series/mucos';
import { FSRCS } from './f-series/fsrcs';
import { MUDRCS } from './mu-series/mudrcs';

export class MHSeries extends Profile {
  constructor(stick: Stick, points: Partial<DOMPoint>[]) {
    super(stick, points);
  }

  protected doubleRabbetClosedSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new MUDRCS(this, glazingBeads);
  }

  protected doubleRabbetOpenSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new MUDROS(this, glazingBeads);
  }

  protected singleRabbetClosedSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FSRCS(this, glazingBeads);
  }

  protected singleRabbetOpenSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new MHSROS(this, glazingBeads);
  }

  protected casedClosedSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new MUCCS(this, glazingBeads);
  }

  protected casedOpenSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new MUCOS(this, glazingBeads);
  }

  protected doubleEgressSection(): ProfileTemplate {
    throw new Error('Profile not valid for this series');
  }
}
