import { addMinutes, differenceInMilliseconds, parseISO } from 'date-fns'

export function isRecentlyAddedItem<T extends { created?: string; lastModified?: string }>(row: T) {
  if (!row.created) return false
  if (row.lastModified && parseISO(row.created) > parseISO(row.lastModified)) return false
  return getDiffInMilliseconds(parseISO(row.created)) < 1000 * 30
}

export function getDiffInMilliseconds(date: Date) {
  const userTimezoneOffset = new Date().getTimezoneOffset()
  const timeNowInGMT = addMinutes(new Date(), userTimezoneOffset)
  return differenceInMilliseconds(timeNowInGMT, date)
}
