import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { EmbossedDoorType } from '../../abstracts/embossed-door-type';
import { Panel } from '../../abstracts/panel';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Embossment } from '../../models/cutout-types/embossment';
import { SquarePanel } from '../../models/cutout-types/embossments/square-panel';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class E4Type extends EmbossedDoorType {
  cutouts: Cutout[];
  private width = uomSwitch('23"', 'door', this.door.doorElevation.unitOfMeasure);
  private height = uomSwitch('65 1/8"', 'door', this.door.doorElevation.unitOfMeasure);
  private bottomRail = uomSwitch('10"', 'door', this.door.doorElevation.unitOfMeasure);

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    if(this.door.height.toDimension('door', UnitOfMeasure.Imperial)=== '80"'){
      this.bottomRail = uomSwitch('8"', 'door', this.door.doorElevation.unitOfMeasure);
    }
    this.cutouts = [this.getEmbossment()];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  get totalEmbossmentHeight(): number {
    return this.height;
  }

  get maxEmbossmentDistances() {
    return {
      fromTop: '15 1/8"'.fromDimension('door', UnitOfMeasure.Imperial),
      fromBottom: '15 1/4"'.fromDimension('door', UnitOfMeasure.Imperial)
    }
  }

  private getEmbossment(): Cutout {
    return new Embossment(
      'Embossment',
      this,
      (this.door.actualWidth - this.width) / 2,
      this.door.actualHeight - this.height - this.bottomRail,
      this.width,
      this.height,
      VerticalCutoutDimensionMode.Bottom,
      HorizontalCutoutDimensionMode.Lock,
      this.getPanels()
    ).setMinMaxDistance(this.minMaxEmbossmentDimensions);
  }

  private getPanels(): Panel[] {
    const topPanelHeight = uomSwitch('37 1/8"', 'door', this.door.doorElevation.unitOfMeasure);
    const bottomPanelHeight = uomSwitch('21 1/2"', 'door', this.door.doorElevation.unitOfMeasure);
    const verticalSpaceBetween = uomSwitch('6 1/2"', 'door', this.door.doorElevation.unitOfMeasure);
    const horizontalSpaceBetween = uomSwitch('5"', 'door', this.door.doorElevation.unitOfMeasure);
    const width = uomSwitch('9"', 'door', this.door.doorElevation.unitOfMeasure);
    return [
      new SquarePanel(0, 0, width, topPanelHeight),
      new SquarePanel(width + horizontalSpaceBetween, 0, width, topPanelHeight),
      new SquarePanel(0, topPanelHeight + verticalSpaceBetween, width, bottomPanelHeight),
      new SquarePanel(width + horizontalSpaceBetween, topPanelHeight + verticalSpaceBetween, width, bottomPanelHeight),
    ];
  }
}
