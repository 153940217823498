import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComparisonType, ComparisonTypes } from '../../../enums/comparison-type';
import { UnitOfMeasure } from '../../../enums/unit-of-measure';
import { ICustomRule } from '../../../interfaces/i-custom-rule';
import { ICustomRuleProperty } from '../../../interfaces/i-custom-rule-property';
import { ICustomRuleType } from '../../../interfaces/i-custom-rule-type';
import { measurementValidators } from '../../../validators/measurementValidator';
import { ControlsOf } from '@oeo/common'

@Component({
  selector: 'lib-custom-rule',
  templateUrl: './custom-rule.component.html',
  styleUrls: ['./custom-rule.component.scss'],
})
export class CustomRuleComponent implements OnInit, OnDestroy {
  @ViewChildren(CustomRuleComponent) customRuleComponent: QueryList<CustomRuleComponent>;
  @Input() parentComparison: ComparisonType;
  @Input() ruleType: ICustomRuleType;
  @Input() customRule: ICustomRule;

  @Output() valid = new EventEmitter<boolean>();

  private _destroy$ = new Subject<void>();

  private _ruleProperty: ICustomRuleProperty;
  get ruleProperty(): ICustomRuleProperty {
    return this._ruleProperty;
  }
  set ruleProperty(value: ICustomRuleProperty) {
    this._ruleProperty = value;
    this.property.patchValue(value?.property);
  }
  form: FormGroup<ControlsOf<Partial<ICustomRule>>> = new FormGroup<ControlsOf<Partial<ICustomRule>>>({});

  get properties(): ICustomRuleProperty[] {
    return this.ruleType.properties.filter(
      p =>
        !(
          this.parentComparison === ComparisonType.MIN ||
          this.parentComparison === ComparisonType.MAX ||
          this.parentComparison === ComparisonType.SUM
        ) ||
        ((this.parentComparison === ComparisonType.MIN ||
          this.parentComparison === ComparisonType.MAX ||
          this.parentComparison === ComparisonType.SUM) &&
          p.type === 'number')
    );
  }

  get property() {
    return this.form.controls.property;
  }

  get comparisons(): ComparisonType[] {
    return ComparisonTypes(this.ruleProperty?.type) ?? [];
  }

  get comparison() {
    return this.form.controls.comparison;
  }

  conclusions: { value: string; selected: boolean }[] = [];
  get conclusion() {
    return this.form.controls.conclusion;
  }

  constructor() {}

  ngOnInit(): void {
    this.form.addControl('property', new FormControl(this.customRule.property, [Validators.required]));
    this.form.addControl('comparison', new FormControl(this.customRule.comparison, [Validators.required]));
    this.form.addControl('conclusion', new FormControl(this.customRule.conclusion));
    this.setRuleProperty(this.property.value);
    this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      Object.assign(this.customRule, {
        property: this.property.value,
        comparison: this.comparison.value,
        conclusion: this.conclusion.value,
      });
      this.valid.emit(this.validateChildren());
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  setRuleProperty(property: string) {
    this.ruleProperty = this.ruleType.properties.first(x => x.property === property);
    this.customRule.type = this.ruleProperty?.type;
    this.comparison.patchValue(this.comparisons.first(c => c === this.comparison.value) ?? null);
    this.conclusion.setValidators(
      this.ruleProperty?.type === 'number' ? measurementValidators(UnitOfMeasure.Imperial) : []
    );
    const conclusions = ((this.conclusion.value as string) ?? '').split('\t');
    this.conclusions =
      this.ruleProperty?.type === 'string'
        ? this.ruleProperty.values.map(v => ({ value: v, selected: conclusions.any(c => c === v) }))
        : [];

    this.concatConclusion();
    this.customRule.subRules =
      this.ruleProperty?.type === 'array'
        ? this.customRule.subRules?.any()
          ? this.customRule.subRules
          : [
              {
                property: null,
                comparison: null,
                conclusion: null,
                andOr: true,
                prefix: this.ruleProperty.ruleType.prefix,
                type: null,
              },
            ]
        : [];
  }

  concatConclusion() {
    this.conclusion.patchValue(
      this.ruleProperty?.type === 'string'
        ? this.conclusions
            .filter(c => c.selected)
            .map(c => c.value)
            .join('\t')
        : this.conclusion.value
    );
  }

  validateChildren(): boolean {
    if (!this.customRuleComponent) {
      return this.form.valid;
    }
    return this.customRuleComponent.toArray().all(x => x.validateChildren()) && this.form.valid;
  }

  addRule() {
    this.customRule.subRules.push({
      property: null,
      comparison: null,
      conclusion: null,
      andOr: true,
      prefix: this.ruleProperty.ruleType.prefix,
      type: null,
    });
  }

  deleteRule(rule: ICustomRule) {
    this.customRule.subRules.remove(rule);
  }
}
