import { Type } from '@angular/core';
import { CSSPropKey, WritableKeys } from '@oeo/common';

export {};

const styleKeys = [
  'fill',
  'stroke',
  'stroke-width',
  'fill-opacity',
  'stroke-opacity',
  'stroke-dasharray',
  'font-size',
  'font-family',
];

declare global {
  interface SVGElement {
    root<T>(type: Type<T>): T;
    applyStyles(): void;
    minX(): number;
    maxX(textWidth: number): number;
    minY(textHeight: number): number;
    maxY(): number;
    flip(characterWidth: number, offset: number): void;
    activateCss(): void;
    deactivateCss(): void;
  }
}

function root<T>(this: SVGElement, type: Type<T>) {
  let element = this.parentNode;
  while (!(element instanceof type)) {
    element = element.parentNode as SVGElement;
  }
  return element as T;
}

function applyStyles(this: SVGElement): void {
  const computedStyles = window.getComputedStyle(this);
  Array.from(computedStyles).forEach((key: string) => {
    if (styleKeys.includes(key)) {
      this.style[key as CSSPropKey] = computedStyles.getPropertyValue(key);
    }
  });
  for (const element of Array.from(this.children)) {
    (element as SVGElement).applyStyles();
  }
}

function minY(this: SVGElement, textHeight: number): number {
  if (this instanceof SVGRectElement) {
    return this.y.baseVal.value;
  } else if (this instanceof SVGLineElement) {
    return this.y1.baseVal.value < this.y2.baseVal.value ? this.y1.baseVal.value : this.y2.baseVal.value;
  } else if (this instanceof SVGPolylineElement) {
    return Array.from(this.points).min(_ => _.y);
  } else if (this instanceof SVGCircleElement) {
    return this.cy.baseVal.value - this.r.baseVal.value;
  } else if (this instanceof SVGEllipseElement) {
    return this.cy.baseVal.value - this.ry.baseVal.value;
  } else if (this instanceof SVGTextElement || this instanceof SVGTSpanElement) {
    return this.children.length === 0 ? (this.y.baseVal[0]?.value ?? 0) - textHeight : 0;
  } else if (this instanceof SVGGElement) {
    return Array.from(this.children).min((_: SVGElement) => _.minY(textHeight)) ?? 0;
  } else {
    return 0;
  }
}

function maxY(this: SVGElement): number {
  if (this instanceof SVGRectElement) {
    return this.y.baseVal.value + this.height.baseVal.value;
  } else if (this instanceof SVGLineElement) {
    return this.y1.baseVal.value > this.y2.baseVal.value ? this.y1.baseVal.value : this.y2.baseVal.value;
  } else if (this instanceof SVGPolylineElement) {
    return Array.from(this.points).max(_ => _.y);
  } else if (this instanceof SVGCircleElement) {
    return this.cy.baseVal.value + this.r.baseVal.value;
  } else if (this instanceof SVGEllipseElement) {
    return this.cy.baseVal.value + this.ry.baseVal.value;
  } else if (this instanceof SVGTextElement || this instanceof SVGTSpanElement) {
    return this.children.length === 0 ? this.y.baseVal[0]?.value ?? 0 : 0;
  } else if (this instanceof SVGGElement) {
    return Array.from(this.children).max((_: SVGElement) => _.maxY()) ?? 0;
  } else {
    return 0;
  }
}

function minX(this: SVGElement): number {
  if (this instanceof SVGRectElement) {
    return this.x.baseVal.value;
  } else if (this instanceof SVGLineElement) {
    return this.x1.baseVal.value < this.x2.baseVal.value ? this.x1.baseVal.value : this.x2.baseVal.value;
  } else if (this instanceof SVGPolylineElement) {
    return Array.from(this.points).min(_ => _.x);
  } else if (this instanceof SVGCircleElement) {
    return this.cx.baseVal.value - this.r.baseVal.value;
  } else if (this instanceof SVGEllipseElement) {
    return this.cx.baseVal.value - this.rx.baseVal.value;
  } else if (this instanceof SVGTextElement || this instanceof SVGTSpanElement) {
    return this.children.length === 0 ? this.x.baseVal[0]?.value ?? 0 : 0;
  } else if (this instanceof SVGGElement) {
    return Array.from(this.children).min((_: SVGElement) => _.minX()) ?? 0;
  } else {
    return 0;
  }
}

function maxX(this: SVGElement, textWidth: number): number {
  if (this instanceof SVGRectElement) {
    return this.x.baseVal.value + this.width.baseVal.value;
  } else if (this instanceof SVGLineElement) {
    return this.x1.baseVal.value > this.x2.baseVal.value ? this.x1.baseVal.value : this.x2.baseVal.value;
  } else if (this instanceof SVGPolylineElement) {
    return Array.from(this.points).max(_ => _.x);
  } else if (this instanceof SVGCircleElement) {
    return this.cx.baseVal.value + this.r.baseVal.value;
  } else if (this instanceof SVGEllipseElement) {
    return this.cx.baseVal.value + this.rx.baseVal.value;
  } else if (this instanceof SVGTextElement || this instanceof SVGTSpanElement) {
    return this.children.length === 0 ? (this.x.baseVal[0]?.value ?? 0) + this.textContent.length * textWidth : 0;
  } else if (this instanceof SVGGElement) {
    return Array.from(this.children).max((_: SVGElement) => _.maxX(textWidth)) ?? 0;
  } else {
    return 0;
  }
}

function flipChildren(element: SVGElement, characterWidth: number) {
  const children = Array.from(element.children);
  for (const child of children.map(c => c as SVGElement)) {
    if (child instanceof SVGTextElement) {
      child.setAttribute(
        'transform',
        `scale(-1,1) translate(${
          -parseFloat(child.getAttribute('x')) * 2 - child.textContent.length * characterWidth
        },0)`
      );
    }
    if (child instanceof SVGForeignObjectElement) {
      child.setAttribute(
        'transform',
        `scale(-1,1) translate(${-parseFloat(child.getAttribute('x')) * 2 - parseFloat(child.getAttribute('width'))},0)`
      );
    }
    if (child instanceof SVGGElement) {
      flipChildren(child, characterWidth);
    }
    if (child instanceof SVGUseElement) {
      child.setAttribute(
        'transform',
        `scale(-1,1) translate(${-parseFloat(child.getAttribute('x')) * 2 - child.getBBox().width},0)`
      );
    }
  }
}

function flip(this: SVGElement, characterWidth: number, offset: number) {
  this.setAttribute('transform', `scale(-1,1) translate(${offset},0)`);
  flipChildren(this, characterWidth);
}

function activateCss(this: SVGElement): void {
  this.classList.add('active');
  for (const child of Array.from(this.children)) {
    (child as SVGElement).activateCss();
  }
}

function deactivateCss(this: SVGElement): void {
  this.classList.remove('active');
  for (const child of Array.from(this.children)) {
    (child as SVGElement).deactivateCss();
  }
}

SVGElement.prototype.root = root;
SVGElement.prototype.applyStyles = applyStyles;
SVGElement.prototype.minX = minX;
SVGElement.prototype.maxX = maxX;
SVGElement.prototype.minY = minY;
SVGElement.prototype.maxY = maxY;
SVGElement.prototype.flip = flip;
SVGElement.prototype.activateCss = activateCss;
SVGElement.prototype.deactivateCss = deactivateCss;
