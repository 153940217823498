import { DoorCore } from '../enums/door-core';
import { DoorGlassInstallation } from '../enums/door-glass-installation';
import { DoorLouverInstallation } from '../enums/door-louver-installation';
import { DoorSteelStiffen } from '../enums/door-steel-stiffen';

export function steelStiffenCores(cores: DoorCore[], steelStiffen: DoorSteelStiffen) {
  if (steelStiffen !== DoorSteelStiffen.NONE) {
    [...cores]
      .filter(c => c !== DoorCore.FIB && c !== DoorCore.PS && c !== DoorCore.PU && c !== DoorCore.LL)
      .forEach(c => cores.remove(c));
  }
  if (steelStiffen === DoorSteelStiffen.NONE) {
    cores.remove(DoorCore.FIB);
  }
}

export function steelStiffenGlassInstallations(
  glassInstallations: DoorGlassInstallation[],
  steelStiffen: DoorSteelStiffen
) {
  if (steelStiffen !== DoorSteelStiffen.NONE) {
    [...glassInstallations]
      .filter(i => i !== DoorGlassInstallation.CKIR && i !== DoorGlassInstallation.CR)
      .forEach(i => glassInstallations.remove(i));
  }
}

export function steelStiffenLouverInstallations(
  louverInstallations: DoorLouverInstallation[],
  steelStiffen: DoorSteelStiffen
) {
  if (steelStiffen !== DoorSteelStiffen.NONE) {
    [...louverInstallations].filter(i => i !== DoorLouverInstallation.CR).forEach(i => louverInstallations.remove(i));
  }
}
