import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';

export interface IElecPrep extends IPrep {
  quantity: number;
  junctionBox: string;
}

export class ElecPrep extends Prep implements IElecPrep {
  quantity: number;
  junctionBox: string;

  properties(): { [key: string]: (value: string) => any } {
    const obj = super.properties();
    obj[nameOf((_: IElecPrep) => _.quantity)] = (value: string) => parseInt(value, 10);
    obj[nameOf((_: IElecPrep) => _.junctionBox)] = (value: string) => value;
    obj[nameOf((_: IPrep) => _.location)] = (value: string) => value?.split(',') ?? [];
    return obj;
  }
}
