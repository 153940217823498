import { AdditionalLabel } from './additional-label';

export enum Approval {
  C_STC42 = 'Construction Only No Label STC42',
  C_STC50 = 'Construction Only No Label STC50',
  DR_1000 = 'DR-1000 DL416 Louvr 25x66 Outs 4080/8080 60psf',
  DR_1001 = 'DR-1001 DL416 Flush Outswg 4080/8080 70psf',
  DR_1002 = 'DR-1002 DL418 Flush/Emboss Inswing 3070 80psf',
  DR_1003 = 'DR-1003 DP418 Single Embossed 3080 70psf',
  DR_551 = 'DR-551 DL418 Storefront Outsw 4080/8080 50psf No HVHZ',
  DR_998 = 'DR-998 DL416 Glzed Outswg 3070/6070 75psf',
  DR_999 = 'DR-999 DL418 Flush Outswing 60psf',
  FL_10356_1 = 'FL-10356.1 Non-Impact - Double Flush Inswing/Outswing',
  FL_10356_2 = 'FL-10356.2 Non-Impact - Double Louver Inswing/Outswing',
  FL_10356_3 = 'FL-10356.3 Non-Impact - Single Flush Inswing/Outswing',
  FL_10356_4 = 'FL-10356.4 Non-Impact - Double Glazed Inswing/Outswing',
  FL_10356_5 = 'FL-10356.5 Non-Impact - Single Louver Inswing/Outswing',
  FL_10356_6 = 'FL-10356.6 Non-Impact - Single Glazed Inswing/Outswing',
  FL_12400_1 = 'FL-28851.1 Double Outswing Systems with Louvers 35.7x76, max 8080 60psf',
  FL_12400_2 = 'FL-28855.1 Double Flush Outswing mort/cyl/rim/svr/cvr/3-pt 55-90psf',
  FL_12400_3 = 'FL-28855.2 Double Glazed Outswing mort/cyl/rim/svr/cvr/3-pt 50-90psf',
  FL_12400_4 = 'FL-28855.3 Single Flush Outswing mort/cyl/rim/svr/cvr/3-pt 55-100PSF',
  FL_12400_6 = 'FL_12400_6 Single Outswing Systems with Louvers 35.7x76, max 4080 60pSF',
  FL_14022_1 = 'FL-28856.1 WS98/9927 Single/Double Panic Level E 150pSF (75 with water infiltration)',
  FL_1592_1 = 'FL-28852.1 Transom SLt 3070/6070 60psf, 4070 50 PSF',
  FL_16704_1 = 'FL-30442.1 CVC VD 98-9949, 3349 Outswing ',
  FL_16704_2 = 'FL-30442.2 Mortise Falcon MA Outswing ',
  FL_16704_3 = 'FL-30442.3 LM9300 3-Pt Level E 107PSF Inswing/Ouswing ',
  FL_16704_4 = 'FL-30442.4 HCA 6x30max light 7870 max VD 98/9927, 33/3549 exit 70psf',
  FL_16740_1 = 'FL-16740.1 Single/Double Flush with Mortise Falcon MA 4080/8080 50psf',
  FL_16740_2 = 'FL-16740.2 Single/Double Flush with CVC exit 98/9949, 33/3549 4080/8080 75psf',
  FL_16740_3 = 'FL-16740.3 (N/A; see Steelcraft)',
  FL_16740_4 = 'FL-16740.4 Single/Double Glazed with SVR exit 98/9927 70psf, N6-6x30, 7870 max HCA',
  FL_20736_1 = 'FL-20736.1 Outswing pair 8080 Positive Lok panic exit',
  FL_20736_2 = 'FL-20736.2 Outswing single 4080 Positive Lok panic exit',
  L1BRD = 'Level 1 Bullet Resist *Label*',
  L2BRD = 'Level 2 Bullet Resist *Label*',
  L3BRD = 'Level 3 Bullet Resist *Label*',
  L4BRD = 'Level 4 Bullet Resist *Label*',
  L5BRD = 'Level 5 Bullet Resist *Label*',
  L6BRD = 'Level 6 Bullet Resist *Label*',
  L7BRD = 'Level 7 Bullet Resist *Label*',
  L8BRD = 'Level 8 Bullet Resist *Label*',
  STC40 = 'STC40',
  STC42 = 'STC42',
  STC43 = 'STC43',
  STC47 = 'STC47',
  STC48 = 'STC48',
  STC50 = 'STC50',
  STC52 = 'STC52',
  SMOKE = 'Positive Pressure w/ Smoke',
}

export function filterApprovals(additionalLabel: AdditionalLabel): Approval[] {

  switch (additionalLabel) {
    case AdditionalLabel.E330:
    case AdditionalLabel.E330PP:
    case AdditionalLabel.E330SMOKE:
      return [
        Approval.FL_10356_1,
        Approval.FL_10356_2,
        Approval.FL_10356_3,
        Approval.FL_10356_4,
        Approval.FL_10356_5,
        Approval.FL_10356_6,
      ];
    case AdditionalLabel.BULLET:
      return [
        Approval.L1BRD,
        Approval.L2BRD,
        Approval.L3BRD,
        Approval.L4BRD,
        Approval.L5BRD,
        Approval.L6BRD,
        Approval.L7BRD,
        Approval.L8BRD,
      ];
    case AdditionalLabel.FBC:
      return [
        Approval.FL_12400_1,
        Approval.FL_12400_2,
        Approval.FL_12400_3,
        Approval.FL_12400_4,
        Approval.FL_12400_6,
        Approval.FL_14022_1,
        Approval.FL_16740_1,
        Approval.FL_16740_2,
        Approval.FL_16740_3,
        Approval.FL_16740_4,
        Approval.FL_20736_2,
      ];
    case AdditionalLabel.STCEMB: {
      return [Approval.STC40, Approval.STC42];
    }
    case AdditionalLabel.STC: {
      return [
        Approval.C_STC42,
        Approval.STC40,
        Approval.STC42,
        Approval.STC43,
        Approval.STC47,
        Approval.STC48,
        Approval.STC50,
        Approval.C_STC50,
        Approval.STC52,
      ];
    }
    case AdditionalLabel.TDI: {
      return [
        Approval.DR_1000,
        Approval.DR_1001,
        Approval.DR_1002,
        Approval.DR_1003,
        Approval.DR_551,
        Approval.DR_998,
        Approval.DR_999,
      ];
    }
    case AdditionalLabel.SMOKE: {
      return [Approval.SMOKE];
    }
    case AdditionalLabel.PP: {
      return [];
    }
    default:
      return [];
  }
}
