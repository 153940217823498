export enum DoorSeamlessEdge {
  VBLE = 'Visible Edge',
  VBLE_TOP = 'Visible Edge - Epoxy Top',
  VBLE_TOPBTM = 'Visible Edge - Epoxy Top and Bottom',
  INT = 'Bondo Filled',
  INT_TOP = 'Bondo Filled - Epoxy Top',
  INT_TOPBTM = 'Bondo Filled - Epoxy Top and Bottom',
  CONT = 'Continuous Weld',
  CONT_TOP = 'Continuous Weld - Epoxy Top',
  CONT_TOPBTM = 'Continuous Weld - Epoxy Top and Bottom',
}

export const VisibleSeamlessEdges = [DoorSeamlessEdge.VBLE, DoorSeamlessEdge.VBLE_TOP, DoorSeamlessEdge.VBLE_TOPBTM];
export const IntermittentSeamlessEdges = [DoorSeamlessEdge.INT, DoorSeamlessEdge.INT_TOP, DoorSeamlessEdge.INT_TOPBTM];
export const ContinuousSeamlessEdges = [DoorSeamlessEdge.CONT, DoorSeamlessEdge.CONT_TOP, DoorSeamlessEdge.CONT_TOPBTM];
