import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { Profile } from '../../abstracts/profile';
import { ProfileTemplate, ProfileTemplateDimensionPosition } from '../../abstracts/profile-template';
import { Direction } from '../../enums/direction';
import { GlassExport } from '../../models/exports/glass-export';

export class MUCOS extends ProfileTemplate {
  private _a: number;
  private _b: number;
  private _f: number;
  private _g: number;

  get stopHeight1() {
    return NaN;
  }
  get stopHeight2() {
    return NaN;
  }
  get backBend1() {
    return this.dimensions.B.get();
  }
  get backBend2() {
    return this.dimensions.F.get();
  }
  get doorSideRabbet1() {
    return NaN;
  }
  get oppositeDoorSideRabbet1() {
    return NaN;
  }
  get doorSideRabbet2() {
    return NaN;
  }
  get oppositeDoorSideRabbet2() {
    return NaN;
  }

  readonly dimensions = {
    A: {
      direction: Direction.Left,
      get: () => this._a,
      position: () => ProfileTemplateDimensionPosition.Below,
    },
    B: {
      direction: Direction.Up,
      get: () => this._b,
      set: (value: number) => {
        this._b = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.Left,
    },
    C: {
      direction: Direction.Right,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    D: {
      direction: Direction.Down,
      get: () => this.profile.jambDepth,
      position: () => ProfileTemplateDimensionPosition.MaxX,
    },
    E: {
      direction: Direction.Left,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MaxY,
      doorSide: true,
    },
    F: {
      direction: Direction.Up,
      get: () => this._f,
      set: (value: number) => {
        this._f = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.Left,
    },
    G: {
      direction: Direction.Right,
      get: () => this._g,
      position: () => ProfileTemplateDimensionPosition.Above,
    },
  };

  constructor(profile: Profile, glazingBeads: GlassExport[]) {
    super(profile, glazingBeads);
    this._b = this._f = this.defaultBackbend;
    this._a = this._g = this.defaultReturn;
    this.loadDimensions();
  }
}
