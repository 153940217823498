import { HelperService } from '../services/helper/helper.service'
import { QuantityToOrderHardwareSetDoorMarking } from './door-marking'
import { ProductCategories } from './enums'
import { EstimateHardwareSetDoorMarking } from './estimate-hardware-set-door-marking.model'
import { EstimateHardwareSet } from './estimateHardwareSet.model'
import { EstimateProductAttachment } from './estimateProduct.model'
import { HardwareSet } from './hardwareSet.model'

export class FlattenedHardwareSet extends HardwareSet {
  quantity: number
  quantityToOrder!: number
  id: number
  extNetPrice?: number
  estimateHardwareSetDoorMarkings: EstimateHardwareSetDoorMarking[]
  doorMarkingsDisplayString?: string
  estimateProductAttachments?: EstimateProductAttachment[]
  hasHollowMetalProducts?: boolean
  selected?: boolean
  constructor(hwSet: EstimateHardwareSet) {
    super()
    this.hardwareSetId = hwSet.hardwareSet.hardwareSetId
    this.quantity = hwSet.quantity
    this.id = hwSet.id
    this.listPrice = hwSet.hardwareSet.listPrice
    this.netPrice = hwSet.hardwareSet.netPrice
    this.extNetPrice = this.netPrice * hwSet.quantity
    this.isSystemSet = hwSet.hardwareSet.isSystemSet
    this.products = hwSet.hardwareSet.products
    this.name = hwSet.hardwareSet.name
    this.estimateHardwareSetDoorMarkings = hwSet.estimateHardwareSetDoorMarkings
    this.estimateProductAttachments = hwSet.estimateProductAttachments
    this.hasHollowMetalProducts = checkForHasHollowMetalProducts(hwSet)

    if (hwSet.estimateHardwareSetDoorMarkings?.length) {
      this.doorMarkingsDisplayString = HelperService.prototype.formatDoorMarking(
        hwSet.estimateHardwareSetDoorMarkings
      ).displayValue
    }
  }
}

export class QuantityFlattenedHardwareSet extends FlattenedHardwareSet {
  quantityToOrderDoorMarks!: QuantityToOrderHardwareSetDoorMarking[]
  showIcon: boolean = true
}

export class ExpandedQuantityFlattenedHardwareSet extends QuantityFlattenedHardwareSet {
  marks_disabled?: boolean
}

function checkForHasHollowMetalProducts(hwSet: EstimateHardwareSet): boolean {
  if (!hwSet.hardwareSet.products?.length) {
    return false
  }
  return hwSet.hardwareSet.products.some((product) => {
    return (
      product.category === ProductCategories.frame ||
      product.category === ProductCategories.door ||
      product.category === ProductCategories.elevation
    )
  })
}
