import { AbstractControl, ValidatorFn } from '@angular/forms'
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure'
import { Subject } from 'rxjs'
import { ActivatedRoute } from '@angular/router'
import { PreferenceService } from '../../../core/services/preference.service'
import { DoorElevationFormGroup } from '../../models/door-elevation-form-group'

export abstract class DoorElevationCommonSettings {
  abstract _destroy$: Subject<void>
  doorElevationFormGroup: DoorElevationFormGroup

  constructor(private route: ActivatedRoute, private preferenceService: PreferenceService) {}

  updateForm<T>(control: AbstractControl<T>, value: T): void {
    if (control.value === value) {
      return
    }
    control.patchValue(value)
    control.markAsTouched()
    control.updateValueAndValidity()
  }

  widthValidators(unitOfMeasure: UnitOfMeasure): ValidatorFn[] {
    return []
  }

  required(control: AbstractControl): boolean {
    return !!control.validator && control.validator({} as AbstractControl).required
  }
}
