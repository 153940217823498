import { Product } from './product.model'
import { ValuePacVolumePrice } from './valuePacVolumePrice.model'
export class HollowMetalStockProduct extends Product {
  series: string
  shipFromLocationIds: number[]
  valuePacQuantity?: number
  valuePacPrice?: number
  valuePacVolumePrice?: ValuePacVolumePrice

  constructor(product: Product) {
    super()
    Object.assign(this, product)
    for (const key in product) {
      if (product.hasOwnProperty(key) && key === 'hollowMetalStockProduct') {
        this.series = product[key].series
        this.shipFromLocationIds = product[key].shipFromLocationIds
        this.valuePacQuantity = product[key].valuePacQuantity
        this.valuePacPrice = product[key].valuePacPrice
        this.valuePacVolumePrice = product[key].valuePacVolumePrice
      }
    }
  }
}

export class ExpandedHollowMetalStockProduct extends HollowMetalStockProduct {
  _expandable: boolean
  selected: boolean = false
  constructor(product: HollowMetalStockProduct) {
    super(product)
    this._expandable = !!this.valuePacVolumePrice
  }
}
