import { Astragal } from '../astragal';

export function Astragals(active: boolean, isDPSeries: boolean, single: boolean, isDutch: boolean): Astragal[] {
  if(single) {
    if(!isDutch) return []
    if(active) return [Astragal.FA]
    return [Astragal.FI]
  }
  if (active) {
    return [Astragal.FA]
  } else if (isDPSeries) {
    return [Astragal.Z]
  } else {
    return [Astragal.Z, Astragal.FI]
  }
}
