<div class="top">
  <mat-form-field class="no-hint">
    <mat-label>{{'FORMFIELDS.location' | translate}}</mat-label>
    <input
      #input
      matInput
      (keyup.enter)="addNewLocation()"
      [placeholder]="'newHardwareLocation' | translate"
      name="location"
      [(ngModel)]="location.value"
    />
  </mat-form-field>
  <div class ="col">
    <button class="mat-flat-button" mat-flat-button color="primary" [disabled]="!location.value" (click)="addNewLocation()">Add</button>
  </div>
</div>
<div class="main">
  <div class="location" *ngFor="let l of category.prepCategoryLocations">
    <span>{{ l.value }}</span>
    <button mat-icon-button color="warn" (click)="deleteLocation(l)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
