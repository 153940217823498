import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IPrepCategory } from '../interfaces/i-prep-category';
import { IPrepCategoryCode } from '../interfaces/i-prep-category-code';
import { IPrepCategoryCodeService } from '../interfaces/i-prep-category-code.service';

@Injectable()
export class DoorPrepCategoryCodeService implements IPrepCategoryCodeService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  create(prepCategoryId: number, prepCodeId: number): Observable<void> {
    return this.http.post<void>(`${this.environment.elevations.apiUrl}v1/doorPrepCategoryCodes`, {
      prepCategoryId,
      prepCodeId,
    });
  }

  delete(prepCategoryId: number, prepCodeId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.environment.elevations.apiUrl}v1/doorPrepCategoryCodes?prepCategoryId=${prepCategoryId}&prepCodeId=${prepCodeId}`
    );
  }
}
