import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UnitOfMeasure } from '../enums/unit-of-measure';
import { IDimension } from '../interfaces/i-dimension';
import { createSVGElement, setHeight, setWidth, setY, setX, addClass, createHTMLElement, setNameSpace, NameSpaces } from './svg-functions';
import { pipe } from 'lodash/fp';
export class DimensionInputMaker {
  constructor(private inputWidth: number, private inputHeight: number) {}

  create(
    x: number,
    y: number,
    dimension: IDimension,
    dimensionName: string,
    destroy$: Subject<void>,
    fontSize?: number
  ): SVGForeignObjectElement {
    const dimensionValue = dimension.dimensions[dimensionName];
    const foreignObject = pipe(
      setX(x),
      setY(y),
      setHeight(this.inputHeight),
      setWidth(this.inputWidth),
      addClass('foreign-object')
    )(createSVGElement('foreignObject'));

    const div = pipe(setNameSpace(NameSpaces.XHTML))(createHTMLElement('div'));

    const input = document.createElement('input');
    // input.tabIndex = 1000;
    input.value = dimensionValue.get().toDimension(dimension.dimensionType, dimension.unitOfMeasure, false);
    if (dimensionValue.set == null) {
      input.disabled = true;
      input.classList.add('disabled-input');
    }
    if (dimensionValue.hint) {
      input.title = dimensionValue.hint;
    }
    if (fontSize) {
      input.style.fontSize = `${fontSize}px`;
    }
    fromEvent(input, 'mousedown')
      .pipe(takeUntil(destroy$))
      .subscribe(e => {
        foreignObject.parentNode.appendChild(foreignObject);
        e.stopPropagation();
      });

    fromEvent(input, 'mouseup')
      .pipe(takeUntil(destroy$))
      .subscribe(e => {
        e.stopPropagation();
      });

    fromEvent(input, 'mousemove')
      .pipe(takeUntil(destroy$))
      .subscribe(e => {
        e.stopPropagation();
      });

    fromEvent(input, 'focusout')
      .pipe(takeUntil(destroy$))
      .subscribe(_ => {
        const val = input.value;
        let measure = '';
        if (val && val.trim().length) {
          switch (dimension.unitOfMeasure) {
            case UnitOfMeasure.Imperial: {
              const measurement = [...val]
                .skip(val.length - 1)
                .take(1)
                .join('');
              if (measurement !== `'` && measurement !== `"`) {
                measure = '"';
              }
              break;
            }
            case UnitOfMeasure.Metric: {
              measure = 'mm';
              break;
            }
          }
          input.value = `${val.trim()}${measure}`;
        }

        const value = input.value.fromDimension(dimension.dimensionType, dimension.unitOfMeasure);
        if (value !== dimensionValue.get()) {
          dimensionValue.set(value);
          if (dimensionValue.get() !== input.value.fromDimension(dimension.dimensionType, dimension.unitOfMeasure)) {
            dimension.update();
          }
        }
      });

    fromEvent(input, 'keyup')
      .pipe(takeUntil(destroy$))
      .subscribe((e: KeyboardEvent) => {
        //input cleanup of unwanted characters
        if (e.code != 'Delete' && e.code != 'Backspace') {
          switch (dimension.unitOfMeasure) {
            case UnitOfMeasure.Imperial: {
              input.value = input.value.replace(/[^0-9, /, ., ']/g, ''); //allow only numeric, period, forward slash & single quote
              break;
            }
            case UnitOfMeasure.Metric: {
              input.value = input.value.replace(/[^0-9]/g, ''); //allow only numeric
              break;
            }
          }
        }

        if (e.code === 'Enter') {
          dimensionValue.set(input.value.fromDimension(dimension.dimensionType, dimension.unitOfMeasure));
          if (dimensionValue.get() !== input.value.fromDimension(dimension.dimensionType, dimension.unitOfMeasure)) {
            dimension.update();
          }
        }
      });
    div.appendChild(input);
    foreignObject.appendChild(div);
    return foreignObject;
  }
}
