import { Approval } from '../../core/enums/approval';
import { DoorFireRating } from '../enums/door-fire-rating';
import { DoorSubType } from '../enums/door-subtype';

export function subTypeFireRatings(fireRatings: DoorFireRating[], subType: DoorSubType) {
  if (subType === DoorSubType.LL) {
    [...fireRatings].forEach(f => fireRatings.remove(f));
  }
}

export function subTypeApprovals(approvals: Approval[], subType: DoorSubType,  isPair: boolean) {
  if (isPair || ![DoorSubType.STD, DoorSubType.INS].includes(subType)) {
    approvals.remove(Approval.FL_10356_3);
    approvals.remove(Approval.FL_10356_6);
    approvals.remove(Approval.FL_10356_5);
  }
  if(!isPair || ![DoorSubType.STD, DoorSubType.INS].includes(subType)) {
    approvals.remove(Approval.FL_10356_1);
    approvals.remove(Approval.FL_10356_2);
    approvals.remove(Approval.FL_10356_4);
  }
  if(subType === DoorSubType.IMP){
    approvals.remove(Approval.FL_12400_4);
  }
  return approvals;
}
