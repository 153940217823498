import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filterProduct: string;
  @Output() filterProductChange: EventEmitter<'Door' | 'Frame'> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  filterChange(fp: 'Door' | 'Frame') {
    this.filterProductChange.emit(fp);
  }
}
