import { UnitOfMeasure } from '../enums/unit-of-measure';
import { DoorThickness } from '../../door-elevation/enums/door-thickness';
import { FrameDoorThickness } from '../../frame-elevation/enums/frame-door-thickness';
import { GlazingBeadSize } from '../../frame-elevation/enums/glazing-bead-size';
import { FrameJambDepth } from '../../frame-elevation/enums/frame-jamb-depth';

export const jambDepthRegex = /(.*").\((.*)\)/;

export function convertImperialMetricEnum(
  jambDepth: FrameJambDepth | GlazingBeadSize | DoorThickness | FrameDoorThickness,
  type: 'door' | 'frame',
  unitOfMeasure: UnitOfMeasure
): number {
  const matches = jambDepthRegex.exec(jambDepth);
  return (unitOfMeasure === UnitOfMeasure.Imperial ? matches[1] : matches[2]).fromDimension(type, unitOfMeasure);
}
