import { Observable, OperatorFunction, filter } from 'rxjs'
import { ITableEvent } from '../types/table.model'

export function filterEventByTableName<T extends ITableEvent>(
  tableNames: ITableEvent['tableName'] | Array<ITableEvent['tableName']>
): OperatorFunction<T, T> {
  return (source$: Observable<T>) => {
    return source$.pipe(
      filter((tableAction) => {
        if (Array.isArray(tableNames)) return tableNames.includes(tableAction.tableName)
        return tableAction.tableName === tableNames
      })
    )
  }
}
