import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';

export interface IDoorIntPivotPrep extends IPrep {
  quantity: number;
}

export class DoorIntPivotPrep extends Prep implements IDoorIntPivotPrep {
  quantity: number;

  properties(): Record<string, ((value: string) => any)> {
    const obj = super.properties();
    obj[nameOf((_: DoorIntPivotPrep) => _.quantity)] = (value: string) => parseInt(value, 10);
    return obj;
  }
}
