import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { StepperComponent } from '@oeo/common'
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs'
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../../../core/interfaces/i-environment'
import { PreferenceService } from '../../../../core/services/preference.service'
import { DoorElevation } from '../../../models/door-elevation'
import { DoorElevationFormGroup, DoorFormGroup } from '../../../models/door-elevation-form-group'
import { DoorElevationConfigService } from '../../../services/door-elevation-config-service/door-elevation-config.service'
import { DoorElevationCommonSettings } from '../door-elevation-common-settings'
import { IStep } from '../../../../core/interfaces/i-step'

@Component({
  selector: 'lib-door-elevation-pair-settings',
  templateUrl: './door-elevation-pair-settings.component.html',
  styleUrls: ['./door-elevation-pair-settings.component.scss'],
  providers: []
})
export class DoorElevationPairSettingsComponent extends DoorElevationCommonSettings implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>()

  @ViewChild(StepperComponent) stepper: StepperComponent<IStep>
  step = 0

  constructor(
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment,
    preferenceService: PreferenceService,
    route: ActivatedRoute,
    public configService: DoorElevationConfigService
  ) {
    super(route, preferenceService)
    this.step = this.configService.steps[configService.step].subStep
  }

  ngOnInit(): void {
    this.setupForm()
  }

  setupForm() {
    this.doorElevationFormGroup = new DoorElevationFormGroup(this.configService.doorElevation, this.widthValidators)
    this.doorElevationFormGroup.doorFormGroups = [
      new DoorFormGroup(this.configService.doorElevation.doors[0], this.doorElevationFormGroup, 'Left'),
      new DoorFormGroup(this.configService.doorElevation.doors[1], this.doorElevationFormGroup, 'Right')
    ]
    this.doorElevationFormGroup.setupValidations()

    if (this.step === 0) {
      Object.keys(this.doorElevationFormGroup.controls).forEach((key) =>
        this.doorElevationFormGroup.get(key).updateValueAndValidity()
      )
    }
    if (this.step === 1) {
      /* Initialize the width of the left door - of which a user can change */
      if (
        !this.doorElevationFormGroup.doorFormGroups[0].width.value ||
        this.doorElevationFormGroup.doorFormGroups[0].width.value.fromDimension(
          'door',
          this.doorElevationFormGroup.unitOfMeasure.value
        ) === 0
      ) {
        this.doorElevationFormGroup.doorFormGroups[0].width.patchValue(
          (
            this.doorElevationFormGroup.width.value.fromDimension(
              'door',
              this.doorElevationFormGroup.unitOfMeasure.value
            ) / 2
          ).toDimension('door', this.doorElevationFormGroup.unitOfMeasure.value)
        )
      }
      Object.keys(this.doorElevationFormGroup.doorFormGroups[0].controls).forEach((key) =>
        this.doorElevationFormGroup.doorFormGroups[0].get(key).updateValueAndValidity()
      )
    }
    if (this.step === 2) {
      this.doorElevationFormGroup.doorFormGroups[1].width.patchValue(
        (
          this.doorElevationFormGroup.width.value.fromDimension(
            'door',
            this.doorElevationFormGroup.unitOfMeasure.value
          ) -
          this.doorElevationFormGroup.doorFormGroups[0].width.value.fromDimension(
            'door',
            this.doorElevationFormGroup.unitOfMeasure.value
          )
        ).toDimension('door', this.doorElevationFormGroup.unitOfMeasure.value)
      )
      if(this.doorElevationFormGroup.doorFormGroups[0].valid){
        this.doorElevationFormGroup.doorFormGroups[1].width.disable()
      }

      this.doorElevationFormGroup.doorFormGroups[1].style.patchValue(
        this.doorElevationFormGroup.doorFormGroups[1].styles.first(
          (s) =>
            s ===
            (this.doorElevationFormGroup.doorFormGroups[1].style.value ??
              this.doorElevationFormGroup.doorFormGroups[0].style.value)
        ) ?? null
      )

      this.doorElevationFormGroup.doorFormGroups[1].edge.patchValue(
        this.doorElevationFormGroup.doorFormGroups[1].edge.value ??
          this.doorElevationFormGroup.doorFormGroups[0].edge.value
      )
      this.doorElevationFormGroup.doorFormGroups[1].glassInstallation.patchValue(
        this.doorElevationFormGroup.doorFormGroups[1].glassInstallation.value ??
          this.doorElevationFormGroup.doorFormGroups[0].glassInstallation.value
      )
      this.doorElevationFormGroup.doorFormGroups[1].glassThickness.patchValue(
        this.doorElevationFormGroup.doorFormGroups[1].glassThickness.value ??
          this.doorElevationFormGroup.doorFormGroups[0].glassThickness.value
      )
      this.doorElevationFormGroup.doorFormGroups[1].louverInstallation.patchValue(
        this.doorElevationFormGroup.doorFormGroups[1].louverInstallation.value ??
          this.doorElevationFormGroup.doorFormGroups[0].louverInstallation.value
      )
      Object.keys(this.doorElevationFormGroup.doorFormGroups[1].controls).forEach((key) =>
        this.doorElevationFormGroup.doorFormGroups[1].get(key).updateValueAndValidity()
      )
      if (this.doorElevationFormGroup.doubleEgress.value) {
        this.doorElevationFormGroup.doorFormGroups[1].handing.patchValue(
          this.doorElevationFormGroup.doorFormGroups[0].handing.value
        )
      }
    }

    if(this.doorElevationFormGroup.doorFormGroups.length > 1) {
      this.doorElevationFormGroup.doorFormGroups.forEach(form => {
        form.active.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
          this.configService.updatePrepSteps(this.formToElevation())
        })
      })
    }
  }


  previous(): void {
    this.configService.goBack()
  }

  canContinue(): boolean {
    switch (this.step) {
      case 0:
        return this.doorElevationFormGroup.valid
      case 1:
        return this.doorElevationFormGroup.doorFormGroups[0].valid && this.doorElevationFormGroup.valid
      case 2:
        return this.doorElevationFormGroup.doorFormGroups[1].valid && this.doorElevationFormGroup.valid
    }
  }

  continue(): void {
    /* Keep the door elevation up to date with the service */
    this.configService.doorElevation = this.formToElevation()
    return this.configService.goNext()
  }

  formToElevation(): DoorElevation {
    return this.doorElevationFormGroup.toElevation()
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.unsubscribe()
    this.doorElevationFormGroup.destroy()
  }
}
