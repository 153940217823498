import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Tool } from '../../../core/models/tool';

@Component({
  selector: 'lib-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.scss'],
})
export class ToolBarComponent {
  private _buttons: QueryList<HTMLButtonElement>;
  private _tool: Tool;
  iconColor: string = '#fff';
  @Input() tools: Tool[];
  get tool(): Tool {
    return this._tool;
  }
  @Input() set tool(value: Tool) {
    this._tool = value;
    if (!this.buttons) {
      return;
    }
  }
  @Input() help = true;
  @Input() edit = true;
  @Input() settings = true;
  @Input() money = false;
  @Output() tool$ = new EventEmitter<Tool>();
  @Output() edit$ = new EventEmitter<void>();
  @Output() settings$ = new EventEmitter<void>();
  @Output() help$ = new EventEmitter<void>();
  @Output() money$ = new EventEmitter<void>();

  get buttons() {
    return this._buttons;
  }

  @ViewChildren('actions') set buttons(value: QueryList<HTMLButtonElement>) {
    this._buttons = value;
    this.tool = this._tool;
  }

  setTool(tool: Tool) {
    if (tool.disabled()) {
      return;
    }
    this.tools.forEach(t => {
      t.active = t.type === tool.type;
    });

    tool.active = true;
    this.tool = tool;
    this.tool$.next(tool);
  }

  resetTool() {
    this.tools.forEach(t => {
      t.active = false;
    });
    if (!this.buttons) {
      return;
    }
  }
}
