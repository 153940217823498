import { AdditionalLabel } from '../../core/enums/additional-label';
import { DoorTopChannel, DoorBottomChannel} from '../enums/door-channel';
import { DoorCore } from '../enums/door-core';
import { DoorEdge } from '../enums/door-edge';
import { DoorMaterial } from '../enums/door-material';
import {
  ContinuousSeamlessEdges,
  DoorSeamlessEdge,
  IntermittentSeamlessEdges,
  VisibleSeamlessEdges,
} from '../enums/door-seamless-edge';
import { DoorSeries } from '../enums/door-series';
import { DoorSteelStiffen } from '../enums/door-steel-stiffen';
import { DoorStyle, DutchStyles, StandardStyles } from '../enums/door-style';
import { DoorSubType } from '../enums/door-subtype';
import { DoorThickness } from '../enums/door-thickness';
import { DoorSeriesInfo } from './door-series-info';

export const DoorSeriesInfos: { [key in DoorSeries]: DoorSeriesInfo } = {
  [DoorSeries.DP]: {
    experlogixSeriesId: 'REP_Elevation',
    experlogixModelId: 'REP_DP',
    subTypes: {
      [DoorSubType.STD]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.CRS, DoorMaterial.A60],
        cores: [DoorCore.HC, DoorCore.PS],
        additionalLabels: [],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [DoorStyle.Flush],
        edges: [DoorEdge.SQSQ],
        seamlessEdges: [DoorSeamlessEdge.VBLE],
        topChannels: [DoorTopChannel.FLUSH],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.EMB]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.A60],
        cores: [DoorCore.PS, DoorCore.TEMP],
        additionalLabels: [],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [],
        edges: [DoorEdge.SQSQ],
        seamlessEdges: [DoorSeamlessEdge.VBLE],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
    },
  },
  [DoorSeries.DE]: {
    experlogixSeriesId: 'REP_Elevation',
    experlogixModelId: 'REP_DE',
    subTypes: {
      [DoorSubType.STD]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.CRS, DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.HC],
        additionalLabels: [AdditionalLabel.E330],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134, DoorThickness._138],
        styles: [...StandardStyles, ...DutchStyles],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.INS]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.CRS, DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.PS, DoorCore.PU, DoorCore.TEMP],
        additionalLabels: [AdditionalLabel.E330],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134, DoorThickness._138],
        styles: [...StandardStyles, ...DutchStyles],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.STL]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.CRS, DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.FIB, DoorCore.PS, DoorCore.PU],
        additionalLabels: [AdditionalLabel.E330],
        steelStiffens: [DoorSteelStiffen._20, DoorSteelStiffen._18, DoorSteelStiffen._16],
        thicknesses: [DoorThickness._134],
        styles: [...StandardStyles, ...DutchStyles],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.EMB]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.A40],
        cores: [DoorCore.PS, DoorCore.TEMP],
        additionalLabels: [AdditionalLabel.E330],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134, DoorThickness._138],
        styles: [],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.LL]: {
        requiresAdditionalLabel: false,
        materials: [DoorMaterial.CRS, DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.LL],
        additionalLabels: [],
        steelStiffens: [DoorSteelStiffen._20],
        thicknesses: [DoorThickness._134],
        styles: StandardStyles,
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.BULLET]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.CRS, DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.BULLET16, DoorCore.BULLET23, DoorCore.BULLET4, DoorCore.BULLET5, DoorCore.BULLET78],
        additionalLabels: [AdditionalLabel.BULLET],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [DoorStyle.Flush],
        edges: [DoorEdge.SQSQ],
        seamlessEdges: [...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH],
        bottomChannels: [DoorBottomChannel.FLUSH],
      },
      [DoorSubType.IMP]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.HC, DoorCore.PS, DoorCore.PU, DoorCore.TEMP, DoorCore.FIB],
        additionalLabels: [AdditionalLabel.FBC],
        steelStiffens: [DoorSteelStiffen.NONE, DoorSteelStiffen._20],
        thicknesses: [DoorThickness._134],
        styles: [...StandardStyles],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.STCEMB]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.A40],
        cores: [DoorCore.TEMP],
        additionalLabels: [AdditionalLabel.STCEMB],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH],
        bottomChannels: [DoorBottomChannel.FLUSH],
      },
      [DoorSubType.STC]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.CRS, DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.SOUND42, DoorCore.SOUND4347, DoorCore.SOUND48, DoorCore.SOUND50, DoorCore.SOUND52],
        additionalLabels: [AdditionalLabel.STC],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [DoorStyle.Flush],
        edges: [DoorEdge.SQSQ, DoorEdge.BEVSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH],
        bottomChannels: [DoorBottomChannel.FLUSH],
      },
    },
  },
  [DoorSeries.DF]: {
    experlogixSeriesId: 'REP_Elevation',
    experlogixModelId: 'REP_DF',
    subTypes: {
      [DoorSubType.STD]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.A60],
        cores: [DoorCore.STIFFENED],
        additionalLabels: [AdditionalLabel.E330],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [DoorStyle.Flush],
        edges: [DoorEdge.BEVBEV],
        seamlessEdges: [DoorSeamlessEdge.CONT],
        topChannels: [DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.INVERT],
      },
    },
  },
  [DoorSeries.DL]: {
    experlogixSeriesId: 'REP_Elevation',
    experlogixModelId: 'REP_DL',
    subTypes: {
      [DoorSubType.IMP]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.HC, DoorCore.PS, DoorCore.PU, DoorCore.TEMP, DoorCore.FIB],
        additionalLabels: [AdditionalLabel.FBC],
        steelStiffens: [DoorSteelStiffen.NONE, DoorSteelStiffen._20],
        thicknesses: [DoorThickness._134],
        styles: [...StandardStyles],
        edges: [DoorEdge.SQSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
      [DoorSubType.IMPEMB]: {
        requiresAdditionalLabel: true,
        materials: [DoorMaterial.A60, DoorMaterial.G90],
        cores: [DoorCore.PS, DoorCore.TEMP],
        additionalLabels: [AdditionalLabel.FBC, AdditionalLabel.TDI],
        steelStiffens: [DoorSteelStiffen.NONE],
        thicknesses: [DoorThickness._134],
        styles: [],
        edges: [DoorEdge.SQSQ],
        seamlessEdges: [...VisibleSeamlessEdges, ...IntermittentSeamlessEdges, ...ContinuousSeamlessEdges],
        topChannels: [DoorTopChannel.FLUSH, DoorTopChannel.INVERT],
        bottomChannels: [DoorBottomChannel.FLUSH, DoorBottomChannel.INVERT],
      },
    },
  },
};
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.STD] = DoorSeriesInfos[DoorSeries.DE].subTypes.Standard;
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.INS] = DoorSeriesInfos[DoorSeries.DE].subTypes.Insulated;
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.STL] = DoorSeriesInfos[DoorSeries.DE].subTypes['Steel Stiffened'];
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.EMB] = DoorSeriesInfos[DoorSeries.DE].subTypes.Embossed;
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.LL] = DoorSeriesInfos[DoorSeries.DE].subTypes['Lead Lined'];
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.STCEMB] = DoorSeriesInfos[DoorSeries.DE].subTypes['STC (Embossed)'];
DoorSeriesInfos[DoorSeries.DL].subTypes[DoorSubType.STC] = DoorSeriesInfos[DoorSeries.DE].subTypes.STC;
