import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core'
import { IPrep } from '../../../../core/interfaces/i-prep'
import { IPrepCode } from '../../../../core/interfaces/i-prep-code'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

@Component({
  selector: 'lib-blank-hinge-prep',
  templateUrl: './blank-hinge-prep.component.html',
  styleUrls: ['./blank-hinge-prep.component.scss', '../prep-base.component.scss']
})
export class BlankHingePrepComponent extends PrepBaseComponent implements OnInit {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent

  set prep(value: IPrep) {
    this.prepsComponent.configService.currentDoor.blankSurfaceHingePrep = value
  }

  get prep(): IPrep {
    return this.prepsComponent.configService.currentDoor.blankSurfaceHingePrep
  }

  public get code(): IPrepCode {
    return this.prep?.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.BLANK_SURFACE_HINGE)
    if (!this.prep) {
      this.prep = {} as IPrep
    }
  }

  ngOnInit(): void {}
}
