import { Component, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { InputEvent, TableColumn } from '../../../../types'
import { TableService } from '../../table.service'
import { DefaultComponent, TDefaultComponentData } from '../default/default.component'

export type TInputComponentData = {
  hasNotification?: () => boolean
  displayValue?: string
  actualValue?: string
  marks_disabled?: boolean
  duplicate?: boolean
  isQuantityDivisible?: boolean
  quantityMultiple?: number
} & TDefaultComponentData

@Component({
  selector: 'oa-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent<T> extends DefaultComponent<T> implements OnDestroy {
  @Input() override data!: string | number | boolean
  @Input() override row!: TInputComponentData
  @Input() override column!: TableColumn<T>
  @Input() product: any
  destroyed$: Subject<boolean> = new Subject()
  inputValue$: Subject<InputEvent<T>> = new Subject()

  constructor(
    private tableActionsService: TableService<T>,
    protected override renderer: Renderer2,
    protected override hostElement: ElementRef
  ) {
    super(renderer, hostElement)
    this.inputValue$
      .pipe(distinctUntilChanged(), debounceTime(800), takeUntil(this.destroyed$))
      .subscribe((res) => this.tableActionsService.inputEvent.next(res))
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation()
  }

  handleChange(val: string | number | boolean, $event: KeyboardEvent) {
    const payload: InputEvent<T> = {
      tableName: this.column.tableName ?? '',
      row: this.row,
      column: this.column,
      newVal: val
    }
    this.inputValue$.next(payload)
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
