import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { DoorBaseData } from '../../rules/door-base-data';
import { DoorGauge } from '../door-gauge';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorGauges(
  series: DoorSeries,
  subType: DoorSubType,
  unitOfMeasure: UnitOfMeasure,
  width: string,
  height: string
) {
  const doorGauges = new DoorBaseData().get(series, subType, unitOfMeasure, width, height) as DoorGauge[];
  return doorGauges;
}
