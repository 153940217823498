import { Component, OnDestroy } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { User } from '@auth0/auth0-spa-js'
import { ControlsOf } from '@oeo/common'
import { Subject, takeUntil } from 'rxjs'
import { NewUserRequest } from '../models'
import { AuthService } from '../services/auth.service'
import { UserService } from '../services/user/user.service'

@Component({
  selector: 'oa-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnDestroy {
  userRequestForm = new FormGroup<ControlsOf<NewUserRequest>>({
    firstName: new FormControl({ value: '', disabled: true }, [Validators.required]),
    lastName: new FormControl({ value: '', disabled: true }, [Validators.required]),
    email: new FormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
    salesContactFirstName: new FormControl(''),
    salesContactLastName: new FormControl(''),
    salesContactEmail: new FormControl('', [Validators.email]),
    companyName: new FormControl('', [Validators.required]),
    companyCity: new FormControl('', [Validators.required]),
    companySecondaryStreetAddress: new FormControl(''),
    companyState: new FormControl('', [Validators.required]),
    companyStreetAddress: new FormControl('', [Validators.required]),
    companyZipCode: new FormControl('', [Validators.required])
  })

  user: User
  loading: boolean = false
  submitted = false
  successful = false
  destroy$ = new Subject<void>()

  constructor(private authService: AuthService, public userService: UserService) {
    this.authService
      .getUser$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (user) => {
          this.user = user
          this.userRequestForm.patchValue({
            firstName: user.given_name,
            lastName: user.family_name,
            email: user.email
          })
        }
      })
  }

  submit() {
    this.submitted = true
    this.loading = true

    this.userService.requestUserAccess(this.userRequestForm.getRawValue()).subscribe((successful) => {
      this.loading = false
      this.successful = successful
    })
  }

  openResources() {
    window.open('https://support.oeo.allegion.com/hc/en-us', '_blank')
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }
}
