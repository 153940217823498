import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IPrepCode } from '../interfaces/i-prep-code';
import { RestService } from './rest.service';

@Injectable()
export class DoorPrepCodeService extends RestService<IPrepCode> {
  constructor(http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) environment: IEnvironment) {
    super(http, `${environment.elevations.apiUrl}v1/doorPrepCodes`);
  }
}
