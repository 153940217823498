import { omit, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import { appendChildElement, appendChildrenElements, createSVGElement, setAttributes, setHeight, setStyles, setWidth, setX, setX1, setX2, setY, setY1, setY2 } from '../../../../core/helpers/svg-functions';
import { Panel } from '../../../abstracts/panel';

const panelStyles = {
  stroke: 'black',
  strokeWidth: '4px',
  fill: 'lightgrey',
  fillOpacity: '0.35',
  transform: 'translate(2, 2)',
};

const lineStyles = {
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '2px',
};

export class SquarePanel extends Panel {
  constructor(x: number, y: number, width: number, height: number) {
    super(x, y, width, height);
  }

  drawCutout() {
    const multiplier = [0, 1, 1.5];
    const distance = 80;

    const rects = new Array(3).fill(0).map((_, i) =>
       pipe(
        setStyles(omit(panelStyles, 'transform')),
        setAttributes(pick(panelStyles, 'transform')),
        setX(this.x + distance * multiplier[i]),
        setY(this.y + distance * multiplier[i]),
        setWidth(this.width - distance * multiplier[i] * 2 - 4),
        setHeight(this.height - distance * multiplier[i] * 2 - 4),
      )(createSVGElement('rect'))
    )

    const topLeft =pipe(
      setStyles(lineStyles),
      setX1(this.x + 2),
      setX2(this.x + distance * 1.5),
      setY1(this.y + 2),
      setY2(this.y + distance * 1.5),
    )( createSVGElement('line'));

    const topRight = pipe(
      setStyles(lineStyles),
      setX1(this.x + this.width - 2),
      setX2(this.x + this.width - distance * 1.5),
      setY1(this.y + 2),
      setY2(this.y + distance * 1.5),
    )(createSVGElement('line'));

    const bottomRight = pipe(
      setStyles(lineStyles),
      setX1(this.x + this.width - 2),
      setX2(this.x + this.width - distance * 1.5),
      setY1(this.y + this.height - 2),
      setY2(this.y + this.height - distance * 1.5),
    )(createSVGElement('line'));

    const bottomLeft = pipe(
      setStyles(lineStyles),
      setX1(this.x + 2),
      setX2(this.x + distance * 1.5),
      setY1(this.y + this.height - 2),
      setY2(this.y + this.height - distance * 1.5),
    )(createSVGElement('line'));

    return pipe(
      appendChildrenElements(rects),
      appendChildElement(bottomLeft),
      appendChildElement(bottomRight),
      appendChildElement(topRight),
      appendChildElement(topLeft),
    )(createSVGElement('g'))
  }
}
