import { AbstractControl, ValidatorFn } from '@angular/forms'

// tslint:disable-next-line:directive-class-suffix
export function NoCareOfValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbiddenVals = ['C/O', 'Care of', 'Attention to', 'Attn']

    // self value
    let value = control.value
    value = value ? value.toLowerCase().trim() : ''
    let isInvalid = false
    let forbiddenVal: string

    forbiddenVals.forEach((f) => {
      const _f = f.toLocaleLowerCase().trim()
      isInvalid = value.includes(_f) ? true : isInvalid
      forbiddenVal = value.includes(_f) ? f : forbiddenVal
    })
    return isInvalid ? { noCareOf: { value: forbiddenVal } } : null
  }
}
