import { omit, pick } from 'lodash';
import { pipe } from 'lodash/fp';
import { appendToContainerElement, createSVGElement, setAttributes, setHeight, setStyles, setWidth, setX, setY } from '../../../core/helpers/svg-functions';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';

const liteStyles = {
  fill: 'rgb(63, 101, 191)',
  fillOpacity: '0.35',
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '16px',
  transform: 'translate(8,8)',
};

export class Lite extends Cutout {
  readonly type = 'Lite';
  sizes: { width: string; height: string }[] = [];
  constructor(
    name: string,
    doorType: DoorType,
    x: number,
    y: number,
    width: number,
    height: number,
    verticalDimensionMode: VerticalCutoutDimensionMode,
    horizontalDimensionMode: HorizontalCutoutDimensionMode,
    sizeEditable: boolean = true,
    positionEditable: boolean = true
  ) {
    super(
      name,
      doorType,
      x,
      y,
      width,
      height,
      verticalDimensionMode,
      horizontalDimensionMode,
      sizeEditable,
      positionEditable
    );
  }

  drawCutout(container?: SVGGElement) {
    const rect = pipe(
      setX(this.x),
      setY(this.y),
      setWidth(this.width - 16),
      setHeight(this.height - 16),
      setAttributes(pick(liteStyles, 'transform')),
      setStyles(omit(liteStyles, 'transform')),
    )(createSVGElement('rect'));

    return container ? appendToContainerElement(container)(rect) : rect;
  }
}
