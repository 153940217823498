import { Stick } from '../stick';
import { StickType } from '../../enums/stick-type';

export class StickValidator {
  constructor(protected stick: Stick) {}

  warnings(): readonly string[] {
    return [];
  }

  errors(): readonly string[] {
    return [];
  }
}
