import { ExperlogixService } from './../core/services/experlogix.service';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { IElevation } from '../core/interfaces/i-elevation';
import { DialogService } from '../core/services/dialog.service';
import { ActionDialogComponent } from './dialogs/action-dialog/action-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser'
import { IElevationComponent } from '../core/interfaces/i-elevation-component';

@Component({
  selector: 'lib-elevation',
  templateUrl: './elevation.component.html',
  styleUrls: ['./elevation.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ElevationComponent),
      multi: true,
    },
  ],
})
export class ElevationComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  loading$ = new BehaviorSubject<boolean>(false);
  elevationComponent: IElevationComponent;


  get elevationContainsErrors() {
    return this.elevationComponent?.service?.messages?.length > 0
  }

  get elevation(): IElevation {
    return this.elevationComponent?.elevation;
  }

  _configuration: string;
  @Input() set configuration(value: string) {
    this._configuration = value;
    if (value) {
      this.loadElevation(value.includes('category id="Door"') ? 'doorElevation' : 'frameElevation');
    }
  }

  @Input() showBreadcrumbs: boolean = true;

  @Output() save$ = new EventEmitter<string>();
  @Output() configureFrame$ = new EventEmitter<void>();
  @Output() configurePreferences$ = new EventEmitter<void>();

  constructor(
    private dialogService: DialogService,
    private experlogixService: ExperlogixService,
    private route: ActivatedRoute,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.registerElevationsIcons()
  }

  onActivate($event: IElevationComponent) {
    this.elevationComponent = $event;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  openActions(): void {
    this.dialogService
      .open(ActionDialogComponent, {
        elevation: Object.assign(this.elevation, { configuration: this.elevationComponent.elevation.toXML() }),
        service: this.elevationComponent.service,
      })
      .subscribe((elevation: IElevation) => {
        if(!elevation) return;
        this.elevationComponent.service.elevation = elevation;
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { style: elevation.name, id: elevation.id },
            queryParamsHandling: 'merge',
          });
      });
  }

  addToEstimate(): void {
    this.loading$.next(true);
    this.experlogixService
      .price(this.elevation.toXML())
      .subscribe({
        next: (config) => {
          this.save$.next(config)
          this.loading$.next(false);
        },
        error: () => this.loading$.next(false)
      });
    }

  registerElevationsIcons(){
    const icons = [
      'architecture',
      'download',
      'draw',
      'flip',
      'folder-vertical',
      'frame',
      'grid',
      'hot-keys',
      'info',
      'lite',
      'louver',
      'move',
      'preps',
      'redo',
      'rulers',
      'undo',
      'home',
      'template',
      'extend',
      'collapse'
    ]
    icons.forEach((icon) => {
      this.iconRegistry.addSvgIcon(icon, this.sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/ovt-elevations/${icon}.svg`))
    })
  }

  loadElevation(elevationType: 'doorElevation' | 'frameElevation') {
    this.router.navigate([elevationType], {
      relativeTo: this.route,
      state: { configuration: this._configuration },
    });
  }
}
