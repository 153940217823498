import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UnitOfMeasure } from '../enums/unit-of-measure';
import { applyUnitOfMeasure } from '../helpers/applyUnitOfMeasure';

@Directive({
  selector: '[libApplyMeasure]',
})
export class ApplyMeasureDirective implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _unitOfMeasure: UnitOfMeasure = UnitOfMeasure.Imperial;
  @Input() set unitOfMeasure(value: UnitOfMeasure) {
    this._unitOfMeasure = value;
  }

  constructor(private inputElementRef: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    fromEvent(this.inputElementRef.nativeElement, 'keyup')
      .pipe(distinctUntilChanged(), debounceTime(500), takeUntil(this._destroy$))
      .subscribe((e: KeyboardEvent) => this.onKeyUp(e, `${this.inputElementRef.nativeElement.value}`));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  onKeyUp(event: KeyboardEvent, value: string) {
    switch (this._unitOfMeasure) {
      case UnitOfMeasure.Imperial: {
        value = value.replace(/[^0-9, /, ., ']/g, ''); // allow only numeric, period, forward slash & single quote
        break;
      }
      case UnitOfMeasure.Metric: {
        value = value.replace(/[^0-9]/g, ''); // allow only numeric
        break;
      }
    }
    const valueWithMeasure = applyUnitOfMeasure(value, this._unitOfMeasure);
    this.inputElementRef.nativeElement.value = valueWithMeasure;
    this.inputElementRef.nativeElement.dispatchEvent(new Event('input'));
  }
}
