import { CSSPropKey } from "@oeo/common";
import { SVGUnits } from "../enums/svg-units";

/** Extracts the parameters from index 2 */
type ExtractParamsFromIndex2<T extends any[]> = T extends [infer U, infer U2, ...infer Us] ? Us : never;
type ValueOf<T> = T[keyof T];

type CreateElementNSOptions = ExtractParamsFromIndex2<Parameters<typeof document['createElementNS']>>;

export enum NameSpaces {
  SVG = 'http://www.w3.org/2000/svg',
  XHTML = 'http://www.w3.org/1999/xhtml',
  XLink = 'http://www.w3.org/1999/xlink',
  XML = 'http://www.w3.org/XML/1998/namespace',
  XMLNS = 'http://www.w3.org/2000/xmlns/',
}

type SVGE<K extends keyof SVGElementTagNameMap> = SVGElementTagNameMap[K];

/**
 * Creates an SVG element
 * @param ele The type of SVG element to create
 * @param args Element Creation options - Refer to [docs here](https://developer.mozilla.org/en-US/docs/Web/API/Document/createElementNS)
 * @returns
 */
export function createSVGElement<K extends keyof SVGElementTagNameMap>(ele: K, ...args: CreateElementNSOptions) {
  return document.createElementNS(NameSpaces.SVG, ele, ...args) as unknown as SVGE<K>;
}

type HTMLElementTagNames = keyof HTMLElementTagNameMap;
type HTMLElementTypes = ValueOf<HTMLElementTagNameMap>;
type HTMLElements<K extends keyof HTMLElementTagNameMap> = HTMLElementTagNameMap[K];
type ExtractParamsFromIndex1<T extends any[]> = T extends [infer U,  ...infer Us] ? Us : never;
type CreateElementOptions = ExtractParamsFromIndex1<Parameters<typeof document['createElement']>>;

/**
 * Creates a HTML element
 * @param ele The type of HTML element to create
 * @param args Element Creation options - Refer to [docs here](https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement)
 * @returns
 */
export function createHTMLElement<K extends HTMLElementTagNames>(ele: K, ...options: CreateElementOptions) {
  return document.createElement(ele, ...options) as unknown as HTMLElements<K>;
}

export type SVGElementType = ValueOf<SVGElementTagNameMap>;

/**
 * Set's multiple attributes on the SVG element at once
 * @param attributes The attributes to set on the element
 * @returns the reference to the element that was passed in
 */
export const setAttributes = function (attributes: Record<string, string | number>) {
  return function<T extends SVGElementType>(element: T): T {
    for (const [key, value] of Object.entries(attributes)) {
      element.setAttribute(key, value.toString());
    }
    return element as T;
  };
};

/**
 * Set's multiple attributes on the SVG element at once
 * @param attributes The attributes to set on the element
 * @returns the reference to the element that was passed in
 */
export const setNameSpace = function (namespace: NameSpaces) {
  return function<T extends Element>(element: T): T {
    element.setAttribute('xmlns', namespace);
    return element as T;
  };
};


/**
 * Adds a `cx` attribute the the SVG element
 * @param value The cx value to set
 * @returns the reference to the element that was passed in
 */
export const setCx = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('cx', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Adds a `cy` attribute the the SVG element
 * @param value The cy value to set
 * @returns the reference to the element that was passed in
 */
export const setCy = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('cy', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the `width` attribute of the SVG element
 * @param value The width value to set
 * @returns the reference to the element that was passed in
 */
export const setWidth = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('width', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the `height` attribute of the SVG element
 * @param value The height value to set
 * @returns the reference to the element that was passed in
 */
export const setHeight = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('height', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the `r` (radius) attribute of the SVG element - used for circles
 * @param value The `r` value to set
 * @returns the reference to the element that was passed in
 */
export const setR = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('r', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the `rx` attribute of the SVG element
 * @param value The `rx` value to set
 * @returns the reference to the element that was passed in
 */
export const setRx = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('rx', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the `ry` (the radius on the y axis) attribute of the SVG element
 * @param value The `ry` value to set
 * @returns the reference to the element that was passed in
 */
export const setRy = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('ry', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`y` attribute](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/y) of the SVG element
 * @param value The `y`value to set
 * @returns the reference to the element that was passed in
 */
export const setY = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('y', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`y1`](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/y1) attribute of the SVG element
 * @param value The y1 value to set
 * @returns the reference to the element that was passed in
 */
export const setY1 = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('y1', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`y2`](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/y2) attribute of the SVG element
 * @param value The y1 value to set
 * @returns the reference to the element that was passed in
 */
export const setY2 = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('y2', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`x`](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/x) attribute of the SVG element
 * @param value The `x` value to set
 * @returns the reference to the element that was passed in
 */
export const setX = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('x', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`x1`](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/x1) attribute of the SVG element
 * @param value The x1 value to set
 * @returns the reference to the element that was passed in
 */
export const setX1 = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('x1', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`x2`](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/x2) attribute of the SVG element
 * @param value The x1 value to set
 * @returns the reference to the element that was passed in
 */
export const setX2 = function (value: string | number, units: SVGUnits = SVGUnits.px) {
  return function<T extends SVGElementType>(element: T): T {
    element.setAttribute('x2', `${value.toString() + units}`);
    return element as T;
  };
};

/**
 * Sets the [`style`](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/style) attribute of the SVG element
 * @param styles The styles value to set
 * @returns the reference to the element that was passed in
 */
export const setStyles = function(styles: { [key: string]: string }) {
  return function<T extends SVGElementType>(element: T): T {
    for (const key in styles) {
      element.style[key as CSSPropKey] = styles[key]
    }
    return element as T;
  };
};

/**
 * Set's the textContent of the SVG element - used for text elements
 * @param text The text to set
 * @returns the reference to the element that was passed in
 */
export const setTextContent = function (text: string) {
  return function<T extends SVGElementType>(element: T): T {
    element.textContent = text;
    return element as T;
  };
};

/**
 * Adds a class or a list of classes to the SVG element
 * @param cssClass - a string or an array of strings to add
 * @returns the reference to the element that was passed in
 */
export const addClass = function (cssClass: string | string[]) {
  return function <K extends SVGElementType>(element: K): K{
    if (typeof cssClass === 'string') {
      element.classList.add(cssClass);
    }
    if (Array.isArray(cssClass)) {
      for (const className of cssClass) {
        element.classList.add(className);
      }
    }
    return element as K
  };
};

/**
 * Sets the [`innerHTML`](https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML) of the SVG element
 * @param html The html string to add
 * @returns the reference to the element that was passed in
 */
export const setInnerHTML = function (html: string) {
  return function<T extends SVGElementType>(element: T): T {
    element.innerHTML = html;
    return element as T;
  };
};

/**
 * Appends an element to a parent element
 * @param element The element to append to the parent
 * @returns the reference to the element that was passed in
 */
export const appendChildElement = function (element: SVGElement) {
  return function <P extends SVGElementType>(parent: P): P{
    parent.appendChild(element);
    return parent as P
  };
};

/**
 * Appends an element to a container
 *
 * This works the same as `appendChildElement`,
 * except that it takes the parent as the first argument
 * returns the child element instead of the parent
 */
export const appendToContainerElement = function <P extends SVGElementType>(parent: P) {
  return function <C extends SVGElementType>(element: C): C{
    parent.appendChild(element);
    return element as C
  };
};

/**
 * Appends a list of elements to a parent element
 * @param elements the child elements to append to the parent
 * @returns the reference to the element that was passed in
 */
export const appendChildrenElements = function  <Child extends SVGElementType>(elements: Child[]) {
  return function <P extends SVGElementType> (parent: P): P {
    for (const element of elements) {
      parent.appendChild(element);
    }
    return parent as P
  };
};

/**
 * Appends an element to a first position of child elements of a parent element
 * @param element The element to append to the parent
 * @returns the reference to the element that was passed in
 */
export const insertFirstChildElement = function (element: SVGElement) {
  return function <P extends SVGElementType> (parent: P): P {
    parent.insertBefore(element, parent.firstChild);
    return parent as P
  }
}
