export enum AdditionalLabel {
  E330 = 'E330',
  BULLET = 'Bullet Resist',
  FBC = 'FBC Label',
  STCEMB = 'STC Embossed',
  STC = 'STC',
  TDI = 'TDI LABEL',
  SMOKE = 'Positive Pressure w/ Smoke',
  PP = 'Positive Pressure',
  E330SMOKE = 'E330 w/ Positive Pressure w/ Smoke',
  E330PP = 'E330 w/ Positive Pressure',
}
