import { IPrep } from '../../../../core/interfaces/i-prep';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { StickSubtype } from '../../../enums/stick-subtype';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';

export abstract class EzPrepComponent extends PrepBaseComponent {
  subTypes: StickSubtype[] = [];
  codes: IPrepCode[];
  showMessage = false;
  private _code: IPrepCode;
  public get code(): IPrepCode {
    return this._code;
  }
  public set code(value: IPrepCode) {
    this._code = value;
    this.updateValue();
  }

  abstract prep: IPrep;

  constructor(prepsComponent: PrepsComponent, prepCategoryId: number, public name: string) {
    super(prepsComponent, prepCategoryId);
  }

  protected init(): void {
    if (!this.prep) {
      this.prep = {} as IPrep;
    }
    this._code = this.codes.first(c => c.id === this.prep.id) ?? null;
  }

  updateValue(): void {
    if (!this.code) {
      return this.draw$.next();
    }
    this.prep.id = this.code?.id;
    this.prep.code = this.code?.code;
    this.prep.pricingCategory = this.code?.pricingCategory;
    this.prep.fixedLocation = this.code?.fixedLocation;
    this.draw$.next();
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (this.prep.locationType !== this.specialPrepLocation.value) {
      this.prep.location = Array.isArray(this.prep.location)? []: '0"'
      this.framePrepComponent.resetAndFit();
      return;
    }
    this.prepLocationInputs.push(this.drawPrep(0, this.prep));
  }
}
