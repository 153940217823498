
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, first, map, mergeMap, skipUntil, skipWhile, takeUntil, tap } from 'rxjs/operators';
import SvgPanZoom from 'svg-pan-zoom';
import { ToolType } from '../../../core/enums/tool-type';
import { Tool } from '../../../core/models/tool';
import { DialogService } from '../../../core/services/dialog.service';
import { Intersectable } from '../../abstracts/intersectable';
import { FrameProfileComponent } from '../../dialogs/frame-profile/frame-profile.component';
import { StickType } from '../../enums/stick-type';
import { ActiveDimensionsController } from '../../helpers/active-dimensions';
import { LineMaker } from '../../helpers/line-maker';
import { ProfileMaker } from '../../helpers/profile-maker';
import { Door } from '../../models/door';
import { IntersectableExport } from '../../models/exports/intersectable-export';
import { FrameElevation } from '../../models/frame-elevation';
import { Glass } from '../../models/glass';
import { Stick } from '../../models/stick';
import { IntersectableService } from '../../services/intersectable.service';

@Component({
  selector: 'lib-drawing-pad',
  templateUrl: './drawing-pad.component.html',
  styleUrls: ['./drawing-pad.component.scss'],
})
export class DrawingPadComponent implements OnInit, OnDestroy {
  private _isInit: boolean;
  private _frameElevation: FrameElevation;
  private _tool$ = new Subject<void>();
  private _previousTool: Tool;
  private _tool: Tool;
  private _destroy$ = new Subject<void>();
  private _lineMaker: LineMaker;
  private _profileMaker: ProfileMaker;
  private _activeDimensionsController: ActiveDimensionsController
  private _frameElevation$ = new Subject<void>();
  private _zoom = 1;

  ruler = true;
  grid = false;

  get zoom() {
    return this._zoom;
  }

  get frameElevation() {
    return this._frameElevation;
  }
  @Input() set frameElevation(value: FrameElevation) {
    this._frameElevation = value;
    this._frameElevation$.next();
    this._frameElevation.afterUpdate$.pipe(takeUntil(this._frameElevation$)).subscribe(() => {
      this.handleAfterUpdate();
      this.resetAndFit();
    });
    if (this._frameElevation.source?.intersectables?.any()) {
      this.loadFromExport(this.frameElevation.source.intersectables);
    } else {
      this.handleAfterUpdate();
    }
    this._frameElevation.svg = this.container;
  }
  @Input() tools: Tool[];

  get height() {
    return this.frameElevation.height;
  }

  get width() {
    return this.frameElevation.width;
  }

  @Input() set tool(tool: Tool) {
    if (tool == null) {
      return;
    }
    this._previousTool = this.tool;
    this._tool = tool;
    this.setTool(tool);
  }
  get tool() {
    return this._tool;
  }

  @Output() tool$ = new EventEmitter<Tool>();

  @ViewChild('svgRef', { static: true }) svgRef: ElementRef<SVGSVGElement>;
  get svg() {
    return this.svgRef.nativeElement;
  }

  @ViewChild('containerRef', { static: true }) containerRef: ElementRef<SVGSVGElement>;
  get container() {
    return this.containerRef.nativeElement;
  }

  @ViewChild('intersectableContainerRef', { static: true }) intersectableContainerRef: ElementRef<SVGGElement>;
  get intersectableContainer() {
    return this.intersectableContainerRef.nativeElement;
  }

  @ViewChild('profileContainerRef', { static: true }) profileContainerRef: ElementRef<SVGGElement>;
  get profileContainer() {
    return this.profileContainerRef.nativeElement;
  }

  @ViewChild('lineContainerRef', { static: true }) lineContainerRef: ElementRef<SVGGElement>;
  get lineContainer() {
    return this.lineContainerRef.nativeElement;
  }

  @ViewChild('textRef', { static: true })
  textRef: ElementRef<SVGTextElement>;
  get text() {
    return this.textRef.nativeElement;
  }

  @ViewChild('rectRef', { static: true }) rectRef: ElementRef<SVGRectElement>;
  get rect() {
    return this.rectRef.nativeElement;
  }

  mouseUp$ = new Subject<void>();
  mouseMove$ = new Subject<MouseEvent>();
  svgPanZoom: SvgPanZoom.Instance;

  constructor(private intersectableService: IntersectableService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this._profileMaker = new ProfileMaker(this.profileContainer);
    this._activeDimensionsController = new ActiveDimensionsController(this.profileContainer);
    this._lineMaker = new LineMaker(this.text, this.lineContainer);
    this._lineMaker.redraw([], this.height, this.width, this.frameElevation.unitOfMeasure);
    this.svgPanZoom = SvgPanZoom(this.svg, {
      zoomEnabled: true,
      fit: false,
      contain: false,
      center: false,
      panEnabled: true,
      minZoom: 0.25,
      onZoom: (zoom: number) => {
        this._zoom = zoom;
      },
    });
    this.resetAndFit();

    this.intersectableService.intersectables$.pipe(takeUntil(this._destroy$)).subscribe(intersectables => {
      this.frameElevation.intersectables = intersectables;
      this.handleAfterUpdate();
    });

    this.intersectableService.undo$
      .pipe(takeUntil(this._destroy$))
      .subscribe(intersectables => this.loadFromExport(intersectables));

    this.intersectableService.redo$
      .pipe(takeUntil(this._destroy$))
      .subscribe(intersectables => this.loadFromExport(intersectables));

    this._profileMaker.selected$.pipe(takeUntil(this._destroy$)).subscribe(template => {
      this.dialogService.open(FrameProfileComponent, template, { closeable: true }).subscribe(() => {
        if (template.hasChanged) {
          template.profile.stick.profileDimensions = Object.keys(template.dimensions).reduce((prev, current) => {
            prev[current] = template.dimensions[current].get();
            return prev;
          }, {} as Record<string, any>);
        }
        this.handleAfterUpdate();
      });
      if (this.intersectableService.activeIntersectable !== template.profile.stick) {
        this.intersectableService.activate(template.profile.stick);
      }
    });

    fromEvent(this.container, 'mousedown')
      .pipe(takeUntil(this._destroy$))
      .subscribe(e => e.stopPropagation());

    fromEvent(document, 'mouseup')
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => this.mouseUp$.next());

    fromEvent(document, 'mousemove')
      .pipe(takeUntil(this._destroy$))
      .subscribe((e: MouseEvent) => this.mouseMove$.next(e));
    this._isInit = true;
  }

  ngOnDestroy() {
    this._activeDimensionsController.destroy();
    this._profileMaker.destroy();
    this.svgPanZoom.destroy();
    this._tool$.next();
    this._tool$.unsubscribe();
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  setTool(tool: Tool) {
    this._tool$.next();
    switch (tool.type) {
      case ToolType.Draw:
        return this.draw();
      case ToolType.Glass:
        return this.glass();
      case ToolType.Door:
        return this.door();
      case ToolType.Flip:
        return this.flip();
      case ToolType.Undo:
        return this.undo();
      case ToolType.Redo:
        return this.redo();
      case ToolType.Ruler:
        this.ruler = !this.ruler;
        return this.tool$.next(this._previousTool);
      case ToolType.Grid:
        this.grid = !this.grid;
        return this.tool$.next(this._previousTool);
    }
  }

  draw() {
    let drawing = false;
    fromEvent(this.rect, 'mousedown')
      .pipe(
        first(),
        takeUntil(this._tool$),
        takeUntil(this._destroy$),
        tap(() => (drawing = true)),
        map((event: MouseEvent) => new Stick(this.frameElevation, this.intersectableContainer, event.x, event.y)),
        mergeMap(stick =>
          this.mouseMove$.pipe(
            takeUntil(this._tool$),
            takeUntil(this._destroy$),
            tap((event: MouseEvent) =>
              stick.resize(
                event.x,
                event.y,
                event.movementX / this.svgPanZoom.getZoom(),
                event.movementY / this.svgPanZoom.getZoom()
              )
            ),
            map(_ => stick)
          )
        ),
        skipUntil(
          this.mouseUp$.pipe(
            skipWhile(_ => !drawing),
            takeUntil(this._tool$),
            takeUntil(this._destroy$)
          )
        )
      )
      .subscribe(stick => {
        if (stick.isValid()) {
          this.setupIntersectableListeners(stick);
          this.intersectableService.add(stick);
        } else {
          stick.destroy();
        }
        this.setTool(this.tool);
      });
  }

  glass() {
    fromEvent(this.rect, 'click')
      .pipe(
        takeUntil(this._tool$),
        takeUntil(this._destroy$),
        map((e: MouseEvent) => new Glass(this.frameElevation, this.intersectableContainer, e.x, e.y))
      )
      .subscribe(glass => {
        if (glass.isValid()) {
          this.setupIntersectableListeners(glass);
          this.intersectableService.add(glass);
        } else {
          glass.destroy();
        }
      });
  }

  door() {
    fromEvent(this.rect, 'click')
      .pipe(
        takeUntil(this._tool$),
        takeUntil(this._destroy$),
        map((e: MouseEvent) => new Door(this.frameElevation, this.intersectableContainer, e.x, e.y))
      )
      .subscribe(door => {
        if (door.isValid()) {
          this.setupIntersectableListeners(door);
          this.intersectableService.add(door);
        } else {
          door.destroy();
        }
      });
  }

  undo() {
    this.intersectableService.undo();
    this.tool$.next(this._previousTool);
  }

  redo() {
    this.intersectableService.redo();
    this.tool$.next(this._previousTool);
  }

  flip() {
    const intersectables = this.intersectableService.intersectables;
    intersectables.forEach(i => {
      i.flipOverCenter();
    });
    this._lineMaker.redraw(intersectables, this.height, this.width, this.frameElevation.unitOfMeasure);
    this._profileMaker.draw(intersectables.filter(i => i instanceof Stick) as Stick[]);
    this.tool$.next(this._previousTool);
  }

  loadFromExport(intersectableExports: IntersectableExport[]) {
    this.frameElevation.intersectables = this.frameElevation.draw(this.intersectableContainer, intersectableExports);
    this.frameElevation.intersectables.forEach(i => {
      this.setupIntersectableListeners(i);
    });
    requestAnimationFrame(() => this.intersectableService.reset(this.frameElevation.intersectables));
  }

  private handleAfterUpdate() {
    if (!this._isInit) {
      return;
    }
    this.intersectableService.addToHistory();

    const sticks = this.intersectableService.intersectables.filter(i => i instanceof Stick) as Stick[];
    for (const stick of sticks) {
      stick.setProfiles();
    }
    sticks.filter((stick: Stick) => stick.type === StickType.PartialMullion).forEach((stick: Stick) => stick.drawJambs());
    this._lineMaker.redraw(
      this.intersectableService.intersectables as Intersectable[],
      this.height,
      this.width,
      this.frameElevation.unitOfMeasure
    );
    this._profileMaker.draw(sticks);
    this._activeDimensionsController.draw(sticks);
  }

  private setupIntersectableListeners(i: Intersectable) {
    fromEvent(i.element, 'click')
      .pipe(takeUntil(this._destroy$), takeUntil(i.destroyed$), tap())
      .subscribe(() => this.intersectableService.activate(i));

    i.afterUpdate$
      .pipe(takeUntil(this._destroy$), takeUntil(i.destroyed$), debounceTime(50))
      .subscribe(() => this.handleAfterUpdate());
    if (i instanceof Stick) {
      i.drawJambs();
    }
  }

  private resetAndFit() {
    requestAnimationFrame(() => {
      this.svgPanZoom.updateBBox().center().updateBBox().fit().updateBBox().zoomOut();
    });
  }
}
