<div class="dialog-content">
  <div class="header">
    <lib-stepper [steps]="steps"> </lib-stepper>
  </div>
  <div class="content" *ngIf="!init">
    <ng-container *ngIf="step === 0">
      <lib-prep-selection [type]="'frame'" [steps]="prepSteps" (step$)="modifySteps($event)"></lib-prep-selection>
    </ng-container>
    <ng-container #PrepComponentEntry></ng-container>
  </div>
  <div class="content" *ngIf="init"></div>
  <div class="footer">
    <button mat-stroked-button [disabled]="step === 0 && !data.isNew" (click)="back()">{{('BUTTON.previous' | translate)}}</button>
    <button
      mat-flat-button
      color="primary"
      *ngIf="step !== steps.length - 1"
      (click)="next()"
      [disabled]="(step > 0 && !currentComponent?.prepLocationInputsFilled) || loading || configService.loading"
    >
      {{('BUTTON.continue' | translate)}}
    </button>
    <button
      mat-flat-button
      color="primary"
      *ngIf="step === steps.length - 1"
      (click)="data.isNew ? next$.emit(data.frame) : close()"
      [disabled]="(step > 0 && !currentComponent?.prepLocationInputsFilled) || loading || configService.loading"
    >
      {{('BUTTON.finish' | translate)}}
    </button>
  </div>
</div>
