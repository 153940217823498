import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, Form, FormControl, FormGroup } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { nameOf } from '@oeo/common';
import { IPrepCategory } from '../../core/interfaces/i-prep-category';
import { IProductCategory } from '../../core/interfaces/i-product-category';
import { IProductCategoryPrepCategoryService } from '../../core/interfaces/i-product-category-prep-category.service';
import { IProductCategoryService } from '../../core/interfaces/i-product-category.service';

@Component({
  selector: 'lib-hardware',
  templateUrl: './hardware.component.html',
  styleUrls: ['./hardware.component.scss'],
})
export class HardwareComponent implements OnInit {
  @Input() prepCategories: IPrepCategory[];
  @Input() loading = false
  private _productCategory: IProductCategory;
  public get productCategory(): IProductCategory {
    return this._productCategory;
  }
  @Input() public set productCategory(value: IProductCategory) {
    this._productCategory = value;
    if (!this._productCategory) {
      return;
    }
    this.message.patchValue(this._productCategory.message);
    this.values = this.prepCategories.map(c => ({
      prepCategoryId: c.id,
      name: c.name,
      checked: this.productCategory.productCategoryPrepCategories.any(x => x.prepCategoryId === c.id),
    }));
  }
  @Input() productCategoryService: IProductCategoryService;
  @Input() productCategoryPrepCategoryService: IProductCategoryPrepCategoryService;

  values: { prepCategoryId: number; name: string; checked: boolean }[];
  form: FormGroup = new FormGroup({
    [nameOf((_: IProductCategory) => _.message)]: new FormControl(''),
  });

  get message(): AbstractControl<string> {
    return this.form.get(nameOf((_: IProductCategory) => _.message));
  }

  constructor() {}

  ngOnInit(): void {}

  save(): void {
    const added = this.values
      .filter(v => v.checked)
      .map(v =>
        this.productCategoryPrepCategoryService.create({
          prepCategoryId: v.prepCategoryId,
          productCategoryId: this.productCategory.id,
        })
      );
    const deleted = this.values
      .filter(v => !v.checked)
      .map(v =>
        this.productCategoryPrepCategoryService.delete({
          prepCategoryId: v.prepCategoryId,
          productCategoryId: this.productCategory.id,
        })
      );
    forkJoin([
      this.productCategoryService.update(this.productCategory.id, this.form.value),
      ...added,
      ...deleted,
    ]).subscribe(() => {
      Object.assign(this.productCategory, this.form.value);
      this.productCategory.productCategoryPrepCategories = this.values
        .filter(v => v.checked)
        .map(v => ({
          prepCategoryId: v.prepCategoryId,
          productCategoryId: this.productCategory.id,
        }));
    });
  }

  delete(): void {}
}
