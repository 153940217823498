<nav aria-label="Breadcrumb">
  <ol>
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <li *ngIf="breadcrumb.label !== 'ignore'">
        <a *ngIf="breadcrumb.params" [routerLink]="[breadcrumb.url]" [queryParams]="breadcrumb.params">
          <ng-container *ngIf="breadcrumb.label; else loading">
            <span
              >{{ breadcrumb.canTranslate ? (breadcrumb.label | translate) : breadcrumb.label }}
              <ng-container *ngTemplateOutlet="helperService.getBreadCrumbContent(breadcrumb.label)"></ng-container>
            </span>
          </ng-container>
        </a>
        <a *ngIf="!breadcrumb.params" [routerLink]="[breadcrumb.url]">
          <ng-container *ngIf="breadcrumb.label; else loading">
            <span
              >{{ breadcrumb.canTranslate ? (breadcrumb.label | translate) : breadcrumb.label }}
              <ng-container *ngTemplateOutlet="helperService.getBreadCrumbContent(breadcrumb.label)"></ng-container>
            </span>
          </ng-container>
        </a>
      </li>
    </ng-container>
  </ol>
</nav>

<ng-template #loading>
  <img src="/assets/img/loading-dots.svg" alt="loading" />
</ng-template>
