import { Component, OnInit } from '@angular/core';
import { ComparisonType } from '../../enums/comparison-type';
import { CustomRuleValidator } from '../../helpers/custom-rule-validator';
import { ICustomRule } from '../../interfaces/i-custom-rule';
import { ICustomRuleEntity } from '../../interfaces/i-custom-rule-entity';
import { ICustomRuleGroup } from '../../interfaces/i-custom-rule-group';
import { IDialog } from '../../interfaces/i-dialog';
import { IRestService } from '../../interfaces/i-rest-service';

@Component({
  selector: 'lib-custom-rules',
  templateUrl: './custom-rules.component.html',
  styleUrls: ['./custom-rules.component.scss'],
})
export class CustomRulesComponent
  implements OnInit, IDialog<{ service: IRestService<ICustomRuleGroup>; obj: ICustomRuleEntity }>
{
  data: { service: IRestService<ICustomRuleGroup>; obj: ICustomRuleEntity };
  ruleGroups: ICustomRuleGroup[] = [];
  ruleGroup: ICustomRuleGroup;
  ruleValidator = new CustomRuleValidator();
  testValue: string;

  get obj() {
    return this.data.obj;
  }

  constructor() {}

  ngOnInit(): void {
    this.data.service.search({}).subscribe(ruleGroups => {
      this.ruleGroups = ruleGroups;
    });
    this.ruleGroup = this.newRule();
  }

  addRule() {
    this.ruleGroup.rules.push({
      property: null,
      comparison: null,
      conclusion: null,
      andOr: true,
      prefix: this.obj.ruleType.prefix,
      type: null,
    });
  }

  deleteRule(rule: ICustomRule) {
    this.ruleGroup.rules.remove(rule);
  }

  isValid() {
    return this.ruleGroup.rules.all(r => r.valid);
  }

  test() {
    const results = this.obj.execRules([this.ruleGroup]);
    if (results.errors.any()) {
      return (this.testValue = results.errors[0]);
    }
    if (results.messages.any()) {
      return (this.testValue = 'Rule executed successfully and returned a message');
    }
    if (!results.messages.any()) {
      return (this.testValue = 'Rule executed successfully and did not return a message');
    }
  }

  save() {
    const body = {
      name: this.ruleGroup.name,
      message: this.ruleGroup.message,
      rules: JSON.stringify(this.ruleGroup.rules),
      level: this.ruleGroup.level,
    };
    if (this.ruleGroup.id === null) {
      this.data.service.create(body).subscribe(x => {
        this.ruleGroup.id = x.id;
        this.ruleGroups.push(this.ruleGroup);
      });
    } else {
      this.data.service.update(this.ruleGroup.id, body).subscribe();
    }
  }

  delete(rule: ICustomRuleGroup) {
    this.data.service.delete(rule.id).subscribe(() => this.ruleGroups.remove(rule));
  }

  newRule(): ICustomRuleGroup {
    return {
      id: null,
      rules: [
        {
          property: null,
          comparison: null,
          conclusion: null,
          andOr: true,
          prefix: this.obj.ruleType.prefix,
          type: null,
        },
      ],
      level: 1,
      message: null,
      name: null,
    };
  }
}
