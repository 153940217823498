import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Mutable, WritableKeys, getEnumValues, nameOf } from '@oeo/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { measurementValidators } from '../../../core/validators/measurementValidator';
import { GlassType } from '../../enums/glass-type';
import { Glass } from '../../models/glass';
import { GlassService } from '../../services/glass.service';

@Component({
  selector: 'lib-glass-info',
  templateUrl: './glass-info.component.html',
  styleUrls: ['./glass-info.component.scss', '../info.component.scss'],
})
export class GlassInfoComponent implements OnInit, OnDestroy {
  glass: Glass;
  private _destroy$ = new Subject<void>();
  formGroup = new FormGroup<{height?: FormControl<number>, width?: FormControl<number>}>({});
  top = 16;
  right = 16;

  constructor(private glassService: GlassService) {}

  ngOnInit(): void {
    this.glassService.activeGlass$.pipe(takeUntil(this._destroy$)).subscribe(glass => {
      this.glass = glass;
      if (this.glass) {
        this.setupForm();
      }
    });
  }

  get types() {
    return getEnumValues(GlassType);
  }

  get height() {
    return this.formGroup.get(nameOf((_: Glass) => _.height));
  }

  get width() {
    return this.formGroup.get(nameOf((_: Glass) => _.width));
  }

  setupForm() {
    for (const control in this.formGroup.controls) {
      this.formGroup.removeControl(control);
    }

    this.formGroup.addControl(
      nameOf((_: Glass) => _.height),
      new FormControl(
        { value: this.glass.height.toDimension('frame', this.glass.unitOfMeasure), disabled: true },
        measurementValidators(this.glass.unitOfMeasure)
      )
    );

    this.formGroup.addControl(
      nameOf((_: Glass) => _.width),
      new FormControl(
        { value: this.glass.width.toDimension('frame', this.glass.unitOfMeasure), disabled: true },
        measurementValidators(this.glass.unitOfMeasure)
      )
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  applyChange(component: HTMLInputElement, control: AbstractControl, value: string): void {
    const controlName = Object.keys(this.formGroup.controls).first((key) => this.formGroup.get(key) === control);
    control.patchValue(value);
    if (control.valid) {
      /* FIXME: This seems wrong -- both the height and width property are getters, they have no setters */
      this.glass[controlName as WritableKeys<Glass>] = value.fromDimension('frame', this.glass.unitOfMeasure) as unknown as never;
    }
    control.patchValue(
      (component.value = (this.glass[controlName as keyof Glass] as number).toDimension('frame', this.glass.unitOfMeasure))
    );
  }

  close(){
    this.glassService.deactivate()
  }
}
