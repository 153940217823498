import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrepsComponent } from './preps.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { DoorPrepCategoryCodeService } from '../core/services/door-prep-category-code.service';
import { PrepCategoryService } from '../core/services/door-prep-category.service';
import { DoorPrepCodeService } from '../core/services/door-prep-code.service';
import { DoorPrepsComponent } from './door-preps/door-preps.component';
import { FramePrepsComponent } from './frame-preps/frame-preps.component';
import { PrepComponent } from './prep/prep.component';
import { DoorPrepService } from '../core/services/door-prep.service';
import { PrepCategoryLocationService } from '../core/services/door-prep-category-location.service';
import { LocationComponent } from './location/location.component';
import { FramePrepCategoryCodeService } from '../core/services/frame-prep-category-code.service';
import { FramePrepCodeService } from '../core/services/frame-prep-code.service';
import { FramePrepService } from '../core/services/frame-prep.service';
import { LookupsService } from '../core/services/lookups.service';
import { ProductCategoryService } from '../core/services/door-product-category.service';
import { HardwareComponent } from './hardware/hardware.component';
import { ProductCategoryPrepCategoryService } from '../core/services/door-product-category-prep-category.service';
import { GenericLocationsComponent } from './generic-locations/generic-locations.component';
import { GenericHardwareTypeComponent } from './generic-hardware-type/generic-hardware-type.component';

@NgModule({
  declarations: [
    PrepsComponent,
    DoorPrepsComponent,
    FramePrepsComponent,
    PrepComponent,
    LocationComponent,
    GenericLocationsComponent,
    GenericHardwareTypeComponent,
    HardwareComponent,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CoreModule],
  providers: [
    DoorPrepCategoryCodeService,
    PrepCategoryService,
    DoorPrepCodeService,
    DoorPrepService,
    PrepCategoryLocationService,
    FramePrepCategoryCodeService,
    FramePrepCodeService,
    FramePrepService,
    LookupsService,
    ProductCategoryService,
    ProductCategoryPrepCategoryService,
  ],
})
export class PrepsModule {}
