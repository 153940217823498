import { Template } from '../abstracts/template';
import { FrameElevation } from '../models/frame-elevation';
import { Stick } from '../models/stick';
import { JointType } from '../enums/joint-type';
import { Orientation } from '../enums/orientation';
import { StickType } from '../enums/stick-type';
import { StickSubtype } from '../enums/stick-subtype';
import { DimensionType } from '../../core/enums/dimension-type';
import { guid } from '@oeo/common';

export class BLF06HM extends Template {
  private _d: number;
  private _e: number;
  private _f: number;
  private _g: number;
  private _h: number;
  private _i: number;

  dimensions = {
    A: { type: DimensionType.Horizontal, get: () => this.defaultFace },
    B: {
      type: DimensionType.Horizontal,
      get: () => this.width - this.dimensions.A.get() - this.dimensions.C.get(),
    },
    C: { type: DimensionType.Horizontal, get: () => this.defaultFace },
    D: {
      type: DimensionType.Vertical,
      get: () => this._d,
      set: (value: number) => {
        if (!this.headDimensions.includes(value)) {
          return;
        }
        const diff = this._d - value;
        this._d = value;
        this._e += diff;
        this.update();
      },
      hint: `Head dimension must be either ${this.headDimensions
        .map(d => d.toDimension(this.dimensionType, this.unitOfMeasure))
        .join(' or ')}`,
    },
    E: {
      type: DimensionType.Vertical,
      get: () => this._e,
      set: (value: number) => {
        const diff = this._e - value;
        this._e = value;
        this._g += diff;
        this.update();
      },
    },
    F: {
      type: DimensionType.Vertical,
      get: () => this._f,
      set: (value: number) => {
        const diff = this._f - value;
        this._f = value;
        this._g += diff;
        this.update();
      },
    },
    G: {
      type: DimensionType.Vertical,
      get: () => this._g,
      set: (value: number) => {
        const diff = this._g - value;
        this._g = value;
        this._i += diff;
        this.update();
      },
    },
    H: {
      type: DimensionType.Vertical,
      get: () => this._h,
      set: (value: number) => {
        const diff = this._h - value;
        this._h = value;
        this._i += diff;
        this.update();
      },
    },
    I: {
      type: DimensionType.Vertical,
      get: () => this._i,
      set: (value: number) => {
        const diff = this._i - value;
        this._i = value;
        this._g += diff;
        this.update();
      },
    },
    J: {
      type: DimensionType.Vertical,
      get: () => this.defaultFace,
    },
  };

  constructor(frameElevation: FrameElevation) {
    super(frameElevation);
    this._d = this.defaultFace;
    this._f = this.defaultFace;
    this._h = this.defaultFace;
    this._g = this._i = (
      (this.height -
        (this.dimensions.D.get() + this.dimensions.F.get() + this.dimensions.H.get() + this.dimensions.J.get())) /
      3
    ).cleanAsDimension(this.dimensionType, this.unitOfMeasure);
    this._e =
      this.height -
      (
        this.dimensions.D.get() +
        this.dimensions.F.get() +
        this.dimensions.G.get() +
        this.dimensions.H.get() +
        this.dimensions.I.get() +
        this.dimensions.J.get()
      ).cleanAsDimension(this.dimensionType, this.unitOfMeasure);
  }

  protected drawObject(container: SVGSVGElement) {
    this.drawLeft(container);
    this.drawTop(container);
    this.drawBottom(container);
    this.drawRight(container);
    this.drawTopMiddle(container);
    this.drawBottomMiddle(container);
  }

  private drawLeft(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: 0, y: this.dimensions.D.get() === this.headFace ? this.headFace / 2 : 0 },
          { x: 0, y: this.height },
          { x: this.dimensions.A.get(), y: this.height - this.dimensions.J.get() },
          { x: this.dimensions.A.get(), y: this.dimensions.D.get() },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Mitered,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawTop(container: SVGSVGElement) {
    const points =
      this._d === this.headFace
        ? [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width, y: this.headFace / 2 },
            { x: this.width - this.dimensions.C.get(), y: this.dimensions.D.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.D.get() },
            { x: 0, y: this.headFace / 2 },
          ]
        : [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width - this.dimensions.C.get(), y: this.dimensions.D.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.D.get() },
          ];
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points,
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: this.dimensions.D.get() === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        rightJointType: this.dimensions.D.get() === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawRight(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.width - this.dimensions.C.get(), y: this.dimensions.D.get() },
          { x: this.width - this.dimensions.C.get(), y: this.height - this.dimensions.J.get() },
          { x: this.width, y: this.height },
          { x: this.width, y: this._d === this.headFace ? this.headFace / 2 : 0 },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Mitered,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawBottom(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: 0, y: this.height },
          { x: this.width, y: this.height },
          { x: this.width - this.dimensions.C.get(), y: this.height - this.dimensions.J.get() },
          { x: this.dimensions.A.get(), y: this.height - this.dimensions.J.get() },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Mitered,
        rightJointType: JointType.Mitered,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawTopMiddle(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.dimensions.A.get(), y: this.dimensions.D.get() + this.dimensions.E.get() },
          {
            x: this.dimensions.A.get(),
            y: this.dimensions.D.get() + this.dimensions.E.get() + this.dimensions.F.get(),
          },
          {
            x: this.width - this.dimensions.C.get(),
            y: this.dimensions.D.get() + this.dimensions.E.get() + this.dimensions.F.get(),
          },
          {
            x: this.width - this.dimensions.C.get(),
            y: this.dimensions.D.get() + this.dimensions.E.get(),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawBottomMiddle(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.dimensions.A.get(),
            y: this.dimensions.D.get() + this.dimensions.E.get() + this.dimensions.F.get() + this.dimensions.G.get(),
          },
          {
            x: this.dimensions.A.get(),
            y:
              this.dimensions.D.get() +
              this.dimensions.E.get() +
              this.dimensions.F.get() +
              this.dimensions.G.get() +
              this.dimensions.H.get(),
          },
          {
            x: this.width - this.dimensions.C.get(),
            y:
              this.dimensions.D.get() +
              this.dimensions.E.get() +
              this.dimensions.F.get() +
              this.dimensions.G.get() +
              this.dimensions.H.get(),
          },
          {
            x: this.width - this.dimensions.C.get(),
            y: this.dimensions.D.get() + this.dimensions.E.get() + this.dimensions.F.get() + this.dimensions.G.get(),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }
}
