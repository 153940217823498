import { Injectable } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd, NavigationExtras } from '@angular/router'
import { filter, distinctUntilChanged, tap, mergeMap, map } from 'rxjs/operators'
import { BehaviorSubject, Observable, combineLatest, from } from 'rxjs'
import { RouteIds } from '../../models/routeIds.model'
import { AppInsightsService } from '@overtur/app-insights'

@Injectable({
  providedIn: 'root'
})
export class RoutingService {
  ids = new RouteIds()
  private _ids: BehaviorSubject<RouteIds> = new BehaviorSubject(new RouteIds())
  ids$: Observable<RouteIds> = this._ids.asObservable()
  getRouteIds = (): RouteIds => this._ids.getValue()
  private readyToNavigate$ = new BehaviorSubject(true)
  event!: any
  activatedRoute!: ActivatedRoute

  routeData: any
  firstChildData: any
  firstChildRoot!: string
  currentUrl!: string
  constructor(public _route: ActivatedRoute, public router: Router, public appInsights: AppInsightsService) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event) => {
          this.event = event
          this.activatedRoute = this._route
          this.routeData = this.activatedRoute.snapshot.data
          this.logPageView(this.router, this._route)
        }),
        distinctUntilChanged()
      )
      .subscribe((event) => {
        this.event = event
        this.setupIds(this._route.root)
      })

    combineLatest([this.readyToNavigate$, this.navigationRequests$])
      .pipe(
        filter(([readyToNavigate]) => readyToNavigate && this.navigationRequests.length > 0),
        mergeMap(([, [firstRequestInQueue]]) => {
          this.readyToNavigate$.next(false)
          this.#navigationRequests.next(this.navigationRequests.slice(1))
          return from(this.router.navigate(...firstRequestInQueue))
        })
      )
      .subscribe(() => this.readyToNavigate$.next(true))
  }

  setupIds(route: ActivatedRoute): void {
    if (!route) return
    const params = { ...route.snapshot.params }
    if (route.snapshot.params['estimateId']) this.ids.estimateId = params['estimateId']
    if (route.snapshot.params['hwSetId']) this.ids.hwSetId = params['hwSetId']
    if (route.snapshot.params['quoteId']) this.ids.quoteId = params['quoteId']
    if (route.snapshot.params['notificationId']) this.ids.notificationId = params['notificationId']

    const payload = {
      estimateId: this.ids.estimateId,
      hwSetId: this.ids.hwSetId,
      quoteId: this.ids.quoteId,
      notificationId: this.ids.notificationId
    }
    this._ids.next(payload)
    if (route.firstChild) {
      this.firstChildData = route.firstChild.snapshot.data
      this.firstChildRoot = route.firstChild.root?.firstChild?.snapshot.url[0]?.path ?? ''
      return this.setupIds(route.firstChild)
    }
  }

  #navigationRequests: BehaviorSubject<Array<Parameters<Router['navigate']>>> = new BehaviorSubject([])
  navigationRequests$: Observable<Array<Parameters<Router['navigate']>>> = this.#navigationRequests.asObservable()
  get navigationRequests(): Array<Parameters<Router['navigate']>> {
    return this.#navigationRequests.getValue()
  }

  /**
   * Adds a navigation request to the queue. If the queue is empty, the navigation request is executed immediately.
   * @param navigationRequestArgs Arguments to pass to the router's navigate method.
   */
  navigate(...navigationRequestArgs: Parameters<Router['navigate']>) {
    this.#navigationRequests.next(this.navigationRequests.concat([navigationRequestArgs]))
  }

  logPageView(router: Router, activeRoute: ActivatedRoute) {
    const name = activeRoute.snapshot.firstChild.routeConfig.path
    const url = router.url
    this.appInsights.appInsights.trackPageView({ name: name, uri: url })
  }
}
