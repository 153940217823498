import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { nameOf } from '@oeo/common';
import { debounceTime, takeUntil } from 'rxjs';
import { FramePrepReferencePoint } from '../../../enums/frame-prep-reference-point';
import { StickSubtype } from '../../../enums/stick-subtype';
import { FrameElevation } from '../../../models/frame-elevation';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepsComponent } from '../preps.component';
import { AnchorPrepComponent } from './anchor-prep.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-head-anchor-prep',
  templateUrl: './anchor-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class HeadAnchorPrepComponent extends AnchorPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;
  subTypes = [StickSubtype.Head];
  isSpecial: boolean = false;

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(
      prepsComponent,
      'headAnchorPrep',
      FramePrepCategoryIds.HEAD_ANCHOR,
      'Head Anchor',
      [FramePrepReferencePoint.LCL, FramePrepReferencePoint.RCL],
      false
    );
  }

  ngOnInit(): void {
    this.init();
    this._referencePoint =
      this.referencePoints.first(c => c === this.prep.referencePoint) ?? FramePrepReferencePoint.FCL;
  }

  ngAfterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  ngOnDestroy(): void {
    super.destroy();
  }
}
