import { Component, OnInit } from '@angular/core'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'oa-logout',
  template: ''
})
export class LogoutComponent implements OnInit {
  constructor(private authService: AuthService) {
    window.onbeforeunload = () => {
      //  window.close will only work if the window was programatically open via JS script
      //  otherwise will close will be blocked by browswer security
      window.close()
    }
    authService.logout()
  }

  ngOnInit() {}
}
