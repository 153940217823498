import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Lite } from '../../models/cutout-types/lite';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class NType extends DoorType {
  cutouts: Cutout[];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = this.getWidth();
    const height = this.getHeight()

    this.cutouts = [
      new Lite(
        'Lite',
        this,
        this.shouldFlipCutout() ? DefaultStile(this.door.doorElevation.unitOfMeasure) :
          this.door.actualWidth - width - DefaultStile(this.door.doorElevation.unitOfMeasure),
        uomSwitch('6 7/8"', 'door', this.door.doorElevation.unitOfMeasure),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private getHeight(): number {
    if(this.door.doorElevation.fireRating) {
      return uomSwitch('20"', 'door', this.door.doorElevation.unitOfMeasure)
    }
    const measures = [
      { min: '1"', max: '80"', height: '29"'}, 
      { min: '81"', max: '94"', height: '33"'}, 
      { min: '95"', max: '120"', height: '45"'}
    ]

    const { height } = measures.find(({ min, max }) => 
      this.door.height >= uomSwitch(min, 'door', this.door.doorElevation.unitOfMeasure) && 
      this.door.height <= uomSwitch(max, 'door', this.door.doorElevation.unitOfMeasure)
    )
    
    return uomSwitch(height, 'door', this.door.doorElevation.unitOfMeasure)
  }

  private getWidth(): number {
    if(this.door.doorElevation.fireRating) {
      return uomSwitch('5"', 'door', this.door.doorElevation.unitOfMeasure)
    }
    return uomSwitch('6"', 'door', this.door.doorElevation.unitOfMeasure);  
  }
}
