import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { CoreConstants } from '../core.constants';
import { UnitOfMeasure } from '../enums/unit-of-measure';

export function measurementValidators(unitOfMeasure: UnitOfMeasure): ValidatorFn[] {
  return [
    Validators.required,
    Validators.minLength(1),
    unitOfMeasureValidator(unitOfMeasure),
    isNotNaNValidator(unitOfMeasure),
    isNotZeroValidator(unitOfMeasure),
  ];
}

export function unitOfMeasureValidator(unitOfMeasure: UnitOfMeasure): ValidatorFn {
  return Validators.pattern(
    unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.regex.imperial : CoreConstants.regex.metric
  );
}

export function isNotNaNValidator(unitOfMeasure: UnitOfMeasure): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = (control.value as string)?.fromDimension('door', unitOfMeasure);
    return isNaN(value) ? { isNaN: true } : null;
  };
}

export function isNotZeroValidator(unitOfMeasure: UnitOfMeasure): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const value = (control.value as string).fromDimension('door', unitOfMeasure);
    return value <= 0 ? { isZero: true } : null;
  };
}

export function isMinimumValidator(type: 'door' | 'frame', unitOfMeasure: UnitOfMeasure, minValue: string  ): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const value = (control.value as string).fromDimension(type, unitOfMeasure);
    const valueToCompare = minValue.fromDimension(type, unitOfMeasure);
    return value < valueToCompare ? { lessThanMinValue: true } : null;
  };
}
