import { inject } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { map, Observable } from 'rxjs'
import { CustomerService } from '../../services/customer/customer.service'

export const unauthorizedGuard = (): Observable<boolean | UrlTree> => {
  const customerService = inject(CustomerService)
  const router = inject(Router)
  return customerService.unauthorized$.pipe(map((res) => (res ? true : router.parseUrl('/landing'))))
}
