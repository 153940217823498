import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap, map, Subject } from 'rxjs';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { BaseElevationRestService } from './rest.service';
import { IElevation } from '../interfaces/i-elevation';
import { IQueryParams } from '../interfaces/i-query-params';
import { IValidationMessage } from '../interfaces/i-validation-message';
import { FrameElevation } from '../../frame-elevation/models/frame-elevation';
import { cloneDeep } from 'lodash';

@Injectable()
export class FrameElevationService extends BaseElevationRestService<FrameElevation> {
  messages: IValidationMessage[] = []

  #elevation: FrameElevation

  set elevation(frameElevation: FrameElevation | null) {
    this.#elevation = cloneDeep(frameElevation);
  }

  get elevation(): FrameElevation {
    return this.#elevation;
  }

  constructor(http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) environment: IEnvironment) {
    super(http, `${environment.elevations.apiUrl}v1/frameElevations`);
  }

  frameElevations$ = new BehaviorSubject<IElevation[]>([])
  search(query: IQueryParams): Observable<IElevation[]> {
      return super.search(query).pipe(
        map(elevations=> elevations.sort((a, b)=> new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())),
        tap((elevations)=>this.frameElevations$.next(elevations))
      )
  }
}
