<lib-dialog>
  <div class="content-warning">
    <mat-icon [svgIcon]="iconName"></mat-icon>
    <h3>{{ title | translate }}</h3>
    <p [innerHtml]="subTitle"></p>
  </div>

  <div class="buttons">
    <button *ngIf="showGoBack"
    aria-label="'Cancel'"
    mat-stroked-button
    (click)="goBack()">
    {{ 'cancel' | translate }}
  </button>

    <button aria-label="confirmText"
      class="mat-flat-button"
      color="primary"
      mat-flat-button
      (click)="confirm()">
        {{ confirmText }}
  </button>
  </div>
</lib-dialog>
