import { Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { IPrep } from '../../../../core/interfaces/i-prep';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { DoorPrepComponent } from '../door-prep/door-prep.component';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';

@Component({
  selector: 'lib-surface-bolt',
  templateUrl: './surface-bolt.component.html',
  styleUrls: ['./surface-bolt.component.scss', '../prep-base.component.scss'],
})
export class SurfaceBoltComponent extends PrepBaseComponent{
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent;

  public get code(): IPrepCode {
    return this.prep?.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }
  public get prep(): IPrep {
    return this.door.surfaceBoltPrep;
  }
  public set prep(value: IPrep) {
    this.door.surfaceBoltPrep = value;
    this.prepsComponent.configService.updateCurrentDoor(this.door)
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, 24);
  }
}
