import { Approval } from '../../core/enums/approval';
import { DoorFireRating } from '../enums/door-fire-rating';
import { DoorSeries } from '../enums/door-series';
import { DoorThickness } from '../enums/door-thickness';

export function seriesFireRatings(fireRatings: DoorFireRating[], series: DoorSeries) {
  if (series === DoorSeries.DF) {
    fireRatings.remove(DoorFireRating._180);
    fireRatings.remove(DoorFireRating._20M);
    fireRatings.remove(DoorFireRating._45);
    fireRatings.remove(DoorFireRating._60);
    fireRatings.remove(DoorFireRating._90);
  }
}

export function seriesThicknesses(thicknesses: DoorThickness[], series: DoorSeries) {
  if (series !== DoorSeries.DE) {
    thicknesses.remove(DoorThickness._138);
  }
}

export function seriesApprovals(approvals: Approval[], series: DoorSeries) {
  if (series !== DoorSeries.DE) {
    approvals.remove(Approval.STC50);
    approvals.remove(Approval.STC52);
  }
}
