import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'

import { filter, distinctUntilChanged, takeUntil, mergeMap, tap } from 'rxjs/operators'

import { BreadCrumb } from '../../../models/breadcrumb'
import { Subject } from 'rxjs'
import { EstimatesService } from '../../../services/estimates/estimates.service'
import { HardwareSetService } from '../../../services/hardware-set/hardware-set.service'
import { QuotesService } from '../../../services/quotes/quotes.service'
import { FlagsService } from '../../../services/flags/flags.service'
import { Features } from '../../../models/features.model'
import { AnnouncementsService } from '../../../services/announcements/announcements.service'
import { IndexedDBService } from '../../../services/indexedDB/indexed-db.service'
import { HelperService } from '../../../services/helper/helper.service'

@Component({
  selector: 'oa-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  estimateName: string
  hwSetName: string
  quoteName: string
  announcementName: string
  flags: Features
  beginPhasedOrder: boolean

  breadcrumbs: { label: string; url: string; params: [] }[]
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private estimatesService: EstimatesService,
    private hardwareSetService: HardwareSetService,
    private quotesService: QuotesService,
    private flagsService: FlagsService,
    private announcementsService: AnnouncementsService,
    private indexedDB: IndexedDBService,
    public helperService: HelperService
  ) {}

  ngOnInit() {
    this.flagsService.flags$.pipe(takeUntil(this.destroyed$)).subscribe((res) => (this.flags = res))
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        mergeMap((res) =>
          this.estimatesService.estimate$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
            tap((estimate) => (this.estimateName = estimate ? estimate.name : null))
          )
        ),
        mergeMap((res) =>
          this.hardwareSetService.hardwareSet$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
            tap((hwSet) => (this.hwSetName = hwSet ? hwSet.name : null))
          )
        ),
        mergeMap((res) =>
          this.quotesService.quote$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
            tap((quote) => (this.quoteName = quote ? quote.name : null))
          )
        ),
        mergeMap((res) =>
          this.announcementsService.announcement$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
            tap((announcement) => (this.announcementName = announcement ? announcement.name : null))
          )
        ),
        mergeMap((res) =>
          this.indexedDB.beginPhasedOrder$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
            tap((beginPhasedOrder) => (this.beginPhasedOrder = beginPhasedOrder))
          )
        )
      )
      .subscribe((res) => {
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root)
      })
  }

  buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Array<any> = []): Array<any> {
    let newBreadcrumbs = []

    const breadcrumb = this.createBreadCrumb(route, url)
    newBreadcrumbs = [breadcrumb, ...breadcrumbs]
    if (route.firstChild) {
      const nextUrl = this.createPathNextUrl(route, url)
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs)
    }
    return newBreadcrumbs
  }

  createBreadCrumb(route: ActivatedRoute, url: string): BreadCrumb {
    let label: string
    let canTranslate: boolean
    let nextUrl = this.createPathNextUrl(route, url)
    if (route.routeConfig && route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
      label = this.getLabel(route.routeConfig.data['breadcrumb'])
      canTranslate = this.canTranslate(route.routeConfig.data['breadcrumb'])
      if (label === 'checkout') {
        if (!this.flags.enablePhasedOrdering || !this.beginPhasedOrder) {
          nextUrl += '/step-2'
        }
      }
    } else {
      label = 'ignore'
    }
    const breadcrumb = {
      canTranslate: canTranslate,
      label: label,
      url: nextUrl,
      params: route.snapshot.queryParams ? route.snapshot.queryParams : null
    }
    return breadcrumb
  }

  createPathNextUrl(route: ActivatedRoute, url: string): string {
    const path = route.snapshot.url.map((segment) => segment.path).join('/')
    const nextUrl = `${url}/${path}`
    return nextUrl
  }

  canTranslate(value: string) {
    return !['Project', 'Estimate', 'Hardware Set', 'Quote'].includes(value)
  }

  getLabel(value: string) {
    switch (value) {
      case 'project':
        return this.estimateName
        break
      case 'estimate':
        return this.estimateName
        break
      case 'hardwareSet':
        return this.hwSetName
        break
      case 'Quote':
        return this.quoteName
        break
      case 'orderHistory':
        return this.flags.internal ? 'Draft Orders' : value
        break
      case 'announcement':
        return this.announcementName
        break
      default:
        return value
        break
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
