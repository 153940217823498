import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Intersectable } from '../abstracts/intersectable';
import { BaseIntersectableService, IntersectableKeyboardEventModifier } from '../abstracts/base-intersectable-service';
import { DialogService } from '../../core/services/dialog.service';
import { IntersectableExport } from '../models/exports/intersectable-export';

@Injectable()
export class IntersectableService extends BaseIntersectableService implements OnDestroy {
  private _lastActionUndo = false;
  private _nextHistory: IntersectableExport[] = [];
  private _history: ReadonlyArray<IntersectableExport>[] = [];

  private _lastActionRedo = false;
  private _undoHistory: ReadonlyArray<IntersectableExport>[] = [];

  private _activeIntersectable: Intersectable;
  get activeIntersectable() {
    return this._activeIntersectable;
  }
  private _activeIntersectable$ = new Subject<Intersectable>();
  get activeIntersectable$() {
    return this._activeIntersectable$.asObservable();
  }

  private _intersectables: Intersectable[] = [];
  get intersectables() {
    return this._intersectables as ReadonlyArray<Intersectable>;
  }
  private _intersectables$ = new Subject<Intersectable[]>();
  get intersectables$() {
    return this._intersectables$.asObservable();
  }
  private _undo$ = new Subject<IntersectableExport[]>();
  get undo$() {
    return this._undo$.asObservable();
  }
  private _redo$ = new Subject<IntersectableExport[]>();
  get redo$() {
    return this._redo$.asObservable();
  }

  constructor(dialogService: DialogService) {
    super(dialogService);
    this.registerHotkey('keyup', 'Delete', IntersectableKeyboardEventModifier.None, this.delete);
    this.registerHotkey('keydown', 'Tab', IntersectableKeyboardEventModifier.None, this.tab);
    this.registerHotkey('keydown', 'Tab', IntersectableKeyboardEventModifier.ShiftKey, this.tabShift);
    this.registerHotkey('keydown', 'Escape', IntersectableKeyboardEventModifier.None, this.escape);
    this.registerHotkey('keydown', 'KeyZ', IntersectableKeyboardEventModifier.CtrlKey, this.undo);
    this.registerHotkey('keydown', 'KeyY', IntersectableKeyboardEventModifier.CtrlKey, this.redo);
  }

  add(intersectable: Intersectable) {
    this._intersectables$.next((this._intersectables = this._intersectables.concat([intersectable])));
    this.activate(intersectable);
  }

  activate(intersectable: Intersectable) {
    const activeIntersectable = intersectable === this._activeIntersectable ? null : intersectable;
    this._intersectables.forEach(s => (s.active = activeIntersectable === s));
    this._activeIntersectable$.next((this._activeIntersectable = activeIntersectable));
    if (this._activeIntersectable == null) {
      return;
    }
    this._activeIntersectable.element.focus();
  }

  delete = () => {
    if (this._activeIntersectable == null || !this._activeIntersectable.isEditable) {
      return;
    }
    this._activeIntersectable.destroy();
    this._intersectables$.next(
      (this._intersectables = this._intersectables.filter(intersectable => intersectable !== this._activeIntersectable))
    );
    this._activeIntersectable$.next((this._activeIntersectable = null));
  };

  tab = () => {
    if (this._intersectables.length === 0) {
      return;
    }
    const index = this._intersectables.indexOf(this._activeIntersectable) + 1;
    this.activate(this._intersectables[index.mod(this._intersectables.length)]);
  };

  tabShift = () => {
    if (this._intersectables.length === 0) {
      return;
    }
    const index = this._intersectables.indexOf(this._activeIntersectable) - 1;
    this.activate(this._intersectables[index.mod(this._intersectables.length)]);
  };

  escape = () => {
    this.activate(null);
  };

  ngOnDestroy() {
    this.reset([]);
    super.ngOnDestroy();
  }

  reset(intersectables: Intersectable[]) {
    this._intersectables.forEach(i => i.destroy());
    this._activeIntersectable$.next((this._activeIntersectable = null));
    this._intersectables$.next((this._intersectables = intersectables));
  }

  addToHistory = () => {
    if (!this._lastActionRedo && !this._lastActionUndo) {
      this._undoHistory = [];
    }
    this._lastActionRedo = false;
    if (this._lastActionUndo === true) {
      this._nextHistory = this.intersectables.map(i => i.toJSON());
      return (this._lastActionUndo = false);
    }
    if (this._history.length === 100) {
      this._history.shift();
    }
    this._history.push(this._nextHistory);
    this._nextHistory = this.intersectables.map(i => i.toJSON());
  };

  redo = () => {
    if (this._undoHistory.length === 0) {
      return;
    }
    this._lastActionRedo = true;
    this._lastActionUndo = false;
    this._redo$.next(this._undoHistory.pop() as IntersectableExport[]);
  };

  undo = () => {
    if (this._history.length === 0) {
      return;
    }
    this._lastActionUndo = true;
    this._lastActionRedo = false;
    if (this._undoHistory.length === 100) {
      this._undoHistory.shift();
    }
    this._undoHistory.push(this._nextHistory);
    this._undo$.next(this._history.pop() as IntersectableExport[]);
  };
}
