import { Injectable } from '@angular/core'
import { Observable, of, forkJoin, BehaviorSubject } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map, take, tap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { ExtendedPurchaseOrder, PoInfo, PurchaseOrder } from '../../models'
import { TranslateService } from '@ngx-translate/core'
import { CustomerService } from '../customer/customer.service'

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  private hasSubmittedOrders!: boolean

  private _purchaseOrders: BehaviorSubject<PurchaseOrder[]> = new BehaviorSubject([] as PurchaseOrder[])
  public purchaseOrders$: Observable<PurchaseOrder[]> = this._purchaseOrders.asObservable()

  private _loading = new BehaviorSubject(false)
  public loading$: Observable<boolean> = this._loading.asObservable()

  private _loaded = new BehaviorSubject(false)
  public loaded$: Observable<boolean> = this._loaded.asObservable()

  constructor(
    private authHttp: HttpClient,
    private translateService: TranslateService,
    private customerService: CustomerService
  ) {
    this.customerService.customer$.subscribe(() => {
      this._loaded.next(false)
    })
  }

  // returns the current loaded state of purchase order
  getLoadedState(): boolean {
    return this._loaded.getValue()
  }

  postPurchaseOrder(payload: PurchaseOrder[]) {
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders'
    return this.authHttp.post(url, payload)
  }

  getPurchaseOrderByID(id: number) {
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders/' + id
    return this.authHttp.get<PurchaseOrder>(url)
  }

  getIndirectOrderById(id: number) {
    const url = environment.onlineOrderingApiUrl + 'IndirectPurchaseOrderExtendedData/' + id
    return this.authHttp.get(url)
  }

  /**
   * Gets most recent purchase orders for the current user
   */
  getMostRecentPurchaseOrders() {
    this._loading.next(true)
    this.getPurchaseOrders()
      .pipe(take(1))
      .subscribe((purchaseOrders: PurchaseOrder[]): void => {
        this._purchaseOrders.next(purchaseOrders)
        this._loading.next(false)
        this._loaded.next(true)
      })
  }

  getPurchaseOrders(): Observable<PurchaseOrder[]> {
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders?submitted=true'
    return this.authHttp.get<PurchaseOrder[]>(url)
  }

  getPurchaseOrdersForEstimate(id: number): Observable<PurchaseOrder[]> {
    const url = environment.onlineOrderingApiUrl + `PurchaseOrders?estimateId=${id}`
    return this.authHttp.get<PurchaseOrder[]>(url)
  }

  getLastCreatedPurchaseOrdersForEstimate(poIds: number[]) {
    const pos$ = poIds?.map((_id) => this.getPurchaseOrderByID(_id))
    return forkJoin(pos$)
  }

  /**
   * Gets submitted processed purchase orders observable
   * @returns submitted processed purchase orders
   */
  getSubmittedProcessedPurchaseOrders(): Observable<PurchaseOrder[]> {
    return this.purchaseOrders$.pipe(
      map((purchaseOrders: PurchaseOrder[]): PurchaseOrder[] => {
        return purchaseOrders
      })
    )
  }

  updatePurchaseOrderByID(id: number, payload: PurchaseOrder) {
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders/' + id
    const body = payload
    return this.authHttp.put(url, body)
  }

  createPoBreakdownPatch(po: ExtendedPurchaseOrder): Partial<ExtendedPurchaseOrder> {
    return {
      projectName: po.projectName,
      projectCity: po.projectCity,
      projectState: po.projectState,
      billingAddressId: po.billingAddressId,
      shippingMethodId: po.shippingMethodId,
      shippingAddressId: po.shippingAddressId,
      packingSlipInstructions: po.packingSlipInstructions,
      shippingCommentsToCarrier: po.shippingCommentsToCarrier,
      specialInstructions: po.specialInstructions,
      doNotShipBeforeDate: po.doNotShipBeforeDate,
      requestedShipDate: po.requestedShipDate,
      purchaseOrderNumber: po.purchaseOrderNumber,
      projectRegistrationNumber:
        po.elockPrnOption === 'projectRegistrationNumber' ? po.projectRegistrationNumber : null,
      pacsPartnerName: po.elockPrnOption === 'pacsPartnerName' ? po.pacsPartnerName : null,
      doorFrameServiceInstructions: po.doorFrameServiceInstructions
    }
  }

  patchPurchaseOrder(
    id: number,
    payload: Pick<PoInfo, 'rckShippingAddressId' | 'residentialConstructionKeyed'>,
    shouldEmailPurchaseOrder: boolean = false
  ) {
    const queryParams = '?shouldEmailPurchaseOrder=' + shouldEmailPurchaseOrder
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders/' + id + queryParams
    const body = payload
    return this.authHttp.patch(url, body)
  }

  public getPurchaseOrderPdf(id: number): Observable<any> {
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders/' + id
    const contentType = 'application/pdf'
    let headers = new HttpHeaders()
    headers = headers.set('Accept', contentType)
    let language = 'en'
    language = this.translateService.currentLang
    headers = headers.set('Accept-Language', language)
    return this.authHttp.get(url, { headers: headers, responseType: 'blob' })
  }

  public getOrderStatusUrl(purchaseOrderNumber?: string): Observable<string> {
    const url =
      environment.onlineOrderingApiUrl +
      `PurchaseOrders/OrderStatusUrl${
        purchaseOrderNumber ? '?purchaseOrderNumber=' + encodeURIComponent(purchaseOrderNumber) : ''
      }`
    return <Observable<string>>this.authHttp.get(url)
  }

  public hasPlacedOrder(): Observable<boolean> {
    if (this.hasSubmittedOrders) {
      return of(true)
    }

    return this.getPurchaseOrders().pipe(
      map((values: Array<any>) => {
        if (values.length === 0) {
          return false
        }
        if (values.some((v) => v.status !== 'Draft')) {
          this.hasSubmittedOrders = true
          return true
        }
        return false
      })
    )
  }

  hasProExpressItems(POs: PurchaseOrder[]): {
    proExpress: boolean
    nonProExpress: boolean
  } {
    let hasProExpressItems = false
    let hasNonProExpressItems = false
    POs.forEach((po: PurchaseOrder) => {
      if (!po.lineItems) {
        return
      }
      const proExpressItems = po.lineItems.filter((line) => line.proExpress)
      const nonProExpressItems = po.lineItems.filter((line) => !line.proExpress)
      hasProExpressItems = hasProExpressItems || proExpressItems.length > 0
      hasNonProExpressItems = hasNonProExpressItems || nonProExpressItems.length > 0
    })
    return {
      proExpress: hasProExpressItems,
      nonProExpress: hasNonProExpressItems
    }
  }

  updatePurchaseOrderProductQuantity(purchaseOrderId: number, purchaseOrderLineItemId: number, quantity: number) {
    const url =
      environment.onlineOrderingApiUrl +
      `PurchaseOrders/${purchaseOrderId}/purchaseOrderLineItems/${purchaseOrderLineItemId}`
    return this.authHttp.patch(url, { quantity })
  }

  deletePurchaseOrderProductQuantity(purchaseOrderId: number, purchaseOrderLineItemId: number) {
    const url =
      environment.onlineOrderingApiUrl +
      `PurchaseOrders/${purchaseOrderId}/purchaseOrderLineItems/${purchaseOrderLineItemId}`
    return this.authHttp.delete(url)
  }

  addRCKtoPO(payload: { estimateId: number; purchaseOrderId: number; quantity: number }) {
    const _payload: any = payload
    _payload.productId = -4
    _payload.unitOfMeasure = 'EACH'
    const url = environment.onlineOrderingApiUrl + `PurchaseOrders/${payload.purchaseOrderId}/purchaseOrderLineItems`
    return this.authHttp.post(url, _payload)
  }

  isPOProExpress(purchaseOrderSplitCategoryId: number): boolean {
    return purchaseOrderSplitCategoryId === 8
  }

  isFastTrack(purchaseOrderSplitCategoryId: number): boolean {
    return purchaseOrderSplitCategoryId === 16
  }

  isStandardOrder(purchaseOrderSplitCategoryId: number): boolean {
    return purchaseOrderSplitCategoryId === 14
  }

  isADSystems(purchaseOrderSplitCategoryId: number): boolean {
    return purchaseOrderSplitCategoryId === 17
  }

  uploadDocument(purchaseOrderId: number, document: File) {
    const url = `${environment.onlineOrderingApiUrl}PurchaseOrders/${purchaseOrderId}/uploadPOAttachment`

    const formData: FormData = new FormData()
    formData.append('purchaseOrderDocumentsUploadRequestJson', JSON.stringify({ purchaseOrderId: purchaseOrderId }))
    formData.append('uploadedFile', document)
    return this.authHttp.post(url, formData).pipe(take(1)).subscribe()
  }

  uploadCommercialInvoice(purchaseOrderId: number, document: File) {
    const url = `${environment.onlineOrderingApiUrl}PurchaseOrders/${purchaseOrderId}/uploadCommercialInvoice`
    const formData: FormData = new FormData()
    formData.append(
      'purchaseOrderCommercialInvoiceUploadRequestJson',
      JSON.stringify({ purchaseOrderId: purchaseOrderId })
    )
    formData.append('commercialInvoiceFile', document)
    return this.authHttp.post(url, formData).pipe(take(1)).subscribe()
  }

  public purchaseOrderNumberExists(purchaseOrderNumber: string): Observable<boolean> {
    const url =
      environment.onlineOrderingApiUrl +
      'PurchaseOrders/purchaseOrderNumberExists?purchaseOrderNumber=' +
      encodeURIComponent(purchaseOrderNumber)
    return this.authHttp.get<boolean>(url)
  }

  public quotesAppliedToPurchaseOrder(quotesNumberList: string[]) {
    const url = environment.onlineOrderingApiUrl + 'PurchaseOrders/quotesApplied'
    return this.authHttp.post<string[]>(url, quotesNumberList)
  }
}
