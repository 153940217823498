<p>{{'INSTRUCTIONS.selectAllPreps' | translate }} {{type}}</p>
<span class="container">
  <mat-checkbox
    *ngFor="let step of steps"
    [(ngModel)]="step.checked"
    (change)="step$.emit(step);"
    color="primary"
    [disabled]="step?.disabled"
    >{{ step.name }}
  </mat-checkbox>
</span>
