import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DoorStyle } from '../door-elevation/enums/door-style';
import { TemplateType } from '../frame-elevation/enums/template-type';
import { BehaviorSubject, Observable } from 'rxjs';
import { FolderService } from '../core/services/folder.service';
import { Folder } from '../core/models/folder';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../core/interfaces/i-environment';
import { combineLatestWith, map, take } from 'rxjs/operators';
import { FrameElevationService } from '../core/services/frame-elevation.service';
import { DoorElevationService } from '../door-elevation/services/door-elevation-service/door-elevation.service';
import { cloneDeep } from 'lodash';

type FilterProduct = 'Door' | 'Frame';

@Component({
  selector: 'lib-template-library',
  templateUrl: './template-library.component.html',
  styleUrls: ['./template-library.component.scss'],
})
export class TemplateLibraryComponent implements OnInit, AfterViewInit {
  templateDisplayOptions: { name: string }[] = [
    // { name: 'Library Drawings' },
    { name: 'TITLE.myDrawings' },
  ];

  templateDisplayOption = this.templateDisplayOptions[0];

  frameTemplates = Object.keys(TemplateType).map(key => {
    return {
      name: TemplateType[key as keyof typeof TemplateType],
      thumbnail: `${this.environment.elevations.blobUrl}templates/${TemplateType[key as keyof typeof TemplateType]}.svg`,
    };
  });
  doorTemplates = Object.keys(DoorStyle).map((key) => {
    return { name: DoorStyle[key as keyof typeof DoorStyle], thumbnail: `${this.environment.elevations.blobUrl}templates/${DoorStyle[key as keyof typeof DoorStyle]}.svg` };
  });

  _filterProduct: FilterProduct = 'Door';
  set filterProduct(filterProduct: FilterProduct) {
    this._filterProduct = filterProduct;
    this.#filters.next({ filterProduct, search: this.filters.search });
  }
  get filterProduct(): FilterProduct {
    return this._filterProduct;
  }

  set search(search: string) {
    this.#filters.next({ filterProduct: this.filters.filterProduct, search: search });
  }
  #folders = new BehaviorSubject<Folder[]>([]);
  folders$: Observable<Folder[]> = this.#folders.asObservable();
  get folders(): Folder[] {return this.#folders.getValue()};

  #filters: BehaviorSubject<{ filterProduct: FilterProduct; search: string }> = new BehaviorSubject({
    filterProduct: 'Door',
    search: '',
  });
  filters$ = this.#filters.asObservable();
  filters = this.#filters.getValue();

  drawings$: Observable<{ id?: number; name: string; thumbnail?: string }[]> = this.folders$.pipe(
    combineLatestWith(this.filters$),
    map(([folders, { filterProduct, search }]) => (folders
        .map(f => (filterProduct === 'Door' ? f.doorElevations : f.frameElevations))
        .selectMany(x => x)
        .filter(t => t.name.searchFor(search, false))
    ))
  );

  loading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private folderService: FolderService,
    private frameElevationService: FrameElevationService,
    private doorElevationService: DoorElevationService,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment,
  ) {}

  ngOnInit(): void {
    this.loadLibraryTemplates();
  }

  ngAfterViewInit(): void {
  }

  loadLibraryTemplates() {
    this.loading$.next(true);
    this.folderService.search({ includes: ['doorElevations', 'frameElevations'] }).subscribe(folders => {
      this.#folders.next(
        cloneDeep(folders).map(folder => {
          folder.doorElevations = folder.doorElevations.map(x => {
            x.thumbnail = `${this.environment.elevations.blobUrl}drawings/${x.thumbnail}`;
            return x;
          });
          folder.frameElevations = folder.frameElevations.map(x => {
            x.thumbnail = `${this.environment.elevations.blobUrl}drawings/${x.thumbnail}`;
            return x;
          });
          return folder;
        })
      );
      this.loading$.next(false);
    });
  }

  selectTemplate(template: { name: string }): void {
    this.templateDisplayOption = template;
  }

  createTemplate(t: { id?: number; name: string }) {
    this.filterProduct === 'Door' ? this.createDoorTemplate(t) : this.createFrameTemplate(t);
  }

  createDoorTemplate(t: { id?: number; name: string }) {
    this.router.navigate(['../doorElevation'], {
      queryParams: { style: `${t.name}`, id: t.id ? t.id : undefined },
      relativeTo: this.route,
    });
  }

  createFrameTemplate(t: { id?: number; name: string }) {
    this.router.navigate(['../frameElevation'], {
      queryParams: { style: `${t.name}`, id: t.id ? t.id : undefined },
      relativeTo: this.route,
    });
  }

  deleteDrawing(id: number) {
    (this.filterProduct === 'Door' ? this.doorElevationService : this.frameElevationService)
      .delete(id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.#folders.next(
            this.folders.map(folder => {
              if (this.filterProduct === 'Door') {
                folder.doorElevations = folder.doorElevations.filter(drawing => drawing.id !== id);
              } else {
                folder.frameElevations = folder.frameElevations.filter(drawing => drawing.id !== id);
              }
              return folder;
            })
          );
        },
        error:() => this.loadLibraryTemplates()
      });
  }
}
