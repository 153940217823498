import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Cacheable } from 'ts-cacheable'
import { environment } from '../../../environments/environment'
import { mergeMap, Observable } from 'rxjs'
import { ShipFromLocation } from '../../models/ship-from-location.model'
import { EstimatesService } from '../estimates/estimates.service'
import { Default, Estimate } from '../../models'

export interface ShipFromLocationUpdate {
  estimateId: number
  shipFromLocationId: number
}

@Injectable({
  providedIn: 'root'
})
export class ShipFromLocationsService {
  url = environment.onlineOrderingApiUrl + 'ShipFromLocations/'

  constructor(private http: HttpClient, private estimatesService: EstimatesService) {}

  @Cacheable()
  get(): Observable<ShipFromLocation[]> {
    return this.http.get<ShipFromLocation[]>(this.url)
  }

  updateShipFromLocation(defaultId: number, payload: ShipFromLocationUpdate): Observable<Default> {
    const url = environment.onlineOrderingApiUrl + `Defaults/${defaultId}`
    if (defaultId) {
      return this.http.put<Default>(url, payload)
    } else {
      return this.createShipFromLocation(payload)
    }
  }

  updateEstimateShipFromLocation(defaultId: number, payload: ShipFromLocationUpdate): Observable<Estimate> {
    return this.updateShipFromLocation(defaultId, payload).pipe(
      mergeMap((val) => this.estimatesService.getEstimate(payload.estimateId))
    )
  }

  createShipFromLocation(payload: ShipFromLocationUpdate): Observable<Default> {
    const url = environment.onlineOrderingApiUrl + `Defaults`
    return this.http.post<Default>(url, payload)
  }

  createEstimateShipFromLocation(payload: ShipFromLocationUpdate): Observable<Estimate> {
    return this.createShipFromLocation(payload).pipe(
      mergeMap((val) => this.estimatesService.getEstimate(payload.estimateId))
    )
  }
}
