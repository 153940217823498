import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IPrepCategoryLocation } from '../interfaces/i-prep-category-location';
import { IPrepCategoryLocationService } from '../interfaces/i-prep-category-location.service';
import { IPrepCode } from '../interfaces/i-prep-code';
import { IQueryParams } from '../interfaces/i-query-params';
import { RestService } from './rest.service';

@Injectable()
export class PrepCategoryLocationService implements IPrepCategoryLocationService {
  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  search(prepCategoryId: number, query: IQueryParams): Observable<IPrepCategoryLocation[]> {
    const queryParams = new URLSearchParams();
    if (query.pageIndex) {
      queryParams.append('pageIndex', `${query.pageIndex}`);
    }
    if (query.pageSize) {
      queryParams.append('pageSize', `${query.pageSize}`);
    }
    if (query.includes) {
      for (const include of query.includes) {
        queryParams.append('includes', include);
      }
    }
    if (query.search) {
      for (const s in query.search) {
        queryParams.append(`search[${s}]`, query.search[s]);
      }
    }
    return this.httpClient.get<IPrepCategoryLocation[]>(
      `${this.environment.elevations.apiUrl}v1/prepCategories/${prepCategoryId}/prepCategoryLocations?${queryParams}`
    );
  }
  getById(prepCategoryId: number, id: number, includes: string[]): Observable<IPrepCategoryLocation> {
    const queryParams = new URLSearchParams();
    for (const value of includes) {
      queryParams.append('includes', value);
    }
    return this.httpClient.get<IPrepCategoryLocation>(
      `${this.environment.elevations.apiUrl}v1/prepCategories/${prepCategoryId}/prepCategoryLocations/${id}?${queryParams}`
    );
  }
  create(prepCategoryId: number, params: any): Observable<IPrepCategoryLocation> {
    return this.httpClient.post<IPrepCategoryLocation>(
      `${this.environment.elevations.apiUrl}v1/prepCategories/${prepCategoryId}/prepCategoryLocations`,
      params
    );
  }
  update(prepCategoryId: number, id: number, params: { [key: string]: any }): Observable<void> {
    return this.httpClient.patch<void>(
      `${this.environment.elevations.apiUrl}v1/prepCategories/${prepCategoryId}/prepCategoryLocations/${id}`,
      params
    );
  }
  delete(prepCategoryId: number, id: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.environment.elevations.apiUrl}v1/prepCategories/${prepCategoryId}/prepCategoryLocations/${id}`
    );
  }
}
