import { Template } from '../abstracts/template';
import { FrameElevation } from '../models/frame-elevation';
import { Stick } from '../models/stick';
import { JointType } from '../enums/joint-type';
import { Orientation } from '../enums/orientation';
import { StickType } from '../enums/stick-type';
import { StickSubtype } from '../enums/stick-subtype';
import { DimensionType } from '../../core/enums/dimension-type';
import { guid } from '@oeo/common';
import { Glass } from '../models/glass';
import { GlassType } from '../enums/glass-type';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { GlazingBeadLocation } from '../enums/glazing-bead-location';
import { Door } from '../models/door';
import { Handing } from '../enums/handing';
import { Rabbet } from '../enums/rabbet';

export class DDST12 extends Template {
  private _b: number;
  private _d: number;
  private _e: number;
  private _g: number;
  private _i: number;
  private _j: number;
  private _l: number;
  private _n: number;

  dimensions = {
    A: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    B: {
      type: DimensionType.Horizontal,
      get: () => this._b,
      set: (value: number) => {
        const diff = this._b - value;
        this._b = value;
        this._d += diff;
        this.update();
      },
    },
    C: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    D: {
      type: DimensionType.Horizontal,
      get: () => this._d,
      set: (value: number) => {
        const diff = this._d - value;
        this._d = value;
        this._b += diff;
        this.update();
      },
    },
    E: {
      type: DimensionType.Horizontal,
      get: () => this._e,
      set: (value: number) => {
        const diff = this._e - value;
        this._e = value;
        this._g += diff;
        this.update();
      },
    },
    F: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    G: {
      type: DimensionType.Horizontal,
      get: () => this._g,
      set: (value: number) => {
        const diff = this._g - value;
        this._g = value;
        this._e += diff;
        this.update();
      },
    },
    H: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    I: {
      type: DimensionType.Vertical,
      get: () => this._i,
      set: (value: number) => {
        if (!this.headDimensions.includes(value)) {
          return;
        }
        const diff = this._i - value;
        this._i = value;
        this._j += diff;
        this.update();
      },
      hint: `Head dimension must be either ${this.headDimensions
        .map(d => d.toDimension(this.dimensionType, this.unitOfMeasure))
        .join(' or ')}`,
    },
    J: {
      type: DimensionType.Vertical,
      get: () => this._j,
      set: (value: number) => {
        const diff = this._j - value;
        this._j = value;
        this._l += diff;
        this.update();
      },
    },
    K: {
      type: DimensionType.Vertical,
      get: () => this.defaultFace,
    },
    L: {
      type: DimensionType.Vertical,
      get: () => this._l,
      set: (value: number) => {
        const diff = this._l - value;
        this._l = value;
        this._n += diff;
        this.update();
      },
    },
    M: {
      type: DimensionType.Vertical,
      get: () => this.defaultFace,
    },
    N: {
      type: DimensionType.Vertical,
      get: () => this._n,
      set: (value: number) => {
        const diff = this._n - value;
        this._n = value;
        this._l += diff;
        this.update();
      },
    },
    O: {
      type: DimensionType.Vertical,
      get: () => this.defaultFace,
    },
  };

  constructor(frameElevation: FrameElevation) {
    super(frameElevation);
    this._b = this._g = (
      (this.width -
        this.rollupDimensions(
          this.dimensionName(() => this.dimensions.H),
          this.dimensionName(() => this.dimensions.B),
          this.dimensionName(() => this.dimensions.D),
          this.dimensionName(() => this.dimensions.E),
          this.dimensionName(() => this.dimensions.G)
        )) /
      4
    ).cleanAsDimension(this.dimensionType, this.unitOfMeasure);
    this._d = (
      (this.width -
        this.rollupDimensions(
          this.dimensionName(() => this.dimensions.H),
          this.dimensionName(() => this.dimensions.D),
          this.dimensionName(() => this.dimensions.E)
        )) /
      2
    ).cleanAsDimension(this.dimensionType, this.unitOfMeasure);
    this._e =
      this.width -
      this.rollupDimensions(
        this.dimensionName(() => this.dimensions.H),
        this.dimensionName(() => this.dimensions.E)
      );
    this._i = this.defaultFace;
    this._j = this._n = (
      (this.height -
        this.rollupDimensions(
          this.dimensionName(() => this.dimensions.O),
          this.dimensionName(() => this.dimensions.L),
          this.dimensionName(() => this.dimensions.N),
          this.dimensionName(() => this.dimensions.J)
        )) /
      4
    ).cleanAsDimension(this.dimensionType, this.unitOfMeasure);
    this._l =
      this.height -
      this.rollupDimensions(
        this.dimensionName(() => this.dimensions.O),
        this.dimensionName(() => this.dimensions.L)
      );
  }

  protected drawObject(container: SVGSVGElement) {
    this.drawHead(container);
    this.drawLeftLite(container);
    this.drawRightLite(container);
    this.drawFrame(container);
    this.drawTopLite(container);
    this.drawLeftDoor(container);
    this.drawRightDoor(container);
  }

  private drawHead(container: SVGSVGElement) {
    const points =
      this.dimensions.I.get() === this.headFace
        ? [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width, y: this.dimensions.I.get() === this.headFace ? this.headFace / 2 : 0 },
            { x: this.width - this.dimensions.H.get(), y: this.dimensions.I.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.I.get() },
            { x: 0, y: this.dimensions.I.get() === this.headFace ? this.headFace / 2 : 0 },
          ]
        : [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width - this.dimensions.H.get(), y: this.dimensions.I.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.I.get() },
          ];
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points,
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: this.dimensions.I.get() === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        rightJointType: this.dimensions.I.get() === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawFrame(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)), y: this.dimensions.I.get() },
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)), y: this.height },
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)), y: this.height },
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)), y: this.dimensions.I.get() },
        ],
        topJointType: JointType.Notched,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.ClosedSection,
        subType: StickSubtype.Hinge,
        flipped: true,
        id: guid(),
      })
    );
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)), y: this.dimensions.I.get() },
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)), y: this.height },
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)), y: this.height },
          { x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)), y: this.dimensions.I.get() },
        ],
        topJointType: JointType.Notched,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.ClosedSection,
        subType: StickSubtype.Hinge,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawTopLite(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );

    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.dimensions.I.get(),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.dimensions.I.get(),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );
  }

  private drawLeftLite(container: SVGSVGElement) {
    // Vertical Left
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: 0, y: this.dimensions.I.get() === this.headFace ? this.headFace / 2 : 0 },
          { x: 0, y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)) },
          { x: this.dimensions.A.get(), y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)) },
          { x: this.dimensions.A.get(), y: this.dimensions.I.get() },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );

    // Horizontal Top Mullion
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.dimensions.A.get(), y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)) },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          { x: this.dimensions.A.get(), y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)) },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );

    // Horizontal Bottom Mullion
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.dimensions.A.get(), y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)) },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
          { x: this.dimensions.A.get(), y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)) },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );

    // Sill
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.OpenSection,
        subType: StickSubtype.Sill,
        flipped: false,
        id: guid(),
      })
    );

    // Top Glass
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.dimensions.A.get(),
            y: this.dimensions.I.get(),
          },
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.dimensions.I.get(),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );

    // Middle Glass
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );

    // Bottom Glass
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
          {
            x: this.dimensions.A.get(),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.B)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );
  }

  private drawRightLite(container: SVGSVGElement) {
    // Vertical Right
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.dimensions.I.get(),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          { x: this.width, y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)) },
          {
            x: this.width,
            y: this.dimensions.I.get() === this.headFace ? this.headFace / 2 : 0,
          },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Square,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );

    // Horizontal Top Mullion
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );

    // Horizontal Bottom Mullion
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );

    // Sill
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Notched,
        rightJointType: JointType.Notched,
        type: StickType.OpenSection,
        subType: StickSubtype.Sill,
        flipped: false,
        id: guid(),
      })
    );

    // Top Glass
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.dimensions.I.get(),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.J)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.dimensions.I.get(),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );

    // Middle Glass
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.L)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );

    // Bottom Glass
    this.intersectables.push(
      Glass.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.F)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.N)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.G)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.M)),
          },
        ],
        exportType: Glass.intersectableName,
        type: GlassType.Glass,

        id: guid(),
      })
    );
  }

  private drawLeftDoor(container: SVGSVGElement) {
    this.intersectables.push(
      Door.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.C)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
        ],
        exportType: Door.intersectableName,
        handing: Handing.LH,
        id: guid(),
      })
    );
  }

  private drawRightDoor(container: SVGSVGElement) {
    this.intersectables.push(
      Door.fromJSON(this.frameElevation, container, {
        points: [
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.D)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.O)),
          },
          {
            x: this.rollupDimensions(this.dimensionName(() => this.dimensions.E)),
            y: this.rollupDimensions(this.dimensionName(() => this.dimensions.K)),
          },
        ],
        exportType: Door.intersectableName,
        handing: Handing.RH,
        id: guid(),
      })
    );
  }
}
