import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';

export interface IOHStopHolderPrep extends IPrep {
  degreeOfOpening: string;
}

export class OHStopHolderPrep extends Prep implements IOHStopHolderPrep {
  degreeOfOpening: string;

  properties() {
    const obj = super.properties();
    obj[nameOf((_: OHStopHolderPrep) => _.degreeOfOpening)] = (value: string) => value;
    return obj;
  }
}
