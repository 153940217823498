import { Inject, Injectable } from '@angular/core'
import { Subject, Observable } from 'rxjs'

import { IStorageService, STORAGE_SERVICE } from '../../interfaces/i-storage-service'
import {
  CutSheetEvent,
  DisplayedTableColumns,
  ExpandTableEvent,
  InputEvent,
  ReorderEvent,
  RowClickEvent,
  SortingAndPaginationEvent,
  TableActionEvent
} from '../../types/table.model'

@Injectable({
  providedIn: 'root'
})
export class TableService<TableData> {
  #displayedTableColumns = this.storageService.getItem('displayedTableColumns') || {}

  get displayedTableColumns() {
    return this.#displayedTableColumns
  }

  constructor(
    @Inject(STORAGE_SERVICE) public storageService: IStorageService<{ displayedTableColumns: DisplayedTableColumns }>
  ) {}

  public inputEvent: Subject<InputEvent<TableData>> = new Subject()
  public inputEvent$: Observable<InputEvent<TableData>> = this.inputEvent.asObservable()

  public expandTable = new Subject<ExpandTableEvent>()
  public expandTable$ = this.expandTable.asObservable()

  public actionEvent = new Subject<TableActionEvent<TableData>>()
  public actionEvent$ = this.actionEvent.asObservable()

  public rowClickEvent = new Subject<RowClickEvent>()
  public rowClickEvent$ = this.rowClickEvent.asObservable()

  public cutsheetEvent = new Subject<CutSheetEvent<TableData>>()
  public cutsheetEvent$ = this.cutsheetEvent.asObservable()

  public sortingAndPaginationEvent: Subject<SortingAndPaginationEvent> = new Subject()
  public sortingAndPaginationEvent$ = this.sortingAndPaginationEvent.asObservable()

  public reorderEvent = new Subject<ReorderEvent>()
  public reorderEvent$ = this.reorderEvent.asObservable()

  setColumnVisibility(tableName: string, property: string, visible: boolean) {
    if (!Object.hasOwn(this.#displayedTableColumns, tableName)) {
      this.#displayedTableColumns[tableName] = {}
    }
    this.#displayedTableColumns[tableName][property.replaceAll(/\s/g, '')] = visible
    this.storageService.setItem('displayedTableColumns', this.#displayedTableColumns)
  }

  isColumnVisible(tableName: string, property: string = '') {
    if (this.columnVisibilitySet(tableName, property)) {
      return this.#displayedTableColumns[tableName][property.replaceAll(/\s/g, '')]
    }
    return true
  }

  columnVisibilitySet(tableName: string, property: string = '') {
    return (
      Object.hasOwn(this.#displayedTableColumns, tableName) &&
      Object.hasOwn(this.#displayedTableColumns[tableName], property.replaceAll(/\s/g, ''))
    )
  }
}
