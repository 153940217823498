import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { EmbossedDoorType } from '../../abstracts/embossed-door-type';
import { Panel } from '../../abstracts/panel';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Embossment } from '../../models/cutout-types/embossment';
import { HDSquarePanel } from '../../models/cutout-types/embossments/hd-square-panel';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class HD2Type extends EmbossedDoorType {
  cutouts: Cutout[];
  private width = uomSwitch('23"', 'door', this.door.doorElevation.unitOfMeasure);

  private height = uomSwitch(this.isTall ? '79.22"': '65"', 'door', this.door.doorElevation.unitOfMeasure)

  private bottomRail = (this.door.actualHeight - this.height) / 2;

  private topPanelHeight = uomSwitch( this.isTall ? '48"': '37"', 'door', this.door.doorElevation.unitOfMeasure)

  private spaceBetween = uomSwitch(this.isTall ?'8.220"':'6 1/2"', 'door', this.door.doorElevation.unitOfMeasure)

  private bottomPanelHeight = uomSwitch(this.isTall ? '23"':'21 1/2"', 'door', this.door.doorElevation.unitOfMeasure)

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    if(this.door.height.toDimension('door', UnitOfMeasure.Imperial) === '80"'){
      this.bottomRail = uomSwitch('8"', 'door', this.door.doorElevation.unitOfMeasure);
    }
    this.cutouts = [this.getEmbossment()];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  get totalEmbossmentHeight(): number {
    return this.topPanelHeight + this.spaceBetween + this.bottomPanelHeight;
  }

  get maxEmbossmentDistances() {
    return {
      fromTop: this.isTall
        ? '8 31/200"'.fromDimension('door', UnitOfMeasure.Imperial)
        : '10 1/8"'.fromDimension('door', UnitOfMeasure.Imperial) ,
      fromBottom: this.isTall
        ? '10 3/8"'.fromDimension('door', UnitOfMeasure.Imperial)
        : '10 3/8"'.fromDimension('door', UnitOfMeasure.Imperial)
    }
  }

  get isTall(): boolean {
    return this.door.height >= uomSwitch(`85.5"`, 'door', this.door.doorElevation.unitOfMeasure)
  }

  private getEmbossment(): Cutout {
    return new Embossment(
      'Embossment',
      this,
      (this.door.actualWidth - this.width) / 2,
      this.door.actualHeight - this.height - this.bottomRail,
      this.width,
      this.height,
      VerticalCutoutDimensionMode.Bottom,
      HorizontalCutoutDimensionMode.Lock,
      this.getPanels()
    ).setMinMaxDistance(this.minMaxEmbossmentDimensions);
  }

  private getPanels(): Panel[] {
    return [
      new HDSquarePanel(0, 0, this.width, this.topPanelHeight),
      new HDSquarePanel(0, this.topPanelHeight + this.spaceBetween, this.width, this.bottomPanelHeight),
    ];
  }
}
