import { CoreConstants } from '../core.constants';
import { UnitOfMeasure } from '../enums/unit-of-measure';
import { uomSwitch } from './uomSwitch';

export function convertMeasurement(value: string, type: 'door' | 'frame', unitOfMeasure: UnitOfMeasure): string {
  if (!value) {
    return uomSwitch('0"', type, unitOfMeasure).toDimension('door', unitOfMeasure);
  }
  if(checkIfImperial(value) && unitOfMeasure === UnitOfMeasure.Imperial){
    return value;
  }
  const multiplier =
    type === 'door' ? CoreConstants.multipliers.doorElevation : CoreConstants.multipliers.frameElevation;
  let num =
    value.fromDimension(
      type,
      unitOfMeasure === UnitOfMeasure.Imperial ? UnitOfMeasure.Metric : UnitOfMeasure.Imperial
    ) / (unitOfMeasure === UnitOfMeasure.Imperial ? 1 : multiplier);
  num *= unitOfMeasure === UnitOfMeasure.Imperial ? 1 / 25.4 : 25.4;
  num *= unitOfMeasure === UnitOfMeasure.Imperial ? multiplier : 1;
  return Math.round(num).toDimension(type, unitOfMeasure);
}

/**
 * Checks if the value is in already in imperial format
 * Fixes a bug where converting from imperial to imperial would result in a value of 0
 */
function checkIfImperial(measure: string){
  return measure.includes('"');
}
