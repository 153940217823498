import { Injectable } from '@angular/core'
import { Customer, ReadAnnoucement } from '../../models'
import { ValueOf, IStorageService, DisplayedTableColumns } from '@oeo/common'
import { BannerNotificationTypes } from '../helper/helper.service'

export type LocalStorageIndex = {
  collapsedNav: boolean
  customer: Customer
  i18n: 'en' | 'fr' | 'es'
  internal: boolean
  internalHash: string
  InternalNotification: boolean
  SurchargeMessage: boolean
  pageLength: number
  readAnnoucements: Array<ReadAnnoucement>
  route: Array<string>
  internal_timestamp: number
  displayedTableColumns: DisplayedTableColumns
  UpdatedFromSWPrompt: boolean
  /* MBA Keys */
  targetBranchCustomerId: string
  parentCompanyId: string
  defaultBranchOnLogin: string
} & { [key in ValueOf<typeof BannerNotificationTypes>]: boolean }

export type StorageKey = keyof LocalStorageIndex

const clearOnLogoutKeys: StorageKey[] = [
  'customer',
  'internal',
  'targetBranchCustomerId',
  'parentCompanyId',
  'internal_timestamp'
]

@Injectable({
  providedIn: 'root'
})
export class StorageService implements IStorageService<LocalStorageIndex> {
  setItem<K extends StorageKey, V extends LocalStorageIndex[K]>(key: K, value: V) {
    if (value === null || value === undefined || value === '' || Number.isNaN(value)) {
      this.removeItem(key)
      return
    }
    localStorage.setItem(key, JSON.stringify(value))
  }

  getItem<K extends StorageKey, V extends LocalStorageIndex[K]>(key: K): V {
    if (localStorage.getItem(key)) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (error) {
        return localStorage.getItem(key) as any
      }
    }
    return null
  }

  removeItem<K extends StorageKey>(key: K) {
    localStorage.removeItem(key)
  }

  containsItem(key: StorageKey): boolean {
    return localStorage.getItem(key) !== null
  }

  clear() {
    localStorage.clear()
  }

  clearAuthKeys() {
    clearOnLogoutKeys.forEach((key) => this.removeItem(key))
  }
}
