import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IPrepCategory } from '../../core/interfaces/i-prep-category';
import { IPrepCategoryCodeService } from '../../core/interfaces/i-prep-category-code.service';
import { IPrepCategoryService } from '../../core/interfaces/i-prep-category.service';
import { IPrepCode } from '../../core/interfaces/i-prep-code';
import { IPrepService } from '../../core/interfaces/i-prep.service';
import { DialogService } from '../../core/services/dialog.service';
import { LookupsService } from '../../core/services/lookups.service';
import { RestService } from '../../core/services/rest.service';

export abstract class GenericPrepsComponent {
  pricingCategories: string[];
  categories: IPrepCategory[];
  codes: IPrepCode[];
  code: IPrepCode;
  search$ = new Subject<string>();
  codes$ = new Subject<IPrepCode[]>();
  protected destroy$ = new Subject<void>();
  dataLoading$ = new BehaviorSubject<boolean>(false);
  loading = false
  constructor(
    protected prepService: IPrepService,
    protected categoryService: IPrepCategoryService,
    public codeService: RestService<IPrepCode>,
    public categoryCodeService: IPrepCategoryCodeService,
    protected dialogService: DialogService,
    private lookupsService: LookupsService,
    pricingCategoryId: number
  ) {
    this.search$.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(value => {
      this.codes$.next(value ? this.codes.filter(c => c.code.searchFor(value)) : this.codes);
    });
    this.lookupsService
      .getAll(pricingCategoryId)
      .subscribe(pricingCategories => (this.pricingCategories = pricingCategories));
  }
  getData(): void {
    this.dataLoading$.next(true);
    this.prepService.getFormattedPreps().subscribe(({prepCategories, prepCodes}) => {
      this.categories = prepCategories;
      
      this.codes = prepCodes;
      this.codes$.next(this.codes);
      this.setNewCode();
      this.dataLoading$.next(false);
    });
  }

  addPrepCode(code: IPrepCode) {
    if (this.codes.any(c => c.id === code.id)) {
      this.codes.remove(code);
      this.code = null;
    } else {
      this.codes.push(code);
      this.code = code;
    }
    this.search$.next(null);
  }

  setNewCode() {
    this.code = {
      id: null,
      fixedLocation: false,
      categories: this.categories.map(c => Object.assign({}, c)),
      shape: null,
      pricingCategory: null,
    };
  }

  search() {}
}
