<lib-left-bar></lib-left-bar>
<div class="app-container">
  <div class="lib-top-bar margin top s4" *ngIf="elevation">
    <div class="bar-content">
      <div class="top-bar">
        <span class="breadcrumbs"><lib-breadcrumb *ngIf="showBreadcrumbs" class="breadcrumbs"></lib-breadcrumb></span>
        <ng-container>
          <span class="title">{{ elevation.name }}</span>
          <div class="actions">
            <button
              class="margin margin s6 right"
              color="primary"
              aria-label="{{'ARIALABELS.addToEstimate' | translate}}"
              mat-flat-button
              (click)="addToEstimate()"
              [disabled]="elevationContainsErrors"
            >
              {{ 'saveAndClose' | translate }}
            </button>
            <button
              class="margin"
              aria-label="{{'ARIALABELS.openElevationActions' | translate}}"
              mat-flat-button
              color="primary"
              (click)="openActions()"
            >
              {{ 'download' | translate }}/{{ (elevation?.id ? 'update' : 'save') | translate }}
              {{ 'drawing' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
      <span class="margin top s3">
        <mat-progress-bar
          mode="indeterminate"
          [ngStyle]="{ visibility: (loading$ | async) === false ? 'hidden' : 'visible' }"
        ></mat-progress-bar>
      </span>
    </div>
  </div>
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
