import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DimensionType } from '../../core/enums/dimension-type';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { DimensionInputMaker } from '../../core/helpers/dimension-input-maker';
import { createSVGElement } from '../../core/helpers/svg-functions';
import { IDimension } from '../../core/interfaces/i-dimension';
import { textStyles } from '../constants/text-styles';
import { FrameCornerCondition } from '../enums/frame-corner-condition';
import { FrameElevation } from '../models/frame-elevation';
import { Stick } from '../models/stick';
import { CSSPropKey } from '@oeo/common'

const dimensionLine = 'dimension';
const distanceLine = 'distance';
const distanceFromEdge = -40;
const characterWidth = 12;
const characterHeight = 16;

export abstract class Template implements IDimension {
  readonly dimensionType = 'frame';
  protected _update$ = new Subject<void>();
  private _destroy$ = new Subject<void>();
  private _inputWidth = 88;
  private _inputHeight = 22;
  private _dimensionInputMaker = new DimensionInputMaker(this._inputWidth, this._inputHeight);

  get height() {
    return this.frameElevation.height;
  }

  get width() {
    return this.frameElevation.width;
  }

  get update$() {
    return this._update$.asObservable();
  }

  get unitOfMeasure() {
    return this.frameElevation.unitOfMeasure;
  }

  private get horizontalDimensions() {
    return Object.keys(this.dimensions)
      .filter(d => this.dimensions[d].type === DimensionType.Horizontal)
      .map(d => ({ key: d, value: this.dimensions[d] }));
  }

  private get verticalDimensions() {
    return Object.keys(this.dimensions)
      .filter(d => this.dimensions[d].type === DimensionType.Vertical)
      .map(d => ({ key: d, value: this.dimensions[d] }));
  }

  abstract readonly dimensions: {
    [dimensionName: string]: { type: DimensionType; hint?: string; get(): number; set?(value: number): void };
  };

  get intersectables() {
    return this.frameElevation.intersectables;
  }

  protected get defaultFace(): number {
    return this.frameElevation.frameSeriesInfo.defaultFace[this.unitOfMeasure];
  }

  protected get headFace(): number {
    return (this.unitOfMeasure === UnitOfMeasure.Imperial ? '4"' : '64mm').fromDimension(
      this.dimensionType,
      this.unitOfMeasure
    );
  }

  protected get headDimensions(): readonly number[] {
    return this.frameElevation.frameSeriesInfo.adjustableHead ? [this.defaultFace, this.headFace] : [this.defaultFace];
  }

  constructor(protected frameElevation: FrameElevation) {
    this.frameElevation.afterUpdate$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.update();
    });
  }

  draw(container: SVGGElement, hideDimensions?: boolean) {
    this.frameElevation.intersectables.forEach(i => {
      if (typeof i.destroy === 'function') {
        i.destroy();
      }
    });
    this.frameElevation.intersectables = [];
    this.clear(container);
    this.drawObject(container);
    if (!hideDimensions) {
      this.drawOverallLines(container);
      this.drawHorizontalLines(container);
      this.drawVerticalLines(container);
    }
    this.intersectables.filter(i => i instanceof Stick).forEach((i: Stick) => i.drawJambs());
  }

  rollupDimensions(key: string, ...exclude: string[]) {
    let keys = Object.keys(this.dimensions);
    if (!keys.any(k => k === key)) {
      throw new Error(`${key} does not exist in dimensions`);
    }
    keys = keys
      .filter(
        (k, i) =>
          i <= keys.indexOf(key) && this.dimensions[key].type === this.dimensions[k].type && !exclude.any(e => e === k)
      )
      .orderBy(_ => _);
    return keys.sum(k => this.dimensions[k].get());
  }

  update() {
    this._update$.next();
  }

  destroy() {
    this._destroy$.next();
  }

  protected abstract drawObject(container: SVGGElement): void;

  protected dimensionName(
    func: () => { type: DimensionType; hint?: string; get(): number; set?: (value: number) => any }
  ): string {
    const value = Object.keys(this.dimensions).first(key => this.dimensions[key] === func());
    if (value == null) {
      throw new Error('Value does not exist');
    }
    return value;
  }

  protected isValidHeadDimension(value: number): boolean {
    return !(
      !this.headDimensions.includes(value) && // this.frameElevation.cornerCondition === FrameCornerCondition.SAW ||
      this.frameElevation.cornerCondition === FrameCornerCondition.DIE
    );
  }

  clear(container: SVGGElement) {
    this._destroy$.next();
    const root = container.root(SVGSVGElement);
    const children = Array.from(root.children).slice(3, root.children.length);
    Array.from(container.children).forEach(_ => _.remove());
    children.forEach(_ => _.remove());
    const text = Array.from(root.children).find(c => c instanceof SVGTextElement);
    while (text.children.length > 0) {
      text.children[0].remove();
    }
  }

  private drawOverallLines(container: SVGGElement) {
    this.drawLine(container, distanceFromEdge, 0, distanceFromEdge, this.height, dimensionLine);
    this.drawLine(container, 0, distanceFromEdge, this.width, distanceFromEdge, dimensionLine);

    const value = `${this.width.toDimension(this.dimensionType, this.unitOfMeasure)} X ${this.height.toDimension(
      this.dimensionType,
      this.unitOfMeasure
    )}`;
    this.drawText(
      container,
      distanceFromEdge / 2 - value.length * characterWidth,
      distanceFromEdge / 2 - characterHeight / 2,
      value,
      dimensionLine
    );
  }

  private drawHorizontalLines(container: SVGGElement) {
    const points = this.horizontalDimensions
      .map((d, index) => ({
        name: d.key,
        value: this.horizontalDimensions
          .slice(0, index)
          .map(_ => _.value.get())
          .sum(_ => _),
      }))
      .concat({ name: null, value: this.width });
    for (let i = 0; i < points.length; i++) {
      this.drawLine(
        container,
        points[i].value,
        distanceFromEdge / 2,
        points[i].value,
        distanceFromEdge * 1.5,
        dimensionLine
      );
      if (points[i + 1]) {
        this.drawLine(
          container,
          (points[i].value + points[i + 1].value) / 2,
          distanceFromEdge,
          (points[i].value + points[i + 1].value) / 2,
          distanceFromEdge * (i.mod(2) ? 2 : 3),
          'distance',
          dimensionLine
        );
        container.appendChild(
          this._dimensionInputMaker.create(
            (points[i].value + points[i + 1].value) / 2 - this._inputWidth / 2,
            distanceFromEdge * (i.mod(2) ? 2 : 3) - this._inputHeight,
            this,
            points[i].name,
            this._destroy$
          )
        );
      }
    }
  }

  private drawVerticalLines(container: SVGGElement) {
    const points = this.verticalDimensions
      .map((d, index) => ({
        name: d.key,
        value: this.verticalDimensions
          .slice(0, index)
          .map(_ => _.value.get())
          .sum(_ => _),
      }))
      .concat({ name: null, value: this.height });
    for (let i = 0; i < points.length; i++) {
      this.drawLine(
        container,
        distanceFromEdge / 2,
        points[i].value,
        distanceFromEdge * 1.5,
        points[i].value,
        dimensionLine
      );
      if (points[i + 1]) {
        this.drawLine(
          container,
          distanceFromEdge,
          (points[i].value + points[i + 1].value) / 2,
          distanceFromEdge * (i.mod(2) ? 2 : 3),
          (points[i].value + points[i + 1].value) / 2,
          distanceLine,
          dimensionLine
        );
        container.appendChild(
          this._dimensionInputMaker.create(
            distanceFromEdge * (i.mod(2) ? 2 : 3) - this._inputWidth,
            (points[i].value + points[i + 1].value) / 2 - this._inputHeight / 2,
            this,
            points[i].name,
            this._destroy$
          )
        );
      }
    }
  }

  private drawLine(container: SVGGElement, x1: number, y1: number, x2: number, y2: number, ...classNames: string[]) {
    const line = createSVGElement('line')
    line.classList.add('line');
    if (classNames) {
      line.classList.add(...classNames);
    }
    line.x1.baseVal.value = x1;
    line.y1.baseVal.value = y1;
    line.x2.baseVal.value = x2;
    line.y2.baseVal.value = y2;
    container.root(SVGSVGElement).appendChild(line);
  }

  private drawText(container: SVGGElement, x: number, y: number, value: string, ...classNames: string[]) {
    const text = createSVGElement('tspan')
    text.classList.add('tspan');
    for (const key in textStyles) {
      text.style[key as CSSPropKey] = textStyles[key];
    }
    if (classNames) {
      text.classList.add(...classNames);
    }
    const xLength = container.root(SVGSVGElement).createSVGLength();
    xLength.value = x;
    text.x.baseVal.appendItem(xLength);
    const yLength = container.root(SVGSVGElement).createSVGLength();
    yLength.value = y;
    text.y.baseVal.appendItem(yLength);
    text.textContent = value;
    Array.from(container.root(SVGSVGElement).children)
      .find(c => c instanceof SVGTextElement)
      .appendChild(text);
  }
}
