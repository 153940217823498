import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { UnauthorizedComponent } from './unauthorized/unauthorized.component'
import { CustomerServiceResolver } from './shared/resolvers/customer/customer.resolver'
import { LandingComponent } from './core/components/landing/landing.component'
import { ProfileInfoComponent } from './core/components/profile-information/profile-info.component'
import { DetachedCustomerAccountComponent } from './detached-customer-account/detached-customer-account.component'
import { userTypeResolver } from './shared/resolvers/userType/userType.resolver'
import { InternalLandingComponent } from './core/components/internal-landing/internal-landing.component'
import { FlagsResolver } from './shared/resolvers/flags/flags.resolver'
import { NotificationsComponent } from './core/components/notifications/notifications.component'
import { Admin } from './shared/guards/admin.service'
import { NotificationComponent } from './core/components/notification/notification.component'
import { LogoutComponent } from './auth/logout.component'
import { SsoComponent } from './auth/sso.component'
import { roleCheck, customerIdGuard, unauthorizedGuard } from './shared/guards'

//  Using a resolver to get the customer object because it guarenties the data is there when the route completes
//  This prevent the flash of UI elements that aren't suppose to be there

const routes: Routes = [
  { path: '', redirectTo: '/landing', pathMatch: 'full' },
  { path: 'sso', component: SsoComponent },
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [unauthorizedGuard] },
  { path: 'detachedCustomerAccount', component: DetachedCustomerAccountComponent },

  { path: 'landing', component: LandingComponent },
  { path: 'internal', component: InternalLandingComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'notifications',
    resolve: { customer: CustomerServiceResolver, flags: FlagsResolver },
    data: { breadcrumb: 'notifications' },
    children: [
      { path: '', component: NotificationsComponent },
      { path: ':notificationId', component: NotificationComponent, data: { breadcrumb: 'announcement' } }
    ]
  },
  {
    path: 'estimates',
    loadChildren: () => import('./estimates/estimates.module').then((m) => m.EstimatesModule),
    canActivate: [roleCheck, customerIdGuard],
    data: { breadcrumb: 'estimates' },
    resolve: { customer: CustomerServiceResolver, flags: FlagsResolver }
  },
  {
    path: 'pro-express',
    loadChildren: () => import('./pro-express/pro-express.module').then((m) => m.ProExpressModule),
    canActivate: [roleCheck, customerIdGuard],
    resolve: { customer: CustomerServiceResolver, userType: userTypeResolver, flags: FlagsResolver }
  },
  {
    path: 'hardware-sets',
    loadChildren: () => import('./hardwareSets/hardware-sets.module').then((m) => m.HardwareSetsModule),
    canActivate: [roleCheck, customerIdGuard],
    resolve: { customer: CustomerServiceResolver, flags: FlagsResolver }
  },
  {
    path: 'cross-reference',
    loadChildren: () => import('./cross-reference/cross-reference.module').then((m) => m.CrossReferenceModule),
    canActivate: [roleCheck, customerIdGuard],
    resolve: { customer: CustomerServiceResolver, userType: userTypeResolver, flags: FlagsResolver }
  },
  {
    path: 'quotes',
    loadChildren: () => import('./quotes/quotes.module').then((m) => m.QuotesModule),
    canActivate: [roleCheck, customerIdGuard],
    resolve: { customer: CustomerServiceResolver, userType: userTypeResolver, flags: FlagsResolver }
  },
  {
    path: 'orderhistory',
    loadChildren: () => import('./orders/orders.module').then((m) => m.OrdersModule),
    canActivate: [roleCheck, customerIdGuard],
    resolve: { customer: CustomerServiceResolver, userType: userTypeResolver, flags: FlagsResolver }
  },
  {
    path: 'profileInfo',
    component: ProfileInfoComponent,
    resolve: { customer: CustomerServiceResolver, userType: userTypeResolver, flags: FlagsResolver }
  },
  // Indirect
  //  project === estimate
  {
    path: 'projects',
    loadChildren: () => import('./estimates/projects.module').then((m) => m.ProjectsModule),
    canActivate: [roleCheck, customerIdGuard],
    data: { breadcrumb: 'projects' },
    resolve: { customer: CustomerServiceResolver, userType: userTypeResolver, flags: FlagsResolver }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [roleCheck, Admin, customerIdGuard],
    data: { breadcrumb: 'admin' },
    resolve: { flags: FlagsResolver }
  },
  { path: '**', redirectTo: 'landing' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
