import { DoorPrepTypes } from '../../door-elevation/interfaces/preps'
import { FramePrepTypes } from '../../frame-elevation/interfaces/preps'
import { IPrep } from '../interfaces/i-prep'
import { IQuestion } from '../interfaces/i-question'

export function parsePrep<T extends IPrep>(
  properties: { [index: string]: any },
  doc: Document,
  path: string,
  prepName: string
): T {
  const nodes = doc.evaluate(
    `${path}/property[contains(translate(@id, 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz'),'${prepName.toLowerCase()}')]`,
    doc,
    null,
    XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
    null
  )
  let node: Element = nodes.iterateNext() as Element
  const obj: Record<string, any> = {}
  while (node) {
    const key = Object.keys(properties).first((p) =>
      p.searchFor(node.getAttribute('id').replace(new RegExp(`${prepName}`, 'i'), ''), true)
    )
    if (key) {
      if (node.textContent) {
        if (key.match(new RegExp('special$', 'i'))) {
          const arr = node.textContent.split('||')
          obj.manufacturer = arr[0]
          obj.hardwareType = arr[1]
          obj.productId = arr[2]
          obj.questions = arr.skip(3).map((x) => ({ title: x.split(':')[0], value: x.split(':')[1] } as IQuestion))
        } else {
          obj[key] = properties[key](node.textContent)
        }
      }
    }
    node = nodes.iterateNext() as Element
  }
  return Object.keys(obj).length > 0 ? obj as T : null
}

const AllPrepsProperties = { frame: FramePrepTypes, door: DoorPrepTypes }

/**
 * XML paths to the prep nodes - for each door or frame elevation
 */
const XmlPrepPaths = {
  door: 'option[@id="Door"]',
  frame: '//category[@id="FrameElevation"]/option[@id="FrameElevation"]'
}
export const parsePreps = (doorOrFrame: 'door' | 'frame', doc: Document) =>
  Object.entries(AllPrepsProperties[doorOrFrame]).reduce((preps, [prepKey, PrepType]) => {
    preps[prepKey] = parsePrep(PrepType.prototype.properties(), doc, XmlPrepPaths[doorOrFrame], prepKey)
    return preps
  }, {} as Record<string, any>)
