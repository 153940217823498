import { AdditionalLabel } from '../../../core/enums/additional-label';
import { bottomChannelSweeps } from '../../rules/bottom-channel';
import { DoorBottomChannel } from '../door-channel';
import { DoorSweep } from '../door-sweep';
import { getEnumValues } from '@oeo/common';

export function DoorSweeps(additionalLabel: AdditionalLabel, bottomChannel: DoorBottomChannel): DoorSweep[] {
  if (!bottomChannel) {
    return [];
  }
  const sweeps = getEnumValues(DoorSweep);
  bottomChannelSweeps(sweeps, bottomChannel);
  return sweeps;
}
