import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
import { Tool } from '../core/models/tool'
import { ToolType } from '../core/enums/tool-type'
import { DoorElevation } from './models/door-elevation'
import { DialogService } from '../core/services/dialog.service'
import { DrawingPadComponent } from './components/drawing-pad/drawing-pad.component'
import { ActivatedRoute, Route, Router } from '@angular/router'
import { DoorElevationService } from './services/door-elevation-service/door-elevation.service'
import { ConfigurationComponent } from './dialogs/configuration/configuration.component'
import { CustomRulesComponent } from '../core/components/custom-rules/custom-rules.component'
import { Subject, Observable, of, EMPTY, from, map, mergeMap } from 'rxjs'
import { UserService } from '../core/services/user.service'
import { DeactivatableComponent } from '../core/guards/can-deactivate.guard'
import { ConfirmDialogComponent } from './dialogs/confirm/confirm.component'
import { DoorElevationConfigService } from './services/door-elevation-config-service/door-elevation-config.service'
import { DoorElevationConfigResult } from './services/door-elevation-config-service/constants'
import { WarningDialogComponent } from './dialogs/confirm/warning.component'
import { IElevationComponent } from '../core/interfaces/i-elevation-component'

@Component({
  selector: 'lib-door-elevation',
  templateUrl: './door-elevation.component.html',
  styleUrls: ['./door-elevation.component.scss']
})
export class DoorElevationComponent implements OnInit, OnDestroy, DeactivatableComponent, IElevationComponent {
  @ViewChild('drawingPad', { static: false }) drawingPad: DrawingPadComponent
  private _destroy$ = new Subject<void>()

  tools: Tool[]
  tool: Tool
  confirmNavigation: boolean = false

  get configuration(): string {
    return this.service.elevation.toXML()
  }

  get elevation(): DoorElevation {
    return this.service.elevation
  }

  constructor(
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    public service: DoorElevationService,
    private userService: UserService,
    private doorConfigService: DoorElevationConfigService
  ) {}

  ngOnInit(): void {

    const id = this.route.snapshot.queryParams.id
    const routeState = window.history.state as { configuration?: string }

    if (routeState.configuration) {
      this.service.elevation = new DoorElevation(DoorElevation.fromXML(routeState.configuration))
    } else if (id) {
      this.service.getById(id, []).subscribe((elevation) => {
        this.service.elevation = new DoorElevation(DoorElevation.fromXML(elevation.configuration), elevation)
      })
    } else {
      this.doorConfigService
        .getPreference()
        .pipe(
          mergeMap(() => this.doorConfigService.createNewDoorElevation(this.route.snapshot.queryParams.state)),
          this.handleDoorElevationConfigResult()
        )
        .subscribe()
    }

    this.tools = [
      { type: ToolType.Pan, disabled: () => false, active: false, visible: () => true },
      {
        type: ToolType.Preps,
        disabled: () => !this.service.currentDoor,
        active: false,
        visible: () => true,
      },
      {
        type: ToolType.Rules,
        disabled: () => !this.service.elevation,
        active: false,
        visible: () => this.userService.isAdmin
      }
    ]
    this.tool = this.tools[0]
  }

  openSettings() {
    this.doorConfigService.modifyDoorElevation(this.service.elevation)
      .pipe(this.handleDoorElevationConfigResult())
      .subscribe()
  }

  save() {}

  download() {}

  money() {
    this.dialogService.open(ConfigurationComponent, this.service.elevation, { closeable: true })
  }

  setTool(tool: Tool): void {
    if (tool.type === ToolType.Preps) {
      return this.openPreps()
    }
    if (tool.type === ToolType.Rules) {
      return this.openRules()
    }
    this.tool = tool
  }

  handleDoorElevationConfigResult() {
    return (doorConfigResult$: Observable<DoorElevationConfigResult>) =>
      doorConfigResult$.pipe(
        mergeMap((doorConfigResult) => {
          if (!doorConfigResult && !this.service.elevation) {
            return from(this.router.navigate(['../home'], { relativeTo: this.route, state: { bypass: true } })).pipe(mergeMap(() => EMPTY))
          }
          if(!doorConfigResult) {
            this.doorConfigService.resetDoorElevation(new DoorElevation(DoorElevation.fromXML(this.service.elevation.toXML())))
            return EMPTY
          }
          this.service.elevation = new DoorElevation(DoorElevation.fromXML(doorConfigResult.doorElevation.toXML()))
          if (!doorConfigResult.persistedPreps) return this.dialogService.open<{}, void>(WarningDialogComponent)
          else return of(null)
        })
      )
  }

  openPreps(): void {
    this.doorConfigService
      .modifyDoorPreps(this.elevation, this.service.currentDoorIndex)
      .pipe(this.handleDoorElevationConfigResult())
      .subscribe(() => {
        if (this.service.elevation.doors.length === 1) {
          return
        }
        const otherDoor = this.service.currentDoor
        if (!otherDoor.hingePrep && !!this.service.currentDoor.hingePrep) {
          otherDoor.hingePrep = Object.assign({}, this.service.currentDoor.hingePrep)
        }
        if (!otherDoor.anchorHingePrep && !!this.service.currentDoor.anchorHingePrep) {
          otherDoor.anchorHingePrep = Object.assign({}, this.service.currentDoor.anchorHingePrep)
        }
        if (
          !otherDoor.blankSurfaceHingePrep &&
          !!this.service.currentDoor.blankSurfaceHingePrep
        ) {
          otherDoor.blankSurfaceHingePrep = Object.assign(
            {},
            this.service.currentDoor.blankSurfaceHingePrep
          )
        }
        if (!otherDoor.intPivotPrep && !!this.service.currentDoor.intPivotPrep) {
          otherDoor.intPivotPrep = Object.assign({}, this.service.currentDoor.intPivotPrep)
        }
        if (!otherDoor.topBottomPivotPrep && !!this.service.currentDoor.topBottomPivotPrep) {
          otherDoor.topBottomPivotPrep = Object.assign(
            {},
            this.service.currentDoor.topBottomPivotPrep
          )
        }
      })
  }

  openRules(): void {
    this.dialogService
      .open(CustomRulesComponent, { service: this.service.doorRuleService, obj: this.service.elevation }, { closeable: true })
      .subscribe()
  }

  canDeactivate(destinationPath: Route): Observable<boolean> {
    if (!this.service.elevation) return of(true)
    this.dialogService.open<Route, boolean>(ConfirmDialogComponent, destinationPath, { closeable: true })
    return this.dialogService.close$.pipe(map((result: Record<string, any>) => result && result['confirm']))
  }

  ngOnDestroy() {
    this._destroy$.next()
    this._destroy$.unsubscribe()
    this.service.reset()
  }
}
