import { DoorElevationModeSettingsComponent } from '../../dialogs/door-elevation-settings/door-elevation-mode-settings/door-elevation-mode-settings.component'
import { DoorElevationPairSettingsComponent } from '../../dialogs/door-elevation-settings/door-elevation-pair-settings/door-elevation-pair-settings.component'
import { DoorElevationSingleSettingsComponent } from '../../dialogs/door-elevation-settings/door-elevation-single-settings/door-elevation-single-settings.component'
import { PrepsComponent } from '../../dialogs/preps/preps.component'
import { ComponentType } from '@angular/cdk/portal'
import { IStep } from '../../../core/interfaces/i-step'
import { DoorElevation } from '../../models/door-elevation'
import { Door } from '../../models/door'

export type DoorMode = 'Single' | 'Pair'

export type DoorElevationConfigResult = {
  doorElevation: DoorElevation
  persistedPreps: boolean
}

export enum StepType {
  DoorMode = 'DoorMode',
  DoorSettings = 'DoorSettings',
  Preps = 'Preps'
}

export enum DoorPosition {
  LEFT = 0,
  RIGHT = 1
}

export interface ConfigStep<T = any> extends Omit<IStep, 'order'> {
  dialogTitle?: string
  name: string
  component: ComponentType<T>
  showStepper: boolean
  type: StepType
  doorIndex: DoorPosition
  persistPreps: boolean
  subStep?: number
}

export const NEW_DOOR_STEPS: ConfigStep[] = [
  {
    dialogTitle: 'TITLE.defaultDoorSettings',
    name: 'TITLE.singlePair',
    component: DoorElevationModeSettingsComponent,
    showStepper: false,
    type: StepType.DoorMode,
    doorIndex: DoorPosition.LEFT,
    persistPreps: true,
    current: false,
    completed: false,
    step: 0
  }
]

export const SINGLE_DOOR_SETTINGS: ConfigStep<DoorElevationSingleSettingsComponent> = {
  name: 'TITLE.doorSettings',
  component: DoorElevationSingleSettingsComponent,
  showStepper: true,
  type: StepType.DoorSettings,
  doorIndex: DoorPosition.LEFT,
  persistPreps: true,
  current: false,
  completed: false,
  step: 1
}

export const SINGLE_PREP_STEPS: ConfigStep<PrepsComponent> = {
  name: 'TITLE.doorPreps',
  component: PrepsComponent,
  showStepper: false,
  type: StepType.Preps,
  doorIndex: DoorPosition.LEFT,
  persistPreps: true,
  current: false,
  completed: false,
  step: 2
}

export const PAIR_STEPS: ConfigStep<DoorElevationPairSettingsComponent>[] = [
  {
    name: 'TITLE.openingSettings',
    component: DoorElevationPairSettingsComponent,
    showStepper: true,
    type: StepType.DoorSettings,
    doorIndex: DoorPosition.LEFT,
    persistPreps: true,
    subStep: 0,
    current: false,
    completed: false,
    step: 1
  },
  {
    dialogTitle: 'TITLE.leftDoorSettings',
    name: 'leftDoor',
    component: DoorElevationPairSettingsComponent,
    showStepper: true,
    type: StepType.DoorSettings,
    doorIndex: DoorPosition.LEFT,
    persistPreps: true,
    subStep: 1,
    current: false,
    completed: false,
    step: 2
  },
  {
    dialogTitle: 'TITLE.rightDoorSettings',
    name: 'rightDoor',
    component: DoorElevationPairSettingsComponent,
    showStepper: true,
    type: StepType.DoorSettings,
    doorIndex: DoorPosition.RIGHT,
    persistPreps: true,
    subStep: 2,
    current: false,
    completed: false,
    step: 3
  }
]

export function getPairPrepSteps(doors: Door[]) {
  const leftActive = !!doors[DoorPosition.LEFT].active
  const rightActive = !!doors[DoorPosition.RIGHT].active
  if (leftActive || !(leftActive || rightActive)) return PAIR_PREPS_STEPS
  else return PAIR_PREPS_STEPS.slice().reverse().map((step, i) => ({ ...step, step: 4 + i }))
}

export const PAIR_PREPS_STEPS: ConfigStep<PrepsComponent>[] = [
  {
    name: 'leftDoorPreps',
    component: PrepsComponent,
    showStepper: false,
    type: StepType.Preps,
    doorIndex: DoorPosition.LEFT,
    persistPreps: true,
    current: false,
    completed: false,
    step: 4
  },
  {
    name: 'rightDoorPreps',
    component: PrepsComponent,
    showStepper: false,
    type: StepType.Preps,
    doorIndex: DoorPosition.RIGHT,
    persistPreps: true,
    current: false,
    completed: false,
    step: 5
  }
]
