import { FilteredType } from '../types'

export function objectKeyExtractor<T, K>(obj: T, regEx: RegExp): FilteredType<T, K>[] {
  const extractedKeys: FilteredType<T, K>[] = []

  Object.keys(obj ?? {}).forEach((key) => {
    if (regEx.test(key)) {
      extractedKeys.push(key as FilteredType<T, K>)
    }
  })
  return extractedKeys
}
