import { CoreConstants } from '../../core/core.constants';
import { Approval } from '../../core/enums/approval';
import { DoorCore } from '../enums/door-core';
import { DoorEdge } from '../enums/door-edge';
import { DoorGauge } from '../enums/door-gauge';
import { DoorMaterial } from '../enums/door-material';
import { DoorSeamlessEdge } from '../enums/door-seamless-edge';

export function gaugeMaterials(materials: DoorMaterial[], gauge: DoorGauge) {
  if (gauge === DoorGauge._20 || gauge === DoorGauge._18) {
    materials.remove(DoorMaterial.G90);
  }
}

export function gaugeEdges(edges: DoorEdge[], gauge: DoorGauge) {
  if (gauge === DoorGauge._14) {
    edges.remove(DoorEdge.BEVSQ);
  }
}

export function gaugeSeamlessEdges(seamlessEdges: DoorSeamlessEdge[], gauge: DoorGauge) {
  if (gauge === DoorGauge._20) {
    seamlessEdges.remove(DoorSeamlessEdge.CONT);
    seamlessEdges.remove(DoorSeamlessEdge.CONT_TOP);
    seamlessEdges.remove(DoorSeamlessEdge.CONT_TOPBTM);
  }
  if (gauge === DoorGauge._14) {
    seamlessEdges.remove(DoorSeamlessEdge.VBLE);
    seamlessEdges.remove(DoorSeamlessEdge.VBLE_TOP);
    seamlessEdges.remove(DoorSeamlessEdge.VBLE_TOPBTM);
  }
}

export function gaugeApprovals(approvals: Approval[], gauge: DoorGauge) {
  if (gauge === DoorGauge._16) {
    approvals.remove(Approval.STC50);
    approvals.remove(Approval.STC52);
    approvals.remove(Approval.C_STC50);
  }
  if (gauge === DoorGauge._14) {
    approvals.remove(Approval.C_STC42);
    approvals.remove(Approval.STC48);
    approvals.remove(Approval.STC40);
    approvals.remove(Approval.STC42);
    approvals.remove(Approval.STC43);
    approvals.remove(Approval.STC47);
  }
}
