import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs';
import { IPrep } from '../../../../core/interfaces/i-prep';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepsComponent } from '../preps.component';
import { EzPrepComponent } from './ez-prep.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-flush-surface-bolt-prep',
  templateUrl: './ez-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class FlushSurfaceBoltPrepComponent extends EzPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;
  isSpecial: boolean = false;

  public get prep(): IPrep {
    return this.frameElevation.flushSurfaceBoltPrep;
  }
  public set prep(value: IPrep) {
    this.frameElevation.flushSurfaceBoltPrep = value;
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, FramePrepCategoryIds.FLUSH_SURFACE_BOLT, 'Flush/Surface Bolt');
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  ngOnDestroy(): void {
    super.destroy();
  }
}
