import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IRestService } from '../interfaces/i-rest-service';
import { IQueryParams } from '../interfaces/i-query-params';
import { IValidationMessage } from '../interfaces/i-validation-message';
import { IElevation } from '../interfaces/i-elevation';

export class RestService<T> implements IRestService<T> {
  constructor(private httpClient: HttpClient, protected route: string) {}
  search(query: IQueryParams): Observable<T[]> {
    const queryParams = new URLSearchParams();
    if (query.pageIndex) {
      queryParams.append('pageIndex', `${query.pageIndex}`);
    }
    if (query.pageSize) {
      queryParams.append('pageSize', `${query.pageSize}`);
    }
    if (query.includes) {
      for (const include of query.includes) {
        queryParams.append('includes', include);
      }
    }
    if (query.search) {
      for (const s in query.search) {
        queryParams.append(`search[${s}]`, query.search[s]);
      }
    }
    return this.httpClient.get<T[]>(`${this.route}?${queryParams}`);
  }
  getById(id: number, includes: string[]): Observable<T> {
    const queryParams = new URLSearchParams();
    for (const value of includes) {
      queryParams.append('includes', value);
    }
    return this.httpClient.get<T>(`${this.route}/${id}?${queryParams}`);
  }
  create(params: any): Observable<T> {
    return this.httpClient.post<T>(`${this.route}`, params);
  }
  update(id: number, params: { [key: string]: any }): Observable<void> {
    return this.httpClient.patch<void>(`${this.route}/${id}`, params);
  }
  delete(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.route}/${id}`);
  }
}

export abstract class BaseElevationRestService<T extends IElevation> extends RestService<IElevation>{
  constructor(http: HttpClient, route: string) {
    super(http, route)
  }
  abstract messages: IValidationMessage[]
  abstract get elevation(): T
  abstract set elevation(elevation: T | null)
}
