import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-preps',
  templateUrl: './preps.component.html',
  styleUrls: ['./preps.component.scss'],
})
export class PrepsComponent implements OnInit {
  prepType: 'Door' | 'Frame' = 'Door';
  types: ['Preps', 'Locations', 'Hardware'] = ['Preps', 'Locations', 'Hardware'];
  type: 'Preps' | 'Locations' = this.types[0];
  constructor() {}

  ngOnInit(): void {}
}
