<div class="host">
  <lib-tool-bar
    [help]="false"
    [edit]="false"
    [tools]="tools"
    (tool$)="setTool($event)"
    [tool]="tool"
    (settings$)="openSettings()"
    [money]="true"
    (money$)="money()"
  ></lib-tool-bar>
  <lib-drawing-pad *ngIf="elevation" [doorElevation]="elevation" [activeDoor]="service.currentDoor"></lib-drawing-pad>
  <lib-door-info *ngIf="service.elevation"></lib-door-info>
  <lib-message-handler [messages]="service.messages"></lib-message-handler>
</div>
<div libDialog></div>
