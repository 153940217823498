<ng-container *ngFor="let t of tools">
  <button
    #actions
    class="tool-bar-button padding s4 all"
    *ngIf="t.visible()"
    (click)="setTool(t)"
    [disabled]="t.disabled()"
    [ngClass]="{ 'btn-active': t.active }"
    matTooltip="{{ t.type | titlecase }}"
    matTooltipPosition="right"
    matTooltipShowDelay="300"
  >
    <mat-icon color="#2C2C2C" [svgIcon]="t.type"></mat-icon>
  </button>
</ng-container>
<div class="spacer"></div>
<i
  class="material-icons"
  *ngIf="help"
  (click)="resetTool(); help$.next()"
  [matTooltip]="'help' | translate"
  matTooltipPosition="right"
  matTooltipShowDelay="300"
>help</i>
<i
  class="material-icons"
  *ngIf="money"
  (click)="resetTool(); money$.next()"
  [matTooltip]="'configurationPricing' | translate"
  matTooltipPosition="right"
  matTooltipShowDelay="300"
  >attach_money</i>
<i
  class="material-icons"
  *ngIf="settings"
  (click)="resetTool(); settings$.next()"
  [matTooltip]="'editSettings' | translate"
  matTooltipPosition="right"
  matTooltipShowDelay="300"
>edit</i>
