<div class="left">
  <div class="top">
     <input
        type="search"
        ngModel
        (ngModelChange)="search$.next($event)"
        [placeholder]="'ACTIONS.search' | translate"
      />
  </div>
  <div class="bottom">
    <div
      class="hardware"
      [ngClass]="{ active: c === productCategory }"
      *ngFor="let c of (productCategories$.value)"
      (click)="productCategory = c"
      [title]="c.displayName"
    >
      {{ c.displayName }}
    </div>
  </div>
</div>
<div class="right">
  <mat-progress-bar mode="indeterminate" [ngStyle]="{visibility: ((dataLoading$ | async) || loading)? 'visible': 'hidden'}"></mat-progress-bar>
  <lib-hardware
    (loading)="loading = $event"
    *ngIf="productCategory"
    [prepCategories]="prepCategories"
    [productCategory]="productCategory"
    [productCategoryService]="productCategoryService"
    [productCategoryPrepCategoryService]="productCategoryPrepCategoryService"
  ></lib-hardware>
</div>
