export enum DoorSweep {
  CROWER = 'K.N. Crower',
  DHSI = 'DHSI',
  HAGER = 'Hager',
  JOHNSON = 'Johnson',
  NG = 'National Guard',
  PEMCO = 'Pemco',
  REESE = 'Reese',
  ZERO = 'Zero',
}
