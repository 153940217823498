import { Panel } from '../../../abstracts/panel';
import { Lite } from '../lite';

export class GlassPanel extends Panel {
  constructor(x: number, y: number, width: number, height: number) {
    super(x, y, width, height);
  }

  drawCutout() {
    return new Lite('Lite', null, this.x, this.y, this.width, this.height, null, null).drawCutout();
  }
}
