import { Component, OnInit, Input } from '@angular/core'
import { TableColumn } from '../../../../types'

export type TIconComponentData = {
  icon?: string
  iconClasses?: string[]
  iconColor?: string
  iconTooltip?: string
}

@Component({
  selector: 'oa-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent<T> {
  /** For the icon component, the data is a boolean value whether or not to show the icon */
  @Input() row!: TIconComponentData
  @Input() column!: TableColumn<T>

  showIcon(rowData: any, property: string | ((arg: any) => any)) {
    if (typeof property === 'string') return rowData[property]
    else if (typeof property === 'function') return property(rowData)
    else return false
  }
}
