import { coreGlassInstallations } from '../../rules/cores';
import { styleGlassInstallations } from '../../rules/glass-installation';
import { steelStiffenGlassInstallations } from '../../rules/steel-stiffen';
import { DoorCore } from '../door-core';
import { DoorGlassInstallation } from '../door-glass-installation';
import { DoorSteelStiffen } from '../door-steel-stiffen';
import { DoorStyle } from '../door-style';
import { getEnumValues } from '@oeo/common';

export function DoorGlassInstallations(core: DoorCore, steelStiffen: DoorSteelStiffen, style: DoorStyle) {
  const glassInstallations = getEnumValues(DoorGlassInstallation);
  steelStiffenGlassInstallations(glassInstallations, steelStiffen);
  coreGlassInstallations(glassInstallations, core);
  styleGlassInstallations(glassInstallations, style);
  return glassInstallations;
}
