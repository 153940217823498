import { PoInfo } from './po-info.model'

export class Address {
  id?: number
  name: string
  line1: string
  line2: string
  city: string
  stateOrProvince: string
  postalCode: string
  country: string
  telephone: string
  fax?: string
  userId?: number

  constructor(poInfo?: PoInfo) {
    if (poInfo) {
      this.id = null
      this.name = poInfo.installationAddressString
      this.line1 = poInfo.installationAddressString
      this.line2 = null
      this.city = poInfo.installationCity
      this.stateOrProvince = poInfo.installationStateOrProvince
      this.postalCode = poInfo.installationPostalCode
      this.country = poInfo.country
      this.telephone = null
      this.fax = null
      this.userId = 0
    } else {
      this.id = null
      this.name = null
      this.line1 = null
      this.line2 = null
      this.city = null
      this.stateOrProvince = null
      this.postalCode = null
      this.country = null
      this.telephone = null
      this.fax = null
      this.userId = 0
    }
  }
}

export class ShortAddress {
  name: string
  line1: string
  line2: string
  city: string
  stateOrProvince: string
  postalCode: string
  telephone: string
  country: string
  constructor(address: Address) {
    if (!address) {
      return
    }
    this.name = address.name ? address.name.trim().toUpperCase() : null
    this.line1 = address.line1 ? address.line1.trim().toUpperCase() : null
    this.line2 = address.line2 ? address.line2.trim().toUpperCase() : null
    this.city = address.city ? address.city.trim().toUpperCase() : null
    this.stateOrProvince = address.stateOrProvince ? address.stateOrProvince.trim().toUpperCase() : null
    this.postalCode = address.postalCode ? address.postalCode.trim().toUpperCase() : null
    this.telephone = address.telephone ? address.telephone.replace(/\D/g, '') : null
    this.country = address.country ? address.country.trim() : null
  }
}

export class AddressValidationResponse {
  address?: Address
  isUpdated: boolean
  status: string
  message: string
}
