import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core'
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  ValidationErrors,
  FormArray,
  FormControl
} from '@angular/forms'
import { LocksmithIdNumber } from '../../../../models/locksmithIdNumber.model'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { LocksmithIdNumbersService } from './locksmith-id-numbers.service'
import { HelperService } from '../../../../services/helper/helper.service'
import { ControlsOf } from '@oeo/common'

@Component({
  selector: 'oa-locksmith-id-numbers',
  templateUrl: './locksmith-id-numbers.component.html',
  styleUrls: ['./locksmith-id-numbers.component.scss']
})
export class LocksmithIDNumbersComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readOnly: boolean
  destroyed$: Subject<boolean> = new Subject()

  lockSmithIdForm: FormGroup<{ locksmithNumbers: FormArray<FormGroup<ControlsOf<LocksmithIdNumber>>> }>
  idNumberArray: Array<string> = []

  constructor(
    public fb: FormBuilder,
    public locksmithIdNumbersService: LocksmithIdNumbersService,
    public helperService: HelperService
  ) {
    this.createForm()
  }

  get numbers() {
    return this.lockSmithIdForm.controls.locksmithNumbers
  }

  ngOnInit() {
    this.locksmithIdNumbersService
      .getCustomerLocksmithNumbers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((locksmithIdNumbers: LocksmithIdNumber[]) => {
        this.lockSmithIdForm.controls.locksmithNumbers.patchValue([])
        if (!locksmithIdNumbers) {
          return
        }
        if (locksmithIdNumbers.length > 0) {
          locksmithIdNumbers.forEach((locksmithIdNumber) => this.addNumber(locksmithIdNumber))
        }
        this.addNumber()
      })
  }

  ngOnChanges(event: SimpleChanges) {
    if (!event.readOnly) {
      return
    }
    if (event.readOnly.currentValue === true) {
      this.numbers.controls.forEach((c) => c.disable())
    } else {
      this.numbers.controls.forEach((c) => c.enable())
    }
  }

  createForm(): void {
    this.lockSmithIdForm = this.fb.group<{ locksmithNumbers: FormArray<FormGroup<ControlsOf<LocksmithIdNumber>>> }>({
      locksmithNumbers: this.fb.array<FormGroup<ControlsOf<LocksmithIdNumber>>>([])
    })
  }

  createNumber(number?: LocksmithIdNumber) {
    const group: FormGroup = this.fb.group<ControlsOf<LocksmithIdNumber>>({
      locksmithNumber: new FormControl({ value: null, disabled: this.readOnly }, [
        Validators.required,
        Validators.pattern(/^([a-zA-Z]|\d){6}$/),
        this.duplicateValidator
      ]),
      description: new FormControl({ value: null, disabled: this.readOnly }, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      id: new FormControl(null)
    })
    if (number) {
      group.patchValue(number)
    }
    return group
  }

  addNumber(number?: LocksmithIdNumber) {
    this.numbers.push(this.createNumber(number))
  }

  addExistingLockSmithNumbers(numbers: LocksmithIdNumber[]): void {
    numbers.forEach((number: LocksmithIdNumber) => this.addNumber(number))
  }

  isLastRow(index: number): boolean {
    return this.numbers.length - 1 === index
  }

  removeIdNumber(id: number, index: number): void {
    this.locksmithIdNumbersService.deleteCustomerLocksmithNumber(id).subscribe((_) => {
      const control = this.lockSmithIdForm.controls.locksmithNumbers
      control.removeAt(index)
      this.idNumberArray = []
      control.value.forEach((id: LocksmithIdNumber) => {
        this.idNumberArray.push(id.locksmithNumber)
      })
      this.helperService.openAlert({ title: 'ALERT.lockSmithNumberDeleted', state: 'info' })
    })
  }

  onBlur(lockSmithNumber: FormControl) {
    if (lockSmithNumber.invalid || lockSmithNumber.pristine || this.readOnly) {
      return
    }
    return this.locksmithIdNumbersService.saveCustomerLocksmithNumber(lockSmithNumber.value).subscribe((res) => {
      lockSmithNumber.patchValue(res)
      this.helperService.openAlert({ title: 'ALERT.lockSmithNumberSaved', state: 'info' })
    })
  }

  duplicateValidator: ValidatorFn = (control: FormGroup<ControlsOf<LocksmithIdNumber>>): ValidationErrors | null => {
    if (!control.value) {
      return null
    }
    const _currentIdNumbers: Array<string> = []
    const _lockSmithIdForm = this.lockSmithIdForm
    _lockSmithIdForm.value.locksmithNumbers.forEach((value) => {
      _currentIdNumbers.push(value.locksmithNumber)
    })
    const idNumber = control.value
    const match = _currentIdNumbers.filter((num) => num === idNumber).length > 1
    return match ? { duplicate: true } : null
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
