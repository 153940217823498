import { DoorLiteKit } from './../door-lite-kit';

export function DoorLiteKits(isFireRated: boolean, isLeadLined: boolean): DoorLiteKit[] {
  const liteKits = Object.values(DoorLiteKit);
  if (!isFireRated && !isLeadLined) {
    [
      DoorLiteKit._115L1,
      DoorLiteKit._115L3,
      DoorLiteKit.VLFDGLL,
      DoorLiteKit.VLFLL,
      DoorLiteKit.SUNBURST,
      DoorLiteKit._651,
      DoorLiteKit._664,
      DoorLiteKit._670,
      DoorLiteKit._672,
      DoorLiteKit._680,
      DoorLiteKit._681,
      DoorLiteKit._684,
    ].forEach(kit => liteKits.remove(kit));
    return liteKits;
  }
  if (!isFireRated && isLeadLined) {
    return [DoorLiteKit._115L1, DoorLiteKit._115L3, DoorLiteKit.VLFDGLL, DoorLiteKit.VLFLL];
  }
  if (isFireRated && !isLeadLined) {
    [
      DoorLiteKit._115L1,
      DoorLiteKit._115L3,
      DoorLiteKit._110D1,
      DoorLiteKit._110D2,
      DoorLiteKit.VLFDGLL,
      DoorLiteKit.VLFLL,
      DoorLiteKit.SUNBURST,
      DoorLiteKit._651,
      DoorLiteKit._664,
      DoorLiteKit._670,
      DoorLiteKit._672,
      DoorLiteKit._680,
      DoorLiteKit._681,
      DoorLiteKit._684,
    ].forEach(kit => liteKits.remove(kit));
    return liteKits;
  }
  if (isFireRated && isLeadLined) {
    return [DoorLiteKit._115L1, DoorLiteKit._115L3, DoorLiteKit.VLFDGLL];
  }
}
