export enum DoorSubType {
  STD = 'Standard',
  EMB = 'Embossed',
  IMP = 'Impact',
  IMPEMB = 'Impact (Embossed)',
  INS = 'Insulated',
  STL = 'Steel Stiffened',
  LL = 'Lead Lined',
  BULLET = 'Bullet',
  STCEMB = 'STC (Embossed)',
  STC = 'STC',
}
