import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { QuantityToOrderHardwareSetDoorMarking, QuantityToOrderProductDoorMarking } from '../../models/door-marking'
import { QuantityFlattenedHardwareSet } from '../../models/flattenedHardwareSet.model'
import { KeyedGroup } from '../../models/keyedGroups.model'
import { PoInfo } from '../../models/po-info.model'
import { QuantityFlattenedProduct } from '../../models/product.model'

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {
  //Products
  private _products: BehaviorSubject<QuantityFlattenedProduct[]> = new BehaviorSubject(null)
  products$: Observable<QuantityFlattenedProduct[]> = this._products.asObservable()
  private productList: QuantityFlattenedProduct[] = []

  private _productNumber: BehaviorSubject<number> = new BehaviorSubject(0)
  productNumber$: Observable<number> = this._productNumber.asObservable()

  // HardwareSets
  private _hardwareSets: BehaviorSubject<QuantityFlattenedHardwareSet[]> = new BehaviorSubject(null)
  hardwareSets$: Observable<QuantityFlattenedHardwareSet[]> = this._hardwareSets.asObservable()
  private hardwareSetsList: QuantityFlattenedHardwareSet[] = []

  // HardwareSetsNumber
  private _hardwareSetNumber: BehaviorSubject<number> = new BehaviorSubject(0)
  hardwareSetNumber$: Observable<number> = this._hardwareSetNumber.asObservable()

  // QuantityToOrderDoormarkings
  private _quantityToOrderDoormarkings: BehaviorSubject<
    QuantityToOrderProductDoorMarking[] | QuantityToOrderHardwareSetDoorMarking[]
  > = new BehaviorSubject(null)
  quantityToOrderDoormarkings$: Observable<
    QuantityToOrderProductDoorMarking[] | QuantityToOrderHardwareSetDoorMarking[]
  > = this._quantityToOrderDoormarkings.asObservable()
  private quantityToOrderDoormarkingsList:
    | QuantityToOrderProductDoorMarking[]
    | QuantityToOrderHardwareSetDoorMarking[] = []

  //map constains [estimate product id , selected doormarking list]
  private _quantityToOrderDoorMarkingProductId: BehaviorSubject<
    Map<number, QuantityToOrderProductDoorMarking[] | QuantityToOrderHardwareSetDoorMarking[]>
  > = new BehaviorSubject(
    new Map<number, QuantityToOrderProductDoorMarking[] | QuantityToOrderHardwareSetDoorMarking[]>()
  )
  quantityToOrderDoorMarkingProductId$: Observable<
    Map<number, QuantityToOrderProductDoorMarking[] | QuantityToOrderHardwareSetDoorMarking[]>
  > = this._quantityToOrderDoorMarkingProductId.asObservable()
  private quantityToOrderDoorMarkingProductIdMap = new Map<
    number,
    QuantityToOrderProductDoorMarking[] | QuantityToOrderHardwareSetDoorMarking[]
  >()

  private _beginPhasedOrder: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  beginPhasedOrder$: Observable<boolean> = this._beginPhasedOrder.asObservable()

  // update the indexedDB version to trigger IDBVersionChangeEvent to create all schemas
  openRequest!: IDBOpenDBRequest
  public db!: IDBDatabase

  constructor() {}

  public async init() {
    if (!('indexedDB' in window)) {
      throw new Error(
        "Your browser doesn't support a stable version of IndexedDB. You will be unable to complete the checkout process.."
      )
    }
    return new Promise((resolve, reject) => {
      let _db: IDBDatabase
      this.openRequest = indexedDB.open('eto', 3)
      this.openRequest.onupgradeneeded = (e: IDBVersionChangeEvent) => {
        _db = (e.target as any).result
        // estimates store introduced in version 1
        if (!_db.objectStoreNames.contains('estimates')) {
          _db.createObjectStore('estimates')
        }
        // estimates store introduced in version 2
        if (!_db.objectStoreNames.contains('products')) {
          _db.createObjectStore('products')
        }
        // hwSets store introduced in version 2
        if (!_db.objectStoreNames.contains('hwSets')) {
          _db.createObjectStore('hwSets')
        }
        // keyedGroups store introduced in version 2
        if (!_db.objectStoreNames.contains('keyedGroups')) {
          _db.createObjectStore('keyedGroups')
        }

        this.db = _db
      }

      this.openRequest.onsuccess = (e) => {
        this.db = (e.target as any).result
        resolve(this.db)
      }

      this.openRequest.onerror = (e) => {
        console.log('Error')
        console.dir(e)
        reject()
      }
    })
  }

  // CheckoutInfo
  getCheckoutInfo(estimateId: number | string): Promise<PoInfo> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['estimates'], 'readwrite').objectStore('estimates')
      const getRequest = objectStore.get(Number(estimateId))
      getRequest.onsuccess = () => resolve(getRequest.result || {})
      getRequest.onerror = (ev) => reject(ev)
    })
  }

  updateCheckoutInfo(estimateId: number | string, payload: PoInfo): Promise<PoInfo> {
    return new Promise(async (resolve, reject) => {
      const currentPoInfo = await this.getCheckoutInfo(Number(estimateId))
      if (!currentPoInfo.isBeginPhasedOrderClicked && !payload.isBeginPhasedOrderClicked) {
        this._beginPhasedOrder.next(false)
      } else if (!payload.isBeginPhasedOrderClicked) {
        this._beginPhasedOrder.next(currentPoInfo.isBeginPhasedOrderClicked)
      } else if (!currentPoInfo.isBeginPhasedOrderClicked) {
        this._beginPhasedOrder.next(payload.isBeginPhasedOrderClicked)
      } else {
        this._beginPhasedOrder.next(payload.isBeginPhasedOrderClicked)
      }
      const updatedPoInfo = { ...currentPoInfo, ...payload }
      if (updatedPoInfo.installationByCustomer) {
        updatedPoInfo.installationContactName = ''
        updatedPoInfo.installationCity = ''
        updatedPoInfo.installationStateOrProvince = ''
        updatedPoInfo.installationPostalCode = ''
        updatedPoInfo.installationAddressString = ''
      }
      const objectStore = this.db.transaction(['estimates'], 'readwrite').objectStore('estimates')
      const putRequest = objectStore.put(updatedPoInfo, Number(estimateId))
      putRequest.onsuccess = () => resolve(updatedPoInfo)
      putRequest.onerror = (ev) => reject(ev)
    })
  }

  deleteAllCheckoutInfos() {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['estimates'], 'readwrite').objectStore('estimates')
      const deletRequest = objectStore.clear()

      deletRequest.onsuccess = () => resolve(deletRequest.result || {})
      deletRequest.onerror = (ev) => reject(ev)
    })
  }

  //Product
  getProductInfo(productId: number | string): Promise<QuantityFlattenedProduct> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['products'], 'readwrite').objectStore('products')
      const getRequest = objectStore.get(Number(productId))
      getRequest.onsuccess = () => resolve(getRequest.result || {})
      getRequest.onerror = (ev) => reject(ev)
    })
  }

  updateProductInfo(productId: number | string, payload: QuantityFlattenedProduct): Promise<QuantityFlattenedProduct> {
    return new Promise(async (resolve, reject) => {
      const currentProductInfo = await this.getProductInfo(Number(productId))
      // this product is not stored in the indexdb
      if (Object.keys(currentProductInfo).length === 0) {
        payload.quantityToOrder = 0
        const updateProductInfo = { ...currentProductInfo, ...payload }
        const objectStore = this.db.transaction(['products'], 'readwrite').objectStore('products')
        const putRequest = objectStore.put(updateProductInfo, Number(productId))

        this.productList = this.productList.concat([updateProductInfo])
        this._productNumber.next(this.productList.length)
        this._products.next(this.productList) // this.productList refer a new object

        putRequest.onsuccess = () => resolve(updateProductInfo)
        putRequest.onerror = (ev) => reject(ev)
      } else {
        //should delete this product
        if (currentProductInfo.quantityToOrderDoorMarks && currentProductInfo.quantityToOrderDoorMarks.length) {
          currentProductInfo.quantityToOrderDoorMarks = []

          //set quantityToOrderDoormarkingsList to empty
          this.quantityToOrderDoormarkingsList = []
          this._quantityToOrderDoormarkings.next(this.quantityToOrderDoormarkingsList)

          //delete that key in the map
          this.quantityToOrderDoorMarkingProductIdMap.delete(Number(productId))
          this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)
        }
        const deleteRequest = await this.deleteProductInfo(productId)
        resolve({} as QuantityFlattenedProduct)
      }
    })
  }

  deleteAllProducts() {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['products'], 'readwrite').objectStore('products')
      const deletRequest = objectStore.clear()

      //clear up
      this.productList = []
      this._productNumber.next(this.productList.length)
      this._products.next(this.productList) // this.productList refer a new object

      this.quantityToOrderDoorMarkingProductIdMap = new Map()
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      deletRequest.onsuccess = () => resolve(deletRequest.result || {})
      deletRequest.onerror = (ev) => reject(ev)
    })
  }

  deleteProductInfo(productId: number | string) {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['products'], 'readwrite').objectStore('products')
      const deletRequest = objectStore.delete(Number(productId))

      this.productList = this.productList.filter((p) => p.id !== productId).concat([])
      this._productNumber.next(this.productList.length)
      this._products.next(this.productList) // this.productList refer a new object

      this.quantityToOrderDoorMarkingProductIdMap.delete(Number(productId))
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      deletRequest.onsuccess = () => resolve(deletRequest.result || {})
      deletRequest.onerror = (ev) => reject(ev)
    })
  }

  getAllProductInfo(): Promise<QuantityFlattenedProduct[]> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['products'], 'readonly').objectStore('products')
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => resolve(getAllRequest.result || [])
      getAllRequest.onerror = (ev) => reject(ev)
    })
  }

  updateProductQuantityToOrder(productId: number | string, newValue: number | string) {
    return new Promise(async (resolve, reject) => {
      const currentProductInfo = await this.getProductInfo(Number(productId))
      currentProductInfo.quantityToOrder = Number(newValue)
      const objectStore = this.db.transaction(['products'], 'readwrite').objectStore('products')
      const updateRequest = objectStore.put(currentProductInfo, Number(productId))
      const allProducts = await this.getAllProductInfo()
      this.productList = allProducts
      this._products.next(this.productList)
      updateRequest.onsuccess = () => resolve(currentProductInfo)
      updateRequest.onerror = (ev) => reject(ev)
    })
  }

  updateProductQuantityToOrderHWS(hwsId: number | string, newValue: number | string) {
    return new Promise(async (resolve, reject) => {
      const currentHWSInfo = await this.getHardwareSetInfo(Number(hwsId))
      currentHWSInfo.quantityToOrder = Number(newValue)
      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const updateRequest = objectStore.put(currentHWSInfo, Number(hwsId))
      const allHWSs = await this.getAllHardwareSetInfo()
      this.hardwareSetsList = allHWSs
      this._hardwareSets.next(this.hardwareSetsList)
      updateRequest.onsuccess = () => resolve(currentHWSInfo)
      updateRequest.onerror = (ev) => reject(ev)
    })
  }

  updateProductQuantityToOrderDetail(
    productId: number,
    payload: Array<QuantityToOrderProductDoorMarking>
  ): Promise<QuantityFlattenedProduct> {
    if (payload && payload.length) {
      payload.forEach((product) => (product.estimateProductId = productId))
    }
    return new Promise(async (resolve, reject) => {
      const currentProductInfo = await this.getProductInfo(productId)
      currentProductInfo.quantityToOrderDoorMarks = payload
      const updateProductInfo = { ...currentProductInfo }
      const objectStore = this.db.transaction(['products'], 'readwrite').objectStore('products')
      const putRequest = objectStore.put(updateProductInfo, productId)

      this.quantityToOrderDoormarkingsList = payload
      this._quantityToOrderDoormarkings.next(this.quantityToOrderDoormarkingsList)

      const allProducts = await this.getAllProductInfo()
      this.productList = allProducts
      this._products.next(this.productList)

      this.quantityToOrderDoorMarkingProductIdMap.set(productId, payload)
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      putRequest.onsuccess = () => resolve(updateProductInfo)
      putRequest.onerror = (ev) => reject(ev)
    })
  }

  updateProductQuantityToOrderDetailHWS(
    hwsId: number,
    payload: QuantityToOrderHardwareSetDoorMarking[]
  ): Promise<QuantityFlattenedHardwareSet> {
    if (payload && payload.length) {
      payload.forEach((product) => (product.estimateHardwareSetId = hwsId))
    }
    return new Promise(async (resolve, reject) => {
      const currentHWSInfo = await this.getHardwareSetInfo(hwsId)
      currentHWSInfo.quantityToOrderDoorMarks = payload
      const updateHWSInfo = { ...currentHWSInfo }
      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const putRequest = objectStore.put(updateHWSInfo, hwsId)

      this.quantityToOrderDoormarkingsList = payload
      this._quantityToOrderDoormarkings.next(this.quantityToOrderDoormarkingsList)

      const allProducts = await this.getAllHardwareSetInfo()
      this.hardwareSetsList = allProducts
      this._hardwareSets.next(this.hardwareSetsList)

      this.quantityToOrderDoorMarkingProductIdMap.set(hwsId, payload)
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      putRequest.onsuccess = () => resolve(updateHWSInfo)
      putRequest.onerror = (ev) => reject(ev)
    })
  }

  getAllSelectedDoorMarkings(): Promise<QuantityToOrderProductDoorMarking[]> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['products'], 'readonly').objectStore('products')
      const getAllProductsRequest = objectStore.getAll()
      getAllProductsRequest.onsuccess = () => {
        resolve(getAllProductsRequest.result || [])
      }
      getAllProductsRequest.onerror = (ev) => reject(ev)
    })
  }

  //HardwareSet
  getHardwareSetInfo(hardwareSetId: number | string): Promise<QuantityFlattenedHardwareSet> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['hwSets'], 'readonly').objectStore('hwSets')
      const getRequest = objectStore.get(Number(hardwareSetId))
      getRequest.onsuccess = () => resolve(getRequest.result || {})
      getRequest.onerror = (ev) => reject(ev)
    })
  }

  getAllHardwareSetInfo(): Promise<QuantityFlattenedHardwareSet[]> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['hwSets'], 'readonly').objectStore('hwSets')
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => resolve(getAllRequest.result || [])
      getAllRequest.onerror = (ev) => reject(ev)
    })
  }

  updateHardwareSetInfo(
    hardwareSetId: number | string,
    payload: QuantityFlattenedHardwareSet
  ): Promise<QuantityFlattenedHardwareSet> {
    return new Promise(async (resolve, reject) => {
      const currentHardwareSetInfo = await this.getHardwareSetInfo(Number(hardwareSetId))
      if (Object.keys(currentHardwareSetInfo).length === 0) {
        payload.quantityToOrder = 0
        const updateHardwareSetInfo = { ...currentHardwareSetInfo, ...payload }
        const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
        const putRequest = objectStore.put(updateHardwareSetInfo, Number(hardwareSetId))

        this.hardwareSetsList = this.hardwareSetsList.concat([updateHardwareSetInfo])
        this._hardwareSetNumber.next(this.hardwareSetsList.length)
        this._hardwareSets.next(this.hardwareSetsList)

        putRequest.onsuccess = () => resolve(updateHardwareSetInfo)
        putRequest.onerror = (ev) => reject(ev)
      } else {
        //should delete this product
        if (currentHardwareSetInfo.quantityToOrderDoorMarks && currentHardwareSetInfo.quantityToOrderDoorMarks.length) {
          currentHardwareSetInfo.quantityToOrderDoorMarks = []

          //set quantityToOrderDoormarkingsList to empty
          this.quantityToOrderDoormarkingsList = []
          this._quantityToOrderDoormarkings.next(this.quantityToOrderDoormarkingsList)

          //delete that key in the map
          this.quantityToOrderDoorMarkingProductIdMap.delete(Number(hardwareSetId))
          this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)
        }

        await this.deleteHardwareSetInfo(hardwareSetId)
      }
    })
  }

  updateHardwareSetQuantityToOrder(hardwareSetId: number | string, newValue: number | string) {
    return new Promise(async (resolve, reject) => {
      const currentHWSInfo = await this.getHardwareSetInfo(Number(hardwareSetId))
      currentHWSInfo.quantityToOrder = Number(newValue)
      const updateHardwareSetInfo = { ...currentHWSInfo }

      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const updateRequest = objectStore.put(updateHardwareSetInfo, Number(hardwareSetId))
      const allHardwareSets = await this.getAllHardwareSetInfo()
      this.hardwareSetsList = allHardwareSets
      this._hardwareSets.next(this.hardwareSetsList)
      updateRequest.onsuccess = () => resolve(currentHWSInfo)
      updateRequest.onerror = (ev) => reject(ev)
    })
  }

  updateHWSQuantityToOrder(hwsId: number | string, newValue: number | string) {
    return new Promise(async (resolve, reject) => {
      const currentHWSInfo = await this.getHardwareSetInfo(Number(hwsId))
      currentHWSInfo.quantityToOrder = Number(newValue)
      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const updateRequest = objectStore.put(currentHWSInfo, Number(hwsId))
      const allHWSs = await this.getAllHardwareSetInfo()
      this.hardwareSetsList = allHWSs
      this._hardwareSets.next(this.hardwareSetsList)
      updateRequest.onsuccess = () => resolve(currentHWSInfo)
      updateRequest.onerror = (ev) => reject(ev)
    })
  }

  updateHWSQuantityToOrderDetail(
    hwsId: number,
    payload: Array<QuantityToOrderHardwareSetDoorMarking>
  ): Promise<QuantityFlattenedHardwareSet> {
    if (payload && payload.length) {
      payload.forEach((product) => (product.estimateHardwareSetId = hwsId))
    }
    return new Promise(async (resolve, reject) => {
      const currentHWSInfo = await this.getHardwareSetInfo(hwsId)
      currentHWSInfo.quantityToOrderDoorMarks = payload
      const updateHWSInfo = { ...currentHWSInfo }
      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const putRequest = objectStore.put(updateHWSInfo, hwsId)

      this.quantityToOrderDoormarkingsList = payload
      this._quantityToOrderDoormarkings.next(this.quantityToOrderDoormarkingsList)

      const allHWSs = await this.getAllHardwareSetInfo()
      this.hardwareSetsList = allHWSs
      this._hardwareSets.next(this.hardwareSetsList)

      this.quantityToOrderDoorMarkingProductIdMap.set(hwsId, payload)
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      putRequest.onsuccess = () => resolve(updateHWSInfo)
      putRequest.onerror = (ev) => reject(ev)
    })
  }

  deleteAllHardwareSetInfos() {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const deletRequest = objectStore.clear()

      // clear up
      this.hardwareSetsList = []
      this._hardwareSetNumber.next(this.hardwareSetsList.length)
      this._hardwareSets.next(this.hardwareSetsList) // this.productList refer a new object

      this.quantityToOrderDoorMarkingProductIdMap = new Map()
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      deletRequest.onsuccess = () => resolve(deletRequest.result || {})
      deletRequest.onerror = (ev) => reject(ev)
    })
  }

  deleteHardwareSetInfo(hardwareSetId: number | string) {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['hwSets'], 'readwrite').objectStore('hwSets')
      const deletRequest = objectStore.delete(Number(hardwareSetId))
      this.hardwareSetsList = this.hardwareSetsList.filter((p) => p.id !== hardwareSetId).concat([])
      this._hardwareSetNumber.next(this.hardwareSetsList.length)
      this._hardwareSets.next(this.hardwareSetsList)

      this.quantityToOrderDoorMarkingProductIdMap.delete(Number(hardwareSetId))
      this._quantityToOrderDoorMarkingProductId.next(this.quantityToOrderDoorMarkingProductIdMap)

      deletRequest.onsuccess = () => resolve(deletRequest.result || {})
      deletRequest.onerror = (ev) => reject(ev)
    })
  }

  //Estimate Keyed Groups

  getKeyedGroups(estimateId: string | number): Promise<KeyedGroup[]> {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['keyedGroups'], 'readwrite').objectStore('keyedGroups')
      const getAllRequest = objectStore.get(Number(estimateId))
      getAllRequest.onsuccess = () => resolve(getAllRequest.result || [])
      getAllRequest.onerror = (ev) => reject(ev)
    })
  }

  updateKeyedGroups(estimateId: string | number, payload: KeyedGroup[]) {
    return new Promise(async (resolve, reject) => {
      // this product is not stored in the indexdb
      const objectStore = this.db.transaction(['keyedGroups'], 'readwrite').objectStore('keyedGroups')
      const putRequest = objectStore.put(payload, Number(estimateId))
      putRequest.onsuccess = (res) => resolve(res)
      putRequest.onerror = (ev) => reject(ev)
    })
  }

  deleteAllKeyedGroups() {
    return new Promise((resolve, reject) => {
      const objectStore = this.db.transaction(['keyedGroups'], 'readwrite').objectStore('keyedGroups')

      const deletRequest = objectStore.clear()
      deletRequest.onsuccess = () => resolve(deletRequest.result || {})
      deletRequest.onerror = (ev) => reject(ev)
    })
  }

  //IndexedDB
  deleteIndexDb() {
    this.deleteAllProducts()
    this.deleteAllHardwareSetInfos()
  }
}
