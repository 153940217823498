import { Component, OnInit, OnDestroy } from '@angular/core';
import { DoorService } from '../../services/door.service';
import { takeUntil } from 'rxjs/operators';
import { Door } from '../../models/door';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Handing } from '../../enums/handing';
import { nameOf } from '@oeo/common';
import { measurementValidators } from '../../../core/validators/measurementValidator';
import { ControlsOf } from '@oeo/common';

@Component({
  selector: 'lib-door-info',
  templateUrl: './door-info.component.html',
  styleUrls: ['./door-info.component.scss', '../info.component.scss'],
})
export class DoorInfoComponent implements OnInit, OnDestroy {
  door: Door;
  private _destroy$ = new Subject<void>();
  formGroup = new FormGroup<ControlsOf<{height?: FormControl<string>, width?: FormControl<string>}>>({});
  top = 16;
  right = 16;

  constructor(private doorService: DoorService) {}

  ngOnInit(): void {
    this.doorService.activeDoor$.pipe(takeUntil(this._destroy$)).subscribe(door => {
      this.door = door;
      if (this.door) {
        this.setupForm();
      }
    });
  }

  get handings() {
    return Object.keys(Handing).map(key => Handing[key as keyof typeof Handing]);
  }

  get height() {
    return this.formGroup.controls.height
  }

  get width() {
    return this.formGroup.controls.width
  }

  setupForm() {
    for (const control in this.formGroup.controls) {
      this.formGroup.removeControl(control);
    }

    this.formGroup.addControl(
      nameOf((_: Door) => _.height),
      new FormControl(
        { value: this.door.height.toDimension('frame', this.door.unitOfMeasure), disabled: true },
        measurementValidators(this.door.unitOfMeasure)
      )
    );

    this.formGroup.addControl(
      nameOf((_: Door) => _.width),
      new FormControl(
        { value: this.door.width.toDimension('frame', this.door.unitOfMeasure), disabled: true },
        measurementValidators(this.door.unitOfMeasure)
      )
    );
  }

  close(){
    this.doorService.deactivate()
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}
