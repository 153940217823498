import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SvgPanZoom from 'svg-pan-zoom';
import { IDialog } from '../../../core/interfaces/i-dialog';
import { ProfileTemplate } from '../../abstracts/profile-template';

@Component({
  selector: 'lib-frame-profile',
  templateUrl: './frame-profile.component.html',
  styleUrls: ['./frame-profile.component.scss'],
})
export class FrameProfileComponent implements OnInit, OnDestroy, IDialog<ProfileTemplate> {
  data: ProfileTemplate;
  private _destroy$ = new Subject<void>();
  private svgPanZoom: SvgPanZoom.Instance;

  @ViewChild('containerRef', { static: true }) containerRef: ElementRef<SVGSVGElement>;
  get container() {
    return this.containerRef.nativeElement;
  }

  @ViewChild('svgRef', { static: true }) svgRef: ElementRef<SVGSVGElement>;
  get svg() {
    return this.svgRef.nativeElement;
  }

  constructor() {}

  ngOnInit(): void {
    this.data.drawProfile(this.container);
    this.data.update$.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.data.drawProfile(this.container);
      this.resetAndFit();
    });
    requestAnimationFrame(() => {
      this.svgPanZoom = SvgPanZoom(this.svg, {
        zoomEnabled: false,
        fit: true,
        contain: true,
        center: true,
        panEnabled: false,
        dblClickZoomEnabled: false,
      });
      this.svgPanZoom.updateBBox().zoomOut().updateBBox();
    });
  }

  ngOnDestroy() {
    this.data.destroy();
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  private resetAndFit() {
    requestAnimationFrame(() => {
      this.svgPanZoom.updateBBox().center().updateBBox().fit().updateBBox().zoomOut();
    });
  }
}
