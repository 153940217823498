export {};

declare global {
  interface SVGPolygonElement {
    rootPoint(): DOMPoint;
  }
}

function rootPoint(this: SVGPolygonElement) {
  return this.points.getItem(0);
}

SVGPolygonElement.prototype.rootPoint = rootPoint;
