import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

import { map, take, mergeMap, distinctUntilChanged, tap } from 'rxjs/operators'
import { Observable, of, BehaviorSubject } from 'rxjs'

import { environment } from '../../../environments/environment'

import { Product } from '../../models/product.model'
import {
  HardwareSet,
  HardwareSetProductAttachment,
  IndirectHardwareSet,
  HardwareSetEstimates
} from '../../models/hardwareSet.model'
import { ExperlogixSession } from '../../models/experlogixSession.model'
import { Customer } from '../../models/customer.model'

import { EstimatesService } from '../../services/estimates/estimates.service'
import { RoutingService } from '../../services/routing/routing.service'
import { CustomerService } from '../customer/customer.service'
import { Cacheable } from 'ts-cacheable'
import { RouteIds } from '../../models/routeIds.model'
import { Params } from '@angular/router'

@Injectable({ providedIn: 'root' })
export class HardwareSetService {
  customer: Customer
  routeIds: RouteIds

  _hardwareSet: BehaviorSubject<HardwareSet | IndirectHardwareSet> = new BehaviorSubject(null)
  hardwareSet$: Observable<HardwareSet | IndirectHardwareSet> = this._hardwareSet.asObservable()
  hardwareSetId: number

  constructor(
    private authHttp: HttpClient,
    private estimateService: EstimatesService,
    private routingService: RoutingService,
    customerService: CustomerService
  ) {
    customerService.customer$.subscribe((customer) => (this.customer = customer || new Customer()))

    this.routingService.ids$.pipe(distinctUntilChanged()).subscribe((res: RouteIds) => {
      this.routeIds = res
      if (!res.hwSetId) {
        this._hardwareSet.next(null)
      } else if (res.hwSetId !== this.hardwareSetId) {
        this._hardwareSet.next(null)
        this.getHardwareSet().pipe(take(1)).subscribe()
      }
      this.hardwareSetId = res.hwSetId
    })
  }

  @Cacheable()
  public getBuyingPrograms(): Observable<any> {
    const url = environment.onlineOrderingApiUrl + 'BuyingPrograms'
    return this.authHttp.get(url)
  }

  public getHardwareSets(): Observable<HardwareSet[]> {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets'
    return this.authHttp.get(url).pipe(
      mergeMap((_hardwareSets: HardwareSet[]) => {
        return of(this.configureData(_hardwareSets))
      })
    )
  }

  configureData(hardwareSets: HardwareSet[]): HardwareSet[] {
    return hardwareSets.map((hardwareSet) => {
      let _hardwareSet: HardwareSet = new HardwareSet()
      _hardwareSet = Object.assign(_hardwareSet, hardwareSet)
      return _hardwareSet
    })
  }

  public getHardwareSet(id?: number) {
    if (this.customer?.isDirect) {
      return this.getDirectUserHwSet(id || this.routeIds.hwSetId).pipe(tap((res) => this._hardwareSet.next(res)))
    } else {
      return this.getIndirectUserHwSet(id || this.routeIds.hwSetId, this.routeIds.estimateId).pipe(
        tap((res) => this._hardwareSet.next(res))
      )
    }
  }

  public getDirectUserHwSet(id: number): Observable<HardwareSet> {
    if (!id) {
      return of(null)
    }
    const params = this.routingService.activatedRoute.queryParams as BehaviorSubject<Params>
    let url = environment.onlineOrderingApiUrl + 'HardwareSets/' + id
    if (params.value?.estimateId) url += `?estimateId=${params.value.estimateId}`
    return this.authHttp.get(url).pipe(map((hardwareSetItem: any) => <HardwareSet>hardwareSetItem))
  }

  // CLB
  public getIndirectUserHwSet(hardwareSetId: number, estimateId: number) {
    if (!hardwareSetId) {
      return of(null)
    }
    let url = environment.onlineOrderingApiUrl + `HardwareSets/${hardwareSetId}`
    if (estimateId) {
      url = url + `?estimateId=${estimateId}`
    }
    return this.authHttp.get(url).pipe(map((hardwareSetItem: any) => <IndirectHardwareSet>hardwareSetItem))
  }

  public deleteHardwareSet(id: number) {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + id
    return this.authHttp.delete(url)
  }

  public updateHardwareSet(payload: HardwareSet, id: number) {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + id
    const body = payload
    return this.authHttp.put(url, body).pipe(
      take(1),
      tap((hwset: IndirectHardwareSet | HardwareSet) => this._hardwareSet.next(hwset))
    )
  }

  public createHardwareSet(payload: Partial<HardwareSet>): Observable<HardwareSet> {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets'
    const body = payload
    return this.authHttp.post<HardwareSet>(url, body)
  }

  public createAndAddHardwareSet(name: string, estimateId: number): Observable<HardwareSet> {
    const indirect = !this.customer?.isDirect
    if (indirect) {
      return this.createIndirectHardwareSet({ name: name, setQuantity: 1, estimateId }).pipe(take(1))
    } else {
      let hwSet: HardwareSet
      return this.createHardwareSet({ name, setQuantity: 1 }).pipe(
        mergeMap((res: HardwareSet) => {
          hwSet = res
          this._hardwareSet.next(res)
          return this.estimateService.saveHardwareSetsToEstimate(estimateId, [
            { hardwareSetId: res.hardwareSetId, quantity: 1 }
          ])
        }),
        mergeMap((res) => of(hwSet))
      )
    }
  }

  public createIndirectHardwareSet<Payload extends { estimateId: number }>(payload: Payload): Observable<HardwareSet> {
    const url = environment.onlineOrderingApiUrl + `HardwareSets/?estimateId=${payload.estimateId}`
    delete payload.estimateId
    const body = payload
    return this.authHttp.post<HardwareSet>(url, body)
  }

  public copyHardwareSet(hardwareSetId: number, hardwareSet: HardwareSet): Observable<number> {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + hardwareSetId + '/Copy'
    const payload = {
      name: hardwareSet.name
    }
    return this.authHttp.post<number>(url, payload)
  }

  getCutSheets(id: number, type: 'zip' | 'pdf') {
    const url = environment.onlineOrderingApiUrl + `HardwareSets/${id}/Cutsheets`
    let headers = new HttpHeaders()
    headers = headers.set('Accept', `application/${type}`)
    return this.authHttp.get(url, { headers: headers, responseType: 'blob' })
  }

  public deleteProductInHardwareSet(id: number, itemId: number) {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + id + '/Products/' + itemId
    return this.authHttp.delete(url).pipe(
      mergeMap((hwSet) => this.getHardwareSet()),
      mergeMap((hwSet) => {
        if (this.routeIds.estimateId) {
          return this.estimateService.getEstimate(this.routeIds.estimateId).pipe(map(() => hwSet))
        } else {
          return of(hwSet)
        }
      })
    )
  }

  public getHwSetConfigurationReport(
    estimateId: number,
    estimateHwSetIdId: number,
    hwSetProductId: number,
    type: 'pdf'
  ) {
    const url =
      environment.onlineOrderingApiUrl +
      `Estimates/${estimateId}/EstimateHardwareSets/${estimateHwSetIdId}/Products/${hwSetProductId}/ConfigurationReport`
    let headers = new HttpHeaders()
    headers = headers.set('Accept', `application/${type}`)
    return this.authHttp.get(url, { headers: headers, responseType: 'blob', observe: 'response' })
  }

  public addToHardwareSet(id: number, payload: number[]): Observable<HardwareSet | IndirectHardwareSet> {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + id + '/products'
    const body = payload
    return this.authHttp.post(url, body).pipe(
      mergeMap(() => this.getHardwareSet()),
      mergeMap((hwSet) => {
        if (this.routeIds.estimateId) {
          return this.estimateService.getEstimate(this.routeIds.estimateId).pipe(map(() => hwSet))
        } else {
          return of(hwSet)
        }
      })
    )
  }

  public updateProductQuantity(hardwareSetId: number, product: Product): Observable<HardwareSet | IndirectHardwareSet> {
    const productObj = {
      hardwareSetId: hardwareSetId,
      hardwareSetProductId: product.productId,
      quantity: Number(product.quantity)
    }
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + hardwareSetId + '/products/' + product.id
    return this.authHttp
      .put(url, productObj)
      .pipe(tap((hwset: HardwareSet | IndirectHardwareSet) => this._hardwareSet.next(hwset)))
  }

  public goToConfigurator(
    hardwareSetId: number,
    hardwareSetProductId?: number,
    redirectUrl?: string
  ): Observable<ExperlogixSession | string> {
    let url = environment.onlineOrderingApiUrl + 'HardwareSets/' + hardwareSetId
    if (hardwareSetProductId) {
      url += '/Products/' + hardwareSetProductId
    }
    url += '/Configure'
    if (redirectUrl) {
      url += '?redirectUrl=' + redirectUrl
    }

    return this.authHttp.get(url, { responseType: 'text' }).pipe(
      map((res) => {
        const session = <ExperlogixSession | string>res
        if (typeof session === 'string') {
          return session
        }

        if (!session.RedirectUrl) {
          session.RedirectUrl = redirectUrl
        }
        return session
      })
    )
  }

  public getHardwareSetEstimates(hardwareSetId: number) {
    const url = environment.onlineOrderingApiUrl + 'HardwareSets/' + hardwareSetId + '/Estimates'
    return this.authHttp.get<HardwareSetEstimates>(url)
  }

  //  Howllow Metal line item attachements

  addProductLineAttachment(
    hardwareSetId: number,
    hardwareSetProductId: number,
    file: File
  ): Observable<HardwareSetProductAttachment[]> {
    const formData = new FormData()
    formData.append('attachmentRequestJson', JSON.stringify({ hardwareSetId, hardwareSetProductId }))
    formData.append('attachmentFile', file)
    const url =
      environment.onlineOrderingApiUrl +
      `HardwareSets/${hardwareSetId}/HardwareSetProducts/${hardwareSetProductId}/Attachments`
    return this.authHttp.post<HardwareSetProductAttachment[]>(url, formData)
  }

  getProductLineAttachments(
    hardwareSetId: number,
    hardwareSetProductId: number
  ): Observable<HardwareSetProductAttachment[]> {
    const url =
      environment.onlineOrderingApiUrl +
      `HardwareSets/${hardwareSetId}/HardwareSetProducts/${hardwareSetProductId}/Attachments`
    return this.authHttp.get<HardwareSetProductAttachment[]>(url)
  }

  getProductLineAttachment(
    hardwareSetId: number,
    hardwareSetProductId: number,
    hardwareSetProductAttachmentId: number
  ): Observable<string> {
    const url =
      environment.onlineOrderingApiUrl +
      `HardwareSets/${hardwareSetId}/HardwareSetProducts/${hardwareSetProductId}/Attachments/${hardwareSetProductAttachmentId}`
    const headers = new HttpHeaders()
    return this.authHttp.get(url, { headers, responseType: 'arraybuffer' }).pipe(
      map((arrayBuffer) => {
        const file = new Blob([arrayBuffer], { type: 'application/pdf' })
        return URL.createObjectURL(file)
      })
    )
  }

  deleteProductLineAttachment(
    hardwareSetId: number,
    hardwareSetProductId: number,
    hardwareSetProductAttachmentId: number
  ): Observable<HardwareSetProductAttachment[]> {
    const url =
      environment.onlineOrderingApiUrl +
      `HardwareSets/${hardwareSetId}/HardwareSetProducts/${hardwareSetProductId}/Attachments/${hardwareSetProductAttachmentId}`
    return this.authHttp.delete<HardwareSetProductAttachment[]>(url)
  }
}
