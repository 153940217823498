<form form="ngForm">
  <mat-form-field [ngClass]="matFieldClasses">
    <mat-label *ngIf="label; else FieldLabelTemplate">{{ label }}</mat-label>
    <ng-template #FieldLabelTemplate>
      <mat-label *ngIf="labelTemplate">
        <ng-content *ngTemplateOutlet="labelTemplate"></ng-content>
      </mat-label>
    </ng-template>
    <input
      matInput
      #InputElement
      [type]="type"
      class="input-component"
      [ngClass]="{disabled, error}"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [readonly]="readonly"
      matAutocompleteOrigin
      [matAutocomplete]="auto"
      [ngModel]="transform(selectedItemValue)"
      (ngModelChange)="selectedItemValue = $event"
      spellcheck="false"
      [required]="required"
      name="input-field"
    />
    <mat-hint><ng-content select="[hint]"></ng-content></mat-hint>
    <mat-autocomplete #auto="matAutocomplete">
      <ng-container *ngFor="let option of filterdInputValues">
        <mat-option *ngIf="renderTemplate; else elseTemplate" [value]="option" class="custom-option">
          <ng-container *ngTemplateOutlet="template; context: { option: transform(option) }"></ng-container>
        </mat-option>
        <ng-template #elseTemplate>
          <mat-option [value]="transform(option)">
            {{ transform(option) }}
          </mat-option>
        </ng-template>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</form>
