export enum StickSubtype {
  Blank = 'Blank',
  Cased = 'Cased',
  Strike = 'Strike',
  Hinge = 'Hinge',
  StrikeStrike = 'Strike/Strike',
  HingeHinge = 'Hinge/Hinge',
  StrikeHinge = 'Strike/Hinge',
  Sill = 'Sill',
  Head = 'Head',
}

export const StrikeSubTypes = [StickSubtype.Strike, StickSubtype.StrikeHinge, StickSubtype.StrikeStrike];
export const HingeSubTypes = [StickSubtype.Hinge, StickSubtype.StrikeHinge, StickSubtype.HingeHinge];
export const JunctionBoxSubTypes = [StickSubtype.Strike, StickSubtype.Hinge, StickSubtype.Head, StickSubtype.Sill];
