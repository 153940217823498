<div class="content">
  <div class="row">
    <mat-icon svgIcon="filter"></mat-icon>
    <span class="heading">{{'filterBy' | translate}}</span>
  </div>
  <span class="secondary-heading">{{'product' | translate}}</span>
  <mat-radio-group [value]="filterProduct" (change)="filterChange($event.value)">
    <mat-radio-button color="primary" [value]="'Door'">{{"doors" | translate}}</mat-radio-button>
    <mat-radio-button color="primary" [value]="'Frame'">{{'frames' | translate}}</mat-radio-button>
  </mat-radio-group>
</div>
