import { AdditionalLabel } from '../../../core/enums/additional-label';
import { Approval, filterApprovals } from '../../../core/enums/approval';
import { fireRatingApprovals } from '../../rules/fire-rating';
import { gaugeApprovals } from '../../rules/gauge';
import { pairAdditionalLabels as pairApprovals } from '../../rules/pair';
import { seriesApprovals } from '../../rules/series';
import { subTypeApprovals } from '../../rules/sub-type';
import { DoorFireRating } from '../door-fire-rating';
import { DoorGauge } from '../door-gauge';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorApprovals(
  series: DoorSeries,
  subType: DoorSubType,
  gauge: DoorGauge,
  additionalLabel: AdditionalLabel,
  fireRating: DoorFireRating,
  isPair: boolean
): Approval[] {
  const approvals = filterApprovals(additionalLabel);
  seriesApprovals(approvals, series);
  gaugeApprovals(approvals, gauge);
  pairApprovals(approvals, isPair);
  subTypeApprovals(approvals, subType, isPair);
  fireRatingApprovals(approvals, fireRating);
  return approvals;
}
