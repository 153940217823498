export enum DoorFireRating {
  _180 = '3 HR UL Label A',
  _20M = '20 Min UL W/Hose Stream',
  _45 = '3/4 HR UL Label C',
  _60 = '1 HR UL Label',
  _90 = '1 1/2 HR UL Label B',
  WHA = 'WHA 180 Minutes',
  WHB = 'WHB 90 Minutes',
  WHC = 'WHC 45 Minutes',
  WHS = 'WHS 20 Minutes',
}
