export enum DoorPrepSpecialReferencePoint {
  LSCL = 'Lock Side to Centerline',
  HSCL = 'Hinge Side to Centerline',
  TDLSCL = 'Top of Door to Lock Side Centerline',
  TDHSCL = 'Top of Door to Hinge Side Centerline',
  TDLST = 'Top of Door to Lock Side Top',
  TDHST = 'Top of Door to Hinge Side Top',
  TL = 'Located on Top of Door from Lock Side',
  LT = 'Located on Lock Edge of Door From Top',
}
