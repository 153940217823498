import { pipe } from 'lodash/fp';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { addClass, appendChildrenElements, appendToContainerElement, createSVGElement, setCx, setCy, setInnerHTML, setR, setStyles, setX, setY } from '../../core/helpers/svg-functions';
import { ProfileTemplate } from '../abstracts/profile-template';
import { textStyles } from '../constants/text-styles';
import { Stick } from '../models/stick';

const circleStyles = {
  fill: 'white',
  stroke: 'black',
  strokWidth: '1px',
};
export class ProfileMaker {
  private _charHeight = 16;
  private _charWidth = 12;
  private _destroy$ = new Subject<void>();
  private _selected$ = new Subject<ProfileTemplate>();

  get selected$() {
    return this._selected$.asObservable();
  }

  constructor(private readonly container: SVGGElement) {}

  draw(sticks: Stick[]) {
    this.destroy();
    const groups = sticks
      .map(s => s.profiles.map(p => p))
      .selectMany(i => i)
      .groupBy(p => p.id);
    groups.forEach((group, key) => {
      for (const template of group) {
        const element = this.drawProfileName(
          this.container,
          template.profile.points,
          String.fromCharCode(Array.from(groups.keys()).indexOf(key) + 65)
        );
        fromEvent(element, 'click')
          .pipe(takeUntil(this._destroy$))
          .subscribe(() => this._selected$.next(template));
      }
    });
  }

  destroy() {
    this._destroy$.next();
    this.container?.querySelectorAll('.lib-profile-label').forEach(el => el.remove());
  }

  drawProfileName(container: SVGGElement, points: Partial<DOMPoint>[], name: string): SVGGElement {
    const x = points.average(pt => pt.x);
    const y = points.average(pt => pt.y);

    const circle = pipe(
      setCx(x),
      setCy(y),
      setR(25),
      addClass('circle'),
      setStyles(circleStyles),
    )(createSVGElement('circle'));

    const text = pipe(
      setX(x - this._charWidth / 1.5),
      setY(y + this._charHeight / 2.5),
      setInnerHTML(name),addClass('tspan'),
      setStyles(textStyles)
    )(createSVGElement('text'));

    const element = pipe(
      addClass('lib-profile-label'),
      appendChildrenElements([circle, text]),
      appendToContainerElement(container),
    )(createSVGElement('g'));

    return element;
  }
}
