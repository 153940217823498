import { FilteredKeys, enumKeys } from '@oeo/common'
import { Subject } from 'rxjs'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { uomSwitch } from '../../../../core/functions/uomSwitch'
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location'
import { IPrepCode } from '../../../../core/interfaces/i-prep-code'
import { DoorPrepReferencePoint } from '../../../enums/door-prep-reference-point'
import { DoorLockPrepSpecialCode } from '../../../enums/door-special-lock-preps'
import { TDoorPrepTypes } from '../../../interfaces/preps'
import { IDoorLockPrep } from '../../../interfaces/preps/i-door-lock-prep'
import { Door } from '../../../models/door'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'

type LockPrepKeys = FilteredKeys<TDoorPrepTypes, `${string}${'L' | 'l'}ockPrep`>

export abstract class LockPrepComponent extends PrepBaseComponent {

  protected destroy$: Subject<void>

  door: Door
  abstract doorPrepComponent: DoorPrepComponent
  codes: IPrepCode[]
  get code(): IPrepCode {
    return this.prep.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  get specialBypass() {
    return Object.keys(DoorLockPrepSpecialCode).includes(this.code?.code?.trim() ?? '')
  }

  get isSpecialPrep() {
    return super.isSpecialPrep || this.specialBypass
  }

  public get noLock() {
    return this.prep?.code?.trim() == 'OL'
  }

  locations: IPrepCategoryLocation[] = []

  get referencePoints(): DoorPrepReferencePoint[] {
    return enumKeys(DoorPrepReferencePoint)
  }

  get prep(): IDoorLockPrep {
    return this.door[this.doorLockPrepCodeProperty]
  }
  set prep(value: IDoorLockPrep) {
    this.door[this.doorLockPrepCodeProperty] = value
  }

  linkToPrepCodes: boolean = false

  constructor(
    public title: string,
    prepsComponent: PrepsComponent,
    private doorLockPrepCodeProperty: LockPrepKeys,
    prepCategoryId: DoorPrepCategoryIds
  ) {
    super(prepsComponent, prepCategoryId)
    /* Hide certain Lock Preps if the door is fire rated */
    const fireRatedPrepCodes = ['PP', '2-1/8" Bore Deadlock 1-1/8" Front', '2-1/8" Bore Deadlock 1" Front', 'OL']
    this.codes = this.codes.orderBy((c) => c.code)
    if (this.door.doorElevation.fireRating) {
      this.codes = this.codes.filter(({ code }) => !fireRatedPrepCodes.includes(code.trim()))
    }
    if (!this.isDPSeries) {
      this.codes = this.codes.concat(this.specialPrepCode)
    }

    this.locations = prepsComponent.configService.prepCategories
      .first((c) => c.id === prepCategoryId)
      ?.prepCategoryLocations.concat([this.specialPrepLocation])

    this.setDefaultPrepValues()
    this.setSpecial()

    this.hardwareType$.pipe(takeUntil(this.destroy$)).subscribe((hardwareType) => {
      /** Exit device types for which prep location will be defaulted to "Template" */
      const exitDeviceTypes = ['Exit Device, CVC', 'Exit Device, CVR', 'Exit Device, RIM', 'Exit Device, SVR']
      if (exitDeviceTypes.any((exitDevice) => exitDevice?.searchFor(hardwareType))) {
        this.locations = [...this.locations, this.templatePrepLocation]
        this.prep.locationType = this.templatePrepLocation.value
      } else {
        this.locations = this.locations.filter(({ value }) => value !== this.templatePrepLocation.value)
        /* Reset Prep location */
        if (this.prep.locationType === this.templatePrepLocation?.value) {
          this.prep.locationType =
            this.locations.first(
              ({ value }) => value === this.prepsComponent.configService.doorElevation?.prepLocationPreference
            )?.value ?? null
        }
      }
    })
    this.hardwareType$.next(this.prep.hardwareType)
  }

  setDefaultPrepValues(){
    if (!this.prep) {
      this.prep = {} as IDoorLockPrep
    }
    this.prep.backset = this.prep.backset ?? uomSwitch('2 3/4"', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure)
    this.prep.referencePoint = this.prep.referencePoint ?? this.referencePoints.first((c) => c === this.prep.referencePoint) ?? DoorPrepReferencePoint.FFCL
    this.prep.locationType = this.prep.locationType ?? this.locations?.first((c) => c.value === this.prep.locationType)?.value ?? null
  }

  onInit(): void {}

  afterViewInit(): void {
    this.draw$.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(() => this.drawPreps())

    this.prepFieldsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if(this.noLock){
        this.prep.locationType = null
      }
      if (!this.noLock && this.prep.code && !this.prep.locationType) {
        this.prep.locationType =
          this.locations.first(
            ({ value }) => value === this.prepsComponent.configService.doorElevation.prepLocationPreference
          )?.value ?? this.locations.length === 1
            ? this.locations.first()?.value
            : null
      }
      if (this.specialBypass) {
        this.hardwareType = this.hardwareTypes.find((t) =>
          t.searchFor(DoorLockPrepSpecialCode[this.prep.code.trim() as keyof typeof DoorLockPrepSpecialCode])
        )
      }
      /* Set Default Reference Location if prep location reference point is fixed */
      if (this.code?.fixedLocation && this.prep.referencePoint !== DoorPrepReferencePoint.FFCL) {
        this.prep.referencePoint = DoorPrepReferencePoint.FFCL
      }
      this.prep.location = this.code?.fixedLocation
        ? this.code.standardLocation
        : this.prep.location ?? uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure)

      this.draw$.next()
    })
  }

  onDestroy(): void {
    super.destroy()
  }

  override get incompleteQs(): boolean {
    return super.incompleteQs || !this.prepFieldsForm.valid
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear()
    if (this.prep.locationType !== this.specialPrepLocation.value) {
      this.prep.location = Array.isArray(this.prep.location) ? [] : '0"'
      this.doorPrepComponent.resetAndFit()
      return
    }
    this.prepLocationInputs.push(this.drawPrep(0, this.prep))
    this.drawShape(0, this.prep, this.door.actualWidth - uomSwitch(this.prep.backset, 'door', this.unitOfMeasure))
  }
}
