export enum FramePrepReferencePoint {
  FCL = 'Floor to Centerline',
  FB = 'Floor to Bottom',
  HCL = 'Head to Centerline',
  HT = 'Head to Top',
  TCL = 'Top to Centerline',
  TT = 'Top to Top',
  LCL = 'Left to Centerline',
  LL = 'Left to Left',
  RCL = 'Right to Centerline',
  RR = 'Right to Right',
  TL = 'Lock on Top of Door from Lock Side',
  LT = 'Lock on Lock Edge of Door From Top',
}

export const VerticalFramePrepReferencePoints = [
  FramePrepReferencePoint.FCL,
  FramePrepReferencePoint.FB,
  FramePrepReferencePoint.HCL,
  FramePrepReferencePoint.HT,
];

export const HorizontalFramePrepReferencePoints = [
  FramePrepReferencePoint.LCL,
  FramePrepReferencePoint.LL,
  FramePrepReferencePoint.RCL,
  FramePrepReferencePoint.RR,
];
