import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import SvgPanZoom from 'svg-pan-zoom';
import { Door } from '../../models/door';
import { DoorElevation } from '../../models/door-elevation';

@Component({
  selector: 'lib-drawing-pad',
  templateUrl: './drawing-pad.component.html',
  styleUrls: ['./drawing-pad.component.scss'],
})
export class DrawingPadComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private _doorElevation$ = new Subject<void>();

  @ViewChild('svgRef', { static: true }) svgRef: ElementRef<SVGSVGElement>;
  get svg() {
    return this.svgRef.nativeElement;
  }

  @ViewChild('containerRef', { static: true }) containerRef: ElementRef<SVGSVGElement>;
  get container() {
    return this.containerRef.nativeElement;
  }

  @ViewChild('leftDoorRef', { static: true }) leftDoorRef: ElementRef<SVGSVGElement>;
  get leftDoor() {
    return this.leftDoorRef.nativeElement;
  }

  @ViewChild('rightDoorRef', { static: true }) rightDoorRef: ElementRef<SVGSVGElement>;
  get rightDoor() {
    return this.rightDoorRef.nativeElement;
  }

  @ViewChildren('doorRef') doorRefs: QueryList<ElementRef<SVGGElement>>;
  get doorElements(): SVGGElement[] {
    return (this.doorRefs?.toArray() ?? []).map(d => d.nativeElement);
  }

  private _svgPanZoom: SvgPanZoom.Instance;
  grid: boolean = true;
  zoom: number;
  ruler: boolean = true;

  @Input() activeDoor: Door;
  private _doorElevation: DoorElevation;
  @Input() set doorElevation(doorElevation: DoorElevation) {
    this._doorElevation = doorElevation;
    if (!this._doorElevation) {
      return;
    }
    this._doorElevation$.next();
    this._doorElevation.update$.pipe(takeUntil(this._destroy$), takeUntil(this._doorElevation$)).subscribe(() => {
      this.drawDoorElevation();
    });
    this.drawDoorElevation(true);
    this._doorElevation.svg = this.container;
  }

  get doorElevation(): DoorElevation {
    return this._doorElevation;
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._svgPanZoom?.destroy();
    this._doorElevation$.next();
    this._doorElevation$.unsubscribe();
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  doorOffset(index: number): string {
    return `${this.doorElevation?.doors.take(index).sum(d => d.width)}`;
  }

  private drawDoorElevation(resetAndFit?: boolean): void {
    for (const doorElement of this.doorElements) {
      while (doorElement.children.length > 1) {
        doorElement.children[doorElement.children.length - 1].remove();
      }
    }
    requestAnimationFrame(() => {
      this._doorElevation.draw(this.doorElements);
      if (resetAndFit) {
        this._doorElevation.init();
        this._svgPanZoom?.destroy();
        this._svgPanZoom = SvgPanZoom(this.svg, {
          zoomEnabled: true,
          fit: false,
          contain: false,
          center: false,
          panEnabled: true,
          minZoom: 0,
          onZoom: (zoom: number) => {
            this.zoom = zoom;
          },
        });
        this.resetAndFit();
      }
    });
  }

  private resetAndFit() {
    requestAnimationFrame(() => {
      this._svgPanZoom.updateBBox().center().updateBBox().fit().updateBBox().zoomOut();
    });
  }
}
