import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StickSubtype } from '../../../enums/stick-subtype';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepsComponent } from '../preps.component';
import { JunctionBoxPrepComponent } from './junction-box-prep.component';
import { FrameElevation } from '../../../models/frame-elevation';
import { nameOf } from '@oeo/common';
import { HorizontalFramePrepReferencePoints } from '../../../enums/frame-prep-reference-point';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-junction-box-sill-prep',
  templateUrl: './junction-box-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class JunctionBoxSillPrepComponent extends JunctionBoxPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;
  subTypes = [StickSubtype.Sill];
  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(
      'Junction Box Sill',
      prepsComponent,
      'junctionBoxSillPrep',
      FramePrepCategoryIds.JUNCTION_BOX_SILL,
      HorizontalFramePrepReferencePoints
    );
  }

  ngOnInit(): void {
    super.onInit();
  }

  ngAfterViewInit(): void {
    super.afterViewInit();
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }
}
