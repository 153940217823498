import { TemplateType } from '../enums/template-type';
import { BLF04 } from '../templates/blf04';
import { BLF05HM } from '../templates/blf05hm';
import { BLF05VM } from '../templates/blf05vm';
import { BLF06HM } from '../templates/blf06hm';
import { BLF06VM } from '../templates/blf06vm';
import { DDPS07 } from '../templates/ddps07';
import { DDSL07 } from '../templates/ddsl07';
import { DDSL09 } from '../templates/ddsl09';
import { DDST10 } from '../templates/ddst10';
import { DDST12 } from '../templates/ddst12';
import { DPS07 } from '../templates/dps07';
import { DSL07 } from '../templates/dsl07';
import { DSL09 } from '../templates/dsl09';
import { DSPS05 } from '../templates/dsps05';
import { DSPS06 } from '../templates/dsps06';
import { DSSL05 } from '../templates/dssl05';
import { DSSL06 } from '../templates/dssl06';
import { DSST06 } from '../templates/dsst06';
import { DSST07 } from '../templates/dsst07';
import { DSST07FM } from '../templates/dsst07fm';
import { DSST08 } from '../templates/dsst08';
import { DST10 } from '../templates/dst10';
import { DST12 } from '../templates/dst12';
import { DTRF04 } from '../templates/dtrf04';
import { SglOpngs } from '../templates/sglopngs';
import { SglOpngsW2ASA } from '../templates/sglopngsw2asa';
import { SPS05 } from '../templates/sps05';
import { SSL05 } from '../templates/ssl05';
import { SSL06 } from '../templates/ssl06';
import { SST06 } from '../templates/sst06';
import { SST07 } from '../templates/sst07';
import { SST07FM } from '../templates/sst07fm';
import { SST08 } from '../templates/sst08';
import { TRF04 } from '../templates/trf04';

export const TemplateTypes = [
  {
    type: TemplateType.BLF04,
    value: BLF04,
  },
  {
    type: TemplateType.SPS05,
    value: SPS05,
  },
  {
    type: TemplateType.BLF05HM,
    value: BLF05HM,
  },
  {
    type: TemplateType.BLF05VM,
    value: BLF05VM,
  },
  {
    type: TemplateType.BLF06HM,
    value: BLF06HM,
  },
  {
    type: TemplateType.BLF06VM,
    value: BLF06VM,
  },
  // {
  //   type: TemplateType.SINGLE,
  //   value: Single,
  // },
  // {
  //   type: TemplateType.PAIR,
  //   value: Pair,
  // },
  {
    type: TemplateType.DDPS07,
    value: DDPS07,
  },
  {
    type: TemplateType.DDSL07,
    value: DDSL07,
  },
  {
    type: TemplateType.DDSL09,
    value: DDSL09,
  },
  {
    type: TemplateType.DDST10,
    value: DDST10,
  },
  {
    type: TemplateType.DDST12,
    value: DDST12,
  },
  {
    type: TemplateType.DPS07,
    value: DPS07,
  },
  {
    type: TemplateType.DSL07,
    value: DSL07,
  },
  {
    type: TemplateType.DSL09,
    value: DSL09,
  },
  {
    type: TemplateType.DSPS05,
    value: DSPS05,
  },
  {
    type: TemplateType.DSPS06,
    value: DSPS06,
  },
  {
    type: TemplateType.DSSL05,
    value: DSSL05,
  },
  {
    type: TemplateType.DSSL06,
    value: DSSL06,
  },
  {
    type: TemplateType.DSST06,
    value: DSST06,
  },
  {
    type: TemplateType.DSST07,
    value: DSST07,
  },
  {
    type: TemplateType.DSST07FM,
    value: DSST07FM,
  },
  {
    type: TemplateType.DSST08,
    value: DSST08,
  },
  {
    type: TemplateType.DST10,
    value: DST10,
  },
  {
    type: TemplateType.DST12,
    value: DST12,
  },
  {
    type: TemplateType.DTRF04,
    value: DTRF04,
  },
  {
    type: TemplateType.SSL05,
    value: SSL05,
  },
  {
    type: TemplateType.SSL06,
    value: SSL06,
  },
  {
    type: TemplateType.SST06,
    value: SST06,
  },
  {
    type: TemplateType.SST07FM,
    value: SST07FM,
  },
  {
    type: TemplateType.SST07,
    value: SST07,
  },
  {
    type: TemplateType.SST08,
    value: SST08,
  },
  {
    type: TemplateType.TRF04,
    value: TRF04,
  },
  {
    type: TemplateType.SGLOPNGS,
    value: SglOpngs,
  },
  {
    type: TemplateType.SGLOPNGSW2ASA,
    value: SglOpngsW2ASA,
  },
];
