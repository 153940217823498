import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { nameOf } from '@oeo/common';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IPrepCategory } from '../interfaces/i-prep-category';
import { IProductCategory } from '../interfaces/i-product-category';
import { IProductCategoryPrepCategory } from '../interfaces/i-product-category-prep-category';
import { IProductCategoryPrepCategoryService } from '../interfaces/i-product-category-prep-category.service';
import { IProductCategoryService } from '../interfaces/i-product-category.service';

@Injectable()
export class ProductCategoryPrepCategoryService implements IProductCategoryPrepCategoryService {
  constructor(private http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}
  create(productCategoryPrepCategory: IProductCategoryPrepCategory): Observable<void> {
    return this.http.post<void>(
      `${this.environment.elevations.apiUrl}v1/productCategoryPrepCategories`,
      productCategoryPrepCategory
    );
  }
  delete(productCategoryPrepCategory: IProductCategoryPrepCategory): Observable<void> {
    const params = new URLSearchParams();
    params.append(
      nameOf((_: IProductCategoryPrepCategory) => _.productCategoryId),
      `${productCategoryPrepCategory.productCategoryId}`
    );
    params.append(
      nameOf((_: IProductCategoryPrepCategory) => _.prepCategoryId),
      `${productCategoryPrepCategory.prepCategoryId}`
    );
    return this.http.delete<void>(`${this.environment.elevations.apiUrl}v1/productCategoryPrepCategories?${params}`);
  }
}
