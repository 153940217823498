import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { gaugeMaterials } from '../../rules/gauge';
import { DoorGauge } from '../door-gauge';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorMaterials(series: DoorSeries, subType: DoorSubType, gauge: DoorGauge) {
  if (!series) {
    return [];
  }
  const materials = [...(DoorSeriesInfos[series]?.subTypes[subType]?.materials ?? [])];
  gaugeMaterials(materials, gauge);
  return materials;
}
