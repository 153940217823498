import { DoorCore } from '../enums/door-core';
import { DoorGlassInstallation } from '../enums/door-glass-installation';
import { DoorLouverInstallation } from '../enums/door-louver-installation';

export function coreGlassInstallations(glassInstallations: DoorGlassInstallation[], core: DoorCore) {
  if (core === DoorCore.TEMP) {
    glassInstallations.remove(DoorGlassInstallation.CKIR);
    glassInstallations.remove(DoorGlassInstallation.CR);
  }
}

export function coreLouverInstallations(louverInstallations: DoorLouverInstallation[], core: DoorCore) {
  if (core === DoorCore.TEMP) {
    louverInstallations.remove(DoorLouverInstallation.CR);
  }
}
