import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IPrepCategory } from '../../core/interfaces/i-prep-category';
import { DialogService } from '../../core/services/dialog.service';
import { PrepCategoryLocationService } from '../../core/services/door-prep-category-location.service';
import { PrepCategoryService } from '../../core/services/door-prep-category.service';

@Component({
  selector: 'lib-generic-locations',
  templateUrl: './generic-locations.component.html',
  styleUrls: ['./generic-locations.component.scss'],
})
export class GenericLocationsComponent implements AfterViewInit {
  categories: IPrepCategory[];
  category: IPrepCategory;

  categories$ = new Subject<IPrepCategory[]>();
  search$ = new Subject<string>();
  destroy$ = new Subject<void>();
  dataLoading$ = new BehaviorSubject<boolean>(false);
  loading = false;
  constructor(
    protected categoryService: PrepCategoryService,
    protected categoryLocationService: PrepCategoryLocationService,
    protected dialogService: DialogService
  ) {
    this.search$.pipe(takeUntil(this.destroy$), debounceTime(500)).subscribe(value => {
      this.categories$.next(value ? this.categories.filter(c => c.name.searchFor(value)) : this.categories);
    });
  }

  ngAfterViewInit(): void {
    this.dataLoading$.next(true);
    this.categoryService.get().subscribe(categories => {
      this.categories$.next((this.categories = categories));
      this.dataLoading$.next(false);
    });
  }
}
