<div class="single-pair-content">
  <span>{{ 'QUESTIONS.whatWouldYouLikeToDraw' | translate }}</span>
  <div class="row cards">
    <div class="card">
      <img height="75" width="75" src="{{ environment.elevations.blobUrl }}common/Single-Door.svg" />
      <button class="mat-flat-button" mat-flat-button color="primary" (click)="configService.setDoorMode('Single')">
        {{'singleDoor' | translate}}
      </button>
    </div>
    <div class="card">
      <img height="75" width="75" src="{{ environment.elevations.blobUrl }}common/Pair-Door.svg" />
      <button class="mat-flat-button" color="primary" mat-flat-button (click)="configService.setDoorMode('Pair')">
        {{ 'pairOfDoors' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="configService.preferenceExists$ | async" class="row preference">
    <mat-checkbox color="primary" [(ngModel)]="configService.loadFromPreference">{{ 'loadFromPreference' | translate }}</mat-checkbox>
  </div>
</div>
