import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { DoorBaseData } from '../rules/door-base-data';
import { DoorGauge } from './door-gauge';
import { DoorSeries } from './door-series';
import { DoorSubType } from './door-subtype';

export enum DoorStyle {
  Flush = 'F',
  N = 'N',
  LNL = 'LNL',
  V = 'V',
  FG = 'FG',
  FG2 = 'FG2',
  G = 'G',
  FG3 = 'FG3',
  FLB = 'FLB',
  FLT = 'FLT',
  FL2 = 'FL2',
  FLBG = 'FLBG',
  FLBN = 'FLBN',
  FLV = 'FLV',
  FLVC = 'FLVC',
  VC = 'VC',
  DF = 'DF',
  DG = 'DG',
  DV = 'DV',
  DN = 'DN',
  E6 = 'E6',
  EG = 'EG',
  E2G = 'E2G',
  E4TL = 'E4TL',
  HD2 = 'HD2',
  HD2A = 'HD2A',
  E1 = 'E1',
  E4 = 'E4',
  E2 = 'E2',
  Transom = 'Transom',
}

export const StandardStyles = [
  DoorStyle.Flush,
  DoorStyle.Transom,
  DoorStyle.N,
  DoorStyle.LNL,
  DoorStyle.V,
  DoorStyle.FG,
  DoorStyle.FG2,
  DoorStyle.G,
  DoorStyle.FG3,
  DoorStyle.FLB,
  DoorStyle.FLT,
  DoorStyle.FL2,
  DoorStyle.FLBG,
  DoorStyle.FLBN,
  DoorStyle.FLV,
  DoorStyle.FLVC,
  DoorStyle.VC,
];

export const DutchStyles = [DoorStyle.DF, DoorStyle.DG, DoorStyle.DV, DoorStyle.DN];

export const LiteLouverOptions: { [key in DoorStyle]: { lite: boolean; louver: boolean } } = {
  [DoorStyle.Flush]: { lite: false, louver: false },
  [DoorStyle.Transom]: { lite: false, louver: false },
  [DoorStyle.N]: { lite: true, louver: false },
  [DoorStyle.LNL]: { lite: true, louver: false },
  [DoorStyle.V]: { lite: true, louver: false },
  [DoorStyle.FG]: { lite: true, louver: false },
  [DoorStyle.FG2]: { lite: true, louver: false },
  [DoorStyle.G]: { lite: true, louver: false },
  [DoorStyle.FG3]: { lite: true, louver: false },
  [DoorStyle.FLB]: { lite: false, louver: true },
  [DoorStyle.FLT]: { lite: false, louver: true },
  [DoorStyle.FL2]: { lite: false, louver: true },
  [DoorStyle.FLBG]: { lite: true, louver: true },
  [DoorStyle.FLBN]: { lite: true, louver: true },
  [DoorStyle.FLV]: { lite: true, louver: true },
  [DoorStyle.FLVC]: { lite: true, louver: true },
  [DoorStyle.VC]: { lite: true, louver: false },
  [DoorStyle.DF]: { lite: false, louver: false },
  [DoorStyle.DG]: { lite: true, louver: false },
  [DoorStyle.DV]: { lite: true, louver: false },
  [DoorStyle.DN]: { lite: true, louver: false },
  [DoorStyle.E6]: { lite: false, louver: false },
  [DoorStyle.EG]: { lite: true, louver: false },
  [DoorStyle.E2G]: { lite: true, louver: false },
  [DoorStyle.E4TL]: { lite: true, louver: false },
  [DoorStyle.HD2]: { lite: false, louver: false },
  [DoorStyle.HD2A]: { lite: false, louver: false },
  [DoorStyle.E1]: { lite: false, louver: false },
  [DoorStyle.E4]: { lite: false, louver: false },
  [DoorStyle.E2]: { lite: false, louver: false },
};
