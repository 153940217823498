import { Template } from '../abstracts/template';
import { DimensionType } from '../../core/enums/dimension-type';
import { FrameElevation } from '../models/frame-elevation';
import { Stick } from '../models/stick';
import { JointType } from '../enums/joint-type';
import { Orientation } from '../enums/orientation';
import { StickType } from '../enums/stick-type';
import { StickSubtype } from '../enums/stick-subtype';
import { guid } from '@oeo/common';
import { FrameCornerCondition } from '../enums/frame-corner-condition';

export class BLF04 extends Template {
  private _face: number;
  private _d: number;

  dimensions = {
    A: { type: DimensionType.Horizontal, get: () => this._face },
    B: {
      type: DimensionType.Horizontal,
      get: () => this.width - (this.dimensions.A.get() + this.dimensions.C.get()),
    },
    C: { type: DimensionType.Horizontal, get: () => this._face },
    D: {
      type: DimensionType.Vertical,
      get: () => this._d,
      set: (value: number) => {
        if (!this.isValidHeadDimension(value)) {
          return this.update();
        }
        this._d = value;
        this.update();
      },
      hint:
        // this.frameElevation.cornerCondition === FrameCornerCondition.SAW ||
        this.frameElevation.cornerCondition === FrameCornerCondition.DIE
          ? `Head dimension must be either ${this.headDimensions
              .map(d => d.toDimension(this.dimensionType, this.unitOfMeasure))
              .join(' or ')}`
          : null,
    },
    E: {
      type: DimensionType.Vertical,
      get: () => this.height - (this.dimensions.D.get() + this.dimensions.F.get()),
    },
    F: { type: DimensionType.Vertical, get: () => this._face },
  };

  constructor(frameElevation: FrameElevation) {
    super(frameElevation);
    this._face = this.defaultFace;
    this._d = this.defaultFace;
  }

  protected drawObject(container: SVGSVGElement) {
    this.drawLeft(container);
    this.drawTop(container);
    this.drawBottom(container);
    this.drawRight(container);
  }

  private drawLeft(container: SVGSVGElement) {
    const stick = Stick.fromJSON(this.frameElevation, container, {
      shopBreaks: [],
      points: [
        { x: 0, y: this.dimensions.D.get() === this.headFace ? this.headFace / 2 : 0 },
        { x: 0, y: this.height },
        { x: this.dimensions.A.get(), y: this.height },
        { x: this.dimensions.A.get(), y: this.dimensions.D.get() === this.headFace ? this.headFace / 2 : 0 },
      ],
      topJointType: JointType.Square,
      bottomJointType: JointType.Square,
      orientation: Orientation.Vertical,
      exportType: Stick.intersectableName,
      leftJointType: null,
      rightJointType: null,
      type: StickType.OpenSection,
      subType: StickSubtype.Blank,
      flipped: true,
      id: guid(),
    });
    switch (this.frameElevation.cornerCondition) {
      case FrameCornerCondition.DIE:
        // case FrameCornerCondition.SAW:
        stick.topJointType = JointType.Mitered;
        stick.bottomJointType = JointType.Mitered;
        break;
      case FrameCornerCondition.BTJ:
        stick.topJointType = JointType.Notched;
        stick.bottomJointType = JointType.Notched;
        break;
    }
    this.intersectables.push(stick);
  }

  private drawTop(container: SVGSVGElement) {
    const points = [
      { x: 0, y: 0 },
      { x: this.width, y: 0 },
      { x: this.width, y: this.dimensions.D.get() },
      { x: 0, y: this.dimensions.D.get() },
    ];
    const stick = Stick.fromJSON(this.frameElevation, container, {
      shopBreaks: [],
      points,
      topJointType: null,
      bottomJointType: null,
      orientation: Orientation.Horizontal,
      exportType: Stick.intersectableName,
      leftJointType: JointType.Square,
      rightJointType: JointType.Square,
      type: StickType.OpenSection,
      subType: StickSubtype.Blank,
      flipped: true,
      id: guid(),
    });
    switch (this.frameElevation.cornerCondition) {
      case FrameCornerCondition.DIE:
        // case FrameCornerCondition.SAW:
        if (this.dimensions.D.get() === this.headFace) {
          stick.leftJointType = JointType._2InchMiter;
          stick.rightJointType = JointType._2InchMiter;
        } else {
          stick.leftJointType = JointType.Mitered;
          stick.rightJointType = JointType.Mitered;
        }
        break;
      case FrameCornerCondition.BTH:
        stick.leftJointType = JointType.Notched;
        stick.rightJointType = JointType.Notched;
        break;
    }
    this.intersectables.push(stick);
  }

  private drawRight(container: SVGSVGElement) {
    const stick = Stick.fromJSON(this.frameElevation, container, {
      shopBreaks: [],
      points: [
        { x: this.width - this.dimensions.C.get(), y: this._d === this.headFace ? this.headFace / 2 : 0 },
        { x: this.width - this.dimensions.C.get(), y: this.height },
        { x: this.width, y: this.height },
        { x: this.width, y: this._d === this.headFace ? this.headFace / 2 : 0 },
      ],
      topJointType: JointType.Square,
      bottomJointType: JointType.Square,
      orientation: Orientation.Vertical,
      exportType: Stick.intersectableName,
      leftJointType: null,
      rightJointType: null,
      type: StickType.OpenSection,
      subType: StickSubtype.Blank,
      flipped: false,
      id: guid(),
    });
    switch (this.frameElevation.cornerCondition) {
      case FrameCornerCondition.DIE:
        // case FrameCornerCondition.SAW:
        stick.topJointType = JointType.Mitered;
        stick.bottomJointType = JointType.Mitered;
        break;
      case FrameCornerCondition.BTJ:
        stick.topJointType = JointType.Notched;
        stick.bottomJointType = JointType.Notched;
        break;
    }
    this.intersectables.push(stick);
  }

  private drawBottom(container: SVGSVGElement) {
    const stick = Stick.fromJSON(this.frameElevation, container, {
      shopBreaks: [],
      points: [
        { x: 0, y: this.height },
        { x: this.width, y: this.height },
        { x: this.width, y: this.height - this.dimensions.F.get() },
        { x: 0, y: this.height - this.dimensions.F.get() },
      ],
      topJointType: null,
      bottomJointType: null,
      orientation: Orientation.Horizontal,
      exportType: Stick.intersectableName,
      leftJointType: JointType.Square,
      rightJointType: JointType.Square,
      type: StickType.OpenSection,
      subType: StickSubtype.Blank,
      flipped: false,
      id: guid(),
    });
    switch (this.frameElevation.cornerCondition) {
      case FrameCornerCondition.DIE:
        // case FrameCornerCondition.SAW:
        stick.leftJointType = JointType.Mitered;
        stick.rightJointType = JointType.Mitered;
        break;
      case FrameCornerCondition.BTH:
        stick.leftJointType = JointType.Notched;
        stick.rightJointType = JointType.Notched;
        break;
    }
    this.intersectables.push(stick);
  }
}
