import { UnitOfMeasure } from '../enums/unit-of-measure';
import { uomSwitch } from './uomSwitch';

export function addToMeasurement(
  measurement: string,
  valueToAdd: string,
  type: 'door' | 'frame',
  unitOfMeasure: UnitOfMeasure
): string {
  if (!measurement) {
    return uomSwitch('0"', type, unitOfMeasure).toDimension('door', unitOfMeasure);
  }

  const input =
    unitOfMeasure === UnitOfMeasure.Imperial
      ? uomSwitch(measurement, type, unitOfMeasure)
      : measurement.fromDimension(type, unitOfMeasure);
  const addThis = uomSwitch(valueToAdd, type, unitOfMeasure);

  return (input + addThis).toDimension(type, unitOfMeasure);
}
