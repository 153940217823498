export enum DoorFinishPaint {
  OMIT_RAW = 'Omit (raw)',
  PRIMED = 'Primed',
  DOUBLE = 'Double Primed',
  WHITE = 'White',
  STONEWHITE = 'Stone White',
  CREAMBUFF = 'Cream Buff',
  MIDNITEBRONZE = 'Midnite Bronze',
  BLACK = 'Black',
}
