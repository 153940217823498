import { Rabbet } from '../enums/rabbet';
import { StickType } from '../enums/stick-type';
import { ProfileTemplate } from '../abstracts/profile-template';
import { Stick } from '../models/stick';
import { GlassExport } from '../models/exports/glass-export';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { convertImperialMetricEnum } from '../../core/functions/convertJambDepth';

export abstract class Profile {
  private _template: ProfileTemplate;

  get face(): number {
    return this.stick.face;
  }

  get unitOfMeasure(): UnitOfMeasure {
    return this.stick.unitOfMeasure;
  }

  get jambDepth(): number {
    return convertImperialMetricEnum(this.stick.frameElevation.jambDepth, 'frame', this.unitOfMeasure);
  }

  protected abstract doubleRabbetClosedSection(glazingBeads: GlassExport[]): ProfileTemplate;

  protected abstract doubleRabbetOpenSection(glazingBeads: GlassExport[]): ProfileTemplate;

  protected abstract singleRabbetClosedSection(glazingBeads: GlassExport[]): ProfileTemplate;

  protected abstract singleRabbetOpenSection(glazingBeads: GlassExport[]): ProfileTemplate;

  protected abstract casedClosedSection(glazingBeads: GlassExport[]): ProfileTemplate;

  protected abstract casedOpenSection(glazingBeads: GlassExport[]): ProfileTemplate;

  protected abstract doubleEgressSection(): ProfileTemplate;

  constructor(public readonly stick: Stick, public readonly points: Partial<DOMPoint>[]) {}

  template(glazingBeads: GlassExport[]): ProfileTemplate {
    switch (this.stick.type) {
      case StickType.ClosedSection:
      case StickType.PartialMullion:
        switch (this.stick.rabbet) {
          case Rabbet.Double: {
            return (this._template = this.doubleRabbetClosedSection(glazingBeads));
          }
          case Rabbet.Single: {
            return (this._template = this.singleRabbetClosedSection(glazingBeads));
          }
          default: {
            return (this._template = this.casedClosedSection(glazingBeads));
          }
        }
      case StickType.OpenSection: {
        switch (this.stick.rabbet) {
          case Rabbet.Double: {
            return (this._template = this.doubleRabbetOpenSection(glazingBeads));
          }
          case Rabbet.Single: {
            return (this._template = this.singleRabbetOpenSection(glazingBeads));
          }
          case Rabbet.DoubleEgress: {
            return (this._template = this.doubleEgressSection());
          }
          default: {
            return (this._template = this.casedOpenSection(glazingBeads));
          }
        }
      }
    }
  }
}
