import { ComponentType } from '@angular/cdk/portal';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-prep-selection',
  templateUrl: './prep-selection.component.html',
  styleUrls: ['./prep-selection.component.scss'],
})
export class PrepSelectionComponent{
  @Input() steps: {
    name: string;
    checked: boolean;
    component: ComponentType<any>;
  }[];

  @Input() type: string;

  @Output() step$ = new EventEmitter<{
    name: string;
    checked: boolean;
    component: ComponentType<any>;
  }>();
}
