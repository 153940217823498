import { Type } from '@angular/core';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { Profile } from '../abstracts/profile';
import { CSeries } from '../profiles/c-series';
import { CKSeries } from '../profiles/ck-series';
import { DESeries } from '../profiles/de-series';
import { DWSeries } from '../profiles/dw-series';
import { FSeries } from '../profiles/f-series';
import { FESeries } from '../profiles/fe-series';
import { FNSeries } from '../profiles/fn-series';
import { FPSeries } from '../profiles/fp-series';
import { FSSeries } from '../profiles/fs-series';
import { KSeries } from '../profiles/k-series';
import { KSSeries } from '../profiles/ks-series';
import { MESeries } from '../profiles/me-series';
import { MDSeries } from '../profiles/md-series';
import { MSSeries } from '../profiles/ms-series';
import { MUSeries } from '../profiles/mu-series';
import { MNSeries } from '../profiles/mn-series';
import { MHSeries } from '../profiles/mh-series';
import { FrameMaterial } from './frame-material';
import { TemplateType } from './template-type';
import { FTSeries } from '../profiles/ft-series';
import { GlazingBeadSize, RepublicGlazingBeadSizes, SteelcraftGlazingBeadSizes } from './glazing-bead-size';
import { FrameJambDepth } from './frame-jamb-depth';
import { convertImperialMetricEnum } from '../../core/functions/convertJambDepth';
import { FrameDoorThickness } from './frame-door-thickness';
import { FrameGauge } from './frame-gauge';

export enum FrameSeries {
  // F = '(F) FLUSH',
  // FN = '(FN) FLUSH 1" FACE',
  // MU = '(MU) MULTI USE',
  // DW = '(DW) DRYWALL',
  // K = '(K) K FRAME',
  // C = '(C) CASEMENT FRAME FLUSH',
  // CK = '(CK) CASEMENT FRAME DRYWALL',
  // FE = '(FE) DOUBLE EGRESS',
  // DE = '(DE) DOUBLE EGRESS SR STYLE',
  // FP = '(FP) PALADIN (ICC500/FEMA)',
  // FS = '(FS) FLUSH STAINLESS',
  // MS = '(MS) MULTI USE STAINLESS',
  // KS = '(KS) DRYWALL STAINLESS',
  ME = '(ME/SE) Universal',
  // MD = '(ME) DOUBLE EGRESS',
  MH = '(MH/SH) Drywall – Profile',
  // MN = '(MN) CASEMENT FRAME',
  // FT = '(FT) THERMAL',
}

export interface FrameSeriesInfo {
  doubleEgress?: boolean;
  doubleRabbet?: boolean;
  singleRabbet?: boolean;
  editable: boolean;
  fixedFace?: boolean;
  defaultFace: { [unitOfMeasure in UnitOfMeasure]: number };
  allowedTemplates?: TemplateType[];
  adjustableHead: boolean;
  type: Type<Profile>;
  materials: FrameMaterial[];
  gauges: FrameGauge[];
  kerf?: TemplateType[];
  experlogixSeriesId: string;
  experlogixModelId: string;
  glazingBeadSizes: GlazingBeadSize[];
  defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => number;
  defaultBackbend: (unitOfMeasure: UnitOfMeasure, jambDepth?: FrameJambDepth) => number;
  defaultReturn: (unitOfMeasure: UnitOfMeasure) => number;
  defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => number;
  defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => number;
}

export const FrameSeriesInfos: { [frameSeries in FrameSeries]: FrameSeriesInfo } = {
  [FrameSeries.MH]: {
    editable: true,
    adjustableHead: true,
    doubleRabbet: true,
    singleRabbet: true,
    defaultFace: {
      [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
      [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
    },
    type: MHSeries,
    materials: [FrameMaterial.CRS, FrameMaterial.A60, FrameMaterial.G90],
    gauges: [FrameGauge._16, FrameGauge._14],
    experlogixSeriesId: 'REP_Elevation',
    experlogixModelId: 'REP_MH',
    glazingBeadSizes: RepublicGlazingBeadSizes,
    defaultBackbend: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
    },
    defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
    },
    defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '3/8"' : '10mm').fromDimension('frame', unitOfMeasure);
    },
    defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
      return (
        convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
        (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
      );
    },
    defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
    },
  },
  // [FrameSeries.DW]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: DWSeries,
  //   // kerf: [TemplateType.SINGLE, TemplateType.PAIR],
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_DW',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/16"' : '8mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.MU]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: MUSeries,
  //   // kerf: [TemplateType.SINGLE, TemplateType.PAIR],
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_MU',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/16"' : '8mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.MS]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: MSSeries,
  //   materials: [FrameMaterial._304SS, FrameMaterial._316SS],
  //   experlogixSeriesId: 'REP_Elevation',
  //   experlogixModelId: 'REP_MS',
  //   glazingBeadSizes: RepublicGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.F]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: FSeries,
  //   // kerf: [TemplateType.SINGLE, TemplateType.PAIR],
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //     FrameMaterial._12CRS,
  //     FrameMaterial._12A60,
  //     FrameMaterial._12G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_F',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure, jambDepth: FrameJambDepth) => {
  //     switch (jambDepth) {
  //       case FrameJambDepth._534: {
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //       }
  //       default:
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //     }
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.FT]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: FTSeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_FT',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure, jambDepth: FrameJambDepth) => {
  //     switch (jambDepth) {
  //       case FrameJambDepth._534: {
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //       }
  //       default:
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //     }
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  [FrameSeries.ME]: {
    editable: true,
    adjustableHead: true,
    doubleRabbet: true,
    singleRabbet: true,
    defaultFace: {
      [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
      [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
    },
    type: MESeries,
    materials: [FrameMaterial.CRS, FrameMaterial.A60, FrameMaterial.G90],
    gauges: [FrameGauge._16, FrameGauge._14],
    experlogixSeriesId: 'REP_Elevation',
    experlogixModelId: 'REP_ME',
    glazingBeadSizes: RepublicGlazingBeadSizes,
    defaultBackbend: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
    },
    defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
    },
    defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
      throw new Error('Series does not implement returns');
    },
    defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
      return (
        convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
        (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
      );
    },
    defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
      return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
    },
  },
  // [FrameSeries.FP]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: FPSeries,
  //   materials: [FrameMaterial._14CRS, FrameMaterial._14A60, FrameMaterial._14G90],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_FP',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure, jambDepth: FrameJambDepth) => {
  //     switch (jambDepth) {
  //       case FrameJambDepth._534: {
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //       }
  //       default:
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //     }
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.FS]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: FSSeries,
  //   materials: [FrameMaterial._304SS, FrameMaterial._316SS],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_FS',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure, jambDepth: FrameJambDepth) => {
  //     switch (jambDepth) {
  //       case FrameJambDepth._534: {
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //       }
  //       default:
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //     }
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.FN]: {
  //   editable: true,
  //   fixedFace: true,
  //   adjustableHead: false,
  //   doubleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '1"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '25mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: FNSeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //     FrameMaterial._12CRS,
  //     FrameMaterial._12A60,
  //     FrameMaterial._12G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_FN',
  //   glazingBeadSizes: SteelcraftGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure, jambDepth: FrameJambDepth) => {
  //     switch (jambDepth) {
  //       case FrameJambDepth._534: {
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //       }
  //       default:
  //         return (unitOfMeasure === UnitOfMeasure.Imperial ? '1/2"' : '13mm').fromDimension('frame', unitOfMeasure);
  //     }
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.MN]: {
  //   editable: true,
  //   fixedFace: true,
  //   adjustableHead: false,
  //   doubleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '1"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '25mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: MNSeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'REP_Elevation',
  //   experlogixModelId: 'REP_MN',
  //   glazingBeadSizes: RepublicGlazingBeadSizes,
  //   defaultBackbend: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '7/16"' : '11mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultStopHeight: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '16mm').fromDimension('frame', unitOfMeasure);
  //   },
  //   defaultReturn: (unitOfMeasure: UnitOfMeasure) => {
  //     throw new Error('Series does not implement returns');
  //   },
  //   defaultDoorSideRabbet: (unitOfMeasure: UnitOfMeasure, doorThickness: FrameDoorThickness) => {
  //     return (
  //       convertImperialMetricEnum(doorThickness, 'frame', unitOfMeasure) +
  //       (unitOfMeasure === UnitOfMeasure.Imperial ? '3/16"' : '10mm').fromDimension('frame', unitOfMeasure)
  //     );
  //   },
  //   defaultOppositeDoorSideRabbet: (unitOfMeasure: UnitOfMeasure) => {
  //     return (unitOfMeasure === UnitOfMeasure.Imperial ? '1 9/16"' : '40mm').fromDimension('frame', unitOfMeasure);
  //   },
  // },
  // [FrameSeries.K]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: KSeries,
  //   kerf: [TemplateType.SINGLE, TemplateType.PAIR],
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_K',
  // },
  // [FrameSeries.KS]: {
  //   editable: true,
  //   adjustableHead: true,
  //   doubleRabbet: true,
  //   singleRabbet: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: KSSeries,
  //   materials: [FrameMaterial._304SS, FrameMaterial._316SS],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_KS',
  // },

  // [FrameSeries.FE]: {
  //   allowedTemplates: [TemplateType.PAIR],
  //   editable: false,
  //   doubleEgress: true,
  //   adjustableHead: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: FESeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_FE',
  // },
  // [FrameSeries.MD]: {
  //   allowedTemplates: [TemplateType.PAIR],
  //   editable: false,
  //   doubleEgress: true,
  //   adjustableHead: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: MDSeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //     FrameMaterial._12CRS,
  //     FrameMaterial._12A60,
  //     FrameMaterial._12G90,
  //   ],
  //   experlogixSeriesId: 'REP_Elevation',
  //   experlogixModelId: 'REP_MD',
  // },
  // [FrameSeries.DE]: {
  //   allowedTemplates: [TemplateType.PAIR],
  //   editable: false,
  //   doubleEgress: true,
  //   adjustableHead: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: DESeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_DE',
  // },
  // [FrameSeries.C]: {
  //   allowedTemplates: [TemplateType.SINGLE, TemplateType.PAIR],
  //   editable: false,
  //   doubleRabbet: true,
  //   adjustableHead: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: CSeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_C',
  // },
  // [FrameSeries.CK]: {
  //   allowedTemplates: [TemplateType.SINGLE, TemplateType.PAIR],
  //   editable: false,
  //   doubleRabbet: true,
  //   adjustableHead: true,
  //   defaultFace: {
  //     [UnitOfMeasure.Imperial]: '2"'.fromDimension('frame', UnitOfMeasure.Imperial),
  //     [UnitOfMeasure.Metric]: '50mm'.fromDimension('frame', UnitOfMeasure.Metric),
  //   },
  //   type: CKSeries,
  //   materials: [
  //     FrameMaterial._16CRS,
  //     FrameMaterial._16A60,
  //     FrameMaterial._16G90,
  //     FrameMaterial._14CRS,
  //     FrameMaterial._14A60,
  //     FrameMaterial._14G90,
  //   ],
  //   experlogixSeriesId: 'STL_Elevation',
  //   experlogixModelId: 'STL_CK',
  // },
};
