<svg id="svgRef" #svgRef>
  <g>
    <svg
      #containerRef
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:ev="http://www.w3.org/2001/xml-events"
    >
      <svg *ngFor="let door of doorElevation.doors; let i = index" style="overflow: auto" [attr.x]="doorOffset(i)">
        <g #doorRef>
          <rect
            transform="translate(8,8)"
            style="fill: white; stroke: #7f7f7f; stroke-width: 16px"
            class="door"
            [ngClass]="{ active: door === activeDoor }"
            [attr.height]="door.actualHeight - 16"
            [attr.width]="door.actualWidth - 16"
          ></rect>
        </g>
      </svg>
    </svg>
  </g>
</svg>
