import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { Profile } from '../../abstracts/profile';
import { ProfileTemplate, ProfileTemplateDimensionPosition } from '../../abstracts/profile-template';
import { Direction } from '../../enums/direction';
import { GlazingBeadLocation } from '../../enums/glazing-bead-location';
import { GlassExport } from '../../models/exports/glass-export';

export class FSRCS extends ProfileTemplate {
  private _c: number;
  private _d: number;
  private _f: number;
  private _g: number;

  get stopHeight1() {
    return this.dimensions.C.get();
  }
  get stopHeight2() {
    return this.dimensions.G.get();
  }
  get backBend1() {
    return NaN;
  }
  get backBend2() {
    return NaN;
  }
  get doorSideRabbet1() {
    return this._d;
  }
  get oppositeDoorSideRabbet1() {
    return NaN;
  }
  get doorSideRabbet2() {
    return this._f;
  }
  get oppositeDoorSideRabbet2() {
    return NaN;
  }

  readonly dimensions = {
    A: {
      direction: Direction.Right,
      get: () => this.profile.face + this._c + this._g,
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    B: {
      direction: Direction.Down,
      get: () => this.profile.jambDepth - this._d,
      position: () => ProfileTemplateDimensionPosition.MaxX,
    },
    C: {
      direction: Direction.Left,
      get: () => this._c,
      set: (value: number) => {
        this._c = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MaxY,
    },
    D: {
      direction: Direction.Down,
      get: () => this._d,
      position: () => ProfileTemplateDimensionPosition.MaxX,
      set: (value: number) => {
        this._d = value;
        this.update();
      },
      glazingBead:
        !!this.glazingBeads[0] && this.glassPosition === GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Right,
              start: () => ({ x: 0, y: this.glassThickness }),
            }
          : null,
    },
    E: {
      direction: Direction.Left,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MaxY,
      doorSide: true,
    },
    F: {
      direction: Direction.Up,
      get: () => this._f,
      position: () => ProfileTemplateDimensionPosition.MinX,
      set: (value: number) => {
        this._f = value;
        this.update();
      },
      glazingBead:
        !!this.glazingBeads[1] && this.glassPosition === GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Left,
              start: () => ({
                x: 0,
                y: -(this.dimensions.F.get() - this._glazingBeadWidth - this.glassThickness),
              }),
            }
          : null,
    },
    G: {
      direction: Direction.Left,
      get: () => this._g,
      set: (value: number) => {
        this._g = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MaxY,
    },
    H: {
      direction: Direction.Up,
      get: () => this.profile.jambDepth - this._f,
      position: () => ProfileTemplateDimensionPosition.MinX,
    },
  };

  constructor(profile: Profile, glazingBeads: GlassExport[]) {
    super(profile, glazingBeads);
    this._c = this._g = this.defaultStopHeight;
    this._d = this._f = this.defaultDoorSideRabbet;
    this.loadDimensions();
  }
}
