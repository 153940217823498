import { Injectable, OnDestroy } from '@angular/core';
import { IntersectableService } from './intersectable.service';
import { Subject } from 'rxjs';
import { Glass } from '../models/glass';
import { map, tap, takeUntil } from 'rxjs/operators';
import { BaseIntersectableService, IntersectableKeyboardEventModifier } from '../abstracts/base-intersectable-service';
import { DialogService } from '../../core/services/dialog.service';

@Injectable()
export class GlassService extends BaseIntersectableService implements OnDestroy {
  private _activeGlass$ = new Subject<Glass>();
  get activeGlass$() {
    return this._activeGlass$.asObservable();
  }
  private _activeGlass: Glass;
  get activeGlass() {
    return this._activeGlass;
  }

  constructor(private intersectableService: IntersectableService, dialogService: DialogService) {
    super(dialogService);
    this.intersectableService.activeIntersectable$
      .pipe(
        takeUntil(this._destroy$),
        map(i => (i instanceof Glass ? i : null)),
        tap(glass => this._activeGlass$.next(glass))
      )
      .subscribe(glass => (this._activeGlass = glass));

    this.registerHotkey('keydown', 'ArrowUp', IntersectableKeyboardEventModifier.None, this.arrowUp);
    this.registerHotkey('keyup', 'ArrowUp', IntersectableKeyboardEventModifier.ShiftKey, this.arrowUpShift);
    this.registerHotkey('keyup', 'ArrowUp', IntersectableKeyboardEventModifier.CtrlKey, this.arrowUpCtrl);
    this.registerHotkey('keydown', 'ArrowUp', IntersectableKeyboardEventModifier.AltKey, this.arrowUpAlt);

    this.registerHotkey('keydown', 'ArrowRight', IntersectableKeyboardEventModifier.None, this.arrowRight);
    this.registerHotkey('keyup', 'ArrowRight', IntersectableKeyboardEventModifier.ShiftKey, this.arrowRightShift);
    this.registerHotkey('keyup', 'ArrowRight', IntersectableKeyboardEventModifier.CtrlKey, this.arrowRightCtrl);
    this.registerHotkey('keydown', 'ArrowRight', IntersectableKeyboardEventModifier.AltKey, this.arrowRightAlt);

    this.registerHotkey('keydown', 'ArrowLeft', IntersectableKeyboardEventModifier.None, this.arrowLeft);
    this.registerHotkey('keyup', 'ArrowLeft', IntersectableKeyboardEventModifier.ShiftKey, this.arrowLeftShift);
    this.registerHotkey('keyup', 'ArrowLeft', IntersectableKeyboardEventModifier.CtrlKey, this.arrowLeftCtrl);
    this.registerHotkey('keydown', 'ArrowLeft', IntersectableKeyboardEventModifier.AltKey, this.arrowLeftAlt);

    this.registerHotkey('keydown', 'ArrowDown', IntersectableKeyboardEventModifier.None, this.arrowDown);
    this.registerHotkey('keyup', 'ArrowDown', IntersectableKeyboardEventModifier.ShiftKey, this.arrowDownShift);
    this.registerHotkey('keyup', 'ArrowDown', IntersectableKeyboardEventModifier.CtrlKey, this.arrowDownCtrl);
    this.registerHotkey('keydown', 'ArrowDown', IntersectableKeyboardEventModifier.AltKey, this.arrowDownAlt);
  }

  deactivate(){
    this.intersectableService.activate(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  arrowUp = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.moveUp();
  };

  arrowUpShift = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.alignUp();
  };

  arrowUpCtrl = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.stretchUp();
  };

  arrowUpAlt = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.shrinkUp();
  };

  arrowRight = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.moveRight();
  };

  arrowRightShift = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.alignRight();
  };

  arrowRightCtrl = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.stretchRight();
  };

  arrowRightAlt = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.shrinkRight();
  };

  arrowLeft = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.moveLeft();
  };

  arrowLeftShift = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.alignLeft();
  };

  arrowLeftCtrl = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.stretchLeft();
  };

  arrowLeftAlt = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.shrinkLeft();
  };

  arrowDown = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.moveDown();
  };

  arrowDownShift = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.alignDown();
  };

  arrowDownCtrl = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.stretchDown();
  };

  arrowDownAlt = () => {
    if (!this._activeGlass?.isEditable) {
      return;
    }
    this._activeGlass?.shrinkDown();
  };
}
