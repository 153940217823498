import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IPrepCategory } from '../../core/interfaces/i-prep-category';
import { IPrepCategoryLocation } from '../../core/interfaces/i-prep-category-location';
import { DialogService } from '../../core/services/dialog.service';
import { PrepCategoryLocationService } from '../../core/services/door-prep-category-location.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent {
  location: IPrepCategoryLocation;
  @ViewChild('input', { static: true, read: HTMLInputElement }) input: HTMLInputElement;

  private _category: IPrepCategory;
  get category(): IPrepCategory {
    return this._category;
  }
  @Input() set category(value: IPrepCategory) {
    this._category = value;
    this.setNewLocation();
  }

  @Input() loading = false;
  loading$ = new BehaviorSubject<boolean>(false);
  constructor(private dialogService: DialogService, private prepCategoryLocationService: PrepCategoryLocationService) {}

  setNewLocation() {
    this.location = {
      id: undefined,
      value: null,
    };
  }

  addNewLocation() {
    if (!this.location.value) {
      return;
    }
    this.loading$.next(true);
    this.prepCategoryLocationService.create(this.category.id, this.location).subscribe(location => {
      this.category.prepCategoryLocations.push(location);
      this.setNewLocation();
      this.input.focus();
      this.loading$.next(false);
    });
  }

  deleteLocation(location: IPrepCategoryLocation) {
    this.loading$.next(true);
    this.prepCategoryLocationService.delete(this.category.id, location.id).subscribe(() => {
      this.category.prepCategoryLocations.remove(location);
      this.loading$.next(false);
    });
  }
}
