import { Injectable } from "@angular/core"
import { BehaviorSubject, take, tap, catchError, forkJoin } from "rxjs"
import { Cacheable } from "ts-cacheable"
import { IPrepCategory } from "../../core/interfaces/i-prep-category"
import { IPrepCode } from "../../core/interfaces/i-prep-code"
import { IProduct } from "../../core/interfaces/i-product"
import { ProductService } from "../../core/services/door-product.service"
import { FramePrepService } from "../../core/services/frame-prep.service"

@Injectable({
  providedIn: 'root'
})
export class FrameElevationConfigService {
    /*** Prep Categories, Prep Codes and Products ***/
    #prepCategories: BehaviorSubject<IPrepCategory[]> = new BehaviorSubject<IPrepCategory[]>([])
    get prepCategories() {
      return this.#prepCategories.getValue()
    }
    #prepCodes: BehaviorSubject<IPrepCode[]> = new BehaviorSubject<IPrepCode[]>([])
    get prepCodes() {
      return this.#prepCodes.getValue()
    }
    #products: BehaviorSubject<IProduct[]> = new BehaviorSubject<IProduct[]>([])
    get products() {
      return this.#products.getValue()
    }

    public loading = true
    constructor(
      private prepService: FramePrepService,
      public readonly productService: ProductService,
    ) {
      forkJoin([this.getFormattedPreps(), this.getProductSelections()]).subscribe(()=> this.loading = false)
    }

    @Cacheable()
    public getFormattedPreps() {
      return this.prepService.getFormattedPreps().pipe(
        take(1),
        tap(({ prepCategories, prepCodes }) => {
          this.#prepCategories.next(prepCategories)
          this.#prepCodes.next(prepCodes)
        }),
        catchError((e) => {
          return []
        })
      )
    }

    @Cacheable()
    public getProductSelections() {
      return this.productService.getProductSelections().pipe(tap((products) => this.#products.next(products)))
    }
}
