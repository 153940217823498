import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Lite } from '../../models/cutout-types/lite';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class VType extends DoorType {
  cutouts: Cutout[];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts); 
    const width = uomSwitch('10"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = uomSwitch('10"', 'door', this.door.doorElevation.unitOfMeasure);
    this.cutouts = [
      new Lite(
        'Lite',
        this,
        (this.door.actualWidth - width) / 2,
        this.door.actualHeight - (height + uomSwitch(`61"`, 'door', this.door.doorElevation.unitOfMeasure)),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private distanceFromTop(liteHeight: number): number {
    if (this.door.height < uomSwitch(`6' 5"`, 'door', this.door.doorElevation.unitOfMeasure)) {
      return uomSwitch(`7 15/32"`, 'door', this.door.doorElevation.unitOfMeasure);
    }
    return (
      this.door.actualHeight - liteHeight / 2 - uomSwitch(`62 1/4"`, 'door', this.door.doorElevation.unitOfMeasure)
    );
  }

}
