export enum PrepLocation {
  Republic = 'Republic',
  Steelcraft = 'Steelcraft',
  Republic_Old = 'Republic (Old)',
  Amweld = 'Amweld',
  Ceco = 'Ceco',
  Curries = 'Curries',
  Fenestra = 'Fenestra',
  Kewanee = 'Kewanee',
  Mesker = 'Mesker',
  Pioneer = 'Pioneer',
}
