export enum ComparisonType {
  EQ = 'EQUALS',
  NEQ = 'NOT EQUALS',
  LESS = 'LESS THAN',
  LESSEQ = 'LESS THAN OR EQUAL',
  GREAT = 'GREATER THAN',
  GREATEQ = 'GREATER THAN OR EQUAL',
  ANY = 'ANY',
  ALL = 'ALL',
  SUM = 'SUM',
  MAX = 'MAX',
  MIN = 'MIN',
  EXISTS = 'EXISTS',
  NEXISTS = 'NOT EXISTS',
}

export function ComparisonTypes(type: 'string' | 'number' | 'array') {
  switch (type) {
    case 'string':
      return [ComparisonType.EQ, ComparisonType.NEQ, ComparisonType.EXISTS, ComparisonType.NEXISTS];
    case 'number':
      return [
        ComparisonType.EQ,
        ComparisonType.NEQ,
        ComparisonType.LESS,
        ComparisonType.LESSEQ,
        ComparisonType.GREAT,
        ComparisonType.GREATEQ,
      ];
    case 'array':
      return [ComparisonType.ANY, ComparisonType.ALL, ComparisonType.SUM, ComparisonType.MAX, ComparisonType.MIN];
  }
}
