import { CoreConstants } from '../../core/core.constants';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { WritableKeys, nameOf } from '@oeo/common';
import { uomSwitch } from '../../core/functions/uomSwitch';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { ICustomRuleType } from '../../core/interfaces/i-custom-rule-type';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../enums/cutout-dimension-mode';
import { CutoutOrderSize } from '../enums/cutout-order-size';
import { DoorGlassInstallation } from '../enums/door-glass-installation';
import { DoorLiteKit, DoorLiteOrderSizes } from '../enums/door-lite-kit';
import { DoorLouverInstallation } from '../enums/door-louver-installation';
import { DoorLouverKit, DoorLouverOrderSizes } from '../enums/door-louver-kit';
import { Door } from '../models/door';
import { CutoutExport } from '../models/exports/cutout-export';
import { DoorGlassType } from './../enums/door-glass-type';
import { DoorType } from './door-type';
import { Updatable } from './updatable';
import { appendToContainerElement, createSVGElement, setStyles, setX1, setX2, setY1, setY2 } from '../../core/helpers/svg-functions';
import { pipe } from 'lodash/fp';
import { MaxMinDistance } from '../../core/models/max-min-distance';
import { ErrorStateMatcher } from '@angular/material/core';

type CutoutValidationErrors = Record<string, {message: `WARNINGS.${string}`, dimension: string}>

export const lineStyles = {
  stroke: 'black',
  strokeWidth: '4px',
};
export const distanceStyles = {
  strokeDasharray: '16px',
  stroke: 'black',
  strokeWidth: '4px',
};

const textStyles = {
  stroke: 'black',
  fontSize: '60px',
  fontFamily: 'Work Sans',
};

const muntinStyles = {
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '8px',
};

const characterHeight = 52;
const characterWidth = 36;
const distanceFromEdge = 40;

export abstract class Cutout extends Updatable {
  get height(): number {
    return this._height;
  }
  set height(value: number) {
    this._height = value;
    this._update$.next();
  }
  get width(): number {
    return this._width;
  }
  set width(value: number) {
    this._width = value;
    this._update$.next();
  }

  get area(): number {
    return (
      (this.width * this.height) /
      (this.doorType.door.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
        ? CoreConstants.multipliers.doorElevation
        : 1)
    );
  }
  get verticalDimensionMode(): VerticalCutoutDimensionMode {
    return this._verticalDimensionMode;
  }
  set verticalDimensionMode(value: VerticalCutoutDimensionMode) {
    this._verticalDimensionMode = value;
    this._update$.next();
  }

  get distanceFromTop(): number {
    return this.y;
  }

  get distanceFromBottom(): number {
    return this.doorType.door.actualHeight - this.y - this.height;
  }

  get verticalDimension(): number {
    return this._verticalDimensionMode === VerticalCutoutDimensionMode.Top
      ? this.distanceFromTop
      : this.distanceFromBottom;
  }
  set verticalDimension(value: number) {
    this.setVerticalDimension(value);
    this._update$.next();
  }
  get horizontalDimensionMode(): HorizontalCutoutDimensionMode {
    return this._horizontalDimensionMode;
  }
  set horizontalDimensionMode(value: HorizontalCutoutDimensionMode) {
    this._horizontalDimensionMode = value;
    this._update$.next();
  }

  get distanceFromLeft(): number {
    return this.x;
  }

  get distanceFromRight(): number {
    return this.doorType.door.actualWidth - this.x - this.width;
  }

  get horizontalDimension(): number {
    return this._horizontalDimensionMode === HorizontalCutoutDimensionMode.Hinge
      ? this.distanceFromLeft
      : this.distanceFromRight;
  }
  set horizontalDimension(value: number) {
    this.setHorizontalDimension(value);
    this._update$.next();
  }
  get element(): SVGElement {
    return this._element;
  }
  get sizeEditable(): boolean {
    return this._sizeEditable;
  }

  get positionEditable(): boolean {
    return this._positionEditable;
  }

  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    this._active = value;
    if (this._active) {
      this.element.activateCss();
    } else {
      this.element.deactivateCss();
    }
  }
  get glassThickness(): number {
    return this.doorType.door.glassThickness;
  }
  get glassInstallation(): DoorGlassInstallation {
    return this.doorType.door.glassInstallation;
  }
  get louverInstallation(): DoorLouverInstallation {
    return this.doorType.door.louverInstallation;
  }
  get glassType(): DoorGlassType {
    return this.doorType.door.glassType
  }
  public get vertMuntinBarQty(): number {
    return this._vertMuntinBarQty;
  }
  public set vertMuntinBarQty(value: number) {
    this._vertMuntinBarQty = value;
    this._update$.next();
  }
  public get horzMuntinBarQty(): number {
    return this._horzMuntinBarQty;
  }
  public set horzMuntinBarQty(value: number) {
    this._horzMuntinBarQty = value;
    this._update$.next();
  }
  set muntinBar(value: { vertQty: number; horzQty: number }) {
    this._vertMuntinBarQty = value?.vertQty ?? 0;
    this._horzMuntinBarQty = value?.horzQty ?? 0;
    this._update$.next();
  }
  protected get doorWidth(): string {
    return this._doorWidth;
  }
  protected set doorWidth(value: string) {
    this._doorWidth = value;
  }
  protected get doorHeight(): string {
    return this._doorHeight;
  }
  protected set doorHeight(value: string) {
    this._doorHeight = value;
  }

  get kit(): DoorLiteKit | DoorLouverKit{
    return this._kit
  }
  set kit(kit: DoorLiteKit | DoorLouverKit){
    this._kit = kit
  }

  private _orderSizes: { [key: string]: CutoutOrderSize } = Object.assign(
    Object.assign({}, DoorLiteOrderSizes),
    DoorLouverOrderSizes
  );

  get orderSize(): CutoutOrderSize {
    return !this.kit ? CutoutOrderSize.C : this._orderSizes[this.kit] ?? CutoutOrderSize.C;
  }

  public maxMinDistance: MaxMinDistance

  constructor(
    public readonly name: string,
    public readonly doorType: DoorType,
    x: number,
    y: number,
    width: number,
    height: number,
    verticalDimensionMode: VerticalCutoutDimensionMode,
    horizontalDimensionMode: HorizontalCutoutDimensionMode,
    sizeEditable: boolean,
    positionEditable: boolean
  ) {
    super();
    this.x = x;
    this.y = y;
    this._width = width;
    this._height = height;
    this._horizontalDimensionMode = horizontalDimensionMode;
    this._verticalDimensionMode = verticalDimensionMode;
    this._sizeEditable = sizeEditable;
    this._positionEditable = positionEditable;
  }

  /** Assign default max distance from the top & bottom */
  setMinMaxDistance(maxMinDistance: MaxMinDistance) {
    this.maxMinDistance = maxMinDistance;
    return this;
  }

  get validation(): { errorStateMatcher: ErrorStateMatcher; errors: CutoutValidationErrors } {
    const errors: CutoutValidationErrors = {}
    /**
     * If maxMinDistance is not set, then we don't need to validate the cutout dimensions
     */
    if (!this.maxMinDistance){
      return {
        errorStateMatcher:{ isErrorState: ()=> false },
        errors
      }
    }

    const maxVerticalDimension = this.verticalDimensionMode === VerticalCutoutDimensionMode.Top
      ? this.maxMinDistance.maxDistance.fromTop
      : this.maxMinDistance.maxDistance.fromBottom
    const minVerticalDimension = this.verticalDimensionMode === VerticalCutoutDimensionMode.Top
      ? this.maxMinDistance.minDistance.fromTop
      : this.maxMinDistance.minDistance.fromBottom

    if (this.verticalDimension > maxVerticalDimension) {
      errors['verticalDimension'] = { message: 'WARNINGS.mustBeLessThan', dimension: maxVerticalDimension.toDimension('door', UnitOfMeasure.Imperial)}
    }
    if (this.verticalDimension < minVerticalDimension) {
      errors['verticalDimension'] = { message: 'WARNINGS.mustBeGreaterThan', dimension: minVerticalDimension.toDimension('door', UnitOfMeasure.Imperial)}
    }
    return {
      errorStateMatcher:{ isErrorState: ()=> Object.keys(errors).length > 0 },
      errors: Object.keys(errors).length > 0 ? errors : null
    }
  }

  abstract readonly type: string;
  abstract sizes: { width: string; height: string }[];
  size: { width: string; height: string };

  private _height: number;

  private _width: number;

  x: number;
  y: number;

  private _verticalDimensionMode: VerticalCutoutDimensionMode;

  private _horizontalDimensionMode: HorizontalCutoutDimensionMode;

  private _element: SVGElement;

  private _sizeEditable: boolean;
  private _positionEditable: boolean;

  private _active: boolean;
  private _vertMuntinBarQty: number = 0;
  private _horzMuntinBarQty: number = 0;

  private _doorWidth: string;

  private _doorHeight: string;

  private _kit: DoorLiteKit | DoorLouverKit
  static fromXML(value: string, unitOfMeasure: UnitOfMeasure): CutoutExport {
    const doc = parseFromXMLString(value);
    return {
      verticalDimension:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: Cutout) => _.verticalDimension)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      horizontalDimension:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: Cutout) => _.horizontalDimension)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      height:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: Cutout) => _.height)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      width:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: Cutout) => _.width)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      verticalDimensionMode: this.parseXML(
        doc,
        nameOf((_: Cutout) => _.verticalDimensionMode)
      ) as VerticalCutoutDimensionMode,
      horizontalDimensionMode: this.parseXML(
        doc,
        nameOf((_: Cutout) => _.horizontalDimensionMode)
      ) as HorizontalCutoutDimensionMode,
      vertMuntinBarQty: parseFloat(
        this.parseXML(
          doc,
          nameOf((_: Cutout) => _.vertMuntinBarQty)
        )
      ),
      horzMuntinBarQty: parseFloat(
        this.parseXML(
          doc,
          nameOf((_: Cutout) => _.horzMuntinBarQty)
        )
      ),
      kit: this.parseXML(
        doc,
        nameOf((_: Cutout) => _.kit)
      ) as DoorLiteKit | DoorLouverKit,
    };
  }

  private static parseXML(doc: Document, property: string): string {
    const value = doc.evaluate(
      `//property[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${property.toLowerCase()}']`,
      doc,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    )?.singleNodeValue?.textContent;
    if (!value || value === 'undefined') {
      return null;
    }
    return value;
  }

  center(): void {
    this.horizontalDimension = (this.doorType.door.actualWidth - this.width) / 2;
  }

  setSize(size: { width: string; height: string }, centerHorizontally?: boolean) {
    const vertDim = this.verticalDimension;
    this._height = uomSwitch(size.height, 'door', this.doorType.door.doorElevation.unitOfMeasure);
    this.setVerticalDimension(vertDim);

    const horzDim = this.horizontalDimension;
    this._width = uomSwitch(size.width, 'door', this.doorType.door.doorElevation.unitOfMeasure);
    this.setHorizontalDimension(horzDim);

    if (centerHorizontally) {
      this.setHorizontalDimension((this.doorType.door.actualWidth - this.width) / 2);
    }
    this.size = size;
    this._update$.next();
  }

  toXML(): string {
    return`<option id="Cutout">
        ${this.createProperty(
          nameOf((_: Door) => _.id),
          `${this.doorType.door.id}`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.type),
          `${this.type}`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.horizontalDimension),
          `${
            this.doorType.door.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.horizontalDimension / CoreConstants.multipliers.doorElevation
              : this.horizontalDimension
          }`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.verticalDimension),
          `${
            this.doorType.door.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.verticalDimension / CoreConstants.multipliers.doorElevation
              : this.verticalDimension
          }`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.height),
          `${
            this.doorType.door.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.height / CoreConstants.multipliers.doorElevation
              : this.height
          }`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.width),
          `${
            this.doorType.door.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.width / CoreConstants.multipliers.doorElevation
              : this.width
          }`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.horizontalDimensionMode),
          `${this.horizontalDimensionMode}`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.verticalDimensionMode),
          `${this.verticalDimensionMode}`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.glassInstallation),
          this.glassInstallation
        )}
        ${this.createProperty(
          'thickness',
          `${
            this.doorType.door.doorElevation.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.glassThickness / CoreConstants.multipliers.doorElevation
              : this.glassThickness
          }`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.glassType),
          this.glassType
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.louverInstallation),
          this.louverInstallation
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.vertMuntinBarQty),
          `${this.vertMuntinBarQty}`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.horzMuntinBarQty),
          `${this.horzMuntinBarQty}`
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.kit),
          this.kit
        )}
        ${this.createProperty(
          nameOf((_: Cutout) => _.orderSize),
          this.orderSize
        )}
      </option>`;
  }

  draw(container: SVGGElement) {
    const element = this.drawCutout(container);
    this._element = element;
    if (this.active) {
      this._element.activateCss();
    }
    this.drawMuntinBars(container);
  }

  abstract drawCutout(container: SVGGElement): SVGElement;

  private setVerticalDimension(value: number) {
    this.y =
      this._verticalDimensionMode === VerticalCutoutDimensionMode.Top
        ? value
        : this.doorType.door.actualHeight - this.height - value;
  }

  private setHorizontalDimension(value: number) {
    this.x =
      this._horizontalDimensionMode === HorizontalCutoutDimensionMode.Hinge
        ? value
        : this.doorType.door.actualWidth - this.width - value;
  }

  private drawMuntinBars(container: SVGGElement) {
    for (let i = 0; i < this.vertMuntinBarQty; i++) {
      const x = (this.width / (this.vertMuntinBarQty + 1)) * (i + 1);
      this.drawMuntinBar(container, this.x + x, this.y + 8, this.x + x, this.y + this.height - 24);
    }
    for (let i = 0; i < this.horzMuntinBarQty; i++) {
      const y = (this.height / (this.horzMuntinBarQty + 1)) * (i + 1);
      this.drawMuntinBar(container, this.x + 8, this.y + y, this.x + this.width - 24, this.y + y);
    }
  }

  private drawMuntinBar(container: SVGGElement, x1: number, y1: number, x2: number, y2: number) {
    const line = pipe(
      setX1(x1),
      setY1(y1),
      setX2(x2),
      setY2(y2),
      setStyles(muntinStyles),
      appendToContainerElement(container)
    )(createSVGElement('line'));
  }

  private createProperty(id: string, value: string): string {
    return `<property id="${id}">${value?.toXmlString() ?? ''}</property>`;
  }

  public mergeWith(source: CutoutExport) {
    if (source == null) {
      return;
    }
    /* FIXME: For Some reason, this (using Object.assign & looping through and assigning keys) works.
    Have to investigate further*/
    Object.assign(this, source);
    for (const key in source) {
      this[key as WritableKeys<Cutout>] = source[key as keyof CutoutExport] as unknown as never;
    }
  }
}

export const liteLouverRuleType: ICustomRuleType = {
  name: 'Lite/Louver',
  prefix: `doorType.cutouts.filter(c => c.type === 'Lite' || c.type === 'Louver' || c.type === 'Circular Lite')`,
  properties: [
    { property: nameOf((_: Cutout) => _.width), name: 'Width', type: 'number' },
    { property: nameOf((_: Cutout) => _.height), name: 'Height', type: 'number' },
    { property: nameOf((_: Cutout) => _.area), name: 'Area', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromTop), name: 'Top Rail', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromBottom), name: 'Bottom Rail', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromLeft), name: 'Left Stile', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromRight), name: 'Right Stile', type: 'number' },
  ],
};

export const liteLouverEmbossmentRuleType: ICustomRuleType = {
  name: 'Lite/Louver/Embossment',
  prefix: `doorType.cutouts`,
  properties: [
    { property: nameOf((_: Cutout) => _.distanceFromTop), name: 'Top Rail', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromBottom), name: 'Bottom Rail', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromLeft), name: 'Left Stile', type: 'number' },
    { property: nameOf((_: Cutout) => _.distanceFromRight), name: 'Right Stile', type: 'number' },
  ],
};
