import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Cacheable } from 'ts-cacheable';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IProduct } from '../interfaces/i-product';

@Injectable()
export class ProductService {
  private _products: IProduct[];

  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  getProductSelections(): Observable<IProduct[]> {
    return this._products
      ? of(this._products)
      : this.httpClient
          .get<IProduct[]>(`${this.environment.elevations.apiUrl}v1/products`)
          .pipe(tap(products => (this._products = products)));
  }

  getProducts(manufacturer: string, productId: string, hardwareType: string) {
    const parms = new URLSearchParams();
    parms.append('manufacturer', manufacturer);
    parms.append('categoryDisplayName', hardwareType);
    parms.append('productId', encodeURIComponent(productId));
    return this.httpClient.get<IProduct[]>(
      `${this.environment.elevations.apiUrl}v1/products/productSelections?${parms}`
    );
  }
}
