import { ValueOf } from '../types'

export function nameOf<T, V extends T[keyof T]>(f: (x: T) => V): ValueOf<{ [K in keyof T]: T[K] extends V ? K : never }>
export function nameOf(f: (x: any) => any): keyof any {
  const p = new Proxy(
    {},
    {
      get: (_, key) => key
    }
  )
  return f(p)
}
