import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICustomRuleGroup } from '../interfaces/i-custom-rule-group';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IQueryParams } from '../interfaces/i-query-params';
import { RestService } from './rest.service';

@Injectable()
export class DoorRuleService extends RestService<ICustomRuleGroup> {
  constructor(http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) environment: IEnvironment) {
    super(http, `${environment.elevations.apiUrl}v1/doorRules`);
  }

  search(query: IQueryParams): Observable<ICustomRuleGroup[]> {
    return super.search(query).pipe(
      map(ruleGroups =>
        ruleGroups.map(rg => {
          rg.rules = JSON.parse(rg.rules as unknown as string);
          return rg;
        })
      )
    );
  }
}
