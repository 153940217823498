import { AbstractControl, ValidatorFn } from '@angular/forms';
import { UnitOfMeasure } from '../enums/unit-of-measure';

export function minMaxValidator(
  min: string,
  max: string,
  type: 'door' | 'frame',
  unitOfMeasure: UnitOfMeasure
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = (control.value as string)?.fromDimension(type, unitOfMeasure) ?? 0;
    const minValue = min.fromDimension(type, unitOfMeasure);
    const maxValue = max.fromDimension(type, unitOfMeasure);
    return value > maxValue || value < minValue
      ? {
          minMax: {
            value: `${minValue.toDimension(type, unitOfMeasure)} or greater than ${maxValue.toDimension(
              type,
              unitOfMeasure
            )}`,
          },
        }
      : null;
  };
}
