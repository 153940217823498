import { Lite } from '../../models/cutout-types/lite';
import { DoorType } from '../../abstracts/door-type';
import { DoorElevation } from '../../models/door-elevation';
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { Cutout } from '../../abstracts/cutout';
import { Door } from '../../models/door';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { CutoutExport } from '../../models/exports/cutout-export';

export class LNLType extends DoorType {
  cutouts: Cutout[];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const width = uomSwitch('6"', 'door', this.door.doorElevation.unitOfMeasure);
    const height = uomSwitch('33"', 'door', this.door.doorElevation.unitOfMeasure);

    this.cutouts = [
      new Lite(
        'Lite',
        this,
        this.shouldFlipCutout() ? DefaultStile(this.door.doorElevation.unitOfMeasure) :
          this.door.actualWidth - width - DefaultStile(this.door.doorElevation.unitOfMeasure),
        DefaultTopRail(this.door.doorElevation.unitOfMeasure),
        width,
        height,
        VerticalCutoutDimensionMode.Top,
        HorizontalCutoutDimensionMode.Lock
      ),
    ];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private getHeight(): number {
    const heights = new Array(100)
      .fill(
        {
          key: uomSwitch(`6' 8"`, 'door', this.door.doorElevation.unitOfMeasure),
          value: uomSwitch('59 21/32"', 'door', this.door.doorElevation.unitOfMeasure),
        },
        0,
        100
      )
      .map((obj, index) => ({
        key: obj.key + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
        value: obj.value + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
      }));
    for (let i = 1; i < heights.length; i++) {
      if (this.door.height < heights[i].key) {
        return heights[i - 1].value;
      }
    }
    return heights.last().value;
  }
}
