import { BannerNotificationTypes, HelperService } from '../../services/helper/helper.service'
import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'oa-notification-banner',
  templateUrl: './notification-banner.component.html',
  styleUrls: ['./notification-banner.component.scss']
})
export class NotificationBannerComponent implements OnInit {
  @Input() notificationType: BannerNotificationTypes

  constructor(public helperService: HelperService) {}

  ngOnInit(): void {}

  hideNotification() {
    const element = document.getElementById(this.notificationType)
    element.className += ' closed'

    element.onanimationend = (event) => {
      this.helperService.showHideNotification(this.notificationType, true, false)
    }
  }
}
