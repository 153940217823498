import { Component, Input } from '@angular/core'
import { TableColumn } from '../../../../types'

export type TCurrencyComponentData = {
  isServiceCenter?: boolean
  isFrameElevation?: boolean
  product?: {
    isFrameElevation: boolean
  }
}

@Component({
  selector: 'oa-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent<C = Component> {
  @Input() data!: string | number | boolean
  @Input() row!: TCurrencyComponentData
  @Input() column!: TableColumn<C>
}
