import { Approval } from '../../core/enums/approval';
import { DoorSubType } from '../enums/door-subtype';

export function pairAdditionalLabels(additionalLabels: Approval[], isPair: boolean) {
  if (isPair) {
    additionalLabels.remove(Approval.L4BRD);
    additionalLabels.remove(Approval.L5BRD);
    additionalLabels.remove(Approval.L6BRD);
    additionalLabels.remove(Approval.L7BRD);
    additionalLabels.remove(Approval.L8BRD);
    additionalLabels.remove(Approval.FL_12400_4);
    additionalLabels.remove(Approval.FL_12400_6);
    additionalLabels.remove(Approval.FL_20736_2);
  }
  else{
    additionalLabels.remove(Approval.FL_12400_1);
    additionalLabels.remove(Approval.FL_12400_2);
    additionalLabels.remove(Approval.FL_12400_3);
    additionalLabels.remove(Approval.FL_20736_1);
    additionalLabels.remove(Approval.FL_14022_1);
  }
}

export function pairSubTypes(subTypes: DoorSubType[], isPair: boolean) {
  if (isPair) {
    subTypes.remove(DoorSubType.STC);
  }
}
