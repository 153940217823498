<nav aria-label="Breadcrumb">
  <ol>
    <!-- displayed in reverse order -->
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = first">
      <li *ngIf="breadcrumb.label !== 'ignore'">
        <a *ngIf="!last" [routerLink]="[breadcrumb.url]">
          <ng-container *ngIf="breadcrumb.label">
            <span>{{ breadcrumb.label }}</span>
          </ng-container>
        </a>
        <ng-container *ngIf="last">
          <span>{{ breadcrumb.label }}</span>
        </ng-container>
      </li>
    </ng-container>
  </ol>
</nav>
