import { createSVGElement } from "../helpers/svg-functions";

export {};

declare global {
  interface SVGSVGElement {
    transformPoint(this: SVGSVGElement, x: number, y: number): SVGPoint;
    getPoint(this: SVGSVGElement, x: number, y: number): SVGPoint;
    prepareForExport(width: number, height: number): string;
    resetFromExport(): void;
  }
}

function prepareForExport(this: SVGSVGElement, width: number, height: number): string {
  const clone = this.cloneNode(true) as SVGSVGElement;
  const viewBox = this.getBBox();
  if (viewBox.width === 0 || viewBox.height === 0) {
    Object.assign(viewBox, calculateViewBox(this, width, height));
  }
  clone.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`);
  clone.setAttribute('width', `${viewBox.width}`);
  clone.setAttribute('height', `${viewBox.height}`);
  clone.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  clone.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
  clone.setAttribute('xmlns:ev', 'http://www.w3.org/2001/xml-events');
  const rect = createSVGElement('rect');
  rect.style.fill = 'transparent';
  rect.style.stroke = 'transparent';
  rect.setAttribute('x', `${viewBox.x}`);
  rect.setAttribute('width', `${viewBox.width}`);
  rect.setAttribute('y', `${viewBox.y}`);
  rect.setAttribute('height', `${viewBox.height}`);
  clone.appendChild(rect);
  const html = clone.outerHTML;
  clone.remove();
  return html;
}

function resetFromExport(this: SVGSVGElement): void {
  this.removeAttribute('viewBox');
  this.removeAttribute('xmlns');
  this.removeAttribute('xmlns:xlink');
  this.removeAttribute('xmlns:ev');
}

function transformPoint(this: SVGSVGElement, x: number, y: number): SVGPoint {
  const point = this.getPoint(x, y).matrixTransform(this.getScreenCTM().inverse());
  point.x = Math.round(point.x);
  point.y = Math.round(point.y);
  return point;
}

function getPoint(this: SVGSVGElement, x: number, y: number) {
  const point = this.createSVGPoint();
  point.x = x;
  point.y = y;
  return point;
}

function calculateViewBox(
  element: SVGSVGElement,
  textWidth: number,
  textHeight: number
): { x: number; width: number; y: number; height: number } {
  const elements = getElements(element);
  const x = elements.min(e => {
    try {
      return e.minX();
    } catch {
      return Number.MAX_VALUE;
    }
  });
  const y = elements.min(e => {
    try {
      return e.minY(textHeight);
    } catch {
      return Number.MAX_VALUE;
    }
  });
  return {
    x,
    y,
    width:
      elements.max(e => {
        try {
          return e.maxX(textWidth);
        } catch {
          return Number.MIN_VALUE;
        }
      }) - x,
    height:
      elements.max(e => {
        try {
          return e.maxY();
        } catch {
          return Number.MIN_VALUE;
        }
      }) - y,
  };
}

function getElements(element: SVGElement): SVGElement[] {
  const arr = Array.from(element.children) as SVGElement[];
  for (const child of arr) {
    arr.push(...getElements(child));
  }
  return arr;
}

SVGSVGElement.prototype.transformPoint = transformPoint;
SVGSVGElement.prototype.getPoint = getPoint;
SVGSVGElement.prototype.prepareForExport = prepareForExport;
SVGSVGElement.prototype.resetFromExport = resetFromExport;
