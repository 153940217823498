import { nameOf } from '@oeo/common';
import { IPrep, Prep } from '../../../core/interfaces/i-prep';
import { DoorHingeBackset } from '../../enums/door-hinge-backset';

export interface IDoorHingePrep extends IPrep {
  quantity: number;
  backset: string;
  highFrequency: string;
  isElectricHinge?: string
  electricHingeLocation?: string
  locationWithinHinge?: string
}

export class DoorHingePrep extends Prep implements IDoorHingePrep {
  quantity: number;
  backset: DoorHingeBackset;
  highFrequency: string;
  isElectricHinge?: string
  electricHingeLocation?: string
  locationWithinHinge?: string

  properties(): { [key: string]: (value: string) => any } {
    const obj = super.properties();
    obj[nameOf((_: IDoorHingePrep) => _.quantity)] = (value: string) => parseInt(value, 10);
    obj[nameOf((_: IDoorHingePrep) => _.backset)] = (value: string) => value as DoorHingeBackset;
    obj[nameOf((_: IPrep) => _.location)] = (value: string) => value?.split(',') ?? [];
    obj[nameOf((_: IDoorHingePrep) => _.highFrequency)] = (value: string) => value;
    obj[nameOf((_: IDoorHingePrep) => _.isElectricHinge)] = (value: string) => value;
    obj[nameOf((_: IDoorHingePrep) => _.electricHingeLocation)] = (value: string) => value;
    obj[nameOf((_: IDoorHingePrep) => _.locationWithinHinge)] = (value: string) => value;
    return obj;
  }
}
