import { Injectable, OnDestroy } from '@angular/core';
import { IntersectableService } from './intersectable.service';
import { Subject } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';
import { Door } from '../models/door';
import { BaseIntersectableService, IntersectableKeyboardEventModifier } from '../abstracts/base-intersectable-service';
import { DialogService } from '../../core/services/dialog.service';

@Injectable()
export class DoorService extends BaseIntersectableService implements OnDestroy {
  private _activeDoor$ = new Subject<Door>();
  get activeDoor$() {
    return this._activeDoor$.asObservable();
  }
  private _activeDoor: Door;
  get activeDoor() {
    return this._activeDoor;
  }

  constructor(private intersectableService: IntersectableService, dialogService: DialogService) {
    super(dialogService);
    this.intersectableService.activeIntersectable$
      .pipe(
        takeUntil(this._destroy$),
        map(i => (i instanceof Door ? i : null)),
        tap(door => this._activeDoor$.next(door))
      )
      .subscribe(door => (this._activeDoor = door));

    this.registerHotkey('keydown', 'ArrowUp', IntersectableKeyboardEventModifier.None, this.arrowUp);
    this.registerHotkey('keyup', 'ArrowUp', IntersectableKeyboardEventModifier.ShiftKey, this.arrowUpShift);
    this.registerHotkey('keyup', 'ArrowUp', IntersectableKeyboardEventModifier.CtrlKey, this.arrowUpCtrl);
    this.registerHotkey('keydown', 'ArrowUp', IntersectableKeyboardEventModifier.AltKey, this.arrowUpAlt);

    this.registerHotkey('keydown', 'ArrowRight', IntersectableKeyboardEventModifier.None, this.arrowRight);
    this.registerHotkey('keyup', 'ArrowRight', IntersectableKeyboardEventModifier.ShiftKey, this.arrowRightShift);
    this.registerHotkey('keyup', 'ArrowRight', IntersectableKeyboardEventModifier.CtrlKey, this.arrowRightCtrl);
    this.registerHotkey('keydown', 'ArrowRight', IntersectableKeyboardEventModifier.AltKey, this.arrowRightAlt);

    this.registerHotkey('keydown', 'ArrowLeft', IntersectableKeyboardEventModifier.None, this.arrowLeft);
    this.registerHotkey('keyup', 'ArrowLeft', IntersectableKeyboardEventModifier.ShiftKey, this.arrowLeftShift);
    this.registerHotkey('keyup', 'ArrowLeft', IntersectableKeyboardEventModifier.CtrlKey, this.arrowLeftCtrl);
    this.registerHotkey('keydown', 'ArrowLeft', IntersectableKeyboardEventModifier.AltKey, this.arrowLeftAlt);

    this.registerHotkey('keydown', 'ArrowDown', IntersectableKeyboardEventModifier.None, this.arrowDown);
    this.registerHotkey('keyup', 'ArrowDown', IntersectableKeyboardEventModifier.ShiftKey, this.arrowDownShift);
    this.registerHotkey('keyup', 'ArrowDown', IntersectableKeyboardEventModifier.CtrlKey, this.arrowDownCtrl);
    this.registerHotkey('keydown', 'ArrowDown', IntersectableKeyboardEventModifier.AltKey, this.arrowDownAlt);
  }

  deactivate(){
    this.intersectableService.activate(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  arrowUp = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.moveUp();
  };

  arrowUpShift = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.alignUp();
  };

  arrowUpCtrl = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.stretchUp();
  };

  arrowUpAlt = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.shrinkUp();
  };

  arrowRight = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.moveRight();
  };

  arrowRightShift = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.alignRight();
  };

  arrowRightCtrl = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.stretchRight();
  };

  arrowRightAlt = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.shrinkRight();
  };

  arrowLeft = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.moveLeft();
  };

  arrowLeftShift = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.alignLeft();
  };

  arrowLeftCtrl = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.stretchLeft();
  };

  arrowLeftAlt = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.shrinkLeft();
  };

  arrowDown = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.moveDown();
  };

  arrowDownShift = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.alignDown();
  };

  arrowDownCtrl = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.stretchDown();
  };

  arrowDownAlt = () => {
    if (!this._activeDoor?.isEditable) {
      return;
    }
    this._activeDoor?.shrinkDown();
  };
}
