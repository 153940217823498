import { CoreConstants } from '../core.constants';
import { UnitOfMeasure } from '../enums/unit-of-measure';

export {};

declare global {
  interface String {
    fromDimension(this: string, type: 'door' | 'frame', unitOfMeasure: UnitOfMeasure): number;
    searchFor(this: string, value: string, exactMatch?: boolean): boolean;
    toCamelCase(this: string): string;
    toXmlString(this: string): string;
  }
}

function fromImperial(value: string, type: 'door' | 'frame') {
  let num = 0;
  const groups = CoreConstants.regex.imperial.exec(value);
  if (groups == null) {
    return 0;
  }
  const feet = parseInt(groups[1], 10);
  if (!isNaN(feet)) {
    num += feet * 12;
  }

  const inches = parseInt(groups[2], 10);
  if (!isNaN(inches)) {
    num += inches;
  }

  const fraction = groups[3];
  if (fraction) {
    const arr = fraction.split('/');
    num += parseInt(arr[0], 10) / parseInt(arr[1], 10);
  }

  const decimal = groups[4];
  if (decimal) {
    num += parseInt(decimal, 10) / Math.pow(10, decimal.length);
  }

  let multiplier = 1;
  switch (type) {
    case 'door':
      multiplier = CoreConstants.multipliers.doorElevation;
      break;
    case 'frame':
      multiplier = CoreConstants.multipliers.frameElevation;
      break;
  }
  return Math.floor(num * multiplier);
}

function fromMetric(value: string) {
  const groups = CoreConstants.regex.metric.exec(value);
  if (groups == null) {
    return 0;
  }
  return Math.round(parseFloat(value));
}

function fromDimension(this: string, type: 'door' | 'frame', unitOfMeasure: UnitOfMeasure): number {
  switch (unitOfMeasure) {
    case UnitOfMeasure.Imperial:
      return fromImperial(this, type);
    case UnitOfMeasure.Metric:
      return fromMetric(this);
  }
}

function searchFor(this: string, value: string, exactMatch?: boolean) {
  const a = this.toLowerCase().trim();
  const b = value?.toLowerCase().trim();
  return exactMatch ? a === b : a.includes(b);
}

function toCamelCase(this: string): string {
  return this.replace(/[^a-z0-9 ]/gim, ' ')
    .split(' ')
    .map((x, i) => (i === 0 ? x.toLowerCase() : x))
    .join('');
}

function toXmlString(this: string): string {
  return (this ?? '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
}

String.prototype.fromDimension = fromDimension;
String.prototype.searchFor = searchFor;
String.prototype.toCamelCase = toCamelCase;
String.prototype.toXmlString = toXmlString;

