import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreConstants } from '../../core/core.constants';
import { AdditionalLabel } from '../../core/enums/additional-label';
import { Approval } from '../../core/enums/approval';
import { PrepLocation } from '../../core/enums/prep-location';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { convertImperialMetricEnum } from '../../core/functions/convertJambDepth';
import { nameOf, enumKeys } from '@oeo/common';
import { uomSwitch as uom } from '../../core/functions/uomSwitch';
import { CustomRuleValidator } from '../../core/helpers/custom-rule-validator';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { ICustomRuleEntity } from '../../core/interfaces/i-custom-rule-entity';
import { ICustomRuleGroup } from '../../core/interfaces/i-custom-rule-group';
import { ICustomRuleType } from '../../core/interfaces/i-custom-rule-type';
import { Updatable } from '../abstracts/updatable';
import { DoorBottomChannel, DoorTopChannel } from '../enums/door-channel';
import { DoorCore } from '../enums/door-core';
import { DoorFinishPaint } from '../enums/door-finish-paint';
import { DoorFireRating } from '../enums/door-fire-rating';
import { DoorGauge } from '../enums/door-gauge';
import { DoorGlassType } from '../enums/door-glass-type';
import { DoorMaterial } from '../enums/door-material';
import { DoorMetalMylar } from '../enums/door-metal-mylar';
import { DoorSeamlessEdge } from '../enums/door-seamless-edge';
import { DoorSeries } from '../enums/door-series';
import { DoorSteelStiffen } from '../enums/door-steel-stiffen';
import { DoorSubType } from '../enums/door-subtype';
import { DoorSweep } from '../enums/door-sweep';
import { DoorThickness } from '../enums/door-thickness';
import { DoorSeriesInfo } from '../interfaces/door-series-info';
import { DoorSeriesInfos } from '../interfaces/door-series-infos';
import { Door, doorRuleType } from '../models/door';
import { DoorElevationExport } from './exports/door-elevation-export';
import { IValidationMessage } from '../../core/interfaces/i-validation-message';
import { IElevation } from '../../core/interfaces/i-elevation';

export class DoorElevation extends Updatable implements ICustomRuleEntity, IElevation {
  id?: number;
  createdOn?: string;
  modifiedOn?: string;
  name: string = 'Untitled Drawing';
  configuration: string;
  userId?: number;
  folderId?: number;
  thumbnail?: string;
  containsErrors?: boolean;

  get hingeClearance(): number {
    return (this.unitOfMeasure === UnitOfMeasure.Imperial ? '1/8"' : '5mm').fromDimension('door', this.unitOfMeasure);
  }
  get headClearance() {
    return (this.unitOfMeasure === UnitOfMeasure.Imperial ? '1/8"' : '3mm').fromDimension('door', this.unitOfMeasure);
  }
  get meetingEdge() {
    return uom('1/8"', 'door', this.unitOfMeasure);
  }

  get doorSeriesInfo(): DoorSeriesInfo {
    return DoorSeriesInfos[this.series];
  }

  constructor(doorElevation?: DoorElevationExport, elevation?: IElevation) {
    super();
    if (doorElevation == null) {
      return;
    }
    Object.assign(this, doorElevation, elevation);
    this.doors = doorElevation.doors?.map(d => new Door(this, d)) ?? [];
  }

  private _doorElevation$ = new Subject<void>();

  readonly uomType = 'door';
  unitOfMeasure: UnitOfMeasure = UnitOfMeasure.Imperial;
  height: number;
  width: number;
  undercut: number = '3/4"'.fromDimension('door', UnitOfMeasure.Imperial);
  series: DoorSeries;
  doors: Door[];
  fireRating: DoorFireRating;
  additionalLabel: AdditionalLabel;
  approval: Approval;
  wideInactive: boolean = false;
  thickness: DoorThickness;
  isPair: boolean = false;
  material: DoorMaterial;
  gauge: DoorGauge;
  core: DoorCore;
  topChannel: DoorTopChannel;
  bottomChannel: DoorBottomChannel;
  sweep: DoorSweep;
  svg: SVGSVGElement;
  steelStiffen: DoorSteelStiffen;
  metalMylar: DoorMetalMylar;
  subType: DoorSubType;
  finishPaint: DoorFinishPaint;
  seamlessEdge: DoorSeamlessEdge;
  glassType: DoorGlassType
  doubleEgress: boolean = false
  prepLocationPreference: PrepLocation

  get isSteelcraft(): boolean {
    return this.doorSeriesInfo.experlogixSeriesId === 'STL_Elevation';
  }
  get isRepublic(): boolean {
    return this.doorSeriesInfo.experlogixSeriesId === 'REP_Elevation';
  }

  readonly ruleType: ICustomRuleType = {
    name: 'Door Elevation',
    prefix: 'this',
    properties: [
      { property: nameOf((_: DoorElevation) => _.width), name: 'Overall Width', type: 'number' },
      { property: nameOf((_: DoorElevation) => _.height), name: 'Overall Height', type: 'number' },
      { property: nameOf((_: DoorElevation) => _.undercut), name: 'Undercut', type: 'number' },
      {
        property: nameOf((_: DoorElevation) => _.series),
        name: 'Series',
        type: 'string',
        values: enumKeys(DoorSeries),
      },
      {
        property: nameOf((_: DoorElevation) => _.subType),
        name: 'Type',
        type: 'string',
        values: enumKeys(DoorSubType),
      },
      {
        property: nameOf((_: DoorElevation) => _.gauge),
        name: 'Gauge',
        type: 'string',
        values: enumKeys(DoorGauge),
      },
      {
        property: nameOf((_: DoorElevation) => _.thickness),
        name: 'Thickness',
        type: 'string',
        values: enumKeys(DoorThickness),
      },
      {
        property: nameOf((_: DoorElevation) => _.fireRating),
        name: 'Fire Rating',
        type: 'string',
        values: enumKeys(DoorFireRating),
      },
      {
        property: nameOf((_: DoorElevation) => _.additionalLabel),
        name: 'Additional Label',
        type: 'string',
        values: enumKeys(AdditionalLabel),
      },
      {
        property: nameOf((_: DoorElevation) => _.approval),
        name: 'Approval',
        type: 'string',
        values: enumKeys(Approval),
      },
      {
        property: nameOf((_: DoorElevation) => _.metalMylar),
        name: 'Metal or Mylar',
        type: 'string',
        values: enumKeys(DoorMetalMylar),
      },
      {
        property: nameOf((_: DoorElevation) => _.material),
        name: 'Material',
        type: 'string',
        values: enumKeys(DoorMaterial),
      },
      {
        property: nameOf((_: DoorElevation) => _.steelStiffen),
        name: 'Steel Stiffen',
        type: 'string',
        values: enumKeys(DoorSteelStiffen),
      },
      {
        property: nameOf((_: DoorElevation) => _.core),
        name: 'Core',
        type: 'string',
        values: enumKeys(DoorCore),
      },
      {
        property: nameOf((_: DoorElevation) => _.finishPaint),
        name: 'Finish Paint',
        type: 'string',
        values: enumKeys(DoorFinishPaint),
      },
      {
        property: nameOf((_: DoorElevation) => _.doors),
        name: 'Doors',
        type: 'array',
        ruleType: doorRuleType,
      },
      {
        property: nameOf((_: DoorElevation) => _.seamlessEdge),
        name: 'Seamless Edge',
        type: 'string',
        values: enumKeys(DoorSeamlessEdge),
      },
      {
        property: nameOf((_: DoorElevation) => _.glassType),
        name: 'Glass Type',
        type: 'string',
        values: enumKeys(DoorGlassType),
      },
    ],
  };

  public prepsContainErrors$ = new BehaviorSubject<boolean>(false)
  validatePreps(): IValidationMessage[]{
    const validationMessages: IValidationMessage[] = []
    const isPair = this.doors.length === 2

    this.doors.map((door, i)=>{
      const doorPosition = isPair ? i === 0 ? 'left' : 'right' : ''
      if (door.active && !door.primaryLockPrep){
        validationMessages.push({value: `WARNINGS.${doorPosition}doorMissingPrimaryLockPrep`, level: 3, category: 'preps'})
      }
      else if(!door.active && !door.primaryStrikePrep){
        validationMessages.push({value:`WARNINGS.${doorPosition}doorMissingPrimaryStrikePrep`, level: 3, category: 'preps'})
      }
      if(!door.hingePrep && !door.blankSurfaceHingePrep){
        validationMessages.push({value:`WARNINGS.${doorPosition}doorMissingHingePrep`, level: 3, category: 'preps'})
      }
    })

    this.prepsContainErrors$.next(validationMessages.length > 0)
    return validationMessages
  }

  static fromXML(value: string): DoorElevationExport {
    if (!value) {
      return {} as DoorElevationExport;
    }
    const doc = parseFromXMLString(value);
    const unitOfMeasure = this.parseXML(
      doc,
      nameOf((_: DoorElevation) => _.unitOfMeasure)
    ) as UnitOfMeasure;
    return {
      unitOfMeasure,
      height:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: DoorElevation) => _.height)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      width:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: DoorElevation) => _.width)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      undercut:
        parseFloat(
          this.parseXML(
            doc,
            nameOf((_: DoorElevation) => _.undercut)
          )
        ) * (unitOfMeasure === UnitOfMeasure.Imperial ? CoreConstants.multipliers.doorElevation : 1),
      series: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.series)
      ) as DoorSeries,
      subType: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.subType)
      ) as DoorSubType,
      fireRating: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.fireRating)
      ) as DoorFireRating,
      additionalLabel: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.additionalLabel)
      ) as AdditionalLabel,
      approval: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.approval)
      ) as Approval,
      wideInactive:
        this.parseXML(
          doc,
          nameOf((_: DoorElevation) => _.wideInactive)
        )?.toLowerCase() === 'true',
      thickness: this.parseXML(doc, 'doorThickness') as DoorThickness,
      isPair:
        this.parseXML(
          doc,
          nameOf((_: DoorElevation) => _.isPair)
        )?.toLowerCase() === 'true',
      material: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.material)
      ) as DoorMaterial,
      seamlessEdge: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.seamlessEdge)
      ) as DoorSeamlessEdge,
      gauge: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.gauge)
      ) as DoorGauge,
      core: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.core)
      ) as DoorCore,
      topChannel: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.topChannel)
      ) as DoorTopChannel,
      bottomChannel: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.bottomChannel)
      ) as DoorBottomChannel,
      sweep: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.sweep)
      ) as DoorSweep,
      steelStiffen: (()=> {
        // TODO: There could be a better way to do this
        const steelStiffen = this.parseXML(
          doc,
          nameOf((_: DoorElevation) => _.steelStiffen)
        ) as DoorSteelStiffen
        if (!steelStiffen) return DoorSteelStiffen.NONE
        else return steelStiffen as DoorSteelStiffen
      })(),
      metalMylar: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.metalMylar)
      ) as DoorMetalMylar,
      finishPaint: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.finishPaint)
      ) as DoorFinishPaint,
      glassType: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.glassType)
      ) as DoorGlassType,
      doubleEgress: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.doubleEgress)
      ) === 'True',
      prepLocationPreference: this.parseXML(
        doc,
        nameOf((_: DoorElevation) => _.prepLocationPreference)
      ) as PrepLocation,
      doors: this.parseDoorXML(doc).map(d => Door.fromXML(d, doc, unitOfMeasure)),
    };
  }

  private static parseDoorXML(doc: Document): string[] {
    const result = doc.evaluate(
      `//category[@id="Door"]/option[@id="Door"]`,
      doc,
      null,
      XPathResult.UNORDERED_NODE_ITERATOR_TYPE,
      null
    );
    const intersectables: string[] = [];
    let intersectable = result.iterateNext() as HTMLElement;
    while (intersectable) {
      intersectables.push(intersectable.outerHTML);
      intersectable = result.iterateNext() as HTMLElement;
    }
    return intersectables;
  }

  private static parseXML(doc: Document, property: string): string {
    const value = doc.evaluate(
      `//category[@id="DoorElevation"]/option[@id="DoorElevation"]/property[translate(@id,'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz')='${property.toLowerCase()}']`,
      doc,
      null,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    )?.singleNodeValue?.textContent;
    if (!value || value === 'undefined') {
      return null;
    }
    return value;
  }

  toXML(): string {
    const erpNomenclatureIdentifier = '{{erpNomenclatureIdentifier}}';
    const doc = parseFromXMLString(
      `
      <lineitem seriesid="${this.doorSeriesInfo?.experlogixSeriesId}" modelid="${
        this.doorSeriesInfo?.experlogixModelId
      }">
          <category id="S_Summary">
            <option id="s_Summary">
              <property id="S_CatalogNumber">${this.catalogNumber()}</property>
            </option>
          </category>
          <category id="Calcs">
            <option id="Calcs">
              <property id="ERP_AGNConfigNomenclature">${erpNomenclatureIdentifier}</property>
            </option>
          </category>
          ${this.appendDoorElevationXml()}
          <category id="Door">
            ${this.doors?.map(door => door.toXML()).join('\n')}
          </category>
          <category id="Cutout">
            ${this.doors.map(d => d.doorType?.cutouts.map(cutout => cutout.toXML())).join('\n') ?? ''}
          </category>
          <category id="Image">
            <option id="Elevation">
              <property id="Svg">${btoa(this.svg?.prepareForExport(40, 80) ?? '')}</property>
            </option>
          </category>
      </lineitem>`
    );
    const erpNomenclature = Array.from(doc.getElementsByTagName('property'))
      .map(x => Array.from(x.innerHTML).take(2))
      .join(' ')
      .replace(/[^a-z0-9]+/gi, '');
    return doc.documentElement.outerHTML.replace(erpNomenclatureIdentifier, erpNomenclature);
  }

  draw(containers: SVGGElement[]): void {
    this._doorElevation$.next();
    for (let i = 0; i < this.doors.length; i++) {
      this.doors[i].draw(containers[i]);
      this.doors[i].update$
        .pipe(takeUntil(this._destroy$), takeUntil(this._doorElevation$))
        .subscribe(() => this._update$.next());
    }
  }

  update(doorElevation?: Partial<DoorElevation>) {
    Object.assign(this, doorElevation);
    this._update$.next();
  }

  destroy(): void {
    this._doorElevation$.next();
    this._doorElevation$.unsubscribe();
    super.destroy();
  }

  execRules(ruleGroups: ICustomRuleGroup[]): { messages: { value: string; level: number }[]; errors: string[] } {
    const errors: string[] = [];
    const messages = ruleGroups
      .map(r => ({ script: new CustomRuleValidator().script(r), value: r.message, level: r.level }))
      .filter(value => {
        try {
          // used in eval for dynamic rules
          const uomSwitch = uom;
          // tslint:disable-next-line: no-eval
          return eval(value.script);
        } catch (ex) {
          errors.push(ex);
          return false;
        }
      });
    return { messages, errors };
  }

  init(): void {
    this._update$.next();
  }

  private appendDoorElevationXml(): string {
    return `<category id="DoorElevation">
      <option id="DoorElevation">
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.unitOfMeasure),
          this.unitOfMeasure
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.height),
          `${
            this.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.height / CoreConstants.multipliers.doorElevation
              : this.height
          }`
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.width),
          `${
            this.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.width / CoreConstants.multipliers.doorElevation
              : this.width
          }`
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.undercut),
          `${
            this.unitOfMeasure === UnitOfMeasure.Imperial
              ? this.undercut / CoreConstants.multipliers.doorElevation
              : this.undercut
          }`
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.series),
          this.series
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.subType),
          this.subType
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.fireRating),
          this.fireRating
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.additionalLabel),
          this.additionalLabel
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.approval),
          this.approval
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.wideInactive),
          `${this.wideInactive}`
        )}
        ${this.createProperty('doorThickness', this.thickness)}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.isPair),
          `${this.isPair}`
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.material),
          this.material
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.gauge),
          `${this.gauge}`
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.core),
          this.core
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.topChannel),
          this.topChannel
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.bottomChannel),
          this.bottomChannel
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.sweep),
          this.sweep
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.steelStiffen),
          this.steelStiffen === DoorSteelStiffen.NONE ? null : this.steelStiffen
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.metalMylar),
          this.metalMylar
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.finishPaint),
          this.finishPaint
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.seamlessEdge),
          this.seamlessEdge
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.glassType),
          this.glassType
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.doubleEgress),
          this.doubleEgress.toString()
        )}
        ${this.createProperty(
          nameOf((_: DoorElevation) => _.prepLocationPreference),
          this.prepLocationPreference
        )}
      </option>
    </category>`;
  }

  private createProperty(id: string, value: string): string {
    return `<property id="${id}">${value?.toXmlString() ?? ''}</property>`;
  }

  private catalogNumber(): string {
    return [
      this.doors.length > 1 ? 'PAIR' : this.doors[0].active ? 'SINGLE ACT' : 'SINGLE INACT',
      this.series,
      this.width.toDimension('door', this.unitOfMeasure),
      'X',
      this.height.toDimension('door', this.unitOfMeasure),
      'X',
      convertImperialMetricEnum(this.thickness, 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure),
      this.gauge,
      this.material,
      this.steelStiffen === DoorSteelStiffen.NONE ? '' : this.steelStiffen,
      this.core,
      this.fireRating,
      this.metalMylar,
      this.glassType
    ]
      .filter(x => !!x)
      .join(' ');
  }
}
