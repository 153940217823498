import { IPrep } from '../../../../core/interfaces/i-prep';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { uomSwitch } from '../../../../core/functions/uomSwitch';
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { DoorPrepReferencePoint } from '../../../enums/door-prep-reference-point';
import { IDoorLockPrep } from '../../../interfaces/preps/i-door-lock-prep';
import { Door } from '../../../models/door';
import { DoorPrepComponent } from '../door-prep/door-prep.component';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';
import { getEnumValues } from '@oeo/common';

export abstract class ViewerPrepComponent extends PrepBaseComponent {
  door: Door;
  abstract doorPrepComponent: DoorPrepComponent;
  codes: IPrepCode[];
  get code(): IPrepCode {
    return this.prep.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  locations: IPrepCategoryLocation[];


  get referencePoints(): DoorPrepReferencePoint[] {
    return getEnumValues(DoorPrepReferencePoint)
  }

  abstract set prep(value: IPrep);
  abstract get prep(): IPrep;

  constructor(public title: string, prepsComponent: PrepsComponent) {
    super(prepsComponent, 32);
    this.setDefaultPrepValues()
  }

  setDefaultPrepValues() {
    if (!this.code) {
      this.prep = {} as IDoorLockPrep;
    }
    this.prep.referencePoint = this.referencePoints.first((c) => c === this.prep.referencePoint) ?? DoorPrepReferencePoint.FFCL;
  }

  onInit(): void {
    this.locations = [this.specialPrepLocation];

    this.setSpecial();
  }

  afterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.prepFieldsForm.valueChanges.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => {
      if(this.prep.code && !this.prep.locationType){
        if(this.locations.length === 1){
          this.prep.locationType = this.locations.first()?.value;
        }
      }
      this.prep.location = this.code?.fixedLocation
        ? this.code.standardLocation
        : this.prep.location ?? uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure);
      this.draw$.next();

    })
    this.draw$.next();
  }

  onDestroy(): void {
    super.destroy();
  }

  override get incompleteQs() {
    return super.incompleteQs && !this.prepFieldsForm.valid
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (this.prep.locationType !== this.specialPrepLocation.value) {
      this.prep.location = Array.isArray(this.prep.location)? []: '0"'
      this.doorPrepComponent.resetAndFit();
      return;
    }
    this.prepLocationInputs.push(this.drawPrep(0, this.prep));
  }
}
