export enum TemplateType {
  BLF04 = 'BLF04',
  BLF05HM = 'BLF05-HM',
  BLF05VM = 'BLF05-VM',
  SPS05 = 'SPS05',
  BLF06HM = 'BLF06-HM',
  BLF06VM = 'BLF06-VM',
  // SINGLE = 'SINGLE',
  // PAIR = 'PAIR',
  DDPS07 = 'DDPS07',
  DDSL07 = 'DDSL07',
  DDSL09 = 'DDSL09',
  DDST10 = 'DDST10',
  DDST12 = 'DDST12',
  DPS07 = 'DPS07',
  DSL07 = 'DSL07',
  DSL09 = 'DSL09',
  DSPS05 = 'DSPS05',
  DSPS06 = 'DSPS06',
  DSSL05 = 'DSSL05',
  DSSL06 = 'DSSL06',
  DSST06 = 'DSST06',
  DSST07 = 'DSST07',
  DSST07FM = 'DSST07-FM',
  DSST08 = 'DSST08',
  DST10 = 'DST10',
  DST12 = 'DST12',
  DTRF04 = 'DTRF04',
  SSL05 = 'SSL05',
  SSL06 = 'SSL06',
  SST06 = 'SST06',
  SST07FM = 'SST07FM',
  SST07 = 'SST07',
  SST08 = 'SST08',
  TRF04 = 'TRF04',
  SGLOPNGS = 'SGL OPNGS',
  SGLOPNGSW2ASA = 'SGL OPNGS W-2ASA',
}
