import { Component, OnInit } from '@angular/core';
import { Route } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { IDialog } from '../../../core/interfaces/i-dialog';
import { DialogService } from '../../../core/services/dialog.service';

@Component({
  selector: 'lib-confirm-dialog',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmDialogComponent implements OnInit, IDialog<Route> {
  confirmText = 'Confirm';
  data: Route;
  iconName = 'warning'
  subTitle: string = ''
  title =('TITLE.confirmSavedChanges')
  where: string;
  showGoBack = true

  constructor(protected dialogService: DialogService, private translate: TranslateService) { }

  ngOnInit(): void {
    const destination  =  this.data?.data?.breadcrumb === 'home' ? 'Home' : this.data?.data?.breadcrumb;
    this.subTitle = (this.translate.instant('WARNINGS.areYouSureYouWantToContinue'))
  }

  confirm() {
    this.dialogService.close({confirm: true});
   }

  goBack() {
    this.dialogService.close({confirm: false});
  }

}
