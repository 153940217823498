import { debounceTime, takeUntil } from 'rxjs/operators';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { DoorPrepReferencePoint } from '../../../enums/door-prep-reference-point';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DoorPrepComponent } from '../door-prep/door-prep.component';
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids';
import { IDoorFlushBoltPrep } from '../../../interfaces/preps/i-door-flush-bolt-prep';
import { UnitOfMeasure } from '../../../../core/enums/unit-of-measure';
import { getEnumValues } from '@oeo/common';
import { orderBy,} from 'lodash';

@Component({
  selector: 'lib-flush-bolt-prep',
  templateUrl: './flush-bolt-prep.component.html',
  styleUrls: ['./flush-bolt-prep.component.scss', '../prep-base.component.scss'],
})
export class FlushBoltPrepComponent extends PrepBaseComponent implements OnInit {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent;

  codes: IPrepCode[];

  /**
   * Filters out prep codes based on height
   */
  get prepCodes(){
    return this.door.height <= '84"'.fromDimension('door', UnitOfMeasure.Imperial) ?
      this.codes.filter(({code})=> !FlushBoltPrepCodesGreaterThan84Inch.includes(code))
      : this.codes
  }

  public get code(): IPrepCode {
    return this.prep?.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  get prep(): IDoorFlushBoltPrep {
    return this.door.flushBoltPrep;
  }

  set prep(value: IDoorFlushBoltPrep) {
    this.door.flushBoltPrep = value;
    this.prepsComponent.configService.updateCurrentDoor(this.door)
  }

  constructor(
    prepsComponent: PrepsComponent,
  ) {
    super(prepsComponent, DoorPrepCategoryIds.FLUSH_BOLT);
    this.codes = orderBy(
      this.codes,
      [({ code }) => FrontierFlushBoltOrder.indexOf(code.trim())],
      ['desc']
    )
  }

  get referencePoints(): DoorPrepReferencePoint[] {
    return getEnumValues(DoorPrepReferencePoint)
  }
  private _referencePoint: DoorPrepReferencePoint
  get referencePoint(): DoorPrepReferencePoint {
    return this._referencePoint
  }
  set referencePoint(value: DoorPrepReferencePoint) {
    this._referencePoint = value
  }

  ngOnInit(): void {
    this.prepFieldsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.draw$.next()
    })
  }

  afterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPrep());
    this.draw$.next();
  }

  drawPrep() {
    super.clear();
    this.prepLocationInputs = []
    if (!this.prep.code) {
      this.doorPrepComponent.resetAndFit();
      return
    }
    const inputElement = super.drawPrep(0, this.prep)
    this.prepLocationInputs.push(inputElement);
    return inputElement
  }
}

const FlushBoltPrepCodesGreaterThan84Inch = [
  'FB32',
  '32AT',
  'FC36',
  'FB30',
  'FB36',
  'FB40',
  'FB48',
  'F30A',
  'F36A',
  'F40A',
  'F48A',
  'F30T',
  'F36T',
  'F40T',
  'F48T',
  '30AT',
  '36AT',
  '40AT',
  '48AT',
  'FCT3'
]

const FrontierFlushBoltOrder = [
  'FB',
  'FB18',
  'FB20',
  'FB22',
  'FB24',
  'FB30',
  'FB32',
  'FB36',
  'FB40',
  'FB48',
  'ET/B',
  'FB/B',
  'FBA',
  'FBAT',
  'F18A'
].reverse()

