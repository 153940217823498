import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { isEqual } from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { IPrep } from '../../interfaces/i-prep';
import { IPrepCategoryLocation } from '../../interfaces/i-prep-category-location';
import { IPrepCode } from '../../interfaces/i-prep-code';
import { IProduct } from '../../interfaces/i-product';
import { IQuestion } from '../../interfaces/i-question';
import { ProductService } from '../../services/door-product.service';

export abstract class SpecialHardwarePrepBaseComponent {
  abstract products: IProduct[];
  abstract prep: IPrep;
  abstract prepsComponent: { loading: boolean; productService: ProductService };
  abstract code: IPrepCode;

  readonly specialPrepCode: IPrepCode = {
    id: 0,
    code: 'Special',
  };

  readonly specialPrepLocation: IPrepCategoryLocation = {
    id: 0,
    value: 'Special'
  }

  readonly templatePrepLocation: IPrepCategoryLocation = {
    id: 0,
    value: 'Template'
  }

  protected prepLocationInputs: HTMLInputElement[] = []
  get prepLocationInputsFilled(){
    return !this.prepLocationInputs.any((location)=> location?.value === '0"')
  }

  /**
   * Checks whether the prep set is the specialPrepCode
   */
  get isSpecialPrep(){
    return isEqual(this.code, this.specialPrepCode)
  }

  private _manufacturer: string = null;
  public get manufacturer(): string {
    return this._manufacturer;
  }
  public set manufacturer(value: string) {
    if (this._manufacturer === value) {
      return;
    }
    this.prep.manufacturer = this._manufacturer = value;
    this.productIds = this.products
      .filter(p => p.manufacturer === this.manufacturer && p.categoryDisplayName === this.hardwareType)
      .select(p => p.productId)
      .distinct()
      .orderBy(x => x);
    this.productId = null;
  }

  manufacturers: string[];

  private _hardwareType: string = null;
  protected hardwareType$ = new Subject<string>()
  public get hardwareType(): string {
    return this._hardwareType;
  }
  public set hardwareType(value: string) {
    if (value === this._hardwareType) {
      return;
    }
    this.prep.hardwareType = this._hardwareType = value;
    this.manufacturers = this.products
      .filter(p => p.categoryDisplayName === this.hardwareType)
      .select(p => p.manufacturer)
      .distinct()
      .orderBy(x => x);
    this.manufacturer = null;
    this.hardwareType$.next(value)
  }

  private _hardwareTypes: string[];
  get hardwareTypes(): string[] {
    return this._hardwareTypes
      ? this._hardwareTypes
      : (this._hardwareTypes = this.products
          .select(p => p.categoryDisplayName)
          .distinct()
          .orderBy(x => x));
  }

  private _productId: string = null;
  public get productId(): string {
    return this._productId;
  }
  public set productId(value: string) {
    if (value === this._productId) {
      return;
    }
    this.prep.productId = this._productId = value;
    this.questions = [];
    if (!this.productId) {
      return;
    }

    this.prepsComponent.loading = true;
    this.prepsComponent.productService
      .getProducts(this.manufacturer, this.productId, this.hardwareType)
      .subscribe(products => {
        this.prepsComponent.loading = false;
        this.availableProducts = products;
        this.prep.pricingCategory = products.first()?.pricingCategory;
        this.setupProductQuestions(true);
      });
  }
  productIds: string[];

  availableProducts: IProduct[];
  private _questions: IQuestion[] = [];
  public get questions(): IQuestion[] {
    return this._questions;
  }
  public set questions(value: IQuestion[]) {
    this._questions = value;
  }

  setSpecial() {
    if (!this.isSpecialPrep) {
      return;
    }
    this.hardwareType = this.prep.hardwareType ?? null;
    this.manufacturer = this.prep.manufacturer ?? null;
    this._productId = this.prep.productId ?? null;
    this.prepsComponent.loading = true;
    this.prepsComponent.productService
      .getProducts(this.manufacturer, this.productId, this.hardwareType)
      .subscribe(products => {
        this.prepsComponent.loading = false;
        this.availableProducts = products;
        this.setupProductQuestions(false);
        for (let i = 0; i < this.prep.questions?.length; i++) {
          if (this.questions[i]) {
            this.questions[i].value = this.prep.questions[i].value;
          } else {
            return;
          }
        }
      });
  }

  setupProductQuestions(setValue: boolean): void {
    for (const option of this.availableProducts.select(x => x.options).selectMany(x => x)) {
      let question = this.questions.first(q => q.title === option.displayLabel);
      if (!question) {
        question = {
          title: option.displayLabel,
          value: null,
          values: [],
          sequence: option.sequence,
        };
        this.questions.push(question);
      }
      if (!question.values.any(v => v === option.value)) {
        question.values.push(option.value);
      }
    }
    this.questions = this.questions.orderBy(x => x.sequence);
    this.questions.forEach(q => {
      q.values.orderBy(v => v);
      if (q.values.length === 1) {
        this.setQuestionValue(q, q.values.first(), setValue);
      }
    });
  }

  setQuestionValue(question: IQuestion, value: string, setValue: boolean) {
    question.value = value;
    const i = this.questions.indexOf(question) + 1;
    const answeredQuestions = this.questions.take(i);
    const availableProducts = this.availableProducts.filter(x =>
      x.options.all(
        y =>
          !answeredQuestions.any(a => a.title === y.displayLabel) ||
          answeredQuestions.any(a => a.title === y.displayLabel && a.value === y.value)
      )
    );
    if (this.questions[i]) {
      const q = this.questions[i];
      q.values = availableProducts
        .select(x => x.options.filter(c => c.displayLabel === q.title).map(y => y.value))
        .selectMany(x => x)
        .distinct();
      if (q.values.length === 1) {
        q.value = q.values.first();
      }
      this.setQuestionValue(q, q.values.any(v => q.value === v) ? q.value : null, setValue);
    } else {
      if (setValue) {
        this.prep.questions = [...this.questions];
      }
    }
  }

  isQuestionVisible(question: IQuestion) {
    if(question.title.searchFor('ALUM BULL NOSING')) return false
    return !question.values.all(v => v === 'N/A');
  }

  /**
   * Checks if there are any incomplete special question values
   */
  hasMissingQuestions(){
    return this.questions.filter(this.isQuestionVisible).any((question)=>!question.value) || !this.hardwareType || !this.manufacturer || !this.productId
  }
}

@Component({
  selector: 'lib-special-hardware',
  templateUrl: './special-hardware.component.html',
  styleUrls: ['./special-hardware.component.scss'],
})
export class SpecialHardwareComponent implements OnDestroy {
  private destroy$ = new Subject()

  @Input() component: SpecialHardwarePrepBaseComponent

  #isSpecialPrep = false
  @Input() set isSpecialPrep(isSpecialPrep: boolean){
    this.#isSpecialPrep = isSpecialPrep
    /* Reset prep questions if prep should not contain special questions */
    if(!this.isSpecialPrep){
      this.component.hardwareType = null
      this.component.manufacturer = null
      this.component.productId = null
      this.component.questions = null
    }
  }

  get isSpecialPrep(){
    return this.#isSpecialPrep
  }

  @ViewChild('SpecialQuestionsForm', { static: true, read: NgForm}) specialQuestionsForm: NgForm

  private prepNotListed: boolean;
  get message(): string {
    return (
      this.component.products.first(p => p.categoryDisplayName === this.component.hardwareType)?.categoryMessage ?? ''
    );
  }

  ngOnDestroy(){
    this.destroy$.next(true)
  }
}
