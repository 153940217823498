<lib-dialog header="Configuration">
  <h3>Overall</h3>
  <ul>
    <li>
      Formula:
      <span class="value"
        >(LinealInchPrice + SpecialProfileLinealInchAdder + SpecialBackBendAdder + SpecialFaceAdder + SpecialRabbetAdder
        + SpecialStopAdder + MaterialAdder) * MaterialMultiplier * Length + CornerCondition1Adder +
        CornerCondition2Adder + StickTypeAdder + Weld1Adder + Weld2Adder + SpliceJointAdder</span
      >
    </li>
    <li>
      Series: <span class="value">{{ lineItem.model }}</span>
    </li>
    <li>
      Unit Price: <span class="value">${{ lineItem.unitPrice }}</span>
    </li>
    <li>
      Material: <span class="value">{{ lineItem.material }}</span>
    </li>
    <li>
      Corner Assembly: <span class="value">{{ lineItem.cornerAssembly }}</span>
    </li>
    <li>
      Jamb Depth: <span class="value">{{ lineItem.jambDepth }}</span>
    </li>
  </ul>
  <ng-container *ngFor="let stick of lineItem.sticks">
    <h3>{{ stick.name }}</h3>
    <ul>
      <li>
        Unit Price: <span class="value">${{ stick.unitPrice }}</span>
      </li>
      <li>
        Lineal Inch Price: <span class="value">${{ stick.linealInchPrice }}</span>
      </li>
      <li>
        Partial Notch Adder: <span class="value">${{ stick.partialNotchAdder }}</span>
      </li>
      <li>
        Corner Condition 1 Adder: <span class="value">${{ stick.cornerCondition1Adder }}</span>
      </li>
      <li>
        Corner Condition 2 Adder: <span class="value">${{ stick.cornerCondition2Adder }}</span>
      </li>
      <li>
        Weld 1 Adder: <span class="value">${{ stick.weld1Adder }}</span>
      </li>
      <li>
        Weld 2 Adder: <span class="value">${{ stick.weld2Adder }}</span>
      </li>
      <li>
        Special Stop Adder: <span class="value">${{ stick.specialStopAdder }}</span>
      </li>
      <li>
        Special Backbend Adder: <span class="value">${{ stick.specialBackBendAdder }}</span>
      </li>
      <li>
        Special Profile Lineal Inch Adder: <span class="value">${{ stick.specialProfileLinealInchAdder }}</span>
      </li>
      <li>
        Splice Joint Adder: <span class="value">${{ stick.spliceJointAdder }}</span>
      </li>
      <li>
        Special Rabbet Adder: <span class="value">${{ stick.specialRabbetAdder }}</span>
      </li>
      <li>
        Special Face Adder: <span class="value">${{ stick.specialFaceAdder }}</span>
      </li>
      <li>
        Material Adder: <span class="value">${{ stick.materialAdder }}</span>
      </li>
      <li>
        Material Multiplier: <span class="value">{{ stick.materialMultiplier }}</span>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngFor="let glass of lineItem.glasses">
    <h3>{{ glass.name }}</h3>
    <ul>
      <li>
        Unit Price: <span class="value">${{ glass.unitPrice }}</span>
      </li>
      <li>
        Vert. Glazing Bead Adder: <span class="value">${{ glass.vertGlazingBeadAdder }}</span>
      </li>
      <li>
        Horz. Glazing Bead Adder: <span class="value">${{ glass.horzGlazingBeadAdder }}</span>
      </li>
      <li>
        Center Glazed Adder: <span class="value">${{ glass.centerGlazedAdder }}</span>
      </li>
    </ul>
  </ng-container>
</lib-dialog>
