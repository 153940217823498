import { AfterViewInit, Component, ElementRef, Input, Renderer2 } from '@angular/core'
import { MatSelectChange } from '@angular/material/select'
import { DropdownOption, InputEvent, TableColumn } from '../../../../types'
import { TableService } from '../../table.service'
import { DefaultComponent } from '../default/default.component'

export type TDropDownComponentData = {}

@Component({
  selector: 'oa-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent<T> extends DefaultComponent<T> implements AfterViewInit {
  @Input() override row!: TDropDownComponentData
  @Input() override column!: TableColumn<T>
  @Input() override data!: string | number | boolean
  @Input() options: DropdownOption[] = []

  selectedValue!: string | undefined

  constructor(
    private tableActions: TableService<T>,
    protected override renderer: Renderer2,
    protected override hostElement: ElementRef
  ) {
    super(renderer, hostElement)
  }

  ngAfterViewInit() {
    this.selectedValue = this.options.find((_) => _.text === this.data)?.value
  }

  handleChange(event: MatSelectChange) {
    const payload: InputEvent<T> = {
      tableName: this.column.tableName ?? '',
      row: this.row,
      column: this.column,
      newVal: this.selectedValue
    }
    this.tableActions.inputEvent.next(payload)
  }
}
