import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';
import { IProductCategory } from '../interfaces/i-product-category';
import { IProductCategoryService } from '../interfaces/i-product-category.service';
import { Cacheable } from 'ts-cacheable';

@Injectable()
export class ProductCategoryService implements IProductCategoryService {
  private _data: IProductCategory[];
  constructor(private http: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  @Cacheable()
  get(): Observable<IProductCategory[]> {
    return this._data
      ? of(this._data)
      : this.http
          .get<IProductCategory[]>(`${this.environment.elevations.apiUrl}v1/productCategories`)
          .pipe(tap(data => (this._data = data)));
  }

  update(id: number, productCategory: Partial<IProductCategory>): Observable<void> {
    this._data = null;
    return this.http.patch<void>(`${this.environment.elevations.apiUrl}v1/productCategories/${id}`, productCategory);
  }
}
