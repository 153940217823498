<lib-dialog [header]="header | translate" [loading]="configService.loadingPrepCodes$ | async">
  <lib-stepper
    *ngIf="configService.steps.length > 1 && configService.steps[configService.step].type === 'DoorSettings'"
    [steps]="configService.steps"
    (stepChange$)="step = steps.indexOf($event)">
  </lib-stepper>
  <div class="door-settings-content">
    <ng-container #DoorElevationSettingContainer></ng-container>
  </div>
</lib-dialog>
