import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { seamlessEdgeBottomChannels } from '../../rules/seamless-edge';
import { DoorSeamlessEdge } from '../door-seamless-edge';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorBottomChannels(series: DoorSeries, subType: DoorSubType, seamlessEdge: DoorSeamlessEdge) {
  if (!series) {
    return [];
  }
  const channels = [...(DoorSeriesInfos[series]?.subTypes[subType]?.bottomChannels ?? [])];
  seamlessEdgeBottomChannels(seamlessEdge, channels);
  return channels;
}
