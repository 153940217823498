<ng-container *ngIf="data; else star">{{ data?.toString() | currency }}</ng-container>
<ng-template #star>
  <ng-container
    *ngIf="row && !row.isServiceCenter && !(row.isFrameElevation || row.product?.isFrameElevation); else doubleStar"
    >*</ng-container
  >
</ng-template>
<ng-template #doubleStar>
  <ng-container *ngIf="row && row.isServiceCenter; else exclamation">**</ng-container>
</ng-template>
<ng-template #exclamation>
  <ng-container *ngIf="row && (row.isFrameElevation || row.product?.isFrameElevation)">!</ng-container>
</ng-template>
