import { Component, OnDestroy } from '@angular/core';
import { Cutout } from '../../../abstracts/cutout';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../../enums/cutout-dimension-mode';
import { DoorMuntinBars } from '../../../enums/door-muntin-bar';
import { DoorGlassInstallation } from '../../../enums/door-glass-installation';
import { DoorLouverInstallation } from '../../../enums/door-louver-installation';
import { DoorLiteKit } from '../../../enums/door-lite-kit';
import { DoorLouverKit } from '../../../enums/door-louver-kit';
import { CutoutOrderSize } from '../../../enums/cutout-order-size';
import { DoorStyle, LiteLouverOptions } from '../../../enums/door-style';
import { DoorSubType } from '../../../enums/door-subtype';
import { DoorLiteKits } from '../../../enums/filters/DoorLiteKits';
import { DoorLouverKits } from '../../../enums/filters/DoorLouverKits';
import { DoorElevationService } from '../../../services/door-elevation-service/door-elevation.service';
import { applyUnitOfMeasure } from '../../../../core/helpers/applyUnitOfMeasure';
import { getEnumValues } from '@oeo/common';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-door-info',
  templateUrl: './door-info.component.html',
  styleUrls: ['./door-info.component.scss'],
})
export class DoorInfoComponent implements OnDestroy {
  subTypes = DoorSubType;
  muntinBars = DoorMuntinBars;

  #cutoutIndex = new BehaviorSubject<number>(0)
  get cutout(): Cutout {
    return this.doorElevationService.currentDoor?.doorType?.cutouts[this.cutoutIndex];
  }
  get cutoutIndex(){
    return this.#cutoutIndex.getValue();
  }
  set cutoutIndex(index: number) {
    this.#cutoutIndex.next(index);

    if (this.cutout) {
      this.doorElevationService.currentDoor.doorType.cutouts.forEach((c, i) => (c.active = i === this.cutoutIndex));
    }
  }

  public get muntinBar(): { vertQty: number; horzQty: number; value: string } {
    return this.muntinBars.first(
      m => m.horzQty === this.cutout?.horzMuntinBarQty && m.vertQty === this.cutout?.vertMuntinBarQty
    ) ?? null;
  }
  public set muntinBar(value: { vertQty: number; horzQty: number; value: string }) {
    if (this.cutout) {
      this.cutout.muntinBar = value;
    }
  }

  public get kit(): DoorLiteKit | DoorLouverKit {
    return (this.kits as Array<DoorLiteKit | DoorLouverKit>).first(k => k === this.cutout?.kit) ?? null;
  }
  public set kit(value: DoorLiteKit | DoorLouverKit) {
    if (this.cutout) {
      this.cutout.kit = value;
    }
  }
  get orderSizes(): CutoutOrderSize[] {
    return getEnumValues(CutoutOrderSize)
  }

  top = 16;
  right = 16;

  readonly horizontalModes = getEnumValues(HorizontalCutoutDimensionMode)
  readonly verticalModes = getEnumValues(VerticalCutoutDimensionMode)

  private destroyed$ = new Subject<void>();
  constructor(public doorElevationService: DoorElevationService) {
    this.#cutoutIndex.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if(this.cutout){
        if(this.showKit && !this.kit) this.kit = (this.kits as []).first()
      }
      else{
        this.muntinBar = null;
        this.kit = null;
      }
    })
  }

  updateValue(property: string, value: string | number): void {
    this.doorElevationService.updateCutoutProperty(this.cutoutIndex, property, applyUnitOfMeasure(value, this.doorElevationService.elevation.unitOfMeasure));
  }

  get cutouts(): Cutout[] {
    return this.doorElevationService.currentDoor?.doorType?.cutouts ?? [];
  }

  get showKit(): boolean {
    const liteWithInstallation =
      this.cutout?.name.searchFor('lite') &&
      [DoorGlassInstallation.CKI, DoorGlassInstallation.CKIR].any(x => this.doorElevationService.currentDoor?.glassInstallation === x);
    const louverWithInstallation =
      this.doorElevationService.currentDoor?.louverInstallation === DoorLouverInstallation.INST && this.cutout?.name.searchFor('louver');
    return liteWithInstallation || louverWithInstallation;
  }

  get kits(): DoorLiteKit[] | DoorLouverKit[] {
    if (
      !(
        this.cutout?.type?.searchFor('lite') ||
        this.doorElevationService.currentDoor?.style === DoorStyle.E4TL ||
        this.cutout?.type?.searchFor('louver')
      )
    )
      return [];
    return this.cutout?.type?.searchFor('lite') || this.doorElevationService.currentDoor?.style === DoorStyle.E4TL
      ? DoorLiteKits(!!this.doorElevationService.elevation.fireRating, this.doorElevationService.elevation.subType === DoorSubType.LL)
      : DoorLouverKits(!!this.doorElevationService.elevation.fireRating, LiteLouverOptions[this.doorElevationService.currentDoor.style as DoorStyle]?.louver); //.map(x => DoorLouverKit[x]);
  }

  center() {
    this.cutout?.center();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
