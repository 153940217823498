import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Subject } from 'rxjs'
import { distinctUntilChanged, takeUntil } from 'rxjs/operators'
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../../../core/interfaces/i-environment'
import { PreferenceService } from '../../../../core/services/preference.service'
import { DoorElevationFormGroup, DoorFormGroup } from '../../../models/door-elevation-form-group'
import { DoorElevationConfigService } from '../../../services/door-elevation-config-service/door-elevation-config.service'
import { DoorElevationCommonSettings } from '../door-elevation-common-settings'

@Component({
  selector: 'lib-door-elevation-single-settings',
  templateUrl: './door-elevation-single-settings.component.html',
  styleUrls: ['./door-elevation-single-settings.component.scss'],
  providers: []
})
export class DoorElevationSingleSettingsComponent extends DoorElevationCommonSettings implements OnInit, OnDestroy {
  _destroy$ = new Subject<void>()

  constructor(
    preferenceService: PreferenceService,
    route: ActivatedRoute,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment,
    public configService: DoorElevationConfigService
  ) {
    super(route, preferenceService)
  }

  ngOnInit(): void {
    this.doorElevationFormGroup = new DoorElevationFormGroup(this.configService.doorElevation, this.widthValidators)
    this.doorElevationFormGroup.doorFormGroups = [
      new DoorFormGroup(this.configService.doorElevation.doors[0], this.doorElevationFormGroup, null)
    ]
    this.doorElevationFormGroup.setupValidations()
    this.doorElevationFormGroup.wideInactive.patchValue(false)
    this.doorElevationFormGroup.doorFormGroups[0].width.valueChanges
      .pipe(takeUntil(this._destroy$), distinctUntilChanged())
      .subscribe(() => this.doorElevationFormGroup.width.patchValue(this.doorElevationFormGroup.doorFormGroups[0].width.value))
    this.doorElevationFormGroup.doorFormGroups[0].width.patchValue(
      this.doorElevationFormGroup.doorFormGroups[0].width.value
    )
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.unsubscribe()
    this.doorElevationFormGroup.destroy()
  }

  continue() {
    this.configService.doorElevation = this.doorElevationFormGroup.toElevation()
    this.configService.goNext()
  }
}
