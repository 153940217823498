import { Component, forwardRef, Inject, ViewChild } from '@angular/core'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'
import { IPrepCode } from '../../../../core/interfaces/i-prep-code'
import { IPrep } from '../../../../core/interfaces/i-prep'

@Component({
  selector: 'lib-dutch-door-shelves-prep',
  templateUrl: './dutch-door-shelves-prep.component.html',
  styleUrls: ['./dutch-door-shelves-prep.component.scss', '../prep-base.component.scss']
})
export class DutchDoorShelvesPrepComponent extends PrepBaseComponent {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent
  public get code(): IPrepCode {
    return this.prep?.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }
  set prep(value: IPrep) {
    this.prepsComponent.configService.currentDoor.dutchDoorShelvesPrep = value
  }
  get prep(): IPrep {
    return this.prepsComponent.configService.currentDoor.dutchDoorShelvesPrep
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.DUTCH_DOOR_SHELVES)
    if (!this.prep) {
      this.prep = {} as IPrep
    }
    this.setSpecial()
  }

}
