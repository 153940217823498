import { Component, Inject } from '@angular/core'
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../../../core/interfaces/i-environment'
import { DoorElevationConfigService } from '../../../services/door-elevation-config-service/door-elevation-config.service'

@Component({
  selector: 'lib-door-elevation-mode-settings',
  templateUrl: './door-elevation-mode-settings.component.html',
  styleUrls: ['./door-elevation-mode-settings.component.scss']
})
export class DoorElevationModeSettingsComponent {
  constructor(
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment,
    public configService: DoorElevationConfigService
  ) {}
}
