import { CoreConstants } from '../../core/core.constants';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { CSSPropKey, FilteredType, guid } from '@oeo/common';
import { nameOf } from '@oeo/common';
import { uomSwitch } from '../../core/functions/uomSwitch';
import { parseFromXMLString } from '../../core/helpers/parse-from-xml-string';
import { Intersectable } from '../abstracts/intersectable';
import { Direction } from '../enums/direction';
import { Handing } from '../enums/handing';
import { DoorExport } from './exports/door-export';
import { FrameElevation } from './frame-elevation';
import { createSVGElement } from '../../core/helpers/svg-functions';

const doorClass = 'door';
const styles: Record<string, string> = {
  stroke: 'black',
  strokeWidth: '1px',
  strokeDasharray: '4px',
  fillOpacity: '0',
};
const doubleEgressStyles = {
  fillOpacity: '1',
  fill: 'white',
};

export class Door extends Intersectable {
  static intersectableName: string = 'Door';

  private _handing: Handing = Handing.RH;
  private _handingLine: SVGPolylineElement;

  get handings() {
    return [Handing.LH, Handing.LHR, Handing.RH, Handing.RHR];
  }

  get handing() {
    return this._handing;
  }
  set handing(value: Handing) {
    this._handing = value;
    this.drawHandingLines();
  }

  get imperialWidth() {
    return this.width.toDimension('frame', this.unitOfMeasure);
  }

  get imperialHeight() {
    return this.height.toDimension('frame', this.unitOfMeasure);
  }

  get topPoints() {
    return this.points
      .sort((a, b) => (a.y < b.y ? -1 : 1))
      .slice(0, 2)
      .sort((a, b) => (a.x < b.x ? -1 : 1));
  }

  get bottomPoints() {
    return this.points
      .sort((a, b) => (a.y < b.y ? 1 : -1))
      .slice(0, 2)
      .sort((a, b) => (a.x < b.x ? -1 : 1));
  }

  get rightPoints() {
    return this.points
      .sort((a, b) => (a.x < b.x ? 1 : -1))
      .slice(0, 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
  }

  get leftPoints() {
    return this.points
      .sort((a, b) => (a.x < b.x ? -1 : 1))
      .slice(0, 2)
      .sort((a, b) => (a.y < b.y ? -1 : 1));
  }

  static fromXML(value: string): DoorExport {
    const doc = parseFromXMLString(value);
    return {
      id: guid(),
      exportType: Door.intersectableName,
      handing: Intersectable.parseXML(
        doc,
        nameOf((_: Door) => _.handing)
      ) as Handing,
      points: JSON.parse(
        Intersectable.parseXML(
          doc,
          nameOf((_: Door) => _.points)
        )
      ),
    };
  }

  constructor(frameElevation: FrameElevation, container: SVGGElement, x: number, y: number, editable: boolean = true) {
    super(frameElevation, container, doorClass, editable);
    for (const key in styles) {
      this.element.style[key as CSSPropKey] = styles[key];
    }
    for (let i = 0; i < 4; i++) {
      this.element.points.appendItem(this.svg.transformPoint(x, y));
    }
    this.fill();
    this.drawHandingLines();
  }

  static fromJSON(
    frameElevation: FrameElevation,
    container: SVGGElement,
    json: DoorExport,
    editable: boolean = true
  ): Door {
    const door = new Door(frameElevation, container, 0, 0, editable);
    for (let i = 0; i < json.points.length; i++) {
      door.points[i].x = json.points[i].x;
      door.points[i].y = json.points[i].y;
    }
    door.handing = json.handing;
    return door;
  }

  protected beforeMoveUp(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected beforeMoveDown(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected beforeMoveRight(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected beforeMoveLeft(points: Partial<DOMPoint>[], sticky: boolean) {}
  protected afterMoveUp(points: Partial<DOMPoint>[], sticky: boolean) {
    this.drawHandingLines();
  }

  protected afterMoveDown(points: Partial<DOMPoint>[], sticky: boolean) {
    this.drawHandingLines();
  }

  protected afterMoveRight(points: Partial<DOMPoint>[], sticky: boolean) {
    this.drawHandingLines();
  }

  protected afterMoveLeft(points: Partial<DOMPoint>[], sticky: boolean) {
    this.drawHandingLines();
  }

  flipOverCenter() {
    super.flipOverCenter();
    this.handing = this.getFlippedHanding();
    this.drawHandingLines();
  }

  private getFlippedHanding() {
    switch (this.handing) {
      case Handing.LH:
        return Handing.RH;
      case Handing.RH:
        return Handing.LH;
      case Handing.LHR:
        return Handing.RHR;
      case Handing.RHR:
        return Handing.LHR;
    }
  }

  isValid() {
    const minLength = uomSwitch('6"', 'frame', this.unitOfMeasure);
    if (this.width < minLength) {
      return false;
    }
    if (this.height < minLength) {
      return false;
    }
    return true;
  }

  destroy() {
    this._handingLine.remove();
    this._handingLine = null;
    this.element.remove();
    this.element = null;
    this.container = null;
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  toJSON(): DoorExport {
    return {
      id: this.id,
      exportType: Door.intersectableName,
      points: this.points.map(pt => ({ x: pt.x, y: pt.y })),
      handing: this.handing,
    };
  }

  toXML(): string {
    return `
    <category id="Opening">
      <option id="Opening">
        <property id="Height">${
          this.unitOfMeasure === UnitOfMeasure.Imperial
            ? this.height / CoreConstants.multipliers.frameElevation
            : this.height
        }</property>
        <property id="Width">
        ${
          this.unitOfMeasure === UnitOfMeasure.Imperial
            ? this.width / CoreConstants.multipliers.frameElevation
            : this.width
        }
        </property>
        <property id="Points">${JSON.stringify(this.points.map(p => ({ x: p.x, y: p.y })))}</property>
        <property id="Handing">${this.handing}</property>
      </option>
    </category>`;
  }

  private drawHandingLines() {
    if (this._handingLine) {
      this._handingLine.remove();
    }
    const midPoint = {
      x: this._handing === Handing.RH || this._handing === Handing.RHR ? this.rightPoints[0].x : this.leftPoints[0].x,
      y: (Math.max(...this.bottomPoints.map(pt => pt.y)) + Math.min(...this.topPoints.map(pt => pt.y))) / 2,
    };
    const topPoint =
      this._handing === Handing.RH || this._handing === Handing.RHR ? this.topPoints[0] : this.topPoints[1];
    const bottomPoint =
      this._handing === Handing.RH || this._handing === Handing.RHR ? this.bottomPoints[0] : this.bottomPoints[1];
    this._handingLine = createSVGElement('polyline');
    for (const key in styles) {
      this._handingLine.style[key as FilteredType<CSSStyleDeclaration, string>] = styles[key];
    }
    this._handingLine.classList.add(doorClass);
    this._handingLine.points.appendItem(topPoint);
    this._handingLine.points.appendItem(this.svg.getPoint(midPoint.x, midPoint.y));
    this._handingLine.points.appendItem(bottomPoint);
    this.drawHandingLine(bottomPoint);
    this.element.insertAdjacentElement('afterend', this._handingLine);
  }

  private drawHandingLine(point: Partial<DOMPoint>) {
    const handingLength = [
      (this.unitOfMeasure === UnitOfMeasure.Imperial ? '12"' : '305mm').fromDimension('frame', this.unitOfMeasure),
      this.width,
    ].min(_ => _);
    switch (this.handing) {
      case Handing.LHR:
        const leftPoint = this._handingLine.points.appendItem(this.svg.getPoint(point.x - this.width, point.y));
        this._handingLine.points.appendItem(
          this.svg.getPoint(
            leftPoint.x + handingLength * Math.cos(Math.PI / 6),
            leftPoint.y + handingLength * Math.sin(Math.PI / 6)
          )
        );
        this._handingLine.points.appendItem(
          this.svg.getPoint(
            leftPoint.x + handingLength * Math.cos(Math.PI / 6),
            leftPoint.y + handingLength * Math.sin(Math.PI / 6) - handingLength / 5
          )
        );
        break;
      case Handing.RHR:
        const rightPoint = this._handingLine.points.appendItem(this.svg.getPoint(point.x + this.width, point.y));
        this._handingLine.points.appendItem(
          this.svg.getPoint(
            rightPoint.x + handingLength * Math.cos(Math.PI - Math.PI / 6),
            rightPoint.y + handingLength * Math.sin(Math.PI - Math.PI / 6)
          )
        );
        this._handingLine.points.appendItem(
          this.svg.getPoint(
            rightPoint.x + handingLength * Math.cos(Math.PI - Math.PI / 6),
            rightPoint.y + handingLength * Math.sin(Math.PI - Math.PI / 6) - handingLength / 5
          )
        );
        break;
    }
  }

  private fill() {
    const arr = [
      {
        points: () => [this.points[0], this.points[1]],
        point: (pt: DOMPoint) => ({ x: pt.x, y: pt.y - 1 }),
        modifier: (pt: DOMPoint) => (pt.y -= 1),
        direction: Direction.Up,
      },
      {
        points: () => [this.points[1], this.points[2]],
        point: (pt: DOMPoint) => ({ x: pt.x + 1, y: pt.y }),
        modifier: (pt: DOMPoint) => (pt.x += 1),
        direction: Direction.Right,
      },
      {
        points: () => [this.points[2], this.points[3]],
        point: (pt: DOMPoint) => ({ x: pt.x, y: pt.y + 1 }),
        modifier: (pt: DOMPoint) => (pt.y += 1),
        direction: Direction.Down,
      },
      {
        points: () => [this.points[0], this.points[3]],
        point: (pt: DOMPoint) => ({ x: pt.x - 1, y: pt.y }),
        modifier: (pt: DOMPoint) => (pt.x -= 1),
        direction: Direction.Left,
      },
    ];
    for (const obj of arr) {
      while (obj.points().every(pt => !this.doesIntersect(obj.point(pt), obj.direction))) {
        obj.points().forEach(pt => obj.modifier(pt));
      }
    }
  }
}
