import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { DefaultStile, DefaultTopRail } from '../../constants/door-constants';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Lite } from '../../models/cutout-types/lite';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class FG3Type extends DoorType {
  cutouts: Cutout[];
  private readonly liteNames = ['Top Lite', 'Middle Lite', 'Bottom Lite'];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    const distanceFromTop = DefaultTopRail(this.door.doorElevation.unitOfMeasure);
    const height = this.getHeight();
    const railHeight = uomSwitch('6"', 'door', this.door.doorElevation.unitOfMeasure);
    this.cutouts = new Array(3).fill(null, 0, 3).map((_, i) => this.lite(height, railHeight, distanceFromTop, i));
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private lite(liteHeight: number, railHeight: number, distanceFromTop: number, i: number): Cutout {
    const width = this.getWidth();
    return new Lite(
      this.liteNames[i],
      this,
      (this.door.actualWidth - width) / 2,
      distanceFromTop + (liteHeight + railHeight) * i,
      width,
      liteHeight,
      i === 2 ? VerticalCutoutDimensionMode.Bottom : VerticalCutoutDimensionMode.Top,
      HorizontalCutoutDimensionMode.Lock
    );
  }

  private getWidth(): number {
    const widths = new Array(100)
      .fill(
        {
          key: uomSwitch(`2'`, 'door', this.door.doorElevation.unitOfMeasure),
          value: uomSwitch('11 25/32"', 'door', this.door.doorElevation.unitOfMeasure),
        },
        0,
        100
      )
      .map((obj, index) => ({
        key: obj.key + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
        value: obj.value + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
      }));
    for (let i = 1; i < widths.length; i++) {
      if (this.door.width < widths[i].key) {
        return widths[i - 1].value;
      }
    }
    return widths.last().value;
  }

  private getHeight(): number {
    const heights = new Array(100)
      .fill(
        {
          key: uomSwitch(`6' 8"`, 'door', this.door.doorElevation.unitOfMeasure),
          value: uomSwitch('15 7/8"', 'door', this.door.doorElevation.unitOfMeasure),
        },
        0,
        100
      )
      .map((obj, index) => ({
        key: obj.key + uomSwitch('2"', 'door', this.door.doorElevation.unitOfMeasure) * index,
        value: obj.value + uomSwitch('21/32"', 'door', this.door.doorElevation.unitOfMeasure) * index,
      }));
    for (let i = 1; i < heights.length; i++) {
      if (this.door.height < heights[i].key) {
        return heights[i - 1].value;
      }
    }
    return heights.last().value;
  }
}
