import { debounceTime, takeUntil } from 'rxjs';
import { uomSwitch } from '../../../../core/functions/uomSwitch';
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location';
import { IPrep } from '../../../../core/interfaces/i-prep';
import { FramePrepReferencePoint } from '../../../enums/frame-prep-reference-point';
import { StickSubtype } from '../../../enums/stick-subtype';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { FilteredKeys } from '@oeo/common'
import { FrameElevation } from '../../../models/frame-elevation';

type JunctionBoxPrepKeys = FilteredKeys<FrameElevation, `junctionBox${string}` >

export abstract class JunctionBoxPrepComponent extends PrepBaseComponent {
  abstract framePrepComponent: FramePrepComponent;
  abstract subTypes: StickSubtype[];

  private _code: IPrepCode;
  get code(): IPrepCode {
    return this._code;
  }
  set code(value: IPrepCode) {
    this._code = value;
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    if (this.prepsComponent.data?.frame.prepLocationPreference && !this.location){
      this.location = this.locations.first(({value}) => value === this.prepsComponent.data.frame.prepLocationPreference);
    }
    this.updateValue();
  }

  locations: IPrepCategoryLocation[];
  private _location: IPrepCategoryLocation;
  get location(): IPrepCategoryLocation {
    return this._location;
  }

  set location(value: IPrepCategoryLocation) {
    this._location = value;
    this.updateValue();
  }

  private _referencePoint: FramePrepReferencePoint;
  get referencePoint(): FramePrepReferencePoint {
    return this._referencePoint;
  }
  set referencePoint(value: FramePrepReferencePoint) {
    this._referencePoint = value;
    this.updateValue();
  }

  get prep(): IPrep {
    return this.frameElevation[this.framePrepCodeCategory];
  }
  set prep(value: IPrep) {
    this.frameElevation[this.framePrepCodeCategory] = value;
  }

  constructor(
    public name: string,
    prepsComponent: PrepsComponent,
    private framePrepCodeCategory: JunctionBoxPrepKeys,
    prepCategoryId: number,
    public referencePoints: FramePrepReferencePoint[]
  ) {
    super(prepsComponent, prepCategoryId);
    if (!this.prep) {
      this.prep = {} as IPrep;
    }
    this._code = this.specialPrepCode;
    this.locations = prepsComponent.configService.prepCategories
      .first(c => c.id === prepCategoryId)
      .prepCategoryLocations.concat([this.specialPrepLocation]);
    this._location = this.locations.first(c => c.value === this.prep.locationType) ?? null;
    this._referencePoint = this.referencePoints.first(c => c === this.prep.referencePoint) ?? null;
    this.setSpecial();
  }

  onInit(): void {}

  afterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  onDestroy(): void {
    super.destroy();
  }

  updateValue(): void {
    this.prep.id = this.code?.id;
    this.prep.code = this.code?.code || null;
    this.prep.pricingCategory = this.code?.pricingCategory;
    this.prep.locationType = this.location?.value;
    this.prep.fixedLocation = this.code?.fixedLocation;
    this.prep.referencePoint = this.referencePoint;
    this.prep.location = this.code?.fixedLocation
      ? this.code.standardLocation
      : this.prep.location ?? uomSwitch('0', 'frame', this.unitOfMeasure).toDimension('frame', this.unitOfMeasure);
    this.draw$.next();
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (this.prep.locationType !== this.specialPrepLocation.value) {
      this.framePrepComponent.resetAndFit();
      return;
    }
    this.prepLocationInputs.push(this.drawPrep(0, this.prep));
  }
}
