import { Component, OnInit } from '@angular/core';
import { IDialog } from '../../../core/interfaces/i-dialog';
import { IElevation } from '../../../core/interfaces/i-elevation';
import { DialogService } from '../../../core/services/dialog.service';
import { RestService } from '../../../core/services/rest.service';
import { FolderService } from '../../../core/services/folder.service';
import { Folder } from '../../../core/models/folder';
import { PdfService } from '../../../core/services/pdf.service';
import { ExperlogixService } from '../../../core/services/experlogix.service';
import { mergeMap } from 'rxjs/operators';
import { BehaviorSubject, forkJoin } from 'rxjs';

@Component({
  selector: 'lib-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss'],
})
export class ActionDialogComponent
  implements OnInit, IDialog<{ elevation: IElevation; service: RestService<IElevation> }>
{
  data: { elevation: IElevation; service: RestService<IElevation> };
  actions: string[] = [];
  action: string;
  name: string;
  folders: Folder[] = [];
  folder: Folder;
  fileType: 0 | 1 = 0;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public dialogService: DialogService,
    private folderService: FolderService,
    private pdfService: PdfService,
    private experlogixService: ExperlogixService
  ) { }

  ngOnInit(): void {
    this.name = this.data.elevation.name;
    this.folderService.search({}).subscribe(folders => {
      this.folders = folders;
      this.folder = this.folders.first(f => f.id === this.data.elevation.folderId);
    });
    this.actions = !this.data.elevation.id
      ? [ 'save', 'download' ]
      : [ 'update', 'duplicate', 'download' ];
    this.action = this.actions.first();
  }

  save (): void {
    if (this.data.elevation.id) {
      this.update();
    } else {
      this.create();
    }
  }

  update () {
    this.loading$.next(true)
    this.experlogixService
      .price(this.data.elevation.configuration)
      .pipe(
        mergeMap((configuration: string) =>
          this.data.service.update(this.data.elevation.id, {
            name: this.name,
            configuration,
            svg: this.data.elevation.svg.prepareForExport(40, 80),
            folderId: `${this.folder.id}`,
          })
        ),
        mergeMap(() => this.data.service.search({}))
      )
      .subscribe(() => {
        this.data.elevation.name = this.name;
        this.dialogService.close(this.data.elevation);
        this.loading$.next(false)
      });
  }

  create(): void {
    this.loading$.next(true)
    this.experlogixService
      .price(this.data.elevation.configuration)
      .pipe(
        mergeMap(configuration =>
          forkJoin([
            this.data.service.create({
              name: this.name,
              configuration,
              folderId: this.folder.id,
              svg: this.data.elevation.svg.prepareForExport(40, 80)
            }),
            this.data.service.search({})
          ])
        ),
      )
      .subscribe(([elevation]: [IElevation, IElevation[]]) => {
        this.dialogService.close(Object.assign(this.data.elevation, elevation))
        this.loading$.next(false)
      });
  }

  download(): void {
    this.loading$.next(true)
    this.experlogixService
      .price(this.data.elevation.configuration)
      .pipe(mergeMap(configuration => this.pdfService.download(configuration, this.fileType)))
      .subscribe(file => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(new Blob([file]));
        a.download = `${this.name}.${this.fileType === 0 ? 'pdf' : 'docx'}`;
        document.body.append(a);
        a.click();
        a.remove();
        this.loading$.next(false)
      });
  }
}
