import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../interfaces/i-environment';

@Injectable()
export class LookupsService {
  constructor(private httpClient: HttpClient, @Inject(ENVIRONMENT_INJECTION_TOKEN) private environment: IEnvironment) {}

  getAll(id: number) {
    return this.httpClient.get<string[]>(`${this.environment.elevations.apiUrl}v1/lookups/${id}`);
  }
}
