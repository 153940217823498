export const ENVIRONMENT_INJECTION_TOKEN = 'environment';

export interface IEnvironment {
  production: boolean;
  elevations: {
    apiUrl: string;
    blobUrl: string;
  };
  flags?: {
    speccy: boolean;
    hideFrameElevation?: boolean;
    hideDoorElevation?: boolean;
    hideFrameElevationPricing?: boolean;
  };
}
