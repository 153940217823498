import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../../../environments/environment'
import { Observable, BehaviorSubject } from 'rxjs'
import { CustomerProfile } from './customer-profile.model'
import { HelperService } from '../../../services/helper/helper.service'

@Injectable({
  providedIn: 'root'
})
export class CustomerProfileService {
  private url: string = environment.onlineOrderingApiUrl + 'Customers/Profile'

  private _profile: BehaviorSubject<CustomerProfile> = new BehaviorSubject<CustomerProfile>(null)
  public profile: Observable<CustomerProfile> = this._profile.asObservable()

  constructor(private http: HttpClient, public helperService: HelperService) {
    this.getProfile()
  }

  private getProfile(): void {
    this.http.get(this.url).subscribe((profile: CustomerProfile) => {
      this._profile.next(profile)
    })
  }

  uploadLogo(file: Blob) {
    const contentType = file.type
    let headers = new HttpHeaders()
    headers = headers.set('content-type', contentType)
    const url = this.url + '/Logo'

    const reader = new FileReader()
    reader.onload = (event) => {
      return this.http.post(url, event.target.result, { headers: headers }).subscribe({
        next: () => {
          this.getProfile()
        },
        error: (error) => {
          if (error.status === 415) {
            this.helperService.handleError('File must be a JPEG or PNG')
          } else {
            this.helperService.handleError(error.message)
          }
          this.getProfile()
        }
      })
    }
    reader.readAsArrayBuffer(file)
  }

  updateTerms(terms: string) {
    const payload = { purchaseOrderTerms: terms }
    this.http.patch(this.url, payload).subscribe(
      () => {
        this.getProfile()
      },
      (error) => {
        this.helperService.handleError(error.message)
        this.getProfile()
      }
    )
  }
}
