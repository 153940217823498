<div class="page-header" [ngClass]="{ loading: loading }">
  <h1>{{ 'notification' | translate }}</h1>
</div>

<mat-card *ngIf="!loading">
  <mat-card-header>
    <h2 mat-card-title>{{ announcement.name }}</h2>
    <mat-card-subtitle>{{ announcement.lastModified | date: 'MM/dd/yyyy' }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngFor="let announcementDetail of announcement.announcementDetails">
      <markdown
        markdown
        *ngIf="announcementDetail.language === language"
        [data]="announcementDetail?.detail"
      ></markdown>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <a mat-button [routerLink]="['../']">{{ 'ACTIONS.close' | translate }}</a>
  </mat-card-actions>
</mat-card>
