import { Profile } from '../abstracts/profile';
import { ProfileTemplate } from '../abstracts/profile-template';
import { Stick } from '../models/stick';
import { FDRCS } from './f-series/fdrcs';
import { FDROS } from './f-series/fdros';
import { FSRCS } from './f-series/fsrcs';
import { FSROS } from './f-series/fsros';
import { FCCS } from './f-series/fccs';
import { FCOS } from './f-series/fcos';
import { GlassExport } from '../models/exports/glass-export';

export class MESeries extends Profile {
  constructor(stick: Stick, points: Partial<DOMPoint>[]) {
    super(stick, points);
  }

  protected doubleRabbetClosedSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FDRCS(this, glazingBeads);
  }

  protected doubleRabbetOpenSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FDROS(this, glazingBeads);
  }

  protected singleRabbetClosedSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FSRCS(this, glazingBeads);
  }

  protected singleRabbetOpenSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FSROS(this, glazingBeads);
  }

  protected casedClosedSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FCCS(this, glazingBeads);
  }

  protected casedOpenSection(glazingBeads: GlassExport[]): ProfileTemplate {
    return new FCOS(this, glazingBeads);
  }

  protected doubleEgressSection(): ProfileTemplate {
    throw new Error('Profile not valid for this series');
  }
}
