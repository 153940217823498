<lib-dialog
  [header]="'TITLE.validations' | translate"
  [closeable]="false"
  libMoveable
  [(top)]="top"
  [(left)]="left"
  [zIndex]="5"
  moveableElementName="mat-card-header"
>
  <img src="./assets/speccy.gif" *ngIf="environment.flags.speccy" />
  <div class="message" *ngIf="messages?.length === 0">
    <mat-icon ngClass="level0" svgIcon="check"></mat-icon>
    <p>{{ 'everythingLooksGood' | translate }}</p>
  </div>
  <div class="message-container">
    <div class="message" *ngFor="let message of messages">
      <mat-icon [ngClass]="{ level2: message.level === 2, level3: message.level === 3 }"
        ><span class="material-symbols-outlined"> warning </span></mat-icon
      >
      <p>{{ message.value | translate }}</p>
    </div>
  </div>
</lib-dialog>
