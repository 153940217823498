<lib-dialog
  header="Glass Information"
  [closeable]="true"
  (close)="close()"
  *ngIf="glass"
  libMoveable
  [(top)]="top"
  [(right)]="right"
  [zIndex]="5"
  moveableElementName="mat-card-header"
>
  <form [formGroup]="formGroup" autocomplete="off">
    <div class="form-fields">
      <mat-form-field class="no-hint">
        <mat-label>{{'height' | translate}}</mat-label>
        <input matInput [formControl]="height" unitOfMeasure="Imperial"/>
      </mat-form-field>
      <mat-form-field class="no-hint">
        <mat-label>{{'width' | translate}}</mat-label>
        <input matInput [formControl]="width" libApplyMeasure unitOfMeasure="Imperial"/>
      </mat-form-field>
    </div>
  </form>
  <div class="row">
    <div class="col">
      <span class="section-header sub">{{'type' | translate }}</span>
      <mat-radio-group [(ngModel)]="glass.type">
        <mat-radio-button *ngFor="let t of types" [value]="t" color="primary">{{ t }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</lib-dialog>
