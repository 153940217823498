import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { merge } from 'lodash';
import { Subject, distinctUntilChanged, mergeMap, takeUntil } from 'rxjs';
import { AdditionalLabel } from '../../../core/enums/additional-label';
import { Approval, filterApprovals } from '../../../core/enums/approval';
import { PrepLocation } from '../../../core/enums/prep-location';
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { convertMeasurement } from '../../../core/functions/convertMeasurement';
import { getEnumValues, nameOf } from '@oeo/common';
import { objectKeyExtractor } from '@oeo/common';
import { IDialog } from '../../../core/interfaces/i-dialog';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../../core/interfaces/i-environment';
import { IPrep } from '../../../core/interfaces/i-prep';
import { DialogService } from '../../../core/services/dialog.service';
import { ProductService } from '../../../core/services/door-product.service';
import { PreferenceService } from '../../../core/services/preference.service';
import { measurementValidators } from '../../../core/validators/measurementValidator';
import { TemplateTypes } from '../../constants/template-types';
import { FireRating } from '../../enums/fire-rating';
import { FrameCornerAssembly } from '../../enums/frame-corner-assembly';
import { FrameCornerCondition } from '../../enums/frame-corner-condition';
import { FrameDoorThickness } from '../../enums/frame-door-thickness';
import { FrameJambDepth } from '../../enums/frame-jamb-depth';
import { FrameMaterial } from '../../enums/frame-material';
import { FrameMetalMylar } from '../../enums/frame-metal-mylar';
import { FrameSeries, FrameSeriesInfos } from '../../enums/frame-series';
import { GlazingBeadLocation } from '../../enums/glazing-bead-location';
import { GlazingBeadSize } from '../../enums/glazing-bead-size';
import { TemplateType } from '../../enums/template-type';
import { FrameElevationExport } from '../../models/exports/frame-elevation-export';
import { FrameElevation } from '../../models/frame-elevation';
import { FrameGauge } from '../../enums/frame-gauge';

interface IFrameElevationSettingsData{ properties: { [key: string]: boolean }; elevation: FrameElevation, isNew: boolean, step: number}
@Component({
  selector: 'lib-frame-elevation-settings',
  templateUrl: './frame-elevation-settings.component.html',
  styleUrls: ['./frame-elevation-settings.component.scss'],
  providers: [PreferenceService],
})
export class FrameElevationSettingsComponent implements OnInit, OnDestroy, IDialog<IFrameElevationSettingsData> {
  header = 'Settings';
  private _destroy$ = new Subject<void>();
  data: IFrameElevationSettingsData;
  readonly formGroup: FormGroup = new FormGroup({});
  saveAsPreference: boolean;

  #step = 0;
  set step(value: number) {
    this.#step = value;
    if (this.step === 0) {
      this.header = this.data.isNew ? 'Frame Default Settings' : 'Frame Settings';
    } else {
      this.header = 'Frame Preps';
    }
  }
  get step() {
    return this.#step;
  }

  #elevation: FrameElevation = new FrameElevation()
  get elevation() {
    return this.#elevation;
  }
  set elevation(elevation: FrameElevation) {
    this.#elevation = merge(this.elevation, elevation);
  }

  get measurementPlaceholder() {
    return this.unitOfMeasure?.value === UnitOfMeasure.Imperial ? '3\' 4"' : '914mm';
  }

  get height(): AbstractControl<string> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.height));
  }

  get width(): AbstractControl<string> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.width));
  }

  readonly serieses = getEnumValues(FrameSeries)
    .orderBy(_ => _);

  get series(): AbstractControl<FrameSeries> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.series));
  }

  readonly commonJambDepths = [
    FrameJambDepth._534,
    FrameJambDepth._578,
    FrameJambDepth._634,
    FrameJambDepth._734,
    FrameJambDepth._834,
  ];
  readonly jambDepths = getEnumValues(FrameJambDepth)

  get jambDepth(): AbstractControl<FrameJambDepth> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.jambDepth));
  }

  get templateTypes(): Array<TemplateType>{
    return this.series?.value == null
      ? []
      : TemplateTypes.filter(
          t => FrameSeriesInfos[this.series.value].allowedTemplates?.includes(t.type) ?? true
        )
          .map(t => t.type)
          .orderBy(_ => _);
  }

  get templateType(): AbstractControl<TemplateType> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.templateType));
  }

  get materials() {
    return FrameSeriesInfos[this.series?.value]?.materials ?? [];
  }

  get material(): AbstractControl<FrameMaterial> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.material));
  }

  get cornerAssemblies(): FrameCornerAssembly[] {
    return getEnumValues(FrameCornerAssembly)
      .filter(x =>
        this.cornerCondition.value === FrameCornerCondition.BTH ||
        this.cornerCondition.value === FrameCornerCondition.BTJ
          ? x !== FrameCornerAssembly.KD
          : true
      )
      .filter(x => (this.fireRating.value ? x === FrameCornerAssembly.WD || x === FrameCornerAssembly.WFP : true));
  }

  get cornerAssembly(): AbstractControl<FrameCornerAssembly> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.cornerAssembly));
  }

  readonly cornerConditions = getEnumValues(FrameCornerCondition);

  get cornerCondition(): AbstractControl<FrameCornerCondition> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.cornerCondition));
  }

  readonly unitOfMeasures = [UnitOfMeasure.Imperial];

  get unitOfMeasure(): AbstractControl<UnitOfMeasure> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.unitOfMeasure));
  }

  get kerf(): AbstractControl<boolean> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.kerf));
  }

  get glazingBeadSizes() {
    return FrameSeriesInfos[this.series?.value]?.glazingBeadSizes ?? [];
  }

  get glazingBeadSize(): AbstractControl<GlazingBeadSize> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.glazingBeadSize));
  }

  readonly glazingBeadLocations = getEnumValues(GlazingBeadLocation)
    .filter(x => x !== GlazingBeadLocation.CenterGlazed);

  get glazingBeadLocation(): AbstractControl<GlazingBeadLocation> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.glazingBeadLocation));
  }

  get glassThickness(): AbstractControl<string> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.glassThickness));
  }

  readonly doorThicknesses = getEnumValues(FrameDoorThickness);

  get doorThickness(): AbstractControl<FrameDoorThickness> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.doorThickness));
  }

  readonly fireRatings = getEnumValues(FireRating);

  get fireRating(): AbstractControl<FireRating> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.fireRating));
  }

  readonly metalMylars = getEnumValues(FrameMetalMylar);

  get metalMylar(): AbstractControl<FrameMetalMylar> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.metalMylar));
  }

  get gauges() {
    return FrameSeriesInfos[this.series?.value]?.gauges ?? [];
  }

  get gauge(): AbstractControl<FrameGauge> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.gauge));
  }

  get prepLocationPreference(): AbstractControl<PrepLocation> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.prepLocationPreference));
  }

  get prepLocationPreferences() {
    return Object.values(PrepLocation);
  }

  readonly additionalLabels = [
    AdditionalLabel.E330,
    AdditionalLabel.BULLET,
    AdditionalLabel.TDI,
    AdditionalLabel.FBC,
    AdditionalLabel.SMOKE,
  ];

  get additionalLabel(): AbstractControl<AdditionalLabel> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.additionalLabel));
  }

  get approvals() {
    return filterApprovals(this.additionalLabel.value);
  }

  get approval(): AbstractControl<Approval> {
    return this.formGroup.get(nameOf((_: FrameElevation) => _.approval));
  }

  constructor(
    public dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private preferenceService: PreferenceService,
    public readonly productService: ProductService,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment
  ) {}

  ngOnInit(): void {
    this.step = this.data.step;

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.unitOfMeasure),
      new FormControl(
        {
          value: this.data.elevation.unitOfMeasure,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.unitOfMeasure)],
        },
        [Validators.required]
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.width),
      new FormControl(
        {
          value: this.data.elevation.width?.toDimension('frame', this.unitOfMeasure.value) ?? null,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.width)],
        },
        measurementValidators(this.unitOfMeasure.value)
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.height),
      new FormControl(
        {
          value: this.data.elevation.height?.toDimension('frame', this.unitOfMeasure.value) ?? null,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.height)],
        },
        measurementValidators(this.unitOfMeasure.value)
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.doorThickness),
      new FormControl(this.data.elevation.doorThickness, [Validators.required])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.glassThickness),
      new FormControl(
        {
          value: this.data.elevation.glassThickness?.toDimension('frame', this.unitOfMeasure.value) ?? null,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.glassThickness)],
        },
        measurementValidators(this.unitOfMeasure.value)
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.fireRating),
      new FormControl(
        {
          value: this.data.elevation.fireRating ?? null,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.fireRating)],
        },
        []
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.additionalLabel),
      new FormControl(
        {
          value: this.data.elevation.additionalLabel ?? null,
          disabled: false,
        },
        []
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.approval),
      new FormControl(
        {
          value: this.data.elevation.approval ?? null,
          disabled: !this.additionalLabel.value,
        },
        [Validators.required]
      )
    );

    this.additionalLabel.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      if (this.additionalLabel.value) {
        this.approval.enable();
      } else {
        this.approval.patchValue(null);
        this.approval.disable();
      }
      this.updateForm(this.approval, this.approval.value);
      this.setMetalMylar();
    });

    this.fireRating.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.setMetalMylar();
      if (!this.cornerAssemblies.includes(this.cornerAssembly.value)) {
        this.updateForm(this.cornerAssembly, null);
      }
    });

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.metalMylar),
      new FormControl(
        {
          value: this.data.elevation.metalMylar ?? null,
          disabled: !this.fireRating.value,
        },
        [Validators.required]
      )
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.templateType),
      new FormControl(
        {
          value: this.data.elevation.templateType ?? null,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.templateType)],
        },
        []
      )
    );

    this.templateType.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((templateType: TemplateType) => this.setKerf());

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.series),
      new FormControl(
        {
          value: this.data.elevation.series,
          disabled: this.data.properties[nameOf((_: FrameElevation) => _.templateType)],
        },
        [Validators.required]
      )
    );

    this.series.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((series: FrameSeries) => {
      this.templateType.setValidators(FrameSeriesInfos[series]?.allowedTemplates ? [Validators.required] : []);
      if (!this.templateTypes.any(t => t === this.templateType.value)) {
        this.templateType.setValue(null);
      }
      this.updateForm(this.templateType, this.templateType.value);

      this.setKerf();
      this.setMaterial();
    });

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.jambDepth),
      new FormControl(this.data.elevation.jambDepth, [Validators.required])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.material),
      new FormControl(this.data.elevation.material, [Validators.required])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.gauge),
      new FormControl(this.data.elevation.gauge, [Validators.required])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.prepLocationPreference),
      new FormControl(this.data.elevation.prepLocationPreference, [])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.cornerAssembly),
      new FormControl(this.data.elevation.cornerAssembly, [Validators.required])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.cornerCondition),
      new FormControl(this.data.elevation.cornerCondition, [Validators.required])
    );

    this.cornerCondition.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(() => {
      if (!this.cornerAssemblies.includes(this.cornerAssembly.value)) {
        this.updateForm(this.cornerAssembly, null);
      }
    });

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.kerf),
      new FormControl(
        {
          value: this.data.elevation.kerf ?? false,
          disabled: !this.data.properties[nameOf((_: FrameElevation) => _.series)],
        },
        []
      )
    );

    this.unitOfMeasure.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
      [this.height, this.width].forEach(control => {
        control.setValidators(measurementValidators(this.unitOfMeasure.value));
        this.updateForm(control, convertMeasurement(control.value, 'frame', this.unitOfMeasure.value));
      });
    });

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.glazingBeadSize),
      new FormControl(this.data.elevation.glazingBeadSize ?? null, [Validators.required])
    );

    this.formGroup.addControl(
      nameOf((_: FrameElevation) => _.glazingBeadLocation),
      new FormControl(this.data.elevation.glazingBeadLocation ?? null, [Validators.required])
    );
    this.validateGlassOptions();

    this.glazingBeadSize.valueChanges.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe(() => {
      this.validateGlassOptions();
    });
  }

  required(control: AbstractControl): boolean {
    return !!control.validator && control.validator({} as AbstractControl).required
  }

  validateGlassOptions() {
    if (this.glazingBeadSize.value === GlazingBeadSize.OMIT) {
      this.glazingBeadLocation.disable();
      this.updateForm(this.glazingBeadLocation, null);
      this.glassThickness.disable();
      this.updateForm(this.glassThickness, null);
    } else {
      this.glazingBeadLocation.enable();
      this.glassThickness.enable();
    }
  }

  setMetalMylar() {
    if (
      this.additionalLabel.value === AdditionalLabel.TDI ||
      this.additionalLabel.value === AdditionalLabel.FBC ||
      !!this.fireRating.value
    ) {
      this.metalMylar.enable();
    } else {
      this.metalMylar.disable();
    }
    this.updateForm(this.metalMylar, null);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  continue() {
    const frameElevationPreps = objectKeyExtractor<FrameElevation, IPrep>(this.data.elevation, /Prep/).filter((prepKey) => !!this.data.elevation[prepKey])
    if (!this.formGroup.invalid) {
      const json: FrameElevationExport = {
        unitOfMeasure: this.unitOfMeasure.value,
        height: (this.height.value).fromDimension('frame', this.unitOfMeasure.value),
        width: (this.width.value).fromDimension('frame', this.unitOfMeasure.value),
        templateType: this.templateType.value,
        series: this.series.value,
        jambDepth: this.jambDepth.value,
        material: this.material.value,
        cornerAssembly: this.cornerAssembly.value,
        cornerCondition: this.cornerCondition.value,
        kerf: this.kerf.value,
        glazingBeadLocation: this.glazingBeadLocation.value,
        glazingBeadSize: this.glazingBeadSize.value,
        glassThickness: (this.glassThickness.value)?.fromDimension('frame', this.unitOfMeasure.value),
        doorThickness: this.doorThickness.value,
        fireRating: this.fireRating.value,
        metalMylar: this.metalMylar.value,
        intersectables: undefined,
        additionalLabel: this.additionalLabel.value,
        approval: this.approval.value,
        gauge: this.gauge.value,
        prepLocationPreference: this.prepLocationPreference.value,
        /* Copy Existing Preps */
        ...frameElevationPreps.reduce((existingPreps, prepKey) => {
          existingPreps[prepKey as keyof FrameElevationExport] = this.data.elevation[prepKey]
          return existingPreps
        }, {} as Record<keyof FrameElevationExport, any>)
      };
      this.elevation.update(json)
      if (this.saveAsPreference) {
        const estimateId = +this.route.parent.snapshot.params.estimateId;
        this.preferenceService
          .getByPreferenceTypeId(2, estimateId)
          .pipe(
            mergeMap(preference =>
              preference == null
                ? this.preferenceService.create({
                    configuration: this.elevation.toXML(),
                    preferenceTypeId: 2,
                    estimateId,
                  })
                : this.preferenceService.update(preference.id, { configuration: this.elevation.toXML() })
            )
          )
          .subscribe();
      }
      return this.dialogService.close(this.elevation.toElevationExport());
    } else {
      Object.keys(this.formGroup.controls).forEach(name => this.formGroup.controls[name].markAsTouched());
    }
  }

  goBack() {
    return this.router.navigate(['../'], { relativeTo: this.route });
  }

  updateForm<T>(control: AbstractControl<T>, value: T): void {
    control.patchValue(value);
    control.markAsTouched();
    control.updateValueAndValidity();
  }

  private setKerf() {
    if (FrameSeriesInfos[this.series.value]?.kerf?.includes(this.templateType.value)) {
      this.kerf.enable();
    } else {
      this.kerf.setValue(false);
      this.kerf.disable();
    }
    this.updateForm(this.kerf, this.kerf.value);
  }

  private setMaterial() {
    if (!FrameSeriesInfos[this.series.value]?.materials.includes(this.material.value)) {
      this.material.setValue(null);
    }
    this.updateForm(this.material, this.material.value);
  }
}
