import { DoorFireRating } from './../door-fire-rating';
import { AdditionalLabel } from '../../../core/enums/additional-label';
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { DoorBaseData } from '../../rules/door-base-data';
import { DoorCore } from '../door-core';
import { DoorSeries } from '../door-series';
import { DoorStyle, DutchStyles } from '../door-style';
import { DoorSubType } from '../door-subtype';

export function DoorStyles(
  series: DoorSeries,
  subType: DoorSubType,
  unitOfMeasure: UnitOfMeasure,
  width: string,
  height: string,
  gauge: string,
  additionalLabel: AdditionalLabel,
  core: DoorCore,
  fireRating: DoorFireRating,
  doorQty: number,
) {
  if (!width || !height || !gauge) {
    return [];
  }
  let styles = new DoorBaseData().get(series, subType, unitOfMeasure, width, height, gauge) as DoorStyle[];
  styles = styles.any() ? styles : [...(DoorSeriesInfos[series]?.subTypes[subType]?.styles ?? [])];
  if (subType === DoorSubType.IMPEMB && additionalLabel !== AdditionalLabel.E330) {
    [...styles]
      .filter(style => style !== DoorStyle.E6 && style !== DoorStyle.E4TL)
      .forEach(style => styles.remove(style));
  }
  if (
    core === DoorCore.TEMP &&
    (subType === DoorSubType.EMB || subType === DoorSubType.IMPEMB || subType === DoorSubType.STCEMB)
  ) {
    [...styles]
      .filter(style => style !== DoorStyle.E6 && style !== DoorStyle.E4TL)
      .forEach(style => styles.remove(style));
  }
  if (fireRating === DoorFireRating._180) {
    [DoorStyle.FG, DoorStyle.FG2, DoorStyle.FG3].forEach((style)=>styles.remove(style))
  }

  /* Style limitations for fire Rated Pair Doors */
  if(fireRating) {
    [DoorStyle.FL2, DoorStyle.FLBG, DoorStyle.FLBN, DoorStyle.FLV, DoorStyle.FLVC].forEach((style)=>styles.remove(style))
    if (doorQty === 2){
      DutchStyles.forEach((style)=>styles.remove(style))
    }
    if (doorQty === 1 && fireRating === DoorFireRating._180){
      [DoorStyle.DG, DoorStyle.DV, DoorStyle.DN].forEach((style)=>styles.remove(style))
    }
  }
  return styles;
}
