import { Subject } from 'rxjs';
import { UnitOfMeasure } from '../../core/enums/unit-of-measure';
import { DimensionInputMaker } from '../../core/helpers/dimension-input-maker';
import { IDimension } from '../../core/interfaces/i-dimension';
import { profileTextStyles } from '../constants/text-styles';
import { Direction } from '../enums/direction';
import { convertImperialMetricEnum } from '../../core/functions/convertJambDepth';
import { GlazingBeadLocation } from '../enums/glazing-bead-location';
import { GlassExport } from '../models/exports/glass-export';
import { Profile } from './profile';
import { GlazingBeadSize } from '../enums/glazing-bead-size';
import { createSVGElement, setStyles, setY2, setY1, setX2, setX1, setInnerHTML, appendToContainerElement, setX, setY } from '../../core/helpers/svg-functions';
import { pipe } from 'lodash/fp';
import { CSSPropKey } from '@oeo/common';

export enum ProfileTemplateDimensionPosition {
  MinY,
  MaxY,
  MinX,
  MaxX,
  Above,
  Below,
  Right,
  Left,
}

const polylineStyles: Record<string, string> = {
  fill: 'none',
  stroke: 'black',
  strokeWidth: '1px',
  strokeLinejoin: 'round',
};

const lineStyles: Record<string, string> = {
  stroke: '#CACACA',
  strokeWidth: '1px',
};

const dimensionStyles: Record<string, string> = {
  strokeDasharray: '0.5px',
};

export abstract class ProfileTemplate implements IDimension {
  static readonly charWidth = 12;
  static readonly charHeight = 16;

  private _inputWidth = 56;
  private _inputHeight = 28;
  private _dimensionInputMaker = new DimensionInputMaker(this._inputWidth, this._inputHeight);
  private _destroy$ = new Subject<void>();
  private readonly _lineDistance = 20;
  private readonly _update$ = new Subject<void>();
  readonly dimensionType = 'frame';
  private readonly _multiplier = 4;
  private _hasChanged = false;

  protected get _glazingBeadWidth() {
    return this.profile.stick.frameElevation.glazingBeadSize === GlazingBeadSize.OMIT
      ? 0
      : this.profile.stick.frameElevation.glazingBeadSize
          .split('x')
          .last()
          .fromDimension('frame', UnitOfMeasure.Imperial);
  }

  protected get _glazingBeadHeight() {
    return this.profile.stick.frameElevation.glazingBeadSize === GlazingBeadSize.OMIT
      ? 0
      : (this.unitOfMeasure === UnitOfMeasure.Imperial ? '5/8"' : '49mm').fromDimension('frame', this.unitOfMeasure);
  }

  protected get glassThickness() {
    return (
      this.profile.stick.frameElevation.glassThickness +
      (this.unitOfMeasure === UnitOfMeasure.Imperial ? '1/8"' : '3mm').fromDimension('frame', this.unitOfMeasure)
    );
  }

  protected get centerGlazingLength(): number {
    return (this.profile.jambDepth - this.glassThickness) / 2;
  }

  protected get glassPosition(): GlazingBeadLocation {
    return this.profile.stick.frameElevation.glazingBeadLocation;
  }

  public get hasChanged(): boolean {
    return this._hasChanged;
  }

  private glazingBeadDimensions = [this._glazingBeadHeight, this._glazingBeadWidth, this._glazingBeadHeight];

  abstract readonly dimensions: {
    [dimensionName: string]: {
      direction: Direction;
      glazingBead?: {
        direction: Direction;
        start(): Partial<DOMPoint>;
      };
      kerf?: boolean;
      doorSide?: boolean;
      get(): number;
      set?(value: number): void;
      position?(): ProfileTemplateDimensionPosition;
    };
  };

  protected get defaultDoorSideRabbet(): number {
    return this.profile.stick.frameElevation.frameSeriesInfo.defaultDoorSideRabbet(
      this.unitOfMeasure,
      this.profile.stick.frameElevation.doorThickness
    );
  }

  protected get defaultOppositeDoorSideRabbet(): number {
    return this.profile.stick.frameElevation.frameSeriesInfo.defaultOppositeDoorSideRabbet(this.unitOfMeasure);
  }

  protected get defaultStopHeight(): number {
    return this.profile.stick.frameElevation.frameSeriesInfo.defaultStopHeight(this.unitOfMeasure);
  }
  protected get defaultBackbend(): number {
    return this.profile.stick.frameElevation.frameSeriesInfo.defaultBackbend(
      this.unitOfMeasure,
      this.profile.stick.frameElevation.jambDepth
    );
  }
  protected get defaultReturn(): number {
    return this.profile.stick.frameElevation.frameSeriesInfo.defaultReturn(this.unitOfMeasure);
  }

  abstract readonly stopHeight1: number;
  abstract readonly stopHeight2: number;
  abstract readonly backBend1: number;
  abstract readonly backBend2: number;
  abstract readonly doorSideRabbet1: number;
  abstract readonly oppositeDoorSideRabbet1: number;
  abstract readonly doorSideRabbet2: number;
  abstract readonly oppositeDoorSideRabbet2: number;

  get specialStop(): boolean {
    return ![
      isNaN(this.stopHeight1) || this.stopHeight1 === this.defaultStopHeight,
      isNaN(this.stopHeight2) || this.stopHeight2 === this.defaultStopHeight,
    ].all(_ => _);
  }

  get specialBackBend(): boolean {
    return ![
      isNaN(this.backBend1) || this.backBend1 === this.defaultBackbend,
      isNaN(this.backBend2) || this.backBend2 === this.defaultBackbend,
    ].all(_ => _);
  }

  get specialRabbet(): boolean {
    return ![
      isNaN(this.doorSideRabbet1) || this.doorSideRabbet1 === this.defaultDoorSideRabbet,
      isNaN(this.doorSideRabbet2) || this.doorSideRabbet2 === this.defaultDoorSideRabbet,
      isNaN(this.oppositeDoorSideRabbet1) || this.oppositeDoorSideRabbet1 === this.defaultOppositeDoorSideRabbet,
      isNaN(this.oppositeDoorSideRabbet2) || this.oppositeDoorSideRabbet2 === this.defaultOppositeDoorSideRabbet,
    ].all(_ => _);
  }

  get update$() {
    return this._update$.asObservable();
  }

  get id(): string {
    return `${Object.keys(this.dimensions)
      .map(key => `${key} ${this.dimensions[key].get()}`)
      .join(' ')} ${
      this.profile.stick.frameElevation.glazingBeadSize === GlazingBeadSize.OMIT
        ? ''
        : this.glazingBeads.map(g => g.type).join(' ')
    }`;
  }

  get unitOfMeasure() {
    return this.profile.unitOfMeasure;
  }

  constructor(readonly profile: Profile, protected glazingBeads: GlassExport[]) {}

  protected loadDimensions() {
    if (this.profile.stick.profileDimensions) {
      for (const key in this.profile.stick.profileDimensions) {
        if (this.dimensions[key]?.set) {
          this.dimensions[key]?.set(this.profile.stick.profileDimensions[key]);
        }
      }
    }
  }

  drawProfile(container: SVGSVGElement, printable?: boolean): ProfileTemplate {
    this.clear(container);
    const points = Array.from(this.draw(container).points);
    this.drawDimensions(container, points, printable);
    return this;
  }

  update() {
    this._update$.next();
    this._hasChanged = true;
  }

  private draw(container: SVGSVGElement): SVGPolylineElement {
    const polyline = createSVGElement('polyline');
    for (const style in polylineStyles) {
      polyline.style[style as CSSPropKey] = polylineStyles[style];
    }
    polyline.points.appendItem(container.getPoint(0, 0));
    Object.keys(this.dimensions).forEach(key => {
      const value = this.dimensions[key];
      const point = Array.from(polyline.points).last();
      if (value.glazingBead) {
        const start = container.getPoint(
          point.x + value.glazingBead.start().x * this._multiplier,
          point.y + value.glazingBead.start().y * this._multiplier
        );
        const glazingBead = this.drawGlazingBeadLine(
          [...this.glazingBeadDimensions],
          start,
          value.glazingBead.direction
        );
        container.appendChild(glazingBead);
      }
      switch (value.direction) {
        case Direction.Up: {
          polyline.points.appendItem(container.getPoint(point.x, point.y - value.get() * this._multiplier));
          break;
        }
        case Direction.Right: {
          polyline.points.appendItem(container.getPoint(point.x + value.get() * this._multiplier, point.y));
          break;
        }
        case Direction.Down: {
          polyline.points.appendItem(container.getPoint(point.x, point.y + value.get() * this._multiplier));
          break;
        }
        case Direction.Left: {
          polyline.points.appendItem(container.getPoint(point.x - value.get() * this._multiplier, point.y));
          break;
        }
      }
    });
    container.appendChild(polyline);
    return polyline;
  }

  private drawDimensions(container: SVGSVGElement, points: SVGPoint[], printable: boolean): void {
    const keys = Object.keys(this.dimensions);
    const dimensions = keys.map(key => this.dimensions[key]);
    for (let i = 0; i < dimensions.length; i++) {
      if (dimensions[i].position == null || dimensions[i].position() == null) {
        continue;
      }
      const a = points[i];
      const b = points[i + 1];
      this.drawDimension(container, points, a, b, dimensions[i].position(), keys[i], printable);
      if (dimensions[i].doorSide) {
        const value = 'DOOR SIDE';
        this.drawText(
          container,
          value,
          {
            x: [a.x, b.x].average(_ => _) - ((value.length / 2) * ProfileTemplate.charWidth) / 2,
            y: [a.y, b.y].average(_ => _) - ProfileTemplate.charHeight / 2,
          },
          { fontSize: `10px` }
        );
      }
    }
    Array.from(container.getElementsByTagName('line'))
      .groupBy(
        line => `${line.x1.baseVal.value} ${line.y1.baseVal.value} ${line.x2.baseVal.value} ${line.y2.baseVal.value}`
      )
      .forEach(value => value[1]?.remove());
  }

  private drawDimension(
    container: SVGSVGElement,
    points: SVGPoint[],
    a: SVGPoint,
    b: SVGPoint,
    position: ProfileTemplateDimensionPosition,
    dimensionName: string,
    printable: boolean
  ) {
    const value = `${this.dimensions[dimensionName].get().toDimension('frame', this.unitOfMeasure)}`;
    const minX = points.min(pt => pt.x);
    const maxX = points.max(pt => pt.x);
    const minY = points.min(pt => pt.y);
    const maxY = points.max(pt => pt.y);
    const averageX = [a, b].average(pt => pt.x);
    const averageY = [a, b].average(pt => pt.y);
    switch (position) {
      case ProfileTemplateDimensionPosition.MinY:
      case ProfileTemplateDimensionPosition.Above: {
        const y = (position === ProfileTemplateDimensionPosition.MinY ? minY : averageY) - this._lineDistance;
        this.drawLine(container, { x: a.x, y }, { x: b.x, y });
        this.drawLine(container, { x: a.x, y: y - this._lineDistance / 4 }, { x: a.x, y: y + this._lineDistance / 4 });
        this.drawLine(container, { x: b.x, y: y - this._lineDistance / 4 }, { x: b.x, y: y + this._lineDistance / 4 });
        this.drawLine(
          container,
          { x: (a.x + b.x) / 2, y },
          { x: (a.x + b.x) / 2, y: y - this._lineDistance / 2 },
          true
        );
        this.drawDimensionText(
          container,
          value,
          dimensionName,
          printable ? averageX - (value.length * ProfileTemplate.charWidth) / 2 : averageX - this._inputWidth / 2,
          printable ? y - this._lineDistance / 2 : y - this._lineDistance / 2 - this._inputHeight,
          printable
        );
        break;
      }
      case ProfileTemplateDimensionPosition.MaxY:
      case ProfileTemplateDimensionPosition.Below: {
        const y = (position === ProfileTemplateDimensionPosition.MaxY ? maxY : averageY) + this._lineDistance;
        this.drawLine(container, { x: a.x, y }, { x: b.x, y });
        this.drawLine(container, { x: a.x, y: y - this._lineDistance / 4 }, { x: a.x, y: y + this._lineDistance / 4 });
        this.drawLine(container, { x: b.x, y: y - this._lineDistance / 4 }, { x: b.x, y: y + this._lineDistance / 4 });
        this.drawLine(container, { x: averageX, y }, { x: averageX, y: y + this._lineDistance / 2 }, true);
        this.drawDimensionText(
          container,
          value,
          dimensionName,
          printable ? averageX - (value.length * ProfileTemplate.charWidth) / 2 : averageX - this._inputWidth / 2,
          printable ? y + this._lineDistance / 2 + ProfileTemplate.charHeight : y + this._lineDistance / 2,
          printable
        );
        break;
      }

      case ProfileTemplateDimensionPosition.MinX:
      case ProfileTemplateDimensionPosition.Left: {
        const x = (position === ProfileTemplateDimensionPosition.MinX ? minX : averageX) - this._lineDistance;
        this.drawLine(container, { x, y: a.y }, { x, y: b.y });
        this.drawLine(container, { x: x - this._lineDistance / 4, y: a.y }, { x: x + this._lineDistance / 4, y: a.y });
        this.drawLine(container, { x: x - this._lineDistance / 4, y: b.y }, { x: x + this._lineDistance / 4, y: b.y });
        this.drawLine(container, { x: x - this._lineDistance / 4, y: b.y }, { x: x + this._lineDistance / 4, y: b.y });
        this.drawLine(container, { x, y: averageY }, { x: x - this._lineDistance / 2, y: averageY }, true);
        this.drawDimensionText(
          container,
          value,
          dimensionName,
          printable
            ? x - this._lineDistance / 2 - value.length * ProfileTemplate.charWidth
            : x - this._lineDistance / 2 - this._inputWidth,
          printable ? averageY + ProfileTemplate.charHeight / 2 : averageY - this._inputHeight / 2,
          printable
        );
        break;
      }
      case ProfileTemplateDimensionPosition.MaxX:
      case ProfileTemplateDimensionPosition.Right: {
        const x = (position === ProfileTemplateDimensionPosition.MaxX ? maxX : averageX) + this._lineDistance;
        this.drawLine(container, { x, y: a.y }, { x, y: b.y });
        this.drawLine(container, { x: x - this._lineDistance / 4, y: a.y }, { x: x + this._lineDistance / 4, y: a.y });
        this.drawLine(container, { x: x - this._lineDistance / 4, y: b.y }, { x: x + this._lineDistance / 4, y: b.y });
        this.drawLine(container, { x: x - this._lineDistance / 4, y: b.y }, { x: x + this._lineDistance / 4, y: b.y });
        this.drawLine(container, { x, y: averageY }, { x: x + this._lineDistance / 2, y: averageY }, true);
        this.drawDimensionText(
          container,
          value,
          dimensionName,
          x + this._lineDistance / 2,
          printable ? averageY + ProfileTemplate.charHeight / 2 : averageY - this._inputHeight / 2,
          printable
        );
        break;
      }
    }
  }

  private drawLine(container: SVGSVGElement, a: Partial<DOMPoint>, b: Partial<DOMPoint>, dimension?: boolean) {
    const line = pipe(
      setY2(b.y),
      setY1(a.y),
      setX2(b.x),
      setX1(a.x),
      setStyles({...lineStyles, ...(dimension ? dimensionStyles : {})}),
      appendToContainerElement(container)
    )(createSVGElement('line'))
  }

  private drawDimensionText(
    container: SVGSVGElement,
    value: string,
    dimensionName: string,
    x: number,
    y: number,
    printable: boolean
  ) {
    if (printable) {
      this.drawText(container, value, { x, y });
    } else {
      container.appendChild(this._dimensionInputMaker.create(x, y, this, dimensionName, this._destroy$));
    }
  }

  private drawText(
    container: SVGSVGElement,
    value: string,
    point: Partial<DOMPoint>,
    styles: { [key: string]: string } = {}
  ) {
    const text = pipe(
      setX(point.x),
      setY(point.y),
      setInnerHTML(value),
      setStyles({...profileTextStyles, ...styles}),
    )(createSVGElement('text'));
    container.appendChild(text);
  }

  private clear(container: SVGSVGElement) {
    while (container.children.length > 0) {
      container.children[0].remove();
    }
  }

  protected dimensionName(
    func: () => {
      direction: Direction;
      get(): number;
      set?(value: number): void;
      position?(): ProfileTemplateDimensionPosition;
    }
  ): string {
    const value = Object.keys(this.dimensions).first(key => this.dimensions[key] === func());
    if (value == null) {
      throw new Error('Value does not exist');
    }
    return value;
  }

  private drawGlazingBeadLine(
    glazingBeads: number[],
    start: DOMPoint,
    direction: Direction,
    polyline?: SVGPolylineElement
  ): SVGPolylineElement {
    if (glazingBeads.length === 0) {
      return polyline;
    }
    if (polyline == null) {
      polyline = createSVGElement('polyline')
      for (const style in polylineStyles) {
        polyline.style[style as CSSPropKey] = polylineStyles[style];
      }
      polyline.points.appendItem(start);
    }
    const glazingBead = glazingBeads.shift();
    const end = polyline.points.appendItem(polyline.points[polyline.points.length - 1]);
    switch (direction) {
      case Direction.Up:
        end.y -= glazingBead * this._multiplier;
        return this.drawGlazingBeadLine(glazingBeads, end, Direction.Right, polyline);
      case Direction.Right:
        end.x += glazingBead * this._multiplier;
        return this.drawGlazingBeadLine(glazingBeads, end, Direction.Down, polyline);
      case Direction.Left:
        end.x -= glazingBead * this._multiplier;
        return this.drawGlazingBeadLine(glazingBeads, end, Direction.Up, polyline);
      case Direction.Down:
        end.y += glazingBead * this._multiplier;
        return this.drawGlazingBeadLine(glazingBeads, end, Direction.Left, polyline);
    }
  }

  destroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}
