import { Discount } from './discount'

export class Quote {
  quoteId: string
  companyId: string
  quoteNumber: string
  name: string
  revisionNumber?: number
  effectiveDate?: string
  expirationDate?: string
  discounts: Discount[]
  state?: string
  status?: string
  valid: boolean
  requestedBy?: string
  results?: string
  type?: string
}

export class QuoteExtended extends Quote {
  selected!: boolean
  constructor(quoteDetails: Partial<QuoteExtended>) {
    super()
    Object.assign(this, quoteDetails)
  }
}
