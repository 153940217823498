import { Injectable } from '@angular/core';
import { map, forkJoin } from 'rxjs';
import { IPrepService } from '../interfaces/i-prep.service';
import { PrepCategoryService } from './door-prep-category.service';
import { DoorPrepCodeService } from './door-prep-code.service';

@Injectable()
export class DoorPrepService implements IPrepService {
  constructor(private categoryService: PrepCategoryService, private codeService: DoorPrepCodeService) {}

  getFormattedPreps() {
    return forkJoin({prepCategories: this.categoryService.get(), prepCodes: this.codeService.search({})}).pipe(
      map(({prepCategories, prepCodes}) => {
        for (const code of prepCodes) {
          code.categories = [...prepCategories].map(c => {
            const category = Object.assign({}, c);
            category.checked = c.doorPrepCategoryCodes.any(p => p.prepCodeId === code.id);
            return category;
          });
        }
        return { prepCategories, prepCodes };
      })
    );
  }
}
