import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Cacheable } from 'ts-cacheable'
import { memoize, isEqual } from 'lodash'
import { Observable, AsyncSubject, of } from 'rxjs'
import { Address, AddressValidationResponse, ShortAddress } from '../../models/address.model'
import { take, mergeMap, tap, map } from 'rxjs/operators'
import { AddressForm } from './address.form'
import { Features } from '../../models/features.model'
import { Country } from '../../models/country.model'
import { State } from '../../models/state.model'

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private _getAddress = memoize((id: number) => this.getAddressById(id))

  constructor(private authHttp: HttpClient, public form: AddressForm) {}

  public postAddress(payload: Address) {
    const url = environment.onlineOrderingApiUrl + 'Addresses'
    const body = payload
    return this.authHttp.post<Address>(url, body)
  }

  @Cacheable()
  public getShippingMethods() {
    const url = environment.onlineOrderingApiUrl + 'ShippingMethods'
    return this.authHttp.get(url)
  }

  public getBillingAddress(refresh?: boolean): Observable<Address[]> {
    const url = environment.onlineOrderingApiUrl + `Addresses/Billing?refreshCache=${refresh || false}`
    return this.authHttp.get<Address[]>(url)
  }

  public getShippingAddress(): Observable<Address[]> {
    const url = environment.onlineOrderingApiUrl + 'Addresses/Shipping'
    return this.authHttp.get<Address[]>(url)
  }

  public getAddress(id: number) {
    return this._getAddress(id)
  }

  private getAddressById(id: number) {
    const url = environment.onlineOrderingApiUrl + 'Addresses/' + id
    return this.authHttp.get(url)
  }

  getFullAddress(addressList: Address[], address: Address): Observable<Address> {
    const _address: AsyncSubject<Address> = new AsyncSubject()
    const match: Address = this.findMatch(addressList, address)
    if (!match) {
      const payload = address
      payload.telephone = payload.telephone.replace(/\D/g, '')
      delete payload.id
      this.postAddress(address)
        .pipe(take(1))
        .subscribe((res: Address) => {
          _address.next(res)
          _address.complete()
        })
    } else {
      _address.next(match)
      _address.complete()
    }
    return _address
  }

  validateAddress(
    address: Address,
    flags: Features
  ): Observable<{
    isValid: boolean
    userEnteredAddress: Address
    addressValidationResponse: AddressValidationResponse
  }> {
    const url = environment.onlineOrderingApiUrl + 'Addresses/Validate'

    return this.authHttp.post<AddressValidationResponse>(url, address).pipe(
      mergeMap((res: AddressValidationResponse) => {
        const isAddressETOValid = this.validateForETO(res.address, flags)
        let match
        if (isAddressETOValid) {
          match = this.findMatch([res.address], address)
        } else {
          match = true
        }
        const payload = {
          isValid: !!match,
          userEnteredAddress: address,
          addressValidationResponse: res
        }
        return of(payload)
      })
    )
  }

  findMatch(addressList: Address[], address: Address): Address {
    const userEnteredAddress = new ShortAddress(address)
    const _addressList = addressList.map((a) => new ShortAddress(a))
    const matchIndex: number = _addressList.findIndex((a) => isEqual(a, userEnteredAddress))
    if (matchIndex > -1) {
      return addressList[matchIndex]
    } else {
      return null
    }
  }

  validateForETO(address: Address, flags: Features) {
    const form = this.form.createAddressForm(flags)
    form.patchValue(address)
    return form.valid
  }

  @Cacheable()
  getCountries(): Observable<Country[]> {
    const url = environment.onlineOrderingApiUrl + 'Countries'
    return this.authHttp
      .get<Country[]>(url)
      .pipe(map((countries: Country[]) => countries.filter((country) => country.isActive)))
  }

  @Cacheable()
  getStatesByCountryId(id: string): Observable<State[]> {
    const url = environment.onlineOrderingApiUrl + `Countries/${id}/States`
    return this.authHttp
      .get<State[]>(url)
      .pipe(map((states: State[]): State[] => states.filter((state) => state.isActive)))
  }
}
