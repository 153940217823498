import { IPrep } from '../interfaces/i-prep'
import { createProperty } from './createProperty'
import { nameOf, objectKeyExtractor } from '@oeo/common'
import { Door } from '../../door-elevation/models/door'
import { FrameElevation } from '../../frame-elevation/models/frame-elevation'

export function appendPrep(obj: Record<string, any>, property: string): string {
  if (!obj[property]) {
    return ''
  }
  const strs: string[] = []
  if ((obj[property] as IPrep).productId) {
    const value = obj[property] as IPrep
    let questions = value.questions?.filter((q)=>q.title && q.value).map((q) => `${q.title}:${q.value}`)?.join('||') ?? ''
    questions = questions ? `||${questions}` : ''
    strs.push(
      createProperty(
        `${property}Special`,
        `${value.manufacturer}||${value.hardwareType}||${value.productId}${questions}`
      )
    )
  }
  for (const key in obj[property]) {
    if (
      key !== nameOf((_: IPrep) => _.manufacturer) &&
      key !== nameOf((_: IPrep) => _.hardwareType) &&
      key !== nameOf((_: IPrep) => _.productId) &&
      key !== nameOf((_: IPrep) => _.questions)
    ) {
      if (obj[property][key] !== null) {
        strs.push(createProperty(`${property}${key}`, `${obj[property][key]}`))
      }
    }
  }
  return strs.join('\n')
}

/**
 * From a door or Frame Elevation, extract all prep properties and append them to the string
 * This is used to create the XML for the door or frame elevation
 * @param doorOrFrame
 * @returns {string} - the prep properties as a string
 */
export const appendPreps = (doorOrFrame: Door | FrameElevation) =>
  objectKeyExtractor<Door | FrameElevation, IPrep>(doorOrFrame, /.*Prep$/)
    .map((key) => appendPrep(doorOrFrame, key))
    .join('\n')
