import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2,
  OnInit,
  OnDestroy,
  ContentChild,
  TemplateRef,
  AfterViewInit
} from '@angular/core'
import { InputTransformFn, transformFunctions } from '../../helpers/input-transforms'
import { FormsModule, NgForm } from '@angular/forms'
import { MaterialModule } from '../../material/material.module'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'lib-input-option',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  standalone: true,
  imports: [FormsModule, MaterialModule, CommonModule]
})
export class InputSelectComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() optionsList: (string | Object)[] = []
  @Input() error: boolean = false
  @Input() type: string = 'text'
  @Input() autocomplete: string = 'off'
  @Input() readonly?: boolean
  @Input() placeholder: string = ''
  @Input() disabled = false
  @Input() transformType: InputTransformFn = 'none'
  @Input() label!: string
  @Input() fieldClass = ''
  @Input() required = false

  get matFieldClasses(): Record<string, boolean | null> {
    return {
      [this.fieldClass]: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'mat-form-field-invalid': !!(this.error || (this.form.touched && this.form.invalid))
    }
  }

  /** A function that transforms the input optionsList - which can be Objects to a string representation */
  transformFn!: (value: any) => string

  @Output() selectionChange = new EventEmitter<string | Object | null>()

  @ViewChild('InputElement', { static: true }) input!: ElementRef<HTMLInputElement>
  @ContentChild('optionTemplate') template!: TemplateRef<any>
  @ContentChild('labelTemplate') labelTemplate!: TemplateRef<any>
  @ViewChild(NgForm, { static: true }) form!: NgForm

  #selectedItemValue!: string
  @Input() set selectedItemValue(value: any) {
    // Filter the values based on input
    this.filterdInputValues = this.filter(value)
    if (value === '') this.selectionChange.emit(null)
    if (typeof value !== 'string') value = this.transform(value)

    this.#selectedItemValue = value

    /*
     * Only emit values that are in the options - prevents emitting
     * values when the user is searching
     */
    if (Array.isArray(this.optionsList) && this.optionsList?.any((o) => this.transform(o) == value)) {
      this.selectionChange.emit(this.optionsList.find((val) => this.transform(val) === this.selectedItemValue))
    } //else this.selectionChange.emit(null)
  }
  get selectedItemValue(): string {
    return this.#selectedItemValue
  }

  filterdInputValues: any[] = []
  /**
   * Whether or not to render the options using a template passed into the component
   */
  renderTemplate: boolean = false

  constructor(protected renderer: Renderer2) {}

  ngOnInit() {
    this.transformFn = transformFunctions[this.transformType]

    this.renderer.listen(this.input?.nativeElement, 'focus', () => {
      this.filterdInputValues = this.optionsList ?? []
      this.blur()
    })
    this.renderer.listen(this.input.nativeElement, 'blur', () => this.blur())
  }

  ngAfterViewInit(): void {
    this.renderTemplate = !!this.template
  }

  /**
   * @param searchString - a query string that the user types in to search
   * @returns the list of options filtered
   */
  private filter(searchString: string): (string | Object)[] {
    if (!searchString || typeof searchString !== 'string') return this.optionsList
    if (!this.optionsList) return []
    // Lowercase to enable searching when search string is uppercase or lowercase
    const filterFunction = (option: any) => {
      return this.transform(option)?.toLowerCase().includes(searchString.toLowerCase())
    }
    return this.optionsList.filter(filterFunction)
  }

  /**
   * Sets the selection to be at the start of the input element
   */
  blur() {
    this.input?.nativeElement.setSelectionRange(0, 0, 'forward')
  }

  /**
   * @param inputValue The option to be changed to a string
   * @returns a string representation of the option
   */
  transform(inputValue: any): string {
    if (Array.isArray(this.optionsList) && this.transformFn && this.optionsList?.any((option) => option === inputValue))
      return this.transformFn(inputValue)
    return inputValue
  }

  ngOnDestroy() {
    this.renderer.destroy()
  }
}
