import { Template } from '../abstracts/template';
import { FrameElevation } from '../models/frame-elevation';
import { Stick } from '../models/stick';
import { JointType } from '../enums/joint-type';
import { Orientation } from '../enums/orientation';
import { StickType } from '../enums/stick-type';
import { StickSubtype } from '../enums/stick-subtype';
import { DimensionType } from '../../core/enums/dimension-type';
import { guid } from '@oeo/common';

export class BLF05VM extends Template {
  private _b: number;
  private _c: number;
  private _d: number;
  private _f: number;

  dimensions = {
    A: { type: DimensionType.Horizontal, get: () => this.defaultFace },
    B: {
      type: DimensionType.Horizontal,
      get: () => this._b,
      set: (value: number) => {
        const diff = this._b - value;
        this._b = value;
        this._d += diff;
        this.update();
      },
    },
    C: {
      type: DimensionType.Horizontal,
      get: () => this._c,
      set: (value: number) => {
        const diff = this._c - value;
        this._c = value;
        this._d += diff;
        this.update();
      },
    },
    D: {
      type: DimensionType.Horizontal,
      get: () => this._d,
      set: (value: number) => {
        const diff = this._d - value;
        this._d = value;
        this._b += diff;
        this.update();
      },
    },
    E: {
      type: DimensionType.Horizontal,
      get: () => this.defaultFace,
    },
    F: {
      type: DimensionType.Vertical,
      get: () => this._f,
      set: (value: number) => {
        if (!this.headDimensions.includes(value)) {
          return this.update();
        }
        this._f = value;
        this.update();
      },
      hint: `Head dimension must be either ${this.headDimensions
        .map(d => d.toDimension(this.dimensionType, this.unitOfMeasure))
        .join(' or ')}`,
    },
    G: {
      type: DimensionType.Vertical,
      get: () => this.height - (this.dimensions.F.get() + this.dimensions.H.get()),
    },
    H: { type: DimensionType.Vertical, get: () => this.defaultFace },
  };

  constructor(frameElevation: FrameElevation) {
    super(frameElevation);
    this._c = this.defaultFace;
    this._f = this.defaultFace;
    this._b = (
      (this.width - (this.dimensions.A.get() + this.dimensions.C.get() + this.dimensions.E.get())) /
      2
    ).cleanAsDimension('door', this.unitOfMeasure);
    this._d =
      this.width -
      (this.dimensions.A.get() + this.dimensions.B.get() + this.dimensions.C.get() + this.dimensions.E.get());
  }

  protected drawObject(container: SVGSVGElement) {
    this.drawLeft(container);
    this.drawTop(container);
    this.drawBottom(container);
    this.drawRight(container);
    this.drawMiddle(container);
  }

  private drawLeft(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: 0, y: this._f === this.headFace ? this.headFace / 2 : 0 },
          { x: 0, y: this.height },
          { x: this.dimensions.A.get(), y: this.height - this.dimensions.H.get() },
          { x: this.dimensions.A.get(), y: this.dimensions.F.get() },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Mitered,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawTop(container: SVGSVGElement) {
    const points =
      this._f === this.headFace
        ? [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width, y: this.headFace / 2 },
            { x: this.width - this.dimensions.E.get(), y: this.dimensions.F.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.F.get() },
            { x: 0, y: this.headFace / 2 },
          ]
        : [
            { x: 0, y: 0 },
            { x: this.width, y: 0 },
            { x: this.width - this.dimensions.E.get(), y: this.dimensions.F.get() },
            { x: this.dimensions.A.get(), y: this.dimensions.F.get() },
          ];
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points,
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: this._f === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        rightJointType: this._f === this.headFace ? JointType._2InchMiter : JointType.Mitered,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: true,
        id: guid(),
      })
    );
  }

  private drawRight(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: this.width - this.dimensions.E.get(), y: this.dimensions.F.get() },
          { x: this.width - this.dimensions.E.get(), y: this.height - this.dimensions.H.get() },
          { x: this.width, y: this.height },
          { x: this.width, y: this._f === this.headFace ? this.headFace / 2 : 0 },
        ],
        topJointType: JointType.Mitered,
        bottomJointType: JointType.Mitered,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawBottom(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          { x: 0, y: this.height },
          { x: this.width, y: this.height },
          { x: this.width - this.dimensions.E.get(), y: this.height - this.dimensions.H.get() },
          { x: this.dimensions.A.get(), y: this.height - this.dimensions.H.get() },
        ],
        topJointType: null,
        bottomJointType: null,
        orientation: Orientation.Horizontal,
        exportType: Stick.intersectableName,
        leftJointType: JointType.Mitered,
        rightJointType: JointType.Mitered,
        type: StickType.OpenSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }

  private drawMiddle(container: SVGSVGElement) {
    this.intersectables.push(
      Stick.fromJSON(this.frameElevation, container, {
        shopBreaks: [],
        points: [
          {
            x: this.dimensions.A.get() + this.dimensions.B.get(),
            y: this.dimensions.F.get(),
          },
          {
            x: this.dimensions.A.get() + this.dimensions.B.get(),
            y: this.height - this.dimensions.H.get(),
          },
          {
            x: this.dimensions.A.get() + this.dimensions.B.get() + this.dimensions.C.get(),
            y: this.height - this.dimensions.H.get(),
          },
          {
            x: this.dimensions.A.get() + this.dimensions.B.get() + this.dimensions.C.get(),
            y: this.dimensions.F.get(),
          },
        ],
        topJointType: JointType.Notched,
        bottomJointType: JointType.Notched,
        orientation: Orientation.Vertical,
        exportType: Stick.intersectableName,
        leftJointType: null,
        rightJointType: null,
        type: StickType.ClosedSection,
        subType: StickSubtype.Blank,
        flipped: false,
        id: guid(),
      })
    );
  }
}
