import { elevationsTTPolicy } from '../policies/trustedType';

/**
 * Creates a Document from an XML String
 * @param xmlString - an XML String
 * @returns A document object
 */
export function parseFromXMLString(xmlString: string): Document {
  return new DOMParser().parseFromString(elevationsTTPolicy.createHTML(xmlString) as unknown as string, 'application/xml');
}
