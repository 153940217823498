import { inject } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { map, mergeMap } from 'rxjs/operators'
import { Observable, EMPTY } from 'rxjs'
import { CustomerService } from '../../services/customer/customer.service'

export const roleCheck = (): Observable<boolean | UrlTree> => {
  const customerService = inject(CustomerService)
  const authService = inject(AuthService)
  const router = inject(Router)

  return authService.getUser$().pipe(
    mergeMap((profile) => {
      if (!profile) {
        authService.login()
        return EMPTY
      }
      return customerService.isInternalOrHasUserRole$
    }),
    map((res) => (res ? true : router.parseUrl('/unauthorized')))
  )
}
