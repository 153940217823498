import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Route, RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { CanDeactivateGuard } from '../core/guards/can-deactivate.guard';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../core/interfaces/i-environment';
import { IUserService, USERSERVICE_INJECTION_TOKEN } from '../core/interfaces/i-user-service';
import { FolderService } from '../core/services/folder.service';
import { FrameElevationService } from '../core/services/frame-elevation.service';
import { PreferenceService } from '../core/services/preference.service';
import { UserService } from '../core/services/user.service';
import { DoorElevationComponent } from '../door-elevation/door-elevation.component';
import { DoorElevationModule } from '../door-elevation/door-elevation.module';
import { DoorElevationConfigService } from '../door-elevation/services/door-elevation-config-service/door-elevation-config.service';
import { DoorElevationService } from '../door-elevation/services/door-elevation-service/door-elevation.service';
import { FrameElevationComponent } from '../frame-elevation/frame-elevation.component';
import { FrameElevationModule } from '../frame-elevation/frame-elevation.module';
import { FrameElevationConfigService } from '../frame-elevation/services/frame-elevation-config.service';
import { PrepsComponent } from '../preps/preps.component';
import { PrepsModule } from '../preps/preps.module';
import { TemplateLibraryComponent } from '../template-library/template-library.component';
import { TemplateLibraryModule } from '../template-library/template-library.module';
import { ActionDialogComponent } from './dialogs/action-dialog/action-dialog.component';
import { ElevationComponent } from './elevation.component';
import { HomeComponent } from './home/home.component';
import { LeftBarComponent } from './left-bar/left-bar.component';
import { OEOCommonModule } from '@oeo/common';

@NgModule({
  declarations: [ElevationComponent, HomeComponent, LeftBarComponent, ActionDialogComponent, ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    TemplateLibraryModule,
    FrameElevationModule,
    DoorElevationModule,
    PrepsModule,
    RouterModule,
    OEOCommonModule
  ],
  exports: [ElevationComponent],
  providers: [DoorElevationService, FrameElevationService, UserService, CanDeactivateGuard, DoorElevationConfigService, FrameElevationConfigService, PreferenceService, FolderService],
})
export class ElevationModule {
  constructor() {}
  static forRoot(environment: IEnvironment, userService: Type<IUserService>): ModuleWithProviders<ElevationModule> {
    return {
      ngModule: ElevationModule,
      providers: [
        { provide: ENVIRONMENT_INJECTION_TOKEN, useValue: environment },
        { provide: USERSERVICE_INJECTION_TOKEN, useClass: userService },
      ],
    };
  }

  static routes(path: string, component: Type<any>, canActivate: any[]): Route {
    return {
      path,
      component,
      canActivate,
      data: {
        breadcrumb: 'Elevations',
      },
      children: [
        {
          path: 'home',
          component: HomeComponent,
          data: {
            breadcrumb: 'TITLE.home',
          },
        },
        {
          path: 'frameElevation',
          component: FrameElevationComponent,
          data: {
            breadcrumb: 'frames',
          },
        },
        {
          path: 'doorElevation',
          component: DoorElevationComponent,
          canDeactivate: [CanDeactivateGuard],
          data: {
            breadcrumb: 'doors',
          },
        },
        {
          path: 'drawingLibrary',
          component: TemplateLibraryComponent,
          data: {
            breadcrumb: 'TITLE.drawingLibrary',
          },
        },
        {
          path: 'manageHardware',
          component: PrepsComponent,
          data: {
            breadcrumb: 'TITLE.manageHardware',
          },
          canActivate: [UserService],
        },
        {
          path: '**',
          redirectTo: 'home',
        },
      ],
    };
  }
}
