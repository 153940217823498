import { pipe } from 'lodash/fp';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { appendToContainerElement, createSVGElement, setHeight, setStyles, setWidth, setX, setY } from '../../../core/helpers/svg-functions';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { Door } from '../../models/door';
import { DoorElevation } from '../../models/door-elevation';
import { CutoutExport } from '../../models/exports/cutout-export';

const dutchStyles = {
  fill: 'rgb(127, 127, 127)',
  stroke: 'none',
};

export class DFType extends DoorType {
  cutouts: Cutout[] = [];

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  customDrawing(container: SVGGElement): void {
    const height = this.dutchLineHeight;
    const distanceFromBottom = this.dutchLineDistanceFromBottom;
    const y = this.door.actualHeight - distanceFromBottom - height;
    const rect = pipe(
      setX(0),
      setY(y - height),
      setHeight(height),
      setWidth(this.door.actualWidth),
      setStyles(dutchStyles),
      appendToContainerElement(container)
    )(createSVGElement('rect'));
  }

  protected get dutchLineDistanceFromBottom(): number {
    return uomSwitch('43 1/8"', 'door', this.door.doorElevation.unitOfMeasure);
  }

  protected get dutchLineHeight(): number {
    return uomSwitch('3/16"', 'door', this.door.doorElevation.unitOfMeasure)
  }
}
