import { DoorTopChannel, DoorBottomChannel } from '../enums/door-channel';
import { DoorSeamlessEdge } from '../enums/door-seamless-edge';

export function seamlessEdgeTopChannels(seamlessEdge: DoorSeamlessEdge, channels: DoorTopChannel[]) {
  if (
    [
      DoorSeamlessEdge.CONT_TOP,
      DoorSeamlessEdge.CONT_TOPBTM,
      DoorSeamlessEdge.INT_TOP,
      DoorSeamlessEdge.INT_TOPBTM,
      DoorSeamlessEdge.VBLE_TOP,
      DoorSeamlessEdge.VBLE_TOPBTM,
    ].any(x => seamlessEdge === x)
  ) {
    channels.remove(DoorTopChannel.INVERT);
  }
}

export function seamlessEdgeBottomChannels(seamlessEdge: DoorSeamlessEdge, channels: DoorBottomChannel[]) {
  if (
    [DoorSeamlessEdge.CONT_TOPBTM, DoorSeamlessEdge.INT_TOPBTM, DoorSeamlessEdge.VBLE_TOPBTM].any(
      x => seamlessEdge === x
    )
  ) {
    channels.remove(DoorBottomChannel.INVERT);
  }
}
