import { pipe } from 'lodash/fp'
import { fromEvent, Subject } from 'rxjs'
import { takeUntil, filter } from 'rxjs/operators'
import { SpecialHardwareComponent, SpecialHardwarePrepBaseComponent } from '../../../core/components/special-hardware/special-hardware.component'
import { PrepShape } from '../../../core/enums/prep-shape'
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure'
import { uomSwitch } from '../../../core/functions/uomSwitch'
import { createHTMLElement, createSVGElement, setAttributes, setCx, setCy, setHeight,setRx,setRy,setStyles,setWidth, setX, setY } from '../../../core/helpers/svg-functions'
import { IPrep } from '../../../core/interfaces/i-prep'
import { IPrepCode } from '../../../core/interfaces/i-prep-code'
import { IProduct } from '../../../core/interfaces/i-product'
import { DoorPrepReferencePoint } from '../../enums/door-prep-reference-point'
import { DoorPrepSpecialReferencePoint } from '../../enums/door-prep-special-reference-point'
import { Door } from '../../models/door'
import { DoorPrepComponent } from './door-prep/door-prep.component'
import { PrepsComponent } from './preps.component'
import { DoorPrepCategoryIds } from '../../../core/enums/prep-category-ids'
import { CSSPropKey } from '@oeo/common'
import { Component, ViewChild } from '@angular/core'
import { merge } from 'lodash'
import { NgForm } from '@angular/forms'
import { DoorSeries } from '../../enums/door-series'

@Component({template: ''})
export abstract class PrepBaseComponent extends SpecialHardwarePrepBaseComponent {

  /**
   * Every prep component should define a prep fields form with which to validate the prep questions
   */
  @ViewChild('PrepFieldsForm', { static: true, read: NgForm }) prepFieldsForm: NgForm

  /* We are assuming that every prep component has, in its template, the 'lib-special-prep' component defined */
  @ViewChild(SpecialHardwareComponent) specialHardwareComponent: SpecialHardwareComponent

  products: IProduct[]
  codes: IPrepCode[]

  protected draw$ = new Subject<void>()
  protected destroy$ = new Subject<void>()
  door: Door
  private _height = 320
  public get height() {
    return this._height
  }
  private _width = 1000
  public get width() {
    return this._width
  }
  readonly horzDistance = 400
  get unitOfMeasure() {
    return this.door.doorElevation.unitOfMeasure
  }

  get g(): SVGGElement {
    return this.doorPrepComponent.preps
  }

  get svg(): SVGSVGElement {
    return this.doorPrepComponent.svg
  }

  get showConduit(): boolean {
    return this.prepCategoryId === DoorPrepCategoryIds.POWER_TRANSFER
  }

  abstract doorPrepComponent: DoorPrepComponent
  abstract code: IPrepCode
  abstract prep: IPrep

  /**
   * Incomplete required questions of prep base component
   * Defaults to checking whether the code is set
   */
  get incompleteQs(): boolean {
    const specialQuestionsForm = this.specialHardwareComponent?.specialQuestionsForm
    return !this.code || (specialQuestionsForm && !specialQuestionsForm?.valid) || (this.prepFieldsForm && !this.prepFieldsForm?.valid)
  }

  get isDPSeries() {
    return this.prepsComponent.configService.doorElevation.series === DoorSeries.DP
  }

  message: string = null
  conduitYesNo = ['YES', 'NO'] as const

  constructor(public readonly prepsComponent: PrepsComponent, private prepCategoryId: DoorPrepCategoryIds) {
    super()
    this.door = this.prepsComponent.configService.currentDoor
    this.codes = this.prepsComponent.configService.getPrepCodes(prepCategoryId)
    this.products = this.prepsComponent.configService.products.filter((p) => p.prepCategoryIds.includes(this.prepCategoryId))
  }

  private createInput(index: number, x: number, y: number, prep: IPrep, isVertical: boolean) {
    const foreignObject = pipe(
      setY(y - (isVertical ? this._height / 2 : 0)),
      setX(x - (!isVertical ? this._width / 2 : 0)),
      setHeight(this._height),
      setWidth(this._width)
    )(createSVGElement('foreignObject'))

    const div = pipe(
      setStyles({width: 'calc(100% - 32px)', height: 'calc(100% - 32px)'}),
    )(createHTMLElement('div'))

    const input = createHTMLElement('input')
    const styles: Record<string, string> = {
      border: '16px solid #d5d8dd',
      borderRadius: '2px',
      height: '100%',
      width: 'calc(100% - 160px)',
      padding: '0 80px',
      textAlign: 'left',
      fontSize: '160px'
    }
    for (const key in styles) {
      input.style[key as CSSPropKey] = styles[key]
    }
    input.value = prep.location instanceof Array ? prep.location[index] : prep.location

    if (!prep.fixedLocation) {
      fromEvent(input, 'mousedown')
        .pipe(takeUntil(this.draw$))
        .subscribe((e) => {
          foreignObject.parentNode.appendChild(foreignObject)
          e.stopPropagation()
        })

      fromEvent(input, 'mouseup')
        .pipe(takeUntil(this.draw$))
        .subscribe((e) => e.stopPropagation())

      fromEvent(input, 'mousemove')
        .pipe(takeUntil(this.draw$))
        .subscribe((e) => e.stopPropagation())

      fromEvent(input, 'focusout')
        .pipe(takeUntil(this.draw$))
        .subscribe(() => this.updateInput(input, isVertical, prep, index))

      fromEvent(input, 'keyup')
        .pipe(filter((event: KeyboardEvent)=> event.key === 'Enter'), takeUntil(this.draw$))
        .subscribe(() => this.updateInput(input, isVertical, prep, index))

    } else {
      input.readOnly = true
    }

    div.appendChild(input)
    foreignObject.appendChild(div)
    return { inputContainer: foreignObject, inputElement: input }
  }

  protected updateInput(input: HTMLInputElement, isVertical: boolean, prep: IPrep, index: number){
    const suffix = this.unitOfMeasure === UnitOfMeasure.Imperial ? '"' : 'mm'
    if (!input.value.endsWith(suffix)) {
      input.value = `${input.value}${suffix}`
    }
    if (isVertical && input.value.fromDimension('door', this.unitOfMeasure) > this.door.actualHeight) {
      input.value = uomSwitch('0"', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure)
    }
    if (!isVertical && input.value.fromDimension('door', this.unitOfMeasure) > this.door.actualWidth) {
      input.value = uomSwitch('0"', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure)
    }
    if (prep.location instanceof Array) {
      prep.location[index] = input.value
        .fromDimension('door', this.unitOfMeasure)
        .toDimension('door', this.unitOfMeasure)
    } else {
      prep.location = input.value
        .fromDimension('door', this.unitOfMeasure)
        .toDimension('door', this.unitOfMeasure)
    }
    input.value = prep.location instanceof Array ? prep.location[index] : prep.location
    this.draw$.next()
  }

  private createCenterline(x: number, y: number, isVertical: boolean): SVGUseElement {
    return pipe(
      setX(x - (!isVertical ? 83 : 0)),
      setY(y - (isVertical ? 125 : 0)),
      setAttributes({ href: '#centerline' })
    )(createSVGElement('use'))
  }

  private createPolyLine(points: { x: number; y: number }[], styles: { [key: string]: string } = {}) {
    const line = createSVGElement('polyline')
    const polylineStyles: Record<string, string>= {
      strokeWidth: '16px',
      fill: 'none',
      stroke: '#7f7f7f',
      strokeDasharray: '32px'
    }
    for (const key in polylineStyles) {
      line.style[key as CSSPropKey] = polylineStyles[key]
    }
    for (const key in styles) {
      line.style[key as CSSPropKey] = styles[key]
    }
    for (const point of points) {
      line.points.appendItem(this.svg.getPoint(point.x, point.y))
    }
    return line
  }

  clear(): void {
    for (const element of Array.from(this.g.children)) {
      element.remove()
    }
  }

  destroy() {
    this.destroy$.next()
    this.destroy$.unsubscribe()
    this.draw$.next()
    this.draw$.unsubscribe()
  }

  drawPrep(index: number, prep: IPrep, hingeSide?: boolean) {
    const domReferencePoint = this.getReferenceDOMPoint(prep.referencePoint as DoorPrepReferencePoint)
    const modifier = domReferencePoint < this.door.height / 2 ? 1 : -1
    const location =
      domReferencePoint +
      uomSwitch(prep.location instanceof Array ? prep.location[index] : prep.location, 'door', this.unitOfMeasure) *
        modifier
    const dist = (this.horzDistance + 80 + this.width) * index
    if (this.hasCenterline(prep.referencePoint as DoorPrepReferencePoint)) {
      this.g.insertAdjacentElement(
        'afterbegin',
        this.createCenterline(
          (hingeSide ? this.horzDistance / 2 + 166 / 2 : this.door.actualWidth + this.horzDistance / 2 - 166 / 2) *
            (hingeSide ? -1 : 1),
          location,
          true
        )
      )
    }

    this.g.insertAdjacentElement(
      'afterbegin',
      this.createPolyLine(
        [
          { x: hingeSide ? 0 : this.door.actualWidth, y: location },
          {
            x: (hingeSide ? 0 : this.door.actualWidth) + (dist + this.horzDistance) * (hingeSide ? -1 : 1),
            y: location
          },
          {
            x: (hingeSide ? 0 : this.door.actualWidth) + (dist + this.horzDistance) * (hingeSide ? -1 : 1),
            y: domReferencePoint
          },
          this.goesToHeadOrFloor(prep.referencePoint as DoorPrepReferencePoint)
            ? null
            : { x: hingeSide ? 0 : this.door.actualWidth, y: domReferencePoint }
        ].filter((x) => !!x)
      )
    )

    const { inputContainer, inputElement } = this.createInput(
      index,
      ((hingeSide ? this.width : this.door.actualWidth) + dist + this.horzDistance + 80) * (hingeSide ? -1 : 1),
      [domReferencePoint, location].average((_) => _),
      prep,
      true
    )
    this.g.insertAdjacentElement('beforeend', inputContainer)
    this.doorPrepComponent.resetAndFit()
    return inputElement
  }

  private getReferenceDOMPoint(referencePoint: DoorPrepReferencePoint): number {
    switch (referencePoint) {
      case DoorPrepReferencePoint.BDB:
      case DoorPrepReferencePoint.BDCL: {
        return this.door.doorElevation.headClearance + this.door.actualHeight - 8
      }
      case DoorPrepReferencePoint.FFB:
      case DoorPrepReferencePoint.FFCL: {
        return this.door.doorElevation.headClearance + this.door.actualHeight + this.door.doorElevation.undercut
      }
      case DoorPrepReferencePoint.TDCL:
      case DoorPrepReferencePoint.TDT: {
        return this.door.doorElevation.headClearance + 8
      }
    }
  }

  private hasCenterline(referencePoint: DoorPrepReferencePoint): boolean {
    switch (referencePoint) {
      case DoorPrepReferencePoint.BDCL:
      case DoorPrepReferencePoint.FFCL:
      case DoorPrepReferencePoint.TDCL:
        return true
      default:
        return false
    }
  }

  private goesToHeadOrFloor(referencePoint: DoorPrepReferencePoint): boolean {
    switch (referencePoint) {
      case DoorPrepReferencePoint.FFB:
      case DoorPrepReferencePoint.FFCL:
        return true
      default:
        return false
    }
  }

  drawShape(index: number, prep: IPrep, x: number): void {
    const prepCode = this.prepsComponent.configService.getPrepCodes(this.prepCategoryId).first((c) => c.id === prep.id)
    if (
      (prep.location instanceof Array ? prep.location[index] : prep.location).fromDimension(
        'door',
        this.unitOfMeasure
      ) <= 0
    ) {
      return
    }
    if (!prepCode?.shape || !prepCode?.height || !prepCode?.width) {
      return
    }
    const height = prepCode.height.fromDimension('door', this.unitOfMeasure)
    const width = prepCode.width.fromDimension('door', this.unitOfMeasure)
    const domReferencePoint = this.getReferenceDOMPoint(prep.referencePoint as DoorPrepReferencePoint)
    const modifier = domReferencePoint < this.door.height / 2 ? 1 : -1
    let location =
      domReferencePoint +
      uomSwitch(prep.location instanceof Array ? prep.location[index] : prep.location, 'door', this.unitOfMeasure) *
        modifier
    switch (prep.referencePoint) {
      case DoorPrepReferencePoint.BDB:
      case DoorPrepReferencePoint.FFB: {
        location -= height / 2
        break
      }
      case DoorPrepReferencePoint.TDT: {
        location += height / 2
        break
      }
    }
    switch (prepCode.shape) {
      case PrepShape.Circle:
        return this.drawCircle(x, location, height, width)
      case PrepShape.Rectangle:
        return this.drawRectangle(x, location, height, width)
    }
  }

  private drawCircle(x: number, y: number, height: number, width: number) {
    const circleStyles = {
      strokeWidth: '16px',
      fill: 'none',
      stroke: '#2680eb',
      strokeDasharray: '32px'
    }
    const circle = pipe(
      setCx(x),
      setCy(y),
      setRx(width / 2),
      setRy(height / 2),
      setStyles(circleStyles)
    )(createSVGElement('ellipse'))
    this.g.appendChild(circle)
  }

  private drawRectangle(x: number, y: number, height: number, width: number) {
    const rectStyles = {
      strokeWidth: '16px',
      fill: 'none',
      stroke: '#2680eb',
      strokeDasharray: '32px'
    }
    const rect = pipe(
      setX(x - width / 2),
      setY(y - height / 2),
      setWidth(width),
      setHeight(height),
      setStyles(rectStyles)
    )(createSVGElement('rect'))
    this.g.appendChild(rect)
  }

  drawSpecialPrep(prep: IPrep): HTMLInputElement {
    switch (prep.specialReferencePoint) {
      case DoorPrepSpecialReferencePoint.TDHSCL:
        prep.referencePoint = DoorPrepReferencePoint.TDCL
        return this.drawPrep(0, prep, true)
      case DoorPrepSpecialReferencePoint.TDLSCL:
        prep.referencePoint = DoorPrepReferencePoint.TDCL
        return this.drawPrep(0, prep, false)
      case DoorPrepSpecialReferencePoint.TDHST:
        prep.referencePoint = DoorPrepReferencePoint.TDT
        return this.drawPrep(0, prep, true)
      case DoorPrepSpecialReferencePoint.TDLST:
        prep.referencePoint = DoorPrepReferencePoint.TDT
        return this.drawPrep(0, prep, false)
      default:
        return this.drawSpecialTopPrep(prep)
    }
  }

  private drawSpecialTopPrep(prep: IPrep) {
    const x1 = 0
    const location = (prep.location as string).fromDimension('door', this.unitOfMeasure)
    const x2 = location
    this.g.insertAdjacentElement('afterbegin', this.createCenterline(x2, -this.horzDistance / 2 - 250 / 2, false))
    this.g.insertAdjacentElement(
      'afterbegin',
      this.createPolyLine([
        { x: x1, y: 0 },
        { x: x1, y: -this.horzDistance },
        { x: x2, y: -this.horzDistance },
        { x: x2, y: 0 }
      ])
    )

    const {inputContainer, inputElement} = this.createInput(
      0,
      [x1, x2].average((_) => _),
      -this.horzDistance - 80 - this._height,
      prep,
      false
    )
    this.g.insertAdjacentElement('beforeend', inputContainer)
    this.doorPrepComponent.resetAndFit()
    return inputElement
  }

  getDisplayValue(code: IPrepCode){
    if (!code) return ''
    return `${code.code} ${code.description ? '(' + code.description + ')' : ''}`
  }

  setPrepCode(prepCodeDisplayValue: string){
    if (!prepCodeDisplayValue) return
    const selectedCode = this.codes.first((c)=>this.getDisplayValue(c) === prepCodeDisplayValue)
    if (!selectedCode) return
    this.prep = merge(this.prep, selectedCode)
  }
}
