<div class="content">
  <h4>{{"home" | translate}}</h4>
  <div class="header column">
    <div class="column main">
      <div class="column cards create-card">
        <h4 class="header-question">{{ "What would you like to create today?" | translate}}</h4>
        <div class="row center">
          <div class="top-card" *ngIf="!environment.flags.hideDoorElevation">
            <img height="64" width="64" src="{{ environment.elevations.blobUrl }}common/Single-Door.svg" />
            <h2>{{'configureSingleDoor' | translate }}</h2>
            <button mat-flat-button color="primary" (click)="goTo('../doorElevation', 'Single')">Create</button> 
          </div>
          <div class="top-card" *ngIf="!environment.flags.hideDoorElevation">
            <img height="64" width="64" src="{{ environment.elevations.blobUrl }}common/Pair-Door.svg" />
            <h2>{{'configurePairOfDoors' | translate }}</h2>
            <button mat-flat-button color="primary" (click)="goTo('../doorElevation', 'Pair')">Create</button> 
          </div>
          <div class="top-card">
            <img height="64" width="64" src="{{ environment.elevations.blobUrl }}common/Draw-3-Sided-Frame.svg" />
            <h2>{{"configure3SidedFrame" | translate}}</h2>
            <button mat-flat-button color="primary" (click)="configureFrame$.emit()">Create</button>
          </div>
          <div class="top-card" *ngIf="!environment.flags.hideFrameElevation">
            <img height="64" width="64" src="{{ environment.elevations.blobUrl }}common/Draw-Frame-Elevation.svg" />
            <h2>{{'drawFrameElevation' | translate}}</h2>
            <button mat-flat-button color="primary" [routerLink]="'../frameElevation'">Create</button>
          </div>
        </div>  
      </div>
      <div class="column cards">
        <div class="actions">
          <div class="buttons">
            <div *ngFor="let tab of tabs; let i=index" (click)="selectedTabIndex = i" class="button" [ngClass]="{ selected: i === selectedTabIndex }">
              {{ tab.text | translate }}
            </div>
          </div>
          <div>
            <!-- search bar placeholder -->
          </div>
        </div>
        <mat-progress-bar mode="indeterminate" [ngStyle]="{visibility: loading ? 'visible': 'hidden'}" color="primary"></mat-progress-bar>
        <mat-tab-group animationDuration="500ms" [(selectedIndex)]="selectedTabIndex" class="oeo-hidden-mat-tab-group-header">
          <mat-tab [label]="'TITLE.myDrawings' | translate" >
            <ng-container *ngTemplateOutlet="ElevationDrawings; context: {$implicit: (doorElevationService.doorElevations$ | async)}"></ng-container>
          </mat-tab>
          <mat-tab [label]="'TITLE.myFrames' | translate">
            <ng-container *ngTemplateOutlet="ElevationDrawings; context: {$implicit: (frameElevationService.frameElevations$ | async)}"></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<ng-template #ElevationDrawings let-elevations>
  <div class="row section wrap">
    <div *ngFor="let elevation of elevations" class="content-card" (click)="goToElevation(elevation)">
      <img height="96" width="96" [src]="environment.elevations.blobUrl + 'drawings/' + elevation.thumbnail" />
      <div class="top">
        <span [title]="elevation.name">{{ elevation.name }}</span>
      <span>{{ elevation.modifiedOn | date: 'short' }}</span>
    </div>
    </div>
  </div>
</ng-template>
