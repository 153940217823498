import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[libMoveable]',
})
export class MoveableDirective implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private _isMoving: boolean;
  private _moveableElement: HTMLElement;
  private _moveableElementName: string;
  private _minX: number;
  private _minY: number;
  private _maxX: number;
  private _maxY: number;

  get moveableElement(): HTMLElement {
    return this._moveableElement ?? this.getMoveableElementByName(this.element) ?? this.element;
  }

  @Input() set moveableElement(value: HTMLElement) {
    this._moveableElement = value;
  }

  @Input() set moveableElementName(value: string) {
    this._moveableElementName = value;
  }

  private _top: number;
  @Input() set top(value: number) {
    if (isNaN(value)) {
      return;
    }
    if (!isNaN(this._bottom)) {
      throw new Error('Cannot set top and bottom');
    }
    this._top = value;
    if (!this.element) {
      return;
    }
    this.element.style.top = `${this._top}px`;
    this.topChange.next(this.top);
  }
  get top(): number {
    return this._top;
  }
  @Output() topChange = new EventEmitter<number>();

  private _left: number;
  @Input() set left(value: number) {
    if (isNaN(value)) {
      return;
    }
    if (!isNaN(this._right)) {
      throw new Error('Cannot set left and right');
    }
    this._left = value;
    if (!this.element) {
      return;
    }
    this.element.style.left = `${this._left}px`;
    this.leftChange.next(this.left);
  }
  get left(): number {
    return this._left;
  }
  @Output() leftChange = new EventEmitter<number>();

  private _bottom: number;
  @Input() set bottom(value: number) {
    if (isNaN(value)) {
      return;
    }
    if (!isNaN(this._top)) {
      throw new Error('Cannot set top and bottom');
    }
    this._bottom = value;
    if (!this.element) {
      return;
    }
    this.element.style.bottom = `${this._bottom}px`;
    this.bottomChange.next(this.bottom);
  }
  get bottom() {
    return this._bottom;
  }
  @Output() bottomChange = new EventEmitter<number>();

  private _right: number;
  @Input() set right(value: number) {
    if (isNaN(value)) {
      return;
    }
    if (!isNaN(this._left)) {
      throw new Error('Cannot set left and right');
    }
    this._right = value;
    if (!this.element) {
      return;
    }
    this.element.style.right = `${this._right}px`;
    this.rightChange.next(this.right);
  }
  get right() {
    return this._right;
  }
  @Output() rightChange = new EventEmitter<number>();

  private _zIndex: number;
  @Input() set zIndex(value: number) {
    if (isNaN(value)) {
      return;
    }
    this._zIndex = value;
  }

  get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.element.style.position = 'absolute';
    this.moveableElement.style.cursor = 'move';
    this.zIndex = this._zIndex;
    this.top = this._top;
    this.left = this._left;
    this.bottom = this._bottom;
    this.right = this._right;

    fromEvent(this.moveableElement, 'mousedown')
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => (this._isMoving = true));

    fromEvent(document, 'mousemove')
      .pipe(takeUntil(this._destroy$))
      .subscribe((event: MouseEvent) => {
        const relativeParentRect = this.getRelativeParent(this.element);
        const rect = this.element.getBoundingClientRect();
        if (!this._isMoving) {
          return;
        }
        this.moveX(event, rect, relativeParentRect);
        this.moveY(event, rect, relativeParentRect);
      });

    fromEvent(document, 'mouseup')
      .pipe(takeUntil(this._destroy$))
      .subscribe(_ => (this._isMoving = false));
  }

  moveX(event: MouseEvent, rect: DOMRect, relativeParentRect: Partial<DOMRect>) {
    let movementX = event.movementX;
    if (event.movementX !== 0) {
      if (rect.right - event.movementX >= relativeParentRect.right && event.movementX > 0) {
        movementX = relativeParentRect.right - rect.right;
      }
      if (rect.left + event.movementX <= relativeParentRect.left && event.movementX < 0) {
        movementX = relativeParentRect.left - rect.left;
      }
    }
    if (!isNaN(this._left)) {
      this.left = this._left + movementX;
    }
    if (!isNaN(this._right)) {
      this.right = this._right - movementX;
    }
  }

  moveY(event: MouseEvent, rect: DOMRect, relativeParentRect: Partial<DOMRect>) {
    let movementY = event.movementY;
    if (event.movementY !== 0) {
      if (rect.bottom - event.movementY > relativeParentRect.bottom && event.movementY > 0) {
        movementY = relativeParentRect.bottom - rect.bottom;
      }
      if (rect.top + event.movementY <= relativeParentRect.top && event.movementY < 0) {
        movementY = relativeParentRect.top - rect.top;
      }
    }
    if (!isNaN(this._bottom)) {
      this.bottom = this._bottom - movementY;
    }
    if (!isNaN(this._top)) {
      this.top = this._top + movementY;
    }
  }

  getRelativeParent(element: HTMLElement): {
    right: number,
    bottom: number,
    left: number,
    top: number
  } {
    const position = window.getComputedStyle(element).position;
    if (position === 'relative' || element.parentElement == null) {
      const rect = element.getBoundingClientRect();
      this._minX = rect.left;
      this._minY = rect.top;
      this._maxX = rect.right;
      this._maxY = rect.bottom;
      return {
        right: rect.right,
        bottom: rect.bottom,
        left: rect.left,
        top: rect.top,
      } as const
    }
    return this.getRelativeParent(element.parentElement);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  private getMoveableElementByName(element: Element): HTMLElement | null {
    if (this._moveableElementName == null) {
      return null;
    }
    if (element.tagName.toLowerCase() === this._moveableElementName.toLowerCase()) {
      return element as HTMLElement;
    }
    for (const child of Array.from(element.children)) {
      return this.getMoveableElementByName(child);
    }
    return null;
  }
}
