import { AdditionalLabel } from '../../../core/enums/additional-label';
import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { DoorFireRating } from '../door-fire-rating';
import { DoorSeries } from '../door-series';
import { DoorSubType } from '../door-subtype';

export function DoorAdditionalLabels(series: DoorSeries, subType: DoorSubType, fireRating: DoorFireRating) {
  const additionalLabels = [...(DoorSeriesInfos[series]?.subTypes[subType]?.additionalLabels ?? [])];
  if([DoorFireRating._180,DoorFireRating._20M, DoorFireRating._45,   DoorFireRating._60, DoorFireRating._90].any(
    fr => fr === fireRating
  )){
    additionalLabels.push(AdditionalLabel.PP, AdditionalLabel.SMOKE, AdditionalLabel.E330PP, AdditionalLabel.E330SMOKE);
  }
  if(DoorFireRating.WHB === fireRating){
    additionalLabels.push(AdditionalLabel.SMOKE, AdditionalLabel.E330SMOKE);
  }
  return additionalLabels;
}
