import { Component, Input } from '@angular/core'
import { TableColumn } from '../../../../types'

export type TDateComponentData = {
  hasNotification?: () => boolean
}

@Component({
  selector: 'oa-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent<C = Component> {
  @Input() data!: string | number | boolean
  @Input() row!: TDateComponentData
  @Input() column!: TableColumn<C>

  showNotification() {
    if (!this.row?.hasNotification) return false
    return this.column.notification && this.row.hasNotification()
  }
}
