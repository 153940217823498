import { UnitOfMeasure } from "../enums/unit-of-measure";

export function applyUnitOfMeasure(rawValue: string | number, unitOfMeasure: UnitOfMeasure): string {
  let value = `${rawValue}`;
  switch (unitOfMeasure) {
    case UnitOfMeasure.Imperial: {
      value = value.replace(/[^0-9, /, ., ']/g, ''); // allow only numeric, period, forward slash & single quote
      break;
    }
    case UnitOfMeasure.Metric: {
      value = value.replace(/[^0-9]/g, ''); // allow only numeric
      break;
    }
  }

  let measure = '';
  if (value && value.trim().length && value !== 'null') {
    switch (unitOfMeasure) {
      case UnitOfMeasure.Imperial: {
        const measurement = [...value]
          .skip(value.length - 1)
          .take(1)
          .join('');
        if (measurement !== `'` && measurement !== `"`) {
          measure = '"';
        }
        break;
      }
      case UnitOfMeasure.Metric: {
        const measurement = [...value]
          .skip(value.length - 2)
          .take(2)
          .join('');
        if (measurement !== `mm`) {
          measure = 'mm';
        }
        break;
      }
    }
  }
  return value.trim() + measure;
}
