<div class="left">
  <div class="top">
    <input
    type="search"
    ngModel
    (ngModelChange)="search$.next($event)"
    [placeholder]="'ACTIONS.search' | translate"
    />
  </div>
  <div class="bottom">
    <div
      class="location"
      [ngClass]="{ active: c === category }"
      *ngFor="let c of categories$ | async"
      (click)="category = c"
      [title]="c.name"
    >
      {{ c.name }}
    </div>
  </div>
</div>
<div class="right">
  <mat-progress-bar mode="indeterminate" [ngStyle]="{visibility: ((dataLoading$ | async) || loading)? 'visible': 'hidden'}"></mat-progress-bar>
  <lib-location *ngIf="category" [category]="category" (loading)="loading = $event"></lib-location>
</div>
