<div class="stepper">
  <ng-container class="step" *ngFor="let step of steps; let i = index">
    <!-- line -->
    <div class="line-wrapper" *ngIf="i > 0">
      <div class="line" aria-hidden="true" [ngClass]="{ disabled: !step.completed }"></div>
    </div>

    <div class="step-wrapper">
      <button
        class="step"
        [matTooltip]="smallDisplayWidth ? step.name : ''"
        [disabled]="!step.completed"
        [ngClass]="{ disabled: !step.completed, active: isActive(i), completed: steps[i].completed }"
        (click)="routed ? router.navigate(['./', 'step-' + (i + 1)]) : setStep(step)"
      >
        <ng-container>
          {{ i + 1 }}
        </ng-container>
        <span class="focus-outline" role="presentation"></span>
      </button>
      <span
        class="label detail"
        [ngClass]="{ disabled: !step.completed, active: isActive(i), completed: steps[i].completed }"
        >{{ step.name }}</span
      >
    </div>
  </ng-container>
</div>
