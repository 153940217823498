<svg
  id="svgRef"
  #svgRef
  libRuler
  [unitOfMeasure]="frameElevation.unitOfMeasure"
  type="frame"
  [zoom]="zoom"
  [ruler]="ruler"
  [grid]="grid"
>
  <g>
    <svg #containerRef>
      <rect #rectRef [attr.height]="height" [attr.width]="width" style="stroke: #7f7f7f; fill: white"></rect>
      <g #intersectableContainerRef></g>
      <g #lineContainerRef></g>
      <text #textRef></text>
      <g #profileContainerRef></g>
    </svg>
  </g>
</svg>
