import { pick, omit } from 'lodash';
import { pipe } from 'lodash/fp';
import { appendChildrenElements, createSVGElement, setAttributes, setStyles, setX1, setX2, setY1, setY2 } from '../../../../core/helpers/svg-functions';
import { Panel } from '../../../abstracts/panel';

const panelStyles = {
  stroke: 'black',
  strokeWidth: '4px',
  fill: 'lightgrey',
  fillOpacity: '0.35',
  transform: 'translate(2,2)',
};

const lineStyles = {
  stroke: 'rgb(127, 127, 127)',
  strokeWidth: '2px',
};

export class HDArchedPanel extends Panel {
  constructor(x: number, y: number, width: number, height: number) {
    super(x, y, width, height);
  }

  drawCutout() {
    const multiplier = [0, 0.25, 1.75, 2, 3];
    const archHeight = 160;
    const distance = 80;
    const outerRects = new Array(5).fill(0).map((_, i) => {
      return pipe(
        setStyles(omit(panelStyles,['transform'])),
        setAttributes({...pick(panelStyles,['transform']),
        d:
        `M ${2 + distance * multiplier[i]} ${archHeight + distance * multiplier[i]} Q ${(this.width - 2) / 2} ${
          2 + distance * multiplier[i]
        } ${this.width - distance * multiplier[i] - 2} ${archHeight + distance * multiplier[i]} L ${
          this.width - distance * multiplier[i] - 2
        } ${this.height - distance * multiplier[i] - 2} L ${2 + distance * multiplier[i]} ${
          this.height - distance * multiplier[i] - 2
        } L ${2 + distance * multiplier[i]} ${archHeight + distance * multiplier[i]}`}),
      )(createSVGElement('path'));
    })

    const topLeft = pipe(
      setX1(distance * 2 + 2),
      setX2(distance * 3),
      setY1(distance * 2 + archHeight + 2),
      setY2(distance * 3 + archHeight),
      setStyles(lineStyles)
    )(createSVGElement('line'));

    const topRight = pipe(
      setX1(this.width - distance * 2 - 2),
      setX2(this.width - distance * 3),
      setY1(distance * 2 + archHeight + 2),
      setY2(distance * 3 + archHeight),
      setStyles(lineStyles)
    )(createSVGElement('line'));

    const bottomRight = pipe(
      setX1(this.width - distance * 2 - 2),
      setX2(this.width - distance * 3),
      setY1(this.height - distance * 2 - 2),
      setY2(this.height - distance * 3),
      setStyles(lineStyles)
    )(createSVGElement('line'));

    const bottomLeft = pipe(
      setX1(distance * 2 + 2),
      setX2(distance * 3),
      setY1(this.height - distance * 2 - 2),
      setY2(this.height - distance * 3),
      setStyles(lineStyles)
    )(createSVGElement('line'));

    return pipe(
      setAttributes({transform:`translate(${this.x}, ${this.y})` }),
      appendChildrenElements([...outerRects, topLeft, topRight, bottomRight, bottomLeft]),
    )(createSVGElement('g'));
  }
}
