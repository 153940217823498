import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, ViewChild } from '@angular/core'
import { uomSwitch } from '../../../../core/functions/uomSwitch'
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location'
import { IPrepCode } from '../../../../core/interfaces/i-prep-code'
import { debounceTime, takeUntil } from 'rxjs/operators'
import { DoorPrepReferencePoint } from '../../../enums/door-prep-reference-point'
import { IDoorHingePrep } from '../../../interfaces/preps/i-door-hinge-prep'
import { DoorPrepComponent } from '../door-prep/door-prep.component'
import { PrepBaseComponent } from '../prep-base.component'
import { PrepsComponent } from '../preps.component'
import { DoorHingeBackset } from '../../../enums/door-hinge-backset'
import { ElectricHingeLocation } from '../../../enums/electric-hinge-location'
import { UnitOfMeasure } from '../../../../core/enums/unit-of-measure'
import { DoorPrepCategoryIds } from '../../../../core/enums/prep-category-ids'
import { getEnumValues } from '@oeo/common'
import { DutchStyles } from '../../../enums/door-style'

@Component({
  selector: 'lib-hinge-prep',
  templateUrl: './hinge-prep.component.html',
  styleUrls: ['./hinge-prep.component.scss', '../prep-base.component.scss']
})
export class HingePrepComponent extends PrepBaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild(DoorPrepComponent, { static: false }) doorPrepComponent: DoorPrepComponent
  codes: IPrepCode[]
  public get code(): IPrepCode {
    return this.prep.code ? this.codes.first((c) => c.code === this.prep.code) : null
  }

  public get noHinge() {
    return !this.code || this.code.code == 'OMIT HINGE'
  }

  public get prep(): IDoorHingePrep {
    return this.door?.hingePrep
  }

  public set prep(value: IDoorHingePrep) {
    this.door.hingePrep = value
  }

  public get isContinuousHinge(): boolean {
    return this.code?.code?.trim() === 'Continuous Hinge'
  }

  public get isDP(): boolean {
    return this.prepsComponent.configService.doorElevation.series == "DP"
  }

  private _locations: IPrepCategoryLocation[] = []
  public get locations(): IPrepCategoryLocation[] {
    return [3, 4].any((q) => q === this.prep.quantity) || this.isContinuousHinge || this.isDutchStyle && this.isContinuousHinge
      ? this._locations
      : this._locations?.filter((l) => l === this.specialPrepLocation)
  }
  public set locations(value: IPrepCategoryLocation[]) {
    this._locations = value
  }

  get referencePoints(): DoorPrepReferencePoint[] {
    return getEnumValues(DoorPrepReferencePoint)
  }

  get backsets(): DoorHingeBackset[] {
    if(this.isDP) {
      return getEnumValues(DoorHingeBackset).filter(b => b == DoorHingeBackset._14)
    }
    return getEnumValues(DoorHingeBackset)
  }

  highFrequencies = ['Top', 'All']

  public get quantityOptions(): number[] {
    if (this.isDutchStyle) {
      if (this.isContinuousHinge) return [2]
      else return [4]
    }
    return this.isContinuousHinge ? [1] : [1, 2, 3, 4, 5]
  }

  public get electricHingeSelected(): boolean {
    return this.prep.isElectricHinge == 'Yes'
  }

  get isSpecialPrep(){
    return super.isSpecialPrep || this.isContinuousHinge
  }

  get electricHingeLocations() {
    return getEnumValues(ElectricHingeLocation)
  }

  locationsWithinHinge = ['Top part of Hinge', 'Middle Part of Hinge', 'Bottom Part of Hinge'] as const

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, DoorPrepCategoryIds.HINGE)
    this.locations = prepsComponent.configService.prepCategories
      .first((c) => c.id === 7)
      ?.prepCategoryLocations.concat([this.specialPrepLocation])
    this.codes = this.codes.concat([this.specialPrepCode])

    this.setDefaultPrepValues()
    this.setSpecial()
  }

  setDefaultPrepValues(){
    if(!this.prep){
      this.prep = {} as IDoorHingePrep
    }
    this.prep.referencePoint = this.prep.referencePoint  ?? DoorPrepReferencePoint.TDT
    this.prep.backset = this.prep.backset ?? DoorHingeBackset._14
    this.prep.quantity = this.prep.quantity ?? this.defaultPrepQuantity
    this.prep.isElectricHinge = this.prep.isElectricHinge ?? 'No'
    const defaultPrepCode = this.codes.find(({ code }) => code === '4 1/2 UNIVERSAL HINGE')
    if (!this.code && defaultPrepCode){
      this.setPrepCode(this.getDisplayValue(defaultPrepCode))
    }
  }

  get isDutchStyle(): boolean {
    return DutchStyles.includes(this.prepsComponent.configService.currentDoor.style)
  }

  get defaultPrepQuantity() {
    return this.prepsComponent.configService.currentDoor.height > '90"'.fromDimension('door', UnitOfMeasure.Imperial)
      ? 4
      : 3
  }

  ngAfterViewInit(): void {
    this.draw$.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(() => this.drawPreps())
    this.prepFieldsForm.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(()=>{
      // TODO: How to test this business logic?
      /* For Non Electric Hinge Prep codes */
      if (!this.electricHingeSelected){
        this.prep.electricHingeLocation = null
        this.prep.conduit = null
        this.prep.locationWithinHinge = null
      }

      /* The Prep Code can only be Continous Hinge if the Quantity is 1 */
      if(this.prep.quantity === 1){
        const continiousHinge = this.codes.first((({code})=>code.trim() === 'Continuous Hinge'))
        this.setPrepCode(this.getDisplayValue(continiousHinge))
      }
      /**
       * Default quantity to 4 for dutch doors (Non-Continuous Hinge) and 2 for dutch doors (Continuous Hinge
       * Default quantity to 1 for non-dutch doors with continuous hinge
       */
      if (this.isDutchStyle) {
        if (this.isContinuousHinge) {
          this.prep.quantity = 2
        }
        else this.prep.quantity = 4
      }
      else{
        if (this.isContinuousHinge) {
          this.prep.quantity = 1
        }
      }
      /* For Omit Hinge, the prep quantity is null */
      if (this.noHinge) {
        this.prep.quantity = null
      }
      /* Continuous Hinges DO NOT have a location type */
      if(this.isContinuousHinge){
        this.prep.locationType = null
      }
      else if(this.prep.code && !this.prep.locationType){
        if (this.locations?.length === 1) {
          this.prep.locationType = this.locations.first()?.value
        }
        /** If door has a prep location preference, then set it */
        else if(this.prepsComponent.configService.doorElevation?.prepLocationPreference){
          this.prep.locationType = this.prepsComponent.configService.doorElevation.prepLocationPreference
        }
      }

      if (!(this.prep.location instanceof Array)) {
        this.prep.location = []
      }
      const locations = (this.prep.location as string[]).take(this.prep.quantity)
      this.prep.location = locations.concat(
        new Array(this.prep.quantity - locations.length).fill(
          uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure),
          0,
          this.prep.quantity - locations.length
        )
      )
      this.draw$.next()
    })
  }

  ngOnDestroy(): void {
    super.destroy()
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear()
    if (this.prep.locationType !== this.specialPrepLocation.value || !this.prep.quantity) {
      this.prep.location = Array.isArray(this.prep.location)? []: '0"'
      this.doorPrepComponent.resetAndFit();
      return
    }
    for (let i = 0; i < this.prep.quantity; i++) {
      this.prepLocationInputs.push(this.drawPrep(i, this.prep, true))
      this.drawShape(i, this.prep, 0)
    }
  }
}
