import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { Profile } from '../../abstracts/profile';
import { ProfileTemplate, ProfileTemplateDimensionPosition } from '../../abstracts/profile-template';
import { Direction } from '../../enums/direction';
import { GlazingBeadLocation } from '../../enums/glazing-bead-location';
import { GlassExport } from '../../models/exports/glass-export';

export class FSROS extends ProfileTemplate {
  private _a: number;
  private _d: number;
  private _e: number;
  private _g: number;

  get stopHeight1() {
    return this.dimensions.D.get();
  }
  get stopHeight2() {
    return NaN;
  }
  get backBend1() {
    return this.dimensions.A.get();
  }
  get backBend2() {
    return this.dimensions.G.get();
  }
  get doorSideRabbet1() {
    return this._e;
  }
  get oppositeDoorSideRabbet1() {
    return NaN;
  }
  get doorSideRabbet2() {
    return NaN;
  }
  get oppositeDoorSideRabbet2() {
    return NaN;
  }

  readonly dimensions = {
    A: {
      direction: Direction.Up,
      get: () => this._a,
      set: (value: number) => {
        this._a = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MinX,
    },
    B: {
      direction: Direction.Right,
      get: () => this.profile.face + this._d,
      position: () => ProfileTemplateDimensionPosition.MinY,
    },
    C: {
      direction: Direction.Down,
      get: () => this.profile.jambDepth - this._e,
      position: () => ProfileTemplateDimensionPosition.MaxX,
    },
    D: {
      direction: Direction.Left,
      get: () => this._d,
      set: (value: number) => {
        this._d = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MaxY,
      kerf: true,
    },
    E: {
      direction: Direction.Down,
      get: () => this._e,
      set: (value: number) => {
        this._e = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MaxX,
      glazingBead:
        !!this.glazingBeads[0] && this.glassPosition === GlazingBeadLocation.DoorSide
          ? {
              direction: Direction.Right,
              start: () => ({ x: 0, y: this.glassThickness }),
            }
          : null,
    },
    F: {
      direction: Direction.Left,
      get: () => this.profile.face,
      position: () => ProfileTemplateDimensionPosition.MaxY,
      doorSide: true,
    },
    G: {
      direction: Direction.Up,
      get: () => this._g,
      set: (value: number) => {
        this._g = value;
        this.update();
      },
      position: () => ProfileTemplateDimensionPosition.MinX,
    },
  };

  constructor(profile: Profile, glazingBeads: GlassExport[]) {
    super(profile, glazingBeads);
    this._a = this._g = this.defaultBackbend;
    this._d = this.defaultStopHeight;
    this._e = this.defaultDoorSideRabbet;
    this.loadDimensions();
  }
}
