import { Component, OnInit } from '@angular/core'
import { AnnouncementsService } from '../../../services/announcements/announcements.service'
import { Announcement } from '../../../models/announcement.model'
import { ActivatedRoute, Router } from '@angular/router'
import { HelperService } from '../../../services/helper/helper.service'
import { firstValueFrom } from 'rxjs'
import { StorageService } from '../../../services/local-storage/local-storage.service'

@Component({
  selector: 'oa-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  loading = true
  announcement: Announcement
  language: string

  constructor(
    private announcementsSvc: AnnouncementsService,
    private route: ActivatedRoute,
    private router: Router,
    private helperSvc: HelperService,
    private storageService: StorageService
  ) {}

  async ngOnInit() {
    const annoucementId = this.route.snapshot.params.notificationId

    try {
      this.language = this.storageService.getItem('i18n') || 'en'
      const announcement = await firstValueFrom(this.announcementsSvc.getById(annoucementId))
      if (!announcement) {
        this.router.navigate(['../'], { relativeTo: this.route })
      }
      this.announcement = announcement
      this.loading = false
    } catch (err) {
      this.helperSvc.handleError(err.message)
      this.loading = false
    }

    this.announcementsSvc.markAsRead(annoucementId)
  }
}
