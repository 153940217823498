import { UnitOfMeasure } from '../../../core/enums/unit-of-measure'
import { uomSwitch } from '../../../core/functions/uomSwitch'
import { Cutout } from '../../abstracts/cutout'
import { Panel } from '../../abstracts/panel'
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode'
import { Embossment } from '../../models/cutout-types/embossment'
import { SquarePanel } from '../../models/cutout-types/embossments/square-panel'
import { Door } from '../../models/door'
import { CutoutExport } from '../../models/exports/cutout-export'
import { EmbossedDoorType } from '../../abstracts/embossed-door-type'

export class E6Type extends EmbossedDoorType {
  cutouts: Cutout[]
  private width = uomSwitch('23"', 'door', this.door.doorElevation.unitOfMeasure)
  private height = uomSwitch(this.isTall ? '81 1/8"' : '65 1/8"', 'door', this.door.doorElevation.unitOfMeasure)
  private bottomRail = uomSwitch('10"', 'door', this.door.doorElevation.unitOfMeasure)

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts)
    if (this.door.height.toDimension('door', UnitOfMeasure.Imperial) === '80"') {
      this.bottomRail = uomSwitch('8"', 'door', this.door.doorElevation.unitOfMeasure)
    }

    this.cutouts = [this.getEmbossment()]
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]))
    }
  }

  get totalEmbossmentHeight(): number {
    return this.height
  }

  get maxEmbossmentDistances() {
    return {
      fromTop: '10"'.fromDimension('door', UnitOfMeasure.Imperial),
      fromBottom: '15 3/8"'.fromDimension('door', UnitOfMeasure.Imperial)
    }
  }

  private getEmbossment(): Cutout {
    return new Embossment(
      'Embossment',
      this,
      (this.door.actualWidth - this.width) / 2,
      this.door.actualHeight - this.height - this.bottomRail,
      this.width,
      this.height,
      VerticalCutoutDimensionMode.Bottom,
      HorizontalCutoutDimensionMode.Lock,
      this.getPanels()
    ).setMinMaxDistance(this.minMaxEmbossmentDimensions)
  }

  get isTall(): boolean {
    return this.door.height >= uomSwitch(`8'`, 'door', this.door.doorElevation.unitOfMeasure)
  }

  get dimensions() {
    const topPanelHeight = this.isTall
      ? uomSwitch('13"', 'door', this.door.doorElevation.unitOfMeasure)
      : uomSwitch('7 1/16"', 'door', this.door.doorElevation.unitOfMeasure)
    const middlePanelHeight = this.isTall
      ? uomSwitch('27.5"', 'door', this.door.doorElevation.unitOfMeasure)
      : uomSwitch('25 7/16"', 'door', this.door.doorElevation.unitOfMeasure)
    const bottomPanelHeight = this.isTall
      ? uomSwitch('25.5"', 'door', this.door.doorElevation.unitOfMeasure)
      : uomSwitch('21 9/16"', 'door', this.door.doorElevation.unitOfMeasure)
    const verticalSpaceBetween1 = this.isTall
      ? uomSwitch('6 9/16"', 'door', this.door.doorElevation.unitOfMeasure)
      : uomSwitch('4 9/16"', 'door', this.door.doorElevation.unitOfMeasure)
    const verticalSpaceBetween2 = this.isTall
      ? uomSwitch('8 9/16"', 'door', this.door.doorElevation.unitOfMeasure)
      : uomSwitch('6 1/2"', 'door', this.door.doorElevation.unitOfMeasure)

    return {
      topPanelHeight,
      middlePanelHeight,
      bottomPanelHeight,
      verticalSpaceBetween1,
      verticalSpaceBetween2
    }
  }

  private getPanels(): Panel[] {
    const horizontalSpaceBetween = uomSwitch('5"', 'door', this.door.doorElevation.unitOfMeasure)
    const width = uomSwitch('9"', 'door', this.door.doorElevation.unitOfMeasure)
    return [
      new SquarePanel(0, 0, width, this.dimensions.topPanelHeight),
      new SquarePanel(width + horizontalSpaceBetween, 0, width, this.dimensions.topPanelHeight),

      new SquarePanel(
        0,
        this.dimensions.topPanelHeight + this.dimensions.verticalSpaceBetween1,
        width,
        this.dimensions.middlePanelHeight
      ),
      new SquarePanel(
        width + horizontalSpaceBetween,
        this.dimensions.topPanelHeight + this.dimensions.verticalSpaceBetween1,
        width,
        this.dimensions.middlePanelHeight
      ),

      new SquarePanel(
        0,
        this.dimensions.topPanelHeight +
          this.dimensions.verticalSpaceBetween1 +
          this.dimensions.middlePanelHeight +
          this.dimensions.verticalSpaceBetween2,
        width,
        this.dimensions.bottomPanelHeight
      ),
      new SquarePanel(
        width + horizontalSpaceBetween,
        this.dimensions.topPanelHeight +
          this.dimensions.verticalSpaceBetween1 +
          this.dimensions.middlePanelHeight +
          this.dimensions.verticalSpaceBetween2,
        width,
        this.dimensions.bottomPanelHeight
      )
    ]
  }
}
