import { Approval } from '../../../core/enums/approval';
import { DoorSeriesInfos } from '../../interfaces/door-series-infos';
import { approvalCores } from '../../rules/approval';
import { fireRatingCores } from '../../rules/fire-rating';
import { steelStiffenCores } from '../../rules/steel-stiffen';
import { DoorCore } from '../door-core';
import { DoorFireRating } from '../door-fire-rating';
import { DoorSeries } from '../door-series';
import { DoorSteelStiffen } from '../door-steel-stiffen';
import { DoorSubType } from '../door-subtype';

export function DoorCores(
  series: DoorSeries,
  subType: DoorSubType,
  approval: Approval,
  fireRating: DoorFireRating,
  steelStiffen: DoorSteelStiffen
): DoorCore[] {
  const cores = [...(DoorSeriesInfos[series]?.subTypes[subType]?.cores ?? [])];
  approvalCores(cores, approval);
  fireRatingCores(cores, fireRating);
  steelStiffenCores(cores, steelStiffen);
  if ((subType === DoorSubType.IMP || subType === DoorSubType.IMPEMB) && steelStiffen !== DoorSteelStiffen.NONE) {
    cores.remove(DoorCore.PU);
  }
  return cores;
}
