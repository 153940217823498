import { CutoutOrderSize } from './cutout-order-size';

export enum DoorLiteKit {
  _110 = 'All Metal Model 110',
  _110B = 'All Metal Model 110B',
  _105 = 'All Metal Model 105',
  _105M = 'All Metal Model 105M',
  _115 = 'All Metal Model 115',
  _115L1 = 'All Metal Model 115L1 Lead Lined 60M Fire',
  _115L3 = 'All Metal Model 115L3 Lead Lined 60M Fire',
  _110D1 = 'All Metal Model 110D1 Double Glass No Fire',
  _110D2 = 'All Metal Model 110D2 Double Glass No Fire',
  _118M = 'All Metal Model 118M 3 HR Rating',
  _118MW = 'All Metal Model 118MW Special Glass',
  VLFDG = 'Air Louvers VLFDG 3 HR Rating',
  VLFDGLL = 'Air Louvers VLFDG-LL Lead Lined 1.5 HR Rating',
  VLFLL = 'Air Louvers VLF-LL Lead Lined No Fire',
  SLIMLINE = 'Air Louvers SLIMLINE',
  VLFIG = 'Air Louvers VLF-IG (1/4" - 1 1/2" IG)',
  VLFEZ = 'Air Louvers VLF-EZ',
  VLFEZHRC = 'Air Louvers VLFEZHRC Windstorm Rated',
  VLFEZIG = 'Air Louvers VLFEZ-IG (5/16" - 1" IG)',
  VLFIGHRC = 'Air Louvers VLFIGHRC Windstorm Rated',
  VLFIGHRCF = 'Air Louvers VLFIGHRC-F (1" - 7/16" IG) Windstorm Fire',
  VLFPVC = 'Air Louvers VLF-PVC',
  VLFR12 = 'Air Louvers VLF-R-12 (12" Diameter)',
  VLFR18 = 'Air Louvers VLF-R-18 (18" Diameter)',
  VLFR24 = 'Air Louvers VLF-R-24 (24" Diameter)',
  VSL = 'Air Louvers VSL',
  VSIG = 'Air Louvers VSIG 3 HR Rating',
  LGLF100 = 'National Guard L-GLF100',
  LFRA100 = 'National Guard L-FRA100 Low Pro',
  GLF100TB = 'National Guard GLF100TB',
  BLF75 = 'Anemostat BLF-75',
  BLF123 = 'Anemostat BLF-123',
  LOPRO = 'Anemostat LOPRO',
  LOPROIS = 'Anemostat LOPRO-IS',
  FGSDBL = 'Anemostat FGS-DBL',
  FGSIS = 'Anemostat FGS-IS',
  BR7 = 'Anemostat BR-7',
  SUNBURST = 'ODL Sunburst Lite Kit',
  _651 = 'ODL 651 Lite 22x10 Kit 1" Thick Glass',
  _664 = 'ODL 664 Lite 22x6 Kit 1/2 or 1" Thick Glass',
  _670 = 'ODL 670 Lite 20x36 Kit 1" Thick Glass',
  _672 = 'ODL 672 Lite 20x36 Kit 1" Thick Glass',
  _681 = 'ODL 681 Lite 22x36 Kit 1" Thick Glass',
  _684 = 'ODL684 Lite 22x36 Kit 1" Thick Glass',
  _680 = 'ODL 680 Lite 6x8 Kit 1/2" Thick Glass',
}

export const DoorLiteOrderSizes: { [key in DoorLiteKit]: CutoutOrderSize } = {
  [DoorLiteKit._110]: CutoutOrderSize.V,
  [DoorLiteKit._110B]: CutoutOrderSize.V,
  [DoorLiteKit._105]: CutoutOrderSize.V,
  [DoorLiteKit._105M]: CutoutOrderSize.V,
  [DoorLiteKit._115]: CutoutOrderSize.V,
  [DoorLiteKit._115L1]: CutoutOrderSize.V,
  [DoorLiteKit._115L3]: CutoutOrderSize.V,
  [DoorLiteKit._110D1]: CutoutOrderSize.V,
  [DoorLiteKit._110D2]: CutoutOrderSize.V,
  [DoorLiteKit._118M]: CutoutOrderSize.V,
  [DoorLiteKit._118MW]: CutoutOrderSize.V,
  [DoorLiteKit.VLFDG]: CutoutOrderSize.C,
  [DoorLiteKit.VLFDGLL]: CutoutOrderSize.C,
  [DoorLiteKit.VLFLL]: CutoutOrderSize.C,
  [DoorLiteKit.SLIMLINE]: CutoutOrderSize.C,
  [DoorLiteKit.VLFIG]: CutoutOrderSize.C,
  [DoorLiteKit.VLFEZ]: CutoutOrderSize.C,
  [DoorLiteKit.VLFEZHRC]: CutoutOrderSize.C,
  [DoorLiteKit.VLFEZIG]: CutoutOrderSize.C,
  [DoorLiteKit.VLFIGHRC]: CutoutOrderSize.C,
  [DoorLiteKit.VLFIGHRCF]: CutoutOrderSize.C,
  [DoorLiteKit.VLFPVC]: CutoutOrderSize.C,
  [DoorLiteKit.VLFR12]: CutoutOrderSize.C,
  [DoorLiteKit.VLFR18]: CutoutOrderSize.C,
  [DoorLiteKit.VLFR24]: CutoutOrderSize.C,
  [DoorLiteKit.VSL]: CutoutOrderSize.C,
  [DoorLiteKit.VSIG]: CutoutOrderSize.C,
  [DoorLiteKit.LGLF100]: CutoutOrderSize.C,
  [DoorLiteKit.LFRA100]: CutoutOrderSize.C,
  [DoorLiteKit.GLF100TB]: CutoutOrderSize.C,
  [DoorLiteKit.BLF75]: CutoutOrderSize.C,
  [DoorLiteKit.BLF123]: CutoutOrderSize.C,
  [DoorLiteKit.LOPRO]: CutoutOrderSize.C,
  [DoorLiteKit.LOPROIS]: CutoutOrderSize.C,
  [DoorLiteKit.FGSDBL]: CutoutOrderSize.C,
  [DoorLiteKit.FGSIS]: CutoutOrderSize.C,
  [DoorLiteKit.BR7]: CutoutOrderSize.C,
  [DoorLiteKit.SUNBURST]: CutoutOrderSize.C,
  [DoorLiteKit._651]: CutoutOrderSize.C,
  [DoorLiteKit._664]: CutoutOrderSize.C,
  [DoorLiteKit._670]: CutoutOrderSize.C,
  [DoorLiteKit._672]: CutoutOrderSize.C,
  [DoorLiteKit._681]: CutoutOrderSize.C,
  [DoorLiteKit._684]: CutoutOrderSize.C,
  [DoorLiteKit._680]: CutoutOrderSize.C,
};
