import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs';
import { uomSwitch } from '../../../../core/functions/uomSwitch';
import { IPrepCategoryLocation } from '../../../../core/interfaces/i-prep-category-location';
import { IPrepCode } from '../../../../core/interfaces/i-prep-code';
import { FrameHingeBackset } from '../../../enums/frame-hinge-backset';
import { FramePrepReferencePoint, VerticalFramePrepReferencePoints } from '../../../enums/frame-prep-reference-point';
import { HingeSubTypes } from '../../../enums/stick-subtype';
import { IFrameHingePrep } from '../../../interfaces/preps/i-frame-hinge-prep';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepBaseComponent } from '../prep-base.component';
import { PrepsComponent } from '../preps.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';
import { getEnumValues } from '@oeo/common';
import { ElectricHingeLocation } from '../../../enums/electric-hinge-location';

@Component({
  selector: 'lib-hinge-prep',
  templateUrl: './hinge-prep.component.html',
  styleUrls: ['./hinge-prep.component.scss', '../prep-base.component.scss'],
})
export class HingePrepComponent extends PrepBaseComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;
  subTypes = HingeSubTypes;
  codes: IPrepCode[];
  private _code: IPrepCode;
  public get code(): IPrepCode {
    return this._code;
  }
  public set code(value: IPrepCode) {
    this._code = value;
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    if (this.prepsComponent.data?.frame.prepLocationPreference && !this.location){
      this.location = this.locations.first(({value}) => value === this.prepsComponent.data.frame.prepLocationPreference);
    }
    this.updateValue();
  }
  private _quantity: number;
  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;
    if (this.locations.length === 1 && !!this.code) {
      this.location = this.locations.first();
      return;
    }
    this.updateValue();
  }

  public get isContinuousHinge(): boolean {
    return this.code?.code?.trim() === 'Continuous Hinge'
  }

  _isElectricHinge: string
  get isElectricHinge(): string {
    return this._isElectricHinge
  }

  set isElectricHinge(value: string) {
    this._isElectricHinge = value
    this.prep.isElectricHinge = value
    this.prep.electricHingeLocation = null
    this.prep.conduit = null
    this.prep.locationWithinHinge = null
    this.updateValue()
  }

  public get electricHingeSelected(): boolean {
    return this._isElectricHinge == 'Yes'
  }

  get electricHingeLocations() {
    return getEnumValues(ElectricHingeLocation)
  }

  locationsWithinHinge = ['Top part of Hinge', 'Middle Part of Hinge', 'Bottom Part of Hinge']

  public get prep(): IFrameHingePrep {
    return this.frameElevation.hingePrep;
  }
  public set prep(value: IFrameHingePrep) {
    this.frameElevation.hingePrep = value;
  }

  private _locations: IPrepCategoryLocation[];
  public get locations(): IPrepCategoryLocation[] {
    return this.quantity > 4 || this.quantity < 3
      ? this._locations.filter(l => l === this.specialPrepLocation)
      : this._locations;
  }
  public set locations(value: IPrepCategoryLocation[]) {
    this._locations = value;
  }
  private _location: IPrepCategoryLocation;
  get location(): IPrepCategoryLocation {
    return this._location;
  }
  set location(value: IPrepCategoryLocation) {
    this._location = value;
    this.updateValue();
  }

  get referencePoints(): FramePrepReferencePoint[] {
    return VerticalFramePrepReferencePoints;
  }
  private _referencePoint: FramePrepReferencePoint;
  get referencePoint(): FramePrepReferencePoint {
    return this._referencePoint;
  }
  set referencePoint(value: FramePrepReferencePoint) {
    this._referencePoint = value;
    this.updateValue();
  }
  get backsets(): FrameHingeBackset[] {
    return getEnumValues(FrameHingeBackset);
  }
  private _backset: FrameHingeBackset;
  get backset(): FrameHingeBackset {
    return this._backset;
  }
  set backset(value: FrameHingeBackset) {
    this._backset = value;
    this.updateValue();
  }
  highFrequencies = ['Top', 'All'];
  public get highFrequency(): string {
    return this.prep.highFrequency;
  }
  public set highFrequency(value: string) {
    this.prep.highFrequency = value;
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, FramePrepCategoryIds.HINGE);
    if (!this.prep) {
      this.prep = {} as IFrameHingePrep;
    }
    this._quantity = this.prep.quantity ?? null;
    this.codes = this.codes.concat([this.specialPrepCode]);
    this._code = this.codes.first(c => c.id === this.prep.id) ?? null;
    this.locations = prepsComponent.configService.prepCategories
      .first(c => c.id === 7)
      .prepCategoryLocations.concat([this.specialPrepLocation]);
    this._location = this.locations.first(c => c.value === this.prep.locationType) ?? null;
    this._backset = this.backsets.first(c => c === this.prep.backset) ?? FrameHingeBackset._14;
    this._referencePoint =
      this.referencePoints.first(c => c === this.prep.referencePoint) ?? FramePrepReferencePoint.HT;
    this.highFrequency = this.prep.highFrequency ?? null;
    this.setSpecial();
  }

  ngAfterViewInit(): void {
    this.draw$.pipe(debounceTime(50), takeUntil(this.destroy$)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  ngOnDestroy(): void {
    super.destroy();
  }

  updateValue(): void {
    if (!this.quantity) {
      this.prep = {} as IFrameHingePrep;
      this._code = null;
      this._location = null;
      return this.draw$.next();
    }
    if (!this.code) {
      this._location = null;
      return this.draw$.next();
    }
    this.prep.id = this.code?.id;
    this.prep.code = this.code?.code;
    this.prep.pricingCategory = this.code?.pricingCategory;
    this.prep.locationType = this.location?.value;
    this.prep.fixedLocation = this.code?.fixedLocation;
    this.prep.backset = this.backset;
    this.prep.referencePoint = this.referencePoint;
    if (!Array.isArray(this.prep.location)) {
      this.prep.location = [];
    }
    const locations = (this.prep.location as string[]).take(this.quantity);
    this.prep.location = locations.concat(
      new Array(this.quantity - locations.length).fill(
        uomSwitch('0', 'door', this.unitOfMeasure).toDimension('door', this.unitOfMeasure),
        0,
        this.quantity - locations.length
      )
    );
    this.prep.quantity = this.quantity;
    this.draw$.next();
  }

  drawPreps(): void {
    this.prepLocationInputs = []
    super.clear();
    if (this.prep.locationType !== this.specialPrepLocation.value || !this.prep.quantity) {
      this.prep.location = Array.isArray(this.prep.location)? []: '0"'
      this.framePrepComponent.resetAndFit();
      return;
    }
    for (let i = 0; i < this.quantity; i++) {
      this.prepLocationInputs.push(this.drawPrep(i, this.prep));
    }
  }
}
