import { Component } from '@angular/core'
import { IDialog } from '../../../core/interfaces/i-dialog'
import { FrameElevation } from '../../models/frame-elevation'
import { FrameElevationConfigService } from '../../services/frame-elevation-config.service'

@Component({
  selector: 'lib-frame-preps-dialog',
  template: `<lib-dialog header="Frame Preps" [closeable]="true" [loading]="frameElevationConfigService.loading">
    <lib-preps [data]="data"> </lib-preps>
  </lib-dialog>`,
  styles: [
    `
      :host {
        height: 80vh;
        width: 80vw;
      }
      lib-dialog {
        ::ng-deep .card-content {
          height: calc(100% - 32px);
          position: relative;
        }
      }

    `
  ]
})
export class PrepsDialogComponent implements IDialog<{ frame: FrameElevation }> {
  data: { frame: FrameElevation }
  constructor(public frameElevationConfigService: FrameElevationConfigService) {}
}
