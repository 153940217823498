import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DialogService } from '../../services/dialog.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, OnDestroy {
  @Input() header: string;
  @Input() closeable = true;
  @Input() loading = false;
  @Input() footerTemplate: HTMLElement;
  @Input() sideTemplate: HTMLElement;
  @Output() close = new EventEmitter();
  private _destroy$ = new Subject<void>();

  constructor(public dialogService: DialogService) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  onClose(){
    this.dialogService.close()
    this.close.emit();
  }
}
