import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'
import { AnnouncementsService } from '../../../services/announcements/announcements.service'
import { Announcement } from '../../../models/announcement.model'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'oa-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  loading = true
  announcements: Announcement[]

  constructor(private announcementsSvc: AnnouncementsService) {
    this.announcementsSvc
      .get(true)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((val) => {
        this.announcements = val
        this.loading = false
      })
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
