export enum DoorGlassInstallation {
  CKI = 'Cutout w/ Kit Installed',
  CKIR = 'Cutout w/ Kit & Reinforcement',
  PP = 'Panel in Place',
  CO = 'Cutout Only',
  CR = 'Cutout w/ Reinforcement',
  RCO = 'Round Cutout Only',
}

export const DoorGlassInstallationOptions: { [key in DoorGlassInstallation]: { cutoutOnly: boolean } } = {
  [DoorGlassInstallation.CKI]: {
    cutoutOnly: false,
  },
  [DoorGlassInstallation.CKIR]: {
    cutoutOnly: false,
  },
  [DoorGlassInstallation.PP]: {
    cutoutOnly: true,
  },
  [DoorGlassInstallation.CO]: {
    cutoutOnly: true,
  },
  [DoorGlassInstallation.CR]: {
    cutoutOnly: true,
  },
  [DoorGlassInstallation.RCO]: {
    cutoutOnly: true,
  },
};
