import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { Cutout } from '../../abstracts/cutout';
import { DoorType } from '../../abstracts/door-type';
import { Panel } from '../../abstracts/panel';
import { HorizontalCutoutDimensionMode, VerticalCutoutDimensionMode } from '../../enums/cutout-dimension-mode';
import { Embossment } from '../../models/cutout-types/embossment';
import { GlassPanel } from '../../models/cutout-types/embossments/glass-panel';
import { SquarePanel } from '../../models/cutout-types/embossments/square-panel';
import { Door } from '../../models/door';
import { CutoutExport } from '../../models/exports/cutout-export';

export class E4TLType extends DoorType {
  cutouts: Cutout[];
  private width = uomSwitch('23"', 'door', this.door.doorElevation.unitOfMeasure);
  private height = uomSwitch(this.isTall ? '81 1/8"' : '65 1/8"', 'door', this.door.doorElevation.unitOfMeasure)
  private bottomRail = uomSwitch('10"', 'door', this.door.doorElevation.unitOfMeasure);

  constructor(door: Door, cutouts?: CutoutExport[]) {
    super(door, cutouts);
    if(this.door.height.toDimension('door', UnitOfMeasure.Imperial)=== '80"'){
      this.bottomRail = uomSwitch('8"', 'door', this.door.doorElevation.unitOfMeasure);
    }
    this.cutouts = [this.getEmbossment()];
    if (cutouts) {
      this.cutouts.forEach((c, i) => c.mergeWith(cutouts[i]));
    }
  }

  private getEmbossment(): Cutout {
    return new Embossment(
      'Embossment',
      this,
      (this.door.actualWidth - this.width) / 2,
      this.door.actualHeight - this.height - this.bottomRail,
      this.width,
      this.height,
      VerticalCutoutDimensionMode.Bottom,
      HorizontalCutoutDimensionMode.Lock,
      this.getPanels()
    );
  }

  get isTall(): boolean {
    return this.door.height >= uomSwitch(`8'`, 'door', this.door.doorElevation.unitOfMeasure);
  }

  private getPanels(): Panel[] {
    const topPanelHeight = uomSwitch(this.isTall ? '13"': '7 1/16"', 'door', this.door.doorElevation.unitOfMeasure);
    const middlePanelHeight = uomSwitch(this.isTall ? '27.5"': '25 7/16"', 'door', this.door.doorElevation.unitOfMeasure);
    const bottomPanelHeight = uomSwitch(this.isTall ? '25.5"': '21 9/16"', 'door', this.door.doorElevation.unitOfMeasure);
    const verticalSpaceBetween1 = uomSwitch(this.isTall ? '6 9/16"': '4 9/16"', 'door', this.door.doorElevation.unitOfMeasure);
    const verticalSpaceBetween2 = uomSwitch(this.isTall ? '8 9/16"': '6 1/2"', 'door', this.door.doorElevation.unitOfMeasure);
    const horizontalSpaceBetween = uomSwitch('5"', 'door', this.door.doorElevation.unitOfMeasure);
    const width = uomSwitch('9"', 'door', this.door.doorElevation.unitOfMeasure);

    return [
      new GlassPanel(0, 0, width, topPanelHeight),
      new GlassPanel(width + horizontalSpaceBetween, 0, width, topPanelHeight),

      new SquarePanel(0, topPanelHeight + verticalSpaceBetween1, width, middlePanelHeight),
      new SquarePanel(width + horizontalSpaceBetween, topPanelHeight + verticalSpaceBetween1, width, middlePanelHeight),

      new SquarePanel(
        0,
        topPanelHeight + verticalSpaceBetween1 + middlePanelHeight + verticalSpaceBetween2,
        width,
        bottomPanelHeight
      ),
      new SquarePanel(
        width + horizontalSpaceBetween,
        topPanelHeight + verticalSpaceBetween1 + middlePanelHeight + verticalSpaceBetween2,
        width,
        bottomPanelHeight
      ),
    ];
  }
}
