export enum DoorCore {
  BULLET16 = 'Level 1/6 Polystyrene',
  BULLET23 = 'Level 2/3 Polystyrene w/ Armor Plating',
  BULLET4 = 'Level 4 Polystyrene w/ Armor Plating',
  BULLET5 = 'Level 5 Polystyrene w/ Armor Plating',
  BULLET78 = 'Level 7/8 Polystyrene w/ Armor Plating',
  HC = 'Honeycomb',
  LL = 'Lead Lined Door 1/16"',
  PS = 'Polystyrene',
  PU = 'Polyurethane',
  FIB = 'Normal Fiberglass Insert',
  TEMP = 'Temperature Rise (250 degree mineral fiberboard)',
  SOUND42 = 'Sound Core (42)',
  SOUND4347 = 'Sound Core (43/47)',
  SOUND50 = 'Sound Core (50)',
  SOUND52 = 'Sound Core (52)',
  SOUND48 = 'Sound Core (48)',
  STIFFENED = 'Stiffened Core',
}
