export enum ToolType {
  Draw = 'draw',
  Flip = 'flip',
  Pan = 'move',
  Undo = 'undo',
  Redo = 'redo',
  Glass = 'lite',
  Door = 'door',
  Ruler = 'rulers',
  Grid = 'grid',
  Preps = 'preps',
  Louver = 'louver',
  Export = 'download',
  Rules = 'check',
}
