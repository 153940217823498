import { inject } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { StorageService } from '../../services/local-storage/local-storage.service'
import { environment } from '../../../environments/environment'

export const customerIdGuard = (): boolean | UrlTree => {
  const storageService = inject(StorageService)
  const router = inject(Router)
  if (!environment.featureFlags.enableMultiBranch) return true
  return storageService.containsItem('targetBranchCustomerId') ? true : router.parseUrl('/landing')
}
