import { HttpClient } from '@angular/common/http'
import { Component } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'oa-third-party-licenses-dialog',
  template: `
    <div mat-dialog-title class="dialog-title" [ngClass]="{ loading: (license$ | async) === null }">
      <h1>
        {{ 'thirdPartyLicenses' | translate }}
      </h1>
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <p mat-dialog-content [innerText]="license$ | async"></p>
  `,
  styles: [
    `
      h1 {
        border-bottom: 0;
        padding: 0;
      }
      div.dialog-title {
        display: flex;
        justify-content: space-between;
      }
    `
  ]
})
export class ThirdPartyLicensesComponent {
  license$ = this.http.get('assets/version-license_2022-05-26_155016.txt', { responseType: 'text' })
  constructor(private http: HttpClient, public dialogRef: MatDialogRef<ThirdPartyLicensesComponent>) {}
}
