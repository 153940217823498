
/**
 * SVG Units of Measure - these are units used to draw SVG elements.on the dom
 *
 * [Units for Measurements Reference](https://oreillymedia.github.io/Using_SVG/guide/units.html)
 */
 export enum SVGUnits {
  /* Pixel units, directly equivalent to SVG user units. */
  px = 'px',
  /**
   * Inches.
   * 1in = 96px or user units
   * 1in = 2.54cm
   * In modern software, inches and all other absolute units will be adjusted to match the px unit.
   */
  in = 'in',
  /** Centimeters. */
  cm = 'cm',
  /** Millimeters */
  mm = 'mm',
  /**
   * Points
   * 1pt ≅ 1.3333px or user units (1px = 0.75pt)
   * 1pt = 1/72in
   */
  pt = 'pt',
  /**
   * Picas
   * 1pc = 16px or user units
   * 1pc = 1/6in
   */
  pc = 'pc',
  /** Em units. Equivalent to the computed font-size in effect for an element. */
  em = 'em',
  /**
   * Ex units. Equivalent to the height of a lower-case letter in the font (and font-size) in effect for an element.
   * If the font doesn’t include lower-case letters, or doesn’t include the metadata about the ex-height,
   * then 1ex = 0.5em. */
  ex = 'ex',
  /**
   * Character units.
   * Equivalent to the width of the 0 (zero) character in the font and font-size in effect for an element.
   */
  ch = 'ch',
  /**
   * Root-em units.
   * The font size of the root element in the document, regardless of any settings for the current element.
   */
  rem = 'rem',
}
