import { Observable, Subject } from 'rxjs';

export abstract class Updatable {
  protected _update$ = new Subject<void>();
  protected _destroy$ = new Subject<void>();

  get update$(): Observable<void> {
    return this._update$.asObservable();
  }

  destroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}
