import { MaxMinDistance } from '../../core/models/max-min-distance'
import { Door } from '../models/door'
import { DoorType } from './door-type';

export abstract class EmbossedDoorType extends DoorType {
  door: Door

  abstract get totalEmbossmentHeight(): number

  abstract get maxEmbossmentDistances(): { fromTop: number; fromBottom: number }

  /**
   * Calculates the minimum distance from the top and bottom of the door to the top and bottom of the embossment.
   */
  private get minEmbossmentDistances() {
    return {
      fromTop: this.door.actualHeight - (this.maxEmbossmentDistances.fromBottom + this.totalEmbossmentHeight),
      fromBottom: Math.max(
        0,
        this.door.actualHeight - (this.maxEmbossmentDistances.fromTop + this.totalEmbossmentHeight)
      )
    }
  }

  /**
   * Gets the minimum and maximum distance from the top and bottom of the door to the top and bottom of the embossment.
   */
  public get minMaxEmbossmentDimensions() {
    return new MaxMinDistance(
      {
        /*
         * Max distance from top is the minimum of the following:
         * 1. 63.563" - (door height - (height + minDistanceFromBottom))
         * 2. Actual Door Height - (Embossment height + Min DistanceFromBottom)
         */
        fromTop: Math.min(
          this.maxEmbossmentDistances.fromTop,
          this.door.actualHeight - (this.totalEmbossmentHeight + this.minEmbossmentDistances.fromBottom)
        ),
        /*
         * Max distance from bottom is the minimum of the following:
         * 1. door height - (height + minDistanceFromTop)
         * 2. Actual Door Height - (Embossment height + Min DistanceFromTop)
         */
        fromBottom: Math.min(
          this.door.height - this.totalEmbossmentHeight,
          this.maxEmbossmentDistances.fromBottom
        )
      },
      {
        fromTop: this.minEmbossmentDistances.fromTop,
        fromBottom: this.minEmbossmentDistances.fromBottom
      }
    )
  }
}

