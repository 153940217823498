import { Approval } from '../../core/enums/approval';
import { DoorCore } from '../enums/door-core';
import { DoorEdge } from '../enums/door-edge';
import { DoorGauge } from '../enums/door-gauge';
import { DoorSeamlessEdge, VisibleSeamlessEdges } from '../enums/door-seamless-edge';
import { DoorThickness } from '../enums/door-thickness';

export function approvalCores(cores: DoorCore[], approval: Approval) {
  if (approval === Approval.L1BRD || approval === Approval.L6BRD) {
    [...cores].filter(c => c !== DoorCore.BULLET16).forEach(c => cores.remove(c));
  }
  if (approval === Approval.L2BRD || approval === Approval.L3BRD) {
    [...cores].filter(c => c !== DoorCore.BULLET23).forEach(c => cores.remove(c));
  }
  if (approval === Approval.L4BRD) {
    [...cores].filter(c => c !== DoorCore.BULLET4).forEach(c => cores.remove(c));
  }
  if (approval === Approval.L5BRD) {
    [...cores].filter(c => c !== DoorCore.BULLET5).forEach(c => cores.remove(c));
  }
  if (approval === Approval.L7BRD || approval === Approval.L8BRD) {
    [...cores].filter(c => c !== DoorCore.BULLET78).forEach(c => cores.remove(c));
  }
  if (approval === Approval.STC42 || approval === Approval.C_STC42) {
    [...cores].filter(c => c !== DoorCore.SOUND42).forEach(c => cores.remove(c));
  }
  if (approval === Approval.STC43 || approval === Approval.STC47) {
    [...cores].filter(c => c !== DoorCore.SOUND4347).forEach(c => cores.remove(c));
  }
  if (approval === Approval.STC48) {
    [...cores].filter(c => c !== DoorCore.SOUND48).forEach(c => cores.remove(c));
  }
  if (approval === Approval.STC50 || approval === Approval.C_STC50) {
    [...cores].filter(c => c !== DoorCore.SOUND50).forEach(c => cores.remove(c));
  }
  if (approval === Approval.STC52) {
    [...cores].filter(c => c !== DoorCore.SOUND52).forEach(c => cores.remove(c));
  }
}

export function approvalSeamlessEdges(seamlessEdges: DoorSeamlessEdge[], approval: Approval) {
  if (
    approval === Approval.C_STC50 ||
    approval === Approval.STC48 ||
    approval === Approval.STC50 ||
    approval === Approval.STC52
  ) {
    [...VisibleSeamlessEdges].forEach(v => {
      seamlessEdges.remove(v);
    });
  }
}
