import { fromEvent, Subject } from 'rxjs';
import { pipe } from 'lodash/fp';
import { filter, takeUntil } from 'rxjs/operators';
import { SpecialHardwarePrepBaseComponent } from '../../../core/components/special-hardware/special-hardware.component';
import { UnitOfMeasure } from '../../../core/enums/unit-of-measure';
import { uomSwitch } from '../../../core/functions/uomSwitch';
import { createSVGElement, setX, setY, setWidth, setHeight, setAttributes } from '../../../core/helpers/svg-functions';
import { IPrep } from '../../../core/interfaces/i-prep';
import { IPrepCategoryLocation } from '../../../core/interfaces/i-prep-category-location';
import { IPrepCode } from '../../../core/interfaces/i-prep-code';
import { IProduct } from '../../../core/interfaces/i-product';
import { FramePrepReferencePoint } from '../../enums/frame-prep-reference-point';
import { StickSubtype } from '../../enums/stick-subtype';
import { FrameElevation } from '../../models/frame-elevation';
import { Stick } from '../../models/stick';
import { FramePrepComponent } from './frame-prep/frame-prep.component';
import { PrepsComponent } from './preps.component';
import { FramePrepCategoryIds } from '../../../core/enums/prep-category-ids';
import { CSSPropKey } from '@oeo/common';

export abstract class PrepBaseComponent extends SpecialHardwarePrepBaseComponent {
  products: IProduct[];
  codes: IPrepCode[];
  readonly specialPrepLocation: IPrepCategoryLocation = {
    id: 0,
    value: 'Special',
  };
  readonly specialPrepCode: IPrepCode = {
    id: 0,
    code: 'Special',
  };
  readonly standardPrepLocation: IPrepCategoryLocation = {
    id: 0,
    value: 'Yes Standard Locations',
  };

  protected prepLocationInputs: HTMLInputElement[] = []
  get prepLocationInputsFilled(){
    return !this.prepLocationInputs.any((location)=> location?.value === '0"')
  }

  protected draw$ = new Subject<void>();
  protected destroy$ = new Subject<void>();
  frameElevation: FrameElevation;
  private _inputOffset = 5;
  private _height = 100;
  public get height() {
    return this._height;
  }
  private _width = 280;
  public get width() {
    return this._width;
  }
  readonly horzDistance = 100;
  get unitOfMeasure() {
    return this.frameElevation.unitOfMeasure;
  }

  get g(): SVGGElement {
    return this.framePrepComponent.preps;
  }

  get svg(): SVGSVGElement {
    return this.framePrepComponent.svg;
  }
  abstract framePrepComponent: FramePrepComponent;
  abstract code: IPrepCode;
  abstract prep: IPrep;
  abstract subTypes: StickSubtype[];

  constructor(public prepsComponent: PrepsComponent, prepCategoryId: FramePrepCategoryIds) {
    super();
    this.frameElevation = this.prepsComponent.data.frame;
    this.codes = prepsComponent.configService.prepCodes
      .filter(
        code =>
          (this.frameElevation.isSteelcraft ? code.isSteelcraft : code.isRepublic) &&
          code.categories.any(category => category.id === prepCategoryId && category.checked)
      )
      .orderBy(c => c.code);
    this.products = prepsComponent.configService.products.filter(p => p.prepCategoryIds.includes(prepCategoryId));
  }

  private createInput(index: number, x: number, y: number, prep: IPrep, isVertical: boolean) {
    const foreignObject = pipe(
      setX(x - (!isVertical ? this._width / 2 : 0)),
      setY(y - (isVertical ? this._height / 2 : 0)),
      setHeight(this._height),
      setWidth(this._width)
    )(createSVGElement('foreignObject'));
    const div = document.createElement('div');
    div.style.width = 'calc(100% - 32px)';
    div.style.height = 'calc(100% - 32px)';

    const input = document.createElement('input');
    const styles: Record<string, string> = {
      border: '4px solid #d5d8dd',
      borderRadius: '2px',
      height: '100%',
      width: 'calc(100% - 40px)',
      padding: '0 20px',
      textAlign: 'left',
      fontSize: '36px',
    };
    for (const key in styles) {
      input.style[key as CSSPropKey] = styles[key];
    }
    input.value = prep.location instanceof Array ? prep.location[index] : prep.location;

    if (!prep.fixedLocation) {
      fromEvent(input, 'mousedown')
        .pipe(takeUntil(this.draw$))
        .subscribe(e => {
          foreignObject.parentNode.appendChild(foreignObject);
          e.stopPropagation();
        });

      fromEvent(input, 'mouseup')
        .pipe(takeUntil(this.draw$))
        .subscribe(e => e.stopPropagation());

      fromEvent(input, 'mousemove')
        .pipe(takeUntil(this.draw$))
        .subscribe(e => e.stopPropagation());

      fromEvent(input, 'focusout')
        .pipe(takeUntil(this.draw$))
        .subscribe(() => this.updateInput(input, isVertical, prep, index))

      fromEvent(input, 'keyup')
        .pipe(filter((event: KeyboardEvent)=> event.key === 'Enter'), takeUntil(this.draw$))
        .subscribe(() => this.updateInput(input, isVertical, prep, index))

    } else {
      input.readOnly = true;
    }

    div.appendChild(input);
    foreignObject.appendChild(div);
    return {inputContainer: foreignObject, inputElement: input};
  }

  private updateInput(input: HTMLInputElement, isVertical: boolean, prep: IPrep, index: number){
    const suffix = this.unitOfMeasure === UnitOfMeasure.Imperial ? '"' : 'mm';
    if (!input.value.endsWith(suffix)) {
      input.value = `${input.value}${suffix}`;
    }
    if (isVertical && input.value.fromDimension('frame', this.unitOfMeasure) > this.frameElevation.height) {
      input.value = uomSwitch('0"', 'frame', this.unitOfMeasure).toDimension('frame', this.unitOfMeasure);
    }
    if (!isVertical && input.value.fromDimension('frame', this.unitOfMeasure) > this.frameElevation.width) {
      input.value = uomSwitch('0"', 'frame', this.unitOfMeasure).toDimension('frame', this.unitOfMeasure);
    }
    if (prep.location instanceof Array) {
      prep.location[index] = input.value
        .fromDimension('frame', this.unitOfMeasure)
        .toDimension('frame', this.unitOfMeasure);
    } else {
      prep.location = input.value
        .fromDimension('frame', this.unitOfMeasure)
        .toDimension('frame', this.unitOfMeasure);
    }
    input.value = prep.location instanceof Array ? prep.location[index] : prep.location;
    this.draw$.next();
  }

  private createCenterline(x: number, y: number, isVertical: boolean): SVGUseElement {
    return pipe(
      setX(x - (!isVertical ? 16.6 : 0)),
      setY(y - (isVertical ? 25 : 0)),
      setAttributes({ href: '#centerline' }),
    )(createSVGElement('use'));
  }

  private createPolyLine(points: { x: number; y: number }[], styles: { [key: string]: string } = {}) {
    const line = createSVGElement('polyline');
    const polylineStyles: Record<string, string> = {
      strokeWidth: '4px',
      fill: 'none',
      stroke: '#7f7f7f',
      strokeDasharray: '8px',
    };
    for (const key in polylineStyles) {
      line.style[key as CSSPropKey] = polylineStyles[key];
    }
    for (const key in styles) {
      line.style[key as CSSPropKey] = styles[key];
    }
    for (const point of points) {
      line.points.appendItem(this.svg.getPoint(point.x, point.y));
    }
    return line;
  }

  clear(): void {
    for (const element of Array.from(this.g.children)) {
      element.remove();
    }
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.draw$.next();
    this.draw$.unsubscribe();
  }

  drawPrep(index: number, prep: IPrep): HTMLInputElement {
    switch (prep.referencePoint) {
      case FramePrepReferencePoint.FB:
      case FramePrepReferencePoint.FCL:
      case FramePrepReferencePoint.HCL:
      case FramePrepReferencePoint.HT:
      case FramePrepReferencePoint.TCL:
      case FramePrepReferencePoint.TT:
        return this.drawVertPrep(index, prep);
      default:
        return this.drawHorzPrep(index, prep);
    }
  }

  private drawVertPrep(index: number, prep: IPrep) {
    const y1 =
      prep.referencePoint === FramePrepReferencePoint.FB || prep.referencePoint === FramePrepReferencePoint.FCL
        ? this.frameElevation.height
        : prep.referencePoint === FramePrepReferencePoint.TCL || prep.referencePoint === FramePrepReferencePoint.TT
        ? 0
        : this.frameElevation.intersectables
            .filter(i => i instanceof Stick)
            .first((s: Stick) => s.isHead)
            ?.bottomPoints.first()?.y ?? 0;
    const location = uomSwitch(
      prep.location instanceof Array ? prep.location[index] : prep.location,
      'frame',
      this.unitOfMeasure
    );
    const y2 =
      prep.referencePoint === FramePrepReferencePoint.FB || prep.referencePoint === FramePrepReferencePoint.FCL
        ? y1 - location
        : y1 + location;
    const dist = -(this.horzDistance + this._inputOffset + this.width) * index;
    if (this.hasCenterline(prep.referencePoint as FramePrepReferencePoint)) {
      this.g.insertAdjacentElement('afterbegin', this.createCenterline(-this.horzDistance / 2, y2, true));
    }

    this.g.insertAdjacentElement(
      'afterbegin',
      this.createPolyLine(
        [
          { x: 0, y: y1 },
          { x: dist - this.horzDistance, y: y1 },
          { x: dist - this.horzDistance, y: y2 },
          { x: 0, y: y2 },
        ].filter(x => !!x)
      )
    );
    const { inputContainer, inputElement } = this.createInput(
      index,
      dist - this.horzDistance - this._inputOffset - this.width,
      [y1, y2].average(_ => _),
      prep,
      true
    )
    this.g.insertAdjacentElement(
      'beforeend',
      inputContainer
    );
    this.framePrepComponent.resetAndFit();
    return inputElement
  }

  private drawHorzPrep(index: number, prep: IPrep) {
    const x1 =
      prep.referencePoint === FramePrepReferencePoint.RCL || prep.referencePoint === FramePrepReferencePoint.RR
        ? this.frameElevation.width
        : 0;
    const location = uomSwitch(
      prep.location instanceof Array ? prep.location[index] : prep.location,
      'frame',
      this.unitOfMeasure
    );
    const x2 =
      prep.referencePoint === FramePrepReferencePoint.RCL || prep.referencePoint === FramePrepReferencePoint.RR
        ? x1 - location
        : x1 + location;
    const dist = -(this.horzDistance + this.height + this._inputOffset) * index;
    if (this.hasCenterline(prep.referencePoint as FramePrepReferencePoint)) {
      this.g.insertAdjacentElement('afterbegin', this.createCenterline(x2, (-this.horzDistance * 3) / 4, false));
    }

    this.g.insertAdjacentElement(
      'afterbegin',
      this.createPolyLine(
        [
          { x: x1, y: 0 },
          { x: x1, y: dist - this.horzDistance },
          { x: x2, y: dist - this.horzDistance },
          { x: x2, y: 0 },
        ].filter(x => !!x)
      )
    );
    const { inputContainer, inputElement } = this.createInput(
      index,
      [x1, x2].average(_ => _) - this._width / 2,
      dist - this.horzDistance - this._inputOffset,
      prep,
      true
    )
    this.g.insertAdjacentElement(
      'beforeend',
      inputContainer
    );
    this.framePrepComponent.resetAndFit();
    return inputElement
  }

  private hasCenterline(referencePoint: FramePrepReferencePoint): boolean {
    switch (referencePoint) {
      case FramePrepReferencePoint.FCL:
      case FramePrepReferencePoint.HCL:
      case FramePrepReferencePoint.LCL:
      case FramePrepReferencePoint.RCL:
      case FramePrepReferencePoint.TCL:
        return true;
      default:
        return false;
    }
  }

  public get isShippedLoose(): boolean {
    return this.code?.description?.includes("Shipped Loose")
  }
}
