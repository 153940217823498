import { Component, forwardRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ENVIRONMENT_INJECTION_TOKEN, IEnvironment } from '../../core/interfaces/i-environment';
import { IElevation } from '../../core/interfaces/i-elevation';
import { DoorElevationService } from '../../door-elevation/services/door-elevation-service/door-elevation.service';
import { FrameElevationService } from '../../core/services/frame-elevation.service';
import { UserService } from '../../core/services/user.service';
import { ElevationComponent } from '../elevation.component';
import { DoorElevationConfigService } from '../../door-elevation/services/door-elevation-config-service/door-elevation-config.service'; 
import { DoorMode } from '../../door-elevation/services/door-elevation-config-service/constants';

interface Tab {
  index: number;
  text: string;
}

@Component({
  selector: 'lib-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent  {
  tabs: Tab[] = [
    { index: 0, text: 'TITLE.myDrawings' },
    { index: 1, text: 'TITLE.myFrames' },
  ];
  selectedTabIndex: number = 0;

  loading = false
  get configureFrame$() {
    return this.elevationComponent.configureFrame$;
  }

  get configurePreferences$() {
    return this.elevationComponent.configurePreferences$;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(ENVIRONMENT_INJECTION_TOKEN) public environment: IEnvironment,
    @Inject(forwardRef(() => ElevationComponent)) private elevationComponent: ElevationComponent,
    public doorElevationService: DoorElevationService,
    public frameElevationService: FrameElevationService,
    public userService: UserService,
    public configService: DoorElevationConfigService
  ) {
    this.getElevations();
  }

  getElevations(): void {
    this.loading = true;
    forkJoin({
      doorElevations: this.doorElevationService.search({}),
      frameElevations: this.frameElevationService.search({})}
    ).subscribe(()=> this.loading = false)
  }

  goTo(route: string, doorMode: DoorMode): void {
    this.router.navigate([route], { relativeTo: this.route, queryParams: {state: doorMode}});
  }

  goToElevation(elevation: IElevation): void {
    this.router.navigate([this.selectedTabIndex === 0 ? '../doorElevation' : '../frameElevation'], {
      relativeTo: this.route,
      queryParams: { style: elevation.name, id: elevation.id },
    });
  }
}
