import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs';
import { FramePrepReferencePoint } from '../../../enums/frame-prep-reference-point';
import { IElecPrep } from '../../../interfaces/preps/i-elec-prep';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepsComponent } from '../preps.component';
import { ElecPrepComponent } from './elec-prep.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-mag-switch-prep',
  templateUrl: './elec-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class MagLockPrepComponent extends ElecPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;

  public get prep(): IElecPrep {
    return this.frameElevation.magLockPrep;
  }
  public set prep(value: IElecPrep) {
    this.frameElevation.magLockPrep = value;
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, FramePrepCategoryIds.MAG_LOCK, 'Mag Lock');
  }

  get referencePoints(): FramePrepReferencePoint[] {
    return !this.location ? [] : [FramePrepReferencePoint.TL, FramePrepReferencePoint.LT];
  }

  ngOnInit(): void {
    this.init();
    this._referencePoint = this.referencePoints.first(c => c === this.prep.referencePoint) ?? null;
  }

  ngAfterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  ngOnDestroy(): void {
    super.destroy();
  }
}
