import { AfterViewInit, Component, forwardRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs';
import { FramePrepReferencePoint } from '../../../enums/frame-prep-reference-point';
import { Orientation } from '../../../enums/orientation';
import { StickType } from '../../../enums/stick-type';
import { IFrameQuantityPrep } from '../../../interfaces/preps/i-frame-quantity-prep';
import { Stick } from '../../../models/stick';
import { FramePrepComponent } from '../frame-prep/frame-prep.component';
import { PrepsComponent } from '../preps.component';
import { JambPrepComponent } from './quantity-prep.component';
import { FramePrepCategoryIds } from '../../../../core/enums/prep-category-ids';

@Component({
  selector: 'lib-bottom-jamb-prep',
  templateUrl: './quantity-prep.component.html',
  styleUrls: ['../prep-base.component.scss'],
})
export class BottomJambPrepComponent extends JambPrepComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(FramePrepComponent, { static: false }) framePrepComponent: FramePrepComponent;
  isSpecial: boolean = false;

  public get prep(): IFrameQuantityPrep {
    return this.frameElevation.bottomJambAnchorPrep;
  }
  public set prep(value: IFrameQuantityPrep) {
    this.frameElevation.bottomJambAnchorPrep = value;
  }

  get referencePoints(): FramePrepReferencePoint[] {
    return [FramePrepReferencePoint.LCL, FramePrepReferencePoint.RCL];
  }

  get sticks(): Stick[] {
    return this.frameElevation.intersectables.filter(
      i =>
        i instanceof Stick && i.type === StickType.OpenSection && i.orientation === Orientation.Horizontal && !i.flipped
    ) as Stick[];
  }

  constructor(@Inject(forwardRef(() => PrepsComponent)) prepsComponent: PrepsComponent) {
    super(prepsComponent, FramePrepCategoryIds.BOTTOM_JAMB_ANCHOR, 'Anchor');
  }

  ngOnInit(): void {
    this.init();
    this._referencePoint =
      this.referencePoints.first(c => c === this.prep.referencePoint) ?? FramePrepReferencePoint.LCL;
  }

  ngAfterViewInit(): void {
    this.draw$.pipe(takeUntil(this.destroy$), debounceTime(50)).subscribe(() => this.drawPreps());
    this.updateValue();
  }

  ngOnDestroy(): void {
    super.destroy();
  }
}
