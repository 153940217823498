import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import SvgPanZoom from 'svg-pan-zoom';
import { Handing } from '../../../enums/handing';
import { Door } from '../../../models/door';

@Component({
  selector: 'lib-door-prep',
  templateUrl: './door-prep.component.html',
  styleUrls: ['./door-prep.component.scss'],
})
export class DoorPrepComponent implements OnInit {
  @Input() door: Door;
  @Input() prepLocationInputsFilled = true
  @ViewChild('gRef', { static: true }) gRef: ElementRef<SVGGElement>;
  @ViewChild('prepsRef', { static: true }) prepsRef: ElementRef<SVGGElement>;
  @ViewChild('svgRef', { static: true }) svgRef: ElementRef<SVGSVGElement>;
  private _svgPanZoom: SvgPanZoom.Instance;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resetAndFit()
  }

  get g() {
    return this.gRef.nativeElement;
  }

  get svg() {
    return this.svgRef.nativeElement;
  }

  get preps() {
    return this.prepsRef.nativeElement;
  }

  get flipped() {
    return this.door.handing === Handing.RH || this.door.handing === Handing.RHR;
  }

  constructor() {}

  ngOnInit(): void {
    requestAnimationFrame(() => {
      this.door.draw(this.g, true);
      requestAnimationFrame(() => {
        this._svgPanZoom = SvgPanZoom(this.svg, {
          zoomEnabled: false,
          fit: false,
          contain: false,
          center: false,
          panEnabled: false,
          minZoom: 0,
          dblClickZoomEnabled: false,
        });
        this.resetAndFit();
      });
    });
  }

  resetAndFit() {
    requestAnimationFrame(() => {
      if (this.flipped) {
        this.g.flip(80, 0);
      }
      this._svgPanZoom.updateBBox().center().updateBBox().fit().updateBBox().zoomOut();
    });
  }
}
