<div class="notification notification-container" [id]="notificationType">
  <ng-container>
    <ng-content></ng-content>
  </ng-container>

  <button
    mat-icon-button
    (click)="hideNotification()"
    attr.aria-label="{{ 'ACTIONS.hideMessage' | translate }}"
    matTooltip="{{ 'close' | translate }}"
  >
    <mat-icon svgIcon="close"></mat-icon>
  </button>
</div>
